import { ThemedBatchesQuery, ThemeId } from "@otta/search/schema";

type ThemedBatchUser = Exclude<ThemedBatchesQuery["currentUser"], null>;
type QueryKey = Exclude<keyof ThemedBatchUser, "id" | "__typename">;

const themeIdQueryKeys: Record<ThemeId | "ALL_MATCHES", QueryKey> = {
  [ThemeId.ApplyViaOtta]: "applyViaOtta",
  [ThemeId.FemaleFounders]: "femaleFounders",
  [ThemeId.HasSalaries]: "hasSalaries",
  [ThemeId.NewlyAdded]: "newlyAdded",
  [ThemeId.PreferredSector]: "preferredSector",
  [ThemeId.RecentlyFunded]: "recentlyFunded",
  [ThemeId.TechForGood]: "techForGood",
  [ThemeId.FullyRemote]: "fullyRemote",
  [ThemeId.FavouriteTechnologies]: "favouriteTechnologies",
  [ThemeId.TakeAnotherLook]: "takeAnotherLook",
  ALL_MATCHES: "allMatches",
};

export function toQueryKey(themeId: ThemeId | "ALL_MATCHES"): QueryKey {
  return themeIdQueryKeys[themeId];
}
