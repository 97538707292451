import { Field, FieldRenderProps } from "react-final-form";
import { ReactElement } from "react";
import { FieldValidator } from "final-form";

import { TextQuestion } from "../types";

import { Labelled } from "./Label";

import { IInputFieldProps, InputField, Spacing } from "@otta/design";
import {
  composeValidators,
  maxLength,
  validEmail,
  validUrl,
} from "@otta/search/utils/validators";
import { AtsTextFormat } from "@otta/search/schema";
import { StrengthBar } from "@otta/search/components/StrengthBar";
import { Textarea } from "@otta/search/components/Input/Textarea";

type FieldProps = {
  question: TextQuestion;
  sectionId: string;
};

type InputProps = Omit<FieldProps, "sectionId"> &
  FieldRenderProps<string, HTMLElement, string> & { id: string };

function validator(format: AtsTextFormat): FieldValidator<string> | undefined {
  switch (format) {
    case AtsTextFormat.Long:
      return undefined;
    case AtsTextFormat.Url:
      return composeValidators(validUrl, maxLength(128));
    case AtsTextFormat.Email:
      return composeValidators(validEmail, maxLength(128));
    case AtsTextFormat.Short:
      return maxLength(128);
    default:
      return undefined;
  }
}

export function TextField({ question, sectionId }: FieldProps): ReactElement {
  const elementId = `${sectionId}_${question.id}`;
  return (
    <Labelled
      htmlFor={elementId}
      required={question.required}
      value={question.label}
    >
      <Field<string, HTMLElement, string>
        validate={validator(question.textFormat)}
        defaultValue={question.textDefault ?? undefined}
        initialValue={question.textAnswer ?? undefined}
        name={`s_${sectionId}.q_${question.id}.a_0.stringValue`}
        render={props => <Text id={elementId} question={question} {...props} />}
      />
    </Labelled>
  );
}

function inputType(
  format: Exclude<AtsTextFormat, AtsTextFormat.Long>
): Exclude<IInputFieldProps["type"], undefined> {
  switch (format) {
    case AtsTextFormat.Short:
      return "text";
    case AtsTextFormat.Email:
      return "email";
    case AtsTextFormat.Phone:
      return "tel";
    case AtsTextFormat.Url:
      return "url";
    default:
      return "text";
  }
}

/**
 * Render the actual input with the helper props provided by Final Form.
 * We map long inputs to text areas with strength bars and everything else to an input
 */
function Text({ question, meta, input, id }: InputProps): React.ReactElement {
  if (question.textFormat == AtsTextFormat.Long) {
    return (
      <Spacing>
        <StrengthBar
          currentStrength={Math.round(
            ((input?.value?.length ?? 0) / 750) * 100
          )}
        />
        <Textarea
          {...input}
          placeholder={`Type your answer here...\n\n\n`}
          error={meta.touched && meta.error}
          minHeight="200px"
          id={id}
        />
      </Spacing>
    );
  } else {
    return (
      <InputField
        {...input}
        type={inputType(question.textFormat)}
        placeholder={`Type your answer here...`}
        error={meta.touched && meta.error}
        id={id}
      />
    );
  }
}
