import { useEffect, useState } from "react";
import { useParams, useSearchParams, Link } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import { PageContainer, SlideUpContainer, Tick } from "../shared";

import { FollowUpQuestionCard } from "./FollowUpQuestionCard";

import { Middle, Text, Card, Button, VerticalSpacing } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  UpdateJobInterviewFeedbackReceivedDocument,
  InterviewFeedbackReceived,
  JobInterviewDocument,
  UserJobStatus,
} from "@otta/search/schema";

export const FullWidthButton = styled(Button)`
  width: 100%;
`;

export function JobInterviewFeedbackReceived(): React.ReactElement {
  const { interviewId } = useParams();
  const [qs] = useSearchParams();
  const [showError, setShowError] = useState(false);

  const onError = () => setShowError(true);

  const token = qs.get("token");
  const received = qs.get("received");

  const stillInterviewing = received !== "true" && received !== "false";

  const { data, loading } = useQuery(JobInterviewDocument, {
    variables: { id: interviewId as string },
    context: { emailToken: token },
    skip: stillInterviewing,
    onError,
  });

  const [mutation] = useMutation(UpdateJobInterviewFeedbackReceivedDocument, {
    context: { emailToken: token },
    onError,
  });

  useEffect(() => {
    mutation({
      variables: {
        id: interviewId as string,
        feedbackReceived:
          received === "true"
            ? InterviewFeedbackReceived.Yes
            : received === "false"
            ? InterviewFeedbackReceived.No
            : InterviewFeedbackReceived.NoResponseYet,
      },
    });

    pushAnalyticsEvent({
      eventName: "Candidate Viewed Interview Feedback Received Flow",
      feedbackReceived:
        received === "true"
          ? "Yes"
          : received === "false"
          ? "No"
          : "Still interviewing",
    });
  }, [mutation, interviewId, received]);

  const interview = data?.jobInterview;
  const jobStatus = interview?.job.userJobStatusInfo.status;
  const showFollowUpQuestion = jobStatus === UserJobStatus.Interviewing;

  return (
    <PageContainer>
      {loading ? (
        <Loading />
      ) : (
        <SlideUpContainer>
          <Middle>
            <VerticalSpacing size={1}>
              <Text bold size={1}>
                Interview updates
              </Text>
              {showError ? (
                <ErrorCard />
              ) : stillInterviewing ? (
                <StillInterviewingCard />
              ) : interview && showFollowUpQuestion ? (
                <FollowUpQuestionCard interview={interview} onError={onError} />
              ) : interview ? (
                <ThankYou />
              ) : (
                <ErrorCard />
              )}
            </VerticalSpacing>
          </Middle>
        </SlideUpContainer>
      )}
    </PageContainer>
  );
}

function ErrorCard(): React.ReactElement {
  return (
    <Card>
      <VerticalSpacing size={1}>
        <Text bold size={1}>
          Oops, something went wrong!
        </Text>
        <Button as={Link} style={{ width: "100%" }} level="secondary" to="/">
          Go to your dashboard
        </Button>
      </VerticalSpacing>
    </Card>
  );
}

function StillInterviewingCard(): React.ReactElement {
  return (
    <Card>
      <VerticalSpacing size={1}>
        <Tick />
        <Text bold size={1}>
          We&apos;re rooting for you - good luck!
        </Text>
        <Text>
          Remember to update your application progress on your dashboard once
          you reach the end of the process
        </Text>
        <Text>
          While you&apos;re here, why not check out your latest matches?
        </Text>
        <Button as={Link} style={{ width: "100%" }} level="secondary" to="/">
          Go to your dashboard
        </Button>
      </VerticalSpacing>
    </Card>
  );
}

function ThankYou(): React.ReactElement {
  return (
    <Card>
      <VerticalSpacing size={1}>
        <Tick />
        <Text bold size={1}>
          Thanks for letting us know
        </Text>
        <Text>
          We&apos;ll use this information to improve the company data we show to
          candidates
        </Text>
        <Text>
          While you&apos;re here, why not check out your latest matches?
        </Text>
        <Button as={Link} style={{ width: "100%" }} level="secondary" to="/">
          Go to your dashboard
        </Button>
      </VerticalSpacing>
    </Card>
  );
}
