import { useState } from "react";
import styled from "@xstyled/styled-components";

import { Spacing, Paragraph, Clickable, Text } from "@otta/design";
import { Link } from "@otta/search/components/Link";
import { Company, UserJobPreferences } from "@otta/search/schema";

const ClickableText = styled(Clickable)`
  display: inline;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const BrowseAllLink = styled(Link)`
  text-decoration: underline;
`;

interface EmployeeEndorsementsProps {
  company: Pick<
    Company.Fragment,
    | "numberEmployeeSurveys"
    | "responseCountByCategory"
    | "employeeSurveys"
    | "urlSafeName"
    | "name"
  >;
  user?: Pick<UserJobPreferences.CurrentUser, "companySubQualitiesPreferences">;
}

export function EmployeeEndorsements({
  company,
  user,
}: EmployeeEndorsementsProps): React.ReactElement | null {
  if ((company.numberEmployeeSurveys ?? 0) === 0) {
    return null;
  }

  const userPreferredCategories = (
    user?.companySubQualitiesPreferences ?? []
  ).map(category => category.companySubQualityId);

  const categories = company.responseCountByCategory.map(c => c.categoryName);

  const categoryMatches = company.responseCountByCategory
    .filter(c => userPreferredCategories.includes(c.id))
    .map(c => c.categoryName);

  return (
    <Spacing>
      <Text bold as="h3" size={1}>
        Employee endorsements
      </Text>
      <EmployeeEndorsementContent
        company={company}
        categories={categories}
        categoryMatches={categoryMatches}
      />
    </Spacing>
  );
}

interface EmployeeEndorsementContentProps
  extends Omit<EmployeeEndorsementsProps, "user"> {
  categories: string[];
  categoryMatches: string[];
}

function EmployeeEndorsementContent({
  company,
  categories,
  categoryMatches,
}: EmployeeEndorsementContentProps): React.ReactElement {
  const [showFullEndorsement, setShowFullEndorsement] = useState(false);

  const previewEndorsementCategory =
    categoryMatches.length > 0 ? categoryMatches[0] : categories[0];

  const employeeSurveyResponses = company.employeeSurveys.map(
    survey => survey.responses
  );

  const endorsementsWithMatchingCategory = employeeSurveyResponses
    .map(response =>
      response.filter(
        indivResponse =>
          indivResponse.category.name == previewEndorsementCategory
      )
    )
    .filter(response => response.length > 0);

  const previewEndorsement =
    `"${endorsementsWithMatchingCategory[0][0].content}"` ?? " ";

  const shortEndorsement = previewEndorsement?.length < 150;

  const previewEndorsementSnippet = shortEndorsement
    ? previewEndorsement
    : previewEndorsement.substring(
        0,
        previewEndorsement.substring(0, 150).lastIndexOf(" ")
      ) + '..."';

  return (
    <Spacing size={-2}>
      <Text bold data-testid="endorsement-preview-category-heading">
        {previewEndorsementCategory}
      </Text>
      <Spacing size={3}>
        <Paragraph data-testid="endorsement-preview">
          {showFullEndorsement ? (
            previewEndorsement
          ) : shortEndorsement ? (
            previewEndorsementSnippet
          ) : (
            <>
              {previewEndorsementSnippet}{" "}
              <ClickableText
                onClick={() => setShowFullEndorsement(true)}
                data-testid="read-more"
                data-analytics-id="Job Card Endorsement Read More Link"
              >
                more
              </ClickableText>
            </>
          )}
        </Paragraph>
        <BrowseAllLink
          to={`/companies/${encodeURIComponent(
            company.urlSafeName
          )}/endorsements`}
          target="true"
          data-testid="browse-all-endorsements-link"
          data-analytics-id="New Job Card Company Endorsements Link"
          underline
        >
          Browse all endorsements
        </BrowseAllLink>
      </Spacing>
    </Spacing>
  );
}
