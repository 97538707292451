import styled, { css, down } from "@xstyled/styled-components";

import { InfoTextSection, SpotlightCard, DesktopAsset } from "./components";

import { CompanyProfileLinkFragment } from "@otta/search/schema";
import { palette } from "@otta/design-tokens";
import { Heading, Text } from "@otta/design";
import { OttaIcons } from "@otta/icons";

const Content = styled.div`
  display: grid;
  gap: md;
  grid-template-columns: 1fr 1.5fr;

  ${down(
    "desktop",
    css`
      grid-template-columns: 1fr;
    `
  )}
`;

export function Benefits({ asset }: { asset: CompanyProfileLinkFragment }) {
  const spotlightTag = {
    icon: OttaIcons["NewToOtta"],
    text: "Benefits",
    color: palette.brand.orange,
  };

  return (
    <SpotlightCard name="benefit-text" tag={spotlightTag}>
      <Content>
        <DesktopAsset asset={asset} />
        <InfoTextSection>
          <Heading size={2}>{asset.companyBrandAsset.shortTitle}</Heading>
          <Text size={-1}>{asset.companyBrandAsset.longTitle}</Text>
        </InfoTextSection>
      </Content>
    </SpotlightCard>
  );
}
