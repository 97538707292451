import { parseISO } from "date-fns";

import { FormAnswer, FormGreenhouseDemographicAnswer } from "../types";

import {
  GreenhouseAnswersFragment,
  GreenhouseApplicationQuestion,
  GreenhouseDemographicAnswer,
  GreenhouseDemographicQuestion,
  LeverAnswersFragment,
  LeverApplicationQuestion,
  OttaAnswersFragment,
  OttaApplicationQuestion,
  WorkableAnswersFragment,
  WorkableApplicationQuestion,
} from "@otta/search/schema";

type Questions =
  | WorkableApplicationQuestion[]
  | LeverApplicationQuestion[]
  | GreenhouseApplicationQuestion[]
  | OttaApplicationQuestion[];

type Answers =
  | WorkableAnswersFragment["answers"]
  | GreenhouseAnswersFragment["answers"]
  | LeverAnswersFragment["answers"]
  | OttaAnswersFragment["answers"];

const QUESTION_TO_ANSWER_MAP = {
  BooleanQuestion: "BooleanAnswer",
  DateQuestion: "DateAnswer",
  DecimalQuestion: "DecimalAnswer",
  FileQuestion: "FileAnswer",
  MultiSelectQuestion: "MultiSelectAnswer",
  SingleSelectQuestion: "SingleSelectAnswer",
  TextAreaQuestion: "TextAreaAnswer",
  TextQuestion: "TextAnswer",
  UrlQuestion: "UrlAnswer",
  GreenhouseDemographicMultiSelectQuestion:
    "GreenhouseDemographicMultiSelectAnswer",
  GreenhouseDemographicSingleSelectQuestion:
    "GreenhouseDemographicSingleSelectAnswer",
};

export function matchQuestionsToAnswers<Q extends Questions, A extends Answers>(
  questions: Q,
  answers: A
): FormAnswer[] {
  return questions.map(question =>
    matchQuestionToAnswer<Q, A>(question, answers)
  );
}

function matchQuestionToAnswer<Q extends Questions, A extends Answers>(
  question: Q[number],
  answers: A
): FormAnswer {
  for (const answer of answers) {
    if (
      answer.questionData.atsId === question.atsId &&
      QUESTION_TO_ANSWER_MAP[question.__typename] === answer.__typename
    ) {
      switch (answer.__typename) {
        case "BooleanAnswer":
          return {
            questionData: {
              atsId: question.atsId,
              question: question.question,
            },
            value: answer.booleanValue,
            questionType: "BooleanQuestion",
          };
        case "DateAnswer":
          return {
            questionData: {
              atsId: question.atsId,
              question: question.question,
            },
            value: parseISO(answer.dateValue),
            questionType: "DateQuestion",
          };
        case "DecimalAnswer":
          return {
            questionData: {
              atsId: question.atsId,
              question: question.question,
            },
            value: answer.decimalValue,
            questionType: "DecimalQuestion",
          };
        case "TextAnswer":
          return {
            questionData: {
              atsId: question.atsId,
              question: question.question,
            },
            value: answer.value,
            questionType: "TextQuestion",
          };
        case "TextAreaAnswer":
          return {
            questionData: {
              atsId: question.atsId,
              question: question.question,
            },
            value: answer.value,
            questionType: "TextAreaQuestion",
          };
        case "FileAnswer":
          return {
            questionData: {
              atsId: question.atsId,
              question: question.question,
            },
            value: {
              name: answer.name,
              url: answer.url,
            },
            questionType: "FileQuestion",
          };
        case "UrlAnswer":
          return {
            questionData: {
              atsId: question.atsId,
              question: question.question,
            },
            value: answer.value,
            questionType: "UrlQuestion",
          };
        case "SingleSelectAnswer": {
          const { __typename, ...rest } = answer.choice;
          return {
            questionData: {
              atsId: question.atsId,
              question: question.question,
            },
            value: rest,
            questionType: "SingleSelectQuestion",
          };
        }
        case "MultiSelectAnswer":
          return {
            questionData: {
              atsId: question.atsId,
              question: question.question,
            },
            value: answer.choices.map(choice => {
              const { __typename, ...rest } = choice;
              return JSON.stringify(rest);
            }),
            questionType: "MultiSelectQuestion",
          };
      }
    }
  }
  return {
    questionData: {
      atsId: question.atsId,
      question: question.question,
    },
    value: null,
    questionType: question.__typename,
  };
}

export function matchGreenhouseDemographicQuestionsToAnswers(
  questions: GreenhouseDemographicQuestion[],
  answers: GreenhouseDemographicAnswer[]
): FormGreenhouseDemographicAnswer[] {
  return questions.map(question => {
    return matchGreenhouseDemographicQuestionToAnswer(question, answers);
  });
}

function matchGreenhouseDemographicQuestionToAnswer(
  question: GreenhouseDemographicQuestion,
  answers: GreenhouseDemographicAnswer[]
): FormGreenhouseDemographicAnswer {
  for (const answer of answers) {
    if (
      answer.questionData.atsId === question.atsId &&
      QUESTION_TO_ANSWER_MAP[question.__typename] === answer.__typename
    ) {
      switch (answer.__typename) {
        case "GreenhouseDemographicSingleSelectAnswer": {
          const { __typename, ...rest } = answer.choice;
          return {
            questionData: {
              atsId: question.atsId,
              question: question.question,
            },
            value: rest,
            questionType: "GreenhouseDemographicSingleSelectQuestion",
          };
        }
        case "GreenhouseDemographicMultiSelectAnswer":
          return {
            questionData: {
              atsId: question.atsId,
              question: question.question,
            },
            value: answer.choices.map(choice => {
              const { label, value, type } = choice;
              return { label, value, type };
            }),
            questionType: "GreenhouseDemographicMultiSelectQuestion",
          };
      }
    }
  }
  return {
    questionData: {
      atsId: question.atsId,
      question: question.question,
    },
    value: null,
    questionType: question.__typename,
  };
}
