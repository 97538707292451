import { QuestionComponentProps } from "..";

import { NegativeTechnologies } from "./NegativeTechnologies";

import { Spacing, Text } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { PreferenceQuestionWrapper } from "@otta/search/containers/Quiz/components/PreferenceQuestionWrapper";
import { TechnologyPreference } from "@otta/search/schema";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

const NegativeTechnologiesPreferenceQuestion = (
  props: QuestionComponentProps
): React.ReactElement => (
  <Spacing>
    <Text align="center">Which technologies do you want to filter out?</Text>
    <NegativeTechnologies {...props} />
  </Spacing>
);

export function NegativeTechnologiesPreference(
  props: QuestionComponentProps
): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();

  if (!preferencesData) {
    return <Loading />;
  }

  const preferences = preferencesData.technologiesUsedPreferences ?? [];

  const hasPreference = preferences.some(
    ({ technologyPreference: pref }) => pref === TechnologyPreference.Negative
  );

  const handleNoClick = () => {
    const positivePreferences = preferences
      .filter(
        ({ technologyPreference: pref }) =>
          pref === TechnologyPreference.Positive
      )
      .map(({ technologyUsedId, technologyPreference }) => ({
        technologyUsedId,
        technologyPreference,
      }));

    updatePreferences({
      technologiesUsedPreferences: positivePreferences,
    });
  };

  return (
    <PreferenceQuestionWrapper
      {...props}
      hasPreference={hasPreference}
      analyticsNoOptionName="no-negative-technologies-preference"
      analyticsYesOptionName="has-negative-technologies-preference"
      Component={NegativeTechnologiesPreferenceQuestion}
      onNoClick={handleNoClick}
      optionalQuestion
    />
  );
}
