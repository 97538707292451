import { Sector, Theme } from "./theme";

import { ThemeId } from "@otta/search/schema";

type Props = {
  theme: Theme<Sector>;
};

export function CardContent({ theme }: Props): React.ReactElement {
  switch (theme.id) {
    case "ALL_MATCHES":
      return (
        <>
          All your <br /> matches
        </>
      );
    case ThemeId.ApplyViaOtta:
      return (
        <>
          Apply with <br /> your profile
        </>
      );
    case ThemeId.HasSalaries:
      return (
        <>
          Jobs with <br /> salaries
        </>
      );
    case ThemeId.PreferredSector: {
      const words = theme.sector.value.split(" ");
      return theme.sector.value.length >= 20 && words.length > 1 ? (
        <>
          {words[0]} <br /> {[...words].slice(1).join(" ")}
        </>
      ) : (
        <>
          {theme.sector.value} <br /> companies
        </>
      );
    }
    case ThemeId.FemaleFounders:
      return (
        <>
          Led by <br /> women
        </>
      );
    case ThemeId.NewlyAdded:
      return (
        <>
          Jobs added <br /> this week
        </>
      );
    case ThemeId.RecentlyFunded:
      return (
        <>
          Recently <br /> funded
        </>
      );
    case ThemeId.TechForGood:
      return (
        <>
          Tech for <br /> good
        </>
      );

    case ThemeId.FavouriteTechnologies:
      return (
        <>
          Favourite <br /> technologies
        </>
      );
    case ThemeId.FullyRemote:
      return (
        <>
          Fully <br /> remote
        </>
      );
    case ThemeId.TakeAnotherLook:
      return (
        <>
          Take another <br /> look
        </>
      );
  }
}
