import { sortBy } from "@otta/search/utils/collections";
import { compareAlphabetically } from "@otta/search/utils/strings";

interface IRow {
  id: string;
  value: string;
}

export interface IOption {
  value: string;
  label: string;
}

export const mapRowToOptions = (rows: IRow[]): IOption[] =>
  rows.map(({ id, value }) => ({
    value: id,
    label: value,
  }));

export function sortOptionsAlphabetically<T extends { label: string }>(
  options: T[]
): T[] {
  return sortBy(options, x => x.label, compareAlphabetically);
}
