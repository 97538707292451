export function Star({
  colour,
  ...props
}: {
  colour: string;
}): React.ReactElement {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={colour}
        d="M5.64745 4.4148L3.64745 2.4148M19.3546 4.4148L21.3546 2.4148M8.32155 8.76269L1.47255 8.98917C0.537613 9.02009 0.153189 10.2032 0.891399 10.7778L6.29923 14.9867C6.62199 15.2379 6.75924 15.6604 6.64578 16.0533L4.74472 22.6371C4.48521 23.5358 5.49164 24.267 6.2662 23.7425L11.9403 19.9C12.2789 19.6707 12.7231 19.6707 13.0617 19.9L18.7358 23.7425C19.5104 24.267 20.5168 23.5358 20.2573 22.6371L18.3562 16.0533C18.2428 15.6604 18.38 15.2379 18.7028 14.9867L24.1106 10.7778C24.8488 10.2032 24.4644 9.02009 23.5295 8.98917L16.6805 8.76269C16.2717 8.74917 15.9124 8.4881 15.7732 8.10352L13.4413 1.65972C13.123 0.780094 11.879 0.780093 11.5607 1.65972L9.22882 8.10352C9.08965 8.4881 8.73032 8.74917 8.32155 8.76269Z"
      />
    </svg>
  );
}
