import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import AllDone from "../shared/AllDone";
import { PageContainer, SlideUpContainer } from "../shared";

import { Middle, VerticalSpacing } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import {
  UpdateOptInPreferencesDocument,
  OptInStatusEnum,
} from "@otta/search/schema";

export function MarketingOptInStatus(): React.ReactElement {
  const [qs] = useSearchParams();
  const token = qs.get("token");
  const [mutate, { data, loading, error }] = useMutation(
    UpdateOptInPreferencesDocument,
    {
      context: { emailToken: token },
    }
  );

  useEffect(() => {
    mutate({
      variables: { optInStatus: OptInStatusEnum.OptedIn },
    });
  }, [mutate]);

  if (loading) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <SlideUpContainer>
        <Middle>
          <VerticalSpacing>
            {(error || data?.updateOptInPreferences === false) && (
              <AllDone
                text={["There has been an error. Try again later."]}
                analytics={{
                  eventName: "Candidate Shown",
                  name: "End of Double Opt in Flow Error",
                }}
                hasError
              />
            )}
            {!error && data?.updateOptInPreferences === true && (
              <AllDone
                text={["You've signed up to receive our marketing emails."]}
                analytics={{
                  eventName: "Candidate Shown",
                  name: "End of Double Opt in Flow Success",
                }}
              />
            )}
          </VerticalSpacing>
        </Middle>
      </SlideUpContainer>
    </PageContainer>
  );
}
