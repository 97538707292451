import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const InlineText = styled(Text)`
  display: inline;
`;

const ApplyButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: ${pxToRem(16)};
  margin-right: lg;

  svg {
    height: 100%;
    width: 100%;
    path {
      fill: black;
    }
  }
`;

export function ApplyViaOttaButtonContent({
  isApplyViaOtta = true,
}: {
  isApplyViaOtta?: boolean;
}): React.ReactElement {
  return (
    <ApplyButtonContent>
      <IconWrapper>
        <Icon icon="apply" size={1.5} />
      </IconWrapper>
      <Text bold>
        Apply
        {isApplyViaOtta && (
          <InlineText as="span" size={-1}>
            {` with your profile`}
          </InlineText>
        )}
      </Text>
    </ApplyButtonContent>
  );
}
