import { useEffect, useMemo } from "react";
import { useMutation } from "@apollo/client";
import qs from "query-string";
import * as Sentry from "@sentry/browser";
import styled from "@xstyled/styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Spinner } from "@otta/search/components/Loading";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  CurrentUserDocument,
  NavBarStatusDocument,
  UpdateUserPreferencesDocument,
} from "@otta/search/schema";

const SpinnerWrapper = styled.div`
  padding: 100px 0;
`;

export function OAuthCallbacks(): React.ReactElement {
  const location = useLocation();
  const { method } = useParams();
  const navigate = useNavigate();

  const search = useMemo(() => qs.parse(location.search), [location.search]);
  const isSignUp = useMemo(
    () => qs.parse(location.hash).method === "signup",
    [location.hash]
  );

  const { loading, data } = useQuery(CurrentUserDocument);

  useQuery(NavBarStatusDocument, { fetchPolicy: "network-only" });

  const [updateUserPreferences] = useMutation(UpdateUserPreferencesDocument);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!data?.currentUser) {
      navigate(isSignUp ? "/sign-up" : "/login", { replace: true });
      return;
    }

    (async () => {
      try {
        const rawStoredPreferences = localStorage.getItem("user_preferences");
        const storedPreferences =
          rawStoredPreferences && JSON.parse(rawStoredPreferences);

        if (storedPreferences) {
          await updateUserPreferences({
            variables: { input: storedPreferences },
          });
          localStorage.removeItem("user_preferences");
        }
        navigate(typeof search.redirect === "string" ? search.redirect : "/", {
          replace: true,
        });
      } catch (error) {
        Sentry.captureException(error);
        navigate("/initial-preferences", { replace: true });
      }
    })();
  }, [loading, data, search, updateUserPreferences, isSignUp, navigate]);

  useEffect(() => {
    if (data?.currentUser && isSignUp) {
      pushAnalyticsEvent({
        eventName: "candidate-sign-up",
        method,
      });
    }
  }, [data, method, isSignUp]);

  return (
    <SpinnerWrapper>
      <Spinner />
    </SpinnerWrapper>
  );
}
