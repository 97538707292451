import styled, { css, down, up } from "@xstyled/styled-components";

import { Text, Spacing } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { INBOX_WIDTH } from "@otta/search/globalConstants";

const Centered = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  @media (max-width: 500px) {
    display: none;
  }
  ${down(
    "desktop",
    css`
      margin: lg lg 16 ${INBOX_WIDTH}px;
    `
  )}
  ${up(
    "desktop",
    css`
      margin-left: 0;
      margin-bottom: lg;
    `
  )}
`;

export function MessagePlaceholder(): React.ReactElement {
  return (
    <Centered
      style={{
        backgroundColor: palette.brand.white,
        borderRadius: 4,
      }}
    >
      <Spacing>
        <Text size={2} bold>
          No message selected
        </Text>
        <Text>Select a message to read</Text>
      </Spacing>
    </Centered>
  );
}
