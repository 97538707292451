export function Link(): React.ReactElement {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.2472 3.89744C6.8731 2.27068 9.50866 2.27068 11.1346 3.89744C12.6823 5.44522 12.7662 7.93034 11.3226 9.57938L11.1693 9.75586C10.9147 10.0452 10.4778 10.0741 10.1885 9.8224C9.89922 9.5707 9.87029 9.13096 10.122 8.84165L10.2782 8.66518C11.2387 7.56582 11.1837 5.911 10.1509 4.87818C9.06892 3.77014 7.31284 3.77014 6.2048 4.87818L2.95099 8.156C1.86784 9.24089 1.86784 10.997 2.95099 12.079C3.98294 13.0858 5.64066 13.1668 6.73712 12.2063L6.91649 12.05C7.2029 11.7983 7.64265 11.8273 7.89434 12.1166C8.14893 12.403 8.11711 12.8427 7.83069 13.0944L7.65133 13.2507C6.00229 14.6914 3.51716 14.6104 1.96909 13.0597C0.343636 11.4367 0.343636 8.77511 1.96909 7.17526L5.2472 3.89744ZM13.7528 11.1011C12.1269 12.727 9.4913 12.727 7.86541 11.1011C6.29159 9.52731 6.23373 7.06822 7.67447 5.41918L7.81044 5.26296C8.06214 4.97365 8.50188 4.94472 8.79119 5.19641C9.08049 5.44811 9.10942 5.88785 8.85773 6.17716L8.72175 6.33338C7.75837 7.43274 7.81623 9.08756 8.84615 10.1204C9.93105 11.2024 11.6871 11.2024 12.772 10.1204L16.0498 6.84256C17.1318 5.75767 17.1318 4.00159 16.0498 2.91959C15.017 1.88735 13.3593 1.83209 12.2628 2.79374L12.0835 2.94852C11.7971 3.20021 11.3573 3.17128 11.1056 2.88314C10.851 2.5947 10.8829 2.15582 11.1693 1.90355L11.3486 1.74848C12.9977 0.305662 15.4828 0.388461 17.0306 1.93769C18.6565 3.56184 18.6565 6.19741 17.0306 7.8233L13.7528 11.1011Z"
        fill="black"
      />
    </svg>
  );
}
