import styled, { css, down } from "@xstyled/styled-components";

import { Asset } from "@otta/search/components/AssetSprinkler/Asset";

export const DesktopAsset = styled(Asset)`
  ${down(
    "desktop",
    css`
      display: none;
    `
  )}
`;
