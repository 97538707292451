export const required = <T = unknown>(value: T): string | undefined =>
  value ? undefined : "Fill in this field";

export const requiredField =
  (fieldName: string) =>
  <T = unknown>(value: T): string | undefined =>
    value ? undefined : `Enter your ${fieldName}`;

export const minLength =
  (min: number) =>
  <T = unknown>(value: T): string | undefined =>
    !value || (typeof value === "string" && value.length >= min)
      ? undefined
      : `Enter at least ${min} character${min > 1 ? "s" : ""}`;

export const maxLength =
  (max: number) =>
  <T = unknown>(value: T): string | undefined =>
    !value || (typeof value === "string" && value.length <= max)
      ? undefined
      : `Enter at most ${max} character${max > 1 ? "s" : ""}`;

export const minMaxLength =
  (min: number, max: number) =>
  <T = unknown>(value: T): string | undefined =>
    typeof value === "string" && value.length <= max && value.length >= min
      ? undefined
      : `Enter between ${min}-${max} characters to balance detail with being concise`;

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validEmail = <T = unknown>(value: T): string | undefined => {
  return typeof value === "string" && emailRegex.test(value)
    ? undefined
    : "Enter a valid email address";
};

const urlRegex =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/i;

export const validUrl = <T = unknown>(value: T): string | undefined =>
  !value || (typeof value === "string" && urlRegex.test(value))
    ? undefined
    : "Enter a valid URL";

const linkedinUrlRegex =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[-%\p{L}\p{M}0-9]{3,100}\/?$/iu;

export const validLinkedinProfileUrl = <T = unknown>(
  value: T
): string | undefined =>
  value === undefined ||
  (typeof value === "string" && linkedinUrlRegex.test(value))
    ? undefined
    : "Enter a valid profile URL";

export const composeValidators =
  <T = unknown>(...validators: ((value: T) => string | undefined)[]) =>
  (value: T): string | undefined =>
    validators.reduce<string | undefined>(
      (error, validator) => error || validator(value),
      undefined
    );
