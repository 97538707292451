import { Field } from "react-final-form";
import { ReactElement } from "react";

import { NumberQuestion } from "../types";

import { Labelled } from "./Label";

import { Input } from "@otta/design";

type FieldProps = {
  question: NumberQuestion;
  sectionId: string;
};

function validate(value?: string): string | undefined {
  return value && value?.trim() !== "" && Number.isNaN(Number.parseFloat(value))
    ? "Enter a number"
    : undefined;
}

export function NumberField({ question, sectionId }: FieldProps): ReactElement {
  const elementId = `${sectionId}_${question.id}`;
  return (
    <Labelled
      htmlFor={elementId}
      required={question.required}
      value={question.label}
    >
      <Field<string | undefined, HTMLElement, string | undefined>
        name={`s_${sectionId}.q_${question.id}.a_0.decimalValue`}
        defaultValue={question.numberDefault ?? undefined}
        initialValue={question.numberAnswer ?? undefined}
        validate={value => validate(value)}
        type={"number"}
        render={({ input, meta }) => (
          <Input {...input} error={meta.touched && meta.error} id={elementId} />
        )}
      />
    </Labelled>
  );
}
