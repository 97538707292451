import { Component } from "react";

import { List } from "@otta/design";
import { modularScale } from "@otta/design-tokens";

interface ITip {
  value: string | React.ReactNode;
}

interface ITipsListProps {
  tips: ITip[];
}

export class TipsList extends Component<ITipsListProps> {
  public render(): React.ReactElement {
    const { tips } = this.props;
    return (
      <List type="positive" style={{ padding: modularScale(-2) }}>
        {tips.map((tip, index) => (
          <li key={`tip-${index}`}>{tip.value}</li>
        ))}
      </List>
    );
  }
}
