import { useCallback, useRef, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  Button as DefaultButton,
  Heading,
  InputField,
  Spacing,
  PasswordField,
} from "@otta/design";
import {
  composeValidators,
  maxLength,
  minLength,
  required,
  validEmail,
} from "@otta/search/utils/validators";
import {
  ChangePasswordDocument,
  ForgotPasswordDocument,
} from "@otta/search/schema";
import { SectionWrapper } from "@otta/search/pages/Login";
import { handleMutationError } from "@otta/search/utils/error";

const Button = styled(DefaultButton)`
  width: 100%;
`;

function ResetPasswordForm({
  done,
  onCompleted,
  token,
}: {
  done: boolean;
  onCompleted: () => void;
  token: string;
}) {
  const navigate = useNavigate();
  const [changePasswordMutation, { loading }] = useMutation(
    ChangePasswordDocument,
    {
      onCompleted: onCompleted,
      onError: handleMutationError,
    }
  );

  return (
    <FinalForm<{ password: string }>
      onSubmit={async values =>
        changePasswordMutation({
          variables: { ...values, token: token },
        })
      }
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete="on">
          <Spacing size={2}>
            <Field
              name="password"
              validate={composeValidators(
                required,
                minLength(8),
                maxLength(200)
              )}
            >
              {({ input, meta }) => (
                <PasswordField
                  {...input}
                  name="password"
                  label="New password"
                  autoComplete="new-password"
                  autoFocus
                  requirements={[
                    {
                      text: "Enter at least 8 characters",
                      validate: (input?: string) =>
                        input ? input.length >= 8 : false,
                    },
                    ...(input?.value?.length > 200
                      ? [
                          {
                            text: "Enter at most 200 characters",
                            validate: () => false,
                          },
                        ]
                      : []),
                  ]}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <Button
              type={done ? "button" : "submit"}
              level={done ? "secondary" : "primary"}
              onClick={done ? () => navigate("/login") : undefined}
            >
              {done
                ? "Done! Click to login"
                : loading
                ? "Loading"
                : "Change password"}
            </Button>
          </Spacing>
        </form>
      )}
    />
  );
}

function ForgotPasswordForm({
  done,
  onCompleted,
}: {
  done: boolean;
  onCompleted: () => void;
}) {
  const [loginMutation, { loading }] = useMutation(ForgotPasswordDocument, {
    onCompleted: onCompleted,
    onError: handleMutationError,
  });

  return (
    <FinalForm<{ email: string }>
      onSubmit={async values => loginMutation({ variables: values })}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Spacing size={2}>
            <Field
              name="email"
              validate={composeValidators(required, validEmail, minLength(5))}
            >
              {({ input, meta }) => (
                <InputField
                  {...input}
                  type="email"
                  name="email"
                  label="Email"
                  autoFocus
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <Button
              type={done ? "button" : "submit"}
              level={done ? "secondary" : "primary"}
            >
              {done
                ? "Done! Check your email"
                : loading
                ? "Loading"
                : "Reset password"}
            </Button>
          </Spacing>
        </form>
      )}
    />
  );
}

export function ForgotPassword(): React.ReactElement {
  const [searchParams] = useSearchParams();

  const [done, setDone] = useState(false);
  const token = useRef(searchParams.get("token"));

  const onCompleted = useCallback(() => setDone(true), []);

  return (
    <SectionWrapper>
      <Spacing size={3}>
        <Heading size={4} align="center">
          {token.current ? "Change your password" : "Forgot your password?"}
        </Heading>
        {token.current ? (
          <ResetPasswordForm
            done={done}
            onCompleted={onCompleted}
            token={token.current}
          />
        ) : (
          <ForgotPasswordForm done={done} onCompleted={onCompleted} />
        )}
      </Spacing>
    </SectionWrapper>
  );
}
