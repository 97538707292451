import { useMutation } from "@apollo/client";
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { formatIncomingData } from "./lib";
import { UserPreferencesContext } from "./UserPreferencesContext";

import { useQuery } from "@otta/search/apollo";
import { EventProvider } from "@otta/search/providers/EventProvider";
import {
  NotificationFrequency,
  UpdateUserPreferencesDocument,
  UserInput,
  UserPreferencesDocument,
} from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";

const DEFAULT_PREFERENCES = {
  jobEmailNotificationsFrequency: NotificationFrequency.Weekly,
};

const DEBOUNCE_TIME = 500;

export const UserPreferencesProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { data: rawRemoteData, loading } = useQuery(UserPreferencesDocument);

  const [onlyRemoteOpportunities, setOnlyRemoteOpportunities] = useState(false);
  const [cachedData, setCachedData] = useState<UserInput | undefined>(
    undefined
  );
  const [localData, setLocalData] = useState<UserInput>();

  const remoteData = useMemo(
    () =>
      rawRemoteData?.currentUser
        ? formatIncomingData(rawRemoteData.currentUser)
        : undefined,
    [rawRemoteData?.currentUser]
  );

  useEffect(() => {
    const rawData = localStorage.getItem("user_preferences");
    if (rawData) {
      setLocalData(JSON.parse(rawData));
    }
  }, []);

  const preferencesData = useMemo<UserInput>(() => {
    return cachedData ?? remoteData ?? localData ?? DEFAULT_PREFERENCES;
  }, [cachedData, remoteData, localData]);

  useEffect(() => {
    setOnlyRemoteOpportunities(
      preferencesData?.locationPreferences?.every(l =>
        l.location.includes("REMOTE")
      ) ?? false
    );
  }, [preferencesData?.locationPreferences]);

  const [updateUserPreferences] = useMutation(UpdateUserPreferencesDocument, {
    onError: error => {
      // Reset to remote data if mutation fails
      setCachedData(remoteData);
      handleMutationError(error);
    },
  });

  const loggedIn = useMemo(
    () => Boolean(rawRemoteData?.currentUser),
    [rawRemoteData?.currentUser]
  );

  const timer = useRef<NodeJS.Timeout>();

  // After sign up
  useEffect(() => {
    if (loggedIn && localStorage.getItem("user_preferences")) {
      cachedData && updateUserPreferences({ variables: { input: cachedData } });
      localStorage.removeItem("user_preferences");
    }
  }, [loggedIn, cachedData, updateUserPreferences]);

  const handleUpdate = useCallback(
    (input: UserInput) => {
      const data = { ...preferencesData, ...input };
      setCachedData(data);

      if (loggedIn) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          updateUserPreferences({ variables: { input: data } });
        }, DEBOUNCE_TIME);
      } else {
        localStorage.setItem("user_preferences", JSON.stringify(data));
      }
    },
    [loggedIn, preferencesData, updateUserPreferences]
  );

  const hasSetPreferences =
    rawRemoteData?.currentUser?.hasSetPreferences ?? false;

  return (
    <EventProvider>
      <UserPreferencesContext.Provider
        value={{
          loading,
          hasSetPreferences,
          preferencesData,
          updatePreferences: handleUpdate,
          loggedIn,
          onlyRemoteOpportunities,
          setOnlyRemoteOpportunities,
        }}
      >
        {children}
      </UserPreferencesContext.Provider>
    </EventProvider>
  );
};
