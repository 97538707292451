type Widths =
  | 10
  | 20
  | 30
  | 40
  | 50
  | 75
  | 100
  | 120
  | 140
  | 160
  | 180
  | 200
  | 225
  | 250
  | 275
  | 300
  | 325
  | 350
  | 375
  | 400
  | 450
  | 500
  | 550
  | 600
  | 650
  | 700
  | 800
  | 900
  | 1000
  | 1200
  | 1400
  | 1600
  | 1800
  | 2000
  | 2500
  | 3000
  | 4000;

type Heights =
  | 50
  | 100
  | 150
  | 200
  | 250
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 1000
  | 1500
  | 2000;

export function imgProxy(
  path: string,
  dimensions: { width: Widths } | { height: Heights },
  extension?: "png" | "jpg" | "webp"
): string {
  const baseUrl = "https://images.otta.com/search";
  const url =
    "width" in dimensions
      ? `${baseUrl}/width_${dimensions.width}/${path}`
      : `${baseUrl}/height_${dimensions.height}/${path}`;

  if (extension) {
    return `${url}.${extension}`;
  }

  return url;
}
