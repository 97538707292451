import { ReactElement, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";

import { LogoWrapper } from "../shared";
import AnimatedCard from "../shared/AnimatedCard";

import { JobApplication } from "./types";

import { modularScale } from "@otta/design-tokens";
import { VerticalSpacing, Button, Text } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import CompanyLogo from "@otta/search/components/CompanyLogo";
import { UpdateJobApplicationExternalRespondedDocument } from "@otta/search/schema";

interface IDidYouHearBackFromProps {
  jobApplication: JobApplication;
  onComplete: () => void;
}

export default function DidYouHearBackFrom({
  jobApplication,
  onComplete,
}: IDidYouHearBackFromProps): ReactElement {
  const location = useLocation();

  const [mutate, { loading }] = useMutation(
    UpdateJobApplicationExternalRespondedDocument
  );

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Candidate Shown",
      pathname: location.pathname,
      name: "Application Response External Responded Flow",
    });
  }, [jobApplication.externalId, location.pathname]);

  const handleResponse = (response: boolean) => async () => {
    await mutate({
      variables: {
        externalId: jobApplication.externalId,
        externalResponded: response,
      },
    });

    pushAnalyticsEvent({
      eventName: "Candidate Responded to External Responded Flow",
      response: response ? "Yes" : "No",
    });

    onComplete();
  };

  return (
    <AnimatedCard>
      <VerticalSpacing>
        <Text bold align="center" style={{ marginBottom: modularScale(3) }}>
          You also applied to:
        </Text>
        <LogoWrapper>
          <CompanyLogo company={jobApplication.company} />
        </LogoWrapper>
        <Text bold size={1} align="center">
          {jobApplication.company.name}
        </Text>
        <Text align="center">{jobApplication.job.title}</Text>
        <Text align="center" bold style={{ marginTop: modularScale(3) }}>
          What happened next?
        </Text>
        <Button
          data-analytics-id="external-responded-yes"
          style={{ width: "100%" }}
          level="secondary"
          onClick={handleResponse(true)}
          disabled={loading}
        >
          My application&apos;s progressing
        </Button>
        <Button
          data-analytics-id="external-responded-yes"
          style={{ width: "100%" }}
          level="secondary"
          onClick={handleResponse(true)}
          disabled={loading}
        >
          It didn&apos;t work out
        </Button>
        <Button
          data-analytics-id="external-responded-no"
          style={{ width: "100%" }}
          level="secondary"
          onClick={handleResponse(false)}
          disabled={loading}
        >
          I didn&apos;t hear back
        </Button>
      </VerticalSpacing>
    </AnimatedCard>
  );
}
