import { useOutletContext } from "react-router-dom";
import { useUp } from "@xstyled/styled-components";
import { useInView } from "react-intersection-observer";
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";

import { JobCardContext } from "../JobHeader";

import { DesktopContent } from "./DesktopContent";
import { MobileContent } from "./MobileContent";

import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  BrandAssetType,
  JobFragment,
  ProfileType,
  TeamMemberRole,
} from "@otta/search/schema";
import {
  peopleBreakdownTracking,
  useEBTracking,
} from "@otta/search/contexts/EBTracking";

export function JobContent(): React.ReactElement | null {
  const data = useOutletContext<JobCardContext | undefined>();
  const [size, setSize] = useState<"desktop" | "mobile" | null>(null);
  const isTablet = useUp("tablet");

  const job = data?.job;
  const user = data?.user;
  const trackingData = useEBTracking();

  useEffect(() => {
    setSize(isTablet ? "desktop" : "mobile");
  }, [size, isTablet]);

  /**
   * We show two different job cards depending on screen width, of course we do.
   * As such on the server we either need to render nothing, or pick one.
   * I've chosen to render the mobile HTML by default but with display: none
   * so desktop users won't see anything before the useEffect above kicks in
   */
  const mobileStyle: CSSProperties = useMemo(
    () => (size === "mobile" ? {} : { display: "none" }),
    [size]
  );

  useEffect(() => {
    if (
      !job ||
      job.company.profileTypeName !== ProfileType.Enhanced ||
      trackingData.loginStatus === null
    ) {
      return;
    }

    const teamManager = job.teamMembers?.find(
      teamMember => teamMember.role === TeamMemberRole.Manager
    );

    const videos = job.team?.brandAssetLinks
      .flatMap(link => link.companyBrandAsset)
      .filter(asset => asset.type === BrandAssetType.Vimeo)
      .map(asset => asset.category);

    pushAnalyticsEvent({
      eventName: "Candidate Viewed Job With EB Content",
      companyId: trackingData.companyId,
      jobId: trackingData.jobId,
      jobFunction: trackingData.jobFunction,
      jobSubFunction: trackingData.jobSubFunction,
      jobValueClassification: trackingData.jobValueClassification,
      minYearsExperienceRequired: trackingData.minYearsExperienceRequired,
      maxYearsExperienceRequired: trackingData.maxYearsExperienceRequired,
      applyViaOtta: trackingData.applyViaOtta,
      loginStatus: trackingData.loginStatus,
      videos,
      teamMission: !!job.team?.mission,
      teamSize: !!job.team?.size,
      yourTeamManager: !!teamManager,
      yourTeamReports: job.teamMembers?.some(
        teamMember => teamMember.role === TeamMemberRole.Report
      ),
      managerPrompts: !!teamManager?.member.prompts?.length,
      peopleBreakdown: peopleBreakdownTracking(job.company?.ebStatistics),
    });
  }, [
    job,
    trackingData.applyViaOtta,
    trackingData.companyId,
    trackingData.jobFunction,
    trackingData.jobId,
    trackingData.jobSubFunction,
    trackingData.jobValueClassification,
    trackingData.loginStatus,
    trackingData.maxYearsExperienceRequired,
    trackingData.minYearsExperienceRequired,
  ]);

  if (!data || !job) {
    return <Loading />;
  }

  const { expandOtherJobs, otherJobsExpanded, toggleKeyboardShortcuts } = data;

  if (size === "desktop") {
    return (
      <DesktopContent
        job={job}
        user={user}
        expandOtherJobs={expandOtherJobs}
        otherJobsExpanded={otherJobsExpanded}
        toggleKeyboardShortcuts={toggleKeyboardShortcuts}
      />
    );
  }
  return (
    <MobileContent
      job={job}
      user={user}
      expandOtherJobs={expandOtherJobs}
      otherJobsExpanded={otherJobsExpanded}
      style={mobileStyle}
    />
  );
}

export const useTrackEBContent = (job: JobFragment) => {
  const triggeredJobIdRef = useRef<string | null>(null);

  const [meetTheTeamSectionRef, isMeetTheTeamSectionInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [peopleBreakdownRef, isPeopleBreakdownInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const isEBContentInView =
    isMeetTheTeamSectionInView || isPeopleBreakdownInView;

  useEffect(() => {
    // We want to trigger this once per job but it would trigger twice since we have
    // isMeetTheTeamSectionInView & isPeopleBreakdownInView in the dependency array
    if (!isEBContentInView || triggeredJobIdRef.current === job.id) return;
    triggeredJobIdRef.current = job.id;

    const firstContentShown = isMeetTheTeamSectionInView
      ? "meet-the-team"
      : "people-breakdown";

    pushAnalyticsEvent({
      eventName: "Candidate Shown Job EB Content",
      companyId: job.company.id,
      jobId: job.id,
      firstContentShown,
    });
  }, [isEBContentInView, isMeetTheTeamSectionInView, job.company.id, job.id]);

  return {
    meetTheTeamSectionRef,
    peopleBreakdownRef,
  };
};
