export const Pencil = (): React.ReactElement => (
  <svg
    width="13"
    height="17"
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.77629 12.5127L10.6254 4.76624C10.8761 4.46353 11.0629 4.11316 11.1745 3.73627C11.2861 3.35939 11.3202 2.9638 11.2748 2.57337C11.2293 2.18295 11.1052 1.80578 10.91 1.46463C10.7147 1.12348 10.4524 0.825415 10.1388 0.588424C9.82525 0.351433 9.4669 0.180428 9.08542 0.0857248C8.70393 -0.00897785 8.30722 -0.0254144 7.91921 0.0374073C7.5312 0.100229 7.15993 0.241006 6.82782 0.451245C6.49571 0.661484 6.20963 0.936826 5.98685 1.26066L0.133868 8.99544C0.0576188 9.09594 0.0160873 9.21849 0.0155282 9.34464L8.19779e-06 13.2246C-0.00047452 13.3156 0.0203673 13.4054 0.0608619 13.4868C0.101356 13.5683 0.160376 13.6391 0.233186 13.6936C0.305996 13.7481 0.39057 13.7848 0.480121 13.8007C0.569673 13.8167 0.66171 13.8114 0.748848 13.7853L4.47171 12.7067C4.59124 12.6759 4.6979 12.608 4.77629 12.5127ZM6.91029 1.96682C7.19781 1.62477 7.60553 1.40598 8.0495 1.35548C8.49347 1.30498 8.93991 1.42663 9.2969 1.69536C9.6539 1.9641 9.89427 2.35948 9.96853 2.8001C10.0428 3.24072 9.94531 3.69305 9.69613 4.06396L9.65733 4.11634L6.87149 2.0095L6.91029 1.96682ZM1.16789 12.4525V10.5125L3.02447 11.9171L1.16789 12.4525Z"
      fill="black"
    />
    <path
      d="M11.8498 14.8407H0.624969C0.470613 14.8407 0.322579 14.902 0.213433 15.0112C0.104286 15.1203 0.0429688 15.2683 0.0429688 15.4227C0.0429688 15.5771 0.104286 15.7251 0.213433 15.8342C0.322579 15.9434 0.470613 16.0047 0.624969 16.0047H11.8498C12.0042 16.0047 12.1522 15.9434 12.2613 15.8342C12.3705 15.7251 12.4318 15.5771 12.4318 15.4227C12.4318 15.2683 12.3705 15.1203 12.2613 15.0112C12.1522 14.902 12.0042 14.8407 11.8498 14.8407Z"
      fill="black"
    />
  </svg>
);
