import "react-image-crop/dist/ReactCrop.css";

import { DropzoneRootProps, FileRejection, useDropzone } from "react-dropzone";
import ReactCrop, { Crop, makeAspectCrop, PixelCrop } from "react-image-crop";
import styled from "@xstyled/styled-components";
import { useCallback, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";

import { palette, pxToRem } from "@otta/design-tokens";
import { Button, Spacing, Text } from "@otta/design";
import { EmptyState } from "@otta/search/components/EmptyState";
import { IconButton as DefaultIconButton } from "@otta/search/components/IconButton";
import { Delete } from "@otta/search/components/Icons/Delete";
import { FileUpload } from "@otta/search/components/Icons/FileUpload";

const DropzoneContainer = styled.div<DropzoneRootProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 4;
  border: ${pxToRem(2)} dashed ${palette.grayscale.shade200};
  outline: none;
  border-color: ${props => getColor(props)};
  transition: default;
`;

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const ImagePreview = styled.img`
  max-width: 100%;
`;

const CropContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: xxl 40 19;
`;

const IconButton = styled(DefaultIconButton)`
  position: absolute;
  align-self: flex-end;
  padding: md md 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const getColor = (props: DropzoneRootProps): string => {
  if (props.isDragAccept) {
    return palette.extended.green.shade400;
  }
  if (props.isDragReject) {
    return palette.brand.red;
  }
  if (props.isDragActive) {
    return palette.brand.blue;
  }
  return palette.brand.grey;
};

const createImage = (
  imageRef: HTMLImageElement,
  crop: Crop,
  minWidth: number
) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const aspectRatio = crop.width / crop.height;
  const rescaledNaturalWidth =
    imageRef.naturalWidth < minWidth ? minWidth : imageRef.naturalWidth;
  const rescaledNaturalHeight =
    imageRef.naturalWidth < minWidth
      ? minWidth * aspectRatio
      : imageRef.naturalHeight;
  const scaleX = rescaledNaturalWidth / imageRef.width;
  const scaleY = rescaledNaturalHeight / imageRef.height;
  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;

  ctx.drawImage(
    imageRef,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Canvas is empty"));
        }
      },
      "image/jpeg",
      1
    );
  });
};

interface ICroppedImageUploadFieldProps {
  value: File | string;
  text: string;
  onChange: (file: File | null) => void;
  onBlur: React.FocusEventHandler<HTMLElement>;
  minWidth: number;
  aspectRatio: number;
  fileSizeMaxKb?: number;
  error?: any;
}

export function CroppedImageUploadField({
  value,
  text,
  fileSizeMaxKb = 5000,
  minWidth,
  aspectRatio,
  onBlur,
  onChange,
}: ICroppedImageUploadFieldProps): React.ReactElement {
  const [crop, setCrop] = useState<Crop>();
  const [imgSrc, setImgSrc] = useState<string>();
  const [cropSaved, setCropSaved] = useState(false);
  const [error, setError] = useState<string>();

  const imageRef = useRef<HTMLImageElement>(null);

  const previewSrc = useMemo(() => {
    if (typeof value === "string") {
      return value;
    }

    if (value instanceof File) {
      return URL.createObjectURL(value);
    }

    return undefined;
  }, [value]);

  const saveFile = useCallback(async () => {
    setCropSaved(true);

    if (imageRef.current && crop) {
      const blob = await createImage(imageRef.current, crop, minWidth);

      onChange(new File([blob], "image.jpeg", { type: "image/jpeg" }));
    }
  }, [crop, minWidth, onChange]);

  const handleDrop = useCallback(
    (newFile: File[], rejectedFile: FileRejection[]) => {
      setError(undefined);

      if (rejectedFile.length === 1) {
        toast.error(
          `File exceeds ${Math.round(
            fileSizeMaxKb / 1000
          )}MB size limit. Use a smaller file.`
        );
      }

      setCrop(undefined);

      if (newFile[0]) {
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          setImgSrc(reader.result?.toString());
        });

        reader.readAsDataURL(newFile[0]);
      }
    },
    [fileSizeMaxKb]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    onDrop: handleDrop,
    maxSize: fileSizeMaxKb * 1024,
    accept: { "image/png": [], "image/jpeg": [] },
    noClick: true,
  });

  const handleImageLoaded = useCallback<
    React.ReactEventHandler<HTMLImageElement>
  >(
    e => {
      const { naturalWidth, naturalHeight } = e.currentTarget;

      const maxWidth = Math.min(naturalWidth, naturalHeight * aspectRatio);

      if (maxWidth >= minWidth) {
        setCrop(
          makeAspectCrop(
            {
              unit: "px",
              width: minWidth,
            },
            aspectRatio,
            naturalWidth,
            naturalHeight
          )
        );
      } else {
        setError("Your image should be at least 300px wide");
        setImgSrc(undefined);
        setCrop(undefined);
      }
    },
    [aspectRatio, minWidth]
  );

  const handleCropChange = useCallback(
    (crop: PixelCrop) => {
      if (crop.width >= minWidth) {
        setCrop(crop);
      }
    },
    [minWidth]
  );

  return (
    <DropzoneContainer
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      })}
      onBlur={onBlur}
    >
      <input {...getInputProps()} />
      {!imgSrc && !previewSrc ? (
        <EmptyState
          button={
            <Spacing size={1}>
              <Button
                onClick={() => {
                  open();
                }}
                level="secondary"
                type="button"
              >
                <FileUpload
                  style={{
                    fill: palette.brand.black,
                    height: pxToRem(13),
                    paddingRight: pxToRem(8),
                  }}
                />
                Upload
              </Button>
            </Spacing>
          }
          message={error ?? text}
          error={!!error}
        />
      ) : (
        <Spacing size={-4}>
          <IconButton
            colour={palette.extended.red.shade500}
            hoverColour={palette.brand.red}
            onClick={() => {
              setCropSaved(false);
              setImgSrc(undefined);
              onChange(null);
            }}
          >
            <Delete />
          </IconButton>
          <CropContainer>
            <Spacing size={-1}>
              {!cropSaved && imgSrc ? (
                <Spacing size={-2}>
                  <PreviewContainer>
                    <ReactCrop
                      crop={crop}
                      onChange={handleCropChange}
                      aspect={aspectRatio}
                      ruleOfThirds
                      keepSelection
                    >
                      <ImagePreview
                        ref={imageRef}
                        src={imgSrc}
                        onLoad={handleImageLoaded}
                      />
                    </ReactCrop>
                  </PreviewContainer>
                  {imgSrc && crop && (
                    <>
                      <Text
                        align="center"
                        size={-1}
                        style={{ color: palette.brand.red }}
                      >
                        Save your crop or remove your image
                      </Text>
                      <ButtonWrapper>
                        <Button
                          onClick={saveFile}
                          level="secondary"
                          type="button"
                        >
                          Save crop
                        </Button>
                      </ButtonWrapper>
                    </>
                  )}
                </Spacing>
              ) : (
                <PreviewContainer>
                  <ImagePreview src={previewSrc} />
                </PreviewContainer>
              )}
            </Spacing>
          </CropContainer>
        </Spacing>
      )}
    </DropzoneContainer>
  );
}
