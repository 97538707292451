import { useCallback, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";

import { UserProfileViewedDocument } from "@otta/search/schema";

const EVENTS = ["blur", "focus", "visibilitychange"] as const;

export function useProfileTracking(jobApplicationId: string): void {
  const startTime = useRef(Date.now());

  const isWindowActive = useRef(true);

  const [mutate, { data }] = useMutation(UserProfileViewedDocument, {
    variables: { jobApplicationId },
  });

  useEffect(() => {
    mutate();
  }, [mutate]);

  const ping = useCallback(() => {
    if (data?.userProfileViewed?.externalId) {
      const activeTime = Math.ceil((Date.now() - startTime.current) / 1000);

      const params = new URLSearchParams({
        t: activeTime.toString(),
        v: data.userProfileViewed.externalId,
      });

      navigator.sendBeacon(`${import.meta.env.VITE_API_HOST}/beacon/s`, params);
    }
  }, [data?.userProfileViewed?.externalId]);

  const handleChange = useCallback(() => {
    const isActive =
      document.hasFocus() && document.visibilityState === "visible";

    if (isActive && !isWindowActive.current) {
      startTime.current = Date.now();
    } else if (!isActive && isWindowActive.current) {
      ping();
    }

    isWindowActive.current = isActive;
  }, [ping]);

  useEffect(() => {
    EVENTS.forEach(event => {
      window.addEventListener(event, handleChange, {
        capture: true,
      });
    });

    return () => {
      ping();
      EVENTS.forEach(event => {
        window.removeEventListener(event, handleChange, {
          capture: true,
        });
      });
    };
  }, [handleChange, ping]);
}
