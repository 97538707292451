import { Field } from "react-final-form";

import { InputField } from "@otta/design";
import { validUrl } from "@otta/search/utils/validators";

export function EditUrlField({ name }: { name: string }): React.ReactElement {
  return (
    <Field
      name={name}
      validate={validUrl}
      render={({ input, meta }) => {
        return (
          <InputField
            {...input}
            type="text"
            placeholder="https://personalblog.com"
            error={meta.touched && meta.error}
          />
        );
      }}
    />
  );
}
