import styled from "@xstyled/styled-components";

import { Question, SelectQuestion } from "./types";
import { Labelled } from "./fields/Label";

import { Text } from "@otta/design";
import { CardTitle } from "@otta/search/pages/Profile/components/FormHeader";
import { HeaderWrapper } from "@otta/search/pages/Profile/PublicProfile/components/Section";

const WrappedText = styled(Text)`
  line-break: auto;
`;

const QuestionContainer = styled.div`
  white-space: pre-wrap;
`;

export const ViewQuestion = ({
  question,
  section: sectionId,
}: {
  question: Question;
  section: string;
}): React.ReactElement => {
  const elementId = `${sectionId}_${question.id}`;

  return (
    <QuestionContainer>
      <HeaderWrapper>
        <CardTitle bold>
          <Labelled
            required={question.required}
            htmlFor={elementId}
            value={question.label}
          />
        </CardTitle>
      </HeaderWrapper>
      <WrappedText>{answerValue(question)}</WrappedText>
    </QuestionContainer>
  );
};

const answerValue = (question: Question) => {
  switch (question.__typename) {
    case "NumberAtsQuestion":
      return (
        question.numberAnswer ??
        question.numberDefault ??
        "Type your answer here..."
      );
    case "TextAtsQuestion":
      return (
        question.textAnswer ??
        question.textDefault ??
        "Type your answer here..."
      );
    case "BooleanAtsQuestion":
      return question.booleanAnswer === true
        ? "Yes"
        : question.booleanAnswer === false
        ? "No"
        : "Select...";
    case "DateTimeAtsQuestion":
      return (
        question.dateTimeAnswer ??
        question.dateTimeDefault ??
        "Type your answer here..."
      );
    case "SelectAtsQuestion":
      return (
        getSelectAnswers(question) ??
        (question.options.some(o => o.allowMultiple)
          ? "Check all that apply..."
          : "Choose an option...")
      );
    case "ConsentAtsQuestion":
      return question.consentAnswer ? "Accepted" : "Choose an option...";
    case "FileAtsQuestion":
      return question.fileAnswer
        ? question.fileAnswer.name
        : "Upload a file...";
    default:
      return null;
  }
};

const getSelectAnswers = (question: SelectQuestion) => {
  const selectionMap = new Map(
    question.options.map(({ localId, label }) => [localId, label])
  );

  if (question.selectAnswers?.length === 0) {
    return undefined;
  }

  const answerValues = question.selectAnswers?.map(answer => {
    if (answer.stringValue) {
      return `${selectionMap.get(answer.idValue)}: ${answer.stringValue}`;
    } else {
      return selectionMap.get(answer.idValue);
    }
  });

  const answerString = answerValues?.join(", ");
  return answerString;
};
