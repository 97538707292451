import {
  format,
  parseISO,
  differenceInMonths,
  differenceInYears,
  addYears,
} from "date-fns";
import { useMemo } from "react";
import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";

const DatesText = styled(Text)`
  white-space: nowrap;
  flex-grow: 0;
  color: gray-600;
  text-align: left;
`;

const DatesWrapper = styled.div`
  white-space: nowrap;
`;

const IndividualDateWrapper = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  padding-left: ${({ noPadding }) => (noPadding ? 0 : pxToRem(2))};
`;

const DateContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: ${({ isMobile }) =>
    isMobile ? "flex-start;" : "flex-end;"};
`;

interface IDates<T> {
  startDate: T;
  endDate: T | null;
  dateFormat: string;
  showDuration?: boolean;
  isMobile?: boolean;
}

const yearsAndMonths = (
  startDate: Date | null,
  endDate: Date | null
): string => {
  const start = startDate ? startDate : new Date();
  const end = endDate ? endDate : new Date();

  const years = differenceInYears(end, start);
  const startMonths = addYears(start, years);
  const months = differenceInMonths(end, startMonths);

  if (years > 0 && months > 0) {
    return ` (${years}y ${months}m)`;
  } else if (years > 0) {
    return ` (${years}y)`;
  } else if (months > 0) {
    return ` (${months}m)`;
  } else if (months === 0 && start <= end) {
    return ` (1m)`;
  }
  return "";
};

const DatesToFrom = ({
  startDate,
  endDate,
  dateFormat,
  showDuration,
  isMobile,
}: IDates<Date>): React.ReactElement => {
  const formattedStartDate = format(startDate, dateFormat);
  const formattedEndDate = endDate
    ? `- ${format(endDate, dateFormat)}`
    : `- Present`;
  const duration = yearsAndMonths(startDate, endDate);
  const showEndDate = startDate !== endDate;

  return (
    <DateContainer isMobile={isMobile}>
      <IndividualDateWrapper noPadding>
        <DatesText size={-1}>
          {formattedStartDate} {showEndDate && formattedEndDate}{" "}
          {showDuration && duration}
        </DatesText>
      </IndividualDateWrapper>
    </DateContainer>
  );
};

export function Dates({
  startDate,
  endDate,
  dateFormat,
  showDuration = false,
  isMobile = false,
}: IDates<string>): React.ReactElement {
  const startDateAsDate = useMemo(() => parseISO(startDate), [startDate]);
  const endDateAsDate = useMemo(
    () => (endDate ? parseISO(endDate) : null),
    [endDate]
  );
  return (
    <DatesWrapper>
      <DatesToFrom
        startDate={startDateAsDate}
        endDate={endDateAsDate}
        dateFormat={dateFormat}
        showDuration={showDuration}
        isMobile={isMobile}
      />
    </DatesWrapper>
  );
}
