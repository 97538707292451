import { FieldValidator } from "final-form";
import styled from "@xstyled/styled-components";

import {
  SettingsField,
  SettingsInputType,
  SettingsName,
} from "./SettingsField";
import { EditableField } from "./Settings";
import { LocationSettings } from "./LocationSettings";

import { palette, pxToRem } from "@otta/design-tokens";
import { Button, Text } from "@otta/design";
import { AccountSettings } from "@otta/search/schema";
import * as locations from "@otta/locations";
import { composeValidators } from "@otta/shared-components";
import { requiredField } from "@otta/search/utils/validators";

interface DisplayProps {
  name: SettingsName;
  title: string;
  value: string;
  onEdit: () => void;
  isDisabled: boolean;
}

interface EditSettingProps {
  title: string;
  name: SettingsName;
  type: SettingsInputType;
  placeholder?: string;
  onCancel: () => void;
  validators?: FieldValidator<string>;
}

interface SettingsProps {
  details: EditableField;
  onEdit: (name: SettingsName) => void;
  onCancel: () => void;
  editingField: string | null;
  currentUser: AccountSettings.CurrentUser;
}

const SettingDisplayContainer = styled.div<{ displayUnderline?: boolean }>`
  border-bottom: ${({ displayUnderline = true }) =>
    displayUnderline ? `1px solid ${palette.grayscale.shade200}` : "none"};
  text-align: left;
`;

const ContentContainer = styled.div`
  margin-top: sm;
  margin-bottom: lg;
`;

const SaveButton = styled(Button)`
  width: 100%;
  margin: xl 0;
`;

export const SettingContainer = ({
  details,
  currentUser,
  onEdit,
  onCancel,
  editingField,
}: SettingsProps) => {
  if (editingField === "location" && details.name === "location") {
    return <LocationSettings currentUser={currentUser} onCancel={onCancel} />;
  } else if (details.name === "location") {
    const display =
      currentUser.region &&
      currentUser.subregion &&
      locations.subregions[currentUser.region] &&
      locations.subregions[currentUser.region][currentUser.subregion]
        ? `${
            locations.subregions[currentUser.region][currentUser.subregion]
          }, ${locations.regions[currentUser.region]}`
        : currentUser.region
        ? `${locations.regions[currentUser.region]}`
        : "None";

    return (
      <DisplayContainer
        title={details.title}
        name={details.name}
        value={display}
        isDisabled={editingField !== null && editingField !== details.name}
        onEdit={() => onEdit(details.name)}
      />
    );
  } else if (editingField === details.name) {
    return (
      <EditSettingContainer
        title={details.title}
        name={details.name}
        type={details.type}
        placeholder={details.placeholder}
        onCancel={onCancel}
        validators={details.validators}
      />
    );
  } else {
    return (
      <DisplayContainer
        title={details.title}
        name={details.name}
        value={
          details.name === "name"
            ? `${details.firstName} ${details.lastName}`
            : `${details.value}`
        }
        isDisabled={editingField !== null && editingField !== details.name}
        onEdit={() => onEdit(details.name)}
      />
    );
  }
};

const DisplayContainer = ({
  title,
  name,
  value,
  onEdit,
  isDisabled,
}: DisplayProps) => {
  return (
    <SettingDisplayContainer
      displayUnderline={name !== "location"}
      data-testid={`${name}-display-section`}
    >
      <Text
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: isDisabled ? palette.grayscale.shade400 : palette.brand.black,
        }}
      >
        <Text bold>{title}</Text>
        <Text
          data-testid={`${name}-edit-button`}
          style={{
            textDecoration: "underline",
            cursor: isDisabled ? "not-allowed" : "pointer",
            userSelect: "none",
            color: isDisabled
              ? palette.grayscale.shade400
              : palette.brand.black,
          }}
          onClick={isDisabled ? undefined : onEdit}
        >
          Edit
        </Text>
      </Text>
      <ContentContainer>
        <Text
          data-cs-mask
          style={{
            color: isDisabled
              ? palette.grayscale.shade400
              : palette.brand.black,
          }}
        >
          {value === "" ? "None" : value}
        </Text>
      </ContentContainer>
    </SettingDisplayContainer>
  );
};

const EditSettingContainer = ({
  title,
  name,
  type,
  placeholder,
  onCancel,
  validators,
}: EditSettingProps) => {
  return (
    <SettingDisplayContainer>
      <Text
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: pxToRem(8),
        }}
      >
        <Text bold>{title}</Text>
        <Text
          data-testid={`${name}-cancel-button`}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            userSelect: "none",
          }}
          onClick={onCancel}
        >
          Cancel
        </Text>
      </Text>
      <ContentContainer>
        {name === "name" ? (
          <>
            <Text
              bold
              style={{ marginTop: pxToRem(24), marginBottom: pxToRem(8) }}
            >
              First name
            </Text>
            <SettingsField
              name="firstName"
              label="First name"
              type={type}
              validators={composeValidators(requiredField("first name"))}
            />
            <Text
              bold
              style={{ marginTop: pxToRem(16), marginBottom: pxToRem(8) }}
            >
              Last name
            </Text>
            <SettingsField
              name="lastName"
              label="Last name"
              type={type}
              validators={composeValidators(requiredField("last name"))}
            />
          </>
        ) : (
          <SettingsField
            name={name}
            label={title}
            type={type}
            placeholder={placeholder}
            validators={validators}
          />
        )}

        <SaveButton type="submit" level="primary">
          Save
        </SaveButton>
      </ContentContainer>
    </SettingDisplayContainer>
  );
};
