import { Field } from "react-final-form";
import styled, { css, up } from "@xstyled/styled-components";

import { HalfWidth } from "../../components/FormData";

import {
  InputField,
  Label,
  Spacing,
  Paragraph,
  TextareaField,
} from "@otta/design";
import { pxToRem, borderRadius } from "@otta/design-tokens";
import {
  composeValidators,
  maxLength,
  minLength,
  required,
  validUrl,
} from "@otta/search/utils/validators";
import { CroppedImageUploadField } from "@otta/search/components/CroppedImageUploadField";
import { ImagePreview as Preview } from "@otta/search/components/ImagePreview";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";
import { Snippets } from "@otta/search/schema";

const ImagePreview = styled(Preview)`
  width: 100%;

  ${up(
    "tablet",
    css`
      width: 300px;
    `
  )}
`;

const DisplayWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;

  ${up(
    "tablet",
    css`
      flex-direction: row;
      align-items: flex-start;
    `
  )}
`;

const DisplayImage = styled(ImagePreview)`
  img {
    margin: md 0;
    border-radius: ${pxToRem(borderRadius)};
  }

  ${up(
    "tablet",
    css`
      img {
        margin-left: lg;
      }
    `
  )}
`;

const Textarea = styled(TextareaField)`
  min-height: ${pxToRem(100)};
  resize: none;
`;

const StyledParagraph = styled(Paragraph)<{ hasImage: boolean }>(
  ({ hasImage }) => css`
    overflow-wrap: anywhere;

    ${up(
      "tablet",
      css`
        ${hasImage && "max-width: 320px;"}
        margin: 4px 0;
      `
    )}
  `
);

const InformationTextWrapper = styled.div`
  display: flex;
  align-items: center;
  &:first-child {
    margin-right: lg;
  }
`;

export function EditingSnippet(): React.ReactElement {
  return (
    <Spacing>
      <Field
        name="title"
        validate={required}
        render={({ input, meta }) => (
          <HalfWidth>
            <InputField
              {...input}
              type="text"
              label="Title"
              autoFocus
              placeholder="Personal blog"
              error={meta.touched && meta.error}
            />
          </HalfWidth>
        )}
      />
      <Field
        name="projectUrl"
        validate={validUrl}
        render={({ input, meta }) => (
          <HalfWidth>
            <InputField
              {...input}
              type="text"
              label="Link"
              placeholder="https://personalblog.com"
              error={meta.touched && meta.error}
            />
          </HalfWidth>
        )}
      />
      <Field
        name="description"
        validate={composeValidators(required, minLength(40), maxLength(750))}
        render={({ input, meta }) => (
          <Textarea
            {...input}
            id="description"
            label="Description"
            placeholder="I was the launch manager for..."
            error={meta.touched && meta.error}
          />
        )}
      />
      <Spacing size={-2}>
        <InformationTextWrapper>
          <TooltipWithIcon content="The header image is a great way to show your skills visually and help your snippet stand out">
            <Label>Header image</Label>
          </TooltipWithIcon>
        </InformationTextWrapper>
        <Field<File | string> name="image">
          {({ input, meta }) => (
            <CroppedImageUploadField
              {...input}
              text="Upload or drag and drop an image at least 300px wide"
              error={meta.touched && meta.error}
              minWidth={100}
              aspectRatio={1}
            />
          )}
        </Field>
      </Spacing>
    </Spacing>
  );
}

export function DisplaySnippet({
  data,
}: {
  data: Snippets.Projects;
}): React.ReactElement {
  const snippet = data;
  return (
    <DisplayWrapper>
      {snippet.description && (
        <StyledParagraph data-cs-mask hasImage={!!snippet.imagePath}>
          {snippet.description}
        </StyledParagraph>
      )}
      {snippet.imagePath && (
        <DisplayImage data-cs-mask src={snippet.imagePath} />
      )}
    </DisplayWrapper>
  );
}
