import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";

import { Error } from "../../../components/FormData";

import { ChoiceInput } from "@otta/search/schema";

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
`;

const LabelText = styled.span`
  margin-left: 6px;
`;

const StyledError = styled(Error)`
  margin-top: 10px;
`;

interface EditMultiSelectFieldProps {
  choices: ChoiceInput[];
  name: string;
}

export function EditMultiSelectField({
  choices,
  name,
}: EditMultiSelectFieldProps): React.ReactElement | null {
  if (choices.length === 0) {
    return null;
  }
  return (
    <CheckboxesContainer>
      {choices.map(({ label, value }) => (
        <Label key={value}>
          <Field
            name={name}
            type="checkbox"
            component="input"
            value={JSON.stringify({ label, value })}
          />
          <LabelText>{label}</LabelText>
        </Label>
      ))}
      <Field name={name} subscription={{ touched: true, error: true }}>
        {({ meta }) =>
          meta.error && meta.touched ? (
            <StyledError>{meta.error}</StyledError>
          ) : null
        }
      </Field>
    </CheckboxesContainer>
  );
}
