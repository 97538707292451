import { useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import styled from "@xstyled/styled-components";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

import { ActionButton } from "./ActionButton";

import { modularScale, pxToRem } from "@otta/design-tokens";
import {
  Modal,
  Text,
  Textarea,
  VerticalSpacing,
  Card,
  Button,
  ErrorText,
} from "@otta/design";
import {
  composeValidators,
  maxLength,
  minLength,
  required,
} from "@otta/search/utils/validators";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  Job,
  CreateJobProblemDocument,
  UserJobPreferences,
} from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";
import { Icon } from "@otta/icons";

const StyledTextarea = styled(Textarea)`
  resize: none;
`;
const ButtonContainer = styled(VerticalSpacing)`
  display: flex;
  flex-direction: column;
`;

interface ReportAProblem {
  job: Pick<Job.Fragment, "id">;
  user?: Pick<UserJobPreferences.CurrentUser, "id">;
  toggleKeyboardShortcuts?: (enable: boolean) => void;
}

export function ReportAProblem({
  job,
  user,
  toggleKeyboardShortcuts,
}: ReportAProblem): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false);

  const [createJobQuestion] = useMutation(CreateJobProblemDocument, {
    onCompleted: () => {
      toast.success("Thanks for letting us know");
      setIsOpen(false);
    },
    onError: handleMutationError,
  });

  const handleSubmitted = ({ content }: { content: string }) => {
    createJobQuestion({ variables: { content, jobId: job.id } });
    toggleKeyboardShortcuts?.(true);
  };

  if (!user) {
    return null;
  }

  const handleClick = () => {
    setIsOpen(true);
    toggleKeyboardShortcuts?.(false);
    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      name: "job-card-report-a-problem",
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    toggleKeyboardShortcuts?.(true);
  };

  return (
    <>
      <ActionButton
        onClick={handleClick}
        icon={<Icon icon="flag" />}
        title="Report a problem with this job"
        data-analytics-id="report-a-problem"
        data-testid="report-a-problem"
      />

      <Modal
        open={isOpen}
        style={{ maxWidth: pxToRem(375) }}
        onOpenChange={open => {
          setIsOpen(open);

          if (!open) {
            toggleKeyboardShortcuts?.(true);
          }
        }}
      >
        <Card data-testid="report-a-problem-modal">
          <FinalForm
            onSubmit={handleSubmitted}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="content"
                  validate={composeValidators(
                    required,
                    minLength(5),
                    maxLength(500)
                  )}
                >
                  {({ input, meta }) => {
                    return (
                      <VerticalSpacing>
                        <Text bold>Something wrong with this job?</Text>
                        <Text style={{ paddingBottom: pxToRem(8) }}>
                          If something looks incorrect on this job, let us know
                          and we&apos;ll look into it.
                        </Text>
                        <VerticalSpacing size={-3}>
                          <Text bold>What&apos;s wrong with this job?</Text>
                          <StyledTextarea
                            data-testid="problem-textarea"
                            rows={5}
                            data-enable-grammarly="false"
                            {...input}
                          />
                          <div style={{ height: modularScale(3) }}>
                            {meta.touched && meta.error && (
                              <ErrorText>{meta.error}</ErrorText>
                            )}
                          </div>
                        </VerticalSpacing>
                        <ButtonContainer size={-2}>
                          <Button
                            level="primary"
                            type="submit"
                            data-testid="send-problem"
                          >
                            Send
                          </Button>
                          <Button level="secondary" onClick={handleClose}>
                            Cancel
                          </Button>
                        </ButtonContainer>
                      </VerticalSpacing>
                    );
                  }}
                </Field>
              </form>
            )}
          />
        </Card>
      </Modal>
    </>
  );
}
