import { useMemo } from "react";
import styled from "@xstyled/styled-components";

import { DismissButton } from "../components/DismissButton";

import { modularScale } from "@otta/design-tokens";
import { Button, Spacing, Text } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Icon } from "@otta/icons";
import { Link } from "@otta/search/components/Link";
import { CurrentUserDocument } from "@otta/search/schema";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const StyledButton = styled(Button)`
  padding: 0.5rem 1.5rem;
`;

const IconWrapper = styled.div`
  height: ${modularScale(0)};
  width: ${modularScale(0)};
  margin-left: 0.5rem;
`;

interface IExternalLinkCueProps {
  onClick: () => void;
  onDismiss: () => void;
  title: string;
  description: string;
  url: string;
  primaryButtonCopy?: string;
}

export const ExternalLinkCue = ({
  onClick,
  onDismiss,
  title,
  description,
  url,
  primaryButtonCopy,
}: IExternalLinkCueProps): React.ReactElement => {
  const { data } = useQuery(CurrentUserDocument);

  const urlWithParams = useMemo(() => {
    const tmpUrl = new URL(url);
    if (data?.currentUser) {
      tmpUrl.searchParams.set("user_id", data.currentUser.externalId);
    }

    return tmpUrl.toString();
  }, [data?.currentUser, url]);

  return (
    <Spacing>
      <Header>
        <Text bold>{title}</Text>
        <DismissButton onClick={onDismiss} />
      </Header>
      <Text>{description}</Text>
      <Buttons>
        <Link
          to={urlWithParams}
          targetBlank
          underline={false}
          onClick={onClick}
        >
          <StyledButton
            data-testid="launch-button"
            level="primary"
            size="small"
          >
            {primaryButtonCopy ? primaryButtonCopy : "Launch survey"}
            <IconWrapper>
              <Icon icon="external" />
            </IconWrapper>
          </StyledButton>
        </Link>
        <StyledButton
          data-testid="dismiss-button"
          level="secondary"
          size="small"
          onClick={onDismiss}
        >
          Not now
        </StyledButton>
      </Buttons>
    </Spacing>
  );
};
