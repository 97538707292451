import styled from "@xstyled/styled-components";

import { Spacing, Text } from "@otta/design";
import { pxToRem, modularScale } from "@otta/design-tokens";

const Container = styled(Spacing)`
  display: flex;
  flex-direction: column;
`;

const Option = styled.label`
  align-items: center;
  display: flex;
  gap: sm;
`;

const LabelText = styled(Text)`
  align-items: center;
  display: flex;
  line-height: ${pxToRem(24)};
  word-break: break-word;
`;

const Input = styled.input`
  &:checked {
    &::before {
      transform: scale(1);
    }
    background-color: yellow-500;
  }

  &::before {
    content: "";
    border-radius: 50%;
    box-shadow: inset ${modularScale()} ${modularScale()} black;
    height: ${pxToRem(7.5)};
    transform: scale(0);
    width: ${pxToRem(7.5)};
  }

  appearance: none;
  background-color: gray-200;
  border-radius: 50%;
  display: grid;
  place-content: center;
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
`;

interface RadioButtonsProps<T> {
  options: { name: string; label: string | React.ReactNode; value: T }[];
  onChange: ({ value }: { value: T }) => void;
  value: T | null | undefined;
}

export function RadioButtons<T extends string | number | boolean>({
  options,
  onChange,
  value,
}: RadioButtonsProps<T>): React.ReactElement {
  return (
    <Container>
      {options.map((o, index) => (
        <Option key={index}>
          <Input
            type="radio"
            name={o.name}
            checked={value === o.value}
            onChange={() => onChange(o)}
          />
          {typeof o.label === "string" ? (
            <LabelText align="left">{o.label}</LabelText>
          ) : (
            o.label
          )}
        </Option>
      ))}
    </Container>
  );
}
