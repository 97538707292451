import { EditBooleanField } from "./fields/EditBooleanField";
import { EditMultiSelectField } from "./fields/EditMultiSelectField";
import { EditDateField } from "./fields/EditDateField";
import { EditDecimalField } from "./fields/EditDecimalField";
import { EditSingleSelectField } from "./fields/EditSingleSelectField";
import { EditFileUploadField } from "./fields/EditFileUploadField";
import { EditTextareaField } from "./fields/EditTextareaField";
import { EditTextField } from "./fields/EditTextField";
import { EditUrlField } from "./fields/EditUrlField";

import { palette, pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";
import {
  GreenhouseApplicationQuestion,
  LeverApplicationQuestion,
  OttaApplicationQuestion,
  WorkableApplicationQuestion,
} from "@otta/search/schema";
import { HeaderWrapper } from "@otta/search/pages/Profile/PublicProfile/components/Section";
import { CardTitle } from "@otta/search/pages/Profile/components/FormHeader";

type Question =
  | LeverApplicationQuestion
  | WorkableApplicationQuestion
  | GreenhouseApplicationQuestion
  | OttaApplicationQuestion;

interface EditQuestionProps {
  fieldName: string;
  question: Question;
  isGreenhouse?: boolean;
}

export function EditQuestion({
  fieldName,
  question,
  isGreenhouse,
}: EditQuestionProps): React.ReactElement {
  return (
    <>
      <HeaderWrapper style={{ marginBottom: pxToRem(8) }}>
        <CardTitle bold>
          {question.question}
          {!question.required && (
            <Text style={{ color: palette.grayscale.shade600 }}>
              (optional)
            </Text>
          )}
        </CardTitle>{" "}
      </HeaderWrapper>
      <Field question={question} isGreenhouse={isGreenhouse} name={fieldName} />
    </>
  );
}
interface FieldProps {
  question: Question;
  name: string;
  isGreenhouse?: boolean;
}

function Field({
  question,
  name,
  isGreenhouse,
}: FieldProps): React.ReactElement {
  switch (question.__typename) {
    case "BooleanQuestion":
      return <EditBooleanField name={name} />;
    case "DateQuestion":
      return <EditDateField name={name} />;
    case "DecimalQuestion":
      return <EditDecimalField name={name} />;
    case "FileQuestion":
      return <EditFileUploadField isGreenhouse={isGreenhouse} name={name} />;
    case "MultiSelectQuestion":
      return <EditMultiSelectField choices={question.choices} name={name} />;
    case "SingleSelectQuestion":
      return <EditSingleSelectField choices={question.choices} name={name} />;
    case "TextQuestion":
      return <EditTextField name={name} />;
    case "TextAreaQuestion":
      return <EditTextareaField name={name} />;
    case "UrlQuestion":
      return <EditUrlField name={name} />;
  }
}
