import styled, { css, up } from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";
import { ShowMore as DefaultShowMore } from "@otta/search/components/ShowMore";
import { Arrow as DefaultArrow } from "@otta/search/components/Icons/Arrow";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CollapsedText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ExperienceWrapper = styled.div`
  margin: 19 0 0;
`;

export const SmallExperienceWrapper = styled.div`
  margin: 19 0 0;
`;

export const ShowMore = styled(DefaultShowMore)`
  margin-top: md;
`;

export const MobileView = styled.div`
  display: block;

  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

export const DesktopView = styled.div`
  display: none;

  ${up(
    "tablet",
    css`
      display: block;
    `
  )}
`;

const Arrow = styled(DefaultArrow)`
  width: ${modularScale(1)};
  height: ${modularScale(1)};
  cursor: pointer;
`;

const ExpansionArrowWrapper = styled.div`
  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    text-decoration: underline;
  }
`;

const CarouselArrowWrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
`;

export const ExpansionArrow = ({
  expand,
  setExpand,
}: {
  expand: boolean;
  setExpand: (e: boolean) => void;
}): React.ReactElement => (
  <ExpansionArrowWrapper onClick={() => setExpand(!expand)}>
    <Text size={-1} style={{ marginRight: modularScale(-6) }}>
      {expand ? "Collapse" : "Expand"}
    </Text>
    <Arrow
      colour={palette.brand.black}
      transform={expand ? "rotate(180deg)" : "rotate(0deg)"}
      height={modularScale()}
    />
  </ExpansionArrowWrapper>
);

export const CarouselArrows = ({
  handleForward,
  handleBackward,
}: {
  handleForward: () => void;
  handleBackward: () => void;
}): React.ReactElement => (
  <div style={{ display: "flex" }}>
    <CarouselArrowWrapper onClick={handleBackward} data-testid="backward-arrow">
      <Arrow colour={palette.brand.black} transform={"rotate(90deg)"} />
    </CarouselArrowWrapper>
    <CarouselArrowWrapper
      onClick={handleForward}
      style={{ marginLeft: modularScale(-3) }}
      data-testid="forward-arrow"
    >
      <Arrow colour={palette.brand.black} transform={"rotate(270deg)"} />
    </CarouselArrowWrapper>
  </div>
);
