import {
  TechnologyUsedPreferenceInput,
  UserInput,
  UserPreferencesQuery,
} from "@otta/search/schema";

function removeTypename<T extends { __typename: string }>(
  obj: T
): Omit<T, "__typename"> {
  const { __typename, ...newObj } = obj;
  return newObj;
}

export function formatIncomingData(
  currentUser: NonNullable<UserPreferencesQuery["currentUser"]>
): UserInput {
  return Object.keys(currentUser).reduce<UserInput>((acc, field) => {
    const followedCompanies = currentUser.followedCompanies?.map(
      pref => pref.company
    );
    switch (field) {
      case "jobFunctionPreferences":
        acc[field] = currentUser[field].map(removeTypename);
        break;
      case "jobSubFunctionPreferences":
        acc[field] = currentUser[field].map(removeTypename);
        break;
      case "companySizePreferences":
        acc[field] = currentUser[field].map(removeTypename);
        break;
      case "locationPreferences":
        acc[field] = currentUser[field].map(removeTypename);
        break;
      case "sectorTagPreferences":
        acc[field] = currentUser[field].map(removeTypename);
        break;
      case "companyQualitiesPreferences":
        acc[field] = currentUser[field].map(removeTypename);
        break;
      case "languageRequirementPreferences":
        acc[field] = currentUser[field].map(removeTypename);
        break;
      case "technologiesUsedPreferences":
        acc[field] = currentUser[field].map(
          removeTypename
        ) as TechnologyUsedPreferenceInput[];
        break;
      case "jobExperiencePreference":
        if (currentUser.jobExperiencePreference !== null) {
          acc[field] = removeTypename(currentUser.jobExperiencePreference);
        }
        break;
      case "minSalaryRequirement":
        if (currentUser.minSalaryRequirement !== null) {
          acc[field] = removeTypename(currentUser.minSalaryRequirement);
        }
        break;
      case "pronoun":
        if (currentUser.pronoun !== null) {
          acc[field] = currentUser[field];
        }
        break;
      case "ethnicities":
        acc[field] = currentUser[field].map(removeTypename);
        break;
      case "genders":
        acc[field] = currentUser[field];
        break;
      case "linkedinUrl":
        acc[field] = currentUser[field];
        break;
      case "searchStage":
        acc[field] = currentUser[field];
        break;
      case "visaRequirementCountries":
        acc[field] = currentUser[field].map(removeTypename);
        break;
      case "jobEmailNotificationsFrequency":
        acc[field] = currentUser[field];
        break;
      case "signUpReasonResponses":
        acc[field] = currentUser[field].map(removeTypename);
        break;
      case "followedCompanies":
        acc[field] = followedCompanies?.map(removeTypename);
        break;
      case "hiddenCompanies":
        acc[field] = currentUser[field].map(removeTypename);
    }
    return acc;
  }, {});
}
