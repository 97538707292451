import { NextButtonModalContents } from "./NextButtonModalContents";

import { Modal } from "@otta/design";

export const PublicJobActionModal = ({
  heading,
  onClose: handleClose,
  utmSource,
  jobExternalId,
}: {
  heading: React.ReactElement | string;
  utmSource?: string;
  jobExternalId: string;
  onClose: () => void;
}): React.ReactElement => {
  return (
    <Modal
      open
      headerContent={heading}
      onOpenChange={o => {
        if (!o) {
          handleClose();
        }
      }}
    >
      <NextButtonModalContents
        utmSource={utmSource}
        externalId={jobExternalId}
      />
    </Modal>
  );
};
