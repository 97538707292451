import { useMutation } from "@apollo/client";

import { FormCardList } from "../../components/FormCardList";
import { CardTitle } from "../../components/FormHeader";

import { DisplaySnippet, EditingSnippet } from "./Snippet";

import {
  SnippetsDocument,
  CreateSnippetDocument,
  DeleteSnippetDocument,
  UpdateSnippetDocument,
  SnippetsQuery,
  UserProjectInput,
  Snippets as SnippetTypes,
  ProfileCompletionStatusDocument,
} from "@otta/search/schema";

export function Snippets(): React.ReactElement {
  const [updateMutation] = useMutation(UpdateSnippetDocument, {
    refetchQueries: [
      { query: SnippetsDocument },
      { query: ProfileCompletionStatusDocument },
    ],
    awaitRefetchQueries: true,
  });

  const [createMutation] = useMutation(CreateSnippetDocument, {
    refetchQueries: [
      { query: SnippetsDocument },
      { query: ProfileCompletionStatusDocument },
    ],
    awaitRefetchQueries: true,
  });

  const [deleteMutation] = useMutation(DeleteSnippetDocument, {
    refetchQueries: [
      { query: SnippetsDocument },
      { query: ProfileCompletionStatusDocument },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <div id="snippets-form-id">
      <FormCardList
        title="Snippets"
        introText="Add side projects, interesting things you’ve done, skills you’ve gained, and your greatest achievements."
        query={SnippetsDocument}
        formatTitle={({ title }) => <CardTitle>{title}</CardTitle>}
        formatQueryData={({ currentUser }: SnippetsQuery) =>
          currentUser?.projects ?? []
        }
        formatIncomingData={(project: SnippetTypes.Projects) => ({
          ...project,
          image: project.imagePath as string,
        })}
        formatOutgoingData={({
          title,
          projectUrl,
          description,
          image,
        }): UserProjectInput & { file: File | string | null } => {
          return {
            title,
            projectUrl,
            description,
            file: image,
          };
        }}
        onCreate={async ({
          file,
          ...snippetInput
        }: UserProjectInput & { file: File | string | null }) => {
          const variables =
            file instanceof Blob
              ? { input: snippetInput, file: file }
              : { input: snippetInput };
          await createMutation({ variables });
        }}
        onUpdate={id =>
          async ({
            file,
            ...snippetInput
          }: UserProjectInput & { file: File | string | null }) => {
            const variables =
              file instanceof Blob
                ? { id, input: snippetInput, file }
                : typeof file === "string"
                ? { id, input: snippetInput }
                : { id, input: snippetInput, file: null };

            await updateMutation({ variables });
          }}
        onDelete={id => async () => deleteMutation({ variables: { id } })}
        editingComponent={EditingSnippet}
        displayComponent={DisplaySnippet}
        recommended={false}
      />
    </div>
  );
}
