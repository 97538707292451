import { useEffect, useState } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { countByLocation } from "./utils";

import { Spacing } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { Checkbox } from "@otta/search/components/Input/Checkbox";
import { VisaLocation } from "@otta/search/constants/locations";
import { VisaRequirementCountryInput } from "@otta/search/schema";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

export function VisaSponsorship({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const isPresent = useIsPresent();
  const [noVisa, setNoVisa] = useState<boolean>(false);
  const { preferencesData, hasSetPreferences, updatePreferences } =
    useUserPreferences();

  const visaRequirementCountries =
    preferencesData?.visaRequirementCountries ?? [];

  if (hasSetPreferences && !visaRequirementCountries.length && !noVisa) {
    setNoVisa(true);
  }

  const needsUK = visaRequirementCountries.some(c => c.location === "UK");
  const needsUS = visaRequirementCountries.some(c => c.location === "US");
  const needsEU = visaRequirementCountries.some(c => c.location === "EU");
  const needsCA = visaRequirementCountries.some(c => c.location === "CA");

  const answeredQuestion = noVisa || needsUK || needsUS || needsEU || needsCA;

  useEffect(() => {
    setNextEnabled(isPresent && answeredQuestion);
  }, [setNextEnabled, isPresent, answeredQuestion]);

  if (!preferencesData) {
    return <Loading />;
  }

  const locationPreferences = preferencesData.locationPreferences ?? [];

  const [usCount, ukCount, euCount, caCount] =
    countByLocation(locationPreferences);

  const handleChange = (location: VisaLocation | null) => {
    let input: VisaRequirementCountryInput[] = [];

    if (location) {
      setNoVisa(false);
      if (visaRequirementCountries.some(l => l.location === location)) {
        input = visaRequirementCountries.filter(c => c.location !== location);
      } else {
        input = [...visaRequirementCountries, { location }];
      }
    }

    updatePreferences({
      visaRequirementCountries: input,
    });
  };

  return (
    <Spacing size={-4}>
      {ukCount > 0 && (
        <Checkbox
          label="I require a visa for the UK"
          checked={needsUK}
          onChange={() => {
            handleChange("UK");
            setNoVisa(false);
          }}
        />
      )}
      {usCount > 0 && (
        <Checkbox
          label="I require a visa for the US"
          checked={needsUS}
          onChange={() => {
            handleChange("US");
            setNoVisa(false);
          }}
        />
      )}
      {euCount > 0 && (
        <Checkbox
          label="I require a visa for the EU"
          checked={needsEU}
          onChange={() => {
            handleChange("EU");
            setNoVisa(false);
          }}
        />
      )}
      {caCount > 0 && (
        <Checkbox
          label="I require a visa for Canada"
          checked={needsCA}
          onChange={() => {
            handleChange("CA");
            setNoVisa(false);
          }}
        />
      )}
      <Checkbox
        data-testid="no-visa-required-checkbox"
        label="I don't require a visa"
        checked={noVisa}
        onChange={() => {
          handleChange(null);
          setNoVisa(true);
        }}
      />
    </Spacing>
  );
}
