import { Text } from "@otta/design";
import { Company } from "@otta/search/schema";

interface CompanyShortDescriptionProps {
  company: Pick<Company.Fragment, "shortDescription">;
}

export function CompanyShortDescription({
  company: { shortDescription },
}: CompanyShortDescriptionProps): React.ReactElement | null {
  if (!shortDescription) {
    return null;
  }

  return (
    <Text align={"left"} data-testid="company-short-description">
      {shortDescription}
    </Text>
  );
}
