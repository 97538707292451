import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { LogoWrapper, UnderlinedLink, Tick } from "../shared";
import AnimatedCard from "../shared/AnimatedCard";

import { FullWidthButton } from ".";

import { Spacing, Text, Button } from "@otta/design";
import CompanyLogo from "@otta/search/components/CompanyLogo";
import {
  CreateJobReactionDocument,
  UpdateShortlistUserJobStatusDocument,
  JobInterviewQuery,
  UserJobStatus,
} from "@otta/search/schema";

export function FollowUpQuestionCard({
  interview,
  onError,
}: {
  interview: JobInterviewQuery["jobInterview"];
  onError: () => void;
}): React.ReactElement {
  const [qs] = useSearchParams();
  const token = qs.get("token");

  const [jobStatus, setJobStatus] = useState<
    null | "ACCEPTED" | "RECEIVED" | "REJECTED"
  >(null);

  const [updateJobStatusMutation] = useMutation(
    UpdateShortlistUserJobStatusDocument,
    {
      context: { emailToken: token },
      onError,
    }
  );

  const [createJobReactionMutation] = useMutation(CreateJobReactionDocument, {
    context: { emailToken: token },
    onError,
  });

  const { job } = interview;

  const handleClick = (action: "ACCEPTED" | "RECEIVED" | "REJECTED") => {
    setJobStatus(action);

    if (action === "REJECTED") {
      createJobReactionMutation({
        variables: { jobId: job.id, direction: false, reason: "no_offer" },
      });
    } else {
      updateJobStatusMutation({
        variables: {
          jobId: job.id,
          status:
            action === "RECEIVED"
              ? UserJobStatus.OfferReceived
              : UserJobStatus.OfferAccepted,
        },
      });
    }
  };

  return (
    <Spacing>
      <AnimatePresence initial={false} mode="wait">
        {jobStatus === null ? (
          <AnimatedCard key="what-happened-question">
            <Spacing size={1}>
              <LogoWrapper>
                <CompanyLogo company={job.company} />
              </LogoWrapper>
              <Text bold size={1}>
                {job.company.name}
              </Text>
              <Text>
                {job.title}
                {job.subtitle ? `- ${job.subtitle}` : ""}
              </Text>
              <Text bold>What happened after the interview process?</Text>
              <Spacing>
                <FullWidthButton
                  data-analytics-id="interview-follow-up-offer-accepted"
                  level="secondary"
                  onClick={() => handleClick("ACCEPTED")}
                >
                  I accepted an offer
                </FullWidthButton>
                <FullWidthButton
                  data-analytics-id="interview-follow-up-offer-received"
                  level="secondary"
                  onClick={() => handleClick("RECEIVED")}
                >
                  I received an offer
                </FullWidthButton>
                <FullWidthButton
                  data-analytics-id="interview-follow-up-rejected"
                  level="secondary"
                  onClick={() => handleClick("REJECTED")}
                >
                  I didn&apos;t get an offer
                </FullWidthButton>
              </Spacing>
            </Spacing>
          </AnimatedCard>
        ) : jobStatus === "ACCEPTED" ? (
          <AnimatedCard key="offer-accepted">
            <Spacing size={1}>
              <Tick />
              <Text bold size={1}>
                Congratulations!
              </Text>
              <Text>
                That&apos;s fantastic news! Best of luck in your new role.
              </Text>
              <Text>
                While you&apos;re here, why not take a moment to update your
                profile with your new position?
              </Text>
              <Text>
                P.S. We&apos;ll make a charity donation on your behalf - watch
                out for more information in your emails.
              </Text>
              <Button
                as={Link}
                style={{ width: "100%" }}
                level="secondary"
                to="/"
              >
                Go to your dashboard
              </Button>
            </Spacing>
          </AnimatedCard>
        ) : jobStatus === "RECEIVED" ? (
          <AnimatedCard key="offer-received">
            <Spacing size={1}>
              <Tick />
              <Text bold size={1}>
                That&apos;s great news!
              </Text>
              <Text>
                if you accept or decline the offer, remember to update your
                progress on your dashboard.
              </Text>
              <Button
                as={Link}
                style={{ width: "100%" }}
                level="secondary"
                to="/"
              >
                Go to your dashboard
              </Button>
            </Spacing>
          </AnimatedCard>
        ) : (
          <AnimatedCard key="offer-rejected">
            <Spacing size={1}>
              <Text bold size={1}>
                Sorry to hear that
              </Text>
              <Text>We&apos;ve updated your dashboard to reflect this.</Text>
              <Text>
                While you&apos;re here, why not check out your latest matches?
              </Text>
              <Button
                as={Link}
                style={{ width: "100%" }}
                level="secondary"
                to="/"
              >
                Go to your dashboard
              </Button>
            </Spacing>
          </AnimatedCard>
        )}
      </AnimatePresence>
      {jobStatus === null && (
        <UnderlinedLink to="/">Do this later</UnderlinedLink>
      )}
    </Spacing>
  );
}
