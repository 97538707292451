import { useOutletContext } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { JobCardContext } from "../JobHeader";

import { CompanyContent } from "@otta/search/pages/Companies/components/CompanyContent";
import { Loading } from "@otta/shared-components";
import { pxToRem } from "@otta/design-tokens";

const CompanyContentContainer = styled.div`
  max-width: 100%;
  width: ${pxToRem(1024)};
`;

export function CompanyProfile() {
  const data = useOutletContext<JobCardContext | undefined>();

  return data ? (
    <CompanyContentContainer>
      <CompanyContent company={data.job.company} />
    </CompanyContentContainer>
  ) : (
    <Loading />
  );
}
