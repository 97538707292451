import {
  CANADA_LOCATIONS,
  EU_LOCATIONS,
  UK_LOCATIONS,
  US_LOCATIONS,
} from "@otta/search/constants/locations";
import { Currency, Location } from "@otta/search/schema";

export function countByLocation(locations: { location: Location }[]): number[] {
  return [
    locations.filter(l => US_LOCATIONS.has(l.location)).length,
    locations.filter(l => UK_LOCATIONS.has(l.location)).length,
    locations.filter(l => EU_LOCATIONS.has(l.location)).length,
    locations.filter(l => CANADA_LOCATIONS.has(l.location)).length,
  ];
}

export function determineCurrency(locations: Location[]): Currency {
  switch (true) {
    case !locations.length:
      return Currency.Usd;
    case locations.some(l => US_LOCATIONS.has(l)):
      return Currency.Usd;
    case locations.some(l => CANADA_LOCATIONS.has(l)):
      return Currency.Cad;
    case locations.some(l => UK_LOCATIONS.has(l)):
      return Currency.Gbp;
    case locations.some(l => EU_LOCATIONS.has(l)):
      return Currency.Eur;
    default:
      return Currency.Usd;
  }
}
