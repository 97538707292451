import { useMutation } from "@apollo/client";
import { useState, useEffect, useMemo } from "react";

import {
  GreenhouseAnswerData,
  GreenhouseQuestionData,
  ConfirmJobLiveDocument,
  ProfileCompletionDocument,
  WorkableAnswerData,
  WorkableQuestionData,
  LeverQuestionData,
  LeverAnswerData,
  JobQuestionDataQuery,
  JobApplicationDataQuery,
  JobApplicationDataDocument,
  JobQuestionDataDocument,
  OttaAnswerData,
  OttaQuestionData,
} from "./../schema";

import { useQuery } from "@otta/search/apollo";
import { useTabVisible } from "@otta/search/hooks/useTabVisible";

export const useIsJobLive = (jobId: string): { isLive: boolean } => {
  const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 mins
  const visible = useTabVisible();

  const [isLive, setIsLive] = useState(true);

  const [confirmJobLive, { data: mutationData }] = useMutation(
    ConfirmJobLiveDocument,
    {
      variables: {
        id: jobId,
      },
    }
  );

  useEffect(() => {
    if (isLive && visible) {
      confirmJobLive();
      const interval = setInterval(() => {
        confirmJobLive();
      }, REFRESH_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [confirmJobLive, isLive, visible, REFRESH_INTERVAL]);

  useEffect(() => {
    if (mutationData?.confirmJobLive) {
      setIsLive(!!mutationData.confirmJobLive.live);
    }
  }, [mutationData]);

  return { isLive };
};

const useHasCompletedApplicationQuestionsV3 = (
  questionsData?: JobQuestionDataQuery
): boolean => {
  return useMemo(() => {
    if (!questionsData) {
      return false;
    }

    const requiredQuestions =
      questionsData.publicJob?.atsQuestions.sections
        .flatMap(section => section.questions)
        .filter(q => q.__typename !== "AtsInformation" && q.required) ?? [];

    return requiredQuestions.every(question => {
      switch (question.__typename) {
        case "BooleanAtsQuestion":
          return question.booleanAnswer === false || !!question.booleanAnswer;
        case "ConsentAtsQuestion":
          return question.consentAnswer === true;
        case "DateTimeAtsQuestion":
          return !!question.dateTimeAnswer;
        case "FileAtsQuestion":
          return !!question.fileAnswer?.url;
        case "NumberAtsQuestion":
          return !!question.numberAnswer;
        case "SelectAtsQuestion":
          return question.selectAnswers && question.selectAnswers?.length > 0;
        case "TextAtsQuestion":
          return !!question.textAnswer;
        default:
          return true;
      }
    });
  }, [questionsData]);
};

function useHasCompletedApplicationQuestions(
  questionsData: JobQuestionDataQuery | undefined,
  applicationData: JobApplicationDataQuery | undefined
): boolean {
  if (!applicationData) {
    return false;
  }

  const questions =
    questionsData?.publicJob?.questionData?.data.questions ?? [];

  const responses =
    applicationData?.latestJobApplication?.data?.data.answers ?? [];

  if (
    questionsData?.publicJob?.questionData.data.__typename ===
    "GreenhouseQuestionData"
  ) {
    const getResponse = (atsId: string | null) =>
      (responses as GreenhouseAnswerData["answers"]).find(
        ({ questionData }) => questionData.atsId === atsId
      );
    if (
      applicationData?.latestJobApplication?.data?.data.__typename ===
        "GreenhouseAnswerData" &&
      questionsData.publicJob.questionData.data.demographicQuestionSet
    ) {
      const { demographicAnswers } =
        applicationData.latestJobApplication.data.data;

      const getDemographicResponse = (atsId: string) =>
        (demographicAnswers as GreenhouseAnswerData["demographicAnswers"]).find(
          ({ questionData }) => questionData.atsId === atsId
        );

      return (
        questionsData.publicJob.questionData.data.demographicQuestionSet.questions
          .filter(({ required }) => required)
          .every(({ atsId }) => !!getDemographicResponse(atsId)) &&
        (questions as GreenhouseQuestionData["questions"])
          .filter(question => question.required)
          .every(({ atsId }) => !!getResponse(atsId))
      );
    }
    return (questions as GreenhouseQuestionData["questions"])
      .filter(question => question.required)
      .every(({ atsId }) => !!getResponse(atsId));
  }

  if (
    questionsData?.publicJob?.questionData?.data.__typename ===
    "LeverQuestionData"
  ) {
    const getResponse = (atsId: string | null) =>
      (responses as LeverAnswerData["answers"]).find(
        ({ questionData }) => questionData.atsId === atsId
      );

    return (questions as LeverQuestionData["questions"])
      .filter(question => question.required)
      .every(({ atsId }) => !!getResponse(atsId));
  }

  if (
    questionsData?.publicJob?.questionData?.data.__typename ===
    "WorkableQuestionData"
  ) {
    const getResponse = (atsId: string | null) =>
      (responses as WorkableAnswerData["answers"]).find(
        ({ questionData }) => questionData.atsId === atsId
      );

    return (questions as WorkableQuestionData["questions"])
      .filter(question => question.required)
      .every(({ atsId }) => !!getResponse(atsId));
  }

  if (
    questionsData?.publicJob?.questionData?.data.__typename ===
    "OttaQuestionData"
  ) {
    const getResponse = (atsId: string | null) =>
      (responses as OttaAnswerData["answers"]).find(
        ({ questionData }) => questionData.atsId === atsId
      );

    return (questions as OttaQuestionData["questions"])
      .filter(question => question.required)
      .every(({ atsId }) => !!getResponse(atsId));
  }

  return false;
}
export function useCheckApplicationProgress(jobId: string): {
  numberCompleted: number;
  rows: { title: string; completed: boolean }[];
} {
  const { data: profileData } = useQuery(ProfileCompletionDocument);

  const newAtsQuestions = useNewAtsQuestions(jobId);

  const { data: questionsData } = useQuery(JobQuestionDataDocument, {
    variables: { jobId },
  });

  const { data: applicationData } = useQuery(JobApplicationDataDocument, {
    variables: { jobId },
  });

  const applicationIsCompleted = useHasCompletedApplicationQuestions(
    questionsData,
    applicationData
  );

  const applicationV3IsCompleted =
    useHasCompletedApplicationQuestionsV3(questionsData);

  const user = profileData?.currentUser;
  const workExperiences = user ? user.workExperiences : [];

  const rows = [
    {
      title: "Application questions",
      completed: newAtsQuestions
        ? applicationV3IsCompleted
        : applicationIsCompleted,
    },
    {
      title: "Work experience",
      completed: workExperiences.length > 0,
    },
    {
      title: "Work experience descriptions",
      completed: workExperiences.reduce(
        (acc, { descriptions }) => (acc ? acc : descriptions.length >= 3),
        false
      ),
    },
  ];

  const numberCompleted = rows.filter(({ completed }) => completed).length;

  return { numberCompleted, rows };
}

export const useNewAtsQuestions = (jobId: string): boolean | undefined => {
  const { data: questionsData } = useQuery(JobQuestionDataDocument, {
    variables: { jobId },
  });

  const { data: answersData, loading } = useQuery(JobApplicationDataDocument, {
    variables: { jobId },
  });

  const oldAnswersExist =
    !!answersData?.latestJobApplication?.data?.data?.answers?.length;

  const atsType = questionsData?.publicJob?.questionData?.data.__typename;

  if (loading || !atsType) {
    return undefined;
  }

  if (oldAnswersExist) {
    return false;
  }
  return true;
};
