import { ReactElement, useMemo } from "react";
import styled, { css, up } from "@xstyled/styled-components";

import { VerticalSpacing } from "@otta/design";
import { BatchCard } from "@otta/search/components/BatchCard";
import { Sector, Theme } from "@otta/search/components/BatchCard/theme";
import { ThemeId } from "@otta/search/schema";

const ThemeBatchOrder: (ThemeId | "ALL_MATCHES")[] = [
  ThemeId.ApplyViaOtta,
  ThemeId.NewlyAdded,
  ThemeId.FullyRemote,
  ThemeId.FavouriteTechnologies,
  ThemeId.RecentlyFunded,
  ThemeId.PreferredSector,
  ThemeId.HasSalaries,
  ThemeId.FemaleFounders,
  ThemeId.TechForGood,
  ThemeId.TakeAnotherLook,
];

/**
 * We can't use row-gap in the CSS grid because we hide some rows dynamically
 * so we have to be old school and give everything a nice 0.5rem margin-bottom
 */
const CardGrid = styled.div`
  z-index: 1;
  display: grid;
  gap: 0.5rem;
  position: relative;
`;

const Row = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);

  ${up(
    "tablet",
    css`
      grid-template-columns: repeat(4, 1fr);
    `
  )}
`;

export type BatchCounts = Record<ThemeId, number> & {
  ALL_MATCHES: number;
};

export function toList(bc: BatchCounts): [keyof BatchCounts, number][] {
  return Object.entries(bc) as [keyof BatchCounts, number][];
}

export type BatchCardData = {
  counts: BatchCounts;
  sector: { id: string; value: string };
};

/**
 * Decide how to sort batch cards - we prioritise all matches
 * and then following the experiment 20221124_THEME_BATCH_NAVIGATION_EXPERIMENT
 * run in a priorty order as listed in PR 2757
 */

function sortOrder(
  [themeA, countA]: [keyof BatchCounts, number],
  [themeB, countB]: [keyof BatchCounts, number]
): number {
  return themeA === "ALL_MATCHES"
    ? -1
    : themeB === "ALL_MATCHES"
    ? 1
    : countA === 0
    ? 1
    : countB === 0
    ? -1
    : 0;
}

function cards(
  sector: BatchCardData["sector"],
  counts: [keyof BatchCounts, number][]
): ReactElement[] {
  counts
    .sort(
      ([themeA], [themeB]) =>
        ThemeBatchOrder.indexOf(themeA) - ThemeBatchOrder.indexOf(themeB)
    )
    .sort((a, b) => sortOrder(a, b));
  return counts.map(([key, value], index) => {
    const theme: Theme<Sector> = { id: key, sector };
    return <BatchCard theme={theme} key={index} count={value} index={index} />;
  });
}

export function BatchCards({
  counts,
  sector,
}: BatchCardData): React.ReactElement {
  const cardElements = useMemo(
    () => cards(sector, toList(counts)).slice(0, 8),
    [counts, sector]
  );

  return (
    <VerticalSpacing>
      <CardGrid>
        <Row>{cardElements.slice(0, 4)}</Row>

        <Row>{cardElements.slice(4, 8)}</Row>
      </CardGrid>
    </VerticalSpacing>
  );
}
