import { useState, useMemo } from "react";
import styled from "@xstyled/styled-components";
import {
  format,
  getMonth,
  getYear,
  setYear,
  setMonth,
  parseISO,
} from "date-fns";

import { Select } from "@otta/search/components/Input/Select";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 8px;
`;

const listOfYears = (endYear: number): { label: string; value: string }[] => {
  const years = [] as string[];
  let dateEnd = endYear;
  for (let i = 65; i > 0; i--) {
    years.push(dateEnd.toString());
    dateEnd -= 1;
  }
  return years.map(year => ({ label: year, value: year }));
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
].map((name, index) => ({ label: name, value: index }));

interface IMonthPickerProps {
  value: string | null;
  onChange: (e: { target: { value: string } }) => void;
  endYear: number;
}
interface IMonthPickerState {
  month: number | null;
  year: number | null;
}

export function MonthPicker({
  value = "",
  endYear,
  onChange,
}: IMonthPickerProps): React.ReactElement {
  const years = useMemo(() => listOfYears(endYear), [endYear]);

  const [state, setState] = useState<IMonthPickerState>(() => {
    if (value) {
      const parsedDate = parseISO(value);
      const month = getMonth(parsedDate);
      const year = getYear(parsedDate);
      return {
        month: isNaN(month) ? null : month,
        year: isNaN(year) ? null : year,
      };
    }
    return { month: null, year: null };
  });

  const handleChange = (newState: {
    month?: number | null;
    year?: number | null;
  }) => {
    const mergedState = { ...state, ...newState };

    if (mergedState.month != null && mergedState.year != null) {
      const asDate = setYear(
        setMonth(new Date(), mergedState.month),
        mergedState.year
      );
      onChange({
        target: {
          value: format(asDate, "yyyy-MM-01"),
        },
      });
    }

    setState(mergedState);
  };

  const handleChangeMonth = (e: { value: number } | null) => {
    handleChange({ month: e?.value });
  };

  const handleChangeYear = (e: { value: string } | null) => {
    handleChange({ year: e?.value ? Number(e.value) : null });
  };

  return (
    <Wrapper>
      <Select
        options={months}
        placeholder="Month"
        data-testid="month-select"
        value={months.find(
          ({ value: currentMonth }) => currentMonth === state.month
        )}
        onChange={handleChangeMonth}
      />
      <Select
        options={years}
        placeholder="Year"
        name="Year"
        value={years.find(
          ({ value: currentYear }) => Number(currentYear) === state.year
        )}
        onChange={handleChangeYear}
      />
    </Wrapper>
  );
}
