import { Spacing, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";

export function CookiePolicy(): React.ReactElement {
  return (
    <div style={{ marginTop: pxToRem(40), color: palette.grayscale.shade600 }}>
      <Spacing>
        <Text align="left">
          We use cookies to give you the best experience on our website.{" "}
          <Link
            to="https://otta.com/privacy-policy/cookie-policy"
            underline
            style={{
              textDecoration: "underline",
              color: palette.grayscale.shade600,
            }}
          >
            Find out more in our cookie policy.
          </Link>
        </Text>
      </Spacing>
    </div>
  );
}
