import { ReactNode } from "react";
import {
  ShowIcon,
  HeartOutlineIcon,
  CandidateStatusIcon,
} from "@welcome-ui/icons";

import { palette } from "@otta/design-tokens";

export interface CardItem {
  id: string;
  title: string;
  subtitle: string;
  image: ReactNode;
  buttonText: string;
  backgroundColor: string;
  link: string;
}

export function takeAnotherLook(): CardItem {
  return {
    id: "take-another-look",
    title: "Take another look",
    subtitle: "1 in 4 candidates save a job they skipped before.",
    image: <ShowIcon />,
    buttonText: "Take another look",
    backgroundColor: palette.brand.white,
    link: "/jobs/theme/take-another-look",
  };
}

export function reviewSavedJobs(): CardItem {
  return {
    id: "saved-jobs",
    title: "Review saved jobs",
    subtitle: "Apply for the jobs you've shown interest in.",
    image: <HeartOutlineIcon />,
    buttonText: "Review saved jobs",
    backgroundColor: palette.brand.white,
    link: "",
  };
}

export function reviewProfile(): CardItem {
  return {
    id: "review-profile",
    title: "Review your profile",
    subtitle: "Review and update your profile to help you stand out.",
    image: <CandidateStatusIcon />,
    buttonText: "Review profile",
    backgroundColor: palette.brand.white,
    link: "/profile",
  };
}

export function createProfile(): CardItem {
  return {
    id: "create-profile",
    title: "Create a profile",
    subtitle: "Apply for jobs faster and get seen by companies hiring.",
    image: <CandidateStatusIcon />,
    buttonText: "Create profile",
    backgroundColor: palette.brand.white,
    link: "/profile",
  };
}
