interface HomeProps extends React.SVGAttributes<SVGSVGElement> {
  active?: boolean;
}

export function Home({ active, ...props }: HomeProps): React.ReactElement {
  return active ? (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.918 10.9727C23.3125 11.3086 23.3633 11.8984 23.0273 12.293C22.6914 12.6875 22.1016 12.7383 21.707 12.4023L20.75 11.5898V18.875C20.75 20.6016 19.3516 22 17.625 22H6.375C4.64922 22 3.25 20.6016 3.25 18.875V11.5898L2.29414 12.4023C1.89922 12.7383 1.30781 12.6875 0.972655 12.293C0.637694 11.8984 0.68617 11.3086 1.08097 10.9727L11.3945 2.22266C11.7422 1.92578 12.2578 1.92578 12.6055 2.22266L22.918 10.9727ZM10.125 20.125H13.875V14.5H10.125V20.125Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.918 10.9727C23.3125 11.3086 23.3633 11.8984 23.0273 12.293C22.6914 12.6875 22.1016 12.7383 21.707 12.4023L20.75 11.5898V18.875C20.75 20.6016 19.3516 22 17.625 22H6.375C4.64922 22 3.25 20.6016 3.25 18.875V11.5898L2.29414 12.4023C1.89922 12.7383 1.30781 12.6875 0.972655 12.293C0.637694 11.8984 0.68617 11.3086 1.08097 10.9727L11.3945 2.22266C11.7422 1.92578 12.2578 1.92578 12.6055 2.22266L22.918 10.9727ZM6.375 20.125H8.25V14.1875C8.25 13.3242 8.94922 12.625 9.8125 12.625H14.1875C15.0508 12.625 15.75 13.3242 15.75 14.1875V20.125H17.625C18.3164 20.125 18.875 19.5664 18.875 18.875V10L12 4.1668L5.125 10V18.875C5.125 19.5664 5.68359 20.125 6.375 20.125ZM10.125 20.125H13.875V14.5H10.125V20.125Z"
        fill="black"
      />
    </svg>
  );
}
