import { Field } from "react-final-form";

import { BooleanQuestion } from "../types";

import { Labelled } from "./Label";
import { OptionLabel } from "./SelectField";

import { Spacing } from "@otta/design";

type FieldProps = {
  question: BooleanQuestion;
  sectionId: string;
};

export function BooleanField(props: FieldProps): React.ReactElement {
  return (
    <Labelled value={props.question.label} required={props.question.required}>
      <Radios {...props} />
    </Labelled>
  );
}

function Radios(props: FieldProps): React.ReactElement {
  return (
    <Spacing>
      {[true, false].map((value, index) => (
        <OptionLabel key={index}>
          <Field<boolean | undefined, HTMLElement, string | undefined>
            name={`s_${props.sectionId}.q_${props.question.id}.a_0.booleanValue`}
            parse={value => value === "true"}
            component="input"
            type={"radio"}
            value={value}
            initialValue={props.question.booleanAnswer ?? undefined}
          />
          {value ? "Yes" : "No"}
        </OptionLabel>
      ))}
    </Spacing>
  );
}
