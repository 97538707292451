import { useParams } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";

import { CompanyContent } from "./components/CompanyContent";
import { useCompany } from "./useCompany";

import { Loading } from "@otta/search/components/Loading";
import { pxToRem } from "@otta/design-tokens";
import { EBTrackingProvider } from "@otta/search/contexts/EBTracking";

const PageContainer = styled.div`
  max-width: ${pxToRem(1164)};
  margin: 0 auto;

  ${up(
    "tablet",
    css`
      padding: 0 14;
    `
  )}
`;

export function CompanyInfo(): React.ReactElement | null {
  const { companyUrlSafeName } = useParams();

  const { data, loading } = useCompany(companyUrlSafeName as string);

  if (loading) {
    return <Loading />;
  }

  const company = data?.company;

  if (!company) {
    return null;
  }

  return (
    <EBTrackingProvider page="company" company={company}>
      <PageContainer data-testid="company-info">
        <CompanyContent company={company} />
      </PageContainer>
    </EBTrackingProvider>
  );
}
