import { useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { FormCardList } from "../../components/FormCardList";
import { CardSubtitle } from "../../components/FormHeader";

import {
  DisplayEducationExperience,
  EditingEducationExperience,
} from "./EducationExperience";

import { palette } from "@otta/design-tokens";
import { Spacing, Text } from "@otta/design";
import { Dates } from "@otta/search/components/Dates";
import {
  EducationExperiencesDocument,
  CreateEducationExperienceDocument,
  UpdateEducationExperienceDocument,
  DeleteEducationExperienceDocument,
  EducationExperiencesQuery,
  UserEducationExperienceInput,
  EducationExperiences as EducationExperiencesTypes,
  ProfileCompletionStatusDocument,
} from "@otta/search/schema";
import { CompletionStatus } from "@otta/search/pages/Profile/components/CompletionStatusTags";

const Padding = styled.div`
  margin-left: xs;
`;

export function EducationExperiences(): React.ReactElement {
  const [updateMutation] = useMutation(UpdateEducationExperienceDocument);
  const [createMutation] = useMutation(CreateEducationExperienceDocument, {
    refetchQueries: [
      { query: EducationExperiencesDocument },
      { query: ProfileCompletionStatusDocument },
    ],
    awaitRefetchQueries: true,
  });
  const [deleteMutation] = useMutation(DeleteEducationExperienceDocument, {
    refetchQueries: [
      { query: EducationExperiencesDocument },
      { query: ProfileCompletionStatusDocument },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <div id="education-form-id">
      <FormCardList
        title="Education"
        introText="Add your highest level of formal education and any relevant professional qualifications."
        query={EducationExperiencesDocument}
        formatTitle={({
          institutionName,
          fieldOfStudy,
          grade,
          educationType,
        }) => (
          <Spacing size={-4}>
            <Text data-cs-mask>
              <strong>{institutionName} </strong>
              {educationType && `(${educationType})`}
            </Text>
            <CardSubtitle
              style={{ color: palette.grayscale.shade600 }}
              data-cs-mask
            >
              {fieldOfStudy}
              {grade && ` (${grade})`}
            </CardSubtitle>
          </Spacing>
        )}
        formatDates={({ startDate, endDate }) => (
          <Padding>
            {startDate && (
              <Dates
                startDate={startDate}
                endDate={endDate}
                dateFormat="MMM yyyy"
              />
            )}
          </Padding>
        )}
        formatQueryData={({ currentUser }: EducationExperiencesQuery) =>
          currentUser?.educationExperiences ?? []
        }
        formatIncomingData={(
          experience: EducationExperiencesTypes.EducationExperiences
        ) => ({
          ...experience,
          descriptions: experience.descriptions.map(({ value }) => value),
        })}
        formatOutgoingData={({
          institutionName,
          educationType,
          fieldOfStudy,
          grade,
          startDate,
          endDate,
          descriptions,
        }): UserEducationExperienceInput => ({
          institutionName,
          educationType,
          fieldOfStudy,
          grade,
          startDate,
          endDate,
          descriptions: (descriptions?.length > 0 ? descriptions : [])
            .filter(x => x.length > 1)
            .map(value => ({ value })),
        })}
        onCreate={async (input: UserEducationExperienceInput) =>
          createMutation({ variables: { input } })
        }
        onUpdate={id => async (input: UserEducationExperienceInput) =>
          updateMutation({ variables: { id, input } })}
        onDelete={id => async () => deleteMutation({ variables: { id } })}
        editingComponent={EditingEducationExperience}
        displayComponent={DisplayEducationExperience}
        formatCompletionStatus={({ currentUser }: EducationExperiencesQuery) =>
          currentUser?.educationExperiences &&
          currentUser.educationExperiences.length > 0
            ? CompletionStatus.complete
            : CompletionStatus.notStarted
        }
        recommended
      />
    </div>
  );
}
