import { Spacing, Text, List } from "@otta/design";
import { ApplyModalJob } from "@otta/search/schema";

interface InternalApplicationSummaryProps {
  company: ApplyModalJob.Company;
}

export const InternalApplicationSummary = ({
  company: { name },
}: InternalApplicationSummaryProps): React.ReactElement => {
  return (
    <Spacing>
      <Text bold align="center">
        {name} is accepting applications via Welcome to the Jungle because we
        help candidates write great applications
      </Text>
      <List type="positive">
        <li>
          Companies prefer receiving applications through Welcome to the Jungle
        </li>
        <li>We&apos;ll guide you to write a great application and stand out</li>
      </List>
    </Spacing>
  );
};
