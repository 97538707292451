import { Routes, Route, useParams } from "react-router-dom";

import Application from "../Profile/Application/index";
import ApplicationPreview from "../Profile/Application/Preview";

import ApplicationView from "./Applications/ApplicationView";
import { JobContent } from "./JobCard/tabs/JobContent";
import { CompanyProfile } from "./JobCard/tabs/CompanyProfile";

import Jobs from ".";

import { CandidateRoute } from "@otta/search/utils/routing/CandidateRoute";
import { PreferencesSetRoute } from "@otta/search/utils/routing/PreferencesSetRoute";
import { Redirect } from "@otta/search/router";

function RedirectFromSectors() {
  const { sectorTagId, jobId } = useParams();

  return (
    <Redirect
      to={{
        pathname: jobId ? `/jobs/${jobId}` : "/jobs",
        search: `?theme=preferred-sector&sector-id=${sectorTagId}`,
      }}
    />
  );
}

function RedirectTheme() {
  const { themeId, jobId } = useParams();

  return (
    <Redirect
      to={{
        pathname: jobId ? `/jobs/${jobId}` : "/jobs",
        search: `?theme=${themeId}`,
      }}
    />
  );
}

export default function JobsRoutes(): React.ReactElement {
  return (
    <Routes>
      <Route path="saved" element={<Redirect to="/" />} />
      <Route path="preferences" element={<Redirect to="/preferences" />} />
      <Route
        path=":jobId/apply"
        element={
          <CandidateRoute>
            <Application />
          </CandidateRoute>
        }
      >
        <Route>
          <Route index element={<JobContent />} />
          <Route path="company" element={<CompanyProfile />} />
        </Route>
      </Route>
      <Route
        path=":jobId/application"
        element={
          <CandidateRoute>
            <ApplicationView />
          </CandidateRoute>
        }
      />
      <Route
        path=":jobId/apply/preview"
        element={
          <CandidateRoute>
            <ApplicationPreview />
          </CandidateRoute>
        }
      />
      <Route
        path="theme/preferred-sector/:sectorTagId"
        element={<RedirectFromSectors />}
      />
      <Route
        path="theme/preferred-sector/:sectorTagId/:jobId"
        element={<RedirectFromSectors />}
      />
      <Route path="theme/:themeId" element={<RedirectTheme />} />
      <Route path="theme/:themeId/:jobId" element={<RedirectTheme />} />
      <Route path="sector/:sectorTagId" element={<RedirectFromSectors />} />
      <Route
        path="sector/:sectorTagId/:jobId"
        element={<RedirectFromSectors />}
      />
      <Route
        path="*"
        element={
          <PreferencesSetRoute
            element={<Jobs />}
            fallback={<Jobs isPublic />}
          />
        }
      />
    </Routes>
  );
}
