import { useEffect, useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { ContextQuestions } from "./sections/ContextQuestions";
import { EducationExperiences } from "./sections/EducationExperiences";
import { Interests } from "./sections/Interests";
import { PersonalDetails } from "./sections/PersonalDetails";
import { Links } from "./sections/Links";
import { Snippets } from "./sections/Snippets";
import { WorkExperiences } from "./sections/WorkExperiences";
import { CompletionStatus } from "./CompletionStatus";
import { ProfileTop } from "./sections/ProfileTop";
import { ImportedRating } from "./ImportedRating";

import { modularScale, pxToRem } from "@otta/design-tokens";
import { Spacing } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import { ProfileCompletionStatusDocument } from "@otta/search/schema";

const Wrapper = styled.div`
  max-width: ${pxToRem(1024)};
  width: 100%;
  padding: 0 lg;
  margin: 0 auto;
  padding-bottom: lg;

  ${up(
    "tablet",
    css`
      display: grid;
      grid-template-columns: 28% auto;
      grid-template-rows: auto;
      grid-template-areas:
        "left header"
        "left right";
      gap: xxl;
    `
  )}
`;

const DesktopLeftColumn = styled.div`
  grid-area: left;
  margin-bottom: lg;
  ${up(
    "tablet",
    css`
      display: block;
      padding-top: xl;
      position: sticky;
      align-self: flex-start;
      top: 0;
    `
  )}
`;

const DesktopRightColumn = styled.div`
  grid-area: right;
`;
const DesktopRightHeader = styled.div`
  grid-area: header;
  padding-top: xl;
  margin-bottom: lg;
`;

export default function Profile(): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();

  const [showImportedRating, setShowImportedRating] = useState(false);

  const imported = !!location.state?.imported;

  useEffect(() => {
    setShowImportedRating(imported);
  }, [imported]);

  useEffect(() => {
    pushAnalyticsEvent({ eventName: "Candidate Viewed Profile Page" });
  }, []);

  const { data: profileCompletionData } = useQuery(
    ProfileCompletionStatusDocument,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const userType = profileCompletionData?.me?.__typename;

  const profileStatus =
    userType === "CurrentCandidate" || userType == "CurrentAdmin"
      ? profileCompletionData?.me?.profileStatus
      : null;

  const completedWorkExperience = profileStatus?.completedFields?.includes(
    "work_experience_descriptions"
  );

  return (
    <Wrapper>
      <DesktopRightHeader>
        <ProfileTop />
      </DesktopRightHeader>

      <DesktopLeftColumn>
        <AnimatePresence initial={false}>
          {showImportedRating && (
            <motion.div
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              style={{ marginBottom: modularScale(2) }}
            >
              <ImportedRating
                onClick={() => {
                  navigate(".", { replace: true });
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
        {profileStatus && <CompletionStatus profileStatus={profileStatus} />}
      </DesktopLeftColumn>

      <DesktopRightColumn>
        <Spacing size={2}>
          <WorkExperiences completedWorkExperience={completedWorkExperience} />
          <EducationExperiences />
          <Snippets />
          <ContextQuestions />
          <Interests />
          <Links />
          <PersonalDetails />
        </Spacing>
      </DesktopRightColumn>
    </Wrapper>
  );
}
