export const Star = (
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement => (
  <svg
    version="1.1"
    viewBox="0 0 79.7 79.7"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m39.9 53.9 10.6-7.7 6.6 20.2zm27.9-20.3h-21.4l-6.6-20.3-6.6 20.3h-21.4l17.3 12.6-6.6 20.3 17.3-12.5 10.7-7.8z"
      fill="#fff"
    />
  </svg>
);
