import { useCallback, useMemo, useState } from "react";
import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import { Button, FieldContainer, Label, Text, Spacing } from "@otta/design";
import * as locations from "@otta/locations";
import { Select } from "@otta/search/components/Input/Select";
import {
  AccountSettings,
  UpdateCurrentUserDocument,
} from "@otta/search/schema";
import { pxToRem } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";

type LocationSettingsName = "region" | "subregion";

const toLabelAndValuePairs = (
  input: Record<string, string>
): { label: string; value: string }[] =>
  Object.entries(input).map(([value, label]) => ({ value, label }));

const LocationField = ({
  name,
  label,
  value,
  options,
  onChange,
  onCancel,
}: {
  name: LocationSettingsName;
  label: string;
  value: string | null;
  options: { label: string; value: string }[];
  onChange: (event: { label: string; value: string } | null) => void;
  onCancel: () => void;
}) => {
  const selected = useMemo(
    () => options.find(o => o.value === value) || null,
    [value, options]
  );

  return (
    <div data-testid={`${name}-field`}>
      <FieldContainer>
        {name === "region" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: pxToRem(8),
            }}
          >
            <Label id={`${name}-label`}>Country</Label>
            <Text
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                userSelect: "none",
              }}
              onClick={onCancel}
            >
              Cancel
            </Text>
          </div>
        ) : (
          <Label htmlFor="sub-region" id={`${name}-label`}>
            {label}
          </Label>
        )}
        <Select
          name={name}
          id={name}
          aria-labelledby={`${name}-label`}
          value={selected}
          options={options}
          onChange={onChange}
        />
      </FieldContainer>
    </div>
  );
};

export const LocationSettings = ({
  currentUser,
  onCancel,
}: {
  currentUser: AccountSettings.CurrentUser;
  onCancel: () => void;
}): React.ReactElement => {
  const [region, setRegion] = useState(currentUser.region);
  const [subregion, setSubregion] = useState(currentUser.subregion);

  const [updateMutation] = useMutation(UpdateCurrentUserDocument);

  const sendAnalytics = useCallback(() => {
    if (currentUser.region !== region) {
      pushAnalyticsEvent({
        eventName: `Candidate Updated region`,
        from: currentUser.region,
        to: region,
      });
    }

    if (subregion && currentUser.subregion !== subregion) {
      pushAnalyticsEvent({
        eventName: `Candidate Updated subregion`,
        from: currentUser.subregion,
        to: subregion,
      });
    }
  }, [currentUser.region, currentUser.subregion, region, subregion]);

  const handleSave = useCallback(() => {
    const values = subregion
      ? { region: region, subregion: subregion }
      : { region: region };
    sendAnalytics();

    updateMutation({
      variables: { input: values },
    }).then(() => {
      onCancel();
    });
  }, [updateMutation, region, subregion, onCancel, sendAnalytics]);

  const regionOptions = useMemo(() => {
    return toLabelAndValuePairs(locations.regions);
  }, []);

  const includeSubregion = useMemo(
    () => region && Object.keys(locations.subregions).includes(region),
    [region]
  );

  const subregionOptions = useMemo(() => {
    return region && includeSubregion
      ? toLabelAndValuePairs(locations.subregions[region])
      : [];
  }, [includeSubregion, region]);

  const subregionLabel = useMemo(() => {
    return region === "US"
      ? "State"
      : region === "CA"
      ? "Province or territory"
      : "Subregion";
  }, [region]);

  const SaveButton = styled(Button)`
    width: 100%;
  `;

  return (
    <Spacing>
      <Spacing size={-1}>
        <LocationField
          value={region}
          name="region"
          label="Country"
          options={regionOptions}
          onCancel={onCancel}
          onChange={event => {
            if (event && event.value !== region) {
              setRegion(event.value);
              setSubregion(null);
            }
          }}
        />
        {includeSubregion && (
          <LocationField
            value={subregion}
            name="subregion"
            label={subregionLabel}
            options={subregionOptions}
            onCancel={() => undefined}
            onChange={event => {
              if (event && event.value !== subregion) {
                setSubregion(event.value);
              }
            }}
          />
        )}
        <SaveButton onClick={() => handleSave()} level="primary">
          Save
        </SaveButton>
      </Spacing>
    </Spacing>
  );
};
