import { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { ApolloError, MutationUpdaterFn, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { parseISO } from "date-fns";
import { useUp } from "@xstyled/styled-components";

import { BannerNotification } from "./Notifications/Banners";
import { ShortlistSection } from "./ShortlistSection";
import { SectionTitle } from "./Section";
import { MobileTabs } from "./MobileTabs";
import { DiscardedJobs } from "./sections/DiscardedJobs";
import { ThemedBatches } from "./ThemedBatches";
import { SpotlightContent } from "./SpotlightContent";

import { modularScale } from "@otta/design-tokens";
import { Middle, Spacing } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import {
  DashboardDocument,
  UpdateShortlistUserJobStatusDocument,
  CreateJobReactionDocument,
  UserJobStatus,
  CreateJobReactionMutation,
  Dashboard as DashboardTypes,
  BatchEndShortlistedJobs,
  RecommendedCompaniesDocument,
} from "@otta/search/schema";
import { Confetti } from "@otta/search/components/Confetti";
import { handleMutationError } from "@otta/search/utils/error";

export type Section =
  | "SAVED"
  | "APPLICATION_STARTED"
  | "APPLIED"
  | "INTERVIEWING"
  | "OFFER_RECEIVED"
  | "OFFER_ACCEPTED"
  | "DISCARDED";

const SECTIONS = [
  UserJobStatus.Saved,
  UserJobStatus.ApplicationStarted,
  UserJobStatus.Applied,
  UserJobStatus.Interviewing,
  UserJobStatus.OfferReceived,
  UserJobStatus.OfferAccepted,
] as const;

const SECTION_TITLES = {
  [UserJobStatus.Saved]: "Saved",
  [UserJobStatus.ApplicationStarted]: "Application started",
  [UserJobStatus.Applied]: "Applied",
  [UserJobStatus.Interviewing]: "Interviewing",
  [UserJobStatus.OfferReceived]: "Offer received",
  [UserJobStatus.OfferAccepted]: "Offer accepted",
} as const;

const discardJobFromCache =
  (id: string): MutationUpdaterFn<CreateJobReactionMutation> =>
  (cache, result) => {
    const cacheData = cache.readQuery({
      query: DashboardDocument,
    });

    if (!cacheData?.currentUser || !result.data?.createJobReaction) {
      return;
    }

    const { shortlistedJobs = [], discardedJobs = [] } = cacheData.currentUser;

    const job = shortlistedJobs.find(j => j.id === id);

    if (!job) {
      return;
    }

    cache.writeQuery({
      query: DashboardDocument,
      data: {
        ...cacheData,
        currentUser: {
          ...cacheData.currentUser,
          shortlistedJobs: shortlistedJobs.filter(j => j.id !== id),
          discardedJobs: [job, ...discardedJobs],
        },
      },
    });
  };

const getTime = (date: Date): number => {
  return date != null ? date.getTime() : 0;
};

// TODO remove once we also have spotlight content on the batch end card
export const compareJobs = (
  jobA:
    | DashboardTypes.ShortlistedJobs
    | BatchEndShortlistedJobs.ShortlistedJobs,
  jobB: DashboardTypes.ShortlistedJobs | BatchEndShortlistedJobs.ShortlistedJobs
): number =>
  getTime(
    jobB.userJobStatusInfo.updatedAt
      ? parseISO(jobB.userJobStatusInfo.updatedAt)
      : new Date()
  ) -
  getTime(
    jobA.userJobStatusInfo.updatedAt
      ? parseISO(jobA.userJobStatusInfo.updatedAt)
      : new Date()
  );

const getSections = (shortlistedJobs: DashboardTypes.ShortlistedJobs[]) =>
  [...shortlistedJobs]
    .sort(compareJobs)
    .reduce<Record<UserJobStatus, DashboardTypes.ShortlistedJobs[]>>(
      (acc, job) => {
        const section = acc[job.userJobStatusInfo.status];

        if (Array.isArray(section)) {
          section.push(job);
        }

        return acc;
      },
      {
        [UserJobStatus.Saved]: [],
        [UserJobStatus.ApplicationStarted]: [],
        [UserJobStatus.Applied]: [],
        [UserJobStatus.Interviewing]: [],
        [UserJobStatus.OfferReceived]: [],
        [UserJobStatus.OfferAccepted]: [],
        [UserJobStatus.NotSaved]: [],
      }
    );

export type JobChangeHandler = (args: {
  id: string;
  section: Section;
  newSection: Section;
}) => void;

const handleUpdateShortlistStatusError = (error: ApolloError) => {
  if (error.message.includes("Cannot unsubmit")) {
    toast.error("You cannot unsubmit a submitted application");
  } else {
    handleMutationError(error);
  }
};

export default function Dashboard(): React.ReactElement {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const [activeSection, setActiveSection] = useState<Section>("SAVED");
  const [showConfetti, setShowConfetti] = useState(false);
  const [firstVisit, setFirstVisit] = useState(false);

  const isTablet = useUp("tablet");

  const { data, loading, fetchMore } = useQuery(DashboardDocument);
  const { data: spotlightData, loading: spotlightLoading } = useQuery(
    RecommendedCompaniesDocument
  );

  const [updateJobStatusMutation] = useMutation(
    UpdateShortlistUserJobStatusDocument,
    {
      optimisticResponse: ({ jobId, status }) => ({
        updateShortlistUserJobStatus: {
          id: jobId,
          userJobStatusInfo: {
            __typename: "UserJobStatusInfo" as const,
            status,
            updatedAt: new Date().toISOString(),
          },
          __typename: "Job" as const,
        },
      }),
      onError: handleUpdateShortlistStatusError,
    }
  );

  const [createJobReactionMutation] = useMutation(CreateJobReactionDocument, {
    optimisticResponse: ({ direction, reason = null }) => ({
      createJobReaction: {
        __typename: "JobReaction" as const,
        direction,
        reason,
        updatedAt: new Date().toISOString(),
        id: Math.ceil(Math.random() * 1_000_000).toString(),
      },
    }),
  });

  useEffect(() => {
    if (localStorage.getItem("first_visit") === "true") {
      setFirstVisit(true);
      localStorage.removeItem("first_visit");
    }
  }, []);

  const mobileTabsTopRef = useRef<HTMLHeadingElement>(null);

  const updateJobStatus = useCallback<JobChangeHandler>(
    ({ id, section, newSection }) => {
      if (newSection === "DISCARDED") {
        createJobReactionMutation({
          variables: {
            jobId: id,
            direction: newSection !== "DISCARDED",
            reason:
              section === "SAVED" || section === "APPLICATION_STARTED"
                ? null
                : section === "APPLIED"
                ? "no_interview"
                : "no_offer",
          },
          update: discardJobFromCache(id),
        });
      } else {
        updateJobStatusMutation({
          variables: {
            jobId: id,
            status: newSection as UserJobStatus,
          },
        });
        if (
          (section !== "OFFER_ACCEPTED" && newSection === "OFFER_RECEIVED") ||
          newSection === "OFFER_ACCEPTED"
        ) {
          setShowConfetti(true);
        }
      }
    },
    [updateJobStatusMutation, createJobReactionMutation]
  );

  useEffect(() => {
    const jobId = search.get("job_id");
    const applied = search.get("applied");
    if (typeof jobId === "string" && applied === "true") {
      updateJobStatus({
        id: jobId,
        newSection: "APPLIED",
        section: "SAVED",
      });
      navigate("/", { replace: true });
      toast.success("We've updated your progress for this job");
    }
  }, [search, navigate, updateJobStatus]);

  const {
    firstName,
    shortlistedJobs = [],
    discardedJobs = [],
  } = data?.currentUser ?? {};

  const jobsForSections = useMemo(
    () => getSections(shortlistedJobs),
    [shortlistedJobs]
  );

  const hiddenSections: Partial<Record<UserJobStatus, boolean>> = {
    [UserJobStatus.ApplicationStarted]:
      jobsForSections.APPLICATION_STARTED.length === 0,
    [UserJobStatus.Interviewing]:
      jobsForSections.INTERVIEWING.length === 0 &&
      jobsForSections.APPLIED.length === 0,
    [UserJobStatus.OfferReceived]: jobsForSections.OFFER_RECEIVED.length === 0,
    [UserJobStatus.OfferAccepted]: jobsForSections.OFFER_ACCEPTED.length === 0,
  };

  const greeting = `Welcome${!firstVisit ? " back" : ""}, ${
    firstName ? firstName : ""
  }`;

  const companyIds = useMemo(
    () =>
      (spotlightData?.currentUser?.jobRecommendations || []).map(
        ({ job }) => job.company.externalId
      ),
    [spotlightData]
  );

  return (
    <div>
      <BannerNotification />
      <ThemedBatches greeting={loading ? null : greeting} />
      <Middle
        maxWidth={1170}
        textAlign="inherit"
        style={{ padding: modularScale(-1) }}
      >
        <Spacing size={1}>
          {!spotlightLoading && !!companyIds.length && (
            <SpotlightContent companyIds={companyIds} isMobile={!isTablet} />
          )}
          {data && !loading ? (
            isTablet ? (
              <Spacing size={3}>
                {SECTIONS.map(section => {
                  if (hiddenSections[section]) {
                    return null;
                  }

                  const jobs = jobsForSections[section];

                  return (
                    <ShortlistSection
                      key={section}
                      title={SECTION_TITLES[section]}
                      jobs={jobs}
                      section={section}
                      onChange={updateJobStatus}
                      isMobile={!isTablet}
                    />
                  );
                })}
                <DiscardedJobs jobs={discardedJobs} fetchMore={fetchMore} />
              </Spacing>
            ) : (
              <>
                <div ref={mobileTabsTopRef}>
                  <SectionTitle bold as="h3">
                    Your progress
                  </SectionTitle>
                </div>
                <MobileTabs
                  tabs={{ ...SECTION_TITLES, DISCARDED: "Seen" }}
                  activeSection={activeSection}
                  updateActive={setActiveSection}
                  mobileTabsTopRef={mobileTabsTopRef}
                  hiddenSections={hiddenSections}
                />
                {activeSection === "DISCARDED" ? (
                  <DiscardedJobs jobs={discardedJobs} fetchMore={fetchMore} />
                ) : (
                  <ShortlistSection
                    title={SECTION_TITLES[activeSection]}
                    jobs={jobsForSections[activeSection]}
                    section={activeSection}
                    onChange={updateJobStatus}
                    isMobile
                  />
                )}
              </>
            )
          ) : (
            <Loading />
          )}
        </Spacing>
      </Middle>
      {showConfetti && (
        <Confetti
          onComplete={() => setShowConfetti(false)}
          data-testid="confetti"
        />
      )}
      <Outlet />
    </div>
  );
}
