import styled from "@xstyled/styled-components";

import { Spacing, Text, List } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { SignupForm } from "@otta/search/pages/Signup/SignupForm";

const Wrapper = styled.div`
  margin: lg auto md;
  padding: 0 10;
  max-width: ${pxToRem(390)};
`;

const SignupWrapper = styled.div`
  margin: md auto;
`;

interface IProps {
  job: {
    originalUrl?: string | null;
    externalId: string;
    company: { name: string };
  };
}

export function PublicInternalApplyModalContent({
  job,
}: IProps): React.ReactElement {
  return (
    <Wrapper>
      <Spacing>
        <Text bold align="center">
          {job.company.name} is accepting applications via Welcome to the Jungle
          which means you need to sign up
        </Text>
        <List type="positive">
          <li>Access jobs from 1000s of the world&apos;s top startups</li>
          <li>
            Companies prefer receiving applications through Welcome to the
            Jungle
          </li>
        </List>
      </Spacing>
      <SignupWrapper>
        <SignupForm redirect={`/jobs/${job.externalId}/apply`} />
      </SignupWrapper>
    </Wrapper>
  );
}
