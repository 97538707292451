import { Spacing, Text } from "@otta/design";
import { ExternalLink } from "@otta/search/components/ExternalLink";

interface ILinkQuestionResponseProps {
  question: string;
  fileUrl: string;
  linkName: string;
}

export function LinkQuestionResponse({
  question,
  fileUrl,
  linkName,
}: ILinkQuestionResponseProps): React.ReactElement | null {
  return (
    <Spacing size={-2}>
      <Text bold align="left">
        {question}
      </Text>
      <ExternalLink
        newTab
        to={fileUrl}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Text size={-1}>{linkName}</Text>
      </ExternalLink>
    </Spacing>
  );
}
