import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ErrorText } from "..";

import { CategoryResponseEditField } from "./CategoryResponseEditField";
import { Tag } from "./CategoryTag";

import { Card, Middle, Spacing, Text } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import {
  CompanyEmployeeSurveyDocument,
  CompanyEmployeeSurvey,
} from "@otta/search/schema";
import { QuestionComponentProps } from "@otta/search/containers/Quiz";

export function CategoryResponse({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement | null {
  const [currentResponse, setCurrentResponse] = useState<
    CompanyEmployeeSurvey.Responses | undefined
  >();
  const { surveyExternalId, question } = useParams();

  const { data: surveyData } = useQuery(CompanyEmployeeSurveyDocument, {
    variables: { externalId: surveyExternalId as string },
  });

  const survey = surveyData?.companyEmployeeSurvey;
  const responses = survey?.responses;

  useEffect(() => {
    const responseIndex = parseInt(question as string, 10);

    if (responses && !isNaN(responseIndex)) {
      const response = responses[responseIndex - 1];
      if (response) {
        setCurrentResponse(response);
      }
    }
  }, [responses, question]);

  if (!survey) {
    return null;
  }

  if (survey.submittedAt) {
    return (
      <ErrorText>
        This endorsement has already been submitted. Thank you
      </ErrorText>
    );
  }
  if (currentResponse) {
    return (
      <Spacing>
        <Middle>
          <Tag selected>{currentResponse.category.name}</Tag>
        </Middle>
        <CategoryResponseEditField
          surveyExternalId={surveyExternalId as string}
          response={currentResponse}
          setNextEnabled={setNextEnabled}
        />
        <Text bold size={-1}>
          Here&apos;s an example
        </Text>
        <Card>
          <Text style={{ fontStyle: "italic" }}>
            &ldquo;{currentResponse.category.placeholderText}&rdquo;
          </Text>
        </Card>
      </Spacing>
    );
  }
  return null;
}
