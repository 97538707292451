import styled from "@xstyled/styled-components";

const ImagePreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const ImagePreview = ({
  src,
  className,
}: {
  src: string;
  className?: string;
}): React.ReactElement => (
  <ImagePreviewContainer className={className}>
    <Image data-testid="preview-image" src={src} alt="upload" />
  </ImagePreviewContainer>
);
