import styled, { css, up } from "@xstyled/styled-components";
import { useMemo, useState } from "react";
import { format } from "date-fns";

import { Carousel } from "../Carousel";

import { Card, Paragraph, Spacing, Text } from "@otta/design";
import { Company } from "@otta/search/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const ScrollableCategories = styled.div`
  display: flex;
  overflow: scroll;
  width: 100%;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  gap: xs;
`;

const Tag = styled(Text)<{ backgroundColor: string }>`
  border-radius: 4;
  padding: xs sm;
  display: flex;
  gap: xs;
  line-height: normal;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ReviewCard = styled(Card)`
  border: ${pxToRem(1)} solid ${palette.grayscale.shade200};
  min-width: 85%;
  scroll-snap-align: start;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${up(
    "desktop",
    css`
      min-width: 100%;
    `
  )}
`;

const ReviewTagsContainer = styled.div`
  display: flex;
  gap: xs;
  line-height: normal;
  flex-wrap: wrap;
`;

const CarouselWrapper = styled.div`
  ${up(
    "desktop",
    css`
      padding-right: xl;
    `
  )}

  margin-top: xl;
`;

export const shouldDisplayEndorsements = (
  company: EndorsementProps["company"]
) => {
  return !!company.numberEmployeeSurveys && company.numberEmployeeSurveys > 0;
};

interface EndorsementProps {
  company: Pick<
    Company.Fragment,
    "employeeSurveys" | "numberEmployeeSurveys" | "responseCountByCategory"
  >;
}

interface FilteredResponse {
  category: string;
  content: string;
  jobFunction: string;
  submittedAt: string;
}

export function Endorsements({
  company,
}: EndorsementProps): React.ReactElement | null {
  const [selectedCategory, setCategory] = useState("All");

  const filteredResponses = useMemo(() => {
    const allResponses = [] as FilteredResponse[];

    [...company.employeeSurveys].forEach(survey => {
      survey.responses.forEach(response => {
        if (
          (selectedCategory === "All" ||
            response.category.name === selectedCategory) &&
          response.content
        ) {
          allResponses.push({
            content: response.content,
            category: response.category.name,
            submittedAt: survey.submittedAt
              ? format(new Date(survey.submittedAt), "MMM yyyy")
              : "N/A",
            jobFunction: survey.jobFunction.value,
          });
        }
      });
    });

    if (!shouldDisplayEndorsements(company)) {
      return null;
    }

    return allResponses.map((response, idx) => (
      <ReviewCard key={`response-${idx}`}>
        <Spacing>
          <Paragraph>{response.content}</Paragraph>
          <ReviewTagsContainer>
            <Tag backgroundColor={palette.brand.yellow} bold>
              <Icon icon="users" />
              {response.jobFunction}
            </Tag>

            <Tag backgroundColor={palette.brand.green} bold>
              <Icon icon="heart" />
              {response.category}
            </Tag>

            <Tag backgroundColor={palette.grayscale.shade200} bold>
              <Icon icon="clock" />
              {response.submittedAt}
            </Tag>
          </ReviewTagsContainer>
        </Spacing>
      </ReviewCard>
    ));
  }, [company, selectedCategory]);

  return (
    <div>
      <Spacing>
        <Text bold as="h2" size={1}>
          Endorsements
        </Text>
        <ScrollableCategories tabIndex={0}>
          <Tag
            tabIndex={0}
            onKeyDown={e => {
              if (e.code === "Enter") {
                setCategory("All");
              }
            }}
            style={{ flexShrink: 0 }}
            bold
            backgroundColor={
              selectedCategory === "All"
                ? palette.brand.yellow
                : palette.brand.grey
            }
            onClick={() => setCategory("All")}
            key="endorsements-all"
          >
            All
          </Tag>
          {company.responseCountByCategory.map(category => (
            <Tag
              style={{ flexShrink: 0 }}
              tabIndex={0}
              bold
              onKeyDown={e => {
                if (e.code === "Enter") {
                  setCategory(category.categoryName);
                }
              }}
              backgroundColor={
                selectedCategory === category.categoryName
                  ? palette.brand.yellow
                  : palette.brand.grey
              }
              onClick={() => setCategory(category.categoryName)}
              key={`endorsements-${category.categoryName}`}
            >
              {category.categoryName} ({category.count})
            </Tag>
          ))}
        </ScrollableCategories>
      </Spacing>
      <CarouselWrapper>
        <Carousel cards={filteredResponses ?? []} />
      </CarouselWrapper>
    </div>
  );
}
