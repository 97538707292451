import styled from "@xstyled/styled-components";

import { Spacing, Text } from "@otta/design";
import { Company, Job } from "@otta/search/schema";

const OfficeLocationContainer = styled.div`
  display: flex;
  gap: 6;
  flex-flow: row wrap;
`;

export function CompanyLocation({
  job,
  company,
}: {
  job: Pick<Job.Fragment, "locationPreferences">;
  company: Pick<Company.Fragment, "parsedHqAddress">;
}): React.ReactElement | null {
  if (job.locationPreferences.length === 0) {
    return null;
  }
  return <CompanyLocationComponent company={company} />;
}

function CompanyLocationComponent({
  company: { parsedHqAddress },
}: {
  company: Pick<Company.Fragment, "parsedHqAddress">;
}): React.ReactElement | null {
  if (!parsedHqAddress) {
    return null;
  }

  return (
    <Spacing>
      <Text as="h3" size={1} bold>
        Company HQ
      </Text>
      <OfficeLocationContainer>
        <Text> {parsedHqAddress} </Text>
      </OfficeLocationContainer>
    </Spacing>
  );
}
