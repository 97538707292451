import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { FieldValidator } from "final-form";

import { CookiePolicy } from "./CookiePolicy";
import { SettingContainer } from "./SettingContainer";
import { SettingsInputType, SettingsName } from "./SettingsField";
import { NewNotificationSettings } from "./NewNotificationSettings/index";

import { palette, pxToRem } from "@otta/design-tokens";
import {
  Button as DefaultButton,
  Card,
  Heading,
  Middle,
  Spacing,
  Text,
} from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import {
  composeValidators,
  minLength,
  required,
  requiredField,
  validEmail,
  validLinkedinProfileUrl,
} from "@otta/search/utils/validators";
import { PageWrapper } from "@otta/search/components/PageWrapper";
import {
  AccountSettings,
  AccountSettingsDocument,
  NotificationFrequency,
  UpdateCurrentUserDocument,
} from "@otta/search/schema";

const Button = styled(DefaultButton)<{ disabled: boolean }>`
  border-color: ${({ disabled }) => (disabled ? palette.brand.grey : "auto")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  width: 100%;
`;

const CardsContainer = styled.div`
  > div {
    flex: 1 1 ${pxToRem(387)};
  }

  display: flex;
  flex-wrap: wrap;
  gap: xl;
`;

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: xl;
`;

const SettingsContainer = styled.div`
  padding: 0 lg;
`;
type FieldValue =
  | { name: "name"; firstName: string; lastName: string }
  | { name: Exclude<SettingsName, "name">; value: string };

export type EditableField = FieldValue & {
  name: SettingsName;
  title: string;
  type: SettingsInputType;
  validators?: FieldValidator<string>;
  placeholder?: string;
};

const SettingsForm = ({
  currentUser,
  setEditingField,
  editingField,
}: {
  currentUser: AccountSettings.CurrentUser;

  setEditingField: (field: string | null) => void;
  editingField: string | null;
}) => {
  const [updateMutation] = useMutation(UpdateCurrentUserDocument);
  const [fields, setFields] = useState<EditableField[]>([
    {
      name: "email",
      title: "Email",
      type: "email",
      value: currentUser.email,
      validators: composeValidators(
        requiredField("email"),
        minLength(5),
        validEmail
      ),
    },
    {
      name: "name",
      title: "Name",
      type: "text",
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
    },
    {
      name: "linkedinUrl",
      title: "LinkedIn URL",
      type: "text",
      value: currentUser.linkedinUrl || "",
      placeholder: "https://linkedin.com/in/your-name",
      validators: validLinkedinProfileUrl,
    },
    {
      name: "location",
      title: "Location",
      type: "text",
      value: "",
      validators: composeValidators(required, minLength(2)),
    },
  ]);

  const onSubmit = (values: {
    email: string;
    firstName: string;
    lastName: string;
    linkedinUrl: string;
  }) => {
    updateMutation({
      variables: {
        input: values.linkedinUrl ? values : { ...values, linkedinUrl: null },
      },
    }).then(() => {
      setEditingField(null);
    });
  };

  useEffect(() => {
    setFields(fields =>
      fields.map(field => {
        if (field.name === "name") {
          return {
            ...field,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
          };
        } else {
          return {
            ...field,
            value:
              field.name === "email"
                ? currentUser.email
                : currentUser.linkedinUrl || "",
          };
        }
      })
    );
  }, [currentUser]);

  return (
    <Card style={{ padding: pxToRem(16) }}>
      <Spacing>
        <Text bold size={1} align="left">
          Personal details
        </Text>
        <FinalForm
          initialValues={{
            email: currentUser.email,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            linkedinUrl: currentUser.linkedinUrl,
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <Spacing size={-1}>
                {fields.map(field => (
                  <SettingContainer
                    key={field.name}
                    details={field}
                    editingField={editingField}
                    currentUser={currentUser}
                    onEdit={name => {
                      setEditingField(name);
                    }}
                    onCancel={() => {
                      form.reset();
                      setEditingField(null);
                    }}
                  />
                ))}
              </Spacing>
            </form>
          )}
        />
      </Spacing>
    </Card>
  );
};

export function Settings(): React.ReactElement | null {
  const { data } = useQuery(AccountSettingsDocument);

  const [editingField, setEditingField] = useState<string | null>(null);

  const candidateData =
    data?.me?.__typename == "CurrentAdmin" ||
    data?.me?.__typename == "CurrentCandidate"
      ? data.me
      : null;

  if (!data || !candidateData || data.currentUser === null) {
    return null;
  }

  const optedIn = candidateData.optedIn ?? false;

  return (
    <PageWrapper style={{ marginTop: pxToRem(40) }}>
      <Middle maxWidth={830}>
        <Spacing size={5}>
          <Heading bold size={2} align="center">
            Account settings
          </Heading>
          <SettingsContainer>
            <Spacing>
              <CardsContainer>
                <CardColumn>
                  <SettingsForm
                    currentUser={data.currentUser}
                    setEditingField={field => {
                      setEditingField(field);
                    }}
                    editingField={editingField}
                  />
                  <DeleteAccount disabled={editingField !== null} />
                </CardColumn>
                <CardColumn>
                  <NewNotificationSettings
                    jobEmailNotificationsFrequency={
                      data.currentUser?.jobEmailNotificationsFrequency ??
                      NotificationFrequency.Never
                    }
                    setEditingField={field => {
                      setEditingField(field);
                    }}
                    editingField={editingField}
                    isOptedIn={optedIn}
                  />
                </CardColumn>
              </CardsContainer>
              <CookiePolicy />
            </Spacing>
          </SettingsContainer>
        </Spacing>
      </Middle>
    </PageWrapper>
  );
}

const DeleteAccount = ({
  disabled,
}: {
  disabled: boolean;
}): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate("/delete-my-account")}
      level="destructive"
      data-analytics-id="delete-my-account"
      disabled={disabled}
    >
      Delete account
    </Button>
  );
};
