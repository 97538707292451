import styled from "@xstyled/styled-components";

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  overflow-y: auto;
  padding: xl 40;
`;
