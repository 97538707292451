import { useSearchParams } from "react-router-dom";

import { OAuthError } from "./Error";
import { Authorize } from "./Authorize";
import { MobileAppSignup } from "./MobileAppSignup";

export function OAuthAuthorize(): React.ReactElement {
  const [searchParams] = useSearchParams();

  const error = searchParams.get("error");
  const method = searchParams.get("method");

  if (error) {
    return <OAuthError error={error} />;
  }

  if (method === "signup") {
    return <MobileAppSignup />;
  }

  return <Authorize />;
}
