import * as React from "react";
import type { SVGProps } from "react";
const SvgMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="#000"
      d="M7.63 3.875a.627.627 0 0 0-.625.625v15c0 .344.281.625.625.625h8.75a.627.627 0 0 0 .625-.625v-15a.627.627 0 0 0-.625-.625H7.63Zm-2.5.625c0-1.379 1.121-2.5 2.5-2.5h8.75c1.379 0 2.5 1.121 2.5 2.5v15c0 1.379-1.121 2.5-2.5 2.5H7.63a2.502 2.502 0 0 1-2.5-2.5v-15Zm5.625 13.125h2.5c.344 0 .625.281.625.625a.627.627 0 0 1-.625.625h-2.5a.627.627 0 0 1-.625-.625c0-.344.281-.625.625-.625Z"
    />
  </svg>
);
export default SvgMobile;
