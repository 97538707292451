import { useEffect } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { SelectField, Spacing, Text } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import {
  TechnologiesUsedDocument,
  TechnologyPreference,
} from "@otta/search/schema";
import {
  IOption,
  mapRowToOptions,
  sortOptionsAlphabetically,
} from "@otta/search/utils/select";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

const LIMIT = 30;
const ADVISED_LIMIT = 10;

export function NegativeTechnologies({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const isPresent = useIsPresent();

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(true);
    }
  }, [setNextEnabled, isPresent]);

  const { preferencesData, updatePreferences } = useUserPreferences();

  const { data: technologiesData } = useQuery(TechnologiesUsedDocument);

  if (!technologiesData || !preferencesData) {
    return <Loading />;
  }

  const preferences = preferencesData.technologiesUsedPreferences ?? [];

  const negativePreferencesSet = preferences.reduce<Set<string>>(
    (acc, pref) =>
      pref.technologyPreference === TechnologyPreference.Negative
        ? acc.add(pref.technologyUsedId)
        : acc,
    new Set()
  );

  const selectedOptions = technologiesData.technologiesUsed.filter(tag =>
    negativePreferencesSet.has(tag.id)
  );

  const handleChange = (options: readonly IOption[]) => {
    if (options.length > LIMIT) {
      return;
    }

    const negativeInputs = options.map(({ value }) => ({
      technologyUsedId: value,
      technologyPreference: TechnologyPreference.Negative,
    }));

    const restInputs = preferences
      .filter(
        pref =>
          pref.technologyPreference !== TechnologyPreference.Negative &&
          !negativeInputs.some(
            p => p.technologyUsedId === pref.technologyUsedId
          )
      )
      .map(({ technologyUsedId, technologyPreference }) => ({
        technologyUsedId,
        technologyPreference:
          technologyPreference as TechnologyPreference.Negative,
      }));

    updatePreferences({
      technologiesUsedPreferences: [...negativeInputs, ...restInputs],
    });
  };

  return (
    <Spacing size={-1}>
      <SelectField<string, true>
        options={sortOptionsAlphabetically(
          mapRowToOptions(technologiesData.technologiesUsed)
        )}
        placeholder="Type technology here..."
        value={sortOptionsAlphabetically(mapRowToOptions(selectedOptions))}
        onChange={handleChange}
        isMulti
      />
      {selectedOptions.length >= ADVISED_LIMIT && (
        <Text align="left" size={-1}>
          Disliking too many technologies could limit the amount of results you
          see
        </Text>
      )}
    </Spacing>
  );
}
