import styled, { css, up } from "@xstyled/styled-components";

import { QuizLayoutProps } from ".";

import { Spacing, Middle, Overline, Card, Text } from "@otta/design";
import { ProgressBar } from "@otta/search/components/ProgressBar";

const Gutter = styled.div`
  padding: 0 md 4xl md;
`;

const QuestionWrapper = styled(Card)`
  padding: xxl lg;
  margin: 0 auto lg;
  background: transparent;

  ${up(
    "tablet",
    css`
      margin: 28 auto lg;
      background: rgba(255, 255, 255, 0.3);
    `
  )}
`;

export function QuizLayout({
  progress,
  customHeaderText,
  buttons,
  question,
  nextEnabled,
  handleNext,
  handleSkip,
  setNextEnabled,
}: QuizLayoutProps): React.ReactElement {
  const QuestionComponent = question.component;

  return (
    <Spacing>
      <ProgressBar data-testid="progress-bar" progress={progress} />
      <Middle maxWidth={490}>
        <Gutter>
          <QuestionWrapper>
            <Spacing size={2}>
              {customHeaderText && (
                <Overline as="h6" size={-1}>
                  {customHeaderText}
                </Overline>
              )}

              <Spacing>
                <Text as="h3" size={1} bold>
                  {question.title}
                </Text>
                {question.subtitle && <Text as="h4">{question.subtitle}</Text>}
              </Spacing>
              <QuestionComponent
                handleNext={handleNext}
                nextEnabled={nextEnabled}
                handleSkip={handleSkip}
                setNextEnabled={setNextEnabled}
              />
            </Spacing>
          </QuestionWrapper>
        </Gutter>
      </Middle>
      {buttons}
    </Spacing>
  );
}
