import { useState } from "react";
import styled from "@xstyled/styled-components";

import { ExpansionArrow } from "../../components/Section";

import { ApplicationQuestionResponse } from "./ApplicationQuestionResponse";

import { Card, Spacing, Text } from "@otta/design";
import {
  WorkableAnswersFragment,
  GreenhouseAnswersFragment,
  LeverAnswersFragment,
  OttaAnswersFragment,
} from "@otta/search/schema";
import {
  ATSQuestionsV1,
  Section,
} from "@otta/search/pages/Profile/sections/ApplicationQuestionsV3/types";
import { ViewQuestion } from "@otta/search/pages/Profile/sections/ApplicationQuestionsV3/ViewQuestion";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ClickableText = styled(Text)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface ApplicationProps {
  answerData:
    | WorkableAnswersFragment
    | GreenhouseAnswersFragment
    | LeverAnswersFragment
    | OttaAnswersFragment;
}

export function Application({
  answerData,
}: ApplicationProps): React.ReactElement | null {
  const [expand, setExpand] = useState(false);

  const { answers } = answerData;

  if (!answers || answers.length <= 0) {
    return null;
  }

  const collapsedAnswers = answers.length > 3 ? answers.slice(0, 3) : answers;

  return (
    <Card data-testid="application-card">
      <Spacing>
        <HeaderWrapper>
          <Text size={1} align="left" bold>
            Questions
          </Text>
          <ExpansionArrow expand={expand} setExpand={setExpand} />
        </HeaderWrapper>
        {expand
          ? answers.map(response => {
              return (
                <ApplicationQuestionResponse
                  key={response.questionData.question}
                  response={response}
                  expand={expand}
                />
              );
            })
          : collapsedAnswers.map(response => {
              return (
                <ApplicationQuestionResponse
                  key={response.questionData.question}
                  response={response}
                  expand={expand}
                />
              );
            })}
        {!expand && answers.length > 3 && (
          <ClickableText size={-1} onClick={() => setExpand(true)}>
            + {answers.length - 3} more responses
          </ClickableText>
        )}
      </Spacing>
    </Card>
  );
}

function ApplicationSection({
  section,
}: {
  section: Section;
}): React.ReactElement {
  const [expand, setExpand] = useState(false);
  const { id, label, questions } = section;
  const collapsedQuestions =
    questions.length > 3 ? questions.slice(0, 3) : questions;
  const displayedQuestions = expand ? questions : collapsedQuestions;

  return (
    <Card data-testid="application-card" key={id}>
      <Spacing>
        {label && (
          <HeaderWrapper>
            <Text size={1} align="left" bold>
              {label}
            </Text>
            <ExpansionArrow expand={expand} setExpand={setExpand} />
          </HeaderWrapper>
        )}
        {displayedQuestions.map(
          (question, i) =>
            question.__typename !== "AtsInformation" && (
              <ViewQuestion question={question} section={id} key={i} />
            )
        )}
        {!expand && questions.length > 3 && (
          <ClickableText size={-1} onClick={() => setExpand(true)}>
            + {questions.length - 3} more responses
          </ClickableText>
        )}
      </Spacing>
    </Card>
  );
}

export function NewATSApplication({
  answerData,
}: {
  answerData: ATSQuestionsV1;
}): React.ReactElement | null {
  const { sections } = answerData;

  if (!sections || sections.length <= 0) {
    return null;
  }

  return (
    <Spacing>
      {sections.map(section => (
        <ApplicationSection key={section.id} section={section} />
      ))}
    </Spacing>
  );
}
