import { FetchPolicy } from "@apollo/client";

import { BatchOptions } from "../../BatchOptions";

import { useDetermineNextStep } from "./DetermineNextStep";
import { useApplyNextStep } from "./ApplyNextStep";

import { Sector } from "@otta/search/components/BatchCard/theme";
import * as Schema from "@otta/search/schema";

/**
 * This file aims to capture the possible actions to take
 * once you've hit the batch end card into a union type:
 */
export enum StepType {
  RefreshRecommendations = "RefreshRecommendations",
  ReplaceThemedBatchCache = "ReplaceThemedBatchCache",
  GoBackToDashboard = "GoBackToDashboard",
}

// like Required<A> but for nulls
type NotNull<A> = Exclude<A, null>;

// dig out the type of job preference suggestions from the schema
type BatchEndUser = NotNull<Schema.BatchEndWithSuggestionsQuery["currentUser"]>;

// only shown for all matches
export type Suggestions = {
  suggestions: NotNull<BatchEndUser["jobPreferenceSuggestions"]>;
  firstName?: string;
};

/**
 * We've got to the end of a regular, non-themed batch.
 * To proceed we need to refresh the backend cache of recommendations
 * and then replace the Apollo cache with the resulting new jobs
 */
type RefreshRecommendations = {
  type: StepType.RefreshRecommendations;
  ignoreSubfunctions: boolean;
  suggestions?: Suggestions;
  themedBatches: Schema.ThemedBatchesQuery["currentUser"];
  topSector?: Sector;
};

/**
 * We've got to the end of a themed batch. We already have
 * the next batch because we fetched it to find out if there are more jobs
 * as such all we need to do is put them into the Apollo cache
 */
export type ReplaceThemedBatchCache = {
  type: StepType.ReplaceThemedBatchCache;
  themedBatches: NonNullable<Schema.ThemedBatchesQuery["currentUser"]>;
  topSector: Sector;
  themeId: Schema.ThemeId;
};

/**
 * We've run out of jobs in either a themed or regular batch
 * We don't yet support going from themed -> regular so
 * just take you back to the dashboard in either casee
 */
type GoBackToDashboard = {
  type: StepType.GoBackToDashboard;
  suggestions?: Suggestions;
  hasSavedJobs?: boolean;
  themedBatches?: Schema.ThemedBatchesQuery["currentUser"];
  topSector?: Sector;
};

export const backToDashboard: GoBackToDashboard = {
  type: StepType.GoBackToDashboard,
};

export type NextStep =
  | GoBackToDashboard
  | RefreshRecommendations
  | ReplaceThemedBatchCache;

/**
 * Given a batch end action (or an undefined one we've not loaded yet)
 * dig out the value we need to provide the refresh mutation for ignoreSubfunctions
 */
export function ignoreSubfunctions(bea?: NextStep): boolean {
  return bea?.type == StepType.RefreshRecommendations
    ? bea.ignoreSubfunctions
    : false;
}

/**
 * Return suggestions - they only exist for NextSteps that come from
 * a non themed batch where the BatchEndWithSuggestions query was called
 */
export function suggestions(ns?: NextStep): Suggestions | undefined {
  return ns?.type != StepType.ReplaceThemedBatchCache
    ? ns?.suggestions
    : undefined;
}

/**
 * This firstName is also only available alongside suggestions
 */
export function firstName(ns?: NextStep): string | undefined {
  return ns?.type != StepType.ReplaceThemedBatchCache
    ? ns?.suggestions?.firstName
    : undefined;
}

export function useNextStep(
  params: BatchOptions,
  token?: string,
  fetchPolicy: FetchPolicy = "network-only"
): [NextStep | undefined, () => Promise<void>] {
  const nextStep = useDetermineNextStep(params, token, fetchPolicy);

  const apply = useApplyNextStep(nextStep, params, token);

  return [nextStep, apply];
}
