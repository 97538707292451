import styled, { up, css } from "@xstyled/styled-components";

import { Card } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";

export const CarouselCard = styled(Card)<{
  fullWidth?: boolean;
  transparent?: boolean;
}>`
  padding: lg;
  border: ${({ transparent = false }) =>
    transparent ? "none" : `1px solid ${palette.beige.shade200}`};
  margin: auto;
  height: 100%;

  ${up(
    "desktop",
    css`
      max-width: ${pxToRem(512)};
    `
  )}

  ${({ fullWidth = false }) =>
    fullWidth &&
    css`
      border: none;
      border-radius: 0;
      padding: 0;
    `};

  ${({ transparent = false }) =>
    transparent &&
    css`
      background: none;
    `};
`;
