import { QuestionComponentProps } from "..";

import { PositiveSectorTags } from "./PositiveSectorTags";

import { Spacing, Text } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { PreferenceQuestionWrapper } from "@otta/search/containers/Quiz/components/PreferenceQuestionWrapper";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

const PositiveSectorTagsPreferenceQuestion = (
  props: QuestionComponentProps
): React.ReactElement => (
  <Spacing>
    <Spacing size={-4}>
      <Text align="center">Which industries are you most interested in?</Text>
      <Text align="center" size={-1}>
        (Select as many as you like)
      </Text>
    </Spacing>
    <PositiveSectorTags {...props} />
  </Spacing>
);

export function PositiveSectorTagsPreference(
  props: QuestionComponentProps
): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();

  if (!preferencesData) {
    return <Loading />;
  }

  const preferences = preferencesData.sectorTagPreferences ?? [];

  const hasPreference =
    preferences.filter(({ preference }) => preference).length > 0;

  const handleNoClick = () => {
    const negativePreferences = preferences.filter(
      ({ preference: pref }) => !pref
    );

    updatePreferences({
      sectorTagPreferences: negativePreferences.map(
        ({ sectorTagId, preference }) => ({
          sectorTagId,
          preference: preference as boolean,
        })
      ),
    });
  };

  return (
    <PreferenceQuestionWrapper
      {...props}
      hasPreference={hasPreference}
      analyticsNoOptionName="no-positive-industry-preference"
      analyticsYesOptionName="has-positive-industry-preference"
      Component={PositiveSectorTagsPreferenceQuestion}
      onNoClick={handleNoClick}
      optionalQuestion
    />
  );
}
