import styled, { css, down, up } from "@xstyled/styled-components";

import { CompanyShortDescription } from "../components/CompanyShortDescription";
import { StatusTags } from "../components/StatusTags";
import { CompanyLogo } from "../components/CompanyLogo";
import { JobCardContext } from "../JobHeader";
import { MeetTheTeamTag } from "../components/MeetTheTeamTag";

import { JobTitleSection } from "./JobTitleSection";
import { SalaryTechExperienceLocation } from "./SalaryTechExperienceLocation";

const TopCardSection = styled.div`
  ${down(
    "tablet",
    css`
      display: flex;
      flex-direction: column;
      gap: md;
    `
  )}

  ${up(
    "tablet",
    css`
      flex-basis: 0;
      flex-grow: 1;
    `
  )}
`;

export const MobileOnly = styled.divBox`
  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

const DesktopOnly = styled.div`
  ${down(
    "tablet",
    css`
      display: none;
    `
  )}
`;

const StyledDesktopOnly = styled(DesktopOnly)`
  margin-top: lg;
`;

export const JobSection = ({
  job,
  user,
}: Pick<JobCardContext, "job" | "user">): React.ReactElement => {
  return (
    <TopCardSection data-testid="job-section">
      <JobTitleSection job={job} />

      <StyledDesktopOnly>
        <SalaryTechExperienceLocation job={job} user={user} />
      </StyledDesktopOnly>

      <MobileOnly>
        <CompanyLogo company={job.company} />
      </MobileOnly>

      <MobileOnly>
        <CompanyShortDescription company={job.company} />
      </MobileOnly>

      <MobileOnly display="flex" gap="sm" flexWrap="wrap">
        <StatusTags company={job.company} job={job} />
        <MeetTheTeamTag job={job} company={job.company} />
      </MobileOnly>
    </TopCardSection>
  );
};
