import { useEffect, useMemo } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";
import { Heart } from "../icons/Heart";

import { IconWrapper, IconsWrapper, StyledText } from "./PositiveTechnologies";

import { palette } from "@otta/design-tokens";
import { Spacing, SelectField, Text } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import {
  mapRowToOptions,
  IOption,
  sortOptionsAlphabetically,
} from "@otta/search/utils/select";
import {
  SectorTagPreferenceInput,
  SectorTagsDocument,
} from "@otta/search/schema";
import { TagsContainer, Tag } from "@otta/search/components/Tag";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

// TODO might need to bring these from a specific sector categories query
const superSectors = [
  "Finance",
  "Security",
  "Education",
  "Health and Beauty",
  "Legal/HR",
  "Real Estate",
  "Travel and Transportation",
  "Consumer",
  "Media",
  "Software",
  "Productivity",
  "Data",
  "Science",
  "Artificial Intelligence",
  "Tech for good",
  "Hardware",
];

const modifiers = [
  "B2B",
  "B2C",
  "Retail",
  "Enterprise",
  "Marketplace",
  "SaaS",
  "eCommerce",
  "B Corporation",
];

export function PositiveSectorTags({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const isPresent = useIsPresent();

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(true);
    }
  }, [setNextEnabled, isPresent]);

  const { preferencesData, updatePreferences } = useUserPreferences();

  const { data: sectorTagsData } = useQuery(SectorTagsDocument);

  const allSectorTags = useMemo(
    () => sectorTagsData?.sectorTags ?? [],
    [sectorTagsData?.sectorTags]
  );

  const varietySectorTags = useMemo(
    () =>
      allSectorTags.filter(
        ({ value }) => superSectors.includes(value) || modifiers.includes(value)
      ),
    [allSectorTags]
  );

  if (!sectorTagsData || !preferencesData) {
    return <Loading />;
  }

  const preferences = preferencesData.sectorTagPreferences ?? [];

  const selectedSectorTags = preferences.flatMap(
    ({ sectorTagId, preference }) => {
      if (preference) {
        return allSectorTags.find(sector => sector.id === sectorTagId) ?? [];
      }
      return [];
    }
  );

  const preferencesMap = preferences.reduce<Map<string, boolean>>(
    (acc, pref) =>
      acc.set(pref.sectorTagId as string, pref.preference as boolean),
    new Map()
  );

  const handleClick = (sectorTagId: string) => {
    const input: SectorTagPreferenceInput[] = [];

    if (preferencesMap.get(sectorTagId)) {
      preferencesMap.delete(sectorTagId);
    } else {
      preferencesMap.set(sectorTagId, true);
    }

    for (const [sectorTagId, preference] of preferencesMap.entries()) {
      input.push({
        sectorTagId,
        preference,
      });
    }

    updatePreferences({
      sectorTagPreferences: input,
    });
  };

  const handleSelectChange = (option: IOption | null) =>
    option
      ? handleClick(option.value)
      : updatePreferences({ sectorTagPreferences: [] });

  const tagOptions = [
    ...new Set([...varietySectorTags, ...selectedSectorTags]),
  ];
  const selectOptions = allSectorTags.filter(
    sectorTag => tagOptions.indexOf(sectorTag) < 0
  );

  return (
    <Spacing>
      <TagsContainer>
        {tagOptions.map(({ id, value }) => {
          return (
            <Tag
              key={id}
              backgroundColor={
                preferencesMap.get(id)
                  ? palette.brand.yellow
                  : palette.brand.white
              }
              data-testid="positive-sector-tag"
              onClick={() => handleClick(id)}
            >
              <IconsWrapper>
                <IconWrapper
                  enter={preferencesMap.get(id) as boolean}
                  exit={!(preferencesMap.get(id) as boolean)}
                  isHeart
                >
                  <Heart />
                </IconWrapper>
              </IconsWrapper>
              <StyledText selected={preferencesMap.get(id) as boolean}>
                {value}
              </StyledText>
            </Tag>
          );
        })}
      </TagsContainer>
      <Text>Can&apos;t find your favourite industry above?</Text>
      <SelectField<string>
        options={sortOptionsAlphabetically(mapRowToOptions(selectOptions))}
        onChange={handleSelectChange}
        placeholder="Type industry here..."
      />
    </Spacing>
  );
}
