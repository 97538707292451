import { useEffect, useRef, useState } from "react";
import { InView } from "react-intersection-observer";
import styled, { css, up } from "@xstyled/styled-components";
import { motion } from "framer-motion";

import { NavArrow } from "./NavArrow";

import { PageProgressIndicator } from "@otta/design";

const CarouselContainer = styled.div`
  width: 100%;

  /* We have a weird issue where the classNames of the styled-components are not in sync with SSR
  more info: https://linear.app/wttj/issue/OTTA-1047/endorsements-on-company-pages-have-inconsistent-loading-and-display#comment-ee4153bf */
  .card-wrapper {
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
`;

const CardsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  gap: xs;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const PageProgressContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  z-index: 0;

  ${up(
    "desktop",
    css`
      display: none;
    `
  )}
`;

export function Carousel({
  cards,
  className,
  onChange,
}: {
  cards: JSX.Element[];
  className?: string;
  onChange?: (currentCard: number) => void;
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [atCarouselStart, setAtCarouselStart] = useState(true);
  const [atCarouselEnd, setAtCarouselEnd] = useState(cards.length === 1);
  const [currentCard, setCurrentCard] = useState(0);

  const handleChangeInView = (inView: boolean, index: number) => {
    if (!inView || currentCard === index) return;

    setAtCarouselStart(index === 0);
    setAtCarouselEnd(index === cards.length - 1);
    setCurrentCard(index);
    if (onChange) {
      onChange(index);
    }
  };

  useEffect(() => {
    wrapperRef.current?.scrollTo?.(0, 0);
  }, [cards]);

  const handleNext = () => {
    wrapperRef.current?.scrollTo({
      left: wrapperRef.current.scrollLeft + wrapperRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  const handlePrevious = () => {
    wrapperRef.current?.scrollTo({
      left: wrapperRef.current.scrollLeft - wrapperRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  return (
    <CarouselContainer className={className}>
      <Wrapper data-testid="carousel">
        <NavArrow
          onClick={handlePrevious}
          direction="left"
          displayArrow={!atCarouselStart && cards.length > 1}
          testId="previous-button"
        />
        <CardsContainer tabIndex={0} ref={wrapperRef}>
          {cards.map((card, index) => (
            <InView
              key={`carousel-card-${index}`}
              root={wrapperRef.current}
              // Not 1 because the card might just a tiny bit not in view
              threshold={0.9}
              onChange={inView => handleChangeInView(inView, index)}
            >
              {({ inView, ref }) => (
                <div
                  className={`card-wrapper carousel-card-${
                    inView ? "visible" : "hidden"
                  }`}
                  ref={ref}
                >
                  {card}
                </div>
              )}
            </InView>
          ))}
        </CardsContainer>
        <NavArrow
          onClick={handleNext}
          direction="right"
          displayArrow={!atCarouselEnd && cards.length > 1}
          testId="next-button"
        />
      </Wrapper>
      <PageProgressContainer>
        <PageProgressIndicator
          total={cards.length}
          current={currentCard + 1}
          progress={currentCard + 1}
        />
      </PageProgressContainer>
    </CarouselContainer>
  );
}
