export function Money(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8747 8.0625H16.1247L12.1463 2.08953C12.0113 1.88717 11.9338 1.65196 11.922 1.40899C11.9102 1.16601 11.9645 0.924387 12.0793 0.70989C12.194 0.495393 12.3649 0.316068 12.5735 0.191046C12.7822 0.0660239 13.0209 -5.99634e-06 13.2642 0H29.7377C29.981 -5.99634e-06 30.2197 0.0660239 30.4284 0.191046C30.637 0.316068 30.8078 0.495393 30.9226 0.70989C31.0373 0.924387 31.0917 1.16601 31.0799 1.40899C31.0681 1.65196 30.9906 1.88717 30.8555 2.08953L26.8747 8.0625ZM16.1247 10.75H26.8747C43.1959 20.0556 43.0269 32.452 42.9958 34.7324V34.7326V34.7327C42.9947 34.8141 42.9938 34.8825 42.9938 34.9375C42.9938 39.3887 38.8702 43 33.7824 43H9.21865C4.13172 43 0.0072428 39.3887 0.0072428 34.9375C0.0072428 34.8763 0.00599546 34.7991 0.00450281 34.7067C-0.0336354 32.3466 -0.231913 20.0768 16.1247 10.75ZM25.3439 31.0625H27.05C27.2985 31.0625 27.5 31.2619 27.5 31.5078V35.3672C27.5 35.6131 27.2985 35.8125 27.05 35.8125H15.95C15.7015 35.8125 15.5 35.6131 15.5 35.3672V33.8828C15.5 33.6369 15.7015 33.4375 15.95 33.4375H17.3V28.6875H16.25C16.0015 28.6875 15.8 28.4881 15.8 28.2422V26.7578C15.8 26.5119 16.0015 26.3125 16.25 26.3125H17.3V23.8618C17.3 21.1447 19.476 19.1875 22.5474 19.1875C24.3716 19.1875 25.7422 20.0247 26.3565 20.4837C26.5589 20.6349 26.5947 20.9217 26.4359 21.1175L25.3675 22.4354C25.225 22.6111 24.9723 22.6543 24.7792 22.535C24.3376 22.2625 23.5044 21.8356 22.6172 21.8356C21.2211 21.8356 20.3 22.7566 20.3 23.954V26.3125H23.45C23.6985 26.3125 23.9 26.5119 23.9 26.7578V28.2422C23.9 28.4881 23.6985 28.6875 23.45 28.6875H20.3V33.3947H24.8939V31.5078C24.8939 31.2619 25.0954 31.0625 25.3439 31.0625Z"
      />
    </svg>
  );
}
