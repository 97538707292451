import styled from "@xstyled/styled-components";

import { Loading } from "@otta/search/components/Loading";

const LoadingWrapper = styled.div`
  min-height: 160px;
`;

export const LoadingCard: React.FC = () => {
  return (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};
