import { useMutation } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";

import { SalaryPreferenceComponent } from "../SalaryPreference";

import { Spacing, List } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { useQuery } from "@otta/search/apollo";
import {
  EU_LOCATIONS,
  UK_LOCATIONS,
  CANADA_LOCATIONS,
} from "@otta/search/constants/locations";
import { QuestionComponentProps } from "@otta/search/containers/Quiz";
import {
  SalaryPreferenceDocument,
  UpdateSalaryPreferenceDocument,
  Currency,
  Location,
} from "@otta/search/schema";

interface Money {
  amount: string;
  currency: Currency;
}

function determineInitialCurrency(location: Location) {
  switch (true) {
    case EU_LOCATIONS.has(location):
      return Currency.Eur;
    case UK_LOCATIONS.has(location):
      return Currency.Gbp;
    case CANADA_LOCATIONS.has(location):
      return Currency.Cad;
    default:
      return Currency.Usd;
  }
}

export function SalaryInput({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const { data } = useQuery(SalaryPreferenceDocument);
  const [mutate] = useMutation(UpdateSalaryPreferenceDocument, {
    refetchQueries: [{ query: SalaryPreferenceDocument }],
  });

  useEffect(() => {
    setNextEnabled(!!data?.salaryPreference?.currentJobSalary);
  }, [setNextEnabled, data]);

  const currency = useMemo(() => {
    const location = data?.salaryPreference?.location ?? Location.London;
    return (
      data?.salaryPreference?.currentJobSalary?.currency ??
      determineInitialCurrency(location)
    );
  }, [
    data?.salaryPreference?.currentJobSalary?.currency,
    data?.salaryPreference?.location,
  ]);

  const handleChange = useCallback(
    (input: Money) => {
      mutate({
        variables: {
          input: {
            currentJobSalary: input,
          },
        },
      });
    },
    [mutate]
  );

  if (!data) {
    return <Loading />;
  }
  const amount = data.salaryPreference?.currentJobSalary?.amount ?? "25000";

  return (
    <Spacing>
      <SalaryPreferenceComponent
        salaryRequirement={{ amount, currency }}
        onChange={handleChange}
      />
      <List type="positive">
        <li>Access salary trajectory data for all roles</li>
        <li>
          Your data is 100% anonymous. We&apos;ll only ever show aggregate data,
          not your company or title
        </li>
        <li>
          Help improve salary transparency. We want everyone to have the
          information they need to be paid fairly
        </li>
      </List>
    </Spacing>
  );
}
