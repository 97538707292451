import { Query } from "@apollo/client/react/components";
import { Component } from "react";
import { ActionMeta } from "react-select";
import { TypedDocumentNode } from "@apollo/client";

import { MultiSelectField } from "./MultiSelectField";

import { mapQueryResultToSelectStructure } from "@otta/search/components/Input/Select";

interface IPassiveMultiSelectFieldProps<F extends string> {
  id?: string;
  optionsQuery: TypedDocumentNode<
    {
      [key in F]: { id: string; value: string }[];
    },
    Record<string, never>
  >;
  optionsFieldName: F;
  limit: number;
  placeholder?: string;
  label?: string;
  excludeOptions?: { id: string }[];
  items: { id: string; value: string }[];
  handleReturn: (e: { id: string; value: string }[]) => void;
}

interface IPassiveMultiSelectFieldState {
  items: { id: string; value: string }[];
  invalid: boolean;
}

export class PassiveMultiSelectField<F extends string> extends Component<
  IPassiveMultiSelectFieldProps<F>,
  IPassiveMultiSelectFieldState
> {
  constructor(props: IPassiveMultiSelectFieldProps<F>) {
    super(props);
    const items = props.items ?? [];

    const formattedItems = items.map(({ id, value }) => ({
      id,
      value,
    }));

    this.state = {
      items: formattedItems,
      invalid: false,
    };
  }

  public handleAdd = async (
    itemId: string,
    itemValue: string
  ): Promise<void> => {
    const currentItemsUsed = this.state.items;
    const addItem = { id: itemId, value: itemValue };
    const updatedItemsUsed = [...currentItemsUsed, addItem];
    await this.setState({ items: updatedItemsUsed });
  };

  public handleRemove = async (
    itemId: string,
    itemValue: string
  ): Promise<void> => {
    const currentItemsUsed = this.state.items;
    const removeItem = {
      id: itemId,
      value: itemValue,
    };
    const updatedItemsUsed = currentItemsUsed.filter(
      ({ id }) => id !== removeItem?.id
    );
    await this.setState({ items: updatedItemsUsed });
  };

  public handleRemoveAll = async (): Promise<void> => {
    await this.setState({ items: [] });
  };

  public handleChange =
    (
      refetchAll: () => Promise<unknown>,
      limit: number,
      handleReturn: (items: { id: string; value: string }[]) => void,
      selectedOptions: { label: string; value: string }[]
    ) =>
    async (
      _newValue: unknown,
      actionMeta: ActionMeta<{ value: string; label: string }>
    ): Promise<void> => {
      // select an existing option
      if (
        actionMeta.action === "select-option" &&
        selectedOptions.length < limit
      ) {
        const newItem = actionMeta.option;
        if (newItem) {
          await this.handleAdd(newItem.value, newItem.label);
        }
      }
      // remove an option by clicking on it
      else if (
        actionMeta.action === "remove-value" &&
        actionMeta.removedValue !== undefined
      ) {
        const removedItem = actionMeta.removedValue;
        await this.handleRemove(removedItem.value, removedItem.label);
      }
      // remove the last item in the list by using the backspace key
      else if (
        actionMeta.action === "pop-value" &&
        actionMeta.removedValue !== undefined
      ) {
        const removedItem = actionMeta.removedValue;
        await this.handleRemove(removedItem.value, removedItem.label);
      }
      // remove all items
      else if (actionMeta.action === "clear") {
        await this.handleRemoveAll();
      }
      if (
        actionMeta.action === "select-option" &&
        selectedOptions.length >= limit
      ) {
        await this.setState({ invalid: true });
      }
      if (
        actionMeta.action !== "select-option" &&
        selectedOptions.length <= limit
      ) {
        await this.setState({ invalid: false });
      }

      // refetch the full list and the parent-specific items
      handleReturn(this.state.items);
      await refetchAll();
    };

  public render(): React.ReactElement {
    const {
      id,
      label,
      optionsQuery,
      optionsFieldName,
      limit,
      handleReturn,
      placeholder,
    } = this.props;

    return (
      <Query<{ [key in F]: { id: string; value: string }[] }>
        query={optionsQuery}
        fetchPolicy="network-only"
      >
        {({ data: allOptions, loading: allLoading, refetch: refetchAll }) => {
          const options = allOptions
            ? allOptions[optionsFieldName].map(mapQueryResultToSelectStructure)
            : [];
          const selectedOptions = this.state.items.map(
            mapQueryResultToSelectStructure
          );
          const handleChange = this.handleChange(
            refetchAll,
            limit,
            handleReturn,
            selectedOptions
          );
          return (
            <MultiSelectField
              id={id}
              label={label}
              allLoading={allLoading}
              currentLoading={false}
              options={options}
              selectedOptions={selectedOptions}
              onChange={handleChange}
              placeholder={placeholder}
              invalid={this.state.invalid}
            />
          );
        }}
      </Query>
    );
  }
}
