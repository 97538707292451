import { ArrowDirection, JobDeckNavProps } from "../../types";

import { NavArrow } from "./NavArrow";

export const JobDeckArrows = ({
  showPrevArrow,
  onPrevious: handlePrevious,
  onNext: handleNext,
}: JobDeckNavProps): React.ReactElement => {
  return (
    <>
      {showPrevArrow && (
        <NavArrow
          onClick={handlePrevious}
          direction={ArrowDirection.Left}
          testId="left-nav-button"
        />
      )}
      <NavArrow
        onClick={handleNext}
        direction={ArrowDirection.Right}
        testId="next-button"
      />
    </>
  );
};
