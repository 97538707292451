import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { NavBar } from "./NavBar";

// This component fills the remaining space after the nav bar so we can postion things at the bottom of the page
const Page = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export function PageWithNavBar(): React.ReactElement {
  return (
    <>
      <Helmet>
        <meta
          name="apple-itunes-app"
          content="app-id=1640509194, affiliate-data=pt=124097318&ct=search_app_smart_banner"
        />
      </Helmet>
      <NavBar />
      <Page>
        <Outlet />
      </Page>
    </>
  );
}
