import { createContext } from "react";

import { UserInput } from "@otta/search/schema";

export interface IUserPreferencesContext {
  loggedIn: boolean;
  loading: boolean;

  preferencesData: UserInput | undefined;
  hasSetPreferences: boolean;
  updatePreferences: (userInput: UserInput) => void;

  onlyRemoteOpportunities: boolean;
  setOnlyRemoteOpportunities: (value: boolean) => void;
}

export const UserPreferencesContext = createContext<IUserPreferencesContext>({
  loggedIn: false,
  loading: true,

  preferencesData: undefined,
  hasSetPreferences: false,
  updatePreferences: () => undefined,

  onlyRemoteOpportunities: false,
  setOnlyRemoteOpportunities: () => undefined,
});
