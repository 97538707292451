import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ErrorText } from "..";

import { CategoryTag } from "./CategoryTag";

import { useQuery } from "@otta/search/apollo";
import {
  CompanySubQualitiesDocument,
  CompanyEmployeeSurveyDocument,
} from "@otta/search/schema";
import { QuestionComponentProps } from "@otta/search/containers/Quiz";
import { TagsContainer } from "@otta/search/components/Tag";

export function CategoryTags({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement | null {
  const { surveyExternalId } = useParams();

  const { data: surveyData } = useQuery(CompanyEmployeeSurveyDocument, {
    variables: { externalId: surveyExternalId as string },
  });

  const { data: companySubQualitiesData } = useQuery(
    CompanySubQualitiesDocument
  );

  const survey = surveyData?.companyEmployeeSurvey;
  const companySubQualities = companySubQualitiesData?.companySubQualities;

  useEffect(() => {
    if (survey && survey.responses.length >= 3) {
      setNextEnabled(true);
    } else {
      setNextEnabled(false);
    }
  }, [setNextEnabled, survey]);

  if (!survey) {
    return null;
  }

  return (
    <>
      {survey.submittedAt ? (
        <ErrorText>
          This endorsement has already been submitted. Thank you
        </ErrorText>
      ) : (
        <>
          {companySubQualities && (
            <TagsContainer>
              {companySubQualities.map(companySubQuality => (
                <CategoryTag
                  key={companySubQuality.id}
                  category={companySubQuality}
                  survey={survey}
                  surveyExternalId={surveyExternalId as string}
                />
              ))}
            </TagsContainer>
          )}
        </>
      )}
    </>
  );
}
