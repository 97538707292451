import { useMutation } from "@apollo/client";
import queryString from "query-string";
import { useEffect } from "react";
import styled from "@xstyled/styled-components";
import { useLocation, Link } from "react-router-dom";

import { Button, Middle, Spacing, Text } from "@otta/design";
import { UpdateCurrentUserViaEmailDocument } from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";
import { PageWrapper } from "@otta/search/components/PageWrapper";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: xl 0;
`;

export function ProfilePreferences(): React.ReactElement {
  const location = useLocation();

  const {
    u: userExternalId,
    sig: hash,
    v: showProfile,
  } = queryString.parse(location.search);

  const [mutation, { error }] = useMutation(UpdateCurrentUserViaEmailDocument, {
    onError: handleMutationError,
  });

  const validURL =
    typeof hash === "string" &&
    typeof userExternalId === "string" &&
    (showProfile === "true" || showProfile === "false");

  useEffect(() => {
    if (validURL) {
      const showProfileToCompanies = showProfile === "true";

      mutation({
        variables: {
          hash: hash as string,
          userExternalId: userExternalId as string,
          input: { showProfileToCompanies },
        },
      });
    }
  }, [validURL, hash, userExternalId, showProfile, mutation]);

  return (
    <PageWrapper>
      <Wrapper>
        <Middle maxWidth={590}>
          {validURL && !error ? (
            <Spacing size={2}>
              <Text align="center" as="h2" bold size={2}>
                Your preferences have been updated
              </Text>

              <Button
                as={Link}
                to="/"
                type="submit"
                level="primary"
                style={{ margin: "0 auto" }}
              >
                Back to home
              </Button>
            </Spacing>
          ) : (
            <Text align="center" as="h2" bold size={2}>
              Oops, something went wrong!
            </Text>
          )}
        </Middle>
      </Wrapper>
    </PageWrapper>
  );
}
