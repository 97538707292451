import { ApolloQueryResult } from "@apollo/client";
import { captureException } from "@sentry/browser";
import { useCallback, useState } from "react";
import { Field, Form } from "react-final-form";
import { useSearchParams } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useCookie } from "react-use";

import {
  Spacing,
  InputField,
  Button,
  Heading,
  Middle,
  ErrorText,
} from "@otta/design";
import { OAuthAuthorizeUserQuery } from "@otta/search/schema";

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  > div:first-child {
    width: 100%;
  }
`;

const ResetPasswordLink = styled.a`
  color: black;
  cursor: pointer;
  margin-bottom: sm;
  margin-top: sm;
  text-decoration: underline;
`;

const FormHeader = styled.div`
  justify-content: center;
  display: flex;
  position: relative;
`;

interface OAuthLoginProps {
  onLogin(): Promise<ApolloQueryResult<OAuthAuthorizeUserQuery>>;
  onForgotPassword: () => void;
}

export function OAuthLogin({
  onLogin,
  onForgotPassword,
}: OAuthLoginProps): React.ReactElement {
  const [searchParams] = useSearchParams();

  const [error, setError] = useState<string | null>(null);
  const [csrf] = useCookie(import.meta.env.VITE_CSRF_COOKIE);

  const handleLogin = useCallback(
    async (values: { email: string; password: string }) => {
      setError(null);

      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_HOST}/auth/login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...(csrf ? { "X-CSRF-Token": csrf } : {}),
            },
            body: JSON.stringify({
              email: values.email,
              password: values.password,
            }),
            credentials: "include",
          }
        );

        if (response.status === 403) {
          setError("Incorrect username or password.");
          return;
        }
        if (response.status !== 200) {
          setError("Something went wrong, try again.");
          return;
        }

        const { data } = await onLogin();

        if (!data.currentUser) {
          setError("Something went wrong, try again.");
          return;
        }

        const nextSearchParams = new URLSearchParams(searchParams);

        nextSearchParams.set("uid", data.currentUser.externalId);

        const url = `${
          import.meta.env.VITE_API_HOST
        }/auth/oauth2/authorize?${nextSearchParams.toString()}`;

        window.location.assign(url);
      } catch (error) {
        captureException(error);
        setError("Something went wrong, try again.");
      }
    },
    [onLogin, searchParams]
  );

  return (
    <Middle>
      <Spacing size={5}>
        <FormHeader>
          <Heading size={1}>Sign in</Heading>
        </FormHeader>

        <Form onSubmit={handleLogin}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} autoComplete="on">
              <Spacing>
                <Field name="email">
                  {({ input }) => (
                    <InputField
                      {...input}
                      type="email"
                      label="Email"
                      autoComplete="username"
                    />
                  )}
                </Field>

                <PasswordContainer>
                  <Field name="password">
                    {({ input }) => (
                      <InputField
                        {...input}
                        type="password"
                        label="Password"
                        autoComplete="current-password"
                      />
                    )}
                  </Field>

                  <ResetPasswordLink type="button" onClick={onForgotPassword}>
                    Forgot password?
                  </ResetPasswordLink>
                </PasswordContainer>

                <Button
                  level="primary"
                  type="submit"
                  data-testid="login-button"
                  style={{ width: "100%" }}
                >
                  {submitting ? "Signing in..." : "Sign in"}
                </Button>
                {error && <ErrorText>{error}</ErrorText>}
              </Spacing>
            </form>
          )}
        </Form>
      </Spacing>
    </Middle>
  );
}
