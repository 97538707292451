import { useQuery } from "@otta/search/apollo";
import { CurrentUserDocument, CurrentUserQuery } from "@otta/search/schema";
import { useAnalytics } from "@otta/shared-components";

type User = NonNullable<CurrentUserQuery["currentUser"]>;

const getExperienceLevelList = (user: User) => {
  return Object.entries(user.jobExperiencePreference ?? {})
    .filter(([, value]) => value === true)
    .map(([key]) => {
      switch (key) {
        case "entryLevel":
          return "entry-level";
        case "oneToTwoYears":
          return "junior";
        case "threeToFourYears":
          return "mid-level";
        case "fiveToEightYears":
          return "senior";
        case "ninePlusYears":
          return "expert and leadership";
        default:
          return key;
      }
    });
};

const getUserProperties = (user: User) => {
  const functions = user.jobFunctionPreferences.map(pref => pref.functionId);
  const subFunctions = user.jobSubFunctionPreferences.map(
    pref => pref.subFunctionId
  );
  const locationPreferences = user.locationPreferences.map(
    pref => pref.location
  );
  const companySizes = user.companySizePreferences.map(
    pref => pref.companySizeId
  );
  const experienceLevels = getExperienceLevelList(user);

  return {
    role: user.role,
    insertedAt: user.insertedAt,
    functions,
    subFunctions,
    locationPreferences,
    companySizes,
    experienceLevels,
    showProfileToCompanies: user.showProfileToCompanies,
    profileCompletion: user.profileCompletion,
    searchStage: user.searchStage ?? undefined,
    signupRegion: user.signupRegion ?? undefined,
    numberJobsSeen: user.jobReactionStatistics.numberJobsSeen,
    numberJobsSaved: user.jobReactionStatistics.numberJobsSaved,
  };
};

const getDataLayerUserProperties = (user: User) => {
  const functions = user.jobFunctionPreferences.map(
    pref => pref.function?.value
  );
  const subFunctions = user.jobSubFunctionPreferences.map(
    pref => pref.subFunction?.value
  );
  const experienceLevels = getExperienceLevelList(user);

  return {
    userId: user.id,
    role: user.role,
    signupRegion: user.signupRegion ?? undefined,
    functionPreferences: functions,
    subFunctionPreferences: subFunctions,
    experienceLevels: experienceLevels,
  };
};

export function useUserAnalytics(): void {
  const { data } = useQuery(CurrentUserDocument, { errorPolicy: "all" });

  return useAnalytics(
    data?.currentUser,
    getUserProperties,
    getDataLayerUserProperties,
    {
      enabled: import.meta.env.PROD,
      pushToDataLayer: import.meta.env.PROD,
    }
  );
}
