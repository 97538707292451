import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { Link as L } from "@otta/search/components/Link";

const Link = styled(L)`
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ArticleIcon = styled(Icon)`
  margin-top: xxs;
  margin-right: sm;
  text-decoration: none;
`;

const ArticleText = styled(Text)`
  margin-top: xxs;
  margin-right: sm;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export function Article({
  url,
  title,
}: {
  url: string;
  title: string;
  small?: boolean;
}): React.ReactElement {
  return (
    <Container>
      <ArticleIcon icon="article" />
      <Link to={url} data-testid="article">
        <ArticleText>{title}</ArticleText>
      </Link>
    </Container>
  );
}
