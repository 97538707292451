import { To } from "react-router-dom";

import { ThemeId } from "@otta/search/schema";

type Id = Exclude<ThemeId, ThemeId.PreferredSector> | "ALL_MATCHES";
export type Theme<A> = { id: ThemeId.PreferredSector; sector: A } | { id: Id };

type SectorId = { id: string };
export type Sector = SectorId & { value: string };

export function themeUrl(theme: Theme<SectorId>): To {
  switch (theme.id) {
    case "ALL_MATCHES":
      return "/jobs";
    case ThemeId.ApplyViaOtta:
      return { pathname: "/jobs", search: "?theme=apply-via-otta" };
    case ThemeId.HasSalaries:
      return { pathname: "/jobs", search: "?theme=has-salaries" };
    case ThemeId.PreferredSector:
      return {
        pathname: "/jobs",
        search: `?theme=preferred-sector&sector-id=${theme.sector.id}`,
      };
    case ThemeId.FemaleFounders:
      return { pathname: "/jobs", search: "?theme=female-leaders" };
    case ThemeId.NewlyAdded:
      return { pathname: "/jobs", search: "?theme=newly-added" };
    case ThemeId.RecentlyFunded:
      return { pathname: "/jobs", search: "?theme=recently-funded" };
    case ThemeId.TechForGood:
      return { pathname: "/jobs", search: "?theme=tech-for-good" };
    case ThemeId.FullyRemote:
      return { pathname: "/jobs", search: "?theme=fully-remote" };
    case ThemeId.FavouriteTechnologies:
      return { pathname: "/jobs", search: "?theme=favourite-technologies" };
    case ThemeId.TakeAnotherLook:
      return { pathname: "/jobs", search: "?theme=take-another-look" };
  }
}
