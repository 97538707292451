import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { NextStep, StepType } from "../actions/NextStep";

import { pxToRem } from "@otta/design-tokens";
import { Button } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import {
  DismissNotificationDocument,
  UserNotificationDocument,
  Notification,
} from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";

interface NextStepsProps {
  nextStep?: NextStep;
  token?: string;
  onRefresh(seeMoreJobs?: boolean): void;
}

const NextStepButton = styled(Button)`
  margin-top: sm;
  height: ${pxToRem(40)};
`;

export default function NextSteps({
  nextStep,
  token,
  onRefresh,
}: NextStepsProps): React.ReactElement {
  const [refreshing, setRefreshing] = useState(false);

  const {
    data: notification,
    loading: resubLoading,
    refetch,
  } = useQuery(UserNotificationDocument, {
    variables: { pageType: "batch_end" },
    context: { emailToken: token },
  });

  const [dismissMutation] = useMutation(DismissNotificationDocument, {
    onError: handleMutationError,
  });

  const dismissNotification = useCallback(async () => {
    if (
      notification?.userNotification === Notification.BatchEndResubscribe &&
      !resubLoading
    ) {
      await dismissMutation({
        variables: { notificationName: "BATCH_END_RESUBSCRIBE" },
        context: { emailToken: token },
      });
      refetch();
    }
  }, [
    notification?.userNotification,
    resubLoading,
    dismissMutation,
    token,
    refetch,
  ]);

  const refresh = (seeMoreJobs = false) => {
    setRefreshing(true);
    dismissNotification();
    onRefresh(seeMoreJobs);
  };
  const navigate = useNavigate();

  const hasRemainingJobs: boolean =
    nextStep?.type !== StepType.GoBackToDashboard;

  const ignoreSubfunctions: boolean =
    nextStep?.type == StepType.RefreshRecommendations &&
    nextStep.ignoreSubfunctions;

  const hasSavedJobs: boolean =
    (nextStep?.type == StepType.GoBackToDashboard && nextStep?.hasSavedJobs) ??
    false;

  if (!ignoreSubfunctions && hasRemainingJobs) {
    return (
      <NextStepButton
        level={"primary"}
        onClick={() => refresh(true)}
        disabled={refreshing}
      >
        {refreshing ? "Loading..." : "See more jobs"}
      </NextStepButton>
    );
  }

  if (hasSavedJobs) {
    return (
      <NextStepButton
        data-analytics-id="review-saved-jobs"
        level={"primary"}
        onClick={() => refresh()}
        disabled={refreshing}
      >
        Review saved jobs
      </NextStepButton>
    );
  }

  if (ignoreSubfunctions && hasRemainingJobs) {
    return (
      <NextStepButton
        data-analytics-id="explore-more"
        level={"primary"}
        onClick={() => refresh()}
        disabled={refreshing}
      >
        {refreshing ? "Loading..." : `Explore more`}
      </NextStepButton>
    );
  }

  return (
    <NextStepButton
      data-analytics-id="return-to-dashboard"
      level={"primary"}
      onClick={() => {
        navigate("/");
      }}
    >
      Return to dashboard
    </NextStepButton>
  );
}
