import { Field, Form as FinalForm } from "react-final-form";
import styled from "@xstyled/styled-components";
import { useCallback, useMemo } from "react";

import { Button } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import {
  composeValidators,
  minLength,
  maxLength,
  minMaxLength,
  required,
} from "@otta/search/utils/validators";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Textarea } from "@otta/search/components/Input/Textarea";

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: sm;
`;

interface IMessageInputProps {
  disabled?: boolean;
  placeholder?: string;
  minChars?: number;
  maxChars?: number;
  onSubmit: ({
    accepted,
    message,
  }: {
    accepted: boolean;
    message: string;
  }) => void;
}

export function MessageInput({
  placeholder,
  disabled,
  minChars,
  maxChars,
  onSubmit,
}: IMessageInputProps): React.ReactElement {
  const onAccept = useCallback(
    async (content: string) => {
      await onSubmit({ accepted: true, message: content });
    },
    [onSubmit]
  );

  const wrappedOnSubmit = useCallback(
    ({ content }: { content: string }) => onAccept(content),
    [onAccept]
  );

  const validators = useMemo(() => {
    if (!maxChars && minChars && minChars > 0) {
      return [required, minLength(minChars)];
    } else if (!minChars && maxChars && maxChars > 0) {
      return [required, maxLength(maxChars)];
    } else if (minChars && maxChars && minChars > 0 && maxChars > 0) {
      return [required, minMaxLength(minChars, maxChars)];
    }

    return [required];
  }, [minChars, maxChars]);

  return (
    <FinalForm<{ content: string }> onSubmit={wrappedOnSubmit}>
      {({ form, handleSubmit, valid }) => (
        <form
          onSubmit={async e => {
            pushAnalyticsEvent({
              eventName: "Candidate Clicked",
              name: "send-button",
              page: "inbox",
            });
            await handleSubmit(e);
            setTimeout(form.reset);
          }}
        >
          <InputWrapper>
            <Field<string>
              name="content"
              validate={composeValidators(...validators)}
            >
              {({ input }) => (
                <Textarea
                  {...input}
                  placeholder={placeholder}
                  minHeight={pxToRem(20)}
                  maxHeight={pxToRem(150)}
                />
              )}
            </Field>

            <Button
              level="primary"
              type="submit"
              disabled={disabled || !valid}
              aria-label="Send"
            >
              Send
            </Button>
          </InputWrapper>
        </form>
      )}
    </FinalForm>
  );
}
