import styled, { css, down } from "@xstyled/styled-components";
import { motion, useReducedMotionConfig } from "framer-motion";

import { Tipbox, TipType, Button } from "@otta/design";
import {
  pxToRem,
  borderRadius,
  modularScale,
  palette,
} from "@otta/design-tokens";

const StyledTipbox = styled(Tipbox)<{
  dismissable?: boolean;
  hasIcon?: boolean;
}>(
  ({ dismissable, hasIcon }) => css`
    border-radius: 0;

    padding: lg;

    align-items: center;

    display: grid;
    grid-template-columns: ${hasIcon ? "1fr auto 1fr" : "auto 1fr"};

    column-gap: 5;

    ${down(
      "desktop",
      css`
        grid-template-columns: auto 1fr;
        align-items: start;

        ${dismissable &&
        css`
          grid-template-columns: 1fr;
        `};
      `
    )}

    ${down(
      "tablet",
      css`
        padding: sm;
      `
    )}
  `
);

const ContentWrapper = styled.div<{ hasIcon?: boolean }>`
  grid-column-start: ${({ hasIcon }) => (hasIcon ? "2" : "1")};
  display: flex;
  justify-content: center;
  align-items: center;

  ${down(
    "desktop",
    css`
      grid-column-start: 1;
      align-items: flex-start;
    `
  )}

  svg {
    margin-right: xs;
    flex-shrink: 0;
  }
`;

const DismissButton = styled(Button)<{ tipLevel: TipType }>`
  ${({ tipLevel }) => {
    switch (tipLevel) {
      case "warning":
        return css`
          background: ${palette.extended.orange.shade200};
        `;
      case "positive":
        return css`
          background: ${palette.brand.green};
        `;
      default:
        return css`
          background: ${palette.extended.blue.shade200};
        `;
    }
  }}
  border-radius: ${pxToRem(borderRadius)};
  border: none;

  font-size: ${modularScale(-2)};
  padding: xs sm;

  margin-left: auto;

  white-space: nowrap;
  height: fit-content;

  &:hover {
    ${({ tipLevel }) => {
      switch (tipLevel) {
        case "warning":
          return css`
            background: ${palette.extended.orange.shade400};
          `;
        case "positive":
          return css`
            background: ${palette.extended.green.shade400};
          `;
        default:
          return css`
            background: ${palette.extended.blue.shade400};
          `;
      }
    }}
  }
`;

const animation = {
  left: {
    x: -200,
  },
  right: {
    x: 200,
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.25,
    },
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.25,
    },
  },
};

const DismissButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
`;

export const DashboardBannerNotification = ({
  children,
  level,
  onClick,
  dismissable = true,
  hasIcon = true,
}: {
  children: React.ReactNode;
  level: TipType;
  onClick: () => void;
  dismissable?: boolean;
  hasIcon?: boolean;
}): React.ReactElement => {
  const reduceMotion = useReducedMotionConfig();

  const content = (
    <StyledTipbox level={level} dismissable={!dismissable} hasIcon={hasIcon}>
      <ContentWrapper hasIcon={hasIcon}>{children}</ContentWrapper>
      <DismissButtonWrapper>
        {dismissable && (
          <DismissButton level="primary" tipLevel={level} onClick={onClick}>
            ✕ Close
          </DismissButton>
        )}
      </DismissButtonWrapper>
    </StyledTipbox>
  );

  return reduceMotion ? (
    <div>{content}</div>
  ) : (
    <motion.div
      variants={animation}
      initial={["hidden", "left"]}
      animate="show"
      exit={["hidden", "right"]}
    >
      {content}
    </motion.div>
  );
};
