export function LinkedIn(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 500 500"
      {...props}
    >
      <g transform="translate(0 -552.36)">
        <rect
          y="552.36"
          width="500"
          height="500"
          rx="250"
          ry="250"
          fill="#2F3942"
        />
        <path
          d="m128.76 642.36c-21.414 0-38.763 17.389-38.763 38.842 0 21.453 17.35 38.842 38.763 38.842s38.763-17.389 38.763-38.842c0-21.453-17.35-38.842-38.763-38.842zm203.03 101.13v0.0263c-4.3783-0.0156-8.6265 0.15968-14.184 0.44737-12.189 0.63097-40.656 13.838-51.079 34.921 0-9.1533 0.10526-17.56 0.10526-30.105-12.485 0-28.824 0.0789-62.947 0.0789 0 38.308-0.42105 173.97-0.42105 213.5h66.079c0-23.45 0.31578-82.353 0.31578-119.34 0-12.42 7.5081-41.105 38.316-41.105 27.974 0 35.816 23.577 35.816 41.105 0 38.523-0.28947 92.976-0.28947 119.34h66.474c0-25.772 0.23633-95.779-0.55263-139.95-0.95794-53.628-33.533-76.51-62.763-78.316-6.015-0.37149-10.49-0.5893-14.868-0.60526zm-236.21 6.2105v212.55h66.263v-212.55h-66.263z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
