import { Helmet } from "react-helmet-async";

import { StartOnboarding } from "./StartOnboarding";

const HelmetComponent = () => {
  return (
    <Helmet>
      <title>Welcome to the Jungle | Sign up</title>
      <link rel="canonical" href="https://app.welcometothejungle.com/sign-up" />
    </Helmet>
  );
};

export const SignupPage: React.FC = () => {
  return (
    <>
      <HelmetComponent />
      <StartOnboarding />
    </>
  );
};
