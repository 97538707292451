import { pxToRem } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";

const APPLE_LINK =
  "https://apps.apple.com/app/apple-store/id1640509194?pt=124097318&ct=onboarding_modal&mt=8";

export const AppleBadge = (): React.ReactElement => (
  <a
    href={APPLE_LINK}
    onClick={() => {
      pushAnalyticsEvent({
        eventName: "Candidate Clicked",
        name: "download-the-app-apple",
      });
    }}
    style={{
      display: "inline-block",
      overflow: "hidden",
      borderRadius: pxToRem(13),
      height: pxToRem(50),
    }}
  >
    <img
      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1677888000"
      alt="Download on the App Store"
    />
  </a>
);
