import { CompanyPodcastData } from "./types";

export function Audio({ audio }: Pick<CompanyPodcastData, "audio">) {
  return (
    <audio
      id="podcast-audio-container"
      controls
      src={audio.src}
      data-testid="company-podcast-audio-player"
      style={{ width: "100%" }}
    />
  );
}
