import { useEffect } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { Spacing } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { ExperienceLevel } from "@otta/search/schema";
import { Checkbox } from "@otta/search/components/Input/Checkbox";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

type ExperienceValue =
  | "internship"
  | "entryLevel"
  | "oneToTwoYears"
  | "threeToFourYears"
  | "fiveToEightYears"
  | "ninePlusYears";

export const EXPERIENCE_LEVELS = [
  {
    label: "Internships",
    value: "internship",
    name: ExperienceLevel.Internship,
  },
  {
    label: "Entry-level/graduate",
    value: "entryLevel",
    name: ExperienceLevel.EntryLevel,
  },
  {
    label: "Junior (1-2 years)",
    value: "oneToTwoYears",
    name: ExperienceLevel.OneToTwoYears,
  },
  {
    label: "Mid-level (3-4 years)",
    value: "threeToFourYears",
    name: ExperienceLevel.ThreeToFourYears,
  },
  {
    label: "Senior (5-8 years)",
    value: "fiveToEightYears",
    name: ExperienceLevel.FiveToEightYears,
  },
  {
    label: "Expert & Leadership (9+ years)",
    value: "ninePlusYears",
    name: ExperienceLevel.NinePlusYears,
  },
] as const;

const PREFERENCE_LIMIT = 2;

export function JobExperience({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();

  const preference = preferencesData?.jobExperiencePreference;
  const isPresent = useIsPresent();

  const numSelected = Object.values(preference ?? {}).filter(
    prefValue => prefValue === true
  ).length;

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(numSelected > 0);
    }
  }, [numSelected, setNextEnabled, isPresent]);

  if (!preferencesData) {
    return <Loading />;
  }

  const handleChange = (value: ExperienceValue) => {
    const clonedPreference = { ...preference };
    if (clonedPreference[value]) {
      clonedPreference[value] = false;
    } else {
      clonedPreference[value] = true;
    }

    updatePreferences({
      jobExperiencePreference: clonedPreference,
    });
  };

  return (
    <Spacing size={-4}>
      {EXPERIENCE_LEVELS.map(({ label, value }) => {
        const selected = !!preference?.[value];

        return (
          <Checkbox
            key={value}
            value={value}
            label={label}
            disabled={numSelected >= PREFERENCE_LIMIT && !selected}
            checked={selected}
            onChange={() => handleChange(value)}
          />
        );
      })}
    </Spacing>
  );
}
