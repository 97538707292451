import { EXPERIENCE_LEVELS } from "../JobExperience";

import { SalariesMultiOptionField } from "./SalariesMultiOptionField";

import { QuestionComponentProps } from "@otta/search/containers/Quiz";

export function SalaryInputJobExperience(
  props: QuestionComponentProps
): React.ReactElement {
  const filteredExperienceLevels = EXPERIENCE_LEVELS.filter(
    experienceLevel => experienceLevel.name !== "INTERNSHIP"
  ).map(({ name, label }) => ({ id: name, value: label }));
  return (
    <SalariesMultiOptionField
      fieldName="currentExperienceLevel"
      options={filteredExperienceLevels}
      {...props}
    />
  );
}
