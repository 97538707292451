import { useEffect } from "react";
import styled from "@xstyled/styled-components";
import { useIsPresent } from "framer-motion";

import { IconsWrapper, IconWrapper, StyledText } from "./PositiveTechnologies";

import { pulse } from "@otta/design";
import {
  pxToRem,
  borderRadius,
  modularScale,
  palette,
} from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { CompanyQualitiesDocument } from "@otta/search/schema";
import { QuestionComponentProps } from "@otta/search/containers/Quiz";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { Heart } from "@otta/search/pages/Jobs/icons/Heart";

const PREFERENCE_LIMIT = 3;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Tag = styled.span<{ selected: boolean; disable: boolean }>`
  display: flex;
  background-color: ${({ selected }) =>
    selected ? palette.brand.yellow : palette.brand.white};
  color: ${({ disable, selected }) =>
    disable && !selected ? palette.grayscale.shade600 : palette.brand.black};
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
  -webkit-tap-highlight-color: transparent;
  padding: md;
  margin: sm sm 0 0;
  border-radius: ${pxToRem(borderRadius)};
  font-size: ${modularScale()};
  cursor: pointer;
  user-select: none;
  &:hover {
    animation: ${pulse} 0.2s ease-out;
  }
`;

export function CompanyQuality({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();

  const { data: companyQualitiesData } = useQuery(CompanyQualitiesDocument);

  const isPresent = useIsPresent();

  const preferences = preferencesData?.companyQualitiesPreferences ?? [];

  const limitReached = preferences.length >= PREFERENCE_LIMIT;

  const companyQualities = companyQualitiesData?.companyQualities;

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(limitReached);
    }
  }, [limitReached, setNextEnabled, isPresent]);

  const handleSelect = (companyQualityId: string) => {
    const set = new Set(preferences.map(pref => pref.companyQualityId));

    if (set.has(companyQualityId)) {
      set.delete(companyQualityId);
    } else if (limitReached) {
      // In case pointer-events: none doesn't stop it;
      return;
    } else {
      set.add(companyQualityId);
    }

    updatePreferences({
      companyQualitiesPreferences: Array.from(set).map(id => ({
        companyQualityId: id,
      })),
    });
  };

  if (!companyQualities || !preferencesData) {
    return <Loading />;
  }

  return (
    <Container>
      {companyQualities.map(companyQuality => {
        const selected = preferences.some(
          pref => pref.companyQualityId === companyQuality.id
        );

        return (
          <Tag
            key={companyQuality.id}
            disable={limitReached && !selected}
            onClick={() => handleSelect(companyQuality.id)}
            selected={selected}
          >
            <IconsWrapper>
              <IconWrapper enter={selected} exit={!selected} isHeart>
                <Heart />
              </IconWrapper>
            </IconsWrapper>
            <StyledText selected={selected}>{companyQuality.value}</StyledText>
          </Tag>
        );
      })}
    </Container>
  );
}
