import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { PageContainer, UnderlinedLink, SlideUpContainer } from "../shared";

import useJobApplications from "./useJobApplications";
import RespondedWizard from "./RespondedWizard";
import ProgressBar from "./ProgressBar";

import { Text, Middle, VerticalSpacing } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import { UpdateJobApplicationExternalRespondedDocument } from "@otta/search/schema";
import { useUtmSource } from "@otta/search/utils/routing/useUtmSource";

export function JobApplicationExternalResponded(): React.ReactElement {
  const utmSource = useUtmSource();
  const { externalId } = useParams();
  const [qs] = useSearchParams();
  const responded = qs.get("responded");
  const [mutate] = useMutation(UpdateJobApplicationExternalRespondedDocument);
  const [jobApplications, jobApplicationsLoading] = useJobApplications();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (jobApplicationsLoading) {
      return;
    }

    pushAnalyticsEvent({
      eventName: "Candidate Viewed External Responded Flow",
      utmSource,
      jobApplicationsShown: jobApplications.length,
      response: responded === "true" ? "Yes" : "No",
    });
  }, [utmSource, jobApplications.length, jobApplicationsLoading, responded]);

  useEffect(() => {
    if (externalId) {
      mutate({
        variables: { externalId, externalResponded: responded === "true" },
      });
    }
  }, [externalId, responded, mutate]);

  const onFinalPage = index >= jobApplications.length;

  return (
    <PageContainer>
      {!jobApplicationsLoading ? (
        <SlideUpContainer>
          <Middle>
            <VerticalSpacing>
              {!onFinalPage && (
                <Text bold size={1}>
                  Thanks for letting us know!
                </Text>
              )}

              {jobApplications.length > 1 && !onFinalPage && (
                <ProgressBar step={index} of={jobApplications.length} />
              )}

              <RespondedWizard
                jobApplications={jobApplications}
                index={index}
                onNext={() => setIndex(index + 1)}
              />

              {!onFinalPage && (
                <UnderlinedLink to="/">Do this later</UnderlinedLink>
              )}
            </VerticalSpacing>
          </Middle>
        </SlideUpContainer>
      ) : (
        <Loading />
      )}
    </PageContainer>
  );
}
