import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import styled from "@xstyled/styled-components";

import { ApplicationATSErrorModal } from "../components/ApplicationATSErrorModal";
import { IncompleteProfileModal } from "../components/IncompleteProfileModal";

import { Button } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  ProfileCompletionDocument,
  PublicJobDocument,
  JobDeckStatusDocument,
  UpdateJobApplicationDocument,
  JobQuestionDataDocument,
  JobApplicationDataDocument,
} from "@otta/search/schema";
import { jobValueClassification } from "@otta/search/utils/analytics/jobProperties";
import { evictDashboardJobsCache } from "@otta/search/pages/Dashboard/mutationUpdaterFn";
import { useUserProfileStatus } from "@otta/search/utils/user";

interface ISendApplicationButtonProps {
  jobId: string;
  disabled?: boolean;
  showConfirmationPopup?: () => void;
  level?: string;
}

const SendButton = styled(Button)`
  width: 100%;
`;

export function SendApplicationButton({
  jobId,
  disabled,
  showConfirmationPopup,
  level,
}: ISendApplicationButtonProps): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const [applyMutation, { loading, error }] = useMutation(
    UpdateJobApplicationDocument,
    {
      variables: { jobId, input: { internal: true, applied: true } },
      update: evictDashboardJobsCache,
      refetchQueries: [
        { query: JobDeckStatusDocument, variables: { externalId: jobId } },
      ],
      onError: error => {
        Sentry.captureException(error);
        if (job?.originalUrl) {
          setShowErrorModal(true);
        }
      },
    }
  );

  const { data: profileData } = useQuery(ProfileCompletionDocument);
  const { data: questionsData } = useQuery(JobQuestionDataDocument, {
    variables: { jobId },
  });
  const { data: applicationData, loading: applicationLoading } = useQuery(
    JobApplicationDataDocument,
    {
      variables: { jobId },
    }
  );
  const { data: jobData } = useQuery(PublicJobDocument, {
    variables: { externalId: jobId },
  });

  const { profileState, completedFields } = useUserProfileStatus();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);

  const user = profileData?.currentUser;
  const job = jobData?.publicJob;

  useEffect(() => {
    if (!applicationData?.latestJobApplication && !applicationLoading) {
      applyMutation({
        variables: { jobId, input: { internal: true, clicked: true } },
        refetchQueries: [
          {
            query: JobApplicationDataDocument,
            variables: { jobId },
          },
        ],
      });
    }
  }, [jobId, applyMutation, applicationLoading, applicationData]);

  useEffect(() => {
    if (profileState !== "NOT_CREATED") {
      pushAnalyticsEvent({
        eventName: "Candidate Completed Profile",
        completedFields,
        profileState,
      });
    }
    if (profileState === "DEEP" || profileState === "FULL") {
      pushAnalyticsEvent({
        eventName: "Candidate Completed Deep Profile",
        completedFields,
        profileState,
      });
    }
  }, [profileState, completedFields]);

  if (!user || !job || !questionsData || !applicationData) {
    return <Loading />;
  }

  const showIncompleteProfileWarning =
    profileState !== "DEEP" && profileState !== "FULL";

  const techJobFunctionIds = ["2", "6", "13", "22", "45"];

  const hasTechJobFunctionPrefs =
    user.jobFunctionPreferences.filter(pref =>
      techJobFunctionIds.includes(pref.functionId)
    ).length > 0;

  const totalApplyConfirms = user.totalJobApplicationConfirms ?? 0;

  const alreadyApplied = !!applicationData.latestJobApplication?.applied;
  const appliedInterally = !!applicationData.latestJobApplication?.internal;

  const atsError = error && job.originalUrl;

  if (alreadyApplied) {
    navigate(`/jobs/${jobId}/application`, { state: location.state });
  }
  const handleSubmitApplication = async () => {
    if (atsError) {
      setShowErrorModal(true);
    } else if (showIncompleteProfileWarning && !showIncompleteModal) {
      setShowIncompleteModal(true);
      pushAnalyticsEvent({
        eventName: "Candidate Shown Incomplete Application Warning",
        jobId: job.id,
        profileState,
      });
    } else {
      setShowIncompleteModal(false);
      pushAnalyticsEvent({
        eventName: "Candidate Confirmed Job Application",
        jobValueClassification: jobValueClassification(job.function?.id),
        totalApplicationConfirmations: totalApplyConfirms + 1,
        jobId: job.id,
        profileState,
        source: "Send Application Button",
      });
      const { errors } = await applyMutation();
      if (!errors) {
        navigate(`/jobs/${job.externalId}/application`, {
          replace: true,
          state: location.state,
        });
      }
    }
  };

  return (
    <>
      {atsError && showErrorModal && (
        <ApplicationATSErrorModal
          companyName={job.company.name}
          originalUrl={job.originalUrl as string}
          onClose={() => {
            setShowErrorModal(false);
            if (showConfirmationPopup) {
              showConfirmationPopup();
            }
          }}
        />
      )}
      {!atsError && showIncompleteModal && (
        <IncompleteProfileModal
          candidateFirstName={user.firstName}
          hasTechJobFunctionPrefs={hasTechJobFunctionPrefs}
          onSubmit={handleSubmitApplication}
          onCancel={() => setShowIncompleteModal(false)}
        />
      )}
      <SendButton
        level={level == "primary" ? "primary" : "secondary"}
        type="button"
        disabled={disabled}
        onClick={handleSubmitApplication}
        data-testid="send-application"
      >
        {alreadyApplied
          ? appliedInterally
            ? "Application sent"
            : "Applied directly"
          : loading
          ? "Sending..."
          : atsError
          ? "Apply directly"
          : "Send now"}
      </SendButton>
    </>
  );
}
