interface FileUploadProps {
  className?: string;
  style?: React.CSSProperties;
}

export function FileUpload({
  className,
  style,
}: FileUploadProps): React.ReactElement {
  return (
    <svg
      viewBox="0 0 384 512"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      fill="currentColor"
    >
      <path d="m369.83 97.98-83.89-83.88c-9-9-21.2-14.1-33.89-14.1h-204.06c-26.49.1-47.99 21.6-47.99 48.09v415.92c0 26.49 21.5 47.99 47.99 47.99h287.94c26.5 0 48.07-21.5 48.07-47.99v-332.04c0-12.69-5.17-24.99-14.17-33.99zm-113.88-45.99 76.09 76.08h-76.09zm80.05 412.02h-288.01v-415.92h159.97v103.98c0 13.3 10.7 23.99 24 23.99h104.04zm-153.02-236.22-72.31 71.77c-7.6 7.54-2.26 20.52 8.45 20.52h48.88v84c0 6.63 5.37 12 12 12h24c6.63 0 12-5.37 12-12v-84h48.88c10.71 0 16.05-12.97 8.45-20.52l-72.31-71.77c-4.99-4.95-13.05-4.95-18.04 0z" />
    </svg>
  );
}
