import { QueryResult } from "@apollo/client";

import { useQuery } from "@otta/search/apollo";
import { useUserLocation } from "@otta/search/hooks/useUserLocation";
import {
  CompanyByUrlSafeNameDocument,
  CompanyByUrlSafeNameQuery,
  CompanyByUrlSafeNameQueryVariables,
} from "@otta/search/schema";
import { countryToCurrency } from "@otta/search/utils/currencies";

export function useCompany(
  urlSafeName: string
): QueryResult<CompanyByUrlSafeNameQuery, CompanyByUrlSafeNameQueryVariables> {
  const { country: userLocation } = useUserLocation();
  const currency = countryToCurrency(userLocation);

  return useQuery(CompanyByUrlSafeNameDocument, {
    variables: { urlSafeName: urlSafeName, currency },
    errorPolicy: "ignore",
    ssr: true,
  });
}
