import styled from "@xstyled/styled-components";

import { ExperienceBar } from "../components/ExperienceBar";
import { JobLocationsSection } from "../components/JobLocationsSection";
import { SalarySection } from "../components/SalarySection";
import { TechnologiesUsedTags } from "../components/TechnologiesUsedTags";
import { JobCardContext } from "../JobHeader";

const TopCardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: md;
`;

export const SalaryTechExperienceLocation = ({
  job,
  user,
  isDesktopDashboard = false,
}: Pick<JobCardContext, "job" | "user"> & {
  isDesktopDashboard?: boolean;
}): React.ReactElement => {
  return (
    <TopCardSection>
      <SalarySection job={job} />
      <TechnologiesUsedTags
        job={job}
        user={user}
        isDesktopDashboard={isDesktopDashboard}
      />
      <ExperienceBar job={job} />
      <JobLocationsSection job={job} user={user} />
    </TopCardSection>
  );
};
