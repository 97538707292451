import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";

import { Group } from "./Group";
import { UnsubscribeFromAll } from "./UnsubscribeFromAll";
import { ConfirmEmailButton } from "./ConfirmEmailButton";

import { Card, Spacing, Text, Tipbox } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import {
  NotificationFrequency,
  TriggerConfirmationEmailDocument,
  UserSubscription,
  UserSubscriptionsDocument,
} from "@otta/search/schema";
import { Loading } from "@otta/shared-components";

export function NewNotificationSettings({
  jobEmailNotificationsFrequency,
  setEditingField,
  editingField,
  isOptedIn,
}: {
  setEditingField: (field: string | null) => void;
  editingField: string | null;
  jobEmailNotificationsFrequency: NotificationFrequency;
  isOptedIn: boolean;
}): React.ReactElement {
  const { data: userSubscriptionData, loading: userSubscriptionLoading } =
    useQuery(UserSubscriptionsDocument);

  const [triggerConfirmationEmail, { data: confirmEmailSent }] = useMutation(
    TriggerConfirmationEmailDocument
  );

  const [groups, setGroups] = useState<UserSubscription[]>([]);

  useEffect(() => {
    if (userSubscriptionData?.getUserSubscriptions) {
      setGroups(userSubscriptionData?.getUserSubscriptions);
    }
  }, [userSubscriptionData?.getUserSubscriptions]);

  const isGlobalUnsubscribe = useMemo(() => {
    if (userSubscriptionData?.getUserSubscriptions) {
      return !userSubscriptionData?.getUserSubscriptions?.some(({ channels }) =>
        channels?.some(({ value }) => value)
      );
    }
    return false;
  }, [userSubscriptionData?.getUserSubscriptions]);

  const messagesFromCompaniesUnsubscribed = useMemo(() => {
    const subscription = userSubscriptionData?.getUserSubscriptions?.find(
      ({ name }) => name === "Messages from companies"
    );

    return subscription && !subscription?.channels?.some(({ value }) => value);
  }, [userSubscriptionData?.getUserSubscriptions]);

  return (
    <Card style={{ padding: pxToRem(16) }}>
      <Spacing>
        <Text size={1} align="left" bold>
          Notifications
        </Text>
        {userSubscriptionLoading ? (
          <Loading />
        ) : (
          <>
            {!isOptedIn && !confirmEmailSent && (
              <Tipbox level="error">
                <Spacing>
                  <Text align="left" data-testid="candidate-opted-out-alert">
                    All of your email notifications are turned off.
                    <br />
                    <br />
                    This means you will not be emailed when a company messages
                    you about a job or recent application.
                    <br />
                    <br />
                    You need to confirm your email address to receive email
                    notifications.
                  </Text>
                  <ConfirmEmailButton mutation={triggerConfirmationEmail} />
                </Spacing>
              </Tipbox>
            )}
            {!isOptedIn && confirmEmailSent && (
              <Tipbox level="positive">
                <Spacing>
                  <Text align="left">
                    Click the link in the confirmation email we have sent you to
                    receive email notifications.
                  </Text>
                </Spacing>
              </Tipbox>
            )}
            {isGlobalUnsubscribe && (
              <div data-testid="unsubscribed-warning">
                <Tipbox level="warning">
                  <Text align="left">
                    You've turned off all notifications. This means you may miss
                    important updates, such as if a company messages you in
                    response to an application.
                    <br />
                    <br />
                    We'll still send you essential updates about your account,
                    like password reset emails and confirmation when you apply
                    for a job.
                  </Text>
                </Tipbox>
              </div>
            )}
            {!isGlobalUnsubscribe && messagesFromCompaniesUnsubscribed && (
              <div data-testid="message-from-companies-warning">
                <Tipbox level="warning">
                  <Text align="left">
                    You've turned off messages from companies. This means you
                    may miss important updates, such as a response to an
                    application.
                  </Text>
                </Tipbox>
              </div>
            )}
            {groups.map((group, idx) => (
              <Group
                key={group.id}
                group={group}
                displayUnderline={idx < groups.length - 1}
                jobEmailNotificationsFrequency={jobEmailNotificationsFrequency}
                editingField={editingField}
                setEditingField={field => setEditingField(field)}
              />
            ))}
            {!isGlobalUnsubscribe && (
              <UnsubscribeFromAll disabled={editingField !== null} />
            )}
          </>
        )}
      </Spacing>
    </Card>
  );
}
