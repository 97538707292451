import { palette } from "@otta/design-tokens";

export const CHART_COLORS = [
  palette.brand.yellow,
  palette.brand.teal,
  palette.brand.orange,
  palette.brand.blue,
  palette.brand.pink,
  palette.brand.green,
  palette.brand.purple,
];

export const UNKNOWN_COLOR = palette.grayscale.shade400;
