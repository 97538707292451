import { useCallback, useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";

import { Button, Spacing, Text, Modal } from "@otta/design";
import {
  pxToRem,
  borderRadius,
  modularScale,
  palette,
} from "@otta/design-tokens";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19;
  flex-wrap: wrap;

  > button {
    margin: xs 6;
    min-width: 100%;
    min-height: ${pxToRem(50)};
  }

  ${up(
    "tablet",
    css`
      > button {
        min-width: fit-content;
        min-height: fit-content;
      }
    `
  )}
`;

const Bubble = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  min-height: fit-content;
  border: ${pxToRem(2)} solid ${palette.grayscale.shade400};
  color: black;
  background-color: gray-50;
  border-radius: ${pxToRem(borderRadius)};
  padding: sm lg;
  cursor: pointer;

  &:hover {
    border-color: yellow-500;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: auto;
      opacity: 0.5;
      box-shadow: none !important;
    `}
`;

const ModalBody = styled.div`
  padding: 3xl;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export function QuickResponses({
  onSend,
  loading,
}: {
  onSend: ({
    accepted,
    message,
  }: {
    accepted: boolean;
    message: string;
  }) => void;
  loading: boolean;
}): React.ReactElement {
  const [declineMessage, setDeclineMessage] = useState<string | null>(null);
  const onAccept = useCallback(
    (message: string) => {
      onSend({ accepted: true, message });
    },
    [onSend]
  );

  const onDecline = useCallback(
    (message: string) => {
      onSend({ accepted: false, message });
    },
    [onSend]
  );

  const acceptOptions = [
    "This sounds great! Could you tell me more? Happy to have a call if it's easier",
    "This sounds interesting, but I’m not quite ready to commit just yet",
  ];

  const declineOptions = [
    "Sorry, the company doesn't feel like a great fit for me right now",
    "Sorry, the role doesn't feel like a great fit for me right now",
    "Sorry, the salary expectations don't align with what I'm looking for",
  ];

  return (
    <>
      <Wrapper data-testid="quick-responses-messaging">
        {acceptOptions.map((acceptOption: string, i) => (
          <Bubble
            key={i}
            data-analytics-id={`positive-quick-response-${acceptOption
              .split(" ")
              .pop()}`}
            onClick={loading ? undefined : () => onAccept(acceptOption)}
            disabled={loading}
          >
            <Text bold align="center" size={-1}>
              {acceptOption}
            </Text>
          </Bubble>
        ))}
        {declineOptions.map((declineOption: string, i) => (
          <Bubble
            key={i}
            data-analytics-id={`negative-quick-response-${
              declineOption.split(" ")[2]
            }`}
            onClick={
              loading ? undefined : () => setDeclineMessage(declineOption)
            }
            disabled={loading}
          >
            <Text bold align="center" size={-1}>
              {declineOption}
            </Text>
          </Bubble>
        ))}
      </Wrapper>

      <Modal
        open={!!declineMessage}
        onOpenChange={o => {
          if (!o) {
            setDeclineMessage(null);
          }
        }}
      >
        <ModalBody>
          <Spacing>
            <Text size={1} bold>
              Are you sure you want to decline this conversation?
            </Text>
            <ModalButtons>
              <Button
                data-analytics-id="decline-message-request"
                level="primary"
                onClick={() => {
                  if (declineMessage) {
                    onDecline(declineMessage);
                  }
                }}
                style={{ marginRight: modularScale() }}
              >
                Decline
              </Button>
              <Button level="secondary" onClick={() => setDeclineMessage(null)}>
                Cancel
              </Button>
            </ModalButtons>
          </Spacing>
        </ModalBody>
      </Modal>
    </>
  );
}
