import { useMutation } from "@apollo/client";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled, { css, down } from "@xstyled/styled-components";

import {
  borderRadius,
  boxShadow,
  modularScale,
  pxToRem,
} from "@otta/design-tokens";
import { Button, Text } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useQuery } from "@otta/search/apollo";
import {
  ApplyModalJobDocument,
  LastUnconfirmedJobApplicationDocument,
  UpdateJobApplicationDocument,
  UserTotalJobApplicationClicksDocument,
} from "@otta/search/schema";
import { jobValueClassification } from "@otta/search/utils/analytics/jobProperties";

const Container = styled.div`
  position: fixed;
  bottom: ${modularScale()};
  left: 50%;
  z-index: 10;
  background-color: gray-600;
  padding: xl;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: ${pxToRem(borderRadius)};
  box-shadow: ${boxShadow};

  ${down(
    "tablet",
    css`
      transform: translateX(-50%);
    `
  )}
`;

const SmallButton = styled(Button)`
  padding: 6 lg;
  font-size: ${modularScale()};
`;

const Buttons = styled.div`
  position: relative;
  margin-left: lg;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: sm;
  z-index: 10;
  pointer-events: auto;
`;

export function ConfirmationPopup({
  jobId,
  close,
}: {
  jobId: string;
  close: () => void;
}): React.ReactElement | null {
  const location = useLocation();
  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Candidate Shown",
      pathname: location.pathname,
      name: `Did You Apply Modal`,
    });
  }, [location.pathname]);
  const [updateJobApplication, { loading }] = useMutation(
    UpdateJobApplicationDocument,
    { onCompleted: close }
  );
  const { data } = useQuery(UserTotalJobApplicationClicksDocument);
  const { data: jobData, loading: jobLoading } = useQuery(
    ApplyModalJobDocument,
    {
      variables: { externalId: jobId },
    }
  );

  const totalApplyConfirms =
    data?.currentUser?.totalJobApplicationConfirms ?? 0;
  const job = jobData?.publicJob;

  const handleClick = useCallback(
    (applied: boolean) => {
      if (job) {
        const analyticsEventData = {
          jobValueClassification: jobValueClassification(job.function?.id),
          jobTitle: job.title,
          companyName: job.company.name,
          jobId: job.id,
          function: job.function?.value,
          subFunction: job.subFunction?.value,
          minYearsExperienceRequired: job.minYearsExperienceRequired,
          maxYearsExperience: job.maxYearsExperienceRequired,
          applyViaOtta: job.acceptsInternalApplications,
          technologiesUsed: (job.technologiesUsed ?? [])
            .map(({ value }) => value)
            .join(", "),
          onlyOnOtta: job.company.onlyOnOtta,
        };

        pushAnalyticsEvent({
          eventName: `Candidate Confirmed Job Application`,
          component: "ApplicationRoute",
          applied,
          totalApplicationConfirmations: totalApplyConfirms + 1,
          ...analyticsEventData,
          source: "Confirmation Popup",
        });
      }
      updateJobApplication({
        variables: {
          input: {
            applied,
            internal: false,
            confirmed: true,
          },
          jobId,
        },
        refetchQueries: () => [
          { query: LastUnconfirmedJobApplicationDocument },
        ],
      });
    },
    [job, updateJobApplication, jobId, totalApplyConfirms]
  );

  if (jobLoading) {
    return null;
  }

  return (
    <Container>
      <Text color="white" bold style={{ whiteSpace: "nowrap" }}>
        Did you apply?
      </Text>
      <Buttons>
        <SmallButton
          level="primary"
          onClick={() => handleClick(true)}
          disabled={loading}
        >
          Yes
        </SmallButton>
        <SmallButton
          level="secondary"
          onClick={() => handleClick(false)}
          disabled={loading}
        >
          No
        </SmallButton>
      </Buttons>
    </Container>
  );
}
