import { Text, Paragraph, Spacing } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";
import { Job, Location, UserJobPreferences } from "@otta/search/schema";
import { REMOTE_LOCATIONS } from "@otta/search/utils/locations";

interface SalarySubmissionProps {
  job: Pick<Job.Fragment, "externalId" | "locationPreferences">;
  user?: Pick<UserJobPreferences.CurrentUser, "hasSubmittedSalary">;
}

export function SalarySubmission({
  job: { externalId, locationPreferences },
  user,
}: SalarySubmissionProps): React.ReactElement | null {
  const cities = locationPreferences.filter(
    lp => !REMOTE_LOCATIONS.includes(lp.location)
  );

  const hasUKLocation = locationPreferences.some(
    lp => lp.location === Location.London
  );

  if (cities.length === 0 || hasUKLocation || user?.hasSubmittedSalary) {
    return null;
  }

  return (
    <Spacing>
      <Text
        align="left"
        size={1}
        style={{ marginRight: modularScale(-4) }}
        bold
      >
        Salary benchmarks
      </Text>
      <Paragraph align="left">
        We don&apos;t have enough data yet to provide salary benchmarks for this
        role.
      </Paragraph>
      <Paragraph data-testid="salary-submission">
        <Link
          to="/salaries/submit-data"
          state={{
            fromJobsCard: externalId,
            ukLocations: false,
          }}
        >
          <b>Submit your salary</b>
        </Link>{" "}
        to help other candidates with crowdsourced salary estimates.
      </Paragraph>
    </Spacing>
  );
}
