import { FollowedCompaniesPreferences } from "./FollowedCompaniesPreferences";

import { OttaIconsId } from "@otta/icons";
import {
  QuizSection,
  Question as SidebarQuestion,
} from "@otta/search/containers/Quiz/SidebarQuiz";
import { CompanyQuality } from "@otta/search/containers/Quiz/questions/CompanyQuality";
import { CompanySizePreference } from "@otta/search/containers/Quiz/questions/CompanySizePreference";
import { HiddenCompaniesPreference } from "@otta/search/containers/Quiz/questions/HiddenCompanies";
import { JobExperience } from "@otta/search/containers/Quiz/questions/JobExperience";
import { JobFunctionSubFunction } from "@otta/search/containers/Quiz/questions/JobFunctionSubFunction";
import { LanguageRequirement } from "@otta/search/containers/Quiz/questions/LanguageRequirement";
import { Location } from "@otta/search/containers/Quiz/questions/Location";
import { NegativeSectorTagsPreference } from "@otta/search/containers/Quiz/questions/NegativeSectorTagsPreference";
import { NegativeTechnologiesPreference } from "@otta/search/containers/Quiz/questions/NegativeTechnologiesPreference";
import { PositiveSectorTagsPreference } from "@otta/search/containers/Quiz/questions/PositiveSectorTagsPreference";
import { PositiveTechnologiesPreference } from "@otta/search/containers/Quiz/questions/PositiveTechnologiesPreference";
import { RemoteLocation } from "@otta/search/containers/Quiz/questions/RemoteLocation";
import { SalaryPreference } from "@otta/search/containers/Quiz/questions/SalaryPreference";
import { VisaSponsorship } from "@otta/search/containers/Quiz/questions/VisaSponsorship";

interface QuestionOptions {
  multipleLocations: boolean;
  showPositiveTechnologies: boolean;
  showNegativeTechnologies: boolean;
  showEmailNotifications: boolean;
  onlyRemoteOpportunities: boolean;
  hasHiddenCompanies: boolean;
  hasFollowedCompanies: boolean;
}

type ConditionalQuizSection = Omit<QuizSection, "questions"> & {
  questions: (false | SidebarQuestion)[];
};

export const sections = ({
  onlyRemoteOpportunities,
  multipleLocations,
  showPositiveTechnologies,
  showNegativeTechnologies,
  hasHiddenCompanies,
  hasFollowedCompanies,
}: QuestionOptions): QuizSection[] => {
  const sections: ConditionalQuizSection[] = [
    {
      title: "Location",
      icon: "location" as OttaIconsId,
      questions: [
        {
          menuTitle: "Locations",
          title: "Where would you like to work?",
          component: Location,
          path: "location",
        },
        onlyRemoteOpportunities && {
          menuTitle: "Remote locations",
          title: "Where could you work remotely from?",
          component: RemoteLocation,
          path: "remote-location",
        },
        {
          menuTitle: "Visa sponsorship",
          title: multipleLocations
            ? "Do you require a visa to work in these locations?"
            : `Do you require a visa to work in this location?`,
          component: VisaSponsorship,
          path: "visa-sponsorship",
        },
        {
          menuTitle: "Languages",
          title: "Which languages can you work in?",
          component: LanguageRequirement,
          path: "language-requirement",
        },
      ],
    },
    {
      title: "Role",
      icon: "user" as OttaIconsId,
      questions: [
        {
          menuTitle: "Type of role",
          title: "What types of roles would you like to see?",
          component: JobFunctionSubFunction,
          path: "function",
        },
        {
          menuTitle: "Level of role",
          title: "What level of roles would you like to see?",
          component: JobExperience,
          subtitle: "Select the most relevant for you (max 2)",
          path: "job-experience",
        },
        {
          menuTitle: "Minimum salary",
          title: "What is your minimum expected salary?",
          component: SalaryPreference,
          path: "salary",
        },
        {
          menuTitle: "Role priorities",
          title: "Which 3 are most important to you in a new role?",
          component: CompanyQuality,
          path: "quality",
        },
      ],
    },
    {
      title: "Industries",
      icon: "industry" as OttaIconsId,
      questions: [
        {
          menuTitle: "Favourite industries",
          title: "Do you have any favourite industries in mind?",
          component: PositiveSectorTagsPreference,
          path: "positive-company-sectors",
        },
        {
          menuTitle: "Hidden industries",
          title: "Are there any industries you want to exclude?",
          component: NegativeSectorTagsPreference,
          path: "negative-company-sectors",
        },
      ],
    },
    {
      title: "Technologies",
      icon: "technology" as OttaIconsId,
      questions: [
        {
          menuTitle: "Favourite technologies",
          title: "Do you have any favourite technologies in mind?",
          component: PositiveTechnologiesPreference,
          path: "positive-technologies",
          skip: !showPositiveTechnologies,
        },
        {
          menuTitle: "Hidden technologies",
          title: "Are there any technologies you want to exclude?",
          component: NegativeTechnologiesPreference,
          path: "negative-technologies",
          skip: !showNegativeTechnologies,
        },
      ],
    },
  ];

  const companiesSection = {
    title: "Company",
    icon: "company" as OttaIconsId,
    questions: [
      {
        menuTitle: "Company size",
        title: "Do you want to work for a specific size of company?",
        component: CompanySizePreference,
        path: "company-size",
      },
      {
        menuTitle: "Followed companies",
        title: hasFollowedCompanies
          ? "You are following these companies"
          : "No followed companies",
        subtitle:
          "We will notify you when they post new jobs that match your search preferences.",
        component: FollowedCompaniesPreferences,
        path: "followed-companies",
      },
      {
        menuTitle: "Hidden companies",
        title: hasHiddenCompanies
          ? "You have hidden these companies"
          : "No hidden companies",
        subtitle: "You will not be shown jobs at these companies.",
        component: HiddenCompaniesPreference,
        path: "hidden-companies",
      },
    ],
  };

  sections.push(companiesSection);

  return sections.map(section => ({
    ...section,
    questions: section.questions.filter((q): q is SidebarQuestion =>
      Boolean(q)
    ),
  }));
};
