import { hiringRegions } from "./utils";

import { Spacing, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";
import { UserJobPreferences, Job, Company } from "@otta/search/schema";

interface VisaDisclaimerComponentProps {
  children: React.ReactElement;
  version2?: boolean;
}

function VisaDisclaimerComponent({
  children,
}: VisaDisclaimerComponentProps): React.ReactElement {
  return (
    <Spacing>
      <Text as="h3" bold size={1}>
        Visa sponsorship
      </Text>
      <Text as="p" size={0}>
        {children}
      </Text>
    </Spacing>
  );
}

interface VisaDisclaimerProps {
  user?: Pick<UserJobPreferences.CurrentUser, "visaRequirementCountries">;
  job: Pick<Job.Fragment, "locationPreferences">;
  company: Pick<Company.Fragment, "visaSponsorshipCountries" | "name">;
}

export function VisaDisclaimer({
  user,
  job,
  company,
}: VisaDisclaimerProps): React.ReactElement {
  const regions = hiringRegions(job.locationPreferences);

  const visasNeeded = new Set(
    (user?.visaRequirementCountries ?? []).map(vrc => vrc.location)
  );

  // Need to include if offersVisa is null until every entry in the
  // company_visa_sponsorship_countries table has an offersVisa and
  // regionID value and we've started enforcing non-null values for each
  const visasOffered = new Set(
    company.visaSponsorshipCountries
      .filter(l => l.offersVisa || l.offersVisa === null)
      .map(l => l.location)
  );

  const visaMatches = new Set(
    Array.from(regions).filter(
      location => visasNeeded.has(location) && visasOffered.has(location)
    )
  );

  const showEUVisaCopy =
    regions.has("EU") && visasNeeded.has("EU") && !visasOffered.has("EU");

  const showCAVisaCopy =
    regions.has("CA") && visasNeeded.has("CA") && !visasOffered.has("CA");

  return (
    <>
      {showEUVisaCopy && (
        <VisaDisclaimerComponent>
          <>
            If you&apos;re offered this role, you may be able to apply for an EU
            Blue Card visa if you meet the education and salary requirements.{" "}
            <Link
              to="https://ec.europa.eu/immigration/blue-card/essential-information_en"
              underline
              style={{ color: palette.grayscale.shade600 }}
            >
              You can read more about the EU Blue Card program on the European
              Commission website.
            </Link>
          </>
        </VisaDisclaimerComponent>
      )}
      {showCAVisaCopy && (
        <VisaDisclaimerComponent>
          <>
            In most cases, you&apos;ll need to be granted a work permit before
            you can apply for roles based in Canada. There are a number of
            schemes available if you meet the education and salary requirements.{" "}
            <Link
              to="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry.html"
              underline
              style={{ color: palette.grayscale.shade600 }}
            >
              You can read more about relevant schemes on the Government of
              Canada website.
            </Link>
          </>
        </VisaDisclaimerComponent>
      )}
      {!showEUVisaCopy && !showCAVisaCopy && visaMatches.size > 0 && (
        <VisaDisclaimerComponent>
          <>
            {company.name} is able to sponsor visas, but that doesn&apos;t
            guarantee they are sponsoring for this role. Ask the hiring team for
            more information.
          </>
        </VisaDisclaimerComponent>
      )}
    </>
  );
}
