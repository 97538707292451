import { useMutation } from "@apollo/client";
import { AnimatePresence, motion } from "framer-motion";
import { useUp } from "@xstyled/styled-components";

import { ActionButton } from "./ActionButton";

import { pushAnalyticsEvent } from "@otta/analytics";
import {
  HideCompanyDocument,
  UnhideCompanyDocument,
  UserAdditionalInfoDocument,
  UserJobPreferences,
  Company,
} from "@otta/search/schema";
import { useVisibleTimer } from "@otta/search/hooks/useVisibleTimer";
import { Icon } from "@otta/icons";

interface HideCompanyLinkProps {
  user?: Pick<UserJobPreferences.CurrentUser, "hiddenCompanies" | "id">;
  company: Pick<Company.Fragment, "liveJobs" | "id">;
  expandOtherJobs?: () => void;
}

export function HideCompanyLink({
  user,
  ...rest
}: HideCompanyLinkProps): null | React.ReactElement {
  if (!user) {
    return null;
  }

  return <HideCompanyLinkComponent {...rest} user={user} />;
}

interface HideCompanyLinkComponentProps
  extends Omit<HideCompanyLinkProps, "user"> {
  user: NonNullable<HideCompanyLinkProps["user"]>;
}

function HideCompanyLinkComponent({
  user: { hiddenCompanies },
  company: { id: companyId },
}: HideCompanyLinkComponentProps): React.ReactElement {
  const [displayToast, setDisplayToast] = useVisibleTimer(2300);
  const isTablet = useUp("tablet");

  const [hideCompany] = useMutation(HideCompanyDocument, {
    refetchQueries: [{ query: UserAdditionalInfoDocument }],
  });

  const [unhideCompany] = useMutation(UnhideCompanyDocument, {
    refetchQueries: [{ query: UserAdditionalInfoDocument }],
  });

  const companyAlreadyHidden = (
    hiddenCompanies.length ? hiddenCompanies : []
  ).some(hiddenCompany => hiddenCompany.id === companyId);

  const handleClick = () => {
    if (!companyAlreadyHidden) {
      hideCompany({ variables: { companyId } });
      setDisplayToast(true);
    } else {
      unhideCompany({ variables: { companyId } });
    }
    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      name: companyAlreadyHidden
        ? "job-card-unhide-company"
        : "job-card-hide-company",
    });
  };

  return (
    <ActionButton
      toastMessage={
        "We won't show you jobs from this company any more. You can change this in your settings."
      }
      toastVisible={displayToast}
      toastInvertMotion={!isTablet}
      toastPosition={isTablet ? "center" : "left"}
      onClick={handleClick}
      icon={<Icon icon="ban" />}
      data-testid="hide-company"
      data-analytics-id="job-card-view-hide-jobs-button"
      title={
        <AnimatePresence initial={false} mode="wait">
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={companyAlreadyHidden ? "hidden" : "unhidden"}
          >
            {companyAlreadyHidden ? "Unhide company" : "Hide company"}
          </motion.span>
        </AnimatePresence>
      }
    />
  );
}
