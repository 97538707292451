import { motion, HTMLMotionProps } from "framer-motion";
import { ReactElement, ReactNode } from "react";

import { Card } from "@otta/design";

interface IAnimatedCardProps extends HTMLMotionProps<"div"> {
  children: ReactNode;
}

export default function AnimatedCard(props: IAnimatedCardProps): ReactElement {
  return (
    <Card
      as={motion.div}
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -300, opacity: 0 }}
      transition={{ ease: [0.41, -0.75, 0.83, 0.67], duration: 0.3 }}
      {...props}
    />
  );
}
