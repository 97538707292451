import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";

export function CompaniesHead(): React.ReactElement {
  return (
    <>
      <Helmet>
        <title>
          Companies - Welcome to the Jungle - The better way to find a job in
          tech
        </title>
      </Helmet>
      <Outlet />
    </>
  );
}
