import styled from "@xstyled/styled-components";
import { useNavigate } from "react-router-dom";

import { SectionCard, ActionButtonWrapper, JobLink } from "./Section";
import { CardTracking } from "./SpotlightShortlistCard";

import { JobChangeHandler, Section } from ".";

import { Button as DefaultButton, Dropdown, DropdownItem } from "@otta/design";
import { DashboardJobFragment, JobFragment } from "@otta/search/schema";
import { pushAnalyticsEvent } from "@otta/analytics";

const PrimaryActionButtonWrapper = styled(ActionButtonWrapper)`
  flex: 1 0 100%;
  width: 100%;

  max-width: 45%;
`;

const RemoveButton = styled.div`
  color: red-600;
`;

const Button = styled(DefaultButton)`
  white-space: nowrap;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  overflow-x: hidden;
`;

export interface IShortlistCardProps {
  job: DashboardJobFragment | JobFragment;
  section: Section;
  onChange: JobChangeHandler;
  isBatchEnd?: boolean;
  hasEmployerBranding?: boolean;
  cardsForTracking?: CardTracking[];
  currentCard?: number;
}

export function ShortlistCardActionButtons({
  job,
  section,
  onChange,
  isBatchEnd,
  hasEmployerBranding = false,
  cardsForTracking = [],
  currentCard = 0,
}: IShortlistCardProps): React.ReactElement {
  const navigate = useNavigate();
  const { id, externalId, live } = job;

  let viewJobDataAnalyticsId = isBatchEnd ? "batch-end-job-view" : null;
  let applyJobDataAnalyticsId = isBatchEnd ? "batch-end-job-apply" : null;
  if (hasEmployerBranding && isBatchEnd) {
    viewJobDataAnalyticsId += "-employer-branding";
    applyJobDataAnalyticsId += "-employer-branding";
  }

  const handleSelect = (newSection: Section) => {
    onChange({ id, section, newSection });
  };

  const handleClick = (variantName: string) => {
    pushAnalyticsEvent({
      eventName: "Candidate Clicked Saved Job Card",
      name: variantName,
      page: isBatchEnd ? "batch-end" : "dashboard",
      companyId: job.company.id,
      jobId: job.id,
      isEndCard: currentCard === cardsForTracking.length - 1,
      carouselCardsCount: cardsForTracking.length,
      currentCardContent: cardsForTracking[currentCard]?.name,
      currentCardVideoCategory: cardsForTracking[currentCard]?.videoCategory,
      currentCardVideoId: cardsForTracking[currentCard]?.videoId,
    });
  };

  const handleApply = () => {
    if (section === "SAVED") {
      handleClick("apply");
    }

    navigate(
      isBatchEnd
        ? `/jobs/batch-end/${externalId}/apply`
        : `/dashboard/jobs/${externalId}/apply`,
      {
        state: isBatchEnd ? "from-batch-end" : undefined,
      }
    );
  };

  return (
    <>
      {["APPLIED", "INTERVIEWING", "OFFER_RECEIVED", "OFFER_ACCEPTED"].includes(
        section
      ) ? (
        <ActionButtonWrapper>
          <Dropdown
            toggler={
              <Button size="small" level="secondary">
                View
              </Button>
            }
          >
            <JobLink
              to={`/jobs/${externalId}/application`}
              underline={false}
              data-testid="view-application-link"
              style={{ fontWeight: "normal" }}
              onClick={() => scroll(0, 0)}
            >
              <DropdownItem data-testid="view-application-button">
                Application
              </DropdownItem>
            </JobLink>
            <JobLink
              to={
                isBatchEnd
                  ? `/jobs/batch-end/${externalId}`
                  : `/dashboard/jobs/${externalId}`
              }
              underline={false}
              style={{ fontWeight: "normal" }}
            >
              <DropdownItem data-testid="view-job-button">Job</DropdownItem>
            </JobLink>
          </Dropdown>
        </ActionButtonWrapper>
      ) : (
        <ActionButtonWrapper>
          <JobLink
            to={
              isBatchEnd
                ? `/jobs/batch-end/${externalId}`
                : `/dashboard/jobs/${externalId}`
            }
            underline={false}
          >
            <Button
              size="small"
              level="secondary"
              data-testid="view-job-button"
              data-analytics-id={viewJobDataAnalyticsId}
              onClick={() => {
                if (section === "SAVED") {
                  handleClick("view");
                }
              }}
            >
              View
            </Button>
          </JobLink>
        </ActionButtonWrapper>
      )}
      {["APPLICATION_STARTED", "SAVED"].includes(section) && live && (
        <ActionButtonWrapper>
          {isBatchEnd ? (
            <Button
              size="small"
              level="secondary"
              onClick={handleApply}
              data-testid="apply-button"
            >
              Apply
            </Button>
          ) : (
            <JobLink
              to={`/dashboard/jobs/${externalId}/apply`}
              underline={false}
              data-testid="apply-button-link"
            >
              <Button
                size="small"
                level="secondary"
                data-testid="apply-button"
                data-analytics-id={applyJobDataAnalyticsId}
                onClick={() => {
                  if (section === "SAVED") {
                    handleClick("apply");
                  }
                }}
              >
                Apply
              </Button>
            </JobLink>
          )}
        </ActionButtonWrapper>
      )}
      {section === "APPLIED" && (
        <PrimaryActionButtonWrapper>
          <Button
            size="small"
            level="secondary"
            data-testid="interviewing-button"
            onClick={() => handleSelect("INTERVIEWING")}
          >
            I&apos;m interviewing
          </Button>
        </PrimaryActionButtonWrapper>
      )}

      {section === "INTERVIEWING" && (
        <PrimaryActionButtonWrapper>
          <Button
            size="small"
            level="secondary"
            data-testid="offer-received-button"
            onClick={() => handleSelect("OFFER_RECEIVED")}
          >
            I got an offer
          </Button>
        </PrimaryActionButtonWrapper>
      )}

      {section === "OFFER_RECEIVED" && (
        <PrimaryActionButtonWrapper>
          <Button
            size="small"
            level="secondary"
            data-testid="offer-accepted-button"
            onClick={() => handleSelect("OFFER_ACCEPTED")}
          >
            I accepted
          </Button>
        </PrimaryActionButtonWrapper>
      )}
      <ActionButtonWrapper>
        <Dropdown
          toggler={
            <Button
              level="secondary"
              data-testid="move-dropdown-button"
              size="small"
            >
              Move
            </Button>
          }
        >
          {section !== "SAVED" && (
            <DropdownItem onClick={() => handleSelect("SAVED")}>
              <div data-testid="move-saved-button">Saved</div>
            </DropdownItem>
          )}
          {section !== "APPLICATION_STARTED" && (
            <DropdownItem onClick={() => handleSelect("APPLICATION_STARTED")}>
              <div data-testid="move-started-button">Application started</div>
            </DropdownItem>
          )}
          {section !== "APPLIED" && (
            <DropdownItem onClick={() => handleSelect("APPLIED")}>
              <div data-testid="move-applied-button">Applied</div>
            </DropdownItem>
          )}
          {section !== "INTERVIEWING" && (
            <DropdownItem onClick={() => handleSelect("INTERVIEWING")}>
              <div data-testid="move-interviewing-button">Interviewing</div>
            </DropdownItem>
          )}
          {["APPLIED", "INTERVIEWING", "OFFER_ACCEPTED"].includes(section) && (
            <DropdownItem onClick={() => handleSelect("OFFER_RECEIVED")}>
              <div data-testid="move-offer-received-button">Offer received</div>
            </DropdownItem>
          )}
          {section === "OFFER_RECEIVED" && (
            <DropdownItem onClick={() => handleSelect("OFFER_ACCEPTED")}>
              <div data-testid="move-offer-accepted-button">Offer accepted</div>
            </DropdownItem>
          )}
          <DropdownItem onClick={() => handleSelect("DISCARDED")}>
            <RemoveButton data-testid="move-remove-button">Remove</RemoveButton>
          </DropdownItem>
        </Dropdown>
      </ActionButtonWrapper>
    </>
  );
}

export function ShortlistCard({
  job,
  section,
  onChange,
  isBatchEnd,
}: IShortlistCardProps): React.ReactElement {
  const { title, subtitle, company } = job;

  return (
    <SectionCard title={title} subtitle={subtitle} company={company}>
      <ShortlistCardActionButtons
        job={job}
        isBatchEnd={isBatchEnd}
        section={section}
        onChange={onChange}
      />
    </SectionCard>
  );
}
