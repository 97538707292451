import { useContext, useEffect } from "react";

import { ExperimentContext } from "@otta/search/providers/Unleash";
import { Experiment } from "@otta/search/constants/experiments";

type ExperimentName = (typeof Experiment)[keyof typeof Experiment];

type ExperimentResult<Payload> = {
  variant: string;
  payload: Payload;
};

interface VariantHookOptions {
  when?: boolean;
  amplitude?: boolean;
}

export function useVariant<Payload = undefined>(
  name: ExperimentName,
  { when = true, amplitude = false }: VariantHookOptions = {}
): ExperimentResult<Payload> {
  const { toggles, variantViewed } = useContext(ExperimentContext);

  const toggle = toggles[name];

  useEffect(() => {
    if (toggle && when) {
      return variantViewed(name, toggle.variant, amplitude);
    }
  }, [amplitude, name, toggle, variantViewed, when]);

  if (!toggle) {
    throw new Error(`Flag ${name} has not been requested`);
  }

  return toggle as ExperimentResult<Payload>;
}

export function useExperiment<
  Payload extends string | Record<string, unknown> | undefined = undefined
>(
  name: ExperimentName,
  opts: Pick<VariantHookOptions, "when"> = {}
): ExperimentResult<Payload> {
  return useVariant<Payload>(name, { ...opts, amplitude: true });
}
