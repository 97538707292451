import styled from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";
import { LatestJobApplication } from "@otta/search/schema";
import { Badge as DefaultBadge } from "@otta/search/components/Badge";
import { Magnify } from "@otta/search/components/Icons/Magnify";
import { Hourglass } from "@otta/search/components/Icons/Hourglass";
import { Remote } from "@otta/search/components/Icons/Remote";
import { Location as LocationIcon } from "@otta/search/components/Icons/Location";
import { REMOTE_PREFERENCES } from "@otta/search/globalConstants";

const BadgeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  > * {
    margin: xxs xs xxs 0;
  }
`;

const Badge = styled(DefaultBadge)`
  text-transform: capitalize;
  > div {
    border-radius: ${pxToRem(24)};
  }
`;

export function Badges({
  candidate,
  job,
}: {
  candidate: LatestJobApplication.Candidate;
  job: LatestJobApplication.Job;
}): React.ReactElement {
  const respondsQuickly =
    candidate.averageResponseTime &&
    candidate.averageResponseTime < 60 * 60 * 24;

  const jobLocationPreferences = job.locationPreferences ?? [];
  const isJobRemote = jobLocationPreferences.some(pref =>
    REMOTE_PREFERENCES.includes(pref.location)
  );
  const cityOverlaps = jobLocationPreferences
    .map(pref => pref.location)
    .filter(loc => !REMOTE_PREFERENCES.includes(loc))
    .filter(loc =>
      candidate.locationPreferences.map(pref => pref.location).includes(loc)
    )
    .map(locationString => ({
      label: locationString.replace("_", " "),
      value: locationString,
    }));

  const worksRemotely = candidate.locationPreferences?.some(pref =>
    REMOTE_PREFERENCES.includes(pref.location)
  );

  const timezoneLocation = candidate.timezone?.location;

  return (
    <BadgeWrapper data-testid="badges-section">
      {respondsQuickly && (
        <Badge
          badgeText="Responds quickly"
          hoverText="The candidate typically responds within 24 hours"
          icon={Hourglass}
          colour="green"
        />
      )}
      {candidate.searchStage === "looking_now" && (
        <Badge
          icon={Magnify}
          badgeText="Looking ASAP"
          colour="green"
          hoverText="The candidate is looking for new roles now"
        />
      )}
      {isJobRemote && worksRemotely && (
        <Badge
          badgeText="Remote OK"
          hoverText={timezoneLocation}
          icon={Remote}
          colour="green"
        />
      )}
      {cityOverlaps.length > 0 &&
        cityOverlaps.map((overlap, index) => (
          <Badge
            key={`overlap-${index}`}
            badgeText={overlap.label}
            hoverText={`The candidate would like to work in ${overlap.label}`}
            icon={LocationIcon}
            colour="green"
          />
        ))}
    </BadgeWrapper>
  );
}
