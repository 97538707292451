import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import Linkify from "react-linkify";
import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";
import { UpdateConversationMessageReadDocument } from "@otta/search/schema";
import { DoubleCheck } from "@otta/search/components/Icons/DoubleCheck";

const MessageBubble = styled.div<{ fromCandidate: boolean }>`
  word-wrap: break-word;
  border-radius: 8;
  background-color: ${({ fromCandidate }) =>
    fromCandidate ? palette.brand.green : palette.brand.white};
  max-width: 100%;
`;

const MessageContent = styled(Text)`
  white-space: pre-wrap;
  color: black;
  padding: md;
  a {
    word-break: break-word;
    color: inherit;
  }
`;

const MessageTimestamp = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 lg xs lg;
`;

export interface EnrichedMessage {
  id: string;
  content: string;
  read: Maybe<boolean>;
  timestamp: string;
  meta?: React.ReactNode;
}

interface IConversationMessageProps extends EnrichedMessage {
  fromCandidate: boolean;
}

export function ConversationMessage({
  id,
  fromCandidate,
  read,
  meta,
  timestamp,
  content,
}: IConversationMessageProps): React.ReactElement {
  const [viewedMutation] = useMutation(UpdateConversationMessageReadDocument);

  useEffect(() => {
    if (id && !fromCandidate && read === false) {
      viewedMutation({ variables: { messageId: id, read: true } });
    }
  }, [id, fromCandidate, read, viewedMutation]);

  return (
    <MessageBubble data-cs-mask fromCandidate={fromCandidate}>
      <Linkify
        componentDecorator={(
          decoratedHref: string,
          decoratedText: string,
          key: number
        ) => (
          <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
            {decoratedText}
          </a>
        )}
      >
        <MessageContent>{content}</MessageContent>
      </Linkify>
      {meta}

      <MessageTimestamp>
        <Text size={-2} as="span" color={palette.grayscale.shade600}>
          {timestamp}
        </Text>
        {fromCandidate && read && (
          <DoubleCheck
            style={{
              maxHeight: modularScale(-1),
              marginLeft: modularScale(-5),
              fill: palette.grayscale.shade600,
            }}
          />
        )}
      </MessageTimestamp>
    </MessageBubble>
  );
}
