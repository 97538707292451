import { Field } from "react-final-form";

import { Spacing } from "@otta/design";
import { Textarea } from "@otta/search/components/Input/Textarea";
import { StrengthBar } from "@otta/search/components/StrengthBar";

const maxStrengthCharacters = 750;

export function EditTextareaField({
  name,
}: {
  name: string;
}): React.ReactElement {
  return (
    <Field
      name={name}
      render={({ input, meta }) => {
        return (
          <Spacing>
            <StrengthBar
              currentStrength={Math.round(
                ((input?.value?.length ?? 0) / maxStrengthCharacters) * 100
              )}
            />
            <Textarea
              {...input}
              placeholder={`Type your answer here...\n\n\n`}
              error={meta.touched && meta.error}
              minHeight="200px"
            />
          </Spacing>
        );
      }}
    />
  );
}
