import { Location } from "@otta/search/schema";

export interface LocationOption {
  label: string;
  value: Location;
  isDisabled?: boolean;
}

export const AVAILABLE_CITIES: LocationOption[] = [
  { label: "Amsterdam", value: Location.Amsterdam },
  { label: "Austin", value: Location.AustinUs },
  { label: "Barcelona", value: Location.Barcelona },
  { label: "Berlin", value: Location.Berlin },
  { label: "Boston", value: Location.BostonUs },
  { label: "Chicago", value: Location.ChicagoUs },
  { label: "Denver", value: Location.DenverUs },
  { label: "Dublin", value: Location.Dublin },
  { label: "London", value: Location.London },
  { label: "Los Angeles", value: Location.LosAngelesUs },
  { label: "Miami", value: Location.MiamiUs },
  { label: "New York", value: Location.NewYorkUs },
  { label: "Paris", value: Location.Paris },
  { label: "San Francisco Bay Area", value: Location.SanFranciscoBayAreaUs },
  { label: "Toronto", value: Location.Toronto },
  { label: "Vancouver", value: Location.Vancouver },
];

export const REMOTE_LOCATIONS_WITH_LABELS: LocationOption[] = [
  { label: "Remote (within the UK)", value: Location.RemoteUk },
  { label: "Remote (within the US)", value: Location.RemoteUs },
  { label: "Remote (within the EU)", value: Location.RemoteEurope },
  { label: "Remote (within France)", value: Location.RemoteFrance },
  { label: "Remote (within Germany)", value: Location.RemoteGermany },
  { label: "Remote (within Ireland)", value: Location.RemoteIreland },
  {
    label: "Remote (within the Netherlands)",
    value: Location.RemoteNetherlands,
  },
  { label: "Remote (within Spain)", value: Location.RemoteSpain },
  { label: "Remote (within Canada)", value: Location.RemoteCanada },
];

export const LOCATION_NAME: Partial<Record<Location, string>> = {
  [Location.Amsterdam]: "Amsterdam",
  [Location.AustinUs]: "Austin",
  [Location.Barcelona]: "Barcelona",
  [Location.Berlin]: "Berlin",
  [Location.BostonUs]: "Boston",
  [Location.ChicagoUs]: "Chicago",
  [Location.DenverUs]: "Denver",
  [Location.Dublin]: "Dublin",
  [Location.London]: "London",
  [Location.LosAngelesUs]: "Los Angeles",
  [Location.MiamiUs]: "Miami",
  [Location.NewYorkUs]: "New York",
  [Location.Paris]: "Paris",
  [Location.SanFranciscoBayAreaUs]: "San Francisco Bay Area",
  [Location.Toronto]: "Toronto",
  [Location.Vancouver]: "Vancouver",
  [Location.RemoteUk]: "UK",
  [Location.RemoteUs]: "US",
  [Location.RemoteCanada]: "Canada",
  [Location.RemoteEurope]: "Anywhere else within the EU",
  [Location.RemoteFrance]: "France",
  [Location.RemoteGermany]: "Germany",
  [Location.RemoteIreland]: "Ireland",
  [Location.RemoteNetherlands]: "Netherlands",
  [Location.RemoteSpain]: "Spain",
};

export const REMOTE_LOCATIONS = [
  Location.RemoteUk,
  Location.RemoteUs,
  Location.RemoteEurope,
  Location.RemoteFrance,
  Location.RemoteGermany,
  Location.RemoteIreland,
  Location.RemoteNetherlands,
  Location.RemoteSpain,
  Location.RemoteCanada,
];

export const REMOTE_EU_LOCATIONS = [
  Location.RemoteFrance,
  Location.RemoteGermany,
  Location.RemoteIreland,
  Location.RemoteNetherlands,
  Location.RemoteSpain,
];

export const locationName = (location: Location): string | undefined => {
  switch (location) {
    case Location.AustinUs:
      return "Austin";
    case Location.BostonUs:
      return "Boston";
    case Location.ChicagoUs:
      return "Chicago";
    case Location.DenverUs:
      return "Denver";
    case Location.London:
      return "London";
    case Location.LosAngelesUs:
      return "Los Angeles";
    case Location.MiamiUs:
      return "Miami";
    case Location.NewYorkUs:
      return "New York";
    case Location.SanFranciscoBayAreaUs:
      return "San Francisco Bay Area";
    case Location.Berlin:
      return "Berlin";
    case Location.Paris:
      return "Paris";
    case Location.Amsterdam:
      return "Amsterdam";
    case Location.Barcelona:
      return "Barcelona";
    case Location.Dublin:
      return "Dublin";
    case Location.Toronto:
      return "Toronto";
    case Location.Vancouver:
      return "Vancouver";
    case Location.RemoteCanada:
      return "Canada";
    case Location.RemoteGermany:
      return "Germany";
    case Location.RemoteNetherlands:
      return "the Netherlands";
    case Location.RemoteFrance:
      return "France";
    case Location.RemoteUk:
      return "the UK";
    case Location.RemoteUs:
      return "the US";
    case Location.RemoteSpain:
      return "Spain";
    case Location.RemoteIreland:
      return "Ireland";
    case Location.RemoteEurope:
      return "the EU";
    default:
      return undefined;
  }
};

export const locationsToString = (locations: Location[]): string => {
  if (locations.some(l => l === Location.RemoteEurope)) {
    locations = locations.filter(l => !REMOTE_EU_LOCATIONS.includes(l));
  }

  const cityLocations: string[] = [];
  const remoteLocations: string[] = [];

  locations.forEach(location => {
    if (location) {
      const loc = locationName(location);
      loc &&
        (REMOTE_LOCATIONS.includes(location)
          ? remoteLocations
          : cityLocations
        ).push(loc);
    }
  });

  cityLocations.sort();
  remoteLocations.sort();

  const cityLocationsText = !remoteLocations.length
    ? cityLocations.join(", or ")
    : cityLocations.join(", ");

  const joiner =
    cityLocations.length > 0 && remoteLocations.length > 0 ? `, or ` : ``;

  const remoteLocationsText = (
    remoteLocations.length > 0 ? `Remote in ${remoteLocations.join(", ")}` : ``
  ).replace(/the /g, "");

  const fullText = `${cityLocationsText}${joiner}${remoteLocationsText}`;

  return fullText;
};
