import { InputHTMLAttributes } from "react";
import { TypedDocumentNode, useMutation } from "@apollo/client";

import { InputField } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { UserInput } from "@otta/search/schema";

interface ITextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  fieldName: string;
  label: string;
  valid?: boolean;
  mutation: TypedDocumentNode<unknown, { input: UserInput }>;
  eventName?: string;
  refetchQueries?: () => any[];
  awaitRefetchQueries?: boolean;
  error?: string;
}

export function TextField({
  fieldName,
  valid,
  mutation,
  eventName,
  refetchQueries = () => [],
  awaitRefetchQueries = false,
  label,
  ...props
}: ITextFieldProps): React.ReactElement {
  const [updateMutation] = useMutation(mutation, {
    awaitRefetchQueries,
    refetchQueries,
  });

  return (
    <InputField
      {...props}
      type="text"
      name={props.name || fieldName}
      label={label}
      onBlur={e => {
        if (valid) {
          updateMutation({
            variables: {
              input: {
                [fieldName]: e.target.value,
              },
            },
          });
          if (typeof eventName === "string") {
            pushAnalyticsEvent({ eventName });
          }
        }
      }}
    />
  );
}
