import styled from "styled-components";
import { useState, useMemo, useCallback, useEffect } from "react";

import { Spacing, Text } from "@otta/design";
import { Checkbox } from "@otta/search/components/Input/Checkbox";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { Loading } from "@otta/shared-components";
import { FollowedCompanyInput } from "@otta/search/schema";
import { QuestionComponentProps } from "@otta/search/containers/Quiz";

const UnfollowAllButton = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

const FollowedCompaniesList = ({
  initialData,
  update,
}: {
  initialData: FollowedCompanyInput[];
  update: (data: FollowedCompanyInput[]) => void;
}) => {
  const [followedCompanies, setFollowedCompanies] = useState<
    Map<string, FollowedCompanyInput>
  >(new Map(initialData.map(c => [c.id, c])));

  const handleClickCompany = (company: FollowedCompanyInput) => {
    const newValue = new Map(followedCompanies);
    if (newValue.has(company.id)) {
      newValue.delete(company.id);
    } else {
      newValue.set(company.id, company);
    }
    update(Array.from(newValue.values()));
    setFollowedCompanies(newValue);
  };

  const handleClickAll = () => {
    if (followedCompanies.size === 0) {
      update(initialData);
      setFollowedCompanies(new Map(initialData.map(c => [c.id, c])));
    } else {
      update([]);
      setFollowedCompanies(new Map());
    }
  };

  const allButtonLabel = useMemo(
    () => (followedCompanies.size === 0 ? "Follow all" : "Unfollow all"),
    [followedCompanies]
  );

  return (
    <Spacing>
      <UnfollowAllButton
        onClick={handleClickAll}
        data-analytics-id="unfollowAllCompanies"
      >
        {allButtonLabel}
      </UnfollowAllButton>
      {initialData.map(company => (
        <Checkbox
          key={company.id}
          label={company.name}
          checked={followedCompanies.has(company.id)}
          onChange={() => handleClickCompany(company)}
        />
      ))}
    </Spacing>
  );
};

export function FollowedCompaniesPreferences(
  props: QuestionComponentProps
): React.ReactElement | null {
  const { preferencesData, updatePreferences, loading } = useUserPreferences();

  const initialData = useMemo(
    () => (!loading && preferencesData?.followedCompanies) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  );

  useEffect(() => {
    if (preferencesData) {
      props.setNextEnabled(true);
    }
  }, [props, preferencesData]);

  const update = useCallback(
    (followedCompanies: FollowedCompanyInput[]) => {
      updatePreferences({ ...preferencesData, followedCompanies });
    },
    [preferencesData, updatePreferences]
  );

  if (loading) {
    return <Loading />;
  }

  if (initialData.length < 1) {
    return null;
  }

  return <FollowedCompaniesList initialData={initialData} update={update} />;
}
