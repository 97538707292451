import { useState } from "react";
import { Field, FieldInputProps } from "react-final-form";
import styled from "@xstyled/styled-components";
import { getDate, getMonth, getYear, isValid } from "date-fns";

import { Error } from "../../../components/FormData";

import { Select } from "@otta/search/components/Input/Select";

const DatePickerContainer = styled.div`
  display: flex;
  margin: 0 -6px;
`;

const StyledError = styled(Error)`
  margin-top: 10px;
`;

const MONTHS = [
  { label: "January", value: 0 },
  { label: "February", value: 1 },
  { label: "March", value: 2 },
  { label: "April", value: 3 },
  { label: "May", value: 4 },
  { label: "June", value: 5 },
  { label: "July", value: 6 },
  { label: "August", value: 7 },
  { label: "September", value: 8 },
  { label: "October", value: 9 },
  { label: "November", value: 10 },
  { label: "December", value: 11 },
];

// Generate days from 1 to 31
const DAYS = Array.from(Array(31)).map((_, i) => {
  return {
    label: `${i + 1}`,
    value: i + 1,
  };
});

// Generate years from 1970 to 2039
const YEARS = Array.from(Array(70)).map((e, i) => ({
  label: `${i + 1970}`,
  value: i + 1970,
}));

interface IDate {
  year: number;
  month: number;
  day: number;
}

const parseDate = ({ year, month, day }: IDate): Date | undefined => {
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return undefined;
  }
  const parsedDate = new Date(year, month, day);

  return isValid(parsedDate) ? parsedDate : undefined;
};

function DatePicker({
  onChange,
  value: currValue,
}: FieldInputProps<Date | null, HTMLElement>): React.ReactElement {
  const [currDay, setCurrDay] = useState<number>(
    currValue ? getDate(currValue) : NaN
  );
  const [currMonth, setCurrMonth] = useState<number>(
    currValue ? getMonth(currValue) : NaN
  );
  const [currYear, setCurrYear] = useState<number>(
    currValue ? getYear(currValue) : NaN
  );

  const handleChange = ({ year, month, day }: IDate) => {
    onChange({
      target: { value: parseDate({ year, month, day }) },
    });
  };

  const handleChangeMonth = (
    option: { label: string; value: number } | null
  ) => {
    const month = option?.value ?? NaN;
    setCurrMonth(month);
    handleChange({ year: currYear, month, day: currDay });
  };

  const handleChangeYear = (
    option: { label: string; value: number } | null
  ) => {
    const year = option?.value ?? NaN;
    setCurrYear(year);
    handleChange({ year: year, month: currMonth, day: currDay });
  };

  const handleChangeDay = (option: { label: string; value: number } | null) => {
    const day = option?.value ?? NaN;
    setCurrDay(day);
    handleChange({ year: currYear, month: currMonth, day: day });
  };

  return (
    <DatePickerContainer>
      <Select
        styles={{
          container: base => ({ ...base, width: "100%", padding: "6px" }),
        }}
        placeholder="Day"
        onChange={handleChangeDay}
        value={DAYS.find(({ value: d }) => d === currDay)}
        options={DAYS}
      />

      <Select
        styles={{
          container: base => ({ ...base, width: "100%", padding: "6px" }),
        }}
        placeholder="Month"
        onChange={handleChangeMonth}
        value={MONTHS.find(({ value: m }) => m === currMonth)}
        options={MONTHS}
      />

      <Select
        styles={{
          container: base => ({ ...base, width: "100%", padding: "6px" }),
        }}
        placeholder="Year"
        onChange={handleChangeYear}
        value={YEARS.find(({ value: y }) => y === currYear)}
        options={YEARS}
      />
    </DatePickerContainer>
  );
}

export function EditDateField({ name }: { name: string }): React.ReactElement {
  return (
    <Field
      name={name}
      render={({ input, meta }) => {
        return (
          <>
            <DatePicker {...input} />
            {meta.error && meta.touched && (
              <StyledError>{meta.error}</StyledError>
            )}
          </>
        );
      }}
    />
  );
}
