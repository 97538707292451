import {
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useEffect, useMemo, useRef } from "react";
import { AnimatePresence } from "framer-motion";

import { JobDeck } from "./JobDeck";
import { NoJobsLeft } from "./JobDeck/NoJobsLeft";
import { BatchOptions, useBatchOptions } from "./JobDeck/BatchOptions";
import { PublicJob } from "./PublicJob";
import {
  JobRecommendationProvider,
  useJobRecommendations,
} from "./JobRecommendationProvider";
import { JobContent } from "./JobCard/tabs/JobContent";
import { CompanyProfile } from "./JobCard/tabs/CompanyProfile";

import { Spinner } from "@otta/search/components/Loading";
import { ThemeId } from "@otta/search/schema";
import { OnboardingQuizFinishScreen } from "@otta/search/components/LoadingScreens";
import { ApplyModalWrapper } from "@otta/search/pages/Dashboard/ApplyModalWrapper";
import ShortlistJob from "@otta/search/pages/Dashboard/ShortlistJob";
import { Redirect } from "@otta/search/router";

const PageWrapper = styled.div<{ hide: boolean }>`
  position: relative;
  display: ${({ hide }) => (hide ? "none" : "block")};
`;

const SpinnerWrapper = styled.div`
  padding: 100px 0;
`;

interface JobProps {
  token?: string;
  params: BatchOptions;
}

function Job({ token, params }: JobProps): React.ReactElement {
  const { jobId } = useParams<"jobId">();

  const { recommendations, loading } = useJobRecommendations();

  const initialJobId = useRef(jobId).current;

  const allJobs = useMemo(() => {
    if (
      initialJobId &&
      initialJobId !== "batch-end" &&
      !recommendations.some(r => r.id === initialJobId)
    ) {
      return [{ id: initialJobId, reacted: true }, ...recommendations];
    }
    return recommendations;
  }, [initialJobId, recommendations]);

  const progress = useMemo(() => {
    const idx = allJobs.findIndex(({ reacted }) => reacted == null);
    if (idx > -1) {
      return idx;
    }

    return allJobs.length;
  }, [allJobs]);

  const jobIds = useMemo(() => allJobs.map(({ id }) => id), [allJobs]);

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <JobDeck
      data-testid="job-deck"
      jobId={jobId}
      jobs={jobIds}
      token={token}
      progress={progress}
      params={params}
    />
  );
}

function FirstJob({ params }: { params: BatchOptions }): React.ReactElement {
  const location = useLocation();
  const { recommendations, loading } = useJobRecommendations();

  const firstJobWithoutReaction = useMemo(
    () => recommendations.find(({ reacted }) => reacted == null),
    [recommendations]
  );

  const isTakeAnotherLook =
    params.type === "theme" && params.theme === ThemeId.TakeAnotherLook;

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (!recommendations.length) {
    return <NoJobsLeft params={params} />;
  }

  const first = isTakeAnotherLook
    ? recommendations[0].id
    : firstJobWithoutReaction
    ? firstJobWithoutReaction.id
    : "batch-end";

  return (
    <Redirect
      to={{ pathname: first, search: location.search }}
      state={location.state}
    />
  );
}

function BatchRecommendations({
  token,
  params,
}: {
  token?: string;
  params: BatchOptions;
}): React.ReactElement {
  const location = useLocation();

  const { onboard = false } = (location.state ?? {}) as {
    onboard?: true;
  };

  return (
    <>
      <AnimatePresence>
        {onboard && <OnboardingQuizFinishScreen />}
      </AnimatePresence>
      <PageWrapper hide={onboard}>
        <JobRecommendationProvider token={token} params={params}>
          <Routes>
            <Route path="/" element={<FirstJob params={params} />} />
            <Route
              path=":jobId"
              element={<Job params={params} token={token} />}
            >
              <Route index element={<JobContent />} />
              <Route path="company" element={<CompanyProfile />} />
            </Route>
          </Routes>
        </JobRecommendationProvider>
      </PageWrapper>
      <Routes>
        <Route path="batch-end/:jobId" element={<ShortlistJob batchEnd />}>
          <Route index element={<JobContent />} />
          <Route path="company" element={<CompanyProfile />} />
        </Route>
        <Route
          path="batch-end/:jobId/apply"
          element={<ApplyModalWrapper batchEnd />}
        />
      </Routes>
    </>
  );
}

function Recommendations({ token }: { token?: string }): React.ReactElement {
  const batchOptions = useBatchOptions();

  if (batchOptions === undefined) {
    return <Redirect to="/jobs" />;
  }

  return <BatchRecommendations token={token} params={batchOptions} />;
}

export default function Jobs({
  isPublic,
}: {
  isPublic?: boolean;
}): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();

  const emailToken = useRef(searchParams.get("token")).current;

  useEffect(() => {
    if (emailToken) {
      const search = new URLSearchParams(searchParams);
      search.delete("token");
      setSearchParams(search);
    }
  }, [searchParams, emailToken, setSearchParams]);

  if (emailToken) {
    return <Recommendations token={emailToken} />;
  }

  if (isPublic) {
    return (
      <Routes>
        <Route path="/" element={<Redirect to="/" />} />
        <Route path=":jobId" element={<PublicJob />}>
          <Route index element={<JobContent />} />
          <Route path="company" element={<CompanyProfile />} />
        </Route>
      </Routes>
    );
  }

  return <Recommendations />;
}
