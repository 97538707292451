import styled, { up, css } from "@xstyled/styled-components";

import { ArrowDirection } from "../../types";
import { NavButton, NavContainer } from "../styledJobCardComponents";

import { Spacing } from "@otta/design";
import { Icon } from "@otta/icons";

const MobileOnly = styled.div`
  display: none;
  ${up(
    "desktop",
    css`
      display: block;
    `
  )}
`;

export const NavArrow = ({
  onClick: handleClick,
  direction,
  testId,
}: {
  onClick: () => void;
  direction: ArrowDirection;
  testId?: string;
}): React.ReactElement => {
  return (
    <MobileOnly>
      <NavContainer align={direction}>
        <Spacing>
          <NavButton onClick={handleClick} data-testid={testId}>
            <Icon icon={`chevron-${direction}`} size={1} />
          </NavButton>
        </Spacing>
      </NavContainer>
    </MobileOnly>
  );
};
