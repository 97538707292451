import styled, { css, up } from "@xstyled/styled-components";
import { motion } from "framer-motion";
import { useState } from "react";

import { BatchOptions } from "../BatchOptions";

import { StepType, useNextStep } from "./actions/NextStep";

import { palette, modularScale } from "@otta/design-tokens";
import {
  Spacing,
  Text as DefaultText,
  Button as DefaultButton,
} from "@otta/design";

const Wrapper = styled(motion.div)<{ version2?: boolean }>`
  ${({ version2 }) =>
    version2 &&
    css`
      ${up(
        "tablet",
        css`
          background: ${palette.brand.yellow};
        `
      )}}
    `}
`;

const Text = styled(DefaultText)<{ version2?: boolean }>`
  font-size: ${modularScale()};

  ${({ version2 }) =>
    version2 &&
    css`
      padding-top: lg;
    `}

  ${up(
    "tablet",
    css`
      font-size: ${modularScale(1)};
    `
  )}
`;

const Button = styled(DefaultButton)<{ version2?: boolean }>`
  ${({ version2 }) =>
    !version2 &&
    css`
      margin-bottom: lg;
    `}
`;

interface BatchEndTopProps {
  params: BatchOptions;
  token?: string;
  version2?: boolean;
}

export function BatchEndTop({
  params,
  token,
  version2 = false,
}: BatchEndTopProps): React.ReactElement | null {
  const [clicked, setClicked] = useState(false);
  const [nextStep, apply] = useNextStep(params, token, "cache-only");

  const handleRefreshClick = () => {
    setClicked(true);
    apply();
  };

  if (nextStep && nextStep?.type !== StepType.GoBackToDashboard) {
    return (
      <Wrapper
        version2={version2}
        key="batch-end-card"
        initial={{ opacity: version2 ? 1 : 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ height: { ease: "easeInOut" } }}
        style={{ textAlign: "center" }}
      >
        <Spacing>
          <Text version2={version2} bold>
            You&apos;ve seen all the jobs in this batch
          </Text>
          <Button
            version2={version2}
            level={version2 ? "secondary" : "primary"}
            onClick={handleRefreshClick}
            disabled={clicked}
          >
            {clicked ? "Loading..." : "See more matches"}
          </Button>
        </Spacing>
      </Wrapper>
    );
  }
  return null;
}
