export function Link(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 13 13"
      fill="none"
      {...props}
    >
      <g fill="#2F3942">
        <path d="m4.5377 5.9649 1.3308-0.76833c0.87669-0.50615 1.9977-0.20578 2.5039 0.67091 0.50616 0.87669 0.20578 1.9977-0.67091 2.5039l-3.9515 2.2814c-0.87669 0.5062-1.9977 0.2058-2.5039-0.6709-0.50615-0.87669-0.20578-1.9977 0.67091-2.5039l1.3131-0.75812-0.5-0.86602-1.3131 0.75812c-1.355 0.7823-1.8192 2.5149-1.0369 3.8699 0.7823 1.355 2.5149 1.8192 3.8699 1.0369l3.9515-2.2814c1.355-0.78229 1.8192-2.5149 1.0369-3.8699-0.7823-1.355-2.5149-1.8192-3.8699-1.0369l-1.3308 0.76833 0.5 0.86602z" />
        <path d="m4.874 3.5274 3.9515-2.2814c0.87669-0.50615 1.9977-0.20578 2.5038 0.67091 0.5062 0.87669 0.2058 1.9977-0.6709 2.5039l-1.3633 0.7871 0.5 0.86602 1.3633-0.7871c1.355-0.78229 1.8193-2.5149 1.037-3.8699-0.7823-1.355-2.5149-1.8192-3.8699-1.0369l-3.9515 2.2814c-1.355 0.78229-1.8192 2.5149-1.0369 3.8699s2.5149 1.8192 3.8699 1.0369l1.265-0.73035-0.5-0.86602-1.265 0.73035c-0.87669 0.50615-1.9977 0.20578-2.5039-0.67091-0.50615-0.87669-0.20578-1.9977 0.67091-2.5039z" />
      </g>
    </svg>
  );
}
