export function CircleUser(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.9999 10.625C16.8359 10.625 14.2708 13.1901 14.2708 16.3542C14.2708 19.5183 16.8359 22.0834 19.9999 22.0834C23.164 22.0834 25.7291 19.5183 25.7291 16.3542C25.7291 13.1901 23.164 10.625 19.9999 10.625ZM19.9999 18.9584C18.5637 18.9584 17.3958 17.7898 17.3958 16.3542C17.3958 14.9154 18.5611 13.75 19.9999 13.75C21.4387 13.75 22.6041 14.918 22.6041 16.3542C22.6041 17.793 21.4387 18.9584 19.9999 18.9584ZM19.9999 3.33337C10.7942 3.33337 3.33325 10.7943 3.33325 20C3.33325 29.2058 10.7942 36.6667 19.9999 36.6667C29.2056 36.6667 36.6666 29.2058 36.6666 20C36.6666 10.7943 29.2056 3.33337 19.9999 3.33337ZM19.9999 33.5417C16.9576 33.5417 14.1562 32.5209 11.8944 30.821C13.0208 28.6589 15.2083 27.2917 17.6692 27.2917H22.3365C24.7942 27.2917 26.9791 28.6595 28.1099 30.821C25.8463 32.5196 23.0403 33.5417 19.9999 33.5417ZM30.4296 28.6263C28.6718 25.8789 25.677 24.1667 22.3306 24.1667H17.6692C14.3254 24.1667 11.3313 25.8757 9.57023 28.625C7.62752 26.2826 6.45825 23.2748 6.45825 20C6.45825 12.5326 12.5331 6.45837 19.9999 6.45837C27.4667 6.45837 33.5416 12.5332 33.5416 20C33.5416 23.2748 32.3697 26.2826 30.4296 28.6263Z"
        fill="#4F4F42"
      />
    </svg>
  );
}
