import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
  margin-top: -4px;
  svg {
    width: 40px;
  }
`;

const FieldInstructions = styled.div<{ error: boolean }>`
  user-select: none;
  margin-bottom: 18px;
  text-align: center;
  color: ${({ error }) => (error ? palette.brand.red : palette.brand.black)};
`;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

interface IEmptyState {
  onBlur?: (e: any) => void;
  iconComponent?: React.ReactNode;
  error?: boolean;
  message?: string;
  button?: React.ReactNode;
}

export const EmptyState = ({
  onBlur,
  iconComponent,
  message,
  button,
  error = false,
  ...props
}: IEmptyState): React.ReactElement => (
  <EmptyStateContainer onBlur={onBlur} {...props}>
    {iconComponent && <IconWrapper>{iconComponent}</IconWrapper>}
    {message && <FieldInstructions error={error}>{message}</FieldInstructions>}
    {button}
  </EmptyStateContainer>
);
