import { DocumentNode } from "graphql";
import {
  MutationFunction,
  RefetchQueriesFunction,
  useMutation,
} from "@apollo/client";

import { Button, Middle, Spacing, Text } from "@otta/design";

interface IMultiOptionFieldProps<
  F extends string,
  Custom extends boolean,
  TData,
  Variables,
  OData,
  OVariables,
  V
> {
  options: { id: string; value: string }[];
  mutation: DocumentNode;
  generateMutationVariables: (id: string) => Variables;
  selectedOption:
    | (Custom extends true ? V : { id: string; value: string })
    | null;
  handleNext: () => void;
  fieldName: F;
  optimisticResponse: (
    x: F,
    y: Custom extends true ? string : { id: string; value: string }
  ) => (z: Variables) => TData;
  customOptions?: Custom;
  otherOption?: boolean;
  otherOptionMutation?: MutationFunction<OData, OVariables>;
  otherOptionMutationVariables?: OVariables;
  selectedOtherOption?: boolean;
  refetchQueries?: RefetchQueriesFunction;
  awaitRefetchQueries?: boolean;
}

export function MultiOptionField<
  F extends string,
  Custom extends boolean,
  TData,
  Variables,
  OData,
  OVariables,
  V
>({
  options,
  mutation,
  generateMutationVariables,
  selectedOption,
  handleNext,
  fieldName,
  optimisticResponse,
  otherOption = false,
  otherOptionMutation,
  otherOptionMutationVariables,
  selectedOtherOption,
  customOptions,
  refetchQueries = () => [],
  awaitRefetchQueries = false,
}: IMultiOptionFieldProps<
  F,
  Custom,
  TData,
  Variables,
  OData,
  OVariables,
  V
>): React.ReactElement {
  const [mutate] = useMutation<TData, Variables>(mutation);
  const createClickHandler =
    (option: { id: string; value: string }) => async () => {
      await mutate({
        variables: generateMutationVariables(option.id),
        awaitRefetchQueries,
        refetchQueries,
        optimisticResponse: optimisticResponse(
          fieldName,
          (customOptions ? option.id : option) as Custom extends true
            ? string
            : { id: string; value: string }
        ),
      });
      handleNext();
    };

  const currentValue = customOptions
    ? selectedOption !== null
      ? (selectedOption as V)
      : undefined
    : (selectedOption as { id: string; value: string } | null)?.id;

  return (
    <Middle style={{ width: "100%" }}>
      <Spacing size={-2}>
        {options.map(option => (
          <Button
            key={option.id}
            style={{ width: "100%" }}
            type="button"
            level={
              currentValue === null || option.id === currentValue
                ? "primary"
                : "secondary"
            }
            onClick={createClickHandler(option)}
          >
            <Text>{option.value}</Text>
          </Button>
        ))}
        {otherOption && otherOptionMutation && (
          <div>
            <Button
              style={{ width: "100%" }}
              level={selectedOtherOption ? "primary" : "secondary"}
              type="button"
              onClick={async () => {
                await otherOptionMutation({
                  variables: otherOptionMutationVariables,
                });
                handleNext();
              }}
            >
              Other
            </Button>
          </div>
        )}
      </Spacing>
    </Middle>
  );
}
