import { CSSProperties } from "react";

export interface WttjSymbolProps {
  className?: string;
  style?: CSSProperties;
  color?: string;
}
/**
 * ```ts
 *
 * import { WttjSymbol } from '@otta/design'
 *
 * ```
 */

export function WttjSymbol({
  className,
  style,
  color = "#000",
}: WttjSymbolProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="125 125 250 250"
      className={className}
      style={style}
      fill={color}
    >
      <path d="M250 125c-68.9 0-125 56.1-125 125s56.1 125 125 125 125-56.1 125-125-56.1-125-125-125zm0 10.7c9.7 0 19 1.2 28 3.5-3.5 29.8-13.1 63.3-28 63.3-14.9-.1-24.5-33.5-28-63.3 9-2.3 18.3-3.5 28-3.5zM200.3 147c7 28.7 28.7 62.5 49.6 62.6 20.9 0 42.6-33.9 49.6-62.6 5.7 2.8 11.1 6 16.3 9.6-11 25.9-35.4 62.6-65.9 63.5-30.6-.8-54.9-37.5-65.9-63.5 5.2-3.6 10.7-6.8 16.3-9.6zm-34.2 25.4c15.3 22.8 48.4 54.8 83.9 54.8s68.5-32 83.9-54.8c4.2 4.5 8 9.4 11.4 14.5-16.2 20.9-50.2 50.8-95.3 50.8-45.1 0-79.1-30-95.3-50.8 3.4-5.1 7.3-10 11.4-14.5zM250 364.3c-9.7 0-19-1.2-28-3.5 3.5-29.8 13.1-63.3 28-63.3 14.9.1 24.5 33.5 28 63.3-9 2.3-18.3 3.5-28 3.5zm49.7-11.3c-7-28.7-28.7-62.5-49.6-62.6-20.9 0-42.6 33.9-49.6 62.6-5.7-2.8-11.1-6-16.2-9.6 11-25.9 35.3-62.6 65.9-63.5 30.6.8 54.9 37.5 65.9 63.5-5.3 3.6-10.8 6.8-16.4 9.6zm34.2-25.4c-15.3-22.8-48.4-54.8-83.9-54.8-35.5 0-68.6 32-83.9 54.8-4.2-4.5-8-9.4-11.4-14.5 16.2-20.9 50.2-50.8 95.3-50.8 45.1 0 79.1 30 95.3 50.8-3.4 5.1-7.2 10-11.4 14.5zM250 255.3c-41.3 0-80.9 14.2-106.7 35.7-4.9-12.7-7.6-26.6-7.6-41 0-14.5 2.7-28.3 7.6-41 25.8 21.5 65.4 35.7 106.7 35.7s80.8-14.2 106.7-35.7c4.9 12.7 7.6 26.6 7.6 41 0 14.5-2.7 28.3-7.6 41-25.9-21.5-65.4-35.7-106.7-35.7z" />
    </svg>
  );
}
