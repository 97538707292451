export function Tick(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.43455 11.7315L0.234549 6.53148C-0.0778574 6.21907 -0.0778574 5.71254 0.234549 5.4001L1.36589 4.26873C1.6783 3.95629 2.18486 3.95629 2.49727 4.26873L6.00024 7.77167L13.5032 0.268729C13.8156 -0.0436777 14.3222 -0.0436777 14.6346 0.268729L15.7659 1.4001C16.0783 1.71251 16.0783 2.21904 15.7659 2.53148L6.56592 11.7315C6.25349 12.0439 5.74695 12.0439 5.43455 11.7315Z"
        fill="currentColor"
      />
    </svg>
  );
}
