import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createLink } from "apollo-absinthe-upload-link";
import { print } from "graphql";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/browser";
import { parse as parseCookies } from "cookie";

import { typePolicies } from "./apollo/typePolicies";

const authLink = setContext((_, { headers, emailToken }) => {
  const cookies = parseCookies(document.cookie);

  return {
    headers: {
      ...headers,
      // get the csrf token if it exists
      "X-CSRF-Token": cookies[import.meta.env.VITE_CSRF_COOKIE],
      "X-XSRF-Token": cookies[import.meta.env.VITE_CSRF_TOKEN_COOKIE],
      ...(emailToken ? { "X-Otta-Email-Token": emailToken } : {}),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  Sentry.withScope(scope => {
    scope.addBreadcrumb({
      type: "http",
      category: "graphql",
      level: "error",
      data: {
        name: operation.operationName,
        query: operation.query.loc?.source?.body ?? print(operation.query),
      },
    });

    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        Sentry.captureMessage(error.message, "error");
      });
    }

    if (networkError) {
      Sentry.captureException(networkError);
    }
  });
});

const httpLink = createLink({
  uri: `${import.meta.env.VITE_API_HOST}/graphql`,
  credentials: "include",
});

export const apolloClient = new ApolloClient({
  ssrForceFetchDelay: 100,
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({ typePolicies }).restore(window.__APOLLO_STATE__),
});
