import { BulletPointSection } from "./BulletPointSection";

import { Job } from "@otta/search/schema";

interface ApplicationProcessProps {
  job: Pick<Job.Fragment, "applicationProcessBullets">;
}

export function ApplicationProcess({
  job: { applicationProcessBullets },
}: ApplicationProcessProps): React.ReactElement {
  const bullets = applicationProcessBullets.map(b => ({
    id: b.id,
    text: b.value,
  }));

  return (
    <BulletPointSection
      title="Application process"
      bullets={bullets}
      bulletTestId="job-application-process-bullet"
    />
  );
}
