import styled from "@xstyled/styled-components";

import { Company, Gender } from "@otta/search/schema";
import { Badge } from "@otta/search/components/Badge";
import { OttaIconsId } from "@otta/icons";

interface Highlight {
  title: string;
  dataTest: string;
  subtitle?: string;
  icon: React.ComponentType | OttaIconsId;
}

const getStrongHiringActivityHighlight = (
  activelyHiring?: boolean
): Highlight[] => {
  if (activelyHiring) {
    return [
      {
        title: `Strong hiring activity`,
        subtitle:
          "Data from the company shows applications are being progressed for this role",
        icon: "chart-up",
        dataTest: "strong-hiring-activity-highlight",
      },
    ];
  }
  return [];
};

const getFemaleFounderHighlight = (
  founders: { gender: Maybe<Gender> }[]
): Highlight[] => {
  if (founders.some(({ gender }) => gender === Gender.Female)) {
    return [
      {
        title: `Led by a woman`,
        icon: "female",
        dataTest: "female-founder-highlight",
      },
    ];
  }
  return [];
};

const getBCorpHighlight = (bcorp: boolean): Highlight[] => {
  if (bcorp) {
    return [
      {
        title: `B Corporation`,
        icon: "rocketlist",
        dataTest: "bcorp-highlight",
      },
    ];
  }
  return [];
};

const getInvestorHighlight = (investors: Company["investors"]): Highlight[] => {
  const subtitle = investors.map(i => (i.topInvestor ? i.value : null));
  if (investors.length > 0) {
    return [
      {
        title: `Top investors`,
        icon: "money-bill",
        dataTest: "top-investor-highlight",
        subtitle: subtitle.join(", "),
      },
    ];
  }
  return [];
};
const getRocketListHighlight = (companyId: string): Highlight[] => {
  const rocketListCompanyIds = [
    "15",
    "35",
    "56",
    "67",
    "70",
    "71",
    "97",
    "106",
    "107",
    "124",
    "129",
    "147",
    "169",
    "180",
    "192",
    "208",
    "233",
    "240",
    "240",
    "245",
    "250",
    "254",
    "256",
    "346",
    "348",
    "365",
    "387",
    "403",
    "623",
    "680",
    "683",
    "690",
    "698",
    "706",
    "746",
    "748",
    "748",
    "756",
    "785",
    "790",
    "798",
    "799",
    "809",
    "839",
    "859",
    "875",
    "878",
    "962",
    "1049",
    "1192",
    "1224",
    "1310",
  ];
  if (rocketListCompanyIds.includes(companyId)) {
    return [
      {
        title: "Rocket List 2021",
        subtitle:
          "Every year we select the 50 most exciting companies for you to accelerate your career",
        icon: "rocketlist",
        dataTest: "rl-2021-highlight",
      },
    ];
  }
  return [];
};

const getHighlights = ({
  company,
  activelyHiring,
}: HighlightsProps): Highlight[] => {
  const highlights = [
    ...getStrongHiringActivityHighlight(activelyHiring),
    ...getFemaleFounderHighlight(company.founders),
    ...getBCorpHighlight(company.bcorp),
    ...getRocketListHighlight(company.id),
    ...getInvestorHighlight(company.investors),
  ];

  return highlights;
};

const HighlightWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: sm;

  svg {
    min-width: 16px;
    max-width: 16px;
  }
`;

interface HighlightsProps {
  company: Pick<
    Company.Fragment,
    "glassdoorRating" | "founders" | "bcorp" | "id" | "investors"
  >;
  activelyHiring?: boolean;
}

export function Highlights({ company, activelyHiring }: HighlightsProps) {
  const highlights = getHighlights({ company, activelyHiring });

  if (highlights.length === 0) {
    return null;
  }

  return (
    <HighlightWrapper>
      {highlights.map(highlight => (
        <Badge
          key={highlight.dataTest}
          badgeText={highlight.title}
          icon={highlight.icon}
          hoverText={highlight.subtitle}
        />
      ))}
    </HighlightWrapper>
  );
}
