import { Text } from "@otta/design";
import { Job } from "@otta/search/schema";

interface JobSubtitleProps {
  job: Pick<Job.Fragment, "subtitle">;
}

export function JobSubtitle({
  job,
}: JobSubtitleProps): React.ReactElement | null {
  if (!job.subtitle) {
    return null;
  }

  return (
    <Text bold as="h2" data-testid="job-subtitle">
      {job.subtitle}
    </Text>
  );
}
