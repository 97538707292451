import { palette } from "@otta/design-tokens";

const COLORS = {
  good: palette.extended.green.shade400,
  medium: palette.extended.orange.shade400,
  bad: palette.brand.red,
  neutral: palette.brand.grey,
};

export interface ICircularProgressProps {
  quadrants?: 0 | 1 | 2 | 3 | 4;
  className?: string;
  style?: React.CSSProperties;
  strokeWidth?: number;
}

/**
 * Circular Progress can be used to demonstrate a state of empty, 1/4, 1/2, 3/4 and full by passing in the quadrant the circle should be filled, too.
 *
 * You can control the size of the circle by passing in a strokeWidth prop and the width / height by placing it in a div with a fixed width. For the regular-sized circle, there is no need to pass in a strokeWidth-- as it is done so by default. For the smaller one pictured here, pass in 3.5.
 *
 * ```tsx
 *
 * import { CircularProgress } from '@otta/design'
 *
 * ```
 *
 *
 */

export const CircularProgress = ({
  quadrants = 0,
  strokeWidth = 10,
  className,
  style,
}: ICircularProgressProps) => {
  const calculatedStrokeWidth = strokeWidth / 50;

  return (
    <svg
      viewBox="-0.22 -1.27 2.5 2.5"
      preserveAspectRatio="xMidYMin meet"
      className={className}
      style={style}
    >
      <path
        d="m2.06-.06a1 1 0 00-1-1"
        fill="none"
        stroke={
          quadrants >= 3
            ? COLORS.good
            : quadrants == 2
            ? COLORS.medium
            : quadrants == 1
            ? COLORS.bad
            : COLORS.neutral
        }
        strokeWidth={calculatedStrokeWidth}
      />
      <path
        d="m1.06 1a1 1 0 001-1"
        fill="none"
        stroke={
          quadrants >= 3
            ? COLORS.good
            : quadrants == 2
            ? COLORS.medium
            : COLORS.neutral
        }
        strokeWidth={calculatedStrokeWidth}
      />
      <path
        d="m0 0a1 1 0 001 1"
        fill="none"
        stroke={quadrants >= 3 ? COLORS.good : COLORS.neutral}
        strokeWidth={calculatedStrokeWidth}
      />
      <path
        d="m1-1.06a1 1 0 00-1 1"
        fill="none"
        stroke={quadrants >= 4 ? COLORS.good : COLORS.neutral}
        strokeWidth={calculatedStrokeWidth}
      />
    </svg>
  );
};
