import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { CurrentUserDocument } from "@otta/search/schema";
import { Redirect } from "@otta/search/router";

interface LoggedInRouteProps {
  children: React.ReactNode;
}

export function LoggedInRoute({
  children,
}: LoggedInRouteProps): React.ReactElement {
  const location = useLocation();
  const { data, loading } = useQuery(CurrentUserDocument, { ssr: true });

  if (loading) {
    return <Loading />;
  }
  const user = data?.currentUser;

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          search:
            location.pathname === "/"
              ? ""
              : queryString.stringify({
                  redirect: location.pathname,
                }),
        }}
      />
    );
  }

  return <>{children}</>;
}
