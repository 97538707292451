import { useCallback, useEffect, useState } from "react";
import styled from "@xstyled/styled-components";
import queryString from "query-string";

import { SignInWithProviderProps } from "./types";

import { Button as DesignButton } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";

const Button = styled(DesignButton)`
  display: flex;
  align-items: center;
`;

export function AppleSignIn({
  action = "Sign in",
  successPath = "/",
  errorPath,
  utmSource,
  gclid,
}: SignInWithProviderProps): React.ReactElement {
  const [successUrl, setSuccessUrl] = useState<string>();
  const [errorUrl, setErrorUrl] = useState<string>();

  useEffect(() => {
    const url = new URL(window.location.href);

    url.pathname = "/auth/oauth2/apple/callback";

    url.searchParams.set("redirect", successPath);

    setSuccessUrl(url.toString());
  }, [successPath]);

  useEffect(() => {
    const url = new URL(window.location.href);

    if (errorPath) {
      url.pathname = errorPath;
    }

    setErrorUrl(url.toString());
  }, [errorPath]);

  const urlParams = {
    success_uri: successUrl,
    error_uri: errorUrl,
    utm_source: utmSource,
    gclid: gclid,
  };

  const href = `${
    import.meta.env.VITE_API_HOST
  }/auth/oauth2/apple/authorize?${queryString.stringify(urlParams)}`;

  const onClick = useCallback(() => {
    pushAnalyticsEvent({ eventName: `Candidate Clicked ${action} with Apple` });
  }, [action]);

  return (
    <Button as="a" level="secondary" href={href} onClick={onClick}>
      <svg
        viewBox="0 0 842.32 1000"
        height={modularScale(2)}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        style={{ marginRight: 8 }}
      >
        <path
          fill="#000"
          d="M824.666 779.304c-15.123 34.937-33.023 67.096-53.763 96.663-28.271 40.308-51.419 68.208-69.258 83.702-27.653 25.43-57.282 38.455-89.01 39.196-22.776 0-50.245-6.481-82.219-19.63-32.08-13.085-61.56-19.566-88.516-19.566-28.27 0-58.59 6.48-91.022 19.567-32.48 13.148-58.646 20-78.652 20.678-30.425 1.296-60.75-12.098-91.022-40.245-19.32-16.852-43.486-45.74-72.436-86.665-31.06-43.702-56.597-94.38-76.602-152.155C10.74 658.443 0 598.013 0 539.509c0-67.017 14.481-124.818 43.486-173.255 22.796-38.906 53.122-69.596 91.078-92.126 37.955-22.53 78.967-34.012 123.132-34.746 24.166 0 55.856 7.475 95.238 22.166 39.27 14.74 64.485 22.215 75.54 22.215 8.266 0 36.277-8.74 83.764-26.166 44.906-16.16 82.806-22.85 113.854-20.215 84.133 6.79 147.341 39.955 189.377 99.707-75.245 45.59-112.466 109.447-111.725 191.364.68 63.807 23.827 116.904 69.319 159.063 20.617 19.568 43.64 34.69 69.257 45.431-5.555 16.11-11.42 31.542-17.654 46.357zM631.71 20.006c0 50.011-18.27 96.707-54.69 139.928-43.949 51.38-97.108 81.071-154.754 76.386-.735-6-1.16-12.314-1.16-18.95 0-48.01 20.9-99.392 58.016-141.403 18.53-21.271 42.098-38.957 70.677-53.066C578.316 9.002 605.29 1.316 630.66 0c.74 6.686 1.05 13.372 1.05 20.005z"
        />
      </svg>
      Continue with Apple
    </Button>
  );
}
