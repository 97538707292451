import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { CurrentUserDocument, RoleType } from "@otta/search/schema";

interface PublicRouteProps {
  publicElement: React.ReactElement;
  privateElement: React.ReactElement;
}

export function PublicRoute({
  publicElement,
  privateElement,
}: PublicRouteProps): React.ReactElement | null {
  const { data, loading } = useQuery(CurrentUserDocument, { ssr: true });

  if (loading) {
    return <Loading />;
  }

  const user = data?.currentUser;

  if (user && user.role !== RoleType.CompanyRecruiter) {
    return privateElement;
  }

  return publicElement;
}
