export function Office(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      {...props}
    >
      <path
        d="M2.33333 2.53125C2.33333 2.35859 2.4825 2.21875 2.66667 2.21875H3.66667C3.85 2.21875 4 2.35859 4 2.53125V3.46875C4 3.64062 3.85 3.78125 3.66667 3.78125H2.66667C2.4825 3.78125 2.33333 3.64062 2.33333 3.46875V2.53125ZM6.33333 2.21875C6.51667 2.21875 6.66667 2.35859 6.66667 2.53125V3.46875C6.66667 3.64062 6.51667 3.78125 6.33333 3.78125H5.33333C5.15 3.78125 5 3.64062 5 3.46875V2.53125C5 2.35859 5.15 2.21875 5.33333 2.21875H6.33333ZM2.33333 5.03125C2.33333 4.85938 2.4825 4.71875 2.66667 4.71875H3.66667C3.85 4.71875 4 4.85938 4 5.03125V5.96875C4 6.14062 3.85 6.28125 3.66667 6.28125H2.66667C2.4825 6.28125 2.33333 6.14062 2.33333 5.96875V5.03125ZM6.33333 4.71875C6.51667 4.71875 6.66667 4.85938 6.66667 5.03125V5.96875C6.66667 6.14062 6.51667 6.28125 6.33333 6.28125H5.33333C5.15 6.28125 5 6.14062 5 5.96875V5.03125C5 4.85938 5.15 4.71875 5.33333 4.71875H6.33333ZM0.5 1.75C0.5 1.05957 1.09688 0.5 1.83333 0.5H7.16667C7.90208 0.5 8.5 1.05957 8.5 1.75V9.25C8.5 9.93945 7.90208 10.5 7.16667 10.5H1.83333C1.09688 10.5 0.5 9.93945 0.5 9.25V1.75ZM1.5 1.75V9.25C1.5 9.42188 1.64917 9.5625 1.83333 9.5625H3.5V8.3125C3.5 7.79492 3.94792 7.375 4.5 7.375C5.05208 7.375 5.5 7.79492 5.5 8.3125V9.5625H7.16667C7.35 9.5625 7.5 9.42188 7.5 9.25V1.75C7.5 1.57734 7.35 1.4375 7.16667 1.4375H1.83333C1.64917 1.4375 1.5 1.57734 1.5 1.75Z"
        fill="black"
      />
    </svg>
  );
}
