import { useMemo, useState, useRef } from "react";
import styled from "@xstyled/styled-components";
import { useLocation } from "react-router-dom";

import { FormCard } from "./FormCard";
import { CVUpload } from "./CVUpload";

import { Spacing, Middle, Button, Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { pxToRem } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import {
  UserWorkExperienceInput,
  WorkExperiencesDocument,
  WorkExperiencesQuery,
} from "@otta/search/schema";
import { SectionHeader } from "@otta/search/pages/Profile/components/FormHeader";
import { CompletionStatusTag } from "@otta/search/pages/Profile/components/CompletionStatusTags";
import { LoadingCard } from "@otta/search/pages/Profile/components/LoadingCard";
import { Plus } from "@otta/search/components/Icons/Plus";
import { CircleAddButton } from "@otta/search/pages/Profile/components/FormCardList";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const AddMoreButton = styled(Button)`
  height: ${pxToRem(35)};
`;

const PlusIcon = styled(Plus)`
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
  padding-right: sm;
`;

const GreyText = styled(Text)`
  color: gray-600;
`;

export interface WorkExperienceItem {
  companyDescription: string | null;
  companyLogoPath: string | null;
  companyName: string;
  companyUrl: string | null;
  descriptions: {
    id: string;
    value: string;
  }[];
  hasProcessingDescriptions: boolean;
  endDate: string | null;
  id: string;
  sectorTags: {
    id: string;
    value: string;
  }[];
  startDate: string;
  technologiesUsed: {
    id: string;
    value: string;
  }[];
  title: string;
}

interface IFormCardProps {
  title: string;
  guidance: string;
  creatingId: null | string;
  onCreate: (values: UserWorkExperienceInput) => void;
  onUpdate: (id: string) => (input: UserWorkExperienceInput) => void;
  onFinish: () => void;
  onDelete: (id: string) => () => void;
  formatCompletionStatus?: (data: WorkExperiencesQuery) => string;
  showTooltip?: boolean | undefined;
  tooltipContent?: string | undefined;
}

export function FormCardList({
  title,
  guidance,
  creatingId,
  onCreate,
  onUpdate,
  onFinish,
  onDelete,
  formatCompletionStatus,
  showTooltip,
  tooltipContent,
}: IFormCardProps): React.ReactElement {
  const [creating, setCreating] = useState(false);
  const { data } = useQuery(WorkExperiencesDocument);
  const topOfFormCardRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const cvRedirect = location.pathname.includes("apply")
    ? location.pathname
    : null;

  const items = useMemo(() => {
    return (data?.currentUser?.workExperiences ?? []).filter(
      item => item.id !== creatingId
    );
  }, [creatingId, data?.currentUser?.workExperiences]);

  const creatingItem = useMemo(() => {
    return (data?.currentUser?.workExperiences ?? []).find(
      item => item.id === creatingId
    );
  }, [creatingId, data?.currentUser?.workExperiences]);

  const empty = items.length === 0;

  if (!data) {
    return (
      <Spacing>
        <HeaderWrapper>
          <SectionHeader data-cs-mask>{title}</SectionHeader>
        </HeaderWrapper>
        <LoadingCard />
      </Spacing>
    );
  }

  const toggleCreatingState = () => setCreating(prevState => !prevState);

  const handleAddMoreClick = () => {
    setCreating(true);
    // Scroll into view if necessary
    if (
      topOfFormCardRef.current &&
      // Check header is in the viewport
      topOfFormCardRef.current.getBoundingClientRect().y < 0
    ) {
      topOfFormCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Spacing>
      <Spacing size={-10}>
        <HeaderWrapper ref={topOfFormCardRef}>
          <TitleWrapper>
            <SectionHeader>{title}</SectionHeader>
            {!!formatCompletionStatus && (
              <CompletionStatusTag
                status={formatCompletionStatus(data)}
                showTooltip={showTooltip}
                tooltipContent={tooltipContent}
              />
            )}
          </TitleWrapper>
          <CircleAddButton
            data-testid="add-button"
            onClick={toggleCreatingState}
            hide={creating}
          >
            <Icon icon="plus" />
          </CircleAddButton>
        </HeaderWrapper>
        <GreyText>{guidance}</GreyText>
      </Spacing>
      {creating &&
        (creatingId && creatingItem ? (
          <FormCard
            data={creatingItem}
            onSubmit={onUpdate(creatingItem.id)}
            onFinish={() => {
              setCreating(false);
              onFinish();
            }}
            onCancel={() => {
              setCreating(false);
              onFinish();
            }}
          />
        ) : (
          <FormCard
            data={null}
            onSubmit={input => onCreate(input)}
            onFinish={() => {
              setCreating(false);
              onFinish();
            }}
            onCancel={() => {
              setCreating(false);
              onFinish();
            }}
          />
        ))}
      {empty && !creating ? (
        <CVUpload redirect={cvRedirect} onEdit={toggleCreatingState} />
      ) : (
        items.map((item, index) => (
          <FormCard
            key={item.id}
            index={index}
            data={item}
            onSubmit={onUpdate(item.id)}
            onDelete={onDelete(item.id)}
            onFinish={onFinish}
            onCancel={() => {
              setCreating(false);
              onFinish();
            }}
          />
        ))
      )}
      {!empty && (
        <Middle>
          <AddMoreButton level="secondary" onClick={handleAddMoreClick}>
            <PlusIcon />
            Add more work experiences
          </AddMoreButton>
        </Middle>
      )}
    </Spacing>
  );
}
