import styled from "@xstyled/styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Card, Middle, Spacing, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { WorkExperiencesDocument } from "@otta/search/schema";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useRedirectParam } from "@otta/search/utils/routing/useRedirectParam";

const Container = styled.div`
  padding: 2rem;
`;

export function ProfileImport() {
  const redirect = useRedirectParam();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { data } = useQuery(WorkExperiencesDocument, {
    pollInterval: 30000,
  });

  useEffect(() => {
    if (data?.currentUser?.workExperiences.length) {
      if (!redirect) {
        navigate("/profile", { replace: true, state: { imported: true } });
      } else {
        navigate(redirect, { replace: true, state: { imported: true } });
      }

      pushAnalyticsEvent({ eventName: "Candidate imported CV" });
    }
  }, [data?.currentUser?.workExperiences.length, navigate, redirect]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!redirect) {
        navigate("/profile", { replace: true });
      } else {
        navigate(redirect, { replace: true });
      }

      pushAnalyticsEvent({ eventName: "Candidate imported CV timed out" });

      toast.error(
        `${t(
          "Something went wrong importing your CV"
        )}. Try again later or create your profile from scratch`
      );
    }, 1000 * 60 * 5);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate, redirect, t]);

  return (
    <Container>
      <Middle maxWidth={560} textAlign="left">
        <Card>
          <Loading />
          <Spacing size={2}>
            <Text size={1} bold align="center">
              {t("Importing your CV")}...
            </Text>

            <Spacing>
              <Text>
                This can take around a minute, but sometimes takes longer.
              </Text>
              <Text>
                We&apos;ll automatically refresh the page when everything has
                been imported. You don&apos;t need to refresh the page.
              </Text>
            </Spacing>
          </Spacing>
        </Card>
      </Middle>
    </Container>
  );
}
