import styled, { css, down, up } from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";

const ARROW_HEIGHT = 50;

export const NavContainer = styled.div<{
  align: "left" | "right";
}>(
  ({ align }) => css`
    position: fixed;
    top: 50%;
    z-index: 2;

    @media (max-width: 1400px) {
      ${align === "left"
        ? css`
            left: 0;
            transform: translate(100px, -${ARROW_HEIGHT / 2}px);
          `
        : css`
            right: 0;
            transform: translate(-100px, -${ARROW_HEIGHT / 2}px);
          `}
    }

    @media (min-width: 1400px) {
      ${align === "left"
        ? css`
            left: 50%;
            transform: translate(-580px, -${ARROW_HEIGHT / 2}px);
          `
        : css`
            right: 50%;
            transform: translate(585px, -${ARROW_HEIGHT / 2}px);
          `}
    }
  `
);

export const NavButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${pxToRem(ARROW_HEIGHT)};
  ${up(
    "tablet",
    css`
      min-width: ${pxToRem(ARROW_HEIGHT)};
    `
  )}

  border: none;
  border-radius: 50%;
  outline: none;

  background-color: ${palette.brand.white};
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0px 0px 20px 0px #0000001d;

  cursor: pointer;
`;

export const BottomNavContainer = styled.div`
  background: ${palette.brand.white};
  bottom: 0;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.05));
  left: 0;
  padding: md;
  position: fixed;
  width: 100vw;
  z-index: 2;

  > div {
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    gap: lg;
    justify-content: center;

    button {
      ${down(
        "tablet",
        css`
          flex-basis: 0;
          flex-grow: 1;
          max-width: ${pxToRem(240)};
          width: 100% !important;
        `
      )}

      ${up(
        "tablet",
        css`
          width: ${pxToRem(240)} !important;
        `
      )}

      box-shadow: none;
      justify-content: center;

      > div {
        left: unset;
        padding-left: 0;
        position: relative;
        width: unset;
      }
    }

    > div {
      ${down(
        "tablet",
        css`
          flex-basis: 0;
          flex-grow: 1;
          max-width: ${pxToRem(240)};
        `
      )}
      margin: 0;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const HintContainer = styled(Row)`
  width: 100%;
  margin-top: sm;
`;

export const GreyText = styled(Text)`
  color: ${palette.grayscale.shade600};
`;

export const Key = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${palette.grayscale.shade200};

  border: 2px solid;
  border-color: ${palette.grayscale.shade400};
  border-radius: ${pxToRem(5)};

  box-shadow: inset -1px -1px 0rem 0.13rem rgb(0 0 0 / 5%);

  width: ${pxToRem(20)};
  height: ${pxToRem(20)};

  margin-right: sm;
`;
