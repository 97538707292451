import { useMemo } from "react";

import { hiringRegions } from "./utils";
import { BulletPointSection } from "./BulletPointSection";

import { Company, Job } from "@otta/search/schema";

function regionAvailabilityText(region: string): string {
  switch (region) {
    case "CA":
      return "This benefit is only available to employees in Canada.";
    default:
      return `This benefit is only available to ${region} employees.`;
  }
}

function regionFlag(region: string): string | null {
  switch (region) {
    case "US":
      return "🇺🇸";
    case "CA":
      return "🇨🇦";
    case "UK":
      return "🇬🇧";
    case "EU":
      return "🇪🇺";
    default:
      return null;
  }
}

function getTooltip(
  benefit: Company.OtherBenefits,
  internationalHiring: boolean
) {
  if (!benefit.location || !internationalHiring) {
    return undefined;
  }

  const flag = regionFlag(benefit.location);

  if (!flag) {
    return undefined;
  }

  return {
    label: flag,
    content: regionAvailabilityText(benefit.location),
  };
}

const BENEFITS_SORT_ORDER = [null, "US", "CA", "UK", "EU"];

interface CompanyBenefitsProps {
  job: Job.Fragment;
  company: Pick<Company.Fragment, "otherBenefits">;
}

export function CompanyBenefits({
  job,
  company: { otherBenefits },
}: CompanyBenefitsProps): React.ReactElement | null {
  const regions = hiringRegions(job.locationPreferences);

  const internationalHiring = regions.size > 1;

  const bullets = useMemo(
    () =>
      otherBenefits
        .filter(b => (!b.location || regions.has(b.location)) && b.value)
        .sort(
          (a, b) =>
            BENEFITS_SORT_ORDER.indexOf(a.location) -
            BENEFITS_SORT_ORDER.indexOf(b.location)
        )
        .map(b => ({
          id: b.id,
          text: b.value as string,
          tooltip: getTooltip(b, internationalHiring),
        })),
    [internationalHiring, otherBenefits, regions]
  );

  if (bullets.length === 0) {
    return null;
  }

  return (
    <BulletPointSection
      title="Company benefits"
      bullets={bullets}
      bulletTestId="company-benefit-bullet"
    />
  );
}
