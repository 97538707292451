import { MouseEventHandler } from "react";
import styled from "@xstyled/styled-components";

import { Delete as Cancel } from "@otta/search/components/Icons/Delete";
import { ScreenReadableText } from "@otta/search/components/ScreenReadableText";
import { EditButton } from "@otta/search/pages/Profile/components/FormCard";

const DismissButtonWrap = styled.div`
  margin-top: -0.3rem;
`;

export const DismissButton = ({
  onClick,
}: {
  onClick?: MouseEventHandler;
}): React.ReactElement => {
  return (
    <DismissButtonWrap>
      <EditButton data-testid="dismiss-x-button" onClick={onClick}>
        <Cancel />
        <ScreenReadableText>Dismiss</ScreenReadableText>
      </EditButton>
    </DismissButtonWrap>
  );
};
