import { useState } from "react";
import styled from "styled-components";

import { CompanyPodcastData } from "./types";

import { Button } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";

const TranscriptContainer = styled.div<{ showTranscript: boolean }>`
  display: flex;
  background: ${({ showTranscript }) =>
    showTranscript ? palette.grayscale.shade50 : "none"};
  flex-direction: column;
  border-radius: 4;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 1rem;
`;

const TranscriptButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TranscriptTextContainer = styled.div`
  max-height: ${pxToRem(269)};
  overflow: auto;
`;

export function Transcript({
  transcript,
}: Pick<CompanyPodcastData, "transcript">) {
  const [showTranscript, setShowTranscript] = useState(false);

  return (
    <TranscriptContainer showTranscript={showTranscript}>
      <TranscriptButtonContainer>
        <Button
          type="button"
          level="secondary"
          data-analytics-id={`${showTranscript ? "Hide" : "Show"} transcript`}
          onClick={() => setShowTranscript(!showTranscript)}
          size="small"
        >
          {showTranscript ? "Hide" : "View"} transcript
        </Button>
      </TranscriptButtonContainer>

      <TranscriptTextContainer>
        {showTranscript && transcript}
      </TranscriptTextContainer>
    </TranscriptContainer>
  );
}
