import { IDashboardBannerNotification } from "..";

import { Text } from "@otta/design";
import { Notification } from "@otta/search/schema";
import { Link } from "@otta/search/components/Link";

const CompleteYourProfileContent: React.FC = () => (
  <Text>
    Complete your profile and get discovered by companies.{" "}
    <Link
      to="/profile"
      underline={true}
      data-testid="link-complete-your-profile-notification"
      data-analytics-id="link-complete-your-profile-notification"
    >
      Go to profile
    </Link>
    .
  </Text>
);

export const CompleteYourProfileNotification: IDashboardBannerNotification = {
  key: "complete-your-profile-notification",
  name: Notification.CompleteProfile,
  Content: CompleteYourProfileContent,
  level: "information",
};
