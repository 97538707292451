import styled from "@xstyled/styled-components";

import { ActionButton } from "./ActionButton";

import { Text, Spacing, Tooltip } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Link } from "@otta/search/components/Link";
import {
  Company,
  Job,
  LocationPreference,
  UserJobPreferences,
  Location,
} from "@otta/search/schema";
import {
  locationName,
  locationsToString,
  REMOTE_EU_LOCATIONS,
  REMOTE_LOCATIONS,
} from "@otta/search/utils/locations";

const JobsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const JobColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const JobFunction = styled.div`
  margin-bottom: xl;
`;

const JobContainer = styled.div`
  margin-left: md;
`;

const MoreLocationsTip = styled.span`
  height: ${modularScale(2)};
  width: ${modularScale(2)};
  font-size: ${modularScale(-2)};
  border: 1px solid ${palette.grayscale.shade400};
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: xs;
  cursor: pointer;
  background-color: white;
`;

interface JobByFunction {
  [functionName: string]: Company.LiveJobs[];
}

const getJobsByFunction = (jobs: Company.LiveJobs[]): JobByFunction => {
  const map: JobByFunction = {};

  for (const job of jobs) {
    const jobFunction = job.function?.value;
    if (!jobFunction) {
      continue;
    }

    if (jobFunction in map) {
      map[jobFunction].push(job);
    } else {
      map[jobFunction] = [job];
    }
  }

  return map;
};

interface CompanyOtherJobsSectionProps {
  company: Pick<Company.Fragment, "liveJobs" | "name">;
  currentJobId: string;
  user?: Pick<UserJobPreferences.CurrentUser, "locationPreferences">;
}

export function CompanyOtherJobsSection({
  company: { liveJobs, name },
  currentJobId,
  user,
}: CompanyOtherJobsSectionProps): React.ReactElement | null {
  const otherJobs = liveJobs.filter(job => job.id !== currentJobId);

  if (otherJobs.length === 0) {
    return null;
  }

  const userLocationPreferences = user?.locationPreferences ?? [];

  const jobsByFunction = Object.entries(getJobsByFunction(otherJobs)).sort(
    ([, jobsA], [, jobsB]) => {
      return jobsB.length - jobsA.length;
    }
  );

  return (
    <Spacing size={2.22}>
      <Text bold size={1}>
        More jobs at {name}
      </Text>
      <JobsContainer>
        <JobColumn>
          {jobsByFunction.map(([functionName, jobs]) => (
            <JobFunction key={functionName} data-testid="company-job-function">
              <Spacing>
                <Text>{functionName}</Text>
                {jobs
                  .sort((a, b) => (a.title > b.title ? 1 : -1))
                  .map(job => (
                    <CompanyJob
                      key={job.id}
                      job={job}
                      userLocationPreferences={userLocationPreferences}
                    />
                  ))}
              </Spacing>
            </JobFunction>
          ))}
        </JobColumn>
      </JobsContainer>
    </Spacing>
  );
}

interface CompanyJobProps {
  job: Pick<
    Job.Fragment,
    "title" | "externalId" | "title" | "subtitle" | "locationPreferences"
  >;
  userLocationPreferences: LocationPreference[];
}

function CompanyJob({ job, userLocationPreferences }: CompanyJobProps) {
  return (
    <JobContainer data-testid="company-job">
      <Spacing size={-4}>
        <Link to={`/jobs/${job.externalId}`} target="_blank">
          {job.title}
          {job.subtitle && <Text as="span"> - {job.subtitle}</Text>}
        </Link>
        <JobLocations
          jobLocationPreferences={job.locationPreferences}
          userPreferences={userLocationPreferences}
        />
      </Spacing>
    </JobContainer>
  );
}

function jobLocationsText(
  locationPreferences: LocationPreference[],
  userPreferences: LocationPreference[]
) {
  const locations = locationPreferences
    .map(lp => lp.location)
    .sort(a => (REMOTE_LOCATIONS.includes(a) ? 1 : -1));

  const matchedLocations = locations
    .filter(
      l => userPreferences.some(ulp => ulp.location === l) && !!locationName(l)
    )
    .slice(0, 2);

  const matchedLocationsText = locationsToString(matchedLocations);
  const allLocationsText = locationsToString(locations);

  const restLocationsLength =
    (locations.some(l => l === Location.RemoteEurope)
      ? locationPreferences.filter(
          l => !REMOTE_EU_LOCATIONS.includes(l.location)
        )
      : locations
    ).length - matchedLocations.length;

  return {
    matchedLocationsText,
    allLocationsText,
    restLocationsLength,
  };
}

interface JobLocationsProps {
  jobLocationPreferences: LocationPreference[];
  userPreferences: LocationPreference[];
}

function JobLocations({
  jobLocationPreferences,
  userPreferences,
}: JobLocationsProps): React.ReactElement {
  const { matchedLocationsText, allLocationsText, restLocationsLength } =
    jobLocationsText(jobLocationPreferences, userPreferences);

  return matchedLocationsText ? (
    <Text size={-1}>
      {matchedLocationsText}
      {restLocationsLength > 0 && (
        <Tooltip content={`You can do this job in ${allLocationsText}`}>
          <MoreLocationsTip data-testid="more-locations-tip">
            +{restLocationsLength}
          </MoreLocationsTip>
        </Tooltip>
      )}
    </Text>
  ) : (
    <Text size={-1}>{allLocationsText}</Text>
  );
}

interface CompanyOtherJobsButtonProps {
  company: Pick<Company.Fragment, "liveJobs" | "name">;
  currentJobId: string;
  user?: Pick<UserJobPreferences.CurrentUser, "locationPreferences">;
  expanded: boolean;
  expand?: () => void;
}

export function CompanyOtherJobsButton({
  company: { liveJobs, name },
  currentJobId,
  expanded,
  expand,
}: CompanyOtherJobsButtonProps): React.ReactElement | null {
  const otherJobs = liveJobs.filter(job => job.id !== currentJobId);

  if (otherJobs.length === 0 || expanded || !expand) {
    return null;
  }

  return (
    <ActionButton
      icon={<Icon icon="visible-on" />}
      title={`View ${otherJobs.length} more job${
        otherJobs.length === 1 ? "" : "s"
      } at ${name}`}
      onClick={expand}
      data-data-testid="job-card-view-company-jobs-button"
    />
  );
}
