import { IDashboardBannerNotification } from "..";

import { Text } from "@otta/design";
import { Notification } from "@otta/search/schema";
import { HiddenEye } from "@otta/search/components/Icons/HiddenEye";
import { Link } from "@otta/search/components/Link";

const HiddenProfileContent: React.FC = () => (
  <>
    <HiddenEye />
    <Text>
      You can&apos;t be headhunted because you&apos;re hidden.{" "}
      <Link
        to="/messaging"
        underline={true}
        data-testid="link-hidden-profile-notification"
        data-analytics-id="link-hidden-profile-notification"
      >
        Reply to your messages
      </Link>{" "}
      to become visible again
    </Text>
  </>
);

export const HiddenProfileNotification: IDashboardBannerNotification = {
  key: "hidden-profile-notification",
  name: Notification.HiddenProfile,
  Content: HiddenProfileContent,
  level: "warning",
};
