import styled from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";

interface IProgressBarProps {
  progress: number;
  size?: number;
}

export const ProgressBar = styled.div<IProgressBarProps>`
  width: 100%;
  height: ${({ size }) => (size ? modularScale(size) : modularScale())};
  background-color: white;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${({ progress }) => progress}%;
    background-color: green-400;
    transition: default;
  }
`;
