import styled from "@xstyled/styled-components";

import { Dribbble } from "@otta/search/components/Icons/Dribbble";
import { GitHub } from "@otta/search/components/Icons/GitHub";
import { Link as LinkIcon } from "@otta/search/components/Icons/Link";
import { LinkedIn } from "@otta/search/components/Icons/LinkedIn";
import { Link } from "@otta/search/components/Link";

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2px 0;
`;

const IconWrapper = styled.div`
  svg {
    width: 16px;
    max-height: 16px;
    display: block;
  }
  width: 16px;
  max-height: 16px;
  margin-right: 8px;
  vertical-align: middle;
`;

function Icon({ url }: { url: string }): React.ReactElement {
  const formattedUrl = url.toLowerCase();
  if (formattedUrl.includes("github")) {
    return <GitHub data-testid="github-icon" />;
  } else if (formattedUrl.includes("dribbble")) {
    return <Dribbble data-testid="dribbble-icon" />;
  } else if (formattedUrl.includes("linkedin")) {
    return <LinkedIn data-testid="linkedin-icon" />;
  } else {
    return <LinkIcon data-testid="external-website-icon" />;
  }
}

interface WebsiteIconProps {
  url: string;
  linkIcon?: boolean;
}

export function WebsiteIcon({
  url,
  linkIcon = false,
}: WebsiteIconProps): React.ReactElement {
  return (
    <Wrapper>
      {linkIcon ? (
        <Link to={url} targetBlank onClick={e => e.stopPropagation()}>
          <IconWrapper>
            <Icon url={url} />
          </IconWrapper>
        </Link>
      ) : (
        <IconWrapper>
          <Icon url={url} />
        </IconWrapper>
      )}
    </Wrapper>
  );
}
