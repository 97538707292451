import { ApolloError } from "@apollo/client";
import { toast } from "react-toastify";

const genericMessage = "Something went wrong. Try again.";

export const handleMutationError = (error: ApolloError): void => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const errorMessage = error.graphQLErrors
      .map(({ message }) => message)
      .join(". ");
    toast.error(errorMessage ? errorMessage : genericMessage);
  } else {
    toast.error(genericMessage);
  }
};
