import { Heading, Overline, Spacing } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { ImageBubble } from "@otta/search/components/ImageBubble";
import { CompaniesDocument } from "@otta/search/schema";

export function SalaryHero(): React.ReactElement {
  const { data } = useQuery(CompaniesDocument, {
    variables: {
      urlSafeNames: ["Monzo", "Deliveroo", "Airbnb", "Netflix", "Spotify"],
    },
  });

  const companies = data?.companies ?? [];

  return (
    <Spacing>
      <Heading align="center" size={4}>
        Explore salaries at the world&apos;s most innovative companies
      </Heading>
      <Overline align="center" size={-2}>
        Verified data from companies like
      </Overline>
      <ImageBubble
        small
        images={companies
          .map(({ faviconPath }) => faviconPath)
          .filter((path): path is string => !!path)}
      />
    </Spacing>
  );
}
