import _Circular from "@fseehawer/react-circular-slider";

import { palette } from "@otta/design-tokens";

const Circular =
  (_Circular as { default?: typeof _Circular }).default ?? _Circular;

export function CircularSlider(props: {
  label: string;
  data: string[];
  width: number;
  dataIndex: number;
  onChange: (x: string) => void;
  prependToValue: string;
  appendToValue: string;
}): React.ReactElement {
  return (
    <Circular
      {...props}
      labelColor={palette.brand.black}
      knobColor={palette.extended.yellow.shade200}
      progressColorFrom={palette.brand.yellow}
      progressColorTo={palette.brand.yellow}
      progressSize={24}
      trackColor={palette.beige.shade400}
      trackSize={24}
    />
  );
}
