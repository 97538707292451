import { useState } from "react";

import { ExperienceItem } from "../../components/ExperienceItem";
import { SmallExperienceItem } from "../../components/SmallExperienceItem";
import {
  ExpansionArrow,
  ExperienceWrapper,
  HeaderWrapper,
  ShowMore,
  SmallExperienceWrapper,
} from "../../components/Section";

import { Card, List, Spacing, Text } from "@otta/design";
import { LatestJobApplication } from "@otta/search/schema";

const ExpandedView = ({
  experiences,
}: {
  experiences: LatestJobApplication.EducationExperiences[];
}) => (
  <ShowMore show={3}>
    {experiences.map(experience => (
      <ExperienceWrapper key={experience.id}>
        <Spacing size={-4}>
          <ExperienceItem
            orgName={experience.institutionName}
            companyUrl={undefined}
            title={experience.fieldOfStudy}
            startDate={experience.startDate}
            endDate={experience.endDate}
            showDuration
            showMonth
          />
          {experience.descriptions?.length > 0 && (
            <List type="bullet" size={-1}>
              {experience.descriptions.map(({ id, value }) => (
                <li data-cs-mask key={id}>
                  {value}
                </li>
              ))}
            </List>
          )}
        </Spacing>
      </ExperienceWrapper>
    ))}
  </ShowMore>
);

const CollapsedView = ({
  experiences,
}: {
  experiences: LatestJobApplication.EducationExperiences[];
}) => (
  <ShowMore show={3}>
    {experiences.map(experience => (
      <SmallExperienceWrapper key={experience.id}>
        <SmallExperienceItem
          data-cs-mask
          orgName={experience.institutionName}
          companyUrl={undefined}
          title={experience.fieldOfStudy}
          startDate={experience.startDate}
          endDate={experience.endDate}
        />
      </SmallExperienceWrapper>
    ))}
  </ShowMore>
);

export function EducationExperiences({
  candidate,
}: {
  candidate: LatestJobApplication.Candidate;
}): React.ReactElement | null {
  const [expand, setExpand] = useState(false);

  if (
    !candidate.educationExperiences ||
    candidate.educationExperiences.length <= 0
  ) {
    return null;
  }

  return (
    <Card data-testid="education-experiences-card">
      <Spacing size={1}>
        <HeaderWrapper>
          <Text size={1} align="left" bold>
            Education
          </Text>
          <ExpansionArrow expand={expand} setExpand={setExpand} />
        </HeaderWrapper>
        {expand ? (
          <ExpandedView experiences={candidate.educationExperiences} />
        ) : (
          <CollapsedView experiences={candidate.educationExperiences} />
        )}
      </Spacing>
    </Card>
  );
}
