import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";
import { Fragment } from "react";

import { FormGreenhouseDemographicChoice } from "../types";

import { InputField } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import {
  composeValidators,
  maxLength,
  required,
} from "@otta/search/utils/validators";
import { GreenhouseDemographicChoiceType } from "@otta/search/schema";

const SingleSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
`;

const LabelText = styled.span`
  margin-left: 6px;
`;

interface EditDemographicSingleSelectFieldProps {
  name: string;
  choices: FormGreenhouseDemographicChoice[];
}

export function EditDemographicSingleSelectField({
  name,
  choices,
}: EditDemographicSingleSelectFieldProps): React.ReactElement {
  return (
    <SingleSelectContainer>
      {choices.map(({ label, value, type }) => {
        return (
          <Fragment key={value}>
            <Label>
              <Field<
                string | { label: string; value: string },
                HTMLInputElement
              >
                name={name}
                type="radio"
                component="input"
                format={x => (x as { label: string; value: string })?.value}
                parse={() => ({ label, value, type })}
                value={value}
              />
              <LabelText>{label}</LabelText>
            </Label>
            {type === GreenhouseDemographicChoiceType.FreeForm && (
              <Field name={name} subscription={{ value: true }}>
                {({ input: { value } }) => {
                  return value.type ===
                    GreenhouseDemographicChoiceType.FreeForm ? (
                    <Field
                      name={`${name}.label`}
                      validate={composeValidators(required, maxLength(128))}
                      render={({ input, meta }) => {
                        return (
                          <InputField
                            {...input}
                            type="text"
                            placeholder={`Type your answer here...`}
                            error={meta.touched && meta.error}
                            style={{ marginBottom: modularScale(1) }}
                          />
                        );
                      }}
                    />
                  ) : null;
                }}
              </Field>
            )}
          </Fragment>
        );
      })}
    </SingleSelectContainer>
  );
}
