import { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";
import { HTMLMotionProps, MotionStyle, motion } from "framer-motion";

import { AssetSprinkler } from "../AssetSprinkler";

import { modularScale, pxToRem } from "@otta/design-tokens";
import { Button, Heading, Text } from "@otta/design";
import {
  CompanyBrandAsset,
  CompanyProfileLinkFragment,
  CompanyTeamMemberFragment,
  JobCompanyTeamMemberFragment,
} from "@otta/search/schema";
import { useHasEBContent } from "@otta/search/hooks/useHasEBContent";
import { useEBTracking } from "@otta/search/contexts/EBTracking";
import { pushAnalyticsEvent } from "@otta/analytics";

const Wrapper = styled.div`
  display: grid;
  row-gap: 0.5rem;
  grid-auto-flow: column;
  grid-template-areas: "heading" "card";
`;

const HeadingText = styled(Text).attrs(a => ({ ...a, bold: true }))`
  grid-area: heading;
`;

const CardWrapper = styled(motion.div)`
  display: flex;
  gap: lg;
  padding: lg;
  border-radius: 4;
  background-color: white;
  box-shadow: 0 ${pxToRem(4)} ${pxToRem(20)} 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  grid-area: card;
`;

const StyledSprinkler = styled(AssetSprinkler)`
  flex-wrap: wrap-reverse;
  flex-grow: 1;

  & [data-desktop-width="ONE_THIRD"] {
    ${up(
      "tablet",
      css`
        max-width: ${pxToRem(144)};
      `
    )}
  }
`;

const SmallishButton = styled(Button)`
  font-size: ${modularScale(-1)};
  min-height: ${pxToRem(32)};
  padding: 0 1rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: lg;
  height: 100%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: sm;
`;

type CardProps = {
  question: string;
  answer: string;
  image?: Omit<CompanyBrandAsset, "sourceSet" | "links">;
};

const MeetCard: React.FunctionComponent<
  {
    card: CardProps;
    name?: string | null;
    onFirstCard?: boolean;
    hasMultipleCards: boolean;
    images: CompanyProfileLinkFragment[];
    onClickNext: () => void;
  } & HTMLMotionProps<"div">
> = ({
  card,
  name,
  onFirstCard,
  hasMultipleCards,
  onClickNext,
  images,
  ...props
}) => {
  return (
    <CardWrapper {...props}>
      <StyledSprinkler assets={images}>
        <Content>
          <Info>
            {name && onFirstCard && <Heading size={3}>Hi, I'm {name}</Heading>}
            <Text size={-1} bold>
              {card.question}
            </Text>
            <Text size={-1}>{card.answer}</Text>
          </Info>
          {hasMultipleCards && (
            <SmallishButton level="secondary" onClick={onClickNext}>
              {name && onFirstCard ? `More about ${name}` : "Next"}
            </SmallishButton>
          )}
        </Content>
      </StyledSprinkler>
    </CardWrapper>
  );
};

// we make cards smaller the further they are from the current one
function transform(zIndex: number, lastIndex: number): MotionStyle {
  const scale = 1 - (lastIndex - zIndex) * 0.05;

  return {
    zIndex,
    marginTop: `${zIndex * 15}px`,
    marginBottom: `${(lastIndex - zIndex) * 15}px`,
    transform: `scale(${scale})`,
  };
}

type JobOrCompanyTeamMember =
  | JobCompanyTeamMemberFragment
  | CompanyTeamMemberFragment;

function useMemberInfo(
  member: JobOrCompanyTeamMember
): [
  string | null,
  NonNullable<CompanyTeamMemberFragment["prompts"]>,
  CompanyProfileLinkFragment[]
] {
  return useMemo(() => {
    switch (member.__typename) {
      case "CompanyTeamMember":
        return [member.name, member.prompts ?? [], member.promptLinks];
      case "JobCompanyTeamMember":
        return [
          member.member.name,
          member.member.prompts ?? [],
          member.member.promptLinks,
        ];
    }
  }, [member]);
}

export function MeetCards({
  member,
}: {
  member: JobOrCompanyTeamMember;
}): React.ReactElement | null {
  const hasEBContent = useHasEBContent();
  const [name, cards, assets] = useMemberInfo(member);
  const [indices, setIndices] = useState<number[]>([]);
  const lastIndex = cards.length - 1;

  const tracking = useEBTracking();

  // indices is a rotating list of z-indexes basically
  // so the position of the highest one is our current card
  const currentPrompt = indices.indexOf(lastIndex) + 1;

  const formattedTrackingData = useMemo(
    () => ({
      companyId: tracking.companyId,
      jobFunction: tracking.jobFunction,
      jobSubFunction: tracking.jobSubFunction,
      minYearsExperienceRequired: tracking.minYearsExperienceRequired,
      maxYearsExperience: tracking.maxYearsExperienceRequired,
      loginStatus: tracking.loginStatus,
      countOfPrompts: cards.length,
      managerId: member.id,
      currentPrompt,
    }),
    [
      cards.length,
      currentPrompt,
      member.id,
      tracking.companyId,
      tracking.jobFunction,
      tracking.jobSubFunction,
      tracking.loginStatus,
      tracking.maxYearsExperienceRequired,
      tracking.minYearsExperienceRequired,
    ]
  );

  useEffect(() => {
    if (formattedTrackingData.currentPrompt > 1) {
      pushAnalyticsEvent({
        eventName: "Candidate Viewed Manager Prompt Card",
        ...formattedTrackingData,
      });
    }
  }, [formattedTrackingData]);

  useMemo(() => {
    setIndices(cards.map((c, i) => lastIndex - i));
  }, [cards, lastIndex]);

  /**
   * There is surely a nice mathematical way to obtain a rotated index
   * given the chosen card + card index but I couldn't figure it out
   */
  const handleClickNext = useCallback(() => {
    const copy = [...indices];
    copy.unshift(copy.pop() as number);
    setIndices(copy);

    if (currentPrompt === 1) {
      pushAnalyticsEvent({
        eventName: "Candidate Clicked More About Manager",
        ...formattedTrackingData,
      });
    }
  }, [currentPrompt, formattedTrackingData, indices]);

  if (!hasEBContent) {
    return null;
  }

  return indices.length > 0 ? (
    <Wrapper>
      {name && <HeadingText>Meet {name}</HeadingText>}
      {cards.map((card, i) => (
        <MeetCard
          card={card}
          key={card.id}
          name={name}
          data-testid={card.id}
          onFirstCard={i === 0}
          images={assets}
          style={transform(indices[i], lastIndex)}
          aria-hidden={indices[i] !== lastIndex}
          hasMultipleCards={lastIndex > 0}
          onClickNext={handleClickNext}
        />
      ))}
    </Wrapper>
  ) : null;
}
