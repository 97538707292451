import ReactSelect, { Props } from "react-select";

import { palette } from "@otta/design-tokens";

interface ISelectProps<
  OptionType extends { label: string; value: unknown } = {
    label: string;
    value: string;
  },
  IsMulti extends boolean = false
> extends Props<OptionType, IsMulti> {
  relativePosition?: boolean;
}

export function mapQueryResultToSelectStructure({
  id,
  value,
}: {
  id: string;
  value: string;
}): { label: string; value: string } {
  return {
    label: value,
    value: id,
  };
}

type StyleFunction<C, P> = (base: C, props: P) => C;

function composeStyles<C, P>(
  ...fns: (StyleFunction<C, P> | undefined)[]
): StyleFunction<C, P> {
  return (base, props) =>
    fns.reduce<C>((acc, fn) => (fn ? fn(acc, props) : acc), base);
}

export function Select<
  OptionType extends { label: string; value: unknown } = {
    label: string;
    value: string;
  },
  IsMulti extends boolean = false
>({
  relativePosition,
  styles,
  ...props
}: ISelectProps<OptionType, IsMulti>): React.ReactElement {
  return (
    <ReactSelect
      styles={{
        ...styles,
        menu: composeStyles(styles?.menu, base =>
          relativePosition ? { ...base, position: "relative" } : base
        ),
        multiValue: composeStyles(styles?.multiValue, base => ({
          ...base,
          borderRadius: "4px",
          border: `1px solid ${palette.grayscale.shade400}`,
        })),
        multiValueRemove: composeStyles(styles?.multiValueRemove, base => ({
          ...base,
          borderRadius: "4px",
          cursor: "pointer",
        })),
        multiValueLabel: composeStyles(styles?.multiValueLabel, base => ({
          ...base,
          background: "white",
          padding: "4px 8px",
          paddingLeft: "8px",
          borderRadius: "4px 0 0 4px",
        })),
        valueContainer: composeStyles(styles?.valueContainer, base => ({
          ...base,
          padding: "2px 3px",
        })),
      }}
      theme={originalTheme => ({
        ...originalTheme,
        borderRadius: 4,
        colors: {
          ...originalTheme.colors,
          primary: palette.brand.black,
          primary25: palette.brand.grey,
          danger: palette.brand.white,
          dangerLight: palette.grayscale.shade600,
        },
      })}
      {...props}
    />
  );
}
