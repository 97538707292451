import { SimpleValue } from "../../components/FormData";

import { Label, Spacing } from "@otta/design";
import {
  EthnicityEnum,
  UserDemographicInfoQuery,
  Gender,
} from "@otta/search/schema";
import { FinalSelect } from "@otta/search/components/Input/FinalSelect";
import {
  ethnicityOptions,
  genderOptions,
  pronounOptions,
} from "@otta/search/containers/Quiz/questions/Demographic";

const validEthnicities = (values?: { label: string; value: string }[]) =>
  (values ?? []).length > 1 &&
  (values ?? []).find(e => e.value === EthnicityEnum.PreferNotToSay)
    ? "Cannot select 'Prefer not to say' and another ethnicity"
    : undefined;

const validGenders = (values?: { label: string; value: string }[]) =>
  (values ?? []).length > 1 &&
  (values ?? []).find(e => e.value === Gender.PreferNotToSay)
    ? "Cannot select 'Prefer not to say' and another gender"
    : undefined;

export function EditingDemographic(): React.ReactElement {
  return (
    <div data-testid="edit-demographic">
      <Spacing>
        <Spacing size={-4}>
          <Label>Gender identity</Label>
          <FinalSelect
            name="genders"
            validate={validGenders}
            options={genderOptions}
            isMulti
            data-cs-mask
          />
        </Spacing>
        <Spacing size={-4}>
          <Label>Pronouns</Label>
          <FinalSelect name="pronoun" options={pronounOptions} data-cs-mask />
        </Spacing>
        <Spacing size={-4}>
          <Label>Ethnicity</Label>
          <FinalSelect
            name="ethnicities"
            validate={validEthnicities}
            options={ethnicityOptions}
            isMulti
            data-cs-mask
          />
        </Spacing>
      </Spacing>
    </div>
  );
}

export function DisplayDemographic({
  data,
}: {
  data: UserDemographicInfoQuery;
}): React.ReactElement | null {
  const user = data?.currentUser;
  if (!user) {
    return null;
  }
  const ethnicities =
    user.ethnicities.length > 0
      ? ethnicityOptions
          .filter(op =>
            user.ethnicities.some(eth => eth.ethnicity === op.value)
          )
          .map(op => op.label)
          .join(", ")
      : "N/A";

  const genders =
    user.genders.length > 0
      ? genderOptions
          .filter(op => user.genders.some(gender => gender === op.value))
          .map(op => op.label)
          .join(", ")
      : "N/A";

  return (
    <div data-testid="display-demographic">
      <SimpleValue title="Gender identity" value={genders} data-cs-mask />
      <SimpleValue
        title="Pronoun"
        value={
          user.pronoun
            ? pronounOptions.find(op => op.value === user.pronoun)?.label ??
              "N/A"
            : "N/A"
        }
        data-cs-mask
      />
      <SimpleValue title="Ethnicity" value={ethnicities} data-cs-mask />
    </div>
  );
}
