import { useState, useEffect } from "react";

export function useVisibleTimer(
  timeout: number
): [boolean, (visible: boolean) => void] {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      const timer = window.setTimeout(() => {
        setVisible(false);
      }, timeout);

      return () => {
        window.clearTimeout(timer);
      };
    }
  }, [timeout, visible]);

  return [visible, setVisible];
}
