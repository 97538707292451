import { createContext, useCallback, useContext } from "react";
import { HelmetProvider, HelmetServerState } from "react-helmet-async";

export type PageStatus = {
  statusCode?: number;
  cacheControl?: number;
  location?: string;
  helmet?: HelmetServerState;
};

interface IContext {
  locale: string;
  redirect(to: string, status?: number): null;
  cacheControl(maxAge: number): void;
  status(code: number): void;
}

const Context = createContext<IContext>({
  redirect() {
    return null;
  },
  cacheControl() {
    return;
  },
  status() {
    return;
  },
  locale: "en-GB",
});

export function PageStatusProvider({
  locale,
  context = {},
  children,
}: {
  context?: PageStatus;
  locale: string;
  children: React.ReactNode;
}): React.ReactElement {
  const redirect = useCallback(
    (to: string, status = 302) => {
      if (!context.statusCode) {
        context.statusCode = status;
        context.location = to;
      }

      return null;
    },
    [context]
  );

  const status = useCallback(
    (statusCode: number) => {
      if (!context.statusCode) {
        context.statusCode = statusCode;
      }
    },
    [context]
  );

  const cacheControl = useCallback(
    (maxAge: number) => {
      context.cacheControl = maxAge;
    },
    [context]
  );

  return (
    <Context.Provider value={{ redirect, cacheControl, status, locale }}>
      <HelmetProvider context={context}>{children}</HelmetProvider>
    </Context.Provider>
  );
}

export function useStatus(): IContext {
  return useContext(Context);
}

// export function useCacheControl(maxAge: number): void {
//   const { ssr, cacheControl } = useStatus();

//   if (ssr) {
//     cacheControl(maxAge);
//   }
// }
