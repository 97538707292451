import { Spacing, Text, Paragraph } from "@otta/design";
import { Company } from "@otta/search/schema";

export const shouldDisplayCompanyMission = (
  company: Pick<CompanyMissionProps["company"], "mission">
) => {
  return Boolean(company.mission);
};

interface CompanyMissionProps {
  company: Company.Fragment;
}

export function CompanyMission({ company }: CompanyMissionProps) {
  if (!shouldDisplayCompanyMission(company)) {
    return null;
  }

  return (
    <div>
      <Spacing>
        <Text bold as="h2" size={1}>
          Company mission
        </Text>
        <Paragraph data-testid="company-mission">{company.mission}</Paragraph>
      </Spacing>
    </div>
  );
}
