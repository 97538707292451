import { List } from "@otta/design";

export function AnonymousBullets(): React.ReactElement {
  return (
    <List type="positive">
      <li>Access salary trajectory data for all roles</li>
      <li>
        Your data is 100% anonymous. We&apos;ll only ever show aggregate data,
        not your company or title
      </li>
      <li>
        Help improve salary transparency. We want everyone to have the
        information they need to be paid fairly
      </li>
    </List>
  );
}
