import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled, { css, up, useUp } from "@xstyled/styled-components";

import { ApplicationStatus } from "../ApplicationStatus";
import { ApplicationQuestions } from "../sections/ApplicationQuestions";
import { ContextQuestions } from "../sections/ContextQuestions";
import { EducationExperiences } from "../sections/EducationExperiences";
import { Interests } from "../sections/Interests";
import { PersonalDetails } from "../sections/PersonalDetails";
import { Snippets } from "../sections/Snippets";
import { WorkExperiences } from "../sections/WorkExperiences";
import { JobNoLongerAvailableModalContent } from "../components/JobNoLongerAvailableModalContent";
import { ConfirmationPopup } from "../components/ConfirmationPopup";
import { Links } from "../sections/Links";
import { ApplicationQuestionsV3 } from "../sections/ApplicationQuestionsV3";

import { Ctas } from "./Ctas";
import { DesktopApplication } from "./DesktopApplication";
import { MobileApplication } from "./MobileApplication";
import { ApplyTipsModal } from "./ApplyTipsModal";

import { palette, pxToRem } from "@otta/design-tokens";
import { Spacing, Modal } from "@otta/design";
import {
  useNewAtsQuestions,
  useIsJobLive,
  useCheckApplicationProgress,
} from "@otta/search/utils/job";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useUserProfileStatus } from "@otta/search/utils/user";
import { Loading } from "@otta/search/components/Loading";
import { useQuery } from "@otta/search/apollo";
import { JobApplication, JobApplicationDocument } from "@otta/search/schema";
import { jobValueClassification } from "@otta/search/utils/analytics/jobProperties";
import { useUserLocation } from "@otta/search/hooks/useUserLocation";
import { countryToCurrency } from "@otta/search/utils/currencies";
import { EBTrackingProvider } from "@otta/search/contexts/EBTracking";

export const APPLY_TIPS_MODAL_LOCAL_STORAGE_KEY = "apply-tips-modal-dismissed";

const ApplicationViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40 xl lg;

  &[hidden] {
    display: none;
  }

  ${up(
    "tablet",
    css`
      padding-bottom: 5xl;
    `
  )}
`;

const CtasWrapper = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  grid-template-columns: repeat(2, ${pxToRem(240)});
  gap: lg;
  padding: lg;
  border-top: 1px solid ${palette.brand.grey};
  background: ${palette.brand.white};

  ${up(
    "tablet",
    css`
      display: grid;
    `
  )}
`;

export type ApplicationProps = {
  job?: JobApplication.PublicJob;
  jobId: string;
  shouldShowJobDetails?: boolean;
  analyticsData: {
    jobValueClassification?: string | null;
    jobTitle?: string;
    companyName?: string;
    jobId?: string;
    function?: string;
    subFunction?: string;
  };
};

export function ApplicationView({
  jobId,
  analyticsData,
}: ApplicationProps): React.ReactElement {
  const [dismissModal, setDismissModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const { profileState, completedFields } = useUserProfileStatus();
  const { isLive } = useIsJobLive(jobId);

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Candidate Viewed Application Profile Page",
      profileState,
      ...analyticsData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const completedWorkExperience = completedFields?.includes(
    "work_experience_descriptions"
  );

  const showNewAtsQuestions = useNewAtsQuestions(jobId);
  const { rows, numberCompleted } = useCheckApplicationProgress(jobId);

  return (
    <ApplicationViewWrapper>
      <Spacing size={2}>
        <ApplicationStatus
          jobId={jobId}
          isLive={isLive}
          showConfirmationPopup={() => setShowPopup(true)}
        />
        {showNewAtsQuestions === true ? (
          <ApplicationQuestionsV3 jobId={jobId} />
        ) : showNewAtsQuestions === false ? (
          <ApplicationQuestions jobId={jobId} />
        ) : (
          <Loading />
        )}
        <WorkExperiences completedWorkExperience={completedWorkExperience} />
        <EducationExperiences />
        <Snippets />
        <ContextQuestions />
        <Interests />
        <Links />
        <PersonalDetails />
      </Spacing>

      <CtasWrapper>
        <Ctas
          jobId={jobId}
          disabled={!isLive || numberCompleted < rows.length}
          showConfirmationPopup={() => setShowPopup(true)}
        />
      </CtasWrapper>

      <Modal
        open={!isLive && !dismissModal}
        onOpenChange={setDismissModal}
        style={{ maxWidth: pxToRem(480) }}
      >
        <JobNoLongerAvailableModalContent
          onClose={() => setDismissModal(true)}
        />
      </Modal>

      {isLive && showPopup && (
        <ConfirmationPopup jobId={jobId} close={() => setShowPopup(false)} />
      )}
    </ApplicationViewWrapper>
  );
}

export default function ApplicationRoute(): React.ReactElement | null {
  const { jobId } = useParams<"jobId">();
  const isTablet = useUp("tablet");
  const { country: userLocation } = useUserLocation();
  const currency = countryToCurrency(userLocation);

  const { data, loading } = useQuery(JobApplicationDocument, {
    variables: {
      jobId: String(jobId),
      currency,
    },
    skip: !jobId,
  });

  const job = data?.publicJob || undefined;

  const analyticsData = useMemo(() => {
    if (loading || !job) {
      return {};
    }

    return {
      jobValueClassification: jobValueClassification(job.function?.id),
      jobTitle: job.title,
      companyName: job.company.name,
      jobId: job.id,
      function: job.function?.value,
      subFunction: job.subFunction?.value,
    };
  }, [job, loading]);

  const [showApplyTipsModal, setShowApplyTipsModal] = useState(false);
  const [shouldShowJobDetails, setShouldShowJobDetails] = useState(false);
  const onCloseTipsModal = () => {
    setShowApplyTipsModal(false);
    setShouldShowJobDetails(true);
    localStorage.setItem(APPLY_TIPS_MODAL_LOCAL_STORAGE_KEY, "true");
  };

  const { isLive } = useIsJobLive(String(jobId));

  useEffect(() => {
    // We're checking the localStorage which isn't available in SSR
    if (typeof window === "undefined") return;
    // If the job is not live, we already show a modal
    if (!isLive) return;
    setShowApplyTipsModal(
      !localStorage.getItem(APPLY_TIPS_MODAL_LOCAL_STORAGE_KEY)
    );
  }, [isLive]);

  if (!jobId) {
    return null;
  }

  const Application = isTablet ? DesktopApplication : MobileApplication;

  return (
    <EBTrackingProvider page="apply" company={job?.company} job={job}>
      <Application
        job={job}
        jobId={jobId}
        analyticsData={analyticsData}
        shouldShowJobDetails={shouldShowJobDetails}
      />
      {showApplyTipsModal && <ApplyTipsModal onClose={onCloseTipsModal} />}
    </EBTrackingProvider>
  );
}
