import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@xstyled/styled-components";

import { QuestionComponentProps } from "..";
import HideCompany from "../../../Images/HideCompany";

import { Spacing, Text } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { Checkbox } from "@otta/search/components/Input/Checkbox";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { HiddenCompanyInput } from "@otta/search/schema";

const NoHiddenCompanies = () => {
  return (
    <Spacing>
      <HideCompany />
      <Text>
        Any companies that you hide will appear here. You can hide companies
        from the job card.
      </Text>
    </Spacing>
  );
};

const AllButton = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
  display: inline;
`;

const HiddenCompaniesList = ({
  initialData,
  update,
}: {
  initialData: HiddenCompanyInput[];
  update: (data: HiddenCompanyInput[]) => void;
}) => {
  const [hiddenCompanies, setHiddenCompanies] = useState<
    Map<string, HiddenCompanyInput>
  >(new Map(initialData.map(c => [c.id, c])));

  const handleClickCompany = (company: HiddenCompanyInput) => {
    const newValue = new Map(hiddenCompanies);
    if (newValue.has(company.id)) {
      newValue.delete(company.id);
    } else {
      newValue.set(company.id, company);
    }
    update(Array.from(newValue.values()));
    setHiddenCompanies(newValue);
  };

  const handleClickAll = () => {
    if (hiddenCompanies.size === 0) {
      update(initialData);
      setHiddenCompanies(new Map(initialData.map(c => [c.id, c])));
    } else {
      update([]);
      setHiddenCompanies(new Map());
    }
  };

  const allButtonLabel = useMemo(
    () => (hiddenCompanies.size === 0 ? "Hide all" : "Unhide all"),
    [hiddenCompanies]
  );

  return (
    <Spacing>
      <AllButton onClick={handleClickAll}>{allButtonLabel}</AllButton>
      {initialData.map(company => (
        <Checkbox
          key={company.id}
          label={company.name}
          checked={hiddenCompanies.has(company.id)}
          onChange={() => handleClickCompany(company)}
        />
      ))}
    </Spacing>
  );
};

export function HiddenCompaniesPreference(
  props: QuestionComponentProps
): React.ReactElement {
  const { preferencesData, updatePreferences, loading } = useUserPreferences();

  const initialData = useMemo(
    () => (!loading && preferencesData?.hiddenCompanies) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  );

  useEffect(() => {
    if (preferencesData) {
      props.setNextEnabled(true);
    }
  }, [props, preferencesData]);

  const update = useCallback(
    (hiddenCompanies: HiddenCompanyInput[]) => {
      updatePreferences({ ...preferencesData, hiddenCompanies });
    },
    [preferencesData, updatePreferences]
  );

  if (loading) {
    return <Loading />;
  }

  if (initialData.length < 1) {
    return <NoHiddenCompanies />;
  }

  return <HiddenCompaniesList initialData={initialData} update={update} />;
}
