import styled, { css, up } from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";
import { Company } from "@otta/search/schema";
import { imgProxy } from "@otta/search/utils/image";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: lg;
`;

const Logo = styled.img`
  max-height: ${pxToRem(32)};
  min-height: ${pxToRem(32)};
  ${up(
    "tablet",
    css`
      max-height: ${pxToRem(48)};
      min-height: ${pxToRem(48)};
    `
  )}
  width: auto;
  height: auto;
  max-width: 180px;
  object-fit: contain;
`;

interface CompanyLogoProps {
  company: Pick<Company.Fragment, "logoPath" | "websiteUrl" | "name">;
}

export function CompanyLogo({
  company: { websiteUrl, logoPath, name },
}: CompanyLogoProps): React.ReactElement | null {
  if (!websiteUrl || !logoPath) {
    return null;
  }

  return (
    <LogoContainer>
      <a
        href={`${websiteUrl}?utm_source=otta.com`}
        target="_blank"
        rel="noreferrer"
      >
        <div data-testid="company-logo">
          {logoPath.startsWith("https://") ? (
            <Logo src={logoPath} alt={name} />
          ) : (
            <picture>
              <source
                type="image/webp"
                srcSet={imgProxy(logoPath, { width: 400 }, "webp")}
              />
              <Logo
                src={imgProxy(logoPath, { width: 400 }, "png")}
                alt={name}
              />
            </picture>
          )}
        </div>
      </a>
    </LogoContainer>
  );
}
