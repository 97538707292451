import { useEffect } from "react";
import styled, { css, up } from "@xstyled/styled-components";

import { ProfileTop } from "./sections/ProfileTop";
import { Application, NewATSApplication } from "./sections/Application";
import { Snippets } from "./sections/Snippets";
import { PublicWorkExperiences as WorkExperiences } from "./sections/WorkExperiences";
import { EducationExperiences } from "./sections/EducationExperiences";
import { ContextQuestions } from "./sections/ContextQuestions";
import { Interests } from "./sections/Interests";
import { MobileView } from "./components/Section";

import { pxToRem } from "@otta/design-tokens";
import { Spacing, Text } from "@otta/design";
import { useNewAtsQuestions } from "@otta/search/utils/job";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import { LatestJobApplicationDocument } from "@otta/search/schema";
import { useProfileTracking } from "@otta/search/hooks/useViewTracking";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${pxToRem(1024)};
  width: 100%;
  margin: 0 auto;
  padding-bottom: lg;
  align-items: center;
  justify-content: center;

  ${up(
    "tablet",
    css`
      flex-direction: row;
    `
  )}
`;

export const LeftWrapper = styled.div<{ additionalSections?: boolean }>(
  ({ additionalSections }) => css`
    ${additionalSections && "flex: 0 0 70%;"}
    padding: lg md 0;
    max-width: ${pxToRem(375)};

    ${up(
      "tablet",
      css`
        padding: xl 19 0;
        width: 100%;
        max-width: ${pxToRem(716)};
        ${additionalSections && `)}max-width: ${pxToRem(538)};`}
      `
    )}

    ${up(
      "desktop",
      css`
        max-width: ${pxToRem(716)};
      `
    )}
  `
);

const RightWrapper = styled.div<{ additionalSections?: boolean }>(
  ({ additionalSections }) => css`
    flex: 0 0 30%;
    display: none;

    ${up(
      "tablet",
      css`
        display: ${additionalSections ? "block" : "none"};
        padding: xl 19 0 6;
        align-self: flex-start;
        top: 0;
      `
    )}
  `
);

export function PublicProfile({
  jobId,
  jobApplicationExternalId,
}: {
  jobId: string;
  jobApplicationExternalId: string;
}): React.ReactElement | null {
  useProfileTracking(jobApplicationExternalId);

  const { data, loading } = useQuery(LatestJobApplicationDocument, {
    variables: { jobId },
  });

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Company Viewed Candidate's Public Otta Profile",
    });
  }, []);

  const application = data?.latestJobApplication;
  const candidate = application?.candidate;

  const showNewAtsQuestions = useNewAtsQuestions(jobId);

  const atsAnswerData = application?.data?.data;
  const newAtsAnswerData = application?.atsQuestions;

  const hasAnswerData =
    (atsAnswerData && atsAnswerData?.answers.length > 0) ||
    (newAtsAnswerData && newAtsAnswerData?.sections.length > 0);

  if (loading) {
    return <Loading />;
  }

  if (!application || !candidate || (!atsAnswerData && !newAtsAnswerData)) {
    return null;
  }

  const job = application.job;

  const hasAdditionalSections =
    candidate.contextQuestionResponses?.length > 0 ||
    !!candidate.profileInterests;

  const hasApplication = application && hasAnswerData;
  const hasSnippets = candidate && candidate.projects?.length > 0;
  const hasContextQuestions = candidate.contextQuestionResponses?.length > 0;

  return (
    <PageWrapper data-testid="public-profile">
      <LeftWrapper additionalSections={hasAdditionalSections}>
        <Spacing size={2}>
          <ProfileTop candidate={candidate} job={job} />
          {hasAnswerData && (
            <Text size={1} align="left" bold>
              Application
            </Text>
          )}
          {showNewAtsQuestions && newAtsAnswerData && (
            <NewATSApplication answerData={newAtsAnswerData} />
          )}
          {!showNewAtsQuestions && atsAnswerData && (
            <Application answerData={atsAnswerData} />
          )}
          <Text size={1} align="left" bold>
            Profile
          </Text>
          <Snippets candidate={candidate} expand={!hasApplication} />
          <WorkExperiences
            candidate={candidate}
            job={job}
            expand={!hasApplication && !hasSnippets}
          />
          <EducationExperiences candidate={candidate} />
          <MobileView>
            <Spacing size={2}>
              {hasContextQuestions && (
                <ContextQuestions candidate={candidate} />
              )}
              <Interests candidate={candidate} />
            </Spacing>
          </MobileView>
        </Spacing>
      </LeftWrapper>
      <RightWrapper additionalSections={hasAdditionalSections}>
        <Spacing size={2}>
          {hasContextQuestions && <ContextQuestions candidate={candidate} />}
          <Interests candidate={candidate} />
        </Spacing>
      </RightWrapper>
    </PageWrapper>
  );
}
