import { Routes, Route } from "react-router-dom";

import { JobInterviewFeedbackReceived } from "./JobInterviewFeedbackReceived";
import { JobApplicationExternalResponded } from "./JobApplicationExternalResponded";
import { InterviewResult } from "./InterviewResult";
import { MarketingOptInStatus } from "./MarketingOptInStatus";

export function Callback(): React.ReactElement {
  return (
    <Routes>
      <Route path="interview-result" element={<InterviewResult />} />
      <Route
        path="job-application-external-responded"
        element={<JobApplicationExternalResponded />}
      />
      <Route
        path="job-application-external-responded/:externalId"
        element={<JobApplicationExternalResponded />}
      />
      <Route
        path="job-interview-feedback-received/:interviewId"
        element={<JobInterviewFeedbackReceived />}
      />
      <Route path="marketing-opt-in" element={<MarketingOptInStatus />} />
    </Routes>
  );
}
