export function Heart(): React.ReactElement {
  return (
    <svg
      className="heart"
      xmlns="http://www.w3.org/2000/svg"
      height="12px"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 14 13"
      fill="none"
    >
      <path
        d="M1.201 1.21986C2.80223 -0.406529 5.39858 -0.406709 7 1.21986C8.60124 -0.406529 11.1976 -0.406709 12.799 1.21986C14.4004 2.84642 14.4002 5.48354 12.799 7.10993L7 13L1.201 7.10993C-0.40042 5.48336 -0.400243 2.84624 1.201 1.21986Z"
        fill="black"
      />
    </svg>
  );
}
