import styled, { css, up } from "@xstyled/styled-components";

import { Card } from "@otta/design";
import { fadeIn } from "@otta/search/components/Animations";

const Img = styled.img<{ small: boolean }>`
  width: ${({ small }) => (small ? "30px" : "100px")};
  min-width: ${({ small }) => (small ? "30px" : "100px")};
  height: auto;
  object-fit: contain;
  border-radius: ${({ small }) => (small ? "6px" : "12px")};
  margin: ${({ small }) => (small ? "4px 6px" : "8px 12px")};

  ${up(
    "tablet",
    css`
      margin: 15px 32px;
    `
  )}
`;

const Imgs = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  max-width: 490px;
`;

const ImgWrapper = styled(Card)<{ small: boolean }>`
  width: ${({ small }) => (small ? "50px" : "80px")};
  height: ${({ small }) => (small ? "50px" : "80px")};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ small }) => (small ? "4px" : "8px")};
  animation: ${fadeIn} 1s ease-in-out;
`;

interface IImageBubbleProps {
  images: string[];
  small?: boolean;
}

export function ImageBubble({
  images,
  small = false,
}: IImageBubbleProps): React.ReactElement {
  return (
    <Imgs>
      {images.map((image, index) => (
        <ImgWrapper key={index} small={small}>
          <Img small={small} src={image} />
        </ImgWrapper>
      ))}
    </Imgs>
  );
}
