import { FieldValidator } from "final-form";
import { Field } from "react-final-form";

import { InputField } from "@otta/design";

export type SettingsInputType = "email" | "text";

export type SettingsName =
  | "email"
  | "name"
  | "firstName"
  | "lastName"
  | "linkedinUrl"
  | "location";

interface SettingsFieldProps {
  name: SettingsName;
  type: SettingsInputType;
  label: string;
  validators?: FieldValidator<string>;
  placeholder?: string;
}

export const SettingsField = ({
  name,
  type,
  validators,
  placeholder,
}: SettingsFieldProps) => (
  <Field<string> name={name} validate={validators}>
    {({ input, meta }) => {
      return (
        <InputField
          {...input}
          data-testid={`${name}-input`}
          placeholder={placeholder}
          type={type}
          name={name}
          error={(meta.active || meta.dirty) && meta.error}
        />
      );
    }}
  </Field>
);
