import styled from "@xstyled/styled-components";

interface ICircularImgWrapperProps {
  width?: string;
}

const CircularImgWrapper = styled.div<ICircularImgWrapperProps>`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ width }) => (width ? width : "100%")};
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
`;

const CircularImgInner = styled.img`
  display: inline;
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
`;

interface ICircularImgProps {
  src: string;
  alt: string;
  width?: string;
}

export function CircularImg({
  src,
  width,
  alt,
}: ICircularImgProps): React.ReactElement {
  return (
    <CircularImgWrapper width={width}>
      <CircularImgInner src={src} alt={alt} loading="lazy" />
    </CircularImgWrapper>
  );
}
