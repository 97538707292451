import store from "store";
import { useParams, Link as RouterLink, useLocation } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";
import { format, parseISO } from "date-fns";
import { useState, useEffect } from "react";

import { ShareOttaPopup } from "./ShareOttaPopup";
import { SetYourPreferencesPopup } from "./SetYourPreferencesPopup";

import { modularScale, pxToRem } from "@otta/design-tokens";
import {
  Button,
  Card,
  List as DefaultList,
  Spacing,
  Heading,
  Text,
} from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import {
  PublicProfile,
  PageWrapper as DefaultPageWrapper,
  LeftWrapper,
} from "@otta/search/pages/Profile/PublicProfile";
import {
  ApplicationStatusDocument,
  UserHasSetPreferencesDocument,
} from "@otta/search/schema";

const PageWrapper = styled(DefaultPageWrapper)`
  padding: xl 0 0;

  ${up(
    "tablet",
    css`
      flex-direction: column;
    `
  )}
`;

const DashboardWrapper = styled(LeftWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
`;

const List = styled(DefaultList)`
  width: fit-content;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: ${pxToRem(250)};

  button {
    margin: xs 0;
  }

  ${up(
    "tablet",
    css`
      flex-direction: row;
      max-width: none;

      button {
        margin: 0 xs;
      }
    `
  )}
`;

const Separator = styled.div`
  display: flex;
  justify-self: center;
  color: gray-400;
  border: 0.5px solid;
  width: ${pxToRem(130)};
`;

const StyledButton = styled(Button)`
  font-size: ${modularScale(-1)};
  padding: sm xl;
`;

type showPopupType = "SHARE" | "SET_PREFERENCES" | false;

export default function ApplicationView(): React.ReactElement {
  const { jobId } = useParams<{ jobId: string }>();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState<showPopupType>(false);
  const { data, loading } = useQuery(ApplicationStatusDocument, {
    variables: {
      jobId: jobId as string,
    },
  });

  const { data: userPreferencesData, loading: userPreferencesLoading } =
    useQuery(UserHasSetPreferencesDocument);

  const preferencesSet =
    !userPreferencesLoading &&
    userPreferencesData?.currentUser?.hasSetPreferences;
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Show either one pop up or the other.
  // Once closed, show neither.
  useEffect(() => {
    if (preferencesSet && !store.get("seen-share-otta-popup")) {
      store.set("seen-share-otta-popup", true);
      setShowPopup("SHARE");
    } else if (
      !userPreferencesLoading &&
      !preferencesSet &&
      !store.get("seen-share-otta-popup")
    ) {
      store.set("seen-share-otta-popup", true);
      setShowPopup("SET_PREFERENCES");
    }
  }, [preferencesSet, userPreferencesLoading]);

  if (loading || userPreferencesLoading) {
    return <Loading />;
  }

  const application = data?.latestJobApplication;
  const applicationExternalId = application?.externalId;
  const appliedViaOtta = application?.applied && application?.internal;

  if (!applicationExternalId || !appliedViaOtta) {
    return (
      <PageWrapper>
        <DashboardWrapper
          style={{ marginTop: modularScale(-2) }}
          data-testid="no-application"
        >
          <Spacing size={2}>
            <Text size={2} as="h3" align="center">
              You didn&apos;t apply for this job through Welcome to the Jungle
            </Text>
            <Spacing size={0}>
              <Text bold align="center">
                Look out for roles where you can apply via Welcome to the Jungle
              </Text>
              <List type="positive">
                <li>Easily apply to thousands of roles with one profile</li>
                <li>Keep track of your applications</li>
                <li>Get insights into the status of your application</li>
              </List>
            </Spacing>
            <ButtonWrapper>
              <Button as={RouterLink} to="/" level="primary">
                Back to Home
              </Button>
            </ButtonWrapper>
          </Spacing>
        </DashboardWrapper>
      </PageWrapper>
    );
  }

  const company = application?.job?.company;

  const companyResponded = typeof application?.accepted === "boolean";
  const inboxPath = application?.conversation
    ? `/messaging/${application.conversation.id}`
    : "/messaging";

  return (
    <PageWrapper>
      <DashboardWrapper data-testid="application-view">
        <Spacing size={2}>
          <Card
            style={{
              width: "100%",
              maxWidth: pxToRem(800),
            }}
          >
            <Spacing size={-1}>
              <Heading size={1} bold align="center">
                We&apos;re rooting for you!
              </Heading>
              {companyResponded ? (
                <Text align="center" data-testid="response-text">
                  {company?.name} has responded to your application
                </Text>
              ) : (
                <Text align="center">
                  We&apos;ve sent your application to {company.name}, and
                  we&apos;ll check to see if you&apos;ve had a response in two
                  weeks.
                </Text>
              )}
              {application?.appliedAt && (
                <Text align="center" size={-1}>
                  Application sent{" "}
                  {format(parseISO(application.appliedAt), "dd MMMM yyyy")}
                </Text>
              )}
              <ButtonWrapper>
                <StyledButton
                  as={RouterLink}
                  to={
                    companyResponded
                      ? inboxPath
                      : location.state === "from-batch-end"
                      ? "/jobs/batch-end"
                      : `/dashboard`
                  }
                  level="primary"
                >
                  {companyResponded
                    ? "View response"
                    : location.state === "from-batch-end"
                    ? "Go back to your jobs"
                    : "Go to your dashboard"}
                </StyledButton>
              </ButtonWrapper>
            </Spacing>
          </Card>
          <Separator />
        </Spacing>
      </DashboardWrapper>
      <PublicProfile
        jobId={jobId as string}
        jobApplicationExternalId={applicationExternalId}
      />
      {showPopup === "SHARE" && <ShareOttaPopup onClose={handleClosePopup} />}
      {showPopup === "SET_PREFERENCES" && (
        <SetYourPreferencesPopup onClose={handleClosePopup} />
      )}
    </PageWrapper>
  );
}
