import styled from "@xstyled/styled-components";
import { Link } from "react-router-dom";

import { Button, Heading, Spacing, Text } from "@otta/design";

const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: yellow-500;
  background-image: url("https://storage.googleapis.com/otta-static/hero-images/colourway-1/shine_brighter_1024.png");
  background-repeat: no-repeat;
  background-position: 50% 12%;
  height: 100%;
  flex: 1;
`;

export const JobNotFound = (): React.ReactElement => {
  return (
    <Background>
      <Spacing size={4}>
        <Heading size={10}>404</Heading>
        <Heading size={4}>Feeling lost?</Heading>
        <Button level="tertiary" as={Link} to="/sign-up">
          <Text bold>Find a job on Welcome to the Jungle</Text>
        </Button>
      </Spacing>
    </Background>
  );
};
