import { Country } from "@otta/search/constants/locations";
import { Currency } from "@otta/search/schema";

export function currencyPrefix(
  currency: string | Currency
): "£" | "$" | "€" | "CA$" {
  switch (currency) {
    case Currency.Gbp:
      return "£";
    case Currency.Eur:
      return "€";
    case Currency.Cad:
      return "CA$";
    default:
      return "$";
  }
}

export const countryToCurrency = (country: Country | undefined): Currency => {
  switch (country) {
    case Country.US:
      return Currency.Usd;
    case Country.UK:
      return Currency.Gbp;
    case Country.CA:
      return Currency.Cad;
    case Country.NL:
      return Currency.Eur;
    case Country.DE:
      return Currency.Eur;
    case Country.ES:
      return Currency.Eur;
    case Country.IE:
      return Currency.Eur;
    case Country.FR:
      return Currency.Eur;
    default:
      return Currency.Usd;
  }
};
