import { ReactElement } from "react";
import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

const Container = styled.div`
  display: flex;
  gap: 5px;
`;

const Step = styled.div<{ $completed: boolean }>`
  flex: 1;
  height: 5px;
  border-radius: 2px;
  background: linear-gradient(
    to right,
    ${palette.extended.green.shade400} 50%,
    ${palette.brand.grey} 50%
  );
  background-size: 200% 100%;
  transition: default;

  background-position: ${({ $completed }) =>
    $completed ? "left bottom" : "right bottom"};
`;

interface IProgressBarProps {
  step: number;
  of: number;
}

export default function ProgressBar({
  step,
  of,
}: IProgressBarProps): ReactElement {
  return (
    <Container>
      {Array.from({ length: of }, (_, index) => {
        return <Step key={index} $completed={index < step} />;
      })}
    </Container>
  );
}
