import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";

import { PublicJobActionModal } from "./PublicJobActionModal";

import { Text } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { PublicJobQuery } from "@otta/search/schema";
import { JobCardNav } from "@otta/search/pages/Jobs/JobDeck/components/JobCardNav";

interface IPublicActionPanelProps {
  job: NonNullable<PublicJobQuery["publicJob"]>;
  utmSource?: string;
}

const NEXT_MODAL_HEADER = <Text bold>Sign up to see more jobs</Text>;
const SAVE_MODAL_HEADER = <Text bold>Sign up to save this job</Text>;

export function PublicJobActionPanel({
  job,
  utmSource,
}: IPublicActionPanelProps): React.ReactElement {
  const navigate = useNavigate();

  const [showNextModal, setShowNextModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const handleSave = () => {
    setShowSaveModal(true);
    pushAnalyticsEvent({
      eventName: "Clicked Public Save Link",
      jobId: job.externalId,
      utmSource,
    });
  };

  const handleNext = () => {
    setShowNextModal(true);
    pushAnalyticsEvent({
      eventName: "Clicked Public Next Link",
      jobId: job.externalId,
      utmSource,
    });
  };

  const handleApply = useCallback(() => {
    if (job.originalUrl) {
      pushAnalyticsEvent({
        eventName: "Clicked Public Apply Link",
        jobId: job.externalId,
        utmSource,
      });

      window.open(job.originalUrl);
    } else {
      navigate({
        pathname: `/apply/sign-up/${job.externalId}`,
        search: queryString.stringify({
          utm_source: utmSource,
          redirect: `/jobs/${job.externalId}/apply`,
        }),
      });
    }
  }, [job.externalId, job.originalUrl, navigate, utmSource]);

  return (
    <>
      <JobCardNav
        onApply={handleApply}
        onSave={handleSave}
        onNext={handleNext}
        showApplyButton={job.live}
        showKeyboardHints={false}
        isPreview={false}
      />
      {showNextModal && (
        <PublicJobActionModal
          heading={NEXT_MODAL_HEADER}
          onClose={() => setShowNextModal(false)}
          utmSource={utmSource}
          jobExternalId={job.externalId}
        />
      )}
      {showSaveModal && (
        <PublicJobActionModal
          heading={SAVE_MODAL_HEADER}
          onClose={() => setShowSaveModal(false)}
          utmSource={utmSource}
          jobExternalId={job.externalId}
        />
      )}
    </>
  );
}
