import { useEffect, useMemo, useCallback } from "react";
import { AnimatePresence } from "framer-motion";
import { useMutation } from "@apollo/client";

import { DashboardBannerNotification } from "./BannerNotification";
import { HiddenProfileNotification } from "./types/HiddenProfile";
import { CompleteYourProfileNotification } from "./types/CompleteYourProfile";
import { CreateAProfileNotification } from "./types/CreateAProfile";
import { UnhideProfileNotification } from "./types/UnhideProfile";

import { TipType } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import { handleMutationError } from "@otta/search/utils/error";
import {
  NotificationDocument,
  DismissNotificationDocument,
  Notification,
} from "@otta/search/schema";

const getNotification = (notification: string | null) => {
  switch (notification) {
    case Notification.HiddenProfile:
      return HiddenProfileNotification;
    case Notification.CreateProfile:
      return CreateAProfileNotification;
    case Notification.CompleteProfile:
      return CompleteYourProfileNotification;
    case Notification.UnhideProfile:
      return UnhideProfileNotification;
    default:
      return null;
  }
};

export interface IDashboardBannerNotification {
  key: string;
  name: string;
  Content: React.FC;
  level: TipType;
  dismissable?: boolean;
  hasIcon?: boolean;
}

export const BannerNotification = (): React.ReactElement | null => {
  const { data, loading, refetch } = useQuery(NotificationDocument, {
    fetchPolicy: "cache-and-network",
  });

  const [dismissMutation] = useMutation(DismissNotificationDocument, {
    onError: handleMutationError,
  });

  const notification = useMemo(() => {
    return getNotification(data?.me?.notification ?? null);
  }, [data]);

  const dismissNotification = useCallback(
    (notification: IDashboardBannerNotification) => async () => {
      pushAnalyticsEvent({
        eventName: "Candidate Dismissed Dashboard Notification",
        key: notification.key,
      });
      await dismissMutation({
        variables: { notificationName: notification.name },
      });
      await refetch();
    },
    [dismissMutation, refetch]
  );

  useEffect(() => {
    if (notification) {
      pushAnalyticsEvent({
        eventName: "Candidate Viewed Dashboard Notification",
        key: notification.key,
      });
    }
  }, [notification]);

  if (loading || !data) {
    return null;
  }

  return (
    <AnimatePresence mode="wait">
      {notification && (
        <DashboardBannerNotification
          key={notification.key}
          level={notification.level}
          onClick={dismissNotification(notification)}
          dismissable={notification.dismissable}
          hasIcon={notification.hasIcon}
        >
          <notification.Content />
        </DashboardBannerNotification>
      )}
    </AnimatePresence>
  );
};
