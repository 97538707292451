import styled, { css, down } from "@xstyled/styled-components";
import { useCallback, useMemo } from "react";

import { responsive, Middle } from "@otta/design";
import { BatchCard } from "@otta/search/components/BatchCard";
import { Sector } from "@otta/search/components/BatchCard/theme";
import { toQueryKey } from "@otta/search/constants/themedBatches";
import { ThemedBatchesQuery, ThemeId } from "@otta/search/schema";
import { useJobRecommendations } from "@otta/search/pages/Jobs/JobRecommendationProvider";

function themes(): (ThemeId | "ALL_MATCHES")[] {
  const themes: (ThemeId | "ALL_MATCHES")[] = Object.values(ThemeId);
  themes.push("ALL_MATCHES");
  return themes;
}

type Props = React.PropsWithChildren<{
  currentTheme: ThemeId | "ALL_MATCHES";
  batches: NonNullable<ThemedBatchesQuery["currentUser"]>;
  sector: Sector;
}>;

type ThemeCount = [ThemeId | "ALL_MATCHES", number];

const AlwaysPadded = styled.div`
  padding: 0 lg;
`;

const PaddedUnlessMobile = styled.div`
  padding: 0 lg;
  ${down(
    "tablet",
    css`
      @media (hover: none) {
        padding: 0;
      }
    `
  )}
`;

const CardContainer = styled.div<{ count: number }>(
  ({ count }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    ${responsive`row-gap: ${{ mobile: 6, desktop: 16 }};`}
    ${responsive`column-gap: ${{ mobile: 8, desktop: 20 }};`}

  ${down(
      "tablet",
      css`
        @media (hover: hover) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (hover: none) {
          overflow-x: scroll;
          grid-auto-columns: 47%;
          grid-template-rows: repeat(2, max-content);
          grid-auto-flow: ${count === 2 ? "row" : "column"};
          grid-template-columns: repeat(2, ${count <= 4 ? "1fr" : "47%"});
          padding: 0 lg;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      `
    )}
  `
);

function compareBatches(
  [themeA, countA]: ThemeCount,
  [themeB, countB]: ThemeCount
): number {
  return themeA == "ALL_MATCHES"
    ? -1
    : themeB == "ALL_MATCHES"
    ? 1
    : themeA == ThemeId.FullyRemote && countA == countB
    ? 1
    : themeB == ThemeId.FullyRemote && countA == countB
    ? -1
    : countB - countA;
}

export function ThemedBatches({
  batches,
  currentTheme,
  sector,
  children,
}: Props): React.ReactElement {
  const counts = useMemo(
    () =>
      themes()
        .map<ThemeCount>(id => [id, (batches?.[toQueryKey(id)] ?? []).length])
        .filter(([theme, count]) => count > 0 && theme !== currentTheme)
        .sort((a, b) => compareBatches(a, b))
        .slice(0, 6),
    [batches, currentTheme]
  );

  const { replace } = useJobRecommendations();

  const handleClick = useCallback(
    (id: ThemeId | "ALL_MATCHES") => {
      replace(batches?.[toQueryKey(id)] ?? []);
    },
    [batches, replace]
  );

  return (
    <>
      <AlwaysPadded>
        <Middle maxWidth={1024} textAlign={"left"}>
          {children}
        </Middle>
      </AlwaysPadded>
      <PaddedUnlessMobile>
        <Middle maxWidth={1024} textAlign={"left"}>
          <CardContainer count={counts.length}>
            {counts.map(([id, count], index) => {
              return (
                <BatchCard
                  key={index}
                  index={index}
                  theme={{ id, sector }}
                  count={count}
                  onClick={() => handleClick(id)}
                />
              );
            })}
          </CardContainer>
        </Middle>
      </PaddedUnlessMobile>
    </>
  );
}
