import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";

import { Error } from "../../../components/FormData";

const MultipleChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
`;

const LabelText = styled.span`
  margin-left: 6px;
`;

const StyledError = styled(Error)`
  margin-top: 10px;
`;

export function EditBooleanField({
  name,
}: {
  name: string;
}): React.ReactElement {
  return (
    <MultipleChoiceContainer>
      {[true, false].map((value, idx) => (
        <Label key={idx}>
          <Field<boolean, HTMLInputElement, string>
            name={name}
            type="radio"
            component="input"
            parse={x => x === "true"}
            value={value}
          />
          <LabelText>{value ? "Yes" : "No"}</LabelText>
        </Label>
      ))}
      <Field name={name} subscription={{ touched: true, error: true }}>
        {({ meta }) =>
          meta.error && meta.touched ? (
            <StyledError>{meta.error}</StyledError>
          ) : null
        }
      </Field>
    </MultipleChoiceContainer>
  );
}
