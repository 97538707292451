import { useCallback } from "react";

import { Button } from "@otta/design";

export function ConfirmEmailButton({ mutation }: { mutation: () => void }) {
  const requestConfirmationEmail = useCallback(() => mutation(), [mutation]);

  return (
    <div style={{ display: "flex" }}>
      <Button level="secondary" onClick={requestConfirmationEmail}>
        Get confirmation email
      </Button>
    </div>
  );
}
