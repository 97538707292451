interface JobsProps extends React.SVGAttributes<SVGSVGElement> {
  active?: boolean;
}

export function Jobs({ active, ...props }: JobsProps): React.ReactElement {
  return active ? (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.1875 4.50488H14.8125C14.9844 4.50488 15.125 4.64551 15.125 4.81738V6.37988H8.875V4.81738C8.875 4.64551 9.01562 4.50488 9.1875 4.50488ZM7 4.81738V6.37988H4.5C3.12109 6.37988 2 7.50098 2 8.87988V12.6299H9.5H14.5H22V8.87988C22 7.50098 20.8789 6.37988 19.5 6.37988H17V4.81738C17 3.61035 16.0195 2.62988 14.8125 2.62988H9.1875C7.98047 2.62988 7 3.61035 7 4.81738ZM22 13.8799H14.5V15.1299C14.5 15.8213 13.9414 16.3799 13.25 16.3799H10.75C10.0586 16.3799 9.5 15.8213 9.5 15.1299V13.8799H2V18.8799C2 20.2588 3.12109 21.3799 4.5 21.3799H19.5C20.8789 21.3799 22 20.2588 22 18.8799V13.8799Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 6.375H17V5.125C17 3.74414 15.8809 2.625 14.5 2.625H9.5C8.12109 2.625 7 3.74414 7 5.125V6.375H4.5C3.11914 6.375 2 7.49609 2 8.875V18.875C2 20.2559 3.11914 21.375 4.5 21.375H19.5C20.8809 21.375 22 20.2559 22 18.875V8.875C22 7.49609 20.8789 6.375 19.5 6.375ZM9.5 4.5H14.5C14.8452 4.5 15.125 4.7798 15.125 5.125V6.375H8.875V5.125C8.875 4.77969 9.15625 4.5 9.5 4.5ZM4.5 8.25H19.5C19.8438 8.25 20.125 8.53125 20.125 8.875V12.625H3.875V8.875C3.875 8.53125 4.15625 8.25 4.5 8.25ZM19.5 19.5H4.5C4.15625 19.5 3.875 19.2188 3.875 18.875V14.5H9.5V15.125C9.5 15.8152 10.0598 16.375 10.75 16.375H13.25C13.9402 16.375 14.5 15.8152 14.5 15.125V14.5H20.125V18.875C20.125 19.2188 19.8438 19.5 19.5 19.5Z"
        fill="black"
      />
    </svg>
  );
}
