import styled, { css, up } from "@xstyled/styled-components";
import { useMemo } from "react";

import { JobCardSection } from "./JobCardSection";

import {
  Job,
  JobCompanyTeamFragment,
  JobCompanyTeamMemberFragment,
  TeamMemberRole,
} from "@otta/search/schema";
import { TeamSize } from "@otta/search/components/MeetTheTeam/TeamSize";
import { MissionCard } from "@otta/search/components/MeetTheTeam/MissionCard";
import { OrgChart } from "@otta/search/components/MeetTheTeam/OrgChart";
import { TeamAssets } from "@otta/search/components/MeetTheTeam/TeamAssets";
import { MeetCards } from "@otta/search/components/MeetCards";
import { useVariant } from "@otta/search/hooks/unleash";
import { Experiment } from "@otta/search/constants/experiments";
import { CompanyPodcast } from "@otta/search/components/MeetTheTeam/CompanyPodcast";
import {
  CompanyPodcastData,
  JobCompanyPodcastData,
} from "@otta/search/components/MeetTheTeam/CompanyPodcast/types";

type TeamProps = {
  team: JobCompanyTeamFragment;
  members: JobCompanyTeamMemberFragment[];
  jobTitle: NonNullable<Pick<Job.Fragment, "title">["title"]>;
  jobId: NonNullable<Pick<Job.Fragment, "externalId">["externalId"]>;
};

const Content = styled.div`
  flex-direction: column;
  padding-top: 0.5rem;
  display: flex;
  gap: 1.5rem;
`;

const MobileTeamSize = styled(TeamSize)`
  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

const DesktopTeamSize = styled(TeamSize)`
  ${up(
    "tablet",
    css`
      display: flex;
    `
  )}
  display: none;
`;

export function MeetTheTeamSection({
  team,
  members,
  jobTitle,
  jobId,
}: TeamProps): React.ReactElement {
  const manager: JobCompanyTeamMemberFragment | undefined = useMemo(
    () => members.find(m => m.role === TeamMemberRole.Manager),
    [members]
  );

  const { payload }: { payload: JobCompanyPodcastData } = useVariant(
    Experiment.CompanyPodcast
  );

  const podcastData: CompanyPodcastData | undefined =
    !!payload && jobId in payload ? payload[jobId] : undefined;

  return (
    <JobCardSection
      title="Meet the team"
      TopRightComponent={<DesktopTeamSize size={team.size} />}
      id="meet-the-team"
    >
      <Content data-testid="mtt-content">
        <MobileTeamSize size={team.size} />
        <TeamAssets assets={team.brandAssetLinks} />
        {!!team.mission && <MissionCard>{team.mission}</MissionCard>}
        {!!podcastData && <CompanyPodcast companyPodcastData={podcastData} />}
        <OrgChart members={members} jobTitle={jobTitle} teamSize={team.size} />
        {manager && <MeetCards member={manager} />}
      </Content>
    </JobCardSection>
  );
}
