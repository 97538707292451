import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";
import { Fragment } from "react";

import { FormGreenhouseDemographicChoice } from "../types";

import { InputField } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import {
  composeValidators,
  required,
  maxLength,
} from "@otta/search/utils/validators";
import { GreenhouseDemographicChoiceType } from "@otta/search/schema";

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
`;

const LabelText = styled.span`
  margin-left: 6px;
`;

interface EditDemographicFieldProps {
  name: string;
  choices: FormGreenhouseDemographicChoice[];
}

export function EditDemographicMultiSelectField({
  name,
  choices,
}: EditDemographicFieldProps): React.ReactElement {
  return (
    <CheckboxesContainer>
      <Field<FormGreenhouseDemographicChoice[]>
        name={name}
        render={({ input: { value, onChange } }) => {
          return (
            <Checkboxes
              name={name}
              selectedChoices={value ?? []}
              onChange={onChange}
              choices={choices}
            />
          );
        }}
      />
    </CheckboxesContainer>
  );
}

function Checkboxes({
  name,
  choices,
  selectedChoices,
  onChange,
}: {
  name: string;
  choices: FormGreenhouseDemographicChoice[];
  selectedChoices: FormGreenhouseDemographicChoice[];
  onChange: (choices: FormGreenhouseDemographicChoice[]) => void;
}) {
  const handleChange = (value: string) => {
    let newSelectedChoices: FormGreenhouseDemographicChoice[] = [];

    const choice = choices.find(
      choice => choice.value === value
    ) as FormGreenhouseDemographicChoice;

    if ([...selectedChoices].some(c => c.value === value)) {
      newSelectedChoices = selectedChoices.filter(c => c.value !== value);
    } else {
      newSelectedChoices = [...selectedChoices, choice];
    }

    if (
      newSelectedChoices.some(
        c => c.type === GreenhouseDemographicChoiceType.DeclineToAnswer
      )
    ) {
      onChange([choice]);
    } else {
      onChange(newSelectedChoices);
    }
  };

  return (
    <>
      {choices.map(({ label, value, type }) => {
        const idx = [...selectedChoices].findIndex(
          choice => choice.value === value
        );
        const checked = !(idx === -1);
        return (
          <Fragment key={value}>
            <Label>
              <input
                type="checkbox"
                value={value}
                checked={checked}
                onChange={() => handleChange(value)}
              />
              <LabelText>{label}</LabelText>
            </Label>
            {type === GreenhouseDemographicChoiceType.FreeForm && checked && (
              <Field
                name={`${name}[${idx}].label`}
                validate={composeValidators(required, maxLength(128))}
                render={({ input, meta }) => {
                  return (
                    <InputField
                      {...input}
                      type="text"
                      placeholder={`Type your answer here...`}
                      error={meta.touched && meta.error}
                      style={{ marginBottom: modularScale() }}
                    />
                  );
                }}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
}
