export function InfoIcon(props: JSX.IntrinsicAttributes): React.ReactElement {
  return (
    <svg
      {...props}
      width="18"
      height="17"
      viewBox="0 0 18 17"
      strokeWidth="0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99996 0.166626C4.39709 0.166626 0.666626 3.89709 0.666626 8.49996C0.666626 13.1028 4.39709 16.8333 8.99996 16.8333C13.6028 16.8333 17.3333 13.1028 17.3333 8.49996C17.3333 3.89709 13.6028 0.166626 8.99996 0.166626ZM8.99996 15.2708C5.26624 15.2708 2.22913 12.2334 2.22913 8.49996C2.22913 4.76656 5.26624 1.72913 8.99996 1.72913C12.7337 1.72913 15.7708 4.76656 15.7708 8.49996C15.7708 12.2334 12.7337 15.2708 8.99996 15.2708ZM10.302 11.1041H9.78121V8.23954C9.78121 7.80985 9.4329 7.45829 8.99996 7.45829H7.95829C7.5286 7.45829 7.17704 7.80985 7.17704 8.23954C7.17704 8.66923 7.5286 9.02079 7.95829 9.02079H8.21871V11.1041H7.69788C7.26819 11.1041 6.91663 11.4557 6.91663 11.8854C6.91663 12.3151 7.26819 12.6666 7.69788 12.6666H10.302C10.7334 12.6666 11.0833 12.3167 11.0833 11.8854C11.0833 11.4541 10.735 11.1041 10.302 11.1041ZM8.99996 6.41663C9.57515 6.41663 10.0416 5.95015 10.0416 5.37496C10.0416 4.79976 9.57515 4.33329 8.99996 4.33329C8.42476 4.33329 7.95829 4.79879 7.95829 5.37496C7.95829 5.95113 8.42379 6.41663 8.99996 6.41663Z"
        fill="black"
      />
    </svg>
  );
}
