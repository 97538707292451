import { ActionPanel } from "../../ActionPanel";
import { isJobDeckProps, isPublicJobNavProps } from "../helpers";
import { ArrowDirection, JobCardNavProps } from "../types";

import { BottomNavContainer } from "./styledJobCardComponents";
import { KeyboardHint } from "./KeyboardHint";
import { JobDeckArrows } from "./JobDeckArrows";
import { NavArrow } from "./JobDeckArrows/NavArrow";

export const JobCardNav = (props: JobCardNavProps): React.ReactElement => {
  const {
    isPreview,
    showApplyButton,
    onApply: handleApply,
    onSave: handleSave,
    onNext: handleNext,
    showKeyboardHints,
  } = props;

  const showJobDeckArrows = isJobDeckProps(props);
  const showPublicJobArrow = isPublicJobNavProps(props);
  const isSaved = isJobDeckProps(props) ? props.isSaved : false;
  const isApplied = isJobDeckProps(props) ? props.isApplied : false;

  return (
    <>
      {showJobDeckArrows && <JobDeckArrows {...props} />}
      {showPublicJobArrow && !isPreview && (
        <NavArrow
          direction={ArrowDirection.Right}
          onClick={handleNext}
          testId="next-button"
        />
      )}
      {!isPreview && (
        <BottomNavContainer data-testid="bottom-nav-bar">
          <ActionPanel
            onApply={handleApply}
            onSave={handleSave}
            isSaved={isSaved}
            isApplied={isApplied}
            showApplyButton={showApplyButton}
          />
          {showKeyboardHints && <KeyboardHint />}
        </BottomNavContainer>
      )}
    </>
  );
};
