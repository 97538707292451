import { useMutation } from "@apollo/client";
import { Field, Form as FinalForm } from "react-final-form";

import { ErrorText, Spacing } from "@otta/design";
import { palette } from "@otta/design-tokens";
import {
  composeValidators,
  minLength,
  required,
} from "@otta/search/utils/validators";
import {
  UpdateCompanyEmployeeSurveyCategoryResponseDocument,
  CompanyEmployeeSurvey,
} from "@otta/search/schema";
import { Textarea } from "@otta/search/components/Input/Textarea";
import { StrengthBar } from "@otta/search/components/StrengthBar";

interface ICategoryResponseEditFieldProps {
  surveyExternalId: string;
  response: CompanyEmployeeSurvey.Responses;
  setNextEnabled: (enabled: boolean) => void;
}

const maxStrengthCharacters = 260;

export function CategoryResponseEditField({
  surveyExternalId,
  response: { id, content },
  setNextEnabled,
}: ICategoryResponseEditFieldProps): React.ReactElement {
  const [mutate] = useMutation(
    UpdateCompanyEmployeeSurveyCategoryResponseDocument
  );

  const onSubmit = (values: { content: string | null }) => {
    const contentInput = values?.content;
    if (!contentInput) {
      return;
    }
    mutate({
      variables: {
        id,
        surveyExternalId,
        content: contentInput,
      },
    });
  };

  const checkNextEnabled = (value?: string): undefined => {
    setNextEnabled((value ?? "").length > 140);
    return undefined;
  };

  return (
    <FinalForm
      initialValues={{ content }}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <Field<string | undefined>
            name="content"
            validate={composeValidators(
              checkNextEnabled,
              required,
              minLength(140)
            )}
            format={value =>
              value ? value.charAt(0).toUpperCase() + value.slice(1) : undefined
            }
            render={({ input, meta }) => (
              <Spacing>
                <StrengthBar
                  strengthOptions={[
                    {
                      label: "We recommend writing some more",
                      value: 54,
                      colour: palette.brand.red,
                    },
                    {
                      label: "You may need to include more detail",
                      value: 77,
                      colour: palette.brand.orange,
                    },
                    {
                      label: "Great length of answer",
                      value: 100,
                      colour: palette.extended.green.shade400,
                    },
                  ]}
                  currentStrength={Math.round(
                    ((input?.value?.length ?? 0) / maxStrengthCharacters) * 100
                  )}
                />
                <Textarea
                  {...input}
                  id="content"
                  onBlur={handleSubmit}
                  label="Detail"
                  placeholder="Enter more detail here..."
                  name="content"
                  autoFocus
                  minHeight="100px"
                />
                <ErrorText>{meta.touched && meta.error}</ErrorText>
              </Spacing>
            )}
          />
        </form>
      )}
    />
  );
}
