import styled, { css, down } from "@xstyled/styled-components";

import { TitleBulletPointList } from "../../../components/FormData";

import { Spacing, Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { UserLinksQuery } from "@otta/search/schema";

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${down(
    "tablet",
    css`
      align-items: flex-start;
    `
  )}
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkedinText = styled(Text)`
  overflow-wrap: anywhere;
`;

const SpacedRowWrapper = styled(RowWrapper)`
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const StyledIcon = styled(Icon)`
  padding: 0 6 xs xs;
`;

export function DisplayLinks({
  data,
}: {
  data: UserLinksQuery;
}): React.ReactElement | null {
  const user = data?.currentUser;

  if (!user) {
    return null;
  }

  const { linkedinUrl, websiteLinks } = user;
  const hasNoOtherLinks = websiteLinks.length === 0;

  return (
    <ColumnWrapper>
      <Spacing>
        <SpacedRowWrapper>
          <RowWrapper>
            <StyledIcon icon="linkedin" size={1} />
            <LinkedinText>
              <strong>LinkedIn:</strong> {linkedinUrl ?? "N/A"}
            </LinkedinText>
          </RowWrapper>
        </SpacedRowWrapper>
        <div>
          <Text>
            <strong>Other website links: </strong>
            {hasNoOtherLinks && " N/A"}
          </Text>
          <TitleBulletPointList
            values={websiteLinks.map(({ url, linkType }) => ({
              type: linkType,
              text: url,
            }))}
          />
        </div>
      </Spacing>
    </ColumnWrapper>
  );
}
