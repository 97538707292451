import { useCallback, useEffect, useRef, useState } from "react";
import { InView } from "react-intersection-observer";

import { Loading } from "@otta/search/components/Loading";

interface ILoadMoreCompanies {
  onMore(): Promise<void>;
}

export function LoadMoreCompanies({
  onMore,
}: ILoadMoreCompanies): React.ReactElement {
  const [loading, setLoading] = useState(false);

  const mounted = useRef(true);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  const handleInView = useCallback(async () => {
    try {
      setLoading(true);
      await onMore();
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [onMore]);

  return (
    <InView
      onChange={inView => {
        if (inView) {
          handleInView();
        }
      }}
    >
      {({ ref }) => (
        <div ref={ref} style={{ minHeight: "100px", width: "100%" }}>
          {loading ? <Loading /> : null}
        </div>
      )}
    </InView>
  );
}
