import styled from "@xstyled/styled-components";
import { Link } from "react-router-dom";

import { Spacing, Text, Button, Middle } from "@otta/design";
import { PageWrapper } from "@otta/search/components/PageWrapper";

const Content = styled.div`
  max-width: 390px;
  margin: 64px auto;
`;

export function NotFound(): React.ReactElement {
  return (
    <PageWrapper>
      <Content>
        <Spacing>
          <Text as="h2" bold size={2} align="center">
            Oops!
          </Text>
          <Text align="center">Something went wrong</Text>

          <Middle>
            <Button as={Link} to="/" level="primary" type="submit">
              Go back to home
            </Button>
          </Middle>
        </Spacing>
      </Content>
    </PageWrapper>
  );
}
