import { useMutation } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { ConversationMessages } from "./ConversationMessages";
import { MessagingLayout } from "./Layout";

import { ErrorText, Middle } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  ConversationMetaDocument,
  ConversationMessagesDocument,
  CreateCandidateConversationMessageDocument,
  UpdateCandidateMessageRequestDocument,
} from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";
import { useTabVisible } from "@otta/search/hooks/useTabVisible";
import { Redirect } from "@otta/search/router";

const POLL_INTERVAL = 60000;

export function Conversation(): React.ReactElement {
  const setShowSideMenu = useOutletContext<(s: boolean) => void>();
  const { conversationId } = useParams<"conversationId">();

  useEffect(() => {
    setShowSideMenu(false);
  }, [setShowSideMenu, conversationId]);

  const {
    data: metadata,
    loading: metadataLoading,
    error: metadataError,
  } = useQuery(ConversationMetaDocument, {
    variables: {
      id: conversationId as string,
    },
  });

  const {
    data: messagesData,
    loading: messagesLoading,
    error: messagesError,
    startPolling,
    stopPolling,
  } = useQuery(ConversationMessagesDocument, {
    variables: {
      id: conversationId as string,
    },
    fetchPolicy: "network-only",
    pollInterval: POLL_INTERVAL,
  });

  const visible = useTabVisible();

  useEffect(() => {
    if (visible) {
      startPolling(POLL_INTERVAL);
    } else {
      stopPolling();
    }
  }, [visible, startPolling, stopPolling]);

  const [sendMessage, { loading: messageSending }] = useMutation(
    CreateCandidateConversationMessageDocument,
    {
      onError: handleMutationError,
      refetchQueries: [
        {
          query: ConversationMessagesDocument,
          variables: { id: conversationId },
        },
      ],
    }
  );

  const [replyToMessageRequest] = useMutation(
    UpdateCandidateMessageRequestDocument,
    {
      onError: handleMutationError,
    }
  );

  const handleSubmit = useCallback(
    async ({ message: content }: { message: string }) => {
      await sendMessage({
        variables: { content, conversationId: conversationId as string },
      });
    },
    [conversationId, sendMessage]
  );

  const respondToRequest = useCallback(
    ({ accepted, message }: { accepted: boolean; message: string }) => {
      replyToMessageRequest({
        variables: {
          conversationId: conversationId as string,
          candidateResponse: message,
          accepted: accepted,
        },
        refetchQueries: () => [
          {
            query: ConversationMetaDocument,
            variables: { id: conversationId as string },
          },
          {
            query: ConversationMessagesDocument,
            variables: { id: conversationId as string },
          },
        ],
      });

      pushAnalyticsEvent({
        eventName: "Candidate Responded to Message Request",
        accepted,
      });
    },
    [conversationId, replyToMessageRequest]
  );

  const user = metadata?.currentUser;

  const conversation = metadata?.conversation;

  const job = conversation?.job;

  const messages = useMemo(
    () => messagesData?.conversation?.messages ?? [],
    [messagesData]
  );

  if (metadataLoading || messagesLoading) {
    return <Loading />;
  }

  if (metadataError || messagesError || !metadata || !messagesData) {
    return (
      <Middle>
        <ErrorText>
          Something went wrong loading the conversation! Try again later
        </ErrorText>
      </Middle>
    );
  }

  if (!user) {
    return <Redirect to="/logout" />;
  }

  if (!conversation || !job) {
    return <Redirect to="/messaging" />;
  }

  const responded =
    !!(conversation.sourced && conversation.hasAcceptedRequest !== null) ||
    !!conversation.jobApplication;

  return (
    <MessagingLayout
      job={job}
      inputDisabled={messageSending}
      onMessage={handleSubmit}
      onRequestResponse={respondToRequest}
      responded={responded}
      sourced={conversation.sourced}
      messagingDisabled={conversation.closed}
      openSideMenu={() => setShowSideMenu(true)}
    >
      <ConversationMessages
        userId={user.id}
        messages={messages}
        sourced={conversation.sourced}
        job={job}
      />
    </MessagingLayout>
  );
}
