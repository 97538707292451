import { Form } from "react-final-form";
import { useState, useMemo } from "react";
import deepEqual from "deep-equal";

import { FormState } from "../types";
import { EditQuestion } from "../EditQuestion";
import { DisplayQuestion } from "../DisplayQuestion";

import { SubmitWrapper } from "./shared";
import { matchQuestionsToAnswers } from "./utils";

import { Button, ErrorText, Spacing } from "@otta/design";
import {
  JobApplicationDataInput,
  LeverAnswerData,
  LeverAnswerInput,
  LeverApplicationQuestion,
  LeverQuestionData,
  LeverAnswersFragment,
} from "@otta/search/schema";
import { SectionHeader } from "@otta/search/pages/Profile/components/FormHeader";
import {
  Card,
  EditButton,
} from "@otta/search/pages/Profile/components/FormCard";
import { Pencil } from "@otta/search/components/Icons/Pencil";
import { Delete } from "@otta/search/components/Icons/Delete";
import { HeaderWrapper } from "@otta/search/pages/Profile/PublicProfile/components/Section";

type LeverFormState = FormState;

interface LeverFormProps {
  questionData: {
    questions: LeverQuestionData["questions"];
  };
  answerData?: {
    answers: LeverAnswerData["answers"];
  };
  onSubmit: (input: JobApplicationDataInput) => void;
}

export function LeverForm({
  questionData,
  answerData = { answers: [] },
  onSubmit,
}: LeverFormProps): React.ReactElement {
  const [isEditing, setIsEditing] = useState(false);

  const questions = questionData.questions;

  const answers = useMemo(
    () =>
      matchQuestionsToAnswers<
        LeverApplicationQuestion[],
        LeverAnswersFragment["answers"]
      >(questions, answerData.answers),
    [questions, answerData]
  );

  const initialValues = useMemo(
    () => ({
      answers,
    }),
    [answers]
  );

  const handleSubmit = async (input: LeverFormState) => {
    await onSubmit(formatMutationData(input));
    setIsEditing(false);
  };

  return (
    <Card
      clickable={!isEditing}
      onClick={isEditing ? undefined : () => setIsEditing(true)}
      data-testid="application-questions-card"
    >
      <Spacing>
        <HeaderWrapper>
          <SectionHeader bold size={1}>
            Application questions
          </SectionHeader>
          <EditButton
            data-testid="edit-button"
            onClick={() => setIsEditing(false)}
          >
            {isEditing ? <Delete /> : <Pencil id="edit-button" />}
          </EditButton>
        </HeaderWrapper>
        {isEditing ? (
          <Form<LeverFormState>
            onSubmit={handleSubmit}
            initialValues={initialValues}
            initialValuesEqual={(oldState, newState) =>
              deepEqual(oldState, newState)
            }
            render={({ handleSubmit, valid, submitFailed }) => (
              <form onSubmit={handleSubmit}>
                <Spacing>
                  {questions.map((q, idx) => (
                    <EditQuestion
                      key={q.atsId}
                      question={q}
                      fieldName={`answers[${idx}].value`}
                    />
                  ))}
                  <SubmitWrapper>
                    <ErrorText>
                      {!valid &&
                        submitFailed &&
                        "Some fields need your attention before you can save"}
                    </ErrorText>
                    <Button level="primary" onClick={handleSubmit}>
                      Save
                    </Button>
                  </SubmitWrapper>
                </Spacing>
              </form>
            )}
          />
        ) : (
          <Spacing>
            {questions.map((q, idx) => (
              <DisplayQuestion
                key={q.atsId}
                answer={answers[idx]}
                required={q.required}
              />
            ))}
          </Spacing>
        )}
      </Spacing>
    </Card>
  );
}

function formatMutationData({
  answers,
}: LeverFormState): JobApplicationDataInput {
  return {
    leverData: {
      answers: answers.reduce<LeverAnswerInput[]>((acc, answer) => {
        if (!answer.value) {
          return acc;
        }

        if (answer.questionType === "TextQuestion") {
          return [
            ...acc,
            {
              textAnswer: {
                questionData: answer.questionData,
                value: answer.value,
              },
            },
          ];
        }

        if (answer.questionType === "TextAreaQuestion") {
          return [
            ...acc,
            {
              textAreaAnswer: {
                questionData: answer.questionData,
                value: answer.value,
              },
            },
          ];
        }

        if (answer.questionType === "UrlQuestion") {
          return [
            ...acc,
            {
              urlAnswer: {
                questionData: answer.questionData,
                value: answer.value,
              },
            },
          ];
        }

        if (answer.questionType === "SingleSelectQuestion") {
          return [
            ...acc,
            {
              singleSelectAnswer: {
                questionData: answer.questionData,
                choice: answer.value,
              },
            },
          ];
        }

        if (answer.questionType === "MultiSelectQuestion") {
          return [
            ...acc,
            {
              multiSelectAnswer: {
                questionData: answer.questionData,
                choices: answer.value.map(choice => JSON.parse(choice)),
              },
            },
          ];
        }

        if (answer.questionType === "FileQuestion") {
          const fileAnswer =
            "file" in answer.value
              ? {
                  fileAnswer: {
                    questionData: answer.questionData,
                    name: answer.value.name,
                    file: answer.value.file,
                  },
                }
              : {
                  existingFileAnswer: {
                    questionData: answer.questionData,
                    name: answer.value.name,
                    url: answer.value.url,
                  },
                };

          return [...acc, fileAnswer];
        }
        return acc;
      }, []),
    },
  };
}
