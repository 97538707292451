import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Quiz } from "@otta/search/containers/Quiz";
import { SalaryInput as SalaryInputValue } from "@otta/search/containers/Quiz/questions/Salaries/SalaryInput";
import { SalaryInputCompany } from "@otta/search/containers/Quiz/questions/Salaries/SalaryInputCompany";
import { SalaryInputGender } from "@otta/search/containers/Quiz/questions/Salaries/SalaryInputGender";
import { SalaryInputJobExperience } from "@otta/search/containers/Quiz/questions/Salaries/SalaryInputJobExperience";
import { SalaryInputJobFunction } from "@otta/search/containers/Quiz/questions/Salaries/SalaryInputJobFunction";
import { SalaryInputJobSubFunction } from "@otta/search/containers/Quiz/questions/Salaries/SalaryInputJobSubFunction";
import { SalaryInputJobTitle } from "@otta/search/containers/Quiz/questions/Salaries/SalaryInputJobTitle";
import { SalaryInputLocation } from "@otta/search/containers/Quiz/questions/Salaries/SalaryInputLocation";
import { CurrentUserDocument } from "@otta/search/schema";

const salaryInputQuestions = [
  {
    title: "Where are you based?",
    component: SalaryInputLocation,
    path: "location",
  },
  {
    title: "What is your most recent employer?",
    component: SalaryInputCompany,
    path: "company",
  },
  {
    title: "What area of the business do you work in?",
    subtitle: "You can be more specific on the next question",
    component: SalaryInputJobFunction,
    path: "function",
  },
  {
    title: "Which of these best describes your most recent role?",
    component: SalaryInputJobSubFunction,
    path: "sub-function",
  },
  {
    title: "What is your most recent job title?",
    component: SalaryInputJobTitle,
    path: "job-title",
  },
  {
    title: "Which of these best describes your level in this role?",
    component: SalaryInputJobExperience,
    path: "experience-level",
  },
  {
    title: "What is your most recent salary?",
    component: SalaryInputValue,
    path: "salary",
  },
  {
    title: "Which gender do you identify with?",
    component: SalaryInputGender,
    path: "gender",
  },
];

export function SalaryInput(): React.ReactElement | null {
  const navigate = useNavigate();
  const location = useLocation();

  const initialState = useRef(
    location.state as { fromJobsCard?: string } | undefined
  );

  useEffect(() => {
    if (initialState.current?.fromJobsCard) {
      pushAnalyticsEvent({
        eventName: "Candidate Clicked",
        name: "Jobs Card Submit Salary Nudge",
      });
    }
  }, [initialState.current?.fromJobsCard]);

  const { data, loading } = useQuery(CurrentUserDocument, { ssr: true });

  if (loading) {
    return null;
  }

  const loggedIn = !!data?.currentUser;

  if (!loggedIn) {
    return null;
  }

  return (
    <Quiz
      analyticsName="submit-salary"
      questions={salaryInputQuestions}
      finishText="Finish"
      onFinish={() => {
        const redirectUrl = "/salaries/submit-data/thank-you";
        navigate(redirectUrl, {
          state: initialState.current,
        });
      }}
    />
  );
}
