type Props = {
  name: "purpose" | "creativity" | "wttj" | "redirect";
  className?: string;
  size: number;
};

function path(file: string): string {
  return `https://static.otta.com/wttj-illustrations/${file}`;
}

export function Illustration({
  name,
  size,
  className,
}: Props): React.ReactElement {
  return (
    <picture>
      <source
        type="image/avif"
        srcSet={`${path(`${name}_${size * 2}.avif`)} 2x, ${path(
          `${name}_${size}.avif`
        )}`}
      />
      <source
        type="image/webp"
        srcSet={`${path(`${name}_${size * 2}.webp`)} 2x, ${path(
          `${name}_${size}.webp`
        )}`}
      />
      <source
        type="image/png"
        srcSet={`${path(`${name}_${size * 2}.png`)} 2x, ${path(
          `${name}_${size}.png`
        )}`}
      />
      <img
        className={className}
        src={`${name}_${path(`${size}.png`)}`}
        width={size}
        alt=""
      />
    </picture>
  );
}
