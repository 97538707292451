interface AccountProps extends React.SVGAttributes<SVGSVGElement> {
  active?: boolean;
}

export function Account({
  active,
  ...props
}: AccountProps): React.ReactElement {
  return active ? (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5859 17.0078C16.7227 15.5078 15.1016 14.5 13.25 14.5H10.75C8.89844 14.5 7.27734 15.5078 6.41406 17.0078C7.78906 18.5391 9.78125 19.5 12 19.5C14.2188 19.5 16.2109 18.5352 17.5859 17.0078ZM22 12C22 17.5234 17.5234 22 12 22C6.47656 22 2 17.5234 2 12C2 6.47656 6.47656 2 12 2C17.5234 2 22 6.47656 22 12ZM12 12.625C13.5547 12.625 14.8125 11.3672 14.8125 9.8125C14.8125 8.25781 13.5547 7 12 7C10.4453 7 9.1875 8.25781 9.1875 9.8125C9.1875 11.3672 10.4453 12.625 12 12.625Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 6.375C10.1016 6.375 8.5625 7.91406 8.5625 9.8125C8.5625 11.7109 10.1016 13.25 12 13.25C13.8984 13.25 15.4375 11.7109 15.4375 9.8125C15.4375 7.91406 13.8984 6.375 12 6.375ZM12 11.375C11.1383 11.375 10.4375 10.6738 10.4375 9.8125C10.4375 8.94922 11.1367 8.25 12 8.25C12.8633 8.25 13.5625 8.95078 13.5625 9.8125C13.5625 10.6758 12.8633 11.375 12 11.375ZM12 2C6.47656 2 2 6.47656 2 12C2 17.5234 6.47656 22 12 22C17.5234 22 22 17.5234 22 12C22 6.47656 17.5234 2 12 2ZM12 20.125C10.1746 20.125 8.49375 19.5125 7.13672 18.4926C7.8125 17.1953 9.125 16.375 10.6016 16.375H13.402C14.8766 16.375 16.1875 17.1957 16.866 18.4926C15.5078 19.5117 13.8242 20.125 12 20.125ZM18.2578 17.1758C17.2031 15.5273 15.4062 14.5 13.3984 14.5H10.6016C8.59531 14.5 6.79883 15.5254 5.74219 17.175C4.57656 15.7695 3.875 13.9648 3.875 12C3.875 7.51953 7.51992 3.875 12 3.875C16.4801 3.875 20.125 7.51992 20.125 12C20.125 13.9648 19.4219 15.7695 18.2578 17.1758Z"
        fill="black"
      />
    </svg>
  );
}
