import { BulletPointSection } from "./BulletPointSection";

import { Job } from "@otta/search/schema";

interface JobInvolvesProps {
  job: Pick<Job.Fragment, "involvesBullets">;
}

export function JobInvolves({
  job: { involvesBullets },
}: JobInvolvesProps): React.ReactElement {
  const bullets = involvesBullets.map(b => ({ id: b.id, text: b.value }));

  return (
    <BulletPointSection
      title="What the job involves"
      bullets={bullets}
      bulletTestId="job-involves-bullet"
    />
  );
}
