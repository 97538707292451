import { useQuery } from "@otta/search/apollo";
import { EventProperties } from "@otta/search/containers/Quiz";
import {
  Location,
  TechnologyPreference,
  TotalRemainingJobsDocument,
  UserInput,
} from "@otta/search/schema";
import { partition } from "@otta/search/utils/collections";

function countTechnologyPreferences(
  prefs: UserInput
): Record<TechnologyPreference, number> {
  const result: Record<TechnologyPreference, number> = {
    [TechnologyPreference.VeryPositive]: 0,
    [TechnologyPreference.Positive]: 0,
    [TechnologyPreference.Negative]: 0,
  };
  const techs = prefs?.technologiesUsedPreferences ?? [];
  techs.forEach(pref => result[pref.technologyPreference]++);
  return result;
}

/**
 * Job experience levels are submitted as an object of booleans(!)
 * so we put all the keys associated with true into a list
 */
function findJobExperienceLevels(prefs: UserInput): string[] {
  return Object.entries(prefs.jobExperiencePreference ?? {})
    .filter(([, v]) => v)
    .map(([k]) => k);
}

type SplitLocations = {
  remote: readonly Location[];
  local: readonly Location[];
};

function splitLocations(prefs: UserInput): SplitLocations {
  const locs = (prefs.locationPreferences ?? []).map(p => p.location);
  const [local, remote] = partition(locs)(loc => loc.startsWith("REMOTE"));
  return { local, remote };
}

type IndustryPreferenceCounts = {
  positive: number;
  negative: number;
};

function countIndustryPreferences(prefs: UserInput): IndustryPreferenceCounts {
  const industries = prefs.sectorTagPreferences ?? [];
  const [negative, positive] = partition(industries)(p => p.preference);
  return { positive: positive.length, negative: negative.length };
}

// we have to use a promise to fetch jobs remaining
type PropertyCallback = () => Promise<EventProperties>;

/**
 * Hook to create event properties based on the results of the prefs quiz
 * that we can pass along to the quiz component so it can fetch them on demand
 */
export function useEventProperties(prefs?: UserInput): PropertyCallback {
  const count = useQuery(TotalRemainingJobsDocument, { skip: true });

  if (!prefs) {
    return () => Promise.resolve({});
  }

  return async () => {
    const locations = splitLocations(prefs);
    const industries = countIndustryPreferences(prefs);
    const techCounts = countTechnologyPreferences(prefs);
    const result = await count.refetch();
    const user = result?.data?.currentUser;
    return {
      totalJobsRemainingCount: user?.totalRemainingJobs ?? 0,
      locationPreferences: locations.local,
      locationPreferencesCount: locations.local.length,
      remoteLocationPreferences: locations.remote,
      remoteLocationPreferencesCount: locations.remote.length,
      functionCount: prefs.jobFunctionPreferences?.length ?? 0,
      subFunctionCount: prefs.jobSubFunctionPreferences?.length ?? 0,
      levelPreferences: findJobExperienceLevels(prefs),
      sizePreferencesCount: prefs.companySizePreferences?.length ?? 0,
      positiveIndustryPreferencesCount: industries.positive,
      negativeIndustryPreferencesCount: industries.negative,
      veryPositiveTechnologyPreferencesCount: techCounts["VERY_POSITIVE"],
      positiveTechnologyPreferencesCount: techCounts["POSITIVE"],
      negativeTechnologyPreferencesCount: techCounts["NEGATIVE"],
      salaryPreference: prefs?.minSalaryRequirement?.amount,
      salaryCurrency: prefs?.minSalaryRequirement?.currency,
    };
  };
}
