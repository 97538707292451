import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import styled from "@xstyled/styled-components";

import { Button, Spacing, Text } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import { evictDashboardJobsCache } from "@otta/search/pages/Dashboard/mutationUpdaterFn";
import {
  ApplyModalJob,
  JobDeckStatusDocument,
  LastUnconfirmedJobApplicationDocument,
  UpdateJobApplicationDocument,
  UserJobStatusDocument,
  UserTotalJobApplicationClicksDocument,
} from "@otta/search/schema";
import {
  getSalaryStatus,
  jobValueClassification,
} from "@otta/search/utils/analytics/jobProperties";
import { FooterContent } from "@otta/search/components/ApplyContent/styles";

const Footer = styled(FooterContent)`
  padding: lg 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    margin-left: md;
  }
`;

interface IDidYouApplyContentProps {
  token?: string;
  type: "JOB_DECK" | "DASHBOARD";
  job: ApplyModalJob.PublicJob;
  onClose: () => void;
}

export function DidYouApplyContent({
  token,
  type,
  job,
  onClose,
}: IDidYouApplyContentProps): React.ReactElement {
  const [updateJobApplication, { loading }] = useMutation(
    UpdateJobApplicationDocument,
    {
      context: { emailToken: token },
    }
  );

  const { data } = useQuery(UserTotalJobApplicationClicksDocument, {
    context: { emailToken: token },
  });

  const totalApplyConfirms =
    data?.currentUser?.totalJobApplicationConfirms ?? 0;

  const handleClick = (applied: boolean) => {
    const analyticsEventData = {
      jobValueClassification: jobValueClassification(job.function?.id),
      jobTitle: job.title,
      companyName: job.company.name,
      jobId: job.id,
      function: job.function?.value,
      subFunction: job.subFunction?.value,
      minYearsExperienceRequired: job.minYearsExperienceRequired,
      maxYearsExperience: job.maxYearsExperienceRequired,
      applyViaOtta: job.acceptsInternalApplications,
      technologiesUsed: (job.technologiesUsed ?? [])
        .map(({ value }) => value)
        .join(", "),
      salaryStatus: getSalaryStatus(job),
    };

    const loginStatus = token ? "email-token" : "logged-in";

    pushAnalyticsEvent({
      eventName: `Candidate Confirmed Job Application`,
      totalApplicationConfirmations: totalApplyConfirms + 1,
      loginStatus,
      applied,
      ...analyticsEventData,
      source: "Did You Apply Content",
      jobCardTab: location.pathname.includes("/company") ? "company" : "job",
    });

    if (applied) {
      updateJobApplication({
        variables: {
          input: {
            applied: true,
            internal: false,
            confirmed: true,
          },
          jobId: job.id,
        },
        refetchQueries: () => [
          { query: UserTotalJobApplicationClicksDocument },
          { query: LastUnconfirmedJobApplicationDocument },
          ...(type === "DASHBOARD"
            ? [
                {
                  query: UserJobStatusDocument,
                  variables: { externalId: job.externalId },
                },
              ]
            : [
                {
                  query: JobDeckStatusDocument,
                  variables: { externalId: job.externalId },
                },
              ]),
        ],
        update: type === "JOB_DECK" ? evictDashboardJobsCache : undefined,
      });
    } else {
      updateJobApplication({
        variables: {
          input: {
            applied: false,
            internal: false,
            confirmed: true,
          },
          jobId: job.id,
        },
        refetchQueries: () => [
          { query: LastUnconfirmedJobApplicationDocument },
        ],
      });
    }
    onClose();
  };

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Candidate Shown",
      pathname: location.pathname,
      name: `Did You Apply Modal`,
    });
  }, []);

  return (
    <Footer>
      <Spacing>
        <Text as="h4" bold align="center">
          Did you apply?
        </Text>
        <ButtonWrapper>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Button
                type="button"
                level="primary"
                onClick={() => handleClick(true)}
                data-testid="applied-button-yes"
              >
                Yes
              </Button>
              <Button
                type="button"
                level="secondary"
                onClick={() => handleClick(false)}
                data-testid="applied-button-no"
              >
                No
              </Button>
            </>
          )}
        </ButtonWrapper>
      </Spacing>
    </Footer>
  );
}
