import { FetchPolicy } from "@apollo/client";
import { useMemo } from "react";

import { BatchOptions } from "../../BatchOptions";

import { backToDashboard, NextStep, StepType, Suggestions } from "./NextStep";

import { useQuery } from "@otta/search/apollo";
import {
  BatchEndThemedBatchesDocument,
  BatchEndWithSuggestionsDocument,
  BatchEndRemainingJobsDocument,
  TopSectorDocument,
  BatchEndWithSuggestionsQuery,
  BatchEndRemainingJobsQuery,
  BatchEndThemedBatchesQuery,
  ThemeId,
  UserJobStatus,
} from "@otta/search/schema";
import { toQueryKey } from "@otta/search/constants/themedBatches";
import { Sector } from "@otta/search/components/BatchCard/theme";

/**
 * Given some batch options this hook will figure out
 * what we should do given that we've got to the end of the batch
 */
export function useDetermineNextStep(
  params: BatchOptions,
  token?: string,
  fetchPolicy: FetchPolicy = "network-only"
): NextStep | undefined {
  const sector = useTopSector(token);

  const { loading: themesLoading, data: themeData } = useQuery(
    BatchEndThemedBatchesDocument,
    {
      variables: { preferredSectorId: `${sector?.id}` },
      skip: sector === undefined,
      fetchPolicy,
      context: {
        emailToken: token,
      },
    }
  );

  const { loading: suggestionsLoading, data: suggestionData } = useQuery(
    BatchEndWithSuggestionsDocument,
    {
      variables: { ignoreSubfunctions: false },
      fetchPolicy,
      skip: params.type !== "none",
      context: { emailToken: token },
    }
  );

  const { loading: remainingLoading, data: remainingData } = useQuery(
    BatchEndRemainingJobsDocument,
    {
      skip:
        params.type !== "none" ||
        suggestionsLoading ||
        !!suggestionData?.currentUser?.totalRemainingJobs,
      variables: { ignoreSubfunctions: true },
      fetchPolicy,
      context: { emailToken: token },
    }
  );

  return useMemo(() => {
    if (
      themesLoading ||
      suggestionsLoading ||
      remainingLoading ||
      sector === undefined
    ) {
      return undefined;
    } else if (params.type == "none" && suggestionData && themeData) {
      return nextStepForAllMatches(
        themeData,
        sector,
        suggestionData,
        remainingData
      );
    } else if (
      (params.type == "theme" || params.type == "preferred") &&
      themeData?.currentUser
    ) {
      return nextStepForThemedBatch(
        themeData.currentUser,
        sector,
        params.theme
      );
    } else {
      return backToDashboard;
    }
  }, [
    params,
    sector,
    remainingData,
    remainingLoading,
    suggestionData,
    suggestionsLoading,
    themeData,
    themesLoading,
  ]);
}

const sectorFallback = {
  id: "38",
  value: "Sustainability",
};

/**
 * Find out the top sector for the current user.
 * This hopefully will hit the cache only if they came from the dashboard
 * We have to default to sustainability but this shouldn't happen often
 */
function useTopSector(token?: string): Sector | undefined {
  const { loading, data } = useQuery(TopSectorDocument, {
    context: { emailToken: token },
  });

  if (loading) {
    return undefined;
  }

  switch (data?.me?.__typename) {
    case "CurrentAdmin":
      return data.me.topSector ?? sectorFallback;
    case "CurrentCandidate":
      return data.me.topSector ?? sectorFallback;
    default:
      return sectorFallback;
  }
}

/**
 * Stick together suggestions and a first name if
 * the suggestions exist and aren't null
 */
function createSuggestions(
  user?: BatchEndWithSuggestionsQuery["currentUser"]
): Suggestions | undefined {
  const suggestions = user?.jobPreferenceSuggestions;
  return suggestions ? { suggestions, firstName: user?.firstName } : undefined;
}

/**
 * Decide what the next step should be if you're not viewing a themed batch:
 * If you've got jobs within your subfunction remaining we should get them
 * otherwise if there are jobs ignoring subfunctions we can suggest them
 */
function nextStepForAllMatches(
  themes: BatchEndThemedBatchesQuery,
  topSector: Sector,
  { currentUser: withSuggestions }: BatchEndWithSuggestionsQuery,
  ignoringSubfunctions?: BatchEndRemainingJobsQuery
): NextStep {
  const suggestions = createSuggestions(withSuggestions);

  if (withSuggestions?.totalRemainingJobs) {
    return {
      suggestions,
      ignoreSubfunctions: false,
      type: StepType.RefreshRecommendations,
      themedBatches: themes.currentUser,
      topSector,
    };
  } else if (ignoringSubfunctions?.currentUser?.totalRemainingJobs) {
    return {
      suggestions,
      ignoreSubfunctions: true,
      type: StepType.RefreshRecommendations,
      themedBatches: themes.currentUser,
      topSector,
    };
  } else {
    return {
      suggestions,
      type: StepType.GoBackToDashboard,
      hasSavedJobs: !!withSuggestions?.shortlistedJobs?.some(
        j => j.live && j.userJobStatusInfo.status === UserJobStatus.Saved
      ),
      themedBatches: themes.currentUser,
      topSector,
    };
  }
}

/**
 * Decide what the next step should be if you are viewing a themed batch,
 * if we've run out of jobs in the batch we take you to the dashboard
 * otherwise we replace the client-side cache with the next batch
 */
function nextStepForThemedBatch(
  user: BatchEndThemedBatchesQuery["currentUser"],
  topSector: Sector,
  themeId: ThemeId
): NextStep {
  const key = toQueryKey(themeId);

  if (user?.[key].length) {
    return {
      type: StepType.ReplaceThemedBatchCache,
      themedBatches: user,
      topSector,
      themeId,
    };
  } else {
    return {
      type: StepType.GoBackToDashboard,
      themedBatches: user,
      topSector,
      hasSavedJobs: !!user?.shortlistedJobs?.some(j => j.live),
    };
  }
}
