import styled, { up, css } from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";
import { OttaIconsId, Icon } from "@otta/icons";
import { Text } from "@otta/design";

const Tag = styled.div<{ color: string }>(
  ({ color }) => css`
    display: none;

    ${up(
      "desktop",
      css`
        align-items: center;
        background: ${color};
        display: flex;
        flex-direction: row;
        gap: xs;
        height: ${pxToRem(32)};
        justify-content: center;
        padding: sm 40;
        position: absolute;
        right: -${pxToRem(38)};
        top: ${pxToRem(38)};
        transform: rotate(45deg);
        width: ${pxToRem(183)};
        z-index: 1;
        transition: default;
      `
    )}
  `
);

export const SpotlightContentTag = ({
  text,
  color,
  icon,
}: {
  text: string;
  color: string;
  icon: OttaIconsId;
}) => (
  <Tag color={color} className="spotlight-content-tag">
    <Icon icon={icon} />
    <Text bold size={-2}>
      {text}
    </Text>
  </Tag>
);
