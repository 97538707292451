import { ReactElement, useState } from "react";

import { FormState, useSubmit } from "./submit";
import { ATSQuestionsV1 } from "./types";
import { EditQuestions } from "./EditQuestions";
import { ViewQuestions } from "./ViewQuestions";

import { useQuery } from "@otta/search/apollo";
import {
  JobQuestionDataDocument,
  JobQuestionDataQueryVariables,
} from "@otta/search/schema";
import { Loading } from "@otta/search/components/Loading";

type Props = JobQuestionDataQueryVariables;
type SectionProps = { sections: ATSQuestionsV1; jobId: string };

export const ApplicationQuestionsV3 = ({
  jobId,
}: Props): ReactElement | null => {
  const res = useQuery(JobQuestionDataDocument, { variables: { jobId } });
  const sections = res?.data?.publicJob?.atsQuestions;
  return sections ? <Sections sections={sections} jobId={jobId} /> : null;
};

/**
 * Render all the sections within a single card linearly + deal with sending them to the backend
 */
const Sections = ({ sections, jobId }: SectionProps): ReactElement => {
  const [isEditing, setIsEditing] = useState(false);
  const [submit, loading] = useSubmit(sections, jobId);

  const handleSubmit = (state: FormState) => {
    submit(state);
    setIsEditing(false);
  };

  if (loading) {
    return <Loading />;
  }

  return isEditing ? (
    <EditQuestions
      sections={sections}
      handleSubmit={handleSubmit}
      handleCancel={() => setIsEditing(false)}
    />
  ) : (
    <ViewQuestions sections={sections} handleClick={() => setIsEditing(true)} />
  );
};
