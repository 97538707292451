import { Heading, Spacing, Text } from "@otta/design";
import { modularScale } from "@otta/design-tokens";

interface OAuthErrorProps {
  error: string;
}

export function OAuthError({ error }: OAuthErrorProps): React.ReactElement {
  return (
    <div style={{ padding: modularScale() }}>
      <Spacing>
        <Heading bold size={5} align="center">
          Something went wrong!
        </Heading>
        <Text align="center">{error}</Text>
        <Text align="center" size={2}>
          We&apos;re unable to log you in at this time
        </Text>
      </Spacing>
    </div>
  );
}
