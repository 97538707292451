import { useContext } from "react";

import {
  IUserPreferencesContext,
  UserPreferencesContext,
} from "./UserPreferencesContext";

import { IEventContext, useEvent } from "@otta/search/providers/EventProvider";

export function useUserPreferences(): IUserPreferencesContext & IEventContext {
  const event = useEvent();
  const userPreferences = useContext(UserPreferencesContext);

  return {
    ...userPreferences,
    ...event,
  };
}
