export * from "./sort";

export function partition<T>(
  array: readonly T[]
): (f: (t: T, index: number) => boolean) => [readonly T[], readonly T[]] {
  return partition => {
    const lefts: T[] = [];
    const rights: T[] = [];
    for (const [index, el] of array.entries()) {
      (partition(el, index) ? rights : lefts).push(el);
    }
    return [lefts, rights];
  };
}

export function removeDuplicates<T>(array: T[], getValue: (el: T) => any): T[] {
  const seen = new Set<any>();
  const output: T[] = [];

  for (const el of array) {
    const val = getValue(el);
    if (!seen.has(val)) {
      output.push(el);
      seen.add(val);
    }
  }

  return output;
}

export function groupBy<T>(
  array: readonly T[],
  getKey: (el: T) => string | undefined
): Record<string, T[]> {
  return array.reduce((acc, el) => {
    const key = getKey(el);
    if (key === undefined) return acc;

    if (key in acc) {
      acc[key].push(el);
    } else {
      acc[key] = [el];
    }
    return acc;
  }, {} as Record<string, T[]>);
}
