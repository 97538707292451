import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { useQuery } from "@otta/search/apollo";
import { SectorTagsDocument, ThemeId } from "@otta/search/schema";

type SectorTag = {
  id: string;
  value?: string;
};

type NoArgThemes = Exclude<ThemeId, ThemeId.PreferredSector>;

export type BatchOptions =
  | { type: "preferred"; theme: ThemeId.PreferredSector; sector: SectorTag }
  | { type: "theme"; theme: NoArgThemes }
  | { type: "none" };

const slugs: Record<ThemeId, string> = {
  [ThemeId.HasSalaries]: "has-salaries",
  [ThemeId.ApplyViaOtta]: "apply-via-otta",
  [ThemeId.PreferredSector]: "preferred-sector",
  [ThemeId.FemaleFounders]: "female-leaders",
  [ThemeId.RecentlyFunded]: "recently-funded",
  [ThemeId.TechForGood]: "tech-for-good",
  [ThemeId.NewlyAdded]: "newly-added",
  [ThemeId.FavouriteTechnologies]: "favourite-technologies",
  [ThemeId.FullyRemote]: "fully-remote",
  [ThemeId.TakeAnotherLook]: "take-another-look",
};

function fromSlug(slug: string): ThemeId | null {
  const entries = Object.entries(slugs);
  return (entries.find(([, v]) => v == slug)?.[0] ?? null) as ThemeId | null;
}

export function toSlug(theme: ThemeId): string {
  return slugs[theme];
}

const batchNames: Record<NoArgThemes, string> = {
  [ThemeId.HasSalaries]: "Jobs with salaries",
  [ThemeId.ApplyViaOtta]: "Apply with your profile",
  [ThemeId.FemaleFounders]: "Led by women",
  [ThemeId.RecentlyFunded]: "Recently funded",
  [ThemeId.TechForGood]: "Tech for good",
  [ThemeId.NewlyAdded]: "Jobs added this week",
  [ThemeId.FavouriteTechnologies]: "Favourite technologies",
  [ThemeId.FullyRemote]: "Fully remote",
  [ThemeId.TakeAnotherLook]: "Take another look",
};

export function batchName(params: BatchOptions): string | null {
  switch (params.type) {
    case "preferred":
      return params.sector.value ? `${params.sector.value} companies` : null;
    case "theme":
      return batchNames[params.theme];
    case "none":
      return null;
    default:
      return null;
  }
}

export function getThemeId(param: BatchOptions): ThemeId | undefined {
  return param.type == "theme" || param.type == "preferred"
    ? param.theme
    : undefined;
}

export function analyticsFields(params: BatchOptions): Record<string, unknown> {
  switch (params.type) {
    case "preferred":
      return { themeId: toSlug(params.theme), sectorId: params.sector.id };
    case "theme":
      return { themeId: toSlug(params.theme) };
    default:
      return {};
  }
}

export function sectorTag(params: BatchOptions): SectorTag | undefined {
  switch (params.type) {
    case "preferred":
      return params.sector;
    default:
      return undefined;
  }
}

export function useBatchOptions(): BatchOptions | undefined {
  const [qs] = useSearchParams();

  const themeId = qs.get("theme");
  const rawSectorTagId = qs.get("sector-id");

  const sectorTagId =
    rawSectorTagId && rawSectorTagId.endsWith("/")
      ? rawSectorTagId.slice(0, -1)
      : rawSectorTagId;

  const { data: allTags } = useQuery(SectorTagsDocument, {
    skip: !sectorTagId,
  });

  const sectorTag = useMemo(
    () => allTags?.sectorTags.find(st => st.id === `${sectorTagId}`),
    [allTags?.sectorTags, sectorTagId]
  );

  const parsedTheme = useMemo(() => {
    if (sectorTagId) {
      return ThemeId.PreferredSector;
    }
    if (themeId) {
      return fromSlug(themeId);
    }
    return null;
  }, [sectorTagId, themeId]);

  const sector = useMemo<SectorTag | null>(
    () => (sectorTagId ? { id: sectorTagId, value: sectorTag?.value } : null),
    [sectorTag?.value, sectorTagId]
  );

  return useMemo<BatchOptions | undefined>(() => {
    if (parsedTheme === ThemeId.PreferredSector && sector) {
      return { type: "preferred", theme: ThemeId.PreferredSector, sector };
    } else if (parsedTheme && parsedTheme !== ThemeId.PreferredSector) {
      return { type: "theme", theme: parsedTheme };
    } else if (!themeId && !sectorTagId) {
      return { type: "none" };
    } else {
      return undefined;
    }
  }, [parsedTheme, sector, sectorTagId, themeId]);
}
