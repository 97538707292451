import { Question } from "./types";
import { TextField } from "./fields/TextField";
import { SingleSelectField } from "./fields/SingleSelectField";
import { SelectField } from "./fields/SelectField";
import { BooleanField } from "./fields/BooleanField";
import { DateTimeField } from "./fields/DateTimeField";
import { NumberField } from "./fields/NumberField";
import { FileUploadField } from "./fields/FileUploadField";
import { ConsentField } from "./fields/ConsentField";

type FieldProps<A> = { section: string; question: A };

export const SingleQuestion = ({
  section,
  question,
}: FieldProps<Question>): React.ReactElement => {
  const key = `${section}-${question.id}`;

  switch (question.__typename) {
    case "FileAtsQuestion":
      return <FileUploadField question={question} sectionId={section} />;
    case "NumberAtsQuestion":
      return <NumberField question={question} sectionId={section} />;
    case "DateTimeAtsQuestion":
      return <DateTimeField question={question} sectionId={section} />;
    case "BooleanAtsQuestion":
      return <BooleanField question={question} sectionId={section} />;
    case "ConsentAtsQuestion":
      return <ConsentField question={question} sectionId={section} />;
    case "TextAtsQuestion":
      return <TextField question={question} sectionId={section} />;
    case "SelectAtsQuestion":
      return question.options.some(q => q.freeText || q.allowMultiple) ? (
        <SelectField question={question} sectionId={section} key={key} />
      ) : (
        <SingleSelectField question={question} sectionId={section} key={key} />
      );
    default:
      return <></>;
  }
};
