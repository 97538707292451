import { IDashboardBannerNotification } from "..";

import { Text } from "@otta/design";
import { Notification } from "@otta/search/schema";
import { Link } from "@otta/search/components/Link";

const CreateAProfileContent: React.FC = () => (
  <Text>
    Upload your CV and get discovered by companies.{" "}
    <Link
      to="/profile"
      underline={true}
      data-testid="link-create-a-profile-notification"
      data-analytics-id="link-create-a-profile-notification"
    >
      Go to profile
    </Link>
    .
  </Text>
);

export const CreateAProfileNotification: IDashboardBannerNotification = {
  key: "create-a-profile-notification",
  name: Notification.CreateProfile,
  Content: CreateAProfileContent,
  level: "information",
};
