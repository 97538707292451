import { Fragment } from "react";
import styled from "@xstyled/styled-components";

import { Spacing } from "@otta/design";
import { Job } from "@otta/search/schema";
import { Steps } from "@otta/icons";

const RangeWrapper = styled.div`
  align-items: center;
  gap: sm;
  display: flex;
`;

interface ExperienceBarProps {
  job: Pick<
    Job.Fragment,
    | "internship"
    | "minYearsExperienceRequired"
    | "maxYearsExperienceRequired"
    | "locationPreferences"
  >;
}

export function ExperienceBar({
  job: { internship, minYearsExperienceRequired, maxYearsExperienceRequired },
}: ExperienceBarProps): React.ReactElement | null {
  if (
    internship ||
    typeof minYearsExperienceRequired !== "number" ||
    (typeof maxYearsExperienceRequired === "number" &&
      minYearsExperienceRequired > maxYearsExperienceRequired)
  ) {
    return null;
  }

  return (
    <Spacing>
      <RangeWrapper data-testid="experience-section">
        <Steps />
        <ExperienceRange
          minValue={minYearsExperienceRequired}
          maxValue={maxYearsExperienceRequired}
        />
      </RangeWrapper>
    </Spacing>
  );
}

function ExperienceRange({
  minValue,
  maxValue,
}: {
  minValue: number;
  maxValue: number | null;
}): React.ReactElement {
  const experienceLevels = [
    { label: "Expert", value: 9 },
    { label: "Senior", value: 5 },
    { label: "Mid", value: 3 },
    { label: "Junior", value: 1 },
    { label: "Entry", value: 0 },
  ];
  const minExperienceLevel = experienceLevels.flatMap(({ label, value }) =>
    minValue >= value ? [label] : []
  )[0];
  const maxExperienceLevel = experienceLevels.flatMap(({ label, value }) =>
    (maxValue ?? 9) >= value ? [label] : []
  )[0];

  const minValueIndex = experienceLevels.findIndex(
    e => e.label === minExperienceLevel
  );
  const maxValueIndex = experienceLevels.findIndex(
    e => e.label === maxExperienceLevel
  );

  const range: string[] = [];
  for (let i = minValueIndex; i >= maxValueIndex; i--) {
    range.push(experienceLevels[i].label);
  }

  return (
    <>
      {range.map((level, index) => (
        <Fragment key={level}>
          {index > 0 && index === range.length - 1 && "and "}
          {level}
          {range.length > 2 && index < range.length - 2 ? ", " : " "}
        </Fragment>
      ))}
      level
    </>
  );
}
