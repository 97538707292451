import styled from "@xstyled/styled-components";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { analyticsFields, BatchOptions } from "./BatchOptions";

import { Middle, Spacing, Text, Button } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Link } from "@otta/search/components/Link";

const Container = styled.div`
  padding-top: xxl;
`;

export function NoJobsLeft({
  params,
}: {
  params: BatchOptions;
}): React.ReactElement {
  const location = useLocation();

  useEffect(() => {
    const themeAnalytics = analyticsFields(params);

    pushAnalyticsEvent({
      eventName: "Candidate Shown No Jobs",
      pathname: location.pathname,
      ...(Object.keys(themeAnalytics).length === 0
        ? { themeId: "all-matches" }
        : themeAnalytics),
    });
  }, [params, location]);
  return (
    <Middle maxWidth={600}>
      <Container>
        <Spacing size={4}>
          <Text bold size={1}>
            There are no relevant jobs right now
          </Text>
          <Text>
            We add new jobs every day, so be sure to check back soon to see
            more. If you want to see more jobs now, try changing your
            preferences.
          </Text>
          <Link to="/preferences" underline={false}>
            <Button
              level="primary"
              data-analytics-id="change-preferences-button"
            >
              Change preferences
            </Button>
          </Link>
        </Spacing>
      </Container>
    </Middle>
  );
}
