import { useEffect } from "react";
import { Trans } from "react-i18next";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { Spacing, List } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { BulletPointBoxButton } from "@otta/search/components/Input/BulletPointBoxButton";
import { NotificationFrequency } from "@otta/search/schema";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

export function EmailNotifications({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const isPresent = useIsPresent();

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(true);
    }
  }, [setNextEnabled, isPresent]);

  const { preferencesData, updatePreferences } = useUserPreferences();

  if (!preferencesData) {
    return <Loading />;
  }

  const handleClick = (frequency: NotificationFrequency) => {
    updatePreferences({ jobEmailNotificationsFrequency: frequency });
  };

  return (
    <Spacing>
      <BulletPointBoxButton
        title="Daily"
        onClick={() => handleClick(NotificationFrequency.Daily)}
        selected={
          preferencesData.jobEmailNotificationsFrequency ===
          NotificationFrequency.Daily
        }
      >
        <List type="positive">
          <li>
            Apply to jobs soon after they are <Trans>advertised</Trans>. We
            typically see the most applications in the first 3 days
          </li>
          <li>Our most active users are emailed daily</li>
        </List>
      </BulletPointBoxButton>
      <BulletPointBoxButton
        title="Weekly"
        onClick={() => handleClick(NotificationFrequency.Weekly)}
        selected={
          preferencesData.jobEmailNotificationsFrequency ===
          NotificationFrequency.Weekly
        }
      >
        <List type="positive">
          <li>Get in the rhythm of checking the best new jobs every week</li>
        </List>
      </BulletPointBoxButton>
      <BulletPointBoxButton
        title="Never"
        onClick={() => handleClick(NotificationFrequency.Never)}
        selected={
          preferencesData.jobEmailNotificationsFrequency ===
          NotificationFrequency.Never
        }
      >
        <List type="positive">
          <li>I don&apos;t want to receive any emails about the latest jobs</li>
        </List>
      </BulletPointBoxButton>
    </Spacing>
  );
}
