import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";
import { FormApi } from "final-form";

import { DatesInput } from "../../components/DatesInput";
import { BulletPointList, HalfWidth } from "../../components/FormData";

import { InputField, Spacing } from "@otta/design";
import {
  composeValidators,
  maxLength,
  required,
} from "@otta/search/utils/validators";
import { BulletTextarea } from "@otta/search/components/BulletTextarea";
import { EducationExperiences } from "@otta/search/schema";

const ListWrapper = styled.div`
  padding: 0;
  margin: 0;
`;

export function EditingEducationExperience({
  form,
  data,
}: {
  form: FormApi<{
    __typename: "UserEducationExperience";
    id: string;
    fieldOfStudy: string | null;
    institutionName: string;
    educationType: string | null;
    grade: string | null;
    startDate: string | null;
    endDate: string | null;
    descriptions: string[];
  }>;
  data: EducationExperiences.EducationExperiences | null;
}): React.ReactElement {
  return (
    <Spacing>
      <Field
        name="institutionName"
        validate={required}
        render={({ input, meta }) => (
          <HalfWidth>
            <InputField
              {...input}
              type="text"
              label="Institution name"
              data-testid="institution-name-field"
              placeholder="Institution name"
              error={meta.touched && meta.error}
            />
          </HalfWidth>
        )}
      />
      <Field
        name="educationType"
        render={({ input, meta }) => (
          <HalfWidth>
            <InputField
              {...input}
              type="text"
              label="Education type"
              data-testid="education-type-field"
              placeholder="Undergraduate Degree"
              error={meta.touched && meta.error}
            />
          </HalfWidth>
        )}
      />
      <Field
        name="fieldOfStudy"
        validate={composeValidators(required, maxLength(255))}
        render={({ input, meta }) => (
          <HalfWidth>
            <InputField
              {...input}
              type="text"
              label="Field of study"
              data-testid="field-of-study-field"
              placeholder="Economics"
              error={meta.touched && meta.error}
            />
          </HalfWidth>
        )}
      />
      <Field
        name="grade"
        render={({ input, meta }) => (
          <HalfWidth>
            <InputField
              {...input}
              label="Grade"
              type="text"
              data-testid="grade-field"
              placeholder="2.1"
              error={meta.touched && meta.error}
            />
          </HalfWidth>
        )}
      />
      <HalfWidth>
        <DatesInput
          startDateName="startDate"
          endDateName="endDate"
          showEndDate={data?.endDate !== null}
          optional={true}
          checkboxLabel="I'm still studying here"
          clearEndDate={() => form.change("endDate", null)}
          setEndDateToOriginal={() => form.change("endDate", data?.endDate)}
        />
      </HalfWidth>
      <BulletTextarea
        label="Description"
        fieldName="descriptions"
        placeholders={[
          "Joined the Economics Society committee...",
          "Played in multiple sports teams, including...",
          "Awarded the academic prize for...",
        ]}
        data-cs-mask
      />
    </Spacing>
  );
}

export function DisplayEducationExperience({
  data,
}: {
  data: EducationExperiences.EducationExperiences;
}): React.ReactElement {
  const experience = data;
  return (
    <ListWrapper>
      {experience.descriptions.length > 0 && (
        <BulletPointList
          data-cs-mask
          values={experience.descriptions.map(({ value }) => value)}
        />
      )}
    </ListWrapper>
  );
}
