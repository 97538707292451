import { palette } from "@otta/design-tokens";

export function Lock(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg viewBox="0 0 46 46" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(-66.276 -76.408)">
        <g fillRule="evenodd">
          <circle
            cx="89.276"
            cy="99.408"
            r="23"
            fill="none"
            strokeWidth=".26458"
          />
          <ellipse
            transform="matrix(.97856 -.20597 .12763 .99182 0 0)"
            cx="76.362"
            cy="113.74"
            rx="7.4677"
            ry="9.079"
            fill={palette.grayscale.shade600}
            stopColor="#000000"
            strokeWidth=".1767"
          />
          <ellipse
            cx="89.24"
            cy="99.987"
            rx="4.6698"
            ry="9.1015"
            fill={palette.brand.grey}
            stopColor="#000000"
            strokeWidth=".1399"
          />
        </g>
        <rect
          x="78.038"
          y="96.109"
          width="22.406"
          height="13.742"
          ry="1.7609"
          fill={palette.grayscale.shade600}
          strokeWidth=".13194"
        />
      </g>
    </svg>
  );
}
