import styled from "@xstyled/styled-components";
import { useState, useRef, useCallback, useEffect } from "react";

import { Button, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

const Banner = styled.div`
  background: ${palette.brand.yellow};
  padding: lg;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const uri = `${import.meta.env.VITE_API_HOST}/graphql`;

const POLL_INTERVAL = 1000;

function ApolloIAPProvider({ children }: { children: React.ReactNode }) {
  const [authenticated, setAuthenticated] = useState(true);

  const refresherWindow = useRef<Window | null>();
  const timer = useRef<number>();

  const checkIAPSession = useCallback(
    () =>
      fetch(uri, {
        credentials: "include",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      }),
    []
  );

  useEffect(() => {
    checkIAPSession()
      .then(res => {
        if (res.status === 401) {
          setAuthenticated(false);
        }
      })
      .catch(() => {
        setAuthenticated(false);
      });
  }, [checkIAPSession]);

  const onReauthenticate = useCallback(() => {
    refresherWindow.current = window.open(
      `${uri}/?gcp-iap-mode=DO_SESSION_REFRESH`,
      "api",
      "width=600,height=400"
    );

    const tick = async () => {
      clearTimeout(timer.current);
      try {
        const res = await checkIAPSession();

        if (res.status === 401) {
          throw new Error("Unauthenticated");
        }
        refresherWindow.current?.close();
        setAuthenticated(true);
      } catch (error) {
        timer.current = window.setTimeout(tick, POLL_INTERVAL);
      }
    };

    timer.current = window.setTimeout(tick, POLL_INTERVAL);
  }, [checkIAPSession]);

  return (
    <>
      {!authenticated && (
        <Banner>
          <Text bold size={1}>
            You&apos;re disconnected from the API!
          </Text>
          <Button size="small" level="secondary" onClick={onReauthenticate}>
            Reauthenticate
          </Button>
        </Banner>
      )}
      {children}
    </>
  );
}

interface IAPRefresherProps {
  children: React.ReactNode;
}

export function IAPRefresher({
  children,
}: IAPRefresherProps): React.ReactElement {
  if (import.meta.env.MODE === "staging") {
    return <ApolloIAPProvider>{children}</ApolloIAPProvider>;
  }

  return <>{children}</>;
}
