import styled, { css, down, up } from "@xstyled/styled-components";
import { useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Navigation } from "./Navigation";
import { CompanyMission } from "./CompanyMission";
import { CompanyJobs, shouldDisplayCompanyJobs } from "./CompanyJobs";
import { MeetTheTeam } from "./MeetTheTeam";
import { CompanyGallery, shouldDisplayCompanyGallery } from "./CompanyGallery";
import { useViewedProfileEvent } from "./useViewedProfileEvent";

import { Card as BaseCard } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { shouldDisplayCompanyMission } from "@otta/search/components/CompanyMission";
import {
  DiversityInitiatives,
  shouldDisplayDiversityInitiatives,
} from "@otta/search/components/DiversityInitiatives";
import { Company } from "@otta/search/schema";
import {
  companyHasPeopleProgressing,
  Founders,
  PeopleProgressing,
  shouldDisplayPeopleAtCompany,
} from "@otta/search/components/PeopleAtCompany";
import {
  CompanyArticles,
  shouldDisplayCompanyArticles,
} from "@otta/search/components/CompanyArticles";
import {
  CompanyValues,
  shouldDisplayCompanyValues,
} from "@otta/search/components/CompanyValues";
import {
  CompanyAdditionalInfo,
  shouldDisplayCompanyAdditionalInfo,
} from "@otta/search/components/CompanyAdditionalInfo";
import {
  Insights,
  shouldDisplayInsights,
} from "@otta/search/components/Insights";
import {
  OttasTake,
  shouldDisplayOttasTake,
} from "@otta/search/components/OttasTake";
import {
  CompanyBenefitsList,
  shouldDisplayCompanyBenefitsList,
} from "@otta/search/components/CompanyBenefitsList";
import {
  CompanyFunding,
  shouldDisplayCompanyFunding,
} from "@otta/search/components/CompanyFunding";
import {
  CompanyPhotos,
  shouldDisplayCompanyPhotos,
} from "@otta/search/components/CompanyPhotos";
import {
  CompanyOffice,
  shouldDisplayCompanyOffice,
} from "@otta/search/components/Office";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  Endorsements,
  shouldDisplayEndorsements,
} from "@otta/search/components/Endorsements";
import { PeopleBreakdown } from "@otta/search/components/PeopleAtCompany/PeopleBreakdown";
import { useHasEBContent } from "@otta/search/hooks/useHasEBContent";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${up(
    "tablet",
    css`
      flex-direction: row-reverse;
      align-items: flex-start;
      gap: xl;
    `
  )}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  max-width: ${pxToRem(736)};
  overflow: hidden;

  ${up(
    "tablet",
    css`
      gap: xl;
    `
  )}
`;

const scrollMarginTop = css`
  scroll-margin-top: 3xl;

  ${up(
    "tablet",
    css`
      scroll-margin-top: xl;
    `
  )}
`;

const Card = styled(BaseCard)`
  ${scrollMarginTop};

  ${down(
    "tablet",
    css`
      border-radius: 0;
    `
  )}
`;

const EndorsementsCard = styled(Card)`
  padding-right: 0;
`;

const FoundersAndPeopleWrapper = styled(Content)`
  ${scrollMarginTop};
`;

type CompanyContentProps = {
  company: Company.Company;
};

export const CompanyContent = ({
  company,
}: CompanyContentProps): React.ReactElement => {
  useViewedProfileEvent(company);
  const hasEBContent = useHasEBContent();

  const navigate = useNavigate();
  const { search } = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Some sections don't have IDs, e.g: PeopleProgressing and Photos
    const elements = Array.from(contentRef.current?.children ?? []).filter(
      child => child.id !== ""
    ) as HTMLDivElement[];

    // Store the sections and if they are intersecting
    const sections = new Map<string, boolean>(
      elements.reduce(
        (acc, el) => acc.set(el.id, false),
        new Map<string, boolean>()
      )
    );
    let timeout: NodeJS.Timeout;
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          sections.set(entry.target.id, entry.isIntersecting);
        });

        // Timeout to prevent trigger when the user clicks on the navigation
        // otherwise the nav and the url will be freaking out
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          // Pick the first that intersects
          for (const [id, isIntersecting] of sections.entries()) {
            if (isIntersecting) {
              navigate(`${search}#${id}`, {
                replace: true,
              });
              break;
            }
          }
        }, 400);
      },
      {
        threshold: 0.5,
      }
    );
    elements.forEach(el => observer.observe(el));

    return () => {
      observer.disconnect();
    };
  }, [company, navigate, search]);

  const handleClickNavItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const target = e.currentTarget;
    const href = target.getAttribute("href") as string;

    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      name: "jump-to-section-navigation",
      section: href.replace("#", "").replace("-section", ""),
      page: "company-profile",
      pathname: window.location.pathname,
    });

    const section = document.querySelector(href);
    if (!section) return;

    e.preventDefault();
    section.scrollIntoView({
      behavior: "smooth",
    });

    navigate(`${search}${href}`, {
      replace: true,
    });
  };

  const sections: Record<string, { id: string; label: string }> =
    useMemo(() => {
      const sections = {
        companyMission: shouldDisplayCompanyMission(company) && {
          id: "company-mission-section",
          label: "Company mission",
        },
        meetTheTeam: hasEBContent &&
          !!company.teams?.length && {
            id: "meet-the-team-section",
            label: "Meet the team",
          },
        insights: shouldDisplayInsights(company) && {
          id: "insights-section",
          label: "Insights",
        },
        ottasTake: shouldDisplayOttasTake(company) && {
          id: "ottas-take-section",
          label: "Our take",
        },
        benefits: shouldDisplayCompanyBenefitsList(company) && {
          id: "benefits-section",
          label: "Benefits",
        },
        values: shouldDisplayCompanyValues(company) && {
          id: "values-section",
          label: "Values",
        },
        funding: shouldDisplayCompanyFunding(company) && {
          id: "funding-section",
          label: "Funding",
        },
        foundersAndPeople: shouldDisplayPeopleAtCompany(company) && {
          id: "founders-section",
          label: "Leadership and people",
        },
        diversityInitiatives: shouldDisplayDiversityInitiatives(company) && {
          id: "diversity-initiatives-section",
          label: "Diversity, equity and inclusion",
        },
        office: shouldDisplayCompanyOffice(company) && {
          id: "office-section",
          label: "Office",
        },
        articles: shouldDisplayCompanyArticles(company) && {
          id: "articles-section",
          label: "Articles",
        },
        additionalInfo: shouldDisplayCompanyAdditionalInfo(company) && {
          id: "additional-info-section",
          label: "Additional Info",
        },
        endorsements: shouldDisplayEndorsements(company) && {
          id: "endorsements-section",
          label: "Endorsements",
        },
        jobs: shouldDisplayCompanyJobs(company) && {
          id: "jobs-section",
          label: "Jobs",
        },
        gallery: shouldDisplayCompanyGallery(company) && {
          id: "gallery-section",
          label: "Gallery",
        },
        photos: shouldDisplayCompanyPhotos(company) && {
          id: "photos-section",
          label: "Photos",
        },
      };

      return Object.fromEntries(
        Object.entries(sections).filter(([, isDisplayed]) => isDisplayed)
      ) as Record<string, { id: string; label: string }>;
    }, [hasEBContent, company]);

  return (
    <Wrapper>
      <Navigation
        onClickItem={handleClickNavItem}
        sections={Object.values(sections)}
      />
      <Content ref={contentRef}>
        {sections.companyMission && (
          <div id={sections.companyMission.id}>
            <CompanyMission company={company} />
          </div>
        )}
        {sections.meetTheTeam && (
          <Card id={sections.meetTheTeam.id}>
            <MeetTheTeam teams={company.teams} />
          </Card>
        )}
        {sections.insights && (
          <Card id={sections.insights.id}>
            <Insights company={company} />
          </Card>
        )}
        {sections.ottasTake && (
          <Card id={sections.ottasTake.id}>
            <OttasTake company={company} hasTitle />
          </Card>
        )}
        {sections.benefits && (
          <Card id={sections.benefits.id}>
            <CompanyBenefitsList company={company} />
          </Card>
        )}
        {sections.values && (
          <Card id={sections.values.id}>
            <CompanyValues company={company} />
          </Card>
        )}
        {sections.funding && (
          <Card id={sections.funding.id}>
            <CompanyFunding company={company} fromCompanyProfile />
          </Card>
        )}
        {sections.foundersAndPeople && (
          <FoundersAndPeopleWrapper id={sections.foundersAndPeople.id}>
            <Card>
              <Founders company={company} />
            </Card>
            {companyHasPeopleProgressing(company) && (
              <Card>
                <PeopleProgressing company={company} />
              </Card>
            )}
            {hasEBContent && !!company.ebStatistics && (
              <Card>
                <PeopleBreakdown company={company} />
              </Card>
            )}
          </FoundersAndPeopleWrapper>
        )}
        {sections.diversityInitiatives && (
          <Card id={sections.diversityInitiatives.id}>
            <DiversityInitiatives company={company} />
          </Card>
        )}
        {sections.office && (
          <Card id={sections.office.id}>
            <CompanyOffice company={company} />
          </Card>
        )}
        {sections.articles && (
          <Card id={sections.articles.id}>
            <CompanyArticles company={company} />
          </Card>
        )}
        {sections.additionalInfo && (
          <Card id={sections.additionalInfo.id}>
            <CompanyAdditionalInfo company={company} />
          </Card>
        )}
        {sections.endorsements && (
          <EndorsementsCard id={sections.endorsements.id}>
            <Endorsements company={company} />
          </EndorsementsCard>
        )}
        {sections.jobs && (
          <Card id={sections.jobs.id}>
            <CompanyJobs company={company} />
          </Card>
        )}
        {sections.gallery && (
          <Card id={sections.gallery.id}>
            <CompanyGallery company={company} />
          </Card>
        )}
        {sections.photos && (
          <Card id={sections.photos.id}>
            <CompanyPhotos company={company} fullWidth={false} />
          </Card>
        )}
      </Content>
    </Wrapper>
  );
};
