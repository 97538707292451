import { useParams } from "react-router-dom";

import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { MessageRequestConversationIdDocument } from "@otta/search/schema";
import { Redirect } from "@otta/search/router";

export function ConversationRedirect(): React.ReactElement {
  const { conversationId } = useParams<"conversationId">();

  return (
    <Redirect
      to={conversationId ? `/messaging/${conversationId}` : "/messaging"}
      status={301}
    />
  );
}

export function MessageRequestRedirect(): React.ReactElement {
  const { messageRequestId } = useParams<"messageRequestId">();

  const { data, loading } = useQuery(MessageRequestConversationIdDocument, {
    ssr: true,
    variables: {
      messageRequestId: messageRequestId as string,
    },
  });

  if (loading) {
    return <Loading />;
  }

  const conversationId = data?.messageRequestConversationId;

  return (
    <Redirect
      to={conversationId ? `/messaging/${conversationId}` : "/messaging"}
      status={301}
    />
  );
}
