import purify from "dompurify";
import { Form } from "react-final-form";
import { useState, useMemo } from "react";
import deepEqual from "deep-equal";

import { EditQuestion } from "../EditQuestion";
import { DisplayQuestion } from "../DisplayQuestion";
import {
  FormAnswer,
  FormGreenhouseDemographicAnswer,
  FormState,
} from "../types";
import { EditDemographicMultiSelectField } from "../fields/EditDemographicMultiSelectField";
import { EditDemographicSingleSelectField } from "../fields/EditDemographicSingleSelectField";

import {
  matchGreenhouseDemographicQuestionsToAnswers,
  matchQuestionsToAnswers,
} from "./utils";
import { SubmitWrapper } from "./shared";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";
import { Spacing, Tipbox, Button, Text, ErrorText } from "@otta/design";
import {
  CardTitle,
  SectionHeader,
} from "@otta/search/pages/Profile/components/FormHeader";
import {
  GreenhouseDemographicQuestion,
  JobApplicationDataInput,
  GreenhouseAnswer,
  GreenhouseApplicationQuestion,
  GreenhouseAnswerInput,
  GreenhouseDemographicAnswer,
  GreenhouseDemographicQuestionSet,
  GreenhouseDemographicAnswerInput,
  GreenhouseAnswersFragment,
} from "@otta/search/schema";
import { Pencil } from "@otta/search/components/Icons/Pencil";
import { Delete } from "@otta/search/components/Icons/Delete";
import {
  Card,
  EditButton,
} from "@otta/search/pages/Profile/components/FormCard";
import { HeaderWrapper } from "@otta/search/pages/Profile/PublicProfile/components/Section";

interface GreenhouseFormState extends FormState {
  demographicAnswers: FormGreenhouseDemographicAnswer[];
}

interface GreenhouseFormProps {
  questionData: {
    questions: GreenhouseApplicationQuestion[];
    demographicQuestionSet: GreenhouseDemographicQuestionSet | null;
  };
  answerData?: {
    answers: GreenhouseAnswer[];
    demographicAnswers: GreenhouseDemographicAnswer[];
  };
  onSubmit: (input: JobApplicationDataInput) => Promise<void>;
}

export function GreenhouseForm({
  questionData,
  answerData = { answers: [], demographicAnswers: [] },
  onSubmit,
}: GreenhouseFormProps): React.ReactElement {
  const [isEditing, setIsEditing] = useState(false);

  const { questions, demographicQuestionSet } = questionData;

  const answers = useMemo(
    () =>
      matchQuestionsToAnswers<
        GreenhouseApplicationQuestion[],
        GreenhouseAnswersFragment["answers"]
      >(questions, answerData.answers),
    [questions, answerData]
  );

  const demographicAnswers = useMemo(
    () =>
      matchGreenhouseDemographicQuestionsToAnswers(
        demographicQuestionSet?.questions ?? [],
        answerData.demographicAnswers
      ),
    [demographicQuestionSet, answerData]
  );

  const initialValues = useMemo(
    () => ({
      answers,
      demographicAnswers,
    }),
    [answers, demographicAnswers]
  );

  const handleSubmit = async (input: GreenhouseFormState) => {
    await onSubmit(formatMutationData(input));
    setIsEditing(false);
  };

  return (
    <Card
      clickable={!isEditing}
      onClick={isEditing ? undefined : () => setIsEditing(true)}
      data-testid="application-questions-card"
    >
      <Spacing>
        <HeaderWrapper>
          <SectionHeader bold size={1}>
            Application questions
          </SectionHeader>
          <EditButton
            data-testid="edit-button"
            onClick={() => setIsEditing(false)}
          >
            {isEditing ? <Delete /> : <Pencil id="edit-button" />}
          </EditButton>
        </HeaderWrapper>
        {isEditing ? (
          <Form<GreenhouseFormState>
            onSubmit={handleSubmit}
            initialValues={initialValues}
            initialValuesEqual={(oldState, newState) =>
              deepEqual(oldState, newState)
            }
            render={({ handleSubmit, valid, submitFailed }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Spacing>
                    {questions.map((q, index) => (
                      <EditQuestion
                        key={q.atsId}
                        question={q}
                        fieldName={`answers[${index}].value`}
                        isGreenhouse
                      />
                    ))}
                    {demographicQuestionSet && (
                      <Spacing>
                        <DemographicHeader
                          demographicQuestionSet={demographicQuestionSet}
                        />
                        {demographicQuestionSet.questions.map(
                          (question: GreenhouseDemographicQuestion, index) => (
                            <EditDemographicQuestion
                              key={question.atsId}
                              fieldName={`demographicAnswers[${index}].value`}
                              question={question}
                            />
                          )
                        )}
                      </Spacing>
                    )}
                    <SubmitWrapper>
                      <ErrorText>
                        {!valid &&
                          submitFailed &&
                          "Some fields need your attention before you can save"}
                      </ErrorText>
                      <Button level="primary" onClick={handleSubmit}>
                        Save
                      </Button>
                    </SubmitWrapper>
                  </Spacing>
                </form>
              );
            }}
          />
        ) : (
          <Spacing>
            {questions.map((q, idx) => (
              <DisplayQuestion
                key={q.atsId}
                answer={answers[idx]}
                required={!!q.required}
              />
            ))}
            {demographicQuestionSet && (
              <Spacing>
                <DemographicHeader
                  demographicQuestionSet={demographicQuestionSet}
                />
                {demographicQuestionSet.questions.map((q, idx) => (
                  <DisplayDemographicQuestion
                    key={q.atsId}
                    answer={demographicAnswers[idx]}
                    required={!!q.required}
                  />
                ))}
              </Spacing>
            )}
          </Spacing>
        )}
      </Spacing>
    </Card>
  );
}

function formatMutationData({
  answers,
  demographicAnswers,
}: GreenhouseFormState): JobApplicationDataInput {
  return {
    greenhouseData: {
      answers: formatAnswers(answers),
      demographicAnswers: formatDemographicAnswers(demographicAnswers),
    },
  };
}

function formatAnswers(answers: FormAnswer[]) {
  return answers.reduce<GreenhouseAnswerInput[]>((acc, answer) => {
    if (!answer.value) {
      return acc;
    }

    if (answer.questionType === "TextQuestion") {
      return [
        ...acc,
        {
          textAnswer: {
            questionData: answer.questionData,
            value: answer.value,
          },
        },
      ];
    }

    if (answer.questionType === "TextAreaQuestion") {
      return [
        ...acc,
        {
          textAreaAnswer: {
            questionData: answer.questionData,
            value: answer.value,
          },
        },
      ];
    }

    if (answer.questionType === "SingleSelectQuestion") {
      return [
        ...acc,
        {
          singleSelectAnswer: {
            questionData: answer.questionData,
            choice: answer.value,
          },
        },
      ];
    }

    if (answer.questionType === "MultiSelectQuestion") {
      return [
        ...acc,
        {
          multiSelectAnswer: {
            questionData: answer.questionData,
            choices: answer.value.map(choice => JSON.parse(choice)),
          },
        },
      ];
    }

    if (answer.questionType === "FileQuestion") {
      const fileAnswer =
        "file" in answer.value
          ? {
              fileAnswer: {
                questionData: answer.questionData,
                name: answer.value.name,
                file: answer.value.file,
              },
            }
          : {
              existingFileAnswer: {
                questionData: answer.questionData,
                name: answer.value.name,
                url: answer.value.url,
              },
            };

      return [...acc, fileAnswer];
    }

    return acc;
  }, []);
}

function formatDemographicAnswers(
  demographicAnswers: FormGreenhouseDemographicAnswer[]
) {
  return demographicAnswers.reduce<GreenhouseDemographicAnswerInput[]>(
    (acc, answer) => {
      if (!answer.value) {
        return acc;
      }
      if (answer.questionType === "GreenhouseDemographicSingleSelectQuestion") {
        return [
          ...acc,
          {
            singleSelectAnswer: {
              questionData: answer.questionData,
              choice: answer.value,
            },
          },
        ];
      }
      if (answer.questionType === "GreenhouseDemographicMultiSelectQuestion") {
        return [
          ...acc,
          {
            multiSelectAnswer: {
              questionData: answer.questionData,
              choices: answer.value,
            },
          },
        ];
      }

      return acc;
    },
    []
  );
}

function DemographicHeader({
  demographicQuestionSet,
}: {
  demographicQuestionSet: GreenhouseDemographicQuestionSet;
}): React.ReactElement {
  return (
    <Spacing>
      <HeaderWrapper>
        <SectionHeader>{demographicQuestionSet.header}</SectionHeader>
      </HeaderWrapper>
      <Tipbox level="information">
        {
          <div
            style={{
              marginLeft: modularScale(-2),
              marginRight: modularScale(-2),
            }}
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(demographicQuestionSet.description, {
                FORBID_TAGS: ["span", "a"],
              }),
            }}
          />
        }
      </Tipbox>
    </Spacing>
  );
}

function EditDemographicQuestion({
  question,
  fieldName,
}: {
  fieldName: string;
  question: GreenhouseDemographicQuestion;
}): React.ReactElement {
  const questionText = question.question;

  const choices = question.choices.map(({ __typename, ...rest }) => rest);
  return (
    <>
      <HeaderWrapper style={{ marginBottom: pxToRem(8) }}>
        <CardTitle bold>
          {questionText}
          {!question.required && (
            <Text style={{ color: palette.grayscale.shade600 }}>
              (optional)
            </Text>
          )}
        </CardTitle>
      </HeaderWrapper>
      {question.__typename === "GreenhouseDemographicMultiSelectQuestion" ? (
        <EditDemographicMultiSelectField name={fieldName} choices={choices} />
      ) : (
        <EditDemographicSingleSelectField name={fieldName} choices={choices} />
      )}
    </>
  );
}

function DisplayDemographicQuestion({
  answer,
  required,
}: {
  answer: FormGreenhouseDemographicAnswer;
  required: boolean;
}): React.ReactElement {
  const { questionData } = answer;

  return (
    <>
      <HeaderWrapper style={{ marginBottom: pxToRem(8) }}>
        <CardTitle bold>
          {questionData.question}
          {!required && (
            <Text style={{ color: palette.grayscale.shade600 }}>
              (optional)
            </Text>
          )}
        </CardTitle>
      </HeaderWrapper>
      <Text>
        {answer.value
          ? formatDisplayValue(answer)
          : answer.questionType === "GreenhouseDemographicMultiSelectQuestion"
          ? "Check all that apply"
          : "Choose an option..."}
      </Text>
    </>
  );
}

function formatDisplayValue(answer: FormGreenhouseDemographicAnswer) {
  if (answer.value) {
    if (answer.questionType === "GreenhouseDemographicMultiSelectQuestion") {
      return answer.value.length > 0
        ? answer.value.map(({ label }) => label).join(", ")
        : "No options selected";
    } else {
      return answer?.value?.label;
    }
  }
}
