import styled from "@xstyled/styled-components";

import { Button, VerticalSpacing, Middle, Text, Modal } from "@otta/design";

const ModalContent = styled.div`
  padding: xl;
`;

interface IErrorModalProps {
  onClose: () => void;
  originalUrl: string;
  companyName: string;
}

interface IApplyDirectlyModalContentProps {
  onClick: () => void;
  companyName: string;
  originalUrl: string;
}

const headerContent = () => (
  <Text size={1} align="left" bold>
    We can&apos;t submit your application right now
  </Text>
);

const ApplyDirectlyModalContent = ({
  onClick,
  companyName,
  originalUrl,
}: IApplyDirectlyModalContentProps) => {
  return (
    <ModalContent>
      <VerticalSpacing>
        <Text>
          Sorry, something&apos;s gone wrong and we couldn&apos;t submit your
          application to {companyName}.
        </Text>
        <Text>
          You could try applying directly on their website - we&apos;ll keep the
          information you&apos;ve entered in this window so you can copy and
          paste it over.
        </Text>
        <Middle>
          <Button
            level="primary"
            onClick={() => {
              window.open(originalUrl, "_blank");
              onClick();
            }}
          >
            Apply on {companyName}&apos;s website
          </Button>
        </Middle>
      </VerticalSpacing>
    </ModalContent>
  );
};

export const ApplicationATSErrorModal = ({
  onClose,
  companyName,
  originalUrl,
}: IErrorModalProps): React.ReactElement => {
  return (
    <Modal
      open
      onOpenChange={o => {
        if (!o) {
          onClose();
        }
      }}
      headerContent={headerContent()}
      dismissable={false}
    >
      <ApplyDirectlyModalContent
        companyName={companyName}
        originalUrl={originalUrl}
        onClick={() => onClose()}
      />
    </Modal>
  );
};
