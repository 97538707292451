import styled from "@xstyled/styled-components";

import { Button, Modal, Spacing, Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const Container = styled.div`
  padding: lg;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const TiltedIcon = styled(Icon)`
  transform: rotate(-10deg);
`;

export const FollowedCompanyModal = ({
  handleClose,
  open,
  companyName,
}: {
  handleClose: () => void;
  open: boolean;
  companyName: string;
}) => {
  return (
    <Modal
      open={open}
      onOpenChange={handleClose}
      dismissable={true}
      style={{ width: pxToRem(454), padding: pxToRem(16) }}
    >
      <Container data-testid="followed-company-modal">
        <Spacing>
          <TiltedIcon size={4} icon="notification-off" />
          <>
            <Text bold align="center" size={2}>
              You&apos;re now following
            </Text>
            <Text bold align="center" size={2}>
              {companyName}
            </Text>
          </>

          <Text align="center">
            We will notify you when companies you follow post new jobs that
            match your search preferences.
          </Text>
          <Button level="primary" type="button" onClick={handleClose}>
            Continue
          </Button>
        </Spacing>
      </Container>
    </Modal>
  );
};
