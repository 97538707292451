import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";
import { Arrow } from "@otta/search/components/Icons/Arrow";

const CollapserContainer = styled.div<{ arrowUp: boolean }>`
  width: 18px;
  max-height: 18px;
  margin-top: 2px;
  vertical-align: middle;
  transition: default;
  ${({ arrowUp }) => arrowUp && "transform: rotate(180deg)"};

  svg {
    width: 18px;
    max-height: 18px;
    display: block;
  }
`;

export function Collapser({ isOpen }: { isOpen: boolean }): React.ReactElement {
  return (
    <CollapserContainer arrowUp={isOpen}>
      <Arrow colour={palette.brand.grey} />
    </CollapserContainer>
  );
}
