import queryString from "query-string";
import { Field, Form as FinalForm } from "react-final-form";
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { useEffect, useState } from "react";
import { useCookie } from "react-use";

import { EmailFrequencySlider } from "./components/EmailFrequencySlider";

import {
  Button as DefaultButton,
  ErrorText,
  InputField,
  Spacing,
  Text,
  PasswordField,
} from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  composeValidators,
  maxLength,
  minLength,
  required,
  validEmail,
} from "@otta/search/utils/validators";
import { SmallCheckbox as Checkbox } from "@otta/search/components/Input/SmallCheckbox";
import { Link } from "@otta/search/components/Link";
import { ExternalSignIn } from "@otta/search/components/ExternalSignIn";
import {
  CurrentUserDocument,
  NavBarStatusDocument,
  NotificationFrequency,
} from "@otta/search/schema";
import { Loading } from "@otta/search/components/Loading";

const Button = styled(DefaultButton)`
  width: 100%;
`;

export function SignUpFormFields({
  autoFocus,
  showEmailSlider,
  showTerms = true,
}: {
  autoFocus?: boolean;
  showEmailSlider?: boolean;
  showTerms?: boolean;
}): React.ReactElement {
  return (
    <Spacing size={-1}>
      <Field
        data-cs-mask
        name="firstName"
        validate={composeValidators(required, minLength(2))}
      >
        {({ input, meta }) => (
          <InputField
            data-cs-mask
            {...input}
            autoFocus={autoFocus}
            type="text"
            name="fname"
            label="First name"
            autoComplete="given-name"
            error={meta.touched && meta.error}
          />
        )}
      </Field>
      <Field
        data-cs-mask
        name="lastName"
        validate={composeValidators(required, minLength(2))}
      >
        {({ input, meta }) => (
          <InputField
            {...input}
            type="text"
            name="lname"
            label="Last name"
            autoComplete="family-name"
            error={meta.touched && meta.error}
          />
        )}
      </Field>
      <Field
        data-cs-mask
        name="email"
        validate={composeValidators(required, validEmail, minLength(5))}
      >
        {({ input, meta }) => (
          <InputField
            {...input}
            name="email"
            type="email"
            label="Email"
            autoComplete="username"
            error={meta.touched && meta.error}
          />
        )}
      </Field>
      <Field
        data-cs-mask
        name="password"
        validate={composeValidators(required, minLength(8), maxLength(200))}
      >
        {({ input, meta }) => (
          <PasswordField
            {...input}
            name="password"
            label="Password"
            requirements={[
              {
                text: "Enter at least 8 characters",
                validate: (input?: string) =>
                  input ? input.length >= 8 : false,
              },
              ...(input?.value?.length > 200
                ? [
                    {
                      text: "Enter at most 200 characters",
                      validate: () => false,
                    },
                  ]
                : []),
            ]}
            value={input.value}
            onChange={ev => input.onChange(ev)}
            error={meta.touched && meta.error}
            autoComplete="new-password"
          />
        )}
      </Field>
      {showEmailSlider && (
        <Field<NotificationFrequency>
          name="jobEmailNotificationsFrequency"
          initialValue={NotificationFrequency.Weekly}
        >
          {({ input }) => <EmailFrequencySlider {...input} />}
        </Field>
      )}
      <Field name="marketingConsent" type="checkbox" data-cs-mask>
        {({ input }) => (
          <Checkbox
            label="I'd like to receive emails relating to job search and updates about new features."
            {...input}
          />
        )}
      </Field>
      {showTerms && (
        <Text size={-1}>
          By signing up you agree to our{" "}
          <Link to="https://otta.com/privacy-policy">Privacy Policy</Link> and{" "}
          <Link to="https://otta.com/terms-and-conditions/candidates">
            Terms &amp; Conditions for Candidates
          </Link>
          .
        </Text>
      )}
    </Spacing>
  );
}

export function SignupForm({
  redirect: redirectOverride,
  ctaText = "Sign up",
  hideLoginLink = false,
  showEmailSlider = false,
  onSignupCallback,
}: {
  redirect?: string;
  ctaText?: string;
  hideLoginLink?: boolean;
  showEmailSlider?: boolean;
  onSignupCallback?: () => void;
}): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, refetch } = useQuery(CurrentUserDocument);
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<"CONFLICT" | boolean>(false);
  const [csrf] = useCookie(import.meta.env.VITE_CSRF_COOKIE);

  const queryParams = queryString.parse(location.search);
  const utmSource =
    (typeof queryParams.utm_source === "string" && queryParams.utm_source) ||
    (typeof queryParams.homepage_utm_source === "string" &&
      queryParams.homepage_utm_source) ||
    undefined;
  const gclid =
    (typeof queryParams.gclid === "string" && queryParams.gclid) ||
    (typeof queryParams.homepage_gclid === "string" &&
      queryParams.homepage_gclid) ||
    undefined;
  const redirect = redirectOverride ?? queryParams.redirect;

  useEffect(() => {
    if (!data?.currentUser?.id) {
      return;
    }
    pushAnalyticsEvent({ eventName: "candidate-sign-up", method: "website" });

    if (redirect && typeof redirect === "string") {
      navigate(redirect);
    } else {
      navigate("/initial-preferences");
    }
  }, [data, redirect, navigate]);

  const handleSignup = async (values: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    marketingConsent: boolean | undefined;
    jobEmailNotificationsFrequency: NotificationFrequency | undefined;
  }) => {
    setLoading(true);
    setError(false);

    try {
      const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await fetch(
        `${import.meta.env.VITE_API_HOST}/auth/signup`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            ...(csrf ? { "X-CSRF-Token": csrf } : {}),
          },
          body: JSON.stringify({
            ...values,
            gclid,
            utmSource,
            timezone,
          }),
        }
      );

      if (response.status === 409) {
        setLoading(false);
        return setError("CONFLICT");
      }

      if (!response.ok || response.status !== 201) {
        throw new Error("Unexpected response from api");
      }

      const { data } = await refetch();

      client.query({
        query: NavBarStatusDocument,
        fetchPolicy: "network-only",
      });

      if (!data.currentUser) {
        throw new Error("No user returned after signing up");
      }

      onSignupCallback && onSignupCallback();
    } catch (error) {
      Sentry.captureException(error);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <FinalForm
      onSubmit={handleSignup}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} data-testid="signup-form">
          <Spacing size={-1}>
            <SignUpFormFields autoFocus showEmailSlider={showEmailSlider} />
            {error === "CONFLICT" && (
              <ErrorText data-testid="signup-conflict-error">
                This email is already signed up, try another or{" "}
                <RouterLink
                  to={{ pathname: "/login", search: location.search }}
                  style={{ color: "inherit" }}
                >
                  sign in
                </RouterLink>
              </ErrorText>
            )}
            {error === true && (
              <ErrorText data-testid="signup-error">
                Something went wrong! Try again in a few seconds
              </ErrorText>
            )}
            <Spacing>
              <Button level="primary" type="submit" disabled={loading}>
                {loading ? <Loading /> : ctaText}
              </Button>
              <Spacing>
                <ExternalSignIn
                  action="Sign up"
                  utmSource={utmSource}
                  gclid={gclid}
                />
                {!hideLoginLink && (
                  <Link to={{ pathname: "/login", search: location.search }}>
                    I have an account
                  </Link>
                )}
              </Spacing>
            </Spacing>
          </Spacing>
        </form>
      )}
    />
  );
}
