import { QuestionComponentProps } from "../..";

import { SalariesMultiOptionField } from "./SalariesMultiOptionField";

import { useQuery } from "@otta/search/apollo";
import {
  SalaryPreferenceDocument,
  JobSubFunctionsDocument,
} from "@otta/search/schema";

interface ISalaryInputJobSubFunctionComponentProps
  extends QuestionComponentProps {
  options: { id: string; value: string }[];
  selectedOtherOption: boolean;
  skip: boolean;
}

function SalaryInputJobSubFunctionComponent({
  options,
  selectedOtherOption,
  skip,
  ...props
}: ISalaryInputJobSubFunctionComponentProps): React.ReactElement {
  return (
    <SalariesMultiOptionField
      fieldName="currentSubFunction"
      mutationFieldName="currentSubFunctionId"
      options={options}
      otherOption
      selectedOtherOption={selectedOtherOption}
      skip={skip}
      {...props}
    />
  );
}

export function SalaryInputJobSubFunction(
  props: QuestionComponentProps
): React.ReactElement {
  const { data: salaryPreferenceData } = useQuery(SalaryPreferenceDocument);

  const currentFunctionId =
    salaryPreferenceData?.salaryPreference?.currentFunction?.id ?? null;
  const selectedOtherOption =
    salaryPreferenceData?.salaryPreference?.currentJobTitle != null &&
    salaryPreferenceData?.salaryPreference?.currentSubFunction == null &&
    salaryPreferenceData?.salaryPreference?.currentFunction != null;

  const { data } = useQuery(JobSubFunctionsDocument, {
    variables: { functionId: currentFunctionId },
    skip: !currentFunctionId,
  });

  if (!currentFunctionId) {
    return (
      <SalaryInputJobSubFunctionComponent
        options={[]}
        selectedOtherOption={selectedOtherOption}
        skip={!currentFunctionId}
        {...props}
      />
    );
  }

  return (
    <SalaryInputJobSubFunctionComponent
      options={data?.jobSubFunctions ?? []}
      selectedOtherOption={selectedOtherOption}
      skip={!currentFunctionId}
      {...props}
    />
  );
}
