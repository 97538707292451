import { SalariesMultiOptionField } from "./SalariesMultiOptionField";

import { QuestionComponentProps } from "@otta/search/containers/Quiz";
import { AVAILABLE_CITIES } from "@otta/search/utils/locations";

export function SalaryInputLocation(
  props: QuestionComponentProps
): React.ReactElement {
  const options = AVAILABLE_CITIES.sort((a, b) =>
    a.label.localeCompare(b.label)
  ).map(({ label, value }) => ({
    id: value,
    value: label,
  }));

  return (
    <SalariesMultiOptionField
      fieldName="location"
      options={options}
      otherOption
      {...props}
    />
  );
}
