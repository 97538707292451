export function CheckCircle(): React.ReactElement {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="check-circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M 390.352 178.677 L 367.816 155.959 C 363.149 151.254 355.551 151.223 350.846 155.891 L 209.494 296.107 L 149.702 235.83 C 145.035 231.125 137.437 231.094 132.732 235.761 L 110.013 258.297 C 105.308 262.964 105.277 270.562 109.945 275.268 L 200.726 366.784 C 205.393 371.489 212.991 371.52 217.696 366.852 L 390.285 195.648 C 394.989 190.98 395.019 183.382 390.352 178.677 Z"
      />
    </svg>
  );
}
