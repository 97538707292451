import { createContext, ReactNode, useContext, useRef } from "react";

import { EventTarget } from "@otta/search/utils/EventTarget";

export interface IEventContext {
  dispatchEvent: (type: string) => boolean;
  addEventListener: (type: string, listener: (e: Event) => void) => () => void;
}

export const EventContext = createContext<IEventContext>({
  dispatchEvent: () => true,
  addEventListener: () => () => undefined,
});

interface EventProviderProps {
  children: ReactNode;
}

export function EventProvider({
  children,
}: EventProviderProps): React.ReactElement {
  const eventTarget = useRef(new EventTarget());

  const addEventListener = (type: string, listener: (e: Event) => void) => {
    eventTarget.current.addEventListener(type, listener);

    return () => {
      eventTarget.current.removeEventListener(type, listener);
    };
  };

  const dispatchEvent = (type: string) => {
    return eventTarget.current.dispatchEvent(
      new CustomEvent(type, { cancelable: true })
    );
  };

  return (
    <EventContext.Provider
      value={{
        addEventListener,
        dispatchEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
}

export function useEvent(): IEventContext {
  return useContext(EventContext);
}
