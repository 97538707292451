import {
  US_LOCATIONS,
  UK_LOCATIONS,
  EU_LOCATIONS,
  CANADA_LOCATIONS,
} from "@otta/search/constants/locations";
import { LocationPreference } from "@otta/search/schema";

export function hiringRegions(
  locationPreferences: LocationPreference[]
): Set<string> {
  return locationPreferences.reduce<Set<string>>((acc, pref) => {
    if (US_LOCATIONS.has(pref.location)) {
      acc.add("US");
    } else if (UK_LOCATIONS.has(pref.location)) {
      acc.add("UK");
    } else if (EU_LOCATIONS.has(pref.location)) {
      acc.add("EU");
    } else if (CANADA_LOCATIONS.has(pref.location)) {
      acc.add("CA");
    }

    return acc;
  }, new Set());
}
