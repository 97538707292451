import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

import { Text } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { RadioButtons } from "@otta/search/components/RadioButtons";
import {
  NotificationFrequency,
  UpdateUserNotificationPreferencesDocument,
} from "@otta/search/schema";

const frequencies = [
  {
    name: "daily",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Text align="left">Daily</Text>
        <Text size={-1} style={{ fontStyle: "italic" }}>
          &nbsp;(recommended)
        </Text>
      </div>
    ),
    value: NotificationFrequency.Daily,
  },
  { name: "weekly", label: "Weekly", value: NotificationFrequency.Weekly },
  { name: "never", label: "Never", value: NotificationFrequency.Never },
];

export function EmailNotificationPreferences({
  user,
}: {
  user:
    | { jobEmailNotificationsFrequency: NotificationFrequency }
    | null
    | undefined;
}): React.ReactElement {
  const currentFrequency = frequencies.find(
    ({ value }) => value === (user ? user.jobEmailNotificationsFrequency : null)
  );
  const [mutation] = useMutation(UpdateUserNotificationPreferencesDocument);

  const [qs] = useSearchParams();
  const notificationFrequency = qs.get("newUserEmailNotificationFrequency");

  useEffect(() => {
    if (typeof notificationFrequency === "string") {
      mutation({
        variables: {
          frequency: notificationFrequency as NotificationFrequency,
        },
      });
      pushAnalyticsEvent({
        eventName: "Candidate changed email frequency",
        source: "Email",
        from: currentFrequency?.value,
        to: notificationFrequency,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = ({
    value: frequency,
  }: {
    value: NotificationFrequency;
  }) => {
    mutation({
      variables: {
        frequency,
      },
    });
    pushAnalyticsEvent({
      eventName: "Candidate changed email frequency",
      source: "Web App",
      from: currentFrequency?.value,
      to: frequency,
    });
  };

  return (
    <RadioButtons
      value={currentFrequency?.value}
      options={frequencies}
      onChange={handleChange}
    />
  );
}
