import styled, { css, up } from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { Suggestion } from "../types";

import { palette, pxToRem } from "@otta/design-tokens";
import { Button, Paragraph, Spacing, Text, Toast } from "@otta/design";
import {
  AcceptJobPreferenceSuggestionDocument,
  DismissJobPreferenceSuggestionDocument,
} from "@otta/search/schema";
import { useVisibleTimer } from "@otta/search/hooks/useVisibleTimer";

const StyledToast = styled(Toast)`
  width: ${pxToRem(300)};

  ${up(
    "tablet",
    css`
      width: ${pxToRem(450)};
    `
  )}
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BottomBar = styled.div`
  display: flex;
  flex-flow: wrap;
  position: relative;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: md;
  width: 100%;

  ${up(
    "tablet",
    css`
      flex-direction: row;
    `
  )}
`;

function firstNameText(firstName?: string | null) {
  if (firstName) {
    return `${firstName}, you've`;
  }
  return "You've";
}

function SuggestionText({
  firstName,
  suggestion,
}: {
  firstName?: string | null;
  suggestion: Suggestion;
}): React.ReactElement | null {
  switch (suggestion.__typename) {
    case "DislikedSectorPreferenceSuggestion":
      return (
        <Paragraph>
          {firstNameText(firstName)} skipped lots of jobs at{" "}
          <strong>{suggestion.sectorTag.value}</strong> companies. Would you
          like to hide these moving forward?
        </Paragraph>
      );
    case "DislikedTechnologyPreferenceSuggestion":
      return (
        <Paragraph>
          {firstNameText(firstName)} skipped lots of jobs using{" "}
          <strong>{suggestion.technology.value}</strong>. Would you like to hide
          these moving forward?
        </Paragraph>
      );
    default:
      return null;
  }
}

interface PreferenceSuggestionsProps {
  firstName?: string | null;
  suggestion: Suggestion;
  idx: number;
  total: number;
  onFinish(): void;
}

export function PreferenceSuggestion({
  firstName,
  suggestion,
  total,
  idx,
  onFinish,
}: PreferenceSuggestionsProps): React.ReactElement {
  const [errorVisible, setErrorVisible] = useVisibleTimer(4000);
  const location = useLocation();
  const emailToken = (location.state as { emailToken?: string })?.emailToken;

  const [acceptSuggestion, acceptResult] = useMutation(
    AcceptJobPreferenceSuggestionDocument,
    {
      errorPolicy: "all",
      context: { emailToken },
    }
  );

  const [dismissSuggestion, dismissResult] = useMutation(
    DismissJobPreferenceSuggestionDocument,
    {
      errorPolicy: "all",
      context: { emailToken },
    }
  );

  const error = useMemo(
    () => Boolean(acceptResult.error || dismissResult.error),
    [acceptResult.error, dismissResult.error]
  );

  useEffect(() => {
    setErrorVisible(error);
  }, [error, setErrorVisible]);

  const loading = acceptResult.loading || dismissResult.loading;

  const handleAccept = useCallback(() => {
    if (!loading) {
      acceptSuggestion({ variables: { id: suggestion.id } });
      onFinish();
    }
  }, [acceptSuggestion, onFinish, loading, suggestion.id]);

  const handleDismiss = useCallback(() => {
    if (!loading) {
      dismissSuggestion({ variables: { id: suggestion.id } });
      onFinish();
    }
  }, [dismissSuggestion, onFinish, loading, suggestion.id]);

  return (
    <Spacing>
      {total > 1 && (
        <TopBar>
          <Text bold color={palette.grayscale.shade400}>
            {idx}/{total}
          </Text>
        </TopBar>
      )}

      <SuggestionText firstName={firstName} suggestion={suggestion} />

      <BottomBar>
        <StyledToast
          className={"update-preferences-error-toast"}
          position={"left"}
          isVisible={errorVisible}
          toastMessage={
            "Sorry, we couldn't update your preferences right now. You can try again another time."
          }
        >
          <ButtonsWrapper>
            <Button
              level="tertiary"
              disabled={loading}
              onClick={handleAccept}
              data-analytics-id="accept-preference-suggestion"
            >
              Hide these jobs
            </Button>
            <Button
              level="tertiary"
              onClick={handleDismiss}
              disabled={loading}
              data-analytics-id="dismiss-preference-suggestion"
            >
              Keep seeing these jobs
            </Button>
          </ButtonsWrapper>
        </StyledToast>
      </BottomBar>
    </Spacing>
  );
}
