import styled from "@xstyled/styled-components";

import { Text, Tooltip } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";

const TagWrapper = styled.div<{ colour: string }>`
  background-color: ${({ colour }) => colour && colour};
  border-radius: 4;
  padding: xxs sm;
  margin: sm;
`;

const TagText = styled(Text)<{ colour: string }>`
  color: ${({ colour }) => colour && colour};
  font-weight: 600;
  font-size: ${pxToRem(13)};
`;

export enum CompletionStatus {
  notStarted = "Not started",
  inProgress = "In progress",
  complete = "Complete",
}

interface TooltipProps {
  showTooltip?: boolean | undefined;
  tooltipContent?: string | undefined;
}

interface StatusProps extends TooltipProps {
  status: string;
}

export function CompletionStatusTag({
  status,
  showTooltip = false,
  tooltipContent,
}: StatusProps): React.ReactElement | null {
  switch (status) {
    case CompletionStatus.notStarted:
      return (
        <NotStartedTag
          showTooltip={showTooltip}
          tooltipContent={tooltipContent}
        />
      );
    case CompletionStatus.inProgress:
      return (
        <InProgressTag
          showTooltip={showTooltip}
          tooltipContent={tooltipContent}
        />
      );
    case CompletionStatus.complete:
      return <CompleteTag />;
    default:
      return null;
  }
}

function NotStartedTag({
  showTooltip,
  tooltipContent,
}: TooltipProps): React.ReactElement {
  return (
    <Tag
      text={CompletionStatus.notStarted}
      backgroundColour={palette.extended.red.shade200}
      textColour={palette.brand.black}
      showTooltip={showTooltip}
      tooltipContent={tooltipContent}
    />
  );
}

function InProgressTag({
  showTooltip,
  tooltipContent,
}: TooltipProps): React.ReactElement {
  return (
    <Tag
      text={CompletionStatus.inProgress}
      backgroundColour={palette.brand.yellow}
      textColour={palette.brand.black}
      showTooltip={showTooltip}
      tooltipContent={tooltipContent}
    />
  );
}

function CompleteTag(): React.ReactElement {
  return (
    <Tag
      text={CompletionStatus.complete}
      backgroundColour={palette.brand.green}
      textColour={palette.brand.black}
      showTooltip={false}
    />
  );
}

interface TagProps extends TooltipProps {
  text: string;
  backgroundColour: string;
  textColour: string;
}

function Tag({
  text,
  backgroundColour,
  textColour,
  showTooltip,
  tooltipContent,
}: TagProps): React.ReactElement {
  if (showTooltip && tooltipContent !== undefined) {
    return (
      <Tooltip content={<Text>{tooltipContent}</Text>}>
        <TagWrapper colour={backgroundColour}>
          <TagText colour={textColour}>{text}</TagText>
        </TagWrapper>
      </Tooltip>
    );
  }

  return (
    <TagWrapper colour={backgroundColour}>
      <TagText colour={textColour}>{text}</TagText>
    </TagWrapper>
  );
}
