import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { pushAnalyticsEvent } from "@otta/analytics";
import { Redirect } from "@otta/search/router";

export const JobsRedirect = (): React.ReactElement => {
  const { jobId: jobExternalId } = useParams<{ jobId: string }>();

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "User Redirected",
      from: "/dashboard/jobs/:jobId",
      to: "/jobs/:jobId",
    });
  }, []);

  return <Redirect to={`/jobs/${jobExternalId}`} />;
};
