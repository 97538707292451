import styled, { css, up } from "@xstyled/styled-components";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

import { Spacing, Paragraph, Button, Middle, Card, Text } from "@otta/design";
import { Link } from "@otta/search/components/Link";

const Wrapper = styled(Card)`
  padding: xxl lg;
  margin: 0 auto lg;
  background: transparent;

  ${up(
    "tablet",
    css`
      margin: 28 auto lg;
      background: rgba(255, 255, 255, 0.3);
    `
  )}
`;

const Gutter = styled.div`
  padding: 0 md 4xl md;
`;

export function SalaryInputThankYou(): React.ReactElement {
  const location = useLocation();

  const locationState = useRef(
    location.state as
      | { fromJobsCard?: string; ukLocations?: boolean }
      | undefined
  );

  return (
    <Spacing>
      <Middle maxWidth={490}>
        <Gutter>
          <Wrapper>
            <Spacing>
              <Text as="h3" size={1} bold>
                Thank you!
              </Text>
              <Paragraph>
                Submitting your salary helps to improve salary transparency for
                everyone.
              </Paragraph>
              {!locationState.current?.ukLocations ? (
                <Paragraph data-testid="no-benchmarks">
                  We&apos;ll let you know when salary benchmarks are available
                  in your area.
                </Paragraph>
              ) : null}
              <Button
                as={Link}
                level="primary"
                to={
                  locationState.current?.fromJobsCard
                    ? `/jobs/${locationState.current?.fromJobsCard}`
                    : "/jobs"
                }
                data-testid="back-to-jobs"
              >
                Return to jobs
              </Button>
              {locationState.current?.ukLocations ? (
                <Button
                  as={Link}
                  level="secondary"
                  to="/salaries"
                  data-testid="benchmarks"
                >
                  View salary benchmarks
                </Button>
              ) : null}
            </Spacing>
          </Wrapper>
        </Gutter>
      </Middle>
    </Spacing>
  );
}
