import styled from "@xstyled/styled-components";

import { Text, Spacing, Paragraph } from "@otta/design";
import { validLinkedinProfileUrl } from "@otta/search/utils/validators";
import { Link } from "@otta/search/components/Link";

const ProfileContainer = styled.div`
  display: flex;
`;

const ProfileTitle = styled(Text)`
  margin-left: sm;
`;

const UnderlinedLink = styled(Link)`
  text-decoration: underline;
  color: black;
`;

export function Profile({
  name,
  title,
  linkedIn,
  bio,
}: {
  name: string;
  title?: string;
  linkedIn: string | null;
  bio: string | null;
}): React.ReactElement {
  return (
    <div data-testid="profile">
      <Spacing size={-2}>
        <ProfileContainer>
          {linkedIn && validLinkedinProfileUrl(linkedIn) === undefined ? (
            <UnderlinedLink to={linkedIn}>
              <Text bold>{name}</Text>
            </UnderlinedLink>
          ) : (
            <Text bold>{name}</Text>
          )}
          <ProfileTitle as="span">{title && `(${title})`}</ProfileTitle>
        </ProfileContainer>
        {bio && bio !== "N/A" && <Paragraph>{bio}</Paragraph>}
      </Spacing>
    </div>
  );
}
