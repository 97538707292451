import styled from "@xstyled/styled-components";
import { useState } from "react";

import { Bullet } from "./Bullet";

import { Button, Spacing, Text } from "@otta/design";
import { LinkType } from "@otta/search/pages/Profile/sections/Links";

const BulletList = styled.div<{ isEmpty: boolean }>`
  padding: 0;
`;

const ErrorLabel = styled(Text)`
  color: red-600;
`;

interface IBullets {
  id?: string;
  value: LinkType[];
  onChange: (value: LinkType[]) => void;
  charLimit?: number;
  maxCount?: number;
  placeholders?: string[];
  error?: string | undefined;
}

export const LinkInput = ({
  id,
  onChange,
  maxCount,
  value: links,
  charLimit,
  placeholders = [],
  error,
}: IBullets): React.ReactElement => {
  const [selection, setSelection] = useState(-1);

  const addNewLink = () => {
    const newLinks = [...links, { url: "", linkType: "" }];

    setSelection(newLinks.length - 1);
    onChange(newLinks);
  };

  const updateLinkUrl =
    (index: number): React.ChangeEventHandler<HTMLTextAreaElement> =>
    e => {
      const currentLink = links[index];
      const newLinks = [
        ...links.slice(0, index),
        {
          url: e.target.value.replace(/[\r\n]/g, ""),
          linkType: currentLink.linkType,
        },
        ...links.slice(index + 1),
      ];

      onChange(newLinks);
    };

  const updateLinkType =
    (index: number): React.ChangeEventHandler<HTMLTextAreaElement> =>
    e => {
      const currentLink = links[index];
      const newLinks = [
        ...links.slice(0, index),
        { url: currentLink.url, linkType: e.target.value },
        ...links.slice(index + 1),
      ];

      onChange(newLinks);
    };

  const removeLink = (index: number) => () => {
    const newLinks = [...links.slice(0, index), ...links.slice(index + 1)];
    onChange(newLinks);
  };

  const isEmpty = !(links && links.length > 0);

  return (
    <BulletList isEmpty={isEmpty}>
      <Spacing>
        {!isEmpty && (
          <>
            {links.map((link, index) => (
              <Bullet
                id={id}
                link={link}
                index={index}
                key={`link-bullet-${index}`}
                onChangeUrl={updateLinkUrl(index)}
                onChangeType={updateLinkType(index)}
                onRemove={removeLink(index)}
                onSetSelection={setSelection}
                selected={selection === index}
                charLimit={charLimit}
                placeholder={
                  placeholders.length > 0
                    ? placeholders[index % placeholders.length]
                    : ""
                }
              />
            ))}
          </>
        )}
        {!!error && <ErrorLabel size={-1}>{error}</ErrorLabel>}
        <Button
          disabled={!!(maxCount && links.length >= maxCount)}
          type="button"
          onClick={addNewLink}
          level="secondary"
        >
          Add link
        </Button>
      </Spacing>
    </BulletList>
  );
};
