import styled, { up, css } from "@xstyled/styled-components";

import { InboxCard } from "./InboxCard";

import { Heading, Spacing } from "@otta/design";
import { Inbox } from "@otta/search/schema";
import { INBOX_WIDTH, INBOX_WIDTH_DESKTOP } from "@otta/search/globalConstants";
import { InsetScrollbar } from "@otta/search/components/Scrollbar";

const SideMenu = styled.div<{ showSideMenu: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
  padding: lg;
  transition: default;
  transition-property: transform;
  transform: translateX(${({ showSideMenu }) => (showSideMenu ? 0 : "-100%")});
  background-color: beige-200;

  @media (max-width: 500px) {
    max-width: 100%;
  }

  width: 100%;

  max-width: ${INBOX_WIDTH}px;
  ${up(
    "desktop",
    css`
      max-width: ${INBOX_WIDTH_DESKTOP}px;
      transform: translateX(0);
    `
  )}

  overflow-y: auto;
  overflow-x: hidden;
  ${InsetScrollbar}
`;

export type Conversation = Inbox.Conversations;

interface IInboxSidebarProps {
  showSideMenu: boolean;
  conversations: Conversation[];
  candidateId: string;
}

export function InboxSidebar({
  showSideMenu,
  conversations,
  candidateId,
}: IInboxSidebarProps): React.ReactElement | null {
  return (
    <SideMenu showSideMenu={showSideMenu} data-testid="inbox-side-menu">
      <Spacing>
        <Heading size={2} bold>
          Inbox
        </Heading>
        {conversations.map(conversation => (
          <InboxCard
            key={conversation.id}
            id={conversation.id}
            to={`${conversation.id}`}
            accepted={conversation.hasAcceptedRequest}
            sourced={conversation.sourced}
            candidateId={candidateId}
            job={conversation.job}
            latestMessage={conversation.latestMessage}
          />
        ))}
      </Spacing>
    </SideMenu>
  );
}
