import Dropzone, { DropzoneRootProps, FileRejection } from "react-dropzone";
import styled from "@xstyled/styled-components";
import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";

import { palette } from "@otta/design-tokens";
import { Button, ErrorText } from "@otta/design";
import { Spinner } from "@otta/search/components/Loading";
import { EmptyState } from "@otta/search/components/EmptyState";
import { IconButton } from "@otta/search/components/IconButton";
import { Delete } from "@otta/search/components/Icons/Delete";
import { Image } from "@otta/search/components/Icons/Image";
import { handleMutationError } from "@otta/search/utils/error";
import { UploadImageDocument, ImageCategory } from "@otta/search/schema";

const DropzoneContainer = styled.div<DropzoneRootProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 4px;
  border: 2px dashed ${props => getColor(props)};
  outline: none;
  transition: default;
`;

const ImageContainer = styled.div`
  margin: 12px;
  display: flex;
  align-items: stretch;
`;

const ImageList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 24px;
`;

const PreviewContainer = styled.div`
  flex-grow: 1;
`;

const PreviewActionTray = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 6px;
`;

const CenteredSpinner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const getColor = (props: DropzoneRootProps): string => {
  if (props.isDragAccept) {
    return palette.extended.green.shade100;
  }
  if (props.isDragReject) {
    return palette.extended.red.shade400;
  }
  if (props.isDragActive) {
    return palette.extended.blue.shade200;
  }
  return palette.grayscale.shade200;
};

interface IFile {
  path: string;
}
interface IImageUploadFieldProps {
  value: IFile[];
  renderPreview: (file: IFile, loading: boolean) => React.ReactNode;
  onChange: (files: IFile[]) => void;
  onBlur: React.FocusEventHandler<HTMLElement>;
  category: ImageCategory;
  fileCountMax: number;
  nounSingular: string;
  nounPlural: string;
  fileSizeMaxKb?: number;
  error?: boolean;
  "data-testid"?: string | null;
}

export function ImageUploadField({
  value,
  fileCountMax,
  nounSingular,
  nounPlural,
  renderPreview,
  error,
  fileSizeMaxKb = 5000,
  onBlur,
  onChange,
  category,
  "data-testid": dataTestId,
}: IImageUploadFieldProps): React.ReactElement {
  const [upload, { loading }] = useMutation(UploadImageDocument, {
    onError: handleMutationError,
  });

  const [errors, setErrors] = useState<string[]>([]);

  const removeFile = useCallback(
    (index: number) => {
      const newFiles = [...value.slice(0, index), ...value.slice(index + 1)];
      onChange(newFiles);
    },
    [onChange, value]
  );

  const uploadFile = useCallback(
    async (file: File) => {
      const { data } = await upload({
        variables: { file, category },
      });

      if (!data?.uploadImage?.path) {
        throw new Error("No image returned after upload");
      }

      return { path: data.uploadImage.path };
    },
    [category, upload]
  );

  const processRejections = useCallback(
    (fileRejections: FileRejection[]) => {
      setErrors([]);

      for (const rejection of fileRejections) {
        for (const error of rejection.errors) {
          if (error.code === "file-too-large") {
            setErrors(errors => [
              ...errors,
              `File ${rejection.file.name} is too large. Max size is ${fileSizeMaxKb}KB.`,
            ]);
          }
          if (error.code === "file-invalid-type") {
            setErrors(errors => [
              ...errors,
              `File ${rejection.file.name} is not an image.`,
            ]);
          }
        }
      }
    },
    [fileSizeMaxKb]
  );

  const processFiles = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      processRejections(fileRejections);

      const allowedNewFiles = fileCountMax - value.length;
      if (allowedNewFiles === 0) {
        return;
      }

      const newFiles = await Promise.all(
        acceptedFiles
          .slice(0, allowedNewFiles)
          .map(file => uploadFile(file).catch(() => null))
      );

      onChange([
        ...value,
        ...newFiles.filter((file): file is IFile => Boolean(file)),
      ]);
    },
    [fileCountMax, onChange, uploadFile, value, processRejections]
  );

  return (
    <Dropzone
      maxSize={fileSizeMaxKb * 1024}
      disabled={value.length >= fileCountMax}
      accept={{
        "image/png": [".png"],
        "image/jpg": [".jpg"],
        "image/jpeg": [".jpeg"],
      }}
      noClick
      onDrop={processFiles}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        open,
      }) => {
        let uploaderContent;
        function handleUploadClick(e: React.MouseEvent<HTMLElement>) {
          e.preventDefault();
          open();
        }

        if (loading) {
          uploaderContent = (
            <CenteredSpinner>
              <Spinner />
            </CenteredSpinner>
          );
        } else {
          if (value.length === 0) {
            uploaderContent = (
              <EmptyState
                data-test={`empty-state-${nounPlural}`}
                data-testid={`empty-state-${nounPlural}`}
                iconComponent={<Image fill={palette.brand.black} />}
                button={
                  <Button
                    type="button"
                    onClick={handleUploadClick}
                    data-testid="upload-button"
                    level="secondary"
                  >
                    Browse...
                  </Button>
                }
                message={`Drag or browse to upload ${
                  fileCountMax > 1 ? nounPlural : nounSingular
                }.`}
                error={!!error}
              />
            );
          } else {
            uploaderContent = (
              <ImageList>
                {value.map((image, index) => (
                  <ImageContainer
                    data-testid={dataTestId}
                    key={`container-${index}`}
                  >
                    <PreviewContainer data-testid="preview-image">
                      {renderPreview(image, loading)}
                    </PreviewContainer>
                    <PreviewActionTray data-testid="preview-image-action-tray">
                      <IconButton
                        colour={palette.extended.red.shade400}
                        hoverColour={palette.brand.red}
                        data-testid="remove-button"
                        onClick={() => removeFile(index)}
                      >
                        <Delete />
                      </IconButton>
                    </PreviewActionTray>
                  </ImageContainer>
                ))}
              </ImageList>
            );
          }
        }
        return (
          <div>
            <DropzoneContainer
              {...getRootProps({
                isDragActive,
                isDragAccept,
                isDragReject,
              })}
              onBlur={onBlur}
            >
              <input {...getInputProps()} />
              {uploaderContent}
              {value.length > 0 && value.length < fileCountMax && !loading && (
                <Button
                  onClick={handleUploadClick}
                  data-testid="upload-button"
                  level="secondary"
                  type="button"
                >
                  Browse...
                </Button>
              )}
            </DropzoneContainer>
            {errors.map((error, index) => (
              <p key={`error-${index}`}>
                <ErrorText>{error}</ErrorText>
              </p>
            ))}
          </div>
        );
      }}
    </Dropzone>
  );
}
