import { ReactElement } from "react";
import styled from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";

const Circle = styled.div`
  background-color: #ff9f14;
  border-radius: 100%;
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

function InfoIcon(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width={props.width || "24"}
      height={props.width || "24"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.47656 0 0 4.47656 0 10C0 15.5234 4.47656 20 10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0ZM10 18.125C5.51953 18.125 1.875 14.4801 1.875 10C1.875 5.51992 5.51953 1.875 10 1.875C14.4805 1.875 18.125 5.51992 18.125 10C18.125 14.4801 14.4805 18.125 10 18.125ZM10 11.875C10.5176 11.875 10.9375 11.4551 10.9375 10.9375V5.9375C10.9375 5.42188 10.5195 5 10 5C9.48047 5 9.0625 5.42188 9.0625 5.9375V10.9375C9.0625 11.457 9.48438 11.875 10 11.875ZM10 13.168C9.32187 13.168 8.77188 13.718 8.77188 14.3961C8.77344 15.0742 9.32031 15.625 10 15.625C10.6797 15.625 11.2281 15.075 11.2281 14.3969C11.2266 13.7188 10.6797 13.168 10 13.168Z"
        fill="white"
      />
    </svg>
  );
}

export function Error(): ReactElement {
  return (
    <Circle>
      <InfoIcon width={24} />
    </Circle>
  );
}
