import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { useSearchParams } from "react-router-dom";
import styled from "@xstyled/styled-components";

import {
  Button,
  ErrorText,
  Heading,
  InputField,
  Spacing,
  Text,
} from "@otta/design";
import { Icon } from "@otta/icons";
import { IconButton } from "@otta/search/components/IconButton";
import { ForgotPasswordDocument } from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";

const FormHeader = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
`;

interface MobileAppPasswordResetProps {
  onPrevious?: () => void;
}

export function MobileAppPasswordReset({
  onPrevious,
}: MobileAppPasswordResetProps): React.ReactElement {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string | undefined>();
  const [done, setDone] = useState(false);

  const [forgotPasswordMutation, { error }] = useMutation(
    ForgotPasswordDocument,
    {
      onCompleted: () => {
        setDone(true);
      },
      onError: handleMutationError,
    }
  );

  const handleReset = (values: { email: string }) => {
    setEmail(values.email);
    forgotPasswordMutation({ variables: values });
  };

  const openEmailApp = () => {
    const redirectUri = searchParams.get("redirect_uri");
    if (redirectUri) {
      const url = new URL(redirectUri);
      url.searchParams.set("error", "forgot_password");
      window.location.assign(url.toString());
    }
  };

  return (
    <Spacing size={5}>
      <FormHeader>
        <IconButton
          type="button"
          onClick={onPrevious}
          style={{ position: "absolute", left: 0, fontSize: "24px" }}
          colour="black"
        >
          <Icon icon="arrow-left" />
        </IconButton>
        <Heading size={1}>Forgot password</Heading>
      </FormHeader>

      {done ? (
        <Spacing size={2.25}>
          <Spacing>
            <Text>We&apos;ve sent an email to {email}.</Text>
            <Text>It has a link to reset your password.</Text>
          </Spacing>

          <Button
            level="primary"
            onClick={openEmailApp}
            style={{ width: "100%" }}
          >
            Open email app
          </Button>
        </Spacing>
      ) : (
        <Spacing>
          <Text>We&apos;ll email you a link to reset your password.</Text>
          <Form onSubmit={handleReset}>
            {({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit} autoComplete="on">
                <Spacing size={2.25}>
                  <Field name="email">
                    {({ input }) => (
                      <InputField
                        {...input}
                        type="email"
                        label="Email"
                        autoComplete="username"
                        data-testid="forgot-password-email-input"
                      />
                    )}
                  </Field>

                  <Button
                    level="primary"
                    type={values.email ? "submit" : "button"}
                    data-testid="login-button"
                    style={{ width: "100%" }}
                  >
                    {submitting ? "Sending..." : "Send email"}
                  </Button>
                  {error && <ErrorText>{error.message}</ErrorText>}
                </Spacing>
              </form>
            )}
          </Form>
        </Spacing>
      )}
    </Spacing>
  );
}
