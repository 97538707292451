import styled from "@xstyled/styled-components";
import { ActionMeta, Options } from "react-select";

import { Select } from "./Select";

import { Error as DefaultError, FieldWrapper } from ".";

import { Label, Spacing } from "@otta/design";

const Error = styled(DefaultError)`
  display: flex;
  position: relative;
  width: fit-content;
  top: auto;
  left: auto;
  margin-top: 8px;
`;

interface IMultiSelectFieldProps {
  id?: string;
  options: { label: string; value: string }[];
  selectedOptions: { label: string; value: string }[];
  onChange: (
    newValue: Options<{ label: string; value: string }>,
    actionMeta: ActionMeta<{ label: string; value: string }>
  ) => void;
  allLoading: boolean;
  currentLoading: boolean;
  placeholder?: string;
  invalid?: boolean;
  label?: string;
  relativePosition?: boolean;
}

export function MultiSelectField({
  id,
  options,
  selectedOptions,
  onChange,
  label,
  allLoading,
  currentLoading,
  placeholder = "Select...",
  invalid = false,
  relativePosition,
}: IMultiSelectFieldProps): React.ReactElement {
  return (
    <FieldWrapper>
      <Spacing size={-5}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <Select
          inputId={id}
          isMulti
          isLoading={allLoading || currentLoading}
          onChange={onChange}
          options={options}
          value={selectedOptions}
          placeholder={placeholder}
          relativePosition={relativePosition}
        />
        {invalid && <Error>Maximum limit reached</Error>}
      </Spacing>
    </FieldWrapper>
  );
}
