import styled from "@xstyled/styled-components";

import { Spacing, Text } from "@otta/design";
import { Tabs } from "@otta/search/components/Tabs";
import { CompanyFragment } from "@otta/search/schema";
import { TeamSize } from "@otta/search/components/MeetTheTeam/TeamSize";
import { MissionCard } from "@otta/search/components/MeetTheTeam/MissionCard";
import { TeamAssets } from "@otta/search/components/MeetTheTeam/TeamAssets";
import { ManagerPrompts } from "@otta/search/components/MeetTheTeam/ManagerPrompts";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useEBTracking } from "@otta/search/contexts/EBTracking";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: xl;
`;

export function MeetTheTeam({
  teams,
}: {
  teams: CompanyFragment["teams"];
}): React.ReactElement {
  const trackingData = useEBTracking();

  return (
    <Spacing size={1}>
      <Text as="h2" bold size={1} data-testid="meet-the-team">
        Meet the team
      </Text>
      <Tabs
        tabs={teams.map(team => ({
          id: team.id,
          title: team.name,
          content: <MeetOneTeam team={team} />,
        }))}
        onChange={(tab: string) => {
          const currentTeam = teams.find(t => t.id === tab);

          pushAnalyticsEvent({
            eventName: "Candidate Clicked Team Name",
            teamName: currentTeam?.name,
            teamId: currentTeam?.id,
            teamCount: teams.length,
            companyId: trackingData.companyId,
            loginStatus: trackingData.loginStatus ? "logged-in" : "logged-out",
          });
        }}
      ></Tabs>
    </Spacing>
  );
}

function MeetOneTeam({
  team,
}: {
  team: CompanyFragment["teams"][0];
}): React.ReactElement {
  return (
    <Wrapper>
      {!!team.size && <TeamSize size={team.size} />}
      <TeamAssets assets={team.brandAssetLinks} />
      {!!team.mission && <MissionCard>{team.mission}</MissionCard>}
      <ManagerPrompts team={team} />
    </Wrapper>
  );
}
