import { AnimatePresence } from "framer-motion";

import { OnboardingQuizThemedBatchScreen } from "@otta/search/components/LoadingScreens";
import { useBatchCardData } from "@otta/search/pages/Dashboard/ThemedBatches";
import { BatchCardData } from "@otta/search/pages/Dashboard/ThemedBatches/BatchCards";

export default function Onboarding(): React.ReactElement {
  const batchData: BatchCardData | "loading" | undefined = useBatchCardData();
  return (
    <AnimatePresence>
      <OnboardingQuizThemedBatchScreen batchData={batchData} />
    </AnimatePresence>
  );
}
