import {
  ApolloCache,
  FetchPolicy,
  FetchResult,
  gql,
  useApolloClient,
  useMutation,
} from "@apollo/client";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { pushAnalyticsEvent } from "@otta/analytics";
import { evictDashboardJobsCache } from "@otta/search/pages/Dashboard/mutationUpdaterFn";
import {
  BrandAssetType,
  CreateJobReactionDocument,
  CreateJobReactionMutation,
  JobDeck,
  JobRecommendations,
  JobRecommendationsDocument,
  TeamMemberRole,
  UserShortlistDocument,
} from "@otta/search/schema";
import {
  analyticsFields,
  BatchOptions,
  sectorTag,
} from "@otta/search/pages/Jobs/JobDeck/BatchOptions";
import { useQuery } from "@otta/search/apollo";
import { jobValueClassification } from "@otta/search/utils/analytics/jobProperties";
import { peopleBreakdownTracking } from "@otta/search/contexts/EBTracking";

function variables(params?: BatchOptions) {
  switch (params?.type) {
    case "theme":
      return {
        themeId: params.theme,
      };
    case "preferred":
      return {
        themeId: params.theme,
        preferredSectorId: `${params.sector.id}`,
      };
    default:
      return {};
  }
}

type RefreshOptions = {
  ignoreSubfunctions: boolean;
  fetchPolicy?: FetchPolicy;
};

interface IJobRecommendationsContext {
  loading: boolean;
  recommendations: { id: string; reacted: boolean | undefined }[];
  replace(recs: JobRecommendations.JobRecommendations[]): void;
  save(job: JobDeck.PublicJob): void;
  skip(job: { __typename: "Job"; id: string }): void;
  refresh(params: BatchOptions, refresh: RefreshOptions): Promise<void>;
}

const JobRecommendationsContext = createContext<IJobRecommendationsContext>({
  loading: true,
  recommendations: [],
  save() {
    return;
  },
  skip() {
    return;
  },
  replace() {
    return Promise.resolve();
  },
  refresh() {
    return Promise.resolve();
  },
});

export function JobRecommendationProvider({
  token,
  params,
  children,
}: {
  token?: string;
  params: BatchOptions;
  children: React.ReactNode;
}): React.ReactElement {
  const client = useApolloClient();

  const { data: userData } = useQuery(UserShortlistDocument);

  const shortlistLength = userData?.currentUser?.shortlistedJobs?.length ?? 0;

  const [loading, setLoading] = useState(true);

  const [recommendations, setRecommendations] = useState<
    JobRecommendations.JobRecommendations[]
  >([]);

  const manuallyRefreshed = useRef(false);

  const fetchRecommendations = useCallback(
    async (params: BatchOptions, refresh?: RefreshOptions) => {
      setLoading(true);

      const { data } = await client.query({
        query: JobRecommendationsDocument,
        fetchPolicy: refresh?.fetchPolicy,
        variables: {
          ...variables(params),
          ignoreSubfunctions: refresh?.ignoreSubfunctions ?? false,
        },
        errorPolicy: "all",
        context: {
          emailToken: token,
        },
      });

      setRecommendations(data.currentUser?.jobRecommendations ?? []);

      setLoading(false);
    },
    [client, token]
  );

  const refresh = useCallback(
    async (params: BatchOptions, refresh?: RefreshOptions) => {
      manuallyRefreshed.current = true;
      await fetchRecommendations(params, refresh);
    },
    [fetchRecommendations]
  );

  useEffect(() => {
    if (!manuallyRefreshed.current) {
      fetchRecommendations(params);
    } else {
      manuallyRefreshed.current = false;
    }
  }, [fetchRecommendations, params]);

  const loginStatus =
    !userData?.currentUser && token ? "email-token" : "logged-in";

  const [createJobReaction] = useMutation(CreateJobReactionDocument, {
    context: { emailToken: token },
    optimisticResponse(variables) {
      return {
        createJobReaction: {
          __typename: "JobReaction" as const,
          direction: variables.direction,
          reason: null,
          updatedAt: new Date().toISOString(),
          id: Math.ceil(Math.random() * 1000000).toString(),
        },
      };
    },
    refetchQueries: [
      { query: UserShortlistDocument, context: { emailToken: token } },
    ],
  });

  const updateCache = useCallback(
    (
      job: {
        __typename: "Job";
        id: string;
      },
      cache: ApolloCache<CreateJobReactionMutation>,
      mutationResult: FetchResult<CreateJobReactionMutation>
    ) => {
      const createdReaction = mutationResult?.data?.createJobReaction;

      if (!createdReaction) {
        return null;
      }

      setRecommendations(recs =>
        recs.map(r =>
          r.id === job.id
            ? { ...r, job: { ...r.job, latestReaction: createdReaction } }
            : r
        )
      );

      cache.writeFragment({
        id: cache.identify({ ...job }),
        fragment: gql`
          fragment JobLatestReaction on Job {
            latestReaction {
              id
              direction
            }
          }
        `,
        data: {
          latestReaction: createdReaction,
        },
      });

      if (userData?.currentUser && createdReaction.direction === false) {
        const cacheData = cache.readFragment<{
          shortlistedJobs: { id: string }[];
        }>({
          id: cache.identify(userData?.currentUser),
          fragment: gql`
            fragment ShortlistedJobs on User {
              shortlistedJobs {
                id
              }
            }
          `,
        });

        const newShortlistedJobs =
          cacheData?.shortlistedJobs.filter(j => j.id !== job.id) ?? [];

        cache.writeFragment({
          id: cache.identify(userData?.currentUser),
          fragment: gql`
            fragment ShortlistedJobs on User {
              shortlistedJobs {
                id
              }
            }
          `,
          data: {
            shortlistedJobs: newShortlistedJobs,
          },
        });
      } else {
        evictDashboardJobsCache(cache);
      }
    },
    [userData?.currentUser]
  );

  const save = useCallback(
    (job: JobDeck.PublicJob) => {
      createJobReaction({
        variables: {
          direction: true,
          jobId: job.id,
        },
        onCompleted(result) {
          if (result.createJobReaction) {
            const teamManager = job.teamMembers?.find(
              teamMember => teamMember.role === TeamMemberRole.Manager
            );

            const teamReports = job.teamMembers?.some(
              teamMember => teamMember.role === TeamMemberRole.Report
            );

            const videos = job.team?.brandAssetLinks
              .flatMap(link => link.companyBrandAsset)
              .filter(asset => asset.type === BrandAssetType.Vimeo)
              .map(asset => asset.category);

            const images = job.team?.brandAssetLinks
              .flatMap(link => link.companyBrandAsset)
              .filter(asset => asset.type === BrandAssetType.Image);

            const employerBrandingAnalyticsData = {
              companyId: job.company.id,
              jobFunction: job.function?.value,
              jobSubFunction: job.subFunction?.value,
              minYearsExperienceRequired: job.minYearsExperienceRequired,
              maxYearsExperience: job.maxYearsExperienceRequired,
              videos,
              officesCount: job.company.offices?.length ?? 0,
              teamCount: job.company.teams.length ?? 0,
              teamMission: !!job.team?.mission,
              teamSize: !!job.team?.size,
              yourTeamManager: !!teamManager,
              yourTeamReports: teamReports,
              managerPrompts: !!teamManager?.member.prompts?.length,
              peopleBreakdown: peopleBreakdownTracking(
                job.company.ebStatistics
              ),
              galleryPhotosCount: images?.length ?? 0,
            };

            pushAnalyticsEvent({
              eventName: "candidate-saved-job",
              event_id: `${result?.createJobReaction?.id}${result?.createJobReaction?.updatedAt}`,
              jobId: job.id,
              totalSavedJobs: shortlistLength + 1,
              pathname: location.pathname,
              sectorTagId: sectorTag(params)?.id,
              applyViaOtta: job.acceptsInternalApplications,
              onlyOnOtta: job.company.onlyOnOtta,
              jobCardTab: location.pathname.includes("/company")
                ? "company"
                : "job",
              loginStatus,
              jobValueClassification: jobValueClassification(job.function?.id),
              ...analyticsFields(params),
              ...employerBrandingAnalyticsData,
            });
          }
        },
        update(cache, result) {
          return updateCache(job, cache, result);
        },
      });
    },
    [createJobReaction, shortlistLength, params, loginStatus, updateCache]
  );

  const skip = useCallback(
    (job: { __typename: "Job"; id: string }) => {
      createJobReaction({
        variables: {
          direction: false,
          jobId: job.id,
        },
        update(cache, result) {
          return updateCache(job, cache, result);
        },
      });
    },
    [createJobReaction, updateCache]
  );

  const mappedRecommendations = useMemo(
    () =>
      recommendations.map(r => ({
        id: r.job.externalId,
        reacted: r.job.latestReaction?.direction,
      })),
    [recommendations]
  );

  return (
    <JobRecommendationsContext.Provider
      value={{
        loading,
        recommendations: mappedRecommendations,
        refresh,
        replace: setRecommendations,
        skip,
        save,
      }}
    >
      {children}
    </JobRecommendationsContext.Provider>
  );
}

export function useJobRecommendations(): IJobRecommendationsContext {
  return useContext(JobRecommendationsContext);
}
