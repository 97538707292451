import { useEffect } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { SelectField, Spacing, Text } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { SectorTagsDocument } from "@otta/search/schema";
import {
  IOption,
  mapRowToOptions,
  sortOptionsAlphabetically,
} from "@otta/search/utils/select";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

const LIMIT = 30;
const ADVISED_LIMIT = 10;

export function NegativeSectorTags({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const isPresent = useIsPresent();

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(true);
    }
  }, [setNextEnabled, isPresent]);

  const { preferencesData, updatePreferences } = useUserPreferences();

  const { data: sectorTagsData } = useQuery(SectorTagsDocument);

  if (!sectorTagsData || !preferencesData) {
    return <Loading />;
  }

  const preferences = preferencesData.sectorTagPreferences ?? [];

  const negativePreferencesSet = preferences
    .filter(pref => !pref.preference)
    .reduce<Set<string>>(
      (acc, pref) => acc.add(pref.sectorTagId as string),
      new Set()
    );

  const selectedOptions = sectorTagsData.sectorTags.filter(tag =>
    negativePreferencesSet.has(tag.id)
  );

  const handleChange = (options: readonly IOption[]) => {
    if (options.length > LIMIT) {
      return;
    }

    const negativeInputs = options.map(({ value }) => ({
      sectorTagId: value,
      preference: false,
    }));

    const positiveInputs = preferences
      .filter(
        pref =>
          pref.preference &&
          !negativeInputs.some(p => p.sectorTagId === pref.sectorTagId)
      )
      .map(({ sectorTagId }) => ({
        sectorTagId,
        preference: true,
      }));

    updatePreferences({
      sectorTagPreferences: [...negativeInputs, ...positiveInputs],
    });
  };

  return (
    <Spacing size={-1}>
      <SelectField<string, true>
        options={sortOptionsAlphabetically(
          mapRowToOptions(sectorTagsData.sectorTags)
        )}
        placeholder="Type industry here..."
        value={sortOptionsAlphabetically(mapRowToOptions(selectedOptions))}
        onChange={handleChange}
        isMulti
      />
      {selectedOptions.length >= ADVISED_LIMIT && (
        <Text align="left" size={-1}>
          Disliking too many industries could limit the amount of results you
          see
        </Text>
      )}
    </Spacing>
  );
}
