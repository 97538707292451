import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

import { BatchOptions, toSlug } from "../../BatchOptions";

import { NextStep, StepType } from "./NextStep";

import { ThemeId } from "@otta/search/schema";
import { useJobRecommendations } from "@otta/search/pages/Jobs/JobRecommendationProvider";
import { toQueryKey } from "@otta/search/constants/themedBatches";

export function useApplyNextStep(
  ns: NextStep | undefined,
  params: BatchOptions,
  token?: string
): () => Promise<void> {
  const navigate = useNavigate();

  const { refresh, replace } = useJobRecommendations();

  return useCallback(async () => {
    if (!ns) {
      return;
    }
    if (ns.type === StepType.RefreshRecommendations) {
      await refresh(params, {
        ignoreSubfunctions: ns.ignoreSubfunctions,
        fetchPolicy: "network-only",
      });
      navigate("/jobs", { state: { token } });
    } else if (
      ns.type === StepType.ReplaceThemedBatchCache &&
      ns.themeId === ThemeId.PreferredSector
    ) {
      replace(ns.themedBatches?.preferredSector ?? []);
      navigate(`/jobs?theme=preferred-sector&sector-id=${ns.topSector.id}/`, {
        state: { token },
      });
    } else if (ns.type === StepType.ReplaceThemedBatchCache) {
      replace(ns.themedBatches?.[toQueryKey(ns.themeId)] ?? []);
      navigate(`/jobs?theme=${toSlug(ns.themeId)}`, { state: { token } });
    } else {
      navigate("/");
    }
  }, [token, navigate, ns, params, refresh, replace]);
}
