import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";
import { Company } from "@otta/search/schema";

const StyledCompanyLogo = styled.img<{
  rounded?: boolean;
}>`
  object-fit: contain;
  width: 100%;
  height: 100%;

  border-radius: ${({ rounded }) => (rounded ? "4px" : "0px")};
`;

const CompanyLogoPlaceholder = styled.div<{
  backgroundColor?: string | null;
  fontSize?: string;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize ?? "40px"};
  user-select: none;
  border-radius: 4px;
  color: white;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : palette.brand.yellow};
`;

interface ICompanyLogoProps {
  company: Pick<Company, "name" | "primaryColour" | "faviconPath">;
  fontSize?: string;
  rounded?: boolean;
}

export default function CompanyLogo({
  company,
  fontSize,
  rounded,
}: ICompanyLogoProps): React.ReactElement {
  const { faviconPath, name, primaryColour } = company;
  return faviconPath ? (
    <StyledCompanyLogo
      rounded={rounded}
      src={faviconPath}
      alt={`${name} logo`}
    />
  ) : (
    <CompanyLogoPlaceholder fontSize={fontSize} backgroundColor={primaryColour}>
      {name.slice(0, 1)}
    </CompanyLogoPlaceholder>
  );
}
