import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { useSearchParams, Link } from "react-router-dom";

import { Button, Text, Middle, Spacing } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import {
  UpdateShortlistUserJobStatusDocument,
  CreateJobReactionDocument,
  UserJobStatus,
  UserJobStatusDocument,
} from "@otta/search/schema";
import { Confetti } from "@otta/search/components/Confetti";

const Header = styled.div`
  padding-top: 5xl;
  align-items: center;
`;

const TagLine = styled.div`
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: ${pxToRem(250)};
  margin: auto;
`;
export function InterviewResult(): React.ReactElement {
  const [qs] = useSearchParams();
  const token = qs.get("token");
  const jobId = qs.get("jobId");
  const received = qs.get("received");

  if (
    jobId !== undefined &&
    typeof jobId === "string" &&
    typeof token === "string"
  ) {
    return (
      <InterviewResultComponent
        jobId={jobId}
        token={token}
        received={received === "true"}
      />
    );
  }

  return (
    <div>
      <Middle>
        <Header>
          <Text bold align="center" size={2} as="h4">
            Oops, something went wrong!
          </Text>
        </Header>
        <div>
          <Button as={Link} to="/" level="tertiary" type="button">
            Return to your dashboard
          </Button>
        </div>
      </Middle>
    </div>
  );
}

function InterviewResultComponent({
  jobId,
  token,
  received,
}: {
  jobId: string;
  token: string;
  received: boolean;
}): React.ReactElement {
  const [showConfetti, setShowConfetti] = useState(false);
  const [
    updateJobStatusMutation,
    { loading: updateJobLoading, error: updateJobError },
  ] = useMutation(UpdateShortlistUserJobStatusDocument, {
    context: { emailToken: token },
    errorPolicy: "all",
  });

  const [
    createJobReactionMutation,
    { loading: createJobReactionLoading, error: createJobReactionError },
  ] = useMutation(CreateJobReactionDocument, {
    context: { emailToken: token },
    errorPolicy: "all",
  });

  const { data: userJobStatusData } = useQuery(UserJobStatusDocument, {
    context: { emailToken: token },
    variables: {
      externalId: jobId,
    },
  });

  useEffect(() => {
    const status =
      userJobStatusData?.publicJob?.userJobStatusInfo?.status ?? "";
    if (status !== "INTERVIEWING" && status !== "OFFER_ACCEPTED") {
      if (received) {
        updateJobStatusMutation({
          variables: {
            jobId: jobId,
            status: UserJobStatus.OfferReceived,
          },
        });
        setShowConfetti(true);
      } else {
        createJobReactionMutation({
          variables: { jobId: jobId, direction: false, reason: "no_offer" },
        });
      }
    }
  }, [
    updateJobStatusMutation,
    createJobReactionMutation,
    token,
    jobId,
    received,
    userJobStatusData,
  ]);

  if (updateJobLoading || createJobReactionLoading) {
    return <Loading />;
  }
  if (updateJobError || createJobReactionError) {
    return (
      <div>
        <Middle>
          <Header>
            <Text bold align="center" size={2} as="h4">
              Oops, something went wrong!
            </Text>
          </Header>
          <div>
            <Button as={Link} to="/" level="tertiary" type="button">
              Return to your dashboard
            </Button>
          </div>
        </Middle>
      </div>
    );
  }

  return (
    <div>
      <Middle maxWidth={590}>
        <Spacing size={0}>
          {received ? (
            <Spacing size={1}>
              <Header>
                <Text bold align="center" size={3} as="h3">
                  Congratulations!
                </Text>
              </Header>
              <TagLine>
                <Text align="center" as="p">
                  We&apos;re pleased we could play a part in your journey to
                  finding fulfilment.
                </Text>
              </TagLine>
              <OfferAccepted token={token} jobId={jobId} />
            </Spacing>
          ) : (
            <Spacing>
              <Header>
                <Text bold size={3} as="h3">
                  Thanks for letting us know!
                </Text>
              </Header>
              <TagLine>
                <Text align="center" as="p">
                  You can see new matches and keep track of the status of all
                  your applications in your dashboard.
                </Text>
              </TagLine>
            </Spacing>
          )}
          <Spacing>
            <Button as={Link} to="/" level="tertiary" type="button">
              Return to your dashboard
            </Button>
          </Spacing>
        </Spacing>

        <div>
          {showConfetti && (
            <Confetti
              onComplete={() => setShowConfetti(false)}
              data-testid="confetti"
            />
          )}
        </div>
      </Middle>
    </div>
  );
}

function OfferAccepted({
  token,
  jobId,
}: {
  jobId: string;
  token: string;
}): React.ReactElement {
  const [clicked, setClicked] = useState(false);
  const [
    updateJobOfferMutation,
    { loading: updateJobOfferLoading, error: updateJobOfferError },
  ] = useMutation(UpdateShortlistUserJobStatusDocument, {
    context: { emailToken: token },
    errorPolicy: "all",
  });

  if (updateJobOfferLoading) {
    return <Loading />;
  }
  if (updateJobOfferError) {
    return <span> Something went wrong. Try refreshing the page.</span>;
  }

  const updateOffer = (accepted: boolean) => {
    if (accepted) {
      updateJobOfferMutation({
        variables: {
          jobId: jobId,
          status: UserJobStatus.OfferAccepted,
        },
      });
    }
    setClicked(true);
  };
  return (
    <div>
      {!clicked ? (
        <Spacing>
          <TagLine>Did you accept your offer?</TagLine>
          <ButtonWrapper>
            <Button
              level="primary"
              type="button"
              onClick={() => {
                updateOffer(true);
              }}
            >
              Yes
            </Button>
            <Button
              level="secondary"
              type="button"
              onClick={() => {
                updateOffer(false);
              }}
            >
              No
            </Button>
          </ButtonWrapper>
        </Spacing>
      ) : (
        <span> Thank you for letting us know! </span>
      )}
    </div>
  );
}
