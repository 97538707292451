import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Error } from "./Error";
import AnimatedCard from "./AnimatedCard";

import { Tick } from ".";

import { VerticalSpacing, Button, Text } from "@otta/design";
import { Link } from "@otta/search/components/Link";
import { pushAnalyticsEvent } from "@otta/analytics";

export default function AllDone({
  text,
  analytics,
  hasError = false,
}: {
  text?: string[];
  analytics: {
    eventName: string;
    name: string;
  };
  hasError?: boolean;
}): ReactElement {
  const location = useLocation();

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: analytics.eventName,
      pathname: location.pathname,
      name: analytics.name,
    });
  }, [analytics.eventName, analytics.name, location.pathname]);

  return (
    <AnimatedCard>
      <VerticalSpacing>
        {hasError ? <Error /> : <Tick />}
        <Text bold size={1} align="center">
          {hasError ? "Oops, something went wrong" : "All done!"}
        </Text>
        {text?.map((t, idx) => (
          <Text key={`completed-text-${idx}`} align="center">
            {t}
          </Text>
        ))}
        <Button
          as={Link}
          to="/"
          data-analytics-id="external-responded-dashboard"
          style={{ width: "100%" }}
          level="primary"
        >
          Go to your dashboard
        </Button>
      </VerticalSpacing>
    </AnimatedCard>
  );
}
