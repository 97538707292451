import styled, { css, up, useUp } from "@xstyled/styled-components";
import { useState, useEffect } from "react";

import { DetailedCompletionTable } from "../components/CompletionTable";

import { DownArrow } from "./DownArrow";

import { Paragraph, Card, Spacing, VerticalSpacing, Text } from "@otta/design";
import { pxToRem, modularScale } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import { ProfileCompletionDocument, ProfileStatus } from "@otta/search/schema";
import { Link } from "@otta/search/components/Link";
import { Icon } from "@otta/icons";

const DesktopOnly = styled.div`
  display: none;
  ${up(
    "tablet",
    css`
      display: initial;
    `
  )}
`;

const MobileOnlyText = styled(Text)`
  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

const RowWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;

const MobileRowWrapper = styled(RowWrapper)`
  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

const PercentProgressBar = styled.div<{ percentCompleted: number }>`
  flex: 1;
  background-color: gray-200;
  border-radius: 4;
  position: relative;
  height: ${pxToRem(8)};
  min-width: 45%;
  margin-right: sm;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: default;
    width: ${({ percentCompleted }) => percentCompleted}%;
    background-color: green-400;
    border-radius: 4;

    ${({ percentCompleted }) =>
      percentCompleted === 0 && `width: ${pxToRem(8)};`}
  }
`;

const StyledDownArrow = styled(DownArrow)`
  height: ${modularScale(-2)};
  width: ${modularScale(-1)};
  margin-left: sm;
`;

const StyledUpArrow = styled(StyledDownArrow)`
  transform: scaleY(-1);
`;

const RequirementsWrapper = styled.div<{
  recommended?: boolean;
  allRecommendedComplete?: boolean;
}>(({ recommended, allRecommendedComplete }) => {
  let bgColor = "none";
  if (recommended) {
    bgColor = "beige-200";
  }
  if (allRecommendedComplete) {
    bgColor = "green-100";
  }

  return css`
    border-radius: 4;
    padding: md;
    background-color: ${bgColor};
  `;
});

const GuideSection: React.FC = () => (
  <Card>
    <Paragraph>
      Read our{" "}
      <Link
        to="https://blog.otta.com/how-to-write-a-strong-otta-profile?utm_source=profile_link"
        underline
      >
        guide to writing a strong profile
      </Link>
      <Icon
        icon="external"
        style={{ height: modularScale(-1), marginLeft: modularScale(-5) }}
      />
    </Paragraph>
  </Card>
);

interface RequirementsSectionProps {
  title: string;
  recommended: boolean;
  rows: {
    title: string;
    completed: boolean;
    id: string;
  }[];
}

function RequirementsSection({
  title,
  recommended,
  rows,
}: RequirementsSectionProps): React.ReactElement {
  const numRows = rows.length;
  const numCompletedRows = rows.filter(({ completed }) => completed).length;
  const allRecommendedComplete = recommended && numRows == numCompletedRows;

  return (
    <RequirementsWrapper
      recommended={recommended}
      allRecommendedComplete={allRecommendedComplete}
    >
      <RowWrapper>
        <Text bold>{title}</Text>
        <Text bold>
          {numCompletedRows}/{numRows} {numCompletedRows === numRows && "🎉"}
        </Text>
      </RowWrapper>
      <DetailedCompletionTable
        rows={rows}
        allRecommendedComplete={allRecommendedComplete}
      />
    </RequirementsWrapper>
  );
}

interface Row {
  id: string;
  title: string;
  completed: boolean;
}

interface ChecklistProps {
  percentCompleted: number | null;
  completedFields: Maybe<Maybe<string>[]>;
  hasTechJobFunctionPrefs: boolean;
}

function Checklist({
  percentCompleted,
  completedFields,
  hasTechJobFunctionPrefs,
}: ChecklistProps): React.ReactElement | null {
  const isTablet = useUp("tablet");

  const [isExpanded, setIsExpanded] = useState(isTablet);

  const handleExpandedToggle = () => setIsExpanded(prevState => !prevState);

  const recommendedRows = [
    {
      title: "Work experience",
      completed: completedFields?.includes("work_experience_descriptions"),
      id: "work-experience-form-id",
    },
    hasTechJobFunctionPrefs && {
      title: "Technologies used",
      completed: completedFields?.includes("work_experience_technologies"),
      id: "work-experience-form-id",
    },
    {
      title: "Education",
      completed: completedFields?.includes("education_experiences"),
      id: "education-form-id",
    },
    {
      title: "LinkedIn URL",
      completed: completedFields?.includes("linkedin_url"),
      id: "linkedin-url-form-id",
    },
  ];

  const optionalRows = [
    {
      title: "Snippets",
      completed: completedFields?.includes("projects"),
      id: "snippets-form-id",
    },
    {
      title: "Questions about you",
      completed: completedFields?.includes("context_questions"),
      id: "context-questions-form-id",
    },
    {
      title: "Interests",
      completed: completedFields?.includes("interests"),
      id: "interests-form-id",
    },
  ];

  const recommended = recommendedRows.filter((row): row is Row => Boolean(row));
  const optional = optionalRows.filter((row): row is Row => Boolean(row));

  const allRows = [...recommended, ...optional];

  const numberCompleted = allRows.filter(
    requirement => requirement.completed
  ).length;

  if (numberCompleted >= 0) {
    return (
      <Card>
        <VerticalSpacing size={-2}>
          <MobileOnlyText bold>Profile checklist</MobileOnlyText>
          {percentCompleted !== null && (
            <RowWrapper>
              <PercentProgressBar percentCompleted={percentCompleted} />
              <Text bold>
                {percentCompleted}%
                <MobileOnlyText as="span"> complete</MobileOnlyText>
              </Text>
            </RowWrapper>
          )}
          <MobileRowWrapper
            onClick={handleExpandedToggle}
            style={{ justifyContent: "flex-start" }}
          >
            <Text>{isExpanded ? "Hide " : "Show "}checklist</Text>
            {isExpanded ? (
              <StyledUpArrow colour="black" bold />
            ) : (
              <StyledDownArrow colour="black" bold />
            )}
          </MobileRowWrapper>
          {isExpanded && (
            <>
              <RequirementsSection
                title="Recommended"
                rows={recommended}
                recommended={true}
              />
              <RequirementsSection
                title="Optional"
                rows={optional}
                recommended={false}
              />
            </>
          )}
        </VerticalSpacing>
      </Card>
    );
  }

  return null;
}

export function CompletionStatus({
  profileStatus,
}: {
  profileStatus: Pick<ProfileStatus, "percent" | "state" | "completedFields">;
}): React.ReactElement | null {
  const { data } = useQuery(ProfileCompletionDocument);

  const user = data?.currentUser;
  const percentCompleted = profileStatus?.percent ?? null;
  const profileState = profileStatus?.state;
  const completedFields = profileStatus?.completedFields;

  useEffect(() => {
    if (profileState !== "NOT_CREATED") {
      pushAnalyticsEvent({
        eventName: "Candidate Completed Profile",
        completedFields,
        profileState,
      });
    }
    if (profileState === "DEEP" || profileState === "FULL") {
      pushAnalyticsEvent({
        eventName: "Candidate Completed Deep Profile",
        completedFields,
        profileState,
      });
    }
  }, [profileState, completedFields]);

  if (!user) {
    return null;
  }

  const techJobFunctionIds = ["2", "6", "13", "22", "45"];

  const hasTechJobFunctionPrefs =
    user?.jobFunctionPreferences.filter(pref =>
      techJobFunctionIds.includes(pref.functionId)
    ).length > 0;

  return (
    <Spacing>
      <Checklist
        percentCompleted={percentCompleted}
        completedFields={completedFields}
        hasTechJobFunctionPrefs={hasTechJobFunctionPrefs}
      />
      <DesktopOnly>
        <GuideSection />
      </DesktopOnly>
    </Spacing>
  );
}
