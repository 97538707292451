import styled, { css, down } from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { modularScale } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";

const BackIcon = styled(Icon)`
  flex: 0 0 ${modularScale(2)};
  height: ${modularScale(2)};
  margin-right: xs;
  font-size: ${modularScale(1.5)};
`;

const Caption = styled.span`
  ${down(
    "desktop",
    css`
      display: none;
    `
  )}
`;

const BackLinkElement = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: unset;
  z-index: 10;

  &:hover {
    text-decoration: none;
  }

  &:hover span {
    text-decoration: underline;
  }
`;

const BackLinkText = styled(Text)`
  display: flex;
  align-items: center;
  position: relative;
  top: 1px;
`;

export function BackLink({ to }: { to: string }): React.ReactElement {
  return (
    <div style={{ position: "relative" }}>
      <BackLinkElement to={to} data-testid="back-link">
        <BackLinkText bold>
          <BackIcon icon="arrow-left" />
          <Caption>Back</Caption>
        </BackLinkText>
      </BackLinkElement>
    </div>
  );
}
