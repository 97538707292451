interface MailProps extends React.SVGAttributes<SVGSVGElement> {
  active?: boolean;
}

export function Mail({ active, ...props }: MailProps): React.ReactElement {
  return active ? (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.875 4.5C2.83984 4.5 2 5.33984 2 6.375C2 6.96484 2.27734 7.51953 2.75 7.875L11.25 14.25C11.6953 14.582 12.3047 14.582 12.75 14.25L21.25 7.875C21.7227 7.51953 22 6.96484 22 6.375C22 5.33984 21.1602 4.5 20.125 4.5H3.875ZM2 8.875V17C2 18.3789 3.12109 19.5 4.5 19.5H19.5C20.8789 19.5 22 18.3789 22 17V8.875L13.5 15.25C12.6094 15.918 11.3906 15.918 10.5 15.25L2 8.875Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 7C2 5.61914 3.11914 4.5 4.5 4.5H19.5C20.8789 4.5 22 5.61914 22 7V17C22 18.3789 20.8789 19.5 19.5 19.5H4.5C3.11914 19.5 2 18.3789 2 17V7ZM3.875 7V7.86328L10.6133 13.3945C11.418 14.0586 12.582 14.0586 13.3867 13.3945L20.125 7.86328V6.96484C20.125 6.65625 19.8438 6.33984 19.5 6.33984H4.5C4.15469 6.33984 3.875 6.65625 3.875 6.96484V7ZM3.875 10.2891V17C3.875 17.3438 4.15469 17.625 4.5 17.625H19.5C19.8438 17.625 20.125 17.3438 20.125 17V10.2891L14.5781 14.8438C13.0781 16.0742 10.9219 16.0742 9.38672 14.8438L3.875 10.2891Z"
        fill="black"
      />
    </svg>
  );
}
