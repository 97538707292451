import { BulletIcon } from "./Styles";

import { modularScale } from "@otta/design-tokens";

export function Bullet(): React.ReactElement {
  return (
    <BulletIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="circle"
        data-testid="bullet-icon"
        className="svg-inline--fa fa-circle fa-w-16"
        role="img"
        viewBox="0 0 512 512"
        style={{
          width: modularScale(-6),
        }}
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
        />
      </svg>
    </BulletIcon>
  );
}
