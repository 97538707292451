import { useEffect, useMemo } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { Text, Spacing } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { Checkbox } from "@otta/search/components/Input/Checkbox";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

const surveyOptions = [
  { value: "friend", label: "From a friend" },
  { value: "ig_facebook", label: "Instagram or Facebook" },
  { value: "google", label: "Google" },
  { value: "in_person", label: "Posters or outdoor advertising" },
  { value: "linkedin", label: "LinkedIn" },
  { value: "tiktok", label: "TikTok" },
  { value: "elsewhere", label: "Elsewhere" },
] as const;

export function SignUpReason({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();

  const isPresent = useIsPresent();

  const preferences = useMemo(
    () => preferencesData?.signUpReasonResponses ?? [],
    [preferencesData]
  );

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(true);
    }
  }, [setNextEnabled, isPresent]);

  if (!preferencesData) {
    return <Loading />;
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const set = new Set(preferences.map(pref => pref.source));

    if (e.target.checked) {
      set.add(e.target.value);
    } else {
      set.delete(e.target.value);
    }

    updatePreferences({
      signUpReasonResponses: Array.from(set).map(id => ({
        source: id,
      })),
    });
  };

  return (
    <Spacing>
      <Text as="h5">
        This helps us understand how to reach more job seekers like you.{" "}
      </Text>
      <Spacing size={-4}>
        {surveyOptions.map(({ value, label }) => (
          <Checkbox
            key={value}
            label={`${label}`}
            onChange={handleChange}
            checked={preferences.some(pref => pref.source === value)}
            value={value}
          />
        ))}
      </Spacing>
    </Spacing>
  );
}
