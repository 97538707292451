import { FieldValidator } from "final-form";
import { Component } from "react";
import { Field, Form as FinalForm } from "react-final-form";

import { Item, QuestionWrapper } from "..";
import { QuestionComponentProps } from "../..";

import { useQuery } from "@otta/search/apollo";
import { TextField } from "@otta/search/components/Input/TextField";
import {
  SalaryPreferenceDocument,
  UpdateSalaryPreferenceDocument,
} from "@otta/search/schema";

interface ISalariesTextFieldProps extends QuestionComponentProps {
  fieldName: "currentCompany" | "currentJobTitle";
  label: string;
  validate?: FieldValidator<any>;
  optional?: boolean;
  skip?: boolean;
}

interface ISalariesTextFieldComponentProps extends ISalariesTextFieldProps {
  field?: string | null;
  enabled: boolean;
  refetchQueries: boolean;
}

interface ISalariesTextFieldComponentState {
  fieldLength: number;
}
export class SalariesTextFieldComponent extends Component<
  ISalariesTextFieldComponentProps,
  ISalariesTextFieldComponentState
> {
  public state = { fieldLength: 0 };

  constructor(props: ISalariesTextFieldComponentProps) {
    super(props);
    if (props.skip && props.handleSkip) {
      props.handleSkip();
    }
    if (props.enabled) {
      props.setNextEnabled(true);
    }
  }

  public componentDidUpdate(
    prevProps: ISalariesTextFieldComponentProps,
    prevState: ISalariesTextFieldComponentState
  ): void {
    if (this.props.skip && this.props.handleSkip) {
      this.props.handleSkip();
    } else if (prevProps.enabled !== this.props.enabled) {
      this.props.setNextEnabled(this.props.enabled);
    } else if (prevState.fieldLength !== this.state.fieldLength) {
      this.props.setNextEnabled(this.state.fieldLength > 0);
    }
  }

  public render(): React.ReactElement {
    const { field, fieldName, label, validate, refetchQueries } = this.props;
    return (
      <QuestionWrapper>
        <Item style={{ marginTop: "16px" }}>
          <FinalForm
            initialValues={{ [fieldName]: field }}
            onSubmit={async () => {
              if (this.state.fieldLength > 0) {
                const activeElement = document.activeElement;
                if (activeElement) {
                  await (activeElement as HTMLInputElement).blur();
                }
                this.props.handleNext();
              }
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name={fieldName} validate={validate}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      mutation={UpdateSalaryPreferenceDocument}
                      fieldName={fieldName}
                      label={label}
                      valid={meta.valid}
                      error={meta.active && meta.error}
                      onChange={e => {
                        this.setState({ fieldLength: e.target.value.length });
                        return input.onChange(e);
                      }}
                      refetchQueries={
                        refetchQueries
                          ? () => [{ query: SalaryPreferenceDocument }]
                          : () => []
                      }
                      awaitRefetchQueries={refetchQueries}
                    />
                  )}
                </Field>
              </form>
            )}
          />
        </Item>
      </QuestionWrapper>
    );
  }
}

export function SalariesTextField({
  fieldName,
  label,
  validate,
  optional,
  skip,
  ...props
}: ISalariesTextFieldProps): React.ReactElement {
  const { data } = useQuery(SalaryPreferenceDocument);

  const field = data?.salaryPreference?.[fieldName];
  const hasField = field !== null && field !== undefined && field.trim() !== "";
  return (
    <SalariesTextFieldComponent
      field={field}
      fieldName={fieldName}
      enabled={optional ? optional : hasField}
      label={label}
      validate={validate}
      skip={skip}
      refetchQueries={!data?.salaryPreference?.id}
      {...props}
    />
  );
}
