import Ajv, { JSONSchemaType } from "ajv";
const ajv = new Ajv();
interface ExternalLinkCue {
  type: "external-link";
  url: string;
  description: string;
  title: string;
  primaryButtonCopy?: string;
  paths?: string[];
}
interface SimplePollCue {
  type: "simple-poll";
  description?: string;
  title: string;
  paths?: string[];
}

export type AnyOttacue = ExternalLinkCue | SimplePollCue;

const schema: JSONSchemaType<AnyOttacue> = {
  oneOf: [
    {
      type: "object",
      properties: {
        type: { type: "string", const: "external-link" },
        url: { type: "string", pattern: "^http(s?)://.*$" },
        description: { type: "string", minLength: 8 },
        title: { type: "string", minLength: 8 },
        primaryButtonCopy: { type: "string", minLength: 2, nullable: true },
        paths: { type: "array", items: { type: "string" }, nullable: true },
      },
      required: ["type", "url", "description", "title"],
      additionalProperties: false,
    },
    {
      type: "object",
      properties: {
        type: { type: "string", const: "simple-poll" },
        description: { type: "string", minLength: 8, nullable: true },
        title: { type: "string", minLength: 8 },
        paths: { type: "array", items: { type: "string" }, nullable: true },
      },
      required: ["type", "title"],
      additionalProperties: false,
    },
  ],
};

const validate = ajv.compile(schema);

function getValidationError(): string {
  const errors = validate.errors;

  if (errors && errors[0]?.message) {
    return errors[0].message;
  } else {
    return "No error found!";
  }
}

function validateCue(payload: AnyOttacue): AnyOttacue {
  const valid = validate(payload);

  if (valid) {
    return payload;
  } else {
    throw new Error(`Invalid ottacue payload: ${getValidationError()}`);
  }
}

export function parseAndValidateCue(payload: unknown): AnyOttacue | never {
  if (!payload || typeof payload !== "object" || !("type" in payload)) {
    throw new Error(`Invalid cue ${JSON.stringify(payload)}`);
  }

  switch (payload.type) {
    case "external-link":
      return validateCue(payload as ExternalLinkCue);
    case "simple-poll":
      return validateCue(payload as SimplePollCue);
    default:
      throw new Error(`Invalid cue type ${payload.type}`);
  }
}
