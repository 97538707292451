import { AnimatePresence } from "framer-motion";
import { ReactElement } from "react";

import AllDone from "../shared/AllDone";

import DidYouHearBackFrom from "./DidYouHearBackFrom";
import { JobApplication } from "./types";

interface IRespondedWizardProps {
  jobApplications: JobApplication[];
  index: number;
  onNext: () => void;
}

export default function RespondedWizard({
  jobApplications,
  index,
  onNext,
}: IRespondedWizardProps): ReactElement {
  return (
    <AnimatePresence initial={false} mode="wait">
      {index < jobApplications.length ? (
        <DidYouHearBackFrom
          key={jobApplications[index].externalId}
          jobApplication={jobApplications[index]}
          onComplete={onNext}
        />
      ) : (
        <AllDone
          key="all-done-hello"
          text={[
            "You've helped us improve the response rate information we show to candidates.",
            "If you've landed any interviews, update your progress using the “Move” button on each saved job.",
          ]}
          analytics={{
            eventName: "Candidate Shown",
            name: "End of External Responded Flow",
          }}
        />
      )}
    </AnimatePresence>
  );
}
