import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";

import { LinkType } from "..";

import { InputField, Spacing, Label } from "@otta/design";
import {
  validLinkedinProfileUrl,
  validUrl,
} from "@otta/search/utils/validators";
import { LinkInput } from "@otta/search/components/LinkInput";
import { Separator } from "@otta/search/components/Separator";

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: xs;
`;

export function EditingLinks(): React.ReactElement {
  return (
    <ColumnWrapper>
      <Spacing>
        <Field
          name="linkedinUrl"
          validate={val => (val ? validLinkedinProfileUrl(val) : undefined)}
          render={({ input, meta }) => (
            <InputField
              {...input}
              type="text"
              label="LinkedIn URL"
              placeholder="https://linkedin.com/in/yourname"
              error={meta.touched && meta.error}
            />
          )}
        />
        <Field<LinkType[]>
          name="websiteLinks"
          validate={links =>
            (links ?? []).some(({ url }) => !url || !!validUrl(url))
              ? "Enter a valid URL"
              : undefined
          }
          render={({ input, meta }) => (
            <Spacing>
              <Label htmlFor="website-links">Other website links</Label>
              <LinkInput
                {...input}
                id="website-links"
                error={meta.touched && meta.error}
              />
              <Separator />
            </Spacing>
          )}
        />
      </Spacing>
    </ColumnWrapper>
  );
}
