import styled from "@xstyled/styled-components";

import { Card, responsive, Spacing, Text } from "@otta/design";
import { LatestJobApplication } from "@otta/search/schema";

const Header = styled(Text)`
  ${responsive`
    font-size: ${responsive.modularScale({ mobile: 1, desktop: 0 })};
  `}
`;

export const Interests = ({
  candidate,
}: {
  candidate: LatestJobApplication.Candidate;
}): React.ReactElement | null =>
  candidate.profileInterests ? (
    <Card data-testid="interests-card">
      <Spacing>
        <Header bold align="left">
          Interests
        </Header>
        <Text data-cs-mask size={-1} align="left">
          {candidate.profileInterests}
        </Text>
      </Spacing>
    </Card>
  ) : null;
