import { Field } from "react-final-form";

import { InputField } from "@otta/design";
import { maxLength } from "@otta/search/utils/validators";

export function EditTextField({ name }: { name: string }): React.ReactElement {
  return (
    <Field
      name={name}
      validate={maxLength(128)}
      render={({ input, meta }) => {
        return (
          <InputField
            {...input}
            type="text"
            placeholder={`Type your answer here...`}
            error={meta.touched && meta.error}
          />
        );
      }}
    />
  );
}
