import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { Form as FinalForm } from "react-final-form";
import { useApolloClient } from "@apollo/client";
import { FormApi } from "final-form";
import { useIsPresent } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { useCookie } from "react-use";

import { QuestionComponentProps } from "..";

import { ErrorText, Spacing } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import {
  NavBarStatusDocument,
  UserPreferencesDocument,
} from "@otta/search/schema";
import { ExternalSignIn } from "@otta/search/components/ExternalSignIn";
import { Link } from "@otta/search/components/Link";
import { SignUpFormFields } from "@otta/search/pages/Signup/SignupForm";

interface SignUpData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  marketingConsent: boolean | undefined;
}

export function SignUp({
  handleSkip,
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const [error, setError] = useState<"CONFLICT" | boolean>(false);
  const client = useApolloClient();

  const [searchParams] = useSearchParams();
  const [csrf] = useCookie(import.meta.env.VITE_CSRF_COOKIE);

  const utmSource =
    searchParams.get("utm_source") ??
    searchParams.get("homepage_utm_source") ??
    undefined;

  const gclid =
    searchParams.get("gclid") ??
    searchParams.get("homepage_gclid") ??
    undefined;

  const referredByUserId = searchParams.get("referrer") ?? undefined;

  const handleSignup = async (values: SignUpData) => {
    setError(false);

    try {
      const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await fetch(
        `${import.meta.env.VITE_API_HOST}/auth/signup`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            ...(csrf ? { "X-CSRF-Token": csrf } : {}),
          },
          body: JSON.stringify({
            ...values,
            gclid,
            utmSource,
            timezone,
            referredByUserId,
          }),
        }
      );

      if (response.status === 409) {
        throw new Error("CONFLICT");
      }

      if (!response.ok || response.status !== 201) {
        throw new Error("Unexpected response from api");
      }

      client.query({
        query: NavBarStatusDocument,
        fetchPolicy: "network-only",
      });

      const { data } = await client.query({
        query: UserPreferencesDocument,
        fetchPolicy: "network-only",
      });

      if (!data.currentUser) {
        throw new Error("No user returned after signing up");
      }
    } catch (error) {
      setError((error as Error).message === "CONFLICT" ? "CONFLICT" : true);
      throw error;
    }
  };

  return (
    <FinalForm onSubmit={handleSignup}>
      {({ form }) => (
        <SignUpForm
          form={form}
          handleSkip={handleSkip as () => void}
          error={error}
          gclid={gclid}
          utmSource={utmSource}
          setNextEnabled={setNextEnabled}
        />
      )}
    </FinalForm>
  );
}

function SignUpForm({
  form,
  handleSkip,
  error,
  utmSource,
  gclid,
  setNextEnabled,
}: {
  form: FormApi<SignUpData>;
  handleSkip: () => void;
  error: "CONFLICT" | boolean;
  utmSource?: string;
  gclid?: string;
  setNextEnabled: (enabled: boolean) => void;
}) {
  const isPresent = useIsPresent();

  const { addEventListener } = useUserPreferences();

  const listener = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      form
        .submit()
        ?.then(() => {
          pushAnalyticsEvent({
            eventName: "candidate-sign-up",
            method: "website",
          });
          handleSkip();
        })
        .catch(e => {
          if ((e as Error).message !== "CONFLICT") {
            Sentry.captureException(e);
          }
        });
    },
    [form, handleSkip]
  );

  const isValid = form.getState().valid;

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(isValid);
    }
  }, [isValid, isPresent, setNextEnabled]);

  useEffect(() => {
    return addEventListener("NEXT", listener);
  }, [listener, addEventListener]);

  return (
    <form onSubmit={listener} data-testid="signup-form">
      <Spacing size={-1}>
        <SignUpFormFields />
        {error === "CONFLICT" && (
          <ErrorText data-testid="signup-conflict-error">
            This email is already signed up, try another or{" "}
            <Link
              to={{ pathname: "/login", search: location.search }}
              style={{ color: "inherit" }}
            >
              sign in
            </Link>
          </ErrorText>
        )}
        {error === true && (
          <ErrorText data-testid="signup-error">
            Something went wrong! Try again in a few seconds
          </ErrorText>
        )}
        <Spacing>
          <ExternalSignIn
            action="Sign up"
            utmSource={utmSource}
            gclid={gclid}
            successPath="/initial-preferences/email-notifications"
          />
        </Spacing>
      </Spacing>
    </form>
  );
}
