import { pxToRem } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";

const GOOGLE_LINK =
  "https://play.google.com/store/apps/details?id=com.otta.otta&utm_source=onboarding_modal";

export const GoogleBadge = (): React.ReactElement => {
  return (
    <a
      href={GOOGLE_LINK}
      onClick={() => {
        pushAnalyticsEvent({
          eventName: "Candidate Clicked",
          name: "download-the-app-android",
        });
      }}
    >
      <img
        style={{ height: pxToRem(60), marginTop: pxToRem(-10) }}
        alt="Get it on Google Play"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
      />
    </a>
  );
};
