import { palette } from "@otta/design-tokens";

export function Clock(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      enableBackground="new 0 0 612 612"
      version="1.1"
      viewBox="0 0 612 612"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={palette.brand.black}
        d="m587.57 186.88c-32.266-75.225-87.096-129.93-162.95-162.28-37.912-16.169-77.631-24.428-119.13-24.428-41.488 0-80.914 8.181-118.78 24.428-75.225 32.265-130.3 86.939-162.62 162.28-16.197 37.748-24.092 77.295-24.092 118.78 0 41.496 7.895 81.371 24.092 119.13 32.323 75.346 87.403 130.35 162.62 162.62 37.877 16.247 77.295 24.42 118.78 24.42s81.214-8.259 119.12-24.42c75.853-32.352 130.68-87.403 162.96-162.62 16.246-37.877 24.427-77.631 24.427-119.13 0-41.488-8.174-80.907-24.428-118.78zm-48.848 253.97c-24.021 41.195-56.929 73.876-98.375 98.039-41.195 24.021-86.332 36.135-134.84 36.135-36.47 0-71.27-7.024-104.4-21.415-33.129-14.384-61.733-33.294-85.661-57.215-23.928-23.928-42.973-52.811-57.214-85.997-14.199-33.065-21.08-68.258-21.08-104.74 0-48.52 11.921-93.428 35.807-134.51 23.971-41.231 56.886-73.947 98.039-98.04 41.146-24.092 85.99-36.142 134.5-36.142 48.52 0 93.649 12.121 134.84 36.142 41.446 24.164 74.283 56.879 98.375 98.039 24.092 41.153 36.135 85.99 36.135 134.51 0 48.521-11.964 93.735-36.128 135.19z"
      />
      <path
        fill={palette.brand.black}
        d="m324.91 302.99v-173.33c0-10.372-9.037-18.738-19.41-18.738-9.701 0-18.403 8.366-18.403 18.738v176c0 0.336 0.671 1.678 0.671 2.678-0.671 6.024 1.007 11.043 5.019 15.062l100.05 100.05c6.695 6.695 19.073 6.695 25.763 0 7.694-7.695 7.188-18.86 0-26.099z"
      />
    </svg>
  );
}
