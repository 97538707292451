import { format } from "date-fns";
import styled from "@xstyled/styled-components";

import { FormAnswer } from "./types";

import { Text } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { HeaderWrapper } from "@otta/search/pages/Profile/PublicProfile/components/Section";
import { CardTitle } from "@otta/search/pages/Profile/components/FormHeader";
import { ExternalLink } from "@otta/search/components/ExternalLink";

interface DisplayQuestionProps {
  required: boolean;
  answer: FormAnswer;
}

const QuestionContainer = styled.div`
  white-space: pre-wrap;
`;

const WrappedText = styled(Text)`
  line-break: anywhere;
`;

export function DisplayQuestion({
  required,
  answer,
}: DisplayQuestionProps): React.ReactElement {
  const { questionData } = answer;

  return (
    <QuestionContainer>
      <HeaderWrapper
        style={{ paddingBottom: pxToRem(8) }}
        data-testid={`${questionData.atsId}-header`}
      >
        <CardTitle bold>
          {questionData.question}

          {!required && (
            <Text style={{ color: palette.grayscale.shade600 }}>
              (optional)
            </Text>
          )}
        </CardTitle>
      </HeaderWrapper>
      <WrappedText>
        {answer.value === null
          ? formatPlaceholderValue(answer)
          : formatDisplayValue(answer)}
      </WrappedText>
    </QuestionContainer>
  );
}

function formatPlaceholderValue({ questionType }: FormAnswer): string {
  switch (questionType) {
    case "BooleanQuestion":
    case "SingleSelectQuestion":
      return "Choose an option...";
    case "MultiSelectQuestion":
      return "Check all that apply";
    case "DateQuestion":
      return "Enter a date here...";
    case "FileQuestion":
      return "Upload a file here...";
    case "DecimalQuestion":
      return "Enter a number here...";
    case "TextQuestion":
    case "TextAreaQuestion":
      return "Type your answer here...";
    case "UrlQuestion":
      return "Enter a link here...";
  }
}

function formatDisplayValue({ value, questionType }: FormAnswer) {
  if (value === null) {
    return null;
  }

  switch (questionType) {
    case "BooleanQuestion":
      return value ? "Yes" : "No";
    case "DateQuestion": {
      return format(value, "do MMMM yyyy");
    }
    case "FileQuestion":
      return "url" in value ? (
        <ExternalLink
          newTab
          to={value.url}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {value.name}
        </ExternalLink>
      ) : null;
    case "SingleSelectQuestion":
      return value.label;
    case "MultiSelectQuestion": {
      const values = value.map(response => JSON.parse(response));
      return values.length > 0
        ? values.map(({ label }) => label).join(", ")
        : "No options selected";
    }
    case "TextQuestion":
    case "TextAreaQuestion":
    case "DecimalQuestion":
    case "UrlQuestion":
      return value;
  }
}
