import styled from "@xstyled/styled-components";

import { Paragraph as DefaultParagraph, Spacing, Text } from "@otta/design";

const CollapsedText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Paragraph = styled(DefaultParagraph)`
  white-space: pre-line;
`;

interface ITextQuestionResponseProps {
  question: string;
  answer: string;
  expand: boolean;
}

export function TextQuestionResponse({
  question,
  answer,
  expand,
}: ITextQuestionResponseProps): React.ReactElement | null {
  return (
    <Spacing size={-2}>
      <Text bold align="left">
        {question}
      </Text>
      {expand ? (
        <Paragraph size={-1} align="left">
          {answer}
        </Paragraph>
      ) : (
        <CollapsedText size={-1} align="left">
          {answer}
        </CollapsedText>
      )}
    </Spacing>
  );
}
