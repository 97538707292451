interface Listeners {
  [type: string]: ((e: Event) => void)[];
}

// A simple implementation of the browser EventTarget in order to support older browsers
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget#simple_implementation_of_eventtarget
export class EventTarget {
  private listeners: Listeners = {};

  addEventListener(type: string, callback: (e: Event) => void): void {
    if (!(type in this.listeners)) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  removeEventListener(type: string, callback: (e: Event) => void): void {
    if (!(type in this.listeners)) {
      return;
    }
    const stack = this.listeners[type];
    for (let i = 0, l = stack.length; i < l; i++) {
      if (stack[i] === callback) {
        stack.splice(i, 1);
        return;
      }
    }
  }

  dispatchEvent(e: Event): boolean {
    if (!(e.type in this.listeners)) {
      return true;
    }
    const stack = this.listeners[e.type].slice();

    for (let i = 0, l = stack.length; i < l; i++) {
      stack[i].call(this, e);
    }
    return !e.defaultPrevented;
  }
}
