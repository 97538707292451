import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { Link, useSearchParams } from "react-router-dom";

import { pxToRem } from "@otta/design-tokens";
import {
  Button as DefaultButton,
  Card,
  Middle,
  Spacing,
  Text,
} from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  NotificationFrequency,
  UpdateUserNotificationPreferencesDocument,
} from "@otta/search/schema";
import { PageWrapper } from "@otta/search/components/PageWrapper";

const Button = styled(DefaultButton)`
  width: 100%;
`;

export function PublicEmailNotifications(): React.ReactElement {
  const [qs] = useSearchParams();

  const [mutation] = useMutation(UpdateUserNotificationPreferencesDocument);

  const newUserEmailNotificationFrequency = qs.get(
    "newUserEmailNotificationFrequency"
  );

  const userExternalId = qs.get("userExternalId");
  const emailToken = qs.get("token");

  useEffect(() => {
    if (
      typeof newUserEmailNotificationFrequency === "string" &&
      (typeof userExternalId === "string" || typeof emailToken === "string")
    ) {
      mutation({
        variables: {
          frequency: newUserEmailNotificationFrequency as NotificationFrequency,
          ...(userExternalId ? { externalId: userExternalId } : {}),
        },
        context: emailToken ? { emailToken } : undefined,
      });
      pushAnalyticsEvent({
        eventName: "Candidate changed email frequency",
        source: "Email",
        to: newUserEmailNotificationFrequency,
      });
    }
  }, [mutation, newUserEmailNotificationFrequency, userExternalId, emailToken]);

  return (
    <PageWrapper style={{ marginTop: pxToRem(30) }}>
      <Middle maxWidth={390}>
        <Card>
          <Spacing size={2}>
            <Text bold size={1} align="center">
              Thanks!
            </Text>
            <Text align="center">
              Your email notification preferences have been updated.
            </Text>
            <Text align="center">Sign in to see your matches</Text>
            <Button as={Link} to="/login" level="primary" type="submit">
              Sign in
            </Button>
          </Spacing>
        </Card>
      </Middle>
    </PageWrapper>
  );
}
