import styled from "@xstyled/styled-components";

import { Icon } from "@otta/icons";
import { Text } from "@otta/design";
import { modularScale } from "@otta/design-tokens";

const IconHack = styled.span`
  font-size: ${modularScale(2.5)};
  margin-bottom: -0.2rem;
  margin-top: 0.2rem;
`;

const FlexText = styled(Text)`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

export function TeamSize({
  size,
  className,
}: {
  size: number | null;
  className?: string;
}): React.ReactElement | null {
  return size !== null ? (
    <FlexText size={-1} bold data-testid="team-size" className={className}>
      <IconHack>
        <Icon icon="users" />
      </IconHack>
      {size === 1 ? "1 person" : `${size} people`}
    </FlexText>
  ) : null;
}
