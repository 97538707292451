import styled, { css } from "@xstyled/styled-components";

import { pxToRem, modularScale, palette } from "@otta/design-tokens";
import { UserJobPreferences, Company } from "@otta/search/schema";
import { TagsContainer as DefaultTagsContainer } from "@otta/search/components/Tag";
import { useUserCompanySectorTags } from "@otta/search/utils/user";
import { Icon } from "@otta/icons";

const TagsContainer = styled(DefaultTagsContainer)`
  gap: sm;
`;

const Wrapper = styled.div`
  display: flex;
  gap: sm;

  > svg {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
  }
`;

const JobCardSectorTag = styled.span<{ bolded: boolean }>`
  ${props =>
    css`
      background: ${props.bolded
        ? palette.brand.green
        : palette.beige.shade200};
      border-radius: 4;
      font-size: ${modularScale()};
      font-weight: ${props.bolded ? "700" : "normal"};
      padding: xs sm;
    `}
`;

interface SectorTagsProps {
  company: Pick<Company.Fragment, "sectorTags">;
  user?: Pick<UserJobPreferences.CurrentUser, "sectorTagPreferences">;
}

export function SectorTags({
  company,
  user,
}: SectorTagsProps): React.ReactElement | null {
  const userCompanySectorTags = useUserCompanySectorTags({ company, user });
  if (!userCompanySectorTags) {
    return null;
  }

  const { orderedSectorTags, userPreferences } = userCompanySectorTags;

  return (
    <Wrapper>
      <Icon icon="industry" size={2} />
      <TagsContainer alignLeft data-testid="company-sector-tags">
        {orderedSectorTags.map(({ id, value }) => (
          <JobCardSectorTag
            key={id}
            bolded={userPreferences.has(id)}
            data-testid="company-sector-tag"
          >
            {value}
          </JobCardSectorTag>
        ))}
      </TagsContainer>
    </Wrapper>
  );
}
