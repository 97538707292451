import { useNavigate } from "react-router-dom";

import ActionCard from "./ActionCard";

export default function ReviewPreferences(): React.ReactElement {
  const navigate = useNavigate();

  return (
    <ActionCard
      analyticsId="update-preferences"
      description="Review and update your preferences to see even better matches."
      level="secondary"
      ctaText="Update your preferences"
      onClick={() => navigate("/preferences")}
    />
  );
}
