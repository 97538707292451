import { useCallback, useEffect, useState } from "react";

export function useTabVisible(): boolean {
  const [visible, setVisible] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setVisible(document.visibilityState === "visible");
  }, [setVisible]);

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityChange, {
      capture: true,
    });

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange, {
        capture: true,
      });
    };
  }, [handleVisibilityChange]);

  return visible;
}
