import { useEffect, useMemo } from "react";
import styled from "@xstyled/styled-components";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { subFunctionInfo } from "./informationText";
import { IconsWrapper, IconWrapper, StyledText } from "./PositiveTechnologies";

import { palette, modularScale } from "@otta/design-tokens";
import { Spacing, Text } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import {
  JobFunctionsSubFunctionsDocument,
  JobSubFunction as IJobSubFunction,
} from "@otta/search/schema";
import { sortBy } from "@otta/search/utils/collections";
import { compareAlphabetically } from "@otta/search/utils/strings";
import { Tag, TagsContainer } from "@otta/search/components/Tag";
import { Checkbox } from "@otta/search/components/Input/Checkbox";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { Heart } from "@otta/search/pages/Jobs/icons/Heart";

const CheckboxContainer = styled.div`
  position: relative;
`;

const TooltipWrapper = styled.div`
  z-index: 1;
  cursor: pointer;
  position: absolute;
  right: ${modularScale()};
  top: 50%;
  transform: translateY(-50%);
  -webkit-tap-highlight-color: transparent;
`;

const SUB_FUNCTION_PREFERENCE_LIMIT = 5;

// This order is in reverse so unknown functions are ordered to the back in the sort function
const KNOWN_FUNCTION_ORDER = [
  "Finance, Legal & Compliance",
  "People, HR, Recruitment",
  "Marketing",
  "Sales & Account Management",
  "Operations & Strategy",
  "Design",
  "Product",
  "Other Engineering",
  "Data",
  "Software Engineering",
];

function sortFunctionsAlphabetically<T extends { value: string }>(
  functions: T[]
): T[] {
  return sortBy(functions, x => x.value, compareAlphabetically);
}

export function JobFunctionSubFunction({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();

  const { data: functionsData } = useQuery(JobFunctionsSubFunctionsDocument);

  const allJobFunctions = useMemo(() => {
    const jobFunctions = functionsData?.jobFunctions ?? [];
    return jobFunctions
      .map(jobFunction => ({
        ...jobFunction,
        subFunctions: sortFunctionsAlphabetically(jobFunction.subFunctions),
      }))
      .sort(
        (a, b) =>
          KNOWN_FUNCTION_ORDER.indexOf(b.value) -
          KNOWN_FUNCTION_ORDER.indexOf(a.value)
      );
  }, [functionsData]);

  const [functionPreferencesSet, subFunctionPreferencesSet] = useMemo(() => {
    const { jobFunctionPreferences, jobSubFunctionPreferences } =
      preferencesData ?? {};

    return [
      new Set((jobFunctionPreferences ?? []).map(({ functionId: id }) => id)),
      new Set(
        (jobSubFunctionPreferences ?? []).map(({ subFunctionId: id }) => id)
      ),
    ];
  }, [preferencesData]);

  const isPresent = useIsPresent();

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(
        functionPreferencesSet.size > 0 && subFunctionPreferencesSet.size > 0
      );
    }
  }, [
    functionPreferencesSet,
    subFunctionPreferencesSet,
    setNextEnabled,
    isPresent,
  ]);

  if (!functionsData || !preferencesData) {
    return <Loading />;
  }

  const selectableSubFunctions = allJobFunctions
    .filter(func => functionPreferencesSet.has(func.id))
    .reduce<IJobSubFunction[]>(
      (acc, jobFunction) => acc.concat(jobFunction.subFunctions),
      []
    );

  const handleChangeFunction = (functionId: string) => {
    if (functionPreferencesSet.has(functionId)) {
      functionPreferencesSet.delete(functionId);
      const func = allJobFunctions.find(f => f.id === functionId);

      (func?.subFunctions ?? []).map(({ id }) =>
        subFunctionPreferencesSet.delete(id)
      );
    } else {
      functionPreferencesSet.add(functionId);
    }
    updatePreferences({
      jobFunctionPreferences: Array.from(functionPreferencesSet).map(id => ({
        functionId: id,
      })),
      jobSubFunctionPreferences: Array.from(subFunctionPreferencesSet).map(
        id => ({
          subFunctionId: id,
        })
      ),
    });
  };

  const handleChangeSubFunction = (subFunctionId: string) => {
    if (subFunctionPreferencesSet.has(subFunctionId)) {
      subFunctionPreferencesSet.delete(subFunctionId);
    } else {
      subFunctionPreferencesSet.add(subFunctionId);
    }

    updatePreferences({
      jobFunctionPreferences: Array.from(functionPreferencesSet).map(id => ({
        functionId: id,
      })),
      jobSubFunctionPreferences: Array.from(subFunctionPreferencesSet).map(
        id => ({ subFunctionId: id })
      ),
    });
  };

  return (
    <Spacing size={4}>
      <TagsContainer>
        {allJobFunctions.map(({ id, value }) => (
          <Tag
            data-testid="function-tag"
            key={id}
            onClick={() => handleChangeFunction(id)}
            backgroundColor={
              functionPreferencesSet.has(id)
                ? palette.brand.yellow
                : palette.brand.white
            }
          >
            <IconsWrapper>
              <IconWrapper
                enter={functionPreferencesSet.has(id)}
                exit={!functionPreferencesSet.has(id)}
                isHeart
              >
                <Heart />
              </IconWrapper>
            </IconsWrapper>
            <StyledText selected={functionPreferencesSet.has(id)}>
              {value}
            </StyledText>
          </Tag>
        ))}
      </TagsContainer>
      <Spacing>
        {selectableSubFunctions.length > 0 && (
          <Text as="h4">Select the most relevant for you (max 5)</Text>
        )}
        <Spacing size={-4}>
          {selectableSubFunctions.map(({ id, value }) => {
            const selected = subFunctionPreferencesSet.has(id);
            const info = subFunctionInfo.find(({ values }) =>
              values.includes(value)
            )?.content;

            return (
              <CheckboxContainer key={id}>
                <Checkbox
                  label={value}
                  checked={selected}
                  onChange={() => handleChangeSubFunction(id)}
                  disabled={
                    !selected &&
                    subFunctionPreferencesSet.size >=
                      SUB_FUNCTION_PREFERENCE_LIMIT
                  }
                />
                <TooltipWrapper>
                  <TooltipWithIcon content={info} />
                </TooltipWrapper>
              </CheckboxContainer>
            );
          })}
        </Spacing>
      </Spacing>
    </Spacing>
  );
}
