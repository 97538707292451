import styled, { css, down } from "@xstyled/styled-components";
import { motion } from "framer-motion";

import { AppleBadge } from "./AppleBadge";
import { GoogleBadge } from "./GoogleBadge";

import { pxToRem } from "@otta/design-tokens";
import { Heading, Text } from "@otta/design";

const BottomBar = styled(motion.div)`
  bottom: 0;
  width: 100%;
  height: ${pxToRem(230)};
  border-radius: ${pxToRem(24)} ${pxToRem(24)} 0 0;
  background-color: yellow-500;
  position: absolute;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  display: flex;
  width: ${pxToRem(320)};
  flex-direction: column;
  justify-content: center;
  margin: 0 37;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageContainer = styled.div`
  height: 100%;
  margin: 0 37;
  display: flex;
  align-items: flex-end;

  ${down(
    "desktop",
    css`
      display: none;
    `
  )}
`;

const StyledImg = styled.img`
  height: ${pxToRem(250)};
`;

export function GetTheApp(): React.ReactElement {
  return (
    <BottomBar
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      transition={{
        type: "spring",
        duration: 0.75,
        delay: 5,
      }}
    >
      <TextContainer>
        <Heading size={3} bold align="center">
          Get the app
        </Heading>
        <Text
          size={1}
          style={{ marginTop: `${pxToRem(16)}`, textAlign: "center" }}
        >
          Search jobs on the go. Never miss a match with our new job alerts.
        </Text>
        <ButtonContainer style={{ marginTop: `${pxToRem(24)}` }}>
          <AppleBadge />
          <GoogleBadge />
        </ButtonContainer>
      </TextContainer>
      <ImageContainer>
        <StyledImg src="https://storage.googleapis.com/otta-static/images/search-app/phones-v2.png" />
      </ImageContainer>
    </BottomBar>
  );
}
