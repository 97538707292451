export function Clipboard(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="24"
      height="33"
      viewBox="0 0 24 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 4.75H15.4625C15.4875 4.5875 15.5 4.41875 15.5 4.25C15.5 2.31875 13.9312 0.75 12 0.75C10.0688 0.75 8.5 2.31875 8.5 4.25C8.5 4.41875 8.5125 4.5875 8.5375 4.75H3C1.34375 4.75 0 6.09375 0 7.75V29.75C0 31.4062 1.34375 32.75 3 32.75H21C22.6562 32.75 24 31.4062 24 29.75V7.75C24 6.09375 22.6562 4.75 21 4.75ZM12 2.75C12.8313 2.75 13.5 3.41875 13.5 4.25C13.5 5.08125 12.8313 5.75 12 5.75C11.1687 5.75 10.5 5.08125 10.5 4.25C10.5 3.41875 11.1687 2.75 12 2.75ZM22 29.75C22 30.3 21.55 30.75 21 30.75H3C2.45 30.75 2 30.3 2 29.75V7.75C2 7.2 2.45 6.75 3 6.75H6V8C6 8.4125 6.3375 8.75 6.75 8.75H17.25C17.6625 8.75 18 8.4125 18 8V6.75H21C21.55 6.75 22 7.2 22 7.75V29.75ZM7 21.25C6.16875 21.25 5.5 21.9188 5.5 22.75C5.5 23.5812 6.16875 24.25 7 24.25C7.83125 24.25 8.5 23.5812 8.5 22.75C8.5 21.9188 7.83125 21.25 7 21.25ZM17.5 21.75H10.5C10.225 21.75 10 21.975 10 22.25V23.25C10 23.525 10.225 23.75 10.5 23.75H17.5C17.775 23.75 18 23.525 18 23.25V22.25C18 21.975 17.775 21.75 17.5 21.75ZM7.8875 17.65L11.9 13.675C12.0312 13.5437 12.0312 13.3313 11.9 13.2L11.1125 12.4062C10.9812 12.275 10.7688 12.275 10.6375 12.4062L7.6625 15.3562L6.375 14.05C6.24375 13.9187 6.03125 13.9187 5.9 14.05L5.10625 14.8375C4.975 14.9688 4.975 15.1812 5.10625 15.3125L7.425 17.65C7.54375 17.7812 7.75625 17.7812 7.8875 17.65ZM17.5 15.75H12.65L10.6313 17.75H17.5C17.775 17.75 18 17.525 18 17.25V16.25C18 15.975 17.775 15.75 17.5 15.75Z"
        fill="black"
      />
    </svg>
  );
}
