import styled from "@xstyled/styled-components";

import GlassdoorStarIcon from "./icons/GlassdoorStar.png";
import { Star } from "./icons/Star";

interface IStarProps {
  styled: {
    on: boolean;
    starFraction: number;
    gradient: boolean;
  };
}

const trustpilotGreen = "#007F4E";
const glassdoorGreen = "#0CAA41";

const TrustpilotStar = styled(Star)<IStarProps>`
  height: 20px;
  margin: 10px 1px;
  background: ${({ styled: { on, gradient, starFraction } }) =>
    on
      ? trustpilotGreen
      : gradient && starFraction
      ? `linear-gradient(90deg, ${trustpilotGreen} ${starFraction}%, #dcdce6 0)`
      : "#dcdce6"};
`;

const GlassdoorStar = styled.img<IStarProps>`
  height: 14px;
  padding: 3px;
  box-sizing: content-box;
  background: ${({ styled: { on, gradient, starFraction } }) =>
    on
      ? glassdoorGreen
      : gradient && starFraction
      ? `linear-gradient(90deg, ${glassdoorGreen} ${starFraction}%, #eaeaea 0)`
      : "#eaeaea"};
  margin: 10px 1px;
`;

const trustpilotMax = 5;
const glassdoorMax = 5;
const numberOfStars = 5;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export function TrustpilotRating({
  score,
  ...props
}: {
  score: number;
}): React.ReactElement {
  const convertedScore = numberOfStars * (score / trustpilotMax);
  const numberOfFullStars = Math.floor(convertedScore);
  const starFraction = 100 * (convertedScore % 1);
  return (
    <Wrapper data-testid="company-trustpilot-rating">
      {[...Array(numberOfStars)].map((_, index) => (
        <TrustpilotStar
          {...props}
          data-testid="trustpilot-star"
          key={index}
          styled={{
            on: index < numberOfFullStars,
            starFraction,
            gradient: index === numberOfFullStars,
          }}
        />
      ))}
    </Wrapper>
  );
}

export function GlassdoorRating({
  score,
  ...props
}: {
  score: number;
}): React.ReactElement {
  const convertedScore = numberOfStars * (score / glassdoorMax);
  const numberOfFullStars = Math.floor(convertedScore);
  const starFraction = 100 * (convertedScore % 1);
  return (
    <Wrapper data-testid="company-glassdoor-rating">
      {[...Array(numberOfStars)].map((_, index) => (
        <GlassdoorStar
          {...props}
          key={index}
          alt="Glassdoor star icon"
          src={GlassdoorStarIcon}
          styled={{
            on: index < numberOfFullStars,
            starFraction,
            gradient: index === numberOfFullStars,
          }}
        />
      ))}
    </Wrapper>
  );
}
