import styled from "@xstyled/styled-components";

import BBC from "./logos/BBC.png";
import EveningStandard from "./logos/Evening-Standard.png";
import TechCrunch from "./logos/TechCrunch.png";

import { Overline, Spacing } from "@otta/design";
import { Country } from "@otta/search/constants/locations";

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;

const PressImage = styled.img`
  width: auto;
  height: 27px;
  margin: 0 10px 4px 10px;
  filter: brightness(0);
`;

interface IPressProps {
  userLocation: Country | undefined;
}

export function Press({ userLocation }: IPressProps): React.ReactElement {
  return (
    <div>
      <Spacing>
        <Overline align="center" size={-1} color="black">
          As featured in
        </Overline>
        <ImageWrapper>
          <PressImage src={BBC} alt="BBC" />
          {userLocation === Country.UK && (
            <PressImage src={EveningStandard} alt="The Evening Standard" />
          )}
          <PressImage src={TechCrunch} alt="TechCrunch" />
        </ImageWrapper>
      </Spacing>
    </div>
  );
}
