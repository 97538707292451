import { DocumentNode } from "@apollo/client";
import { FormApi } from "final-form";

import { FormCard } from "./FormCard";
import { LoadingCard } from "./LoadingCard";

import { useQuery } from "@otta/search/apollo";

interface IFormQueryCardProps<QueryData, IncomingData, OutgoingData> {
  title: string;
  introText?: string;
  tip?: string;
  query: DocumentNode;
  formatIncomingData: (data: QueryData) => IncomingData;
  formatOutgoingData: (values: IncomingData) => OutgoingData;
  onSubmit: (values: OutgoingData) => void;
  editingComponent: React.ComponentType<{
    form: FormApi<IncomingData>;
    data: QueryData | null;
  }>;
  displayComponent: React.ComponentType<{ data: QueryData }>;
  "data-testid"?: string;
}

export function FormQueryCard<QueryData, IncomingData, OutgoingData>({
  title,
  introText,
  tip,
  query,
  formatIncomingData,
  formatOutgoingData,
  onSubmit,
  editingComponent,
  displayComponent,
  "data-testid": dataTestId,
}: IFormQueryCardProps<
  QueryData,
  IncomingData,
  OutgoingData
>): React.ReactElement {
  const { data } = useQuery<QueryData>(query);
  if (!data) {
    return <LoadingCard />;
  }

  return (
    <FormCard
      title={title}
      introText={introText}
      tip={tip}
      data={data}
      formatIncomingData={formatIncomingData}
      formatOutgoingData={formatOutgoingData}
      onSubmit={onSubmit}
      editingComponent={editingComponent}
      displayComponent={displayComponent}
      data-testid={dataTestId}
      data-cs-mask
    />
  );
}
