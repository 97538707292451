import styled from "@xstyled/styled-components";

import { SendApplicationButton } from "../components/SendApplicationButton";

import { Button } from "@otta/design";
import { Link } from "@otta/search/components/Link";

const PreviewLink = styled(Link)`
  display: block;

  button {
    width: 100%;
  }
`;

export function Ctas({
  jobId,
  disabled,
  showConfirmationPopup,
}: {
  jobId: string;
  disabled: boolean;
  showConfirmationPopup: () => void;
}) {
  return (
    <>
      <PreviewLink underline={false} to={`/jobs/${jobId}/apply/preview`}>
        <Button disabled={disabled} level="primary">
          Preview application
        </Button>
      </PreviewLink>
      <SendApplicationButton
        jobId={jobId}
        disabled={disabled}
        showConfirmationPopup={showConfirmationPopup}
        level="secondary"
      />
    </>
  );
}
