import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useFirstMountState, useMountedState } from "react-use";

import { BottomButtonBar } from "./BottomButtonBar";
import { AnimatedQuizLayout } from "./AnimatedQuizLayout";
import { QuizLayout } from "./QuizLayout";

import { pushAnalyticsEvent } from "@otta/analytics";
import { Loading } from "@otta/search/components/Loading";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { Redirect } from "@otta/search/router";

export interface QuestionComponentProps {
  nextEnabled: boolean;
  setNextEnabled: (r: boolean) => void;
  handleNext: () => void;
  handleSkip?: () => void;
}
export interface Question {
  skip?: boolean;
  title: React.ReactNode;
  path: string;
  subtitle?: React.ReactNode;
  component: React.ComponentType<QuestionComponentProps>;
}

export type EventProperties = Partial<
  Record<string, string | number | readonly string[]>
>;

interface QuizProps {
  questions: Question[];
  onFinish: () => void;
  finishText: string;
  analyticsName?: string;
  customHeaderText?: string;
  animated?: boolean;
  progressBanner?: boolean;
  eventProperties?: () => Promise<EventProperties>;
}

export interface QuizLayoutProps extends QuestionComponentProps {
  customHeaderText?: string;
  progress: number;
  buttons: React.ReactElement;
  question: Question;
}

function QuizComponent({
  animated,
  questions,
  customHeaderText,
  finishText,
  analyticsName,
  onFinish,
  eventProperties,
  progressBanner,
}: QuizProps): React.ReactElement {
  const location = useLocation();
  const { question } = useParams<"question">();
  const navigate = useNavigate();
  const { dispatchEvent } = useUserPreferences();

  const startQuestion = useMemo(
    () => questions.findIndex(q => !q.skip),
    [questions]
  );

  const currentQuestion = useMemo(
    () => questions.findIndex(q => q.path === question),
    [question, questions]
  );

  const isLastQuestion: boolean = currentQuestion + 1 === questions.length;
  const eventProps = eventProperties ?? (() => Promise.resolve({}));
  const isFirstMount = useFirstMountState();
  const isMounted = useMountedState();

  useEffect(() => {
    if (analyticsName && isFirstMount) {
      const evt = { eventName: "Candidate Began Quiz", name: analyticsName };
      eventProps().then(props => pushAnalyticsEvent({ ...evt, ...props }));
    }
    return () => {
      if (analyticsName && !isMounted()) {
        const did = isLastQuestion ? "Completed" : "Left";
        const evt = { eventName: `Candidate ${did} Quiz`, name: analyticsName };
        eventProps().then(props => pushAnalyticsEvent({ ...evt, ...props }));
      }
    };
  });

  const [direction, setDirection] = useState(1);
  const [nextEnabled, setNextEnabled] = useState(false);

  const handleButton = (forward: boolean, skip: boolean) => {
    const step = forward ? 1 : -1;
    setDirection(step);

    let newQuestionNumber = currentQuestion + step;

    while (questions[newQuestionNumber]?.skip) {
      newQuestionNumber += step;
    }

    setNextEnabled(false);

    if (newQuestionNumber < startQuestion) {
      return;
    }

    if (newQuestionNumber >= questions.length) {
      return onFinish();
    }

    const to = {
      pathname: `../${questions[newQuestionNumber].path}`,
      search: location.search,
    };

    if (skip) {
      return navigate(to, { replace: true });
    }

    navigate(to);
  };

  const numQuestions = questions.length;
  const actualQuestionNumber = currentQuestion + 1;

  if (currentQuestion === -1) {
    return <Redirect to=".." />;
  }

  if (actualQuestionNumber > numQuestions) {
    return <Loading />;
  }

  const buttons = (
    <BottomButtonBar
      negativeText="Back"
      showNegative={currentQuestion > startQuestion}
      positiveText={isLastQuestion ? finishText : "Next"}
      nextEnabled={nextEnabled}
      handlePositive={() => {
        // This is false if there is any event listener that calls preventDefault
        if (dispatchEvent("NEXT")) {
          handleButton(true, false);
        }
      }}
      handleNegative={() => handleButton(false, false)}
    />
  );

  const props = {
    progress: (100 * actualQuestionNumber) / numQuestions,
    buttons,
    nextEnabled,
    customHeaderText,
    question: questions[currentQuestion],
    setNextEnabled,
    handleNext: () => handleButton(true, false),
    handleSkip: () => handleButton(true, true),
  };

  if (animated) {
    return (
      <AnimatedQuizLayout
        direction={direction}
        progressBanner={progressBanner}
        firstQuestion={currentQuestion === startQuestion}
        {...props}
      />
    );
  }

  return <QuizLayout {...props} />;
}

function FirstQuestion({ questions }: Pick<QuizProps, "questions">) {
  const location = useLocation();

  // this handles the case where the first question in a quiz is optional / skippable
  const startIndex = questions.findIndex(({ skip }) => !skip);

  return (
    <Redirect
      to={{ pathname: questions[startIndex].path, search: location.search }}
    />
  );
}

export function Quiz(props: QuizProps): React.ReactElement {
  return (
    <Routes>
      <Route path="/" element={<FirstQuestion questions={props.questions} />} />
      <Route path=":question" element={<QuizComponent {...props} />} />
    </Routes>
  );
}
