import { ReactNode, useCallback, useRef, createContext, useMemo } from "react";

import { useDebugger, useDebuggerPortal } from "@otta/experiments";
import { pushExperimentToAmplitude } from "@otta/analytics";

interface IExperimentContext {
  toggles: Record<string, { variant: string; payload?: unknown }>;
  variantViewed(name: string, variant: string, sendToAmplitude: boolean): void;
}

export const ExperimentContext = createContext<IExperimentContext>({
  toggles: {},
  variantViewed() {
    return;
  },
});

interface ExperimentProviderProps {
  toggles: Record<string, { variant: string; payload?: unknown }>;
  definitions?: Record<string, { variant: string; payload?: unknown }[]>;
  children: ReactNode;
}

export function ExperimentProvider({
  toggles,
  definitions,
  children,
}: ExperimentProviderProps): React.ReactElement {
  const seenExperiments = useRef<Record<string, string>>({});

  const portal = useDebuggerPortal();
  const [overrides, onSeen, el] = useDebugger(definitions ?? {}, portal);

  const variantViewed = useCallback(
    (name: string, variant: string, sendToAmplitude: boolean) => {
      if (sendToAmplitude && seenExperiments.current[name] !== variant) {
        seenExperiments.current[name] = variant;
        pushExperimentToAmplitude(name, variant);
      }

      return onSeen(name, variant);
    },
    [onSeen]
  );

  const mergedToggles = useMemo(() => {
    if (definitions) {
      return Object.entries(overrides).reduce(
        (acc, [name, variant]) => ({
          ...acc,
          [name]: definitions[name].find(d => d.variant === variant) as {
            variant: string;
            payload?: unknown;
          },
        }),
        toggles
      );
    }

    return toggles;
  }, [overrides, definitions, toggles]);

  return (
    <>
      {el}
      <ExperimentContext.Provider
        value={{ toggles: mergedToggles, variantViewed }}
      >
        {children}
      </ExperimentContext.Provider>
    </>
  );
}
