import Dropzone, { DropzoneRootProps } from "react-dropzone";
import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";
import { useMemo } from "react";

import { Error } from "../../../components/FormData";

import { palette } from "@otta/design-tokens";
import { Spacing } from "@otta/design";
import { ExternalLink } from "@otta/search/components/ExternalLink";

const getColor = (props: DropzoneRootProps): string => {
  if (props.isDragAccept) {
    return palette.brand.green;
  }
  if (props.isDragReject) {
    return palette.extended.red.shade500;
  }
  if (props.isDragActive) {
    return palette.extended.yellow.shade100;
  }
  return palette.grayscale.shade200;
};

const DropzoneTitle = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
`;

const DropzoneSubTitle = styled.div`
  font-style: italic;
  text-align: center;
`;

const DropzoneContainer = styled.div<DropzoneRootProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 2px dashed ${palette.grayscale.shade200};
  border-color: ${props => getColor(props)};
  outline: none;
  transition: default;
  height: 200px;
  cursor: pointer;
  color: black;
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

const StyledError = styled(Error)`
  margin-top: 10px;
`;

const MAX_FILE_NUMBER = 1;
const MAX_FILE_SIZE = 8000000;

export function EditFileUploadField({
  isGreenhouse,
  name,
}: {
  isGreenhouse?: boolean;
  name: string;
}): React.ReactElement {
  const ACCEPTED_FILE_TYPES = useMemo(
    () =>
      isGreenhouse
        ? ({
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx"],
            "text/plain": [".txt"],
            "application/rtf": [".rtf"],
          } as Record<string, string[]>)
        : ({
            "application/pdf": [".pdf"],
            "image/jpeg": [".jpg", ".jpeg"],
            "image/png": [".png"],
            "application/zip": [".zip"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [".xlsx"],
          } as Record<string, string[]>),
    [isGreenhouse]
  );

  return (
    <Field
      name={name}
      render={({ input: { onChange, value }, meta }) => {
        const handleDrop = (acceptedFiles: File[]) => {
          if (acceptedFiles.length === 1) {
            onChange({ file: acceptedFiles[0], name: acceptedFiles[0].name });
          }
        };

        return (
          <>
            <Spacing>
              {value.url && (
                <ExternalLink
                  newTab
                  to={value.url}
                  onClick={e => e.stopPropagation()}
                >
                  {value.name}
                </ExternalLink>
              )}
              <Dropzone
                onDrop={handleDrop}
                maxFiles={MAX_FILE_NUMBER}
                maxSize={MAX_FILE_SIZE}
                accept={ACCEPTED_FILE_TYPES}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => (
                  <DropzoneContainer
                    {...getRootProps({
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                    })}
                  >
                    {value.file ? (
                      <>
                        <DropzoneTitle>{value.name}</DropzoneTitle>
                        <DropzoneSubTitle>
                          Click save to upload this file
                        </DropzoneSubTitle>
                      </>
                    ) : (
                      <>
                        <DropzoneTitle>
                          Drag a file or click here to browse
                        </DropzoneTitle>
                        <DropzoneSubTitle>
                          {isGreenhouse
                            ? ".pdf, .doc, .docx, .txt, .rtf less than 8mb"
                            : ".pdf, .jpeg, .png, .zip, .docx, .xlsx less than 8mb"}
                        </DropzoneSubTitle>
                      </>
                    )}
                    <input
                      {...getInputProps()}
                      data-testid="file-upload-input"
                    />
                  </DropzoneContainer>
                )}
              </Dropzone>
            </Spacing>
            {meta.error && meta.touched && (
              <StyledError>{meta.error}</StyledError>
            )}
          </>
        );
      }}
    />
  );
}
