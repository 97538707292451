import styled from "@xstyled/styled-components";

import { Card, Text } from "@otta/design";
import CompanyLogo from "@otta/search/components/CompanyLogo";

const CompanyName = styled(Text)`
  margin: 0;
  margin-bottom: 6px;
  text-align: left;
  font-size: 16px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const JobTitle = styled(Text)`
  margin: 0;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const JobContent = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const JobInfoContainer = styled.div`
  width: 100%;
  height: 60px;
`;

const CompanyLogoWrapper = styled.div`
  flex: 0 0 60px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 60px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

interface SectionCardProps {
  accent?: boolean;
  title: string;
  subtitle: string | null;
  company: {
    name: string;
    primaryColour: string | null;
    faviconPath: string | null;
  };
}

export function JobInfo({
  company,
  title,
  subtitle,
}: Pick<SectionCardProps, "title" | "subtitle" | "company">) {
  return (
    <JobContent>
      <CompanyLogoWrapper>
        <CompanyLogo company={company} />
      </CompanyLogoWrapper>
      <JobInfoContainer>
        <CompanyName bold data-testid="job-company-name">
          {company.name}
        </CompanyName>
        <JobTitle data-testid="job-title">
          <Text as="span">{title}</Text>
          {subtitle && ` - ${subtitle}`}
        </JobTitle>
      </JobInfoContainer>
    </JobContent>
  );
}

export function JobPreviewCard({
  title,
  subtitle,
  company,
  children,
}: React.PropsWithChildren<SectionCardProps>): React.ReactElement {
  return (
    <Card
      style={{
        position: "relative",
      }}
      data-testid="preview-card"
    >
      <JobInfo title={title} subtitle={subtitle} company={company} />
      <ActionButtonsContainer>{children}</ActionButtonsContainer>
    </Card>
  );
}
