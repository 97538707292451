import { up, css } from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

export const InsetScrollbar = css`
  ${up(
    "desktop",
    css`
      ::-webkit-scrollbar {
        width: 18px;
      }

      ::-webkit-scrollbar-track {
        margin: 8px 0;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 24px;
        box-shadow: inset 0 0 18px 18px ${palette.grayscale.shade400};
        border: solid 5px transparent;
        background: transparent;
      }
    `
  )}
`;
