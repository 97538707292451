interface DownArrowProps {
  colour: string;
  className?: string;
  bold?: boolean;
}

export function DownArrow({
  colour,
  className,
  bold,
}: DownArrowProps): React.ReactElement {
  return (
    <svg
      version="1.1"
      viewBox="0 0 15.816 9.387"
      xmlns="http://www.w3.org/2000/svg"
      stroke={colour}
      fill={colour}
      className={className}
    >
      <g
        transform="matrix(.868 0 0 .868 -2.0299 -5.1486)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={bold ? "1.5" : ".21211"}
      >
        <rect
          transform="rotate(-45)"
          x="-3.2457"
          y="18.027"
          width="11.884"
          height="1.4099"
        />
        <rect
          transform="rotate(45)"
          x="7.5528"
          y="1.8357"
          width="11.884"
          height="1.4099"
        />
      </g>
    </svg>
  );
}
