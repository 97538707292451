import styled from "@xstyled/styled-components";
import { Children, useState } from "react";

import { modularScale, palette } from "@otta/design-tokens";

const ShowMoreButton = styled.button`
  display: flex;
  font-size: ${modularScale(-1)};
  cursor: pointer;
  background: none;
  border: none;

  &:hover {
    text-decoration: underline;
  }
  &::after {
    content: "";
    display: inline-block;
    border: solid ${palette.brand.black};
    border-width: 0 2px 2px 0;
    height: 6px;
    width: 6px;
    transform: rotate(45deg) translate(6px, -3px);
  }
`;

interface IShowMoreProps {
  show: number;
  displayWithoutCalculation?: boolean;
  children: React.ReactNode;
  className?: string;
}

export function ShowMore({
  children,
  className,
  displayWithoutCalculation,
  show,
}: IShowMoreProps): React.ReactElement | null {
  const [more, setMore] = useState(false);

  const count = Children.count(children);

  if (count === 0) {
    return null;
  }

  if (count < show) {
    return <div className={className}>{children}</div>;
  }

  const childArray = Children.toArray(children);

  const rest = childArray.slice(show);
  return (
    <div>
      {childArray.slice(0, show)}
      {more && rest}
      {!more && rest.length > 0 && (
        <div className={className}>
          <ShowMoreButton
            data-testid="show-more-button"
            onClick={() => setMore(true)}
          >
            {displayWithoutCalculation
              ? "Show more"
              : "Show " + (count - show) + " more"}
          </ShowMoreButton>
        </div>
      )}
    </div>
  );
}
