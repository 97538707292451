import { NumericFormat } from "react-number-format";
import styled, { useUp } from "@xstyled/styled-components";
import { format } from "date-fns";
import { useMemo } from "react";

import { Spacing, Text } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import {
  Company,
  CompanyFundingRound,
  FundingAmount,
  Money,
} from "@otta/search/schema";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";
import { currencyPrefix } from "@otta/search/utils/currencies";
import { Icon } from "@otta/icons";

const SHOWN_FUNDING_ROUNDS = 2;

const FundingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: xl;
  padding: md;
  border-radius: 4;
  background-color: beige-200;
`;

const FundingRound = styled.div`
  align-items: center;
  display: flex;
  gap: md;
  width: 100%;
`;

const FundingDate = styled(Text)`
  color: gray-600;
  min-width: 76px;
  text-align: center;
`;

const FundingDot = styled.div`
  background-color: gray-400;
  border-radius: 50%;
  display: block;
  height: ${pxToRem(8)};
  min-height: ${pxToRem(8)};
  width: ${pxToRem(8)};
  min-width: ${pxToRem(8)};
  position: relative;

  &[data-islast="false"] {
    &::after {
      content: "";
      background-color: gray-400;
      top: 0;
      left: 3px;
      position: absolute;
      height: 48px;
      width: 2px;
    }
  }

  &[data-showtrail="true"] {
    &::after {
      content: "";
      background-image: linear-gradient(
        ${palette.grayscale.shade400} 60%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: right;
      background-size: 2px 7px;
      top: 0;
      left: 3px;
      position: absolute;
      height: 26.5px;
      width: 2px;
    }
  }
`;

const RoundTypeContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const RoundType = styled(Text)`
  padding: xxs sm;
  background-color: blue-200;
  border-radius: 4;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CompanyProfileDesktopWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: lg;
`;

const CompanyProfileDesktopTotalFundingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: xs;
  padding: md;
  border-radius: 4;
  background-color: beige-200;
`;

const CompanyProfileDesktopTotalFundingDescription = styled(Text)`
  color: gray-600;
`;

const Title = ({
  fundingRounds,
  integratedWithDealroom,
}: {
  fundingRounds: CompanyFundingRound[];
  integratedWithDealroom: boolean;
}) => {
  const numberFundingRounds = () => {
    if (fundingRounds.length <= 2) {
      return (
        <>
          ({fundingRounds.length} round
          {fundingRounds.length > 1 && "s"})
        </>
      );
    } else {
      return <>(last 2 of {fundingRounds.length} rounds)</>;
    }
  };

  if (integratedWithDealroom) {
    return (
      <TooltipWithIcon content="Data from Dealroom.co">
        <Text bold as="h2" size={1}>
          Funding {numberFundingRounds()}
        </Text>
      </TooltipWithIcon>
    );
  }
  return (
    <Text bold as="h2" size={1}>
      Funding {numberFundingRounds()}
    </Text>
  );
};

const formatFundingAmount = (funding: Money | FundingAmount) => {
  const millions = Math.ceil((Number(funding.amount) / 1000000) * 10) / 10;
  const showBillions = millions >= 1000;

  return (
    <NumericFormat
      value={showBillions ? millions / 1000 : millions}
      prefix={currencyPrefix(funding.currency)}
      suffix={showBillions ? "bn" : "m"}
      decimalScale={1}
      displayType="text"
    />
  );
};

const formatFundingDate = (round: { month: number; year: number }) => {
  return format(new Date(round.year, round.month - 1, 1), "MMM yyyy");
};

export const Chart = ({
  recentFundingRounds,
  hasMoreFundingRound,
}: {
  recentFundingRounds: CompanyFundingRound[];
  hasMoreFundingRound: boolean;
}) => {
  return (
    <FundingContainer>
      {recentFundingRounds.map((round, index) => {
        const isLast = index === recentFundingRounds.length - 1;

        return (
          <FundingRound key={round.id} data-testid="funding-round">
            <FundingDate>{formatFundingDate(round)}</FundingDate>
            {recentFundingRounds.length > 1 && (
              <FundingDot
                data-testid="funding-dot"
                data-islast={isLast}
                data-showtrail={isLast && hasMoreFundingRound}
              />
            )}
            <Text bold>{formatFundingAmount(round.funding)}</Text>
            {round.round && (
              <RoundTypeContainer>
                <RoundType bold size={-1}>
                  {round.round}
                </RoundType>
              </RoundTypeContainer>
            )}
          </FundingRound>
        );
      })}
    </FundingContainer>
  );
};

const TopInvestors = ({
  investors,
}: {
  investors: Company.Fragment["investors"];
}) => {
  return (
    <TooltipWithIcon content={investors.map(({ value }) => value).join(", ")}>
      <Icon icon="checkmark" />
      <Text size={-1} data-testid="investors">
        This company has top investors
      </Text>
    </TooltipWithIcon>
  );
};

const hasCompanyFunding = ({
  totalFunding,
  recentFundingRounds,
}: {
  totalFunding: Money | null;
  recentFundingRounds: CompanyFundingRound[];
}) =>
  totalFunding &&
  Number(totalFunding.amount) > 0 &&
  recentFundingRounds.length > 0;

export const shouldDisplayCompanyFunding = (
  company: CompanyFundingProps["company"]
) => {
  const recentFundingRounds = company.fundingRounds
    .slice()
    .sort((a, b) =>
      a.year > b.year ? -1 : a.year < b.year ? 1 : b.month - a.month
    )
    .slice(0, SHOWN_FUNDING_ROUNDS);

  return hasCompanyFunding({
    totalFunding: company.totalFunding,
    recentFundingRounds,
  });
};

interface CompanyFundingProps {
  company: Pick<
    Company.Fragment,
    "fundingRounds" | "totalFunding" | "investors" | "integratedWithDealroom"
  >;
  fromCompanyProfile?: boolean;
}

export function CompanyFunding({
  company,
  fromCompanyProfile,
}: CompanyFundingProps) {
  const { totalFunding, fundingRounds, investors, integratedWithDealroom } =
    company;
  const isTablet = useUp("tablet");

  const recentFundingRounds = useMemo(() => {
    return fundingRounds
      .slice()
      .sort((a, b) =>
        a.year > b.year ? -1 : a.year < b.year ? 1 : b.month - a.month
      )
      .slice(0, SHOWN_FUNDING_ROUNDS);
  }, [fundingRounds]);

  if (
    // We're not using shouldDisplayCompanyFunding since we would recalculate the funding rounds twice otherwise
    !hasCompanyFunding({ totalFunding, recentFundingRounds }) ||
    // This is checked in hasCompanyFunding but TS doesn't know it and complains later on
    totalFunding === null
  ) {
    return null;
  }

  const shouldShowTopInvestors = investors.length > 0 && fromCompanyProfile;

  if (fromCompanyProfile && isTablet) {
    return (
      <Spacing>
        <Title
          fundingRounds={fundingRounds}
          integratedWithDealroom={integratedWithDealroom}
        />
        <CompanyProfileDesktopWrapper>
          <Chart
            recentFundingRounds={recentFundingRounds}
            hasMoreFundingRound={fundingRounds.length > SHOWN_FUNDING_ROUNDS}
          />
          <CompanyProfileDesktopTotalFundingWrapper>
            <Text bold as="h2" data-testid="company-total-funding">
              {formatFundingAmount(totalFunding)}
            </Text>

            <CompanyProfileDesktopTotalFundingDescription>
              Total funding
            </CompanyProfileDesktopTotalFundingDescription>
          </CompanyProfileDesktopTotalFundingWrapper>
        </CompanyProfileDesktopWrapper>

        {shouldShowTopInvestors && <TopInvestors investors={investors} />}
      </Spacing>
    );
  }

  return (
    <Spacing>
      <Title
        fundingRounds={fundingRounds}
        integratedWithDealroom={integratedWithDealroom}
      />
      <Chart
        recentFundingRounds={recentFundingRounds}
        hasMoreFundingRound={fundingRounds.length > SHOWN_FUNDING_ROUNDS}
      />

      <Text bold as="h2" data-testid="company-total-funding">
        Total funding: {formatFundingAmount(totalFunding)}
      </Text>

      {shouldShowTopInvestors && <TopInvestors investors={investors} />}
    </Spacing>
  );
}
