import { useState, useCallback, useEffect, useRef } from "react";
import { ApolloQueryResult, FetchMoreQueryOptions } from "@apollo/client";
import { Link } from "react-router-dom";

import {
  Section,
  CardsContainer,
  SectionCard,
  ActionButtonWrapper,
} from "../Section";
import { TipCard } from "../TipCard";

import { Button } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import {
  DashboardDiscardedJobsDocument,
  DashboardJobFragment,
  DashboardDiscardedJobsQuery,
  DashboardDiscardedJobsQueryVariables,
} from "@otta/search/schema";

interface IDiscardedJobsProps {
  jobs: DashboardJobFragment[];
  fetchMore: (
    opts: FetchMoreQueryOptions<
      DashboardDiscardedJobsQueryVariables,
      DashboardDiscardedJobsQuery
    >
  ) => Promise<ApolloQueryResult<DashboardDiscardedJobsQuery>>;
}

const PAGE_LIMIT = 9;

export function DiscardedJobs({
  jobs,
  fetchMore,
}: IDiscardedJobsProps): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(jobs.length >= PAGE_LIMIT);

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleViewMore = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => {
    setLoading(true);
    fetchMore({
      query: DashboardDiscardedJobsDocument,
      variables: {
        offset: jobs.length,
        limit: PAGE_LIMIT,
      },
    })
      .then(result => {
        if (!isMounted.current) {
          return;
        }

        if ((result.data.currentUser?.discardedJobs.length ?? 0) < PAGE_LIMIT) {
          setHasMore(false);
        }

        setLoading(false);
      })
      .catch(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  }, [jobs.length, fetchMore]);

  return (
    <Section title="Seen" data-testid="discarded-section">
      <CardsContainer data-testid="discarded-jobs">
        {jobs.map(({ id, externalId, title, subtitle, company }) => (
          <SectionCard
            key={id}
            title={title}
            subtitle={subtitle}
            company={company}
          >
            <ActionButtonWrapper>
              <Button
                as={Link}
                size="small"
                level="secondary"
                to={`/dashboard/jobs/${externalId}`}
                style={{ display: "flex" }}
                data-analytics-id="dashboard-seen-section-view-job"
              >
                View
              </Button>
            </ActionButtonWrapper>
          </SectionCard>
        ))}
        {!jobs.length && <TipCard sectionType="DISCARDED" tipType="EMPTY" />}
      </CardsContainer>
      {loading && <Loading />}
      {hasMore && (
        <Button level="secondary" onClick={handleViewMore}>
          View more
        </Button>
      )}
    </Section>
  );
}
