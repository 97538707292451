import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { ValidPropertyType } from "@amplitude/analytics-types";

import { identifyUser, pushEventToDataLayer } from "@otta/analytics";

export function useAnalytics<U extends { id: string; email: string }>(
  user: U | null | undefined,
  properties: (user: U) => Record<string, ValidPropertyType | undefined>,
  dataLayerProperties?: (
    user: U
  ) => Record<string, ValidPropertyType | undefined>,
  { enabled = true, pushToDataLayer = false } = {}
) {
  useEffect(() => {
    if (!enabled || !user) {
      return;
    }
    Sentry.configureScope(scope => {
      scope.setUser({ email: user.email });
    });

    if (pushToDataLayer) {
      pushEventToDataLayer({
        event: "user-initialised",
        ...dataLayerProperties?.(user),
      });
    }

    identifyUser(user.id, properties(user));
  }, [enabled, properties, user]);
}
