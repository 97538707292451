import { useCallback, useMemo, useState } from "react";
import styled from "@xstyled/styled-components";

import { Overline, Spacing, Text } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { Checkbox } from "@otta/search/components/Input/Checkbox";
import { Location as LocationType } from "@otta/search/schema";
import { useUserLocation } from "@otta/search/hooks/useUserLocation";
import { LOCATION_NAME } from "@otta/search/utils/locations";
import { Country, locationCountries } from "@otta/search/constants/locations";
import { partition } from "@otta/search/utils/collections";

/**
 * A group of locations that should be shown together, so if the user's location
 * is part of the group then all the other locations within the group will be shown
 * above the "show more" line.
 */
export type LocationGroup = {
  locations: ReadonlyArray<LocationType>;
  visible?(country?: Country): boolean;
};

type LabelledLocation = {
  location: LocationType;
  label: string;
};

type LocationsByProximity = {
  international: readonly LabelledLocation[];
  nearby: readonly LabelledLocation[];
};

type Props = {
  value: readonly LocationType[];
  options: readonly LocationGroup[];
  valueChanged(locs: LocationType[]): void;
  remoteChanged?(checked: boolean): void;
  remoteSelected?: boolean;
  showRemote?: boolean;
  loading: boolean;
};

interface LocationCheckboxProps {
  label: string;
  checked: boolean;
  onChange: (preference: string, checked: boolean) => void;
  value: string;
}

const ClickableText = styled(Text)`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

function LocationCheckbox({
  label,
  checked,
  value,
  onChange,
}: LocationCheckboxProps): React.ReactElement {
  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => onChange(value, e.target.checked),
    [onChange, value]
  );

  return <Checkbox label={label} onChange={handleChange} checked={checked} />;
}

function mapToLabel(locations: LocationType[]): readonly LabelledLocation[] {
  return locations.flatMap(location => {
    const label = LOCATION_NAME[location];
    return label ? { location, label } : [];
  });
}

/**
 * Given a country we think the user is located in and grouped locations
 * then place the group(s) containing that country into nearby and others into international
 */
function groupByProximity(
  country: Country | undefined,
  options: readonly LocationGroup[]
): LocationsByProximity {
  const forCountry = options.filter(o => !o.visible || o.visible(country));
  if (country === undefined) {
    return {
      nearby: mapToLabel(forCountry.flatMap(l => l.locations)),
      international: [],
    };
  }
  const [international, nearby] = partition(forCountry)(({ locations }) =>
    locations.some(l => !country || locationCountries[l] == country)
  );

  const [others, top] = partition(nearby.flatMap(n => n.locations))(
    t => country !== undefined && locationCountries[t] == country
  );

  return {
    international: mapToLabel(international.flatMap(i => i.locations)),
    nearby: mapToLabel([...top, ...others]),
  };
}

/**
 * Do our selected preferences include
 * international locations?
 */
function shouldShowMore(
  prefs: readonly LocationType[],
  opts: LocationsByProximity
) {
  const oneEmpty = opts.international.length === 0 || opts.nearby.length === 0;
  const hasIntl = opts.international.some(l => prefs.includes(l.location));
  return hasIntl || oneEmpty;
}

/**
 * The purpose of this component is to handle showing groups of locations,
 * choosing the best group to show according to where you are and hiding the rest,
 * unless you've previously chosen locations in multiple groups.
 */
export function CommonLocation(props: Props): React.ReactElement {
  const { country, loading: loadingCountry } = useUserLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialCountry = useMemo(() => country, [country != null]);
  const [clickedShowMore, setShowMore] = useState(false);

  const options = useMemo(
    () => groupByProximity(initialCountry, props.options),
    [props.options, initialCountry]
  );

  const showInternational = useMemo(
    () => clickedShowMore || shouldShowMore(props.value, options),
    [options, props.value, clickedShowMore]
  );

  if (showInternational && !clickedShowMore) {
    setShowMore(true);
  }

  if (props.loading || loadingCountry) {
    return <Loading />;
  }

  const handleChange = (preference: string, checked: boolean) => {
    const set = new Set(props.value);
    const loc: LocationType = preference as LocationType;
    checked ? set.add(loc) : set.delete(loc);
    props.valueChanged(Array.from(set));
  };

  return (
    <div>
      <Spacing size={5}>
        <Spacing size={-4}>
          {options.nearby.map(({ location, label }) => (
            <LocationCheckbox
              key={location}
              label={label}
              onChange={handleChange}
              checked={props.value.some(pref => pref === location)}
              value={location}
            />
          ))}
          {props.showRemote && (
            <LocationCheckbox
              label={"Remote"}
              onChange={(_, checked) => {
                props.remoteChanged && props.remoteChanged(checked);
              }}
              checked={props.remoteSelected ?? false}
              value={"remoteOpportunities"}
            />
          )}
        </Spacing>
        {!showInternational && (
          <ClickableText
            onClick={() => setShowMore(true)}
            data-testid="show-international-countries-btn"
            bold
          >
            View more locations
          </ClickableText>
        )}
        {showInternational && options.international.length > 0 && (
          <Spacing>
            <Overline size={-1}>more locations</Overline>
            <Spacing size={-4}>
              {options.international.map(({ location, label }) => (
                <LocationCheckbox
                  key={location}
                  label={label}
                  onChange={handleChange}
                  checked={props.value.some(pref => pref === location)}
                  value={location}
                />
              ))}
            </Spacing>
          </Spacing>
        )}
      </Spacing>
    </div>
  );
}
