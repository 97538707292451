import { useParams } from "react-router-dom";

import { BulletPointSection } from "./BulletPointSection";

import { Job } from "@otta/search/schema";

function processRequirements(input: Job.Requirements[]) {
  const requirements = input.map(r => ({
    ...r,
    desirable: r.desirable || r.value.startsWith("(Desirable)"),
    value: r.value.replace("(Desirable) ", ""),
  }));

  return requirements.filter(r => r.value);
}

interface JobRequirementsProps {
  job: Pick<Job.Fragment, "requirements">;
}

export function JobRequirements({
  job: { requirements },
}: JobRequirementsProps): React.ReactElement | null {
  const { jobId: jobExternalId } = useParams();

  const bullets = processRequirements(requirements)
    .filter(r => !r.desirable)
    .map(r => ({
      id: r.id,
      text: r.value,
    }));

  const subBullets = processRequirements(requirements)
    .filter(r => r.desirable)
    .map(r => ({
      id: r.id,
      text: r.value,
    }));

  return (
    <BulletPointSection
      title="Who you are"
      bullets={bullets}
      bulletTestId="job-requirement-bullet"
      subtitle="Desirable"
      subBullets={subBullets}
      showVideo={jobExternalId === "SWpHVTgz"}
    />
  );
}
