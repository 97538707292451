import { useEffect, useMemo } from "react";
import { useIsPresent } from "framer-motion";
import styled from "@xstyled/styled-components";

import { QuestionComponentProps } from "..";

import { Text, SelectField } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { LanguageRequirementsDocument } from "@otta/search/schema";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { Tag, TagsContainer } from "@otta/search/components/Tag";

const topLanguages = [
  "French",
  "German",
  "Mandarin",
  "Italian",
  "Spanish",
  "Dutch",
  "Portuguese",
  "Arabic",
  "Hindi",
  "Urdu",
];

const LanguageTag = styled(Tag)`
  font-size: ${pxToRem(17)};
`;

const LanguageTagContainer = styled(TagsContainer)`
  padding-bottom: 20;
`;

const SelectFieldTitle = styled(Text)`
  padding-bottom: 10;
`;

const Subtitle = styled(Text)`
  padding-bottom: 23;
`;

export const LanguageRequirement = ({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement => {
  const { preferencesData, updatePreferences } = useUserPreferences();

  const { data } = useQuery(LanguageRequirementsDocument);

  const preferences = preferencesData?.languageRequirementPreferences ?? [];

  const isPresent = useIsPresent();

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(true);
    }
  }, [setNextEnabled, isPresent]);

  const otherLanguages = useMemo(
    () =>
      (data?.languageRequirements ?? []).filter(
        l => !topLanguages.includes(l.value)
      ),
    [data?.languageRequirements]
  );

  const tagLanguages = useMemo(
    () =>
      (data?.languageRequirements ?? []).filter(l =>
        topLanguages.includes(l.value)
      ),
    [data?.languageRequirements]
  );

  const languageOptions = useMemo(
    () =>
      otherLanguages
        .map(t => ({
          label: t.value,
          value: t.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [otherLanguages]
  );

  const selectedTopLanguages = useMemo(
    () =>
      preferences.filter(p =>
        tagLanguages.some(l => l.id === p.languageRequirementId)
      ),
    [preferences, tagLanguages]
  );

  const selectedOtherLanguages = useMemo(
    () =>
      preferences.filter(p =>
        otherLanguages.some(l => l.id === p.languageRequirementId)
      ),
    [preferences, otherLanguages]
  );

  if (!data || !preferencesData) {
    return <Loading />;
  }

  const handleSelect = (
    options: readonly { value: string; label: string }[]
  ) => {
    const input = options.map(({ value }) => ({
      languageRequirementId: value,
    }));

    updatePreferences({
      languageRequirementPreferences: [...input, ...selectedTopLanguages],
    });
  };

  const handleClick = (languageId: string) => {
    const set = new Set(selectedTopLanguages.map(s => s.languageRequirementId));
    if (set.has(languageId)) {
      set.delete(languageId);
    } else {
      set.add(languageId);
    }
    const input = Array.from(set).map(value => ({
      languageRequirementId: value,
    }));
    updatePreferences({
      languageRequirementPreferences: [...selectedOtherLanguages, ...input],
    });
  };

  return (
    <>
      <Subtitle align={"center"} size={0.5}>
        Add any languages other than English you are comfortable working in, so
        we can show you relevant jobs.
      </Subtitle>
      <LanguageTagContainer>
        {tagLanguages.map(({ id, value }) => (
          <LanguageTag
            key={id}
            data-testid="language-tag"
            backgroundColor={
              selectedTopLanguages.some(
                ({ languageRequirementId }) => id === languageRequirementId
              )
                ? palette.brand.yellow
                : palette.brand.white
            }
            onClick={() => handleClick(id)}
          >
            {value}
          </LanguageTag>
        ))}
      </LanguageTagContainer>
      <SelectFieldTitle align={"center"} size={0}>
        Can&apos;t find a language above?
      </SelectFieldTitle>
      <SelectField<string, true>
        placeholder="Type language here..."
        isMulti
        options={languageOptions}
        value={languageOptions.filter(({ value }) =>
          preferences.some(({ languageRequirementId: id }) => id === value)
        )}
        onChange={handleSelect}
      />
    </>
  );
};
