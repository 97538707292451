export function GitHub(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <g fill="#2F3942">
        <path d="m512 0c-282.75 0-512 229.25-512 512 0 226.25 146.69 418.12 350.16 485.81 25.594 4.688 34.938-11.125 34.938-24.625 0-12.188-0.469-52.562-0.719-95.312-142.38 30.937-172.47-60.375-172.47-60.375-23.312-59.125-56.844-74.875-56.844-74.875-46.531-31.75 3.53-31.125 3.53-31.125 51.406 3.562 78.47 52.75 78.47 52.75 45.688 78.25 119.88 55.625 149 42.5 4.654-33 17.904-55.625 32.5-68.375-113.66-12.937-233.22-56.875-233.22-253.06 0-55.938 19.969-101.56 52.656-137.41-5.219-13-22.844-65.094 5.062-135.56 0 0 42.938-13.75 140.81 52.5 40.812-11.406 84.594-17.031 128.12-17.219 43.5 0.188 87.312 5.875 128.19 17.281 97.688-66.312 140.69-52.5 140.69-52.5 28 70.531 10.375 122.56 5.125 135.5 32.812 35.844 52.625 81.469 52.625 137.41 0 196.69-119.75 240-233.81 252.69 18.438 15.875 34.75 47 34.75 94.75 0 68.438-0.688 123.62-0.688 140.5 0 13.625 9.312 29.562 35.25 24.562 203.31-67.812 349.88-259.69 349.88-485.81 0-282.75-229.25-512-512-512z" />
      </g>
    </svg>
  );
}
