import { Text } from "@otta/design";
import { ThemeId } from "@otta/search/schema";

export function ThemeDescription({
  themeId,
}: {
  themeId: ThemeId;
}): React.ReactElement {
  switch (themeId) {
    case ThemeId.ApplyViaOtta:
      return (
        <Text>
          Use your profile to apply seamlessly. Save time and stand out.
        </Text>
      );
    case ThemeId.FemaleFounders:
      return <Text>Companies founded or currently led by women.</Text>;
    case ThemeId.HasSalaries:
      return (
        <Text>
          Jobs with public salaries. Companies that practice salary transparency
          often have fairer pay.
        </Text>
      );
    case ThemeId.PreferredSector:
      return (
        <Text>
          All the jobs from your favourite industry. If these don&apos;t look
          quite right, we recommend changing your job preferences.
        </Text>
      );
    case ThemeId.NewlyAdded:
      return <Text>These are the latest jobs. Hot off the press.</Text>;
    case ThemeId.RecentlyFunded:
      return (
        <Text>
          Companies which have raised investor funding in the last three months.
          These companies tend to be in a strong financial position.
        </Text>
      );
    case ThemeId.FullyRemote:
      return (
        <Text>
          Meaningful jobs - without the commute. If the remote locations
          don&apos;t look quite right, we recommend changing your job
          preferences.
        </Text>
      );
    case ThemeId.TechForGood:
      return (
        <Text>
          Companies driving positive change in the world. Think sustainability
          and social impact.
        </Text>
      );
    case ThemeId.FavouriteTechnologies:
      return (
        <Text>
          All the jobs that use your favourite technologies. If these don&apos;t
          look quite right, we recommend changing your job preferences.
        </Text>
      );
    case ThemeId.TakeAnotherLook:
      return (
        <Text>
          All the jobs you&apos;ve already seen that are still hiring.
        </Text>
      );
  }
}
