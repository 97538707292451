import "react-phone-input-2/lib/style.css";

import { Field } from "react-final-form";
import PI from "react-phone-input-2";
import validator from "validator";

import { Error, SimpleValue } from "../../components/FormData";

import { Label, Spacing } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import {
  LanguageRequirementsDocument,
  UserAdditionalInfoQuery,
} from "@otta/search/schema";
import { FinalSelect } from "@otta/search/components/Input/FinalSelect";
import { mapQueryResultToSelectStructure } from "@otta/search/components/Input/Select";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";
import { useUserLocation } from "@otta/search/hooks/useUserLocation";
import { VISA_LOCATIONS } from "@otta/search/constants/locations";

// This is a hack because react-phone-input-2 doesn't export a umd module and is badly written
const PhoneInput = (PI as { default?: typeof PI }).default ?? PI;

const isValidPhoneNumber = (phoneNumber?: string) =>
  typeof phoneNumber === "string" &&
  validator.isMobilePhone(
    phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`,
    "any",
    {
      strictMode: true,
    }
  );

export function EditingAdditionalInfo(): React.ReactElement {
  const { data } = useQuery(LanguageRequirementsDocument);
  const languages = (data?.languageRequirements ?? []).map(
    mapQueryResultToSelectStructure
  );
  const { country: userLocation } = useUserLocation();

  return (
    <Spacing>
      <Field
        name="phoneNumber"
        validate={val =>
          val && !isValidPhoneNumber(val)
            ? "Enter a valid mobile number"
            : undefined
        }
        render={({ input, meta }) => (
          <Spacing size={-4}>
            <TooltipWithIcon content="We show this to hiring managers when they're interviewing you">
              <Label>Mobile number</Label>
            </TooltipWithIcon>
            <PhoneInput
              {...input}
              country={userLocation === "US" ? "us" : "gb"}
              placeholder={
                userLocation === "US" ? "+1 (123) 456-7890" : "+44 7712 345678"
              }
            />
            {meta.error && <Error>{meta.error}</Error>}
          </Spacing>
        )}
      />
      <Spacing size={-4}>
        <Label htmlFor="visa-locations">I require a visa to work in</Label>
        <FinalSelect
          id="visa-locations"
          name="visaLocations"
          options={VISA_LOCATIONS}
          isMulti
        />
      </Spacing>
      <Spacing size={-4}>
        <TooltipWithIcon
          content={`We assume all candidates on Welcome to the Jungle can work in English, so you don't need to include that here.`}
        >
          <Label htmlFor="languages-spoken">Languages</Label>
        </TooltipWithIcon>
        <FinalSelect
          id="languages-spoken"
          name="languageRequirements"
          options={languages}
          isMulti
        />
      </Spacing>
    </Spacing>
  );
}

export function DisplayAdditionalInfo({
  data,
}: {
  data: UserAdditionalInfoQuery;
}): React.ReactElement | null {
  const user = data?.currentUser;

  const requiresVisa =
    (data?.currentUser?.visaRequirementCountries ?? []).length !== 0;

  const { data: languageData } = useQuery(LanguageRequirementsDocument);
  if (!data || !user) {
    return null;
  }

  const allLanguages = languageData?.languageRequirements ?? [];
  const userLanguages =
    (user.languageRequirementPreferences ?? []).length > 0
      ? user.languageRequirementPreferences
          .map(
            ({ languageRequirementId: id }) =>
              allLanguages.find(({ id: languageId }) => id === languageId)
                ?.value
          )
          .join(", ")
      : "N/A";
  return (
    <div>
      <SimpleValue title="Mobile number" value={user.phoneNumber ?? "N/A"} />
      <SimpleValue title="Require a visa" value={requiresVisa ? "Yes" : "No"} />
      <SimpleValue title="Languages" value={userLanguages} />
    </div>
  );
}
