import { ReactNode } from "react";
import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

const MissionCardContainer = styled.div`
  display: flex;
  background: ${palette.brand.grey};
  flex-direction: column;
  border-radius: 4;
  padding: 1rem;
  gap: 0.5rem;
`;

export function MissionCard({
  children,
}: {
  children: ReactNode;
}): React.ReactElement | null {
  return (
    <MissionCardContainer>
      <Text as="h4" bold>
        Our mission
      </Text>
      {children}
    </MissionCardContainer>
  );
}
