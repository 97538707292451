import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

export const CompanyLogoPlaceholder = styled.div<{
  backgroundColor: string | null;
}>`
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  user-select: none;
  color: white;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : palette.brand.yellow};
`;
