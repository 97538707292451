import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";

import { useQuery } from "@otta/search/apollo";
import { evictJobRecommendationsCache } from "@otta/search/pages/Jobs/JobDeck/mutationUpdaterFn";
import { UserPreferencesProvider } from "@otta/search/providers/UserPreferencesProvider";
import { CurrentUserDocument } from "@otta/search/schema";

interface PreferencesRouteProps {
  children: React.ReactNode;
}

export function PreferencesRoute({
  children,
}: PreferencesRouteProps): React.ReactElement {
  const { data } = useQuery(CurrentUserDocument);
  const loggedIn = !!data?.currentUser;
  const cache = useApolloClient().cache;

  useEffect(() => {
    if (loggedIn) {
      evictJobRecommendationsCache(cache);
    }
  }, [loggedIn, cache]);

  return <UserPreferencesProvider>{children}</UserPreferencesProvider>;
}
