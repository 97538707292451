import { Button, Card, Paragraph, Spacing, Text } from "@otta/design";

interface IActionCardProps {
  heading?: string;
  description: string;
  level?: "primary" | "secondary";
  ctaText: string;
  onClick: () => void;
  isDisabled?: boolean;
  analyticsId?: string;
}

export default function ActionCard({
  heading,
  description,
  level,
  ctaText,
  onClick,
  isDisabled,
  analyticsId,
}: IActionCardProps): React.ReactElement {
  return (
    <Card>
      <Spacing>
        {heading && (
          <Text size={1} bold>
            {heading}
          </Text>
        )}
        <Paragraph>{description}</Paragraph>
        <Button
          data-analytics-id={analyticsId}
          level={level ?? "primary"}
          onClick={onClick}
          disabled={isDisabled}
        >
          {ctaText}
        </Button>
      </Spacing>
    </Card>
  );
}
