import { useMutation } from "@apollo/client";
import styled, { css, up } from "@xstyled/styled-components";

import {
  Card,
  Spacing,
  Text,
  Button,
  Dropdown,
  DropdownItem,
  Toggle,
} from "@otta/design";
import { Icon } from "@otta/icons";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  UpdateCurrentCandidateDocument,
  CreateCandidateCvPdfDocument,
  UserInput,
  ProfileTopDocument,
} from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";
import { LoadingCard } from "@otta/search/pages/Profile/components/LoadingCard";

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${up(
    "tablet",
    css`
      flex-direction: row;
    `
  )}
`;

const ButtonWrapper = styled.div`
  margin-right: 0;

  button {
    padding: md 19;
  }

  div,
  button {
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: md;
  }

  ${up(
    "tablet",
    css`
      width: auto;
      margin-right: sm;
      &:not(:last-child) {
        margin-bottom: 0;
      }

      div,
      button {
        width: auto;
      }
    `
  )}
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: lg;

  ${up(
    "desktop",
    css`
      flex-direction: row;
      justify-content: center;
      text-align: center;
      gap: sm;
    `
  )}
`;

const BulletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: sm;
  flex-basis: 100%;

  ${up(
    "desktop",
    css`
      flex-direction: column;
    `
  )}
`;

interface HeaderProps {
  firstName: string | undefined;
  hasProfile: boolean | undefined;
}

const Header = ({ firstName, hasProfile }: HeaderProps): React.ReactElement => {
  return (
    <Spacing>
      <Text data-cs-mask bold as="h2">
        Hey {firstName},{" "}
        {hasProfile
          ? "this is your profile"
          : "it's time to create your profile"}
      </Text>
      <HeaderWrapper>
        <BulletWrapper>
          <Icon icon="search" size={2} />

          <Text>
            <strong>Get discovered</strong> by companies
          </Text>
        </BulletWrapper>
        <BulletWrapper>
          <Icon icon="edit" size={2} />

          <Text>
            <strong>Easily apply</strong> to jobs with your profile
          </Text>
        </BulletWrapper>
        <BulletWrapper>
          <Icon icon="visible-off" size={2} />
          <Text>
            Your current employer <strong>can&apos;t see you</strong>
          </Text>
        </BulletWrapper>
      </HeaderWrapper>
    </Spacing>
  );
};

interface SearchStageMap {
  [searchStage: string]: {
    shortText: string;
    longText?: string;
  };
}

const searchStageMap: SearchStageMap = {
  looking_now: {
    shortText: "ASAP",
    longText: "As soon as possible",
  },
  in_the_next_year: {
    shortText: "in the next year",
    longText: "In the next year",
  },
  for_the_right_role: {
    shortText: "for the right role",
    longText: "Not sure, I'd move for the right role",
  },
  just_curious: {
    shortText: "just curious",
    longText: "Not looking, I'm just curious",
  },
};

export function ProfileTop(): React.ReactElement | null {
  const { data } = useQuery(ProfileTopDocument);

  const [updateCurrentCandidateMutation] = useMutation(
    UpdateCurrentCandidateDocument,
    {
      onError: handleMutationError,
    }
  );

  const [createCvPdfMutation, { loading: createCvPdfLoading }] = useMutation(
    CreateCandidateCvPdfDocument,
    {
      onError: handleMutationError,
    }
  );

  const handleCreateCvPdf = async () => {
    const result = await createCvPdfMutation();
    const url = result?.data?.createCandidateCvPdf?.url;
    if (url) {
      window.open(url);
    }
  };

  if (!data) {
    return <LoadingCard />;
  }

  if (!data?.me) {
    return null;
  }

  const candidateData =
    data.me.__typename == "CurrentAdmin" ||
    data.me.__typename == "CurrentCandidate"
      ? data.me
      : null;

  const firstName = candidateData?.firstName;
  const searchStage = candidateData?.searchStage;
  const showProfileToCompanies = candidateData?.showProfileToCompanies;
  const workExperiences = candidateData?.workExperiences;
  const hasUnactionedMessages = candidateData?.hasUnactionedMessages;

  const handleUpdateUser = (input: UserInput): void => {
    if (candidateData) {
      updateCurrentCandidateMutation({
        variables: { input: input },
        optimisticResponse: () => ({
          updateCurrentCandidate: {
            ...candidateData,
            searchStage: input.searchStage ?? candidateData.searchStage,
            showProfileToCompanies:
              input.showProfileToCompanies ??
              candidateData.showProfileToCompanies,
          },
        }),
      });
    }
  };

  const handleProfileVisbilityToggle = (value: boolean) => {
    handleUpdateUser({ showProfileToCompanies: value });
    pushAnalyticsEvent({
      eventName: "Candidate Profile Visibility Toggled",
      visible: value,
    });
  };

  const hasProfile = workExperiences && workExperiences?.length > 0;

  return (
    <Card>
      <Spacing>
        <Header data-cs-mask firstName={firstName} hasProfile={hasProfile} />
        {hasProfile && !hasUnactionedMessages && (
          <Toggle
            data-testid="profile-visibility-toggle"
            label="Let companies find my profile"
            onChange={handleProfileVisbilityToggle}
            checked={showProfileToCompanies ?? false}
          />
        )}
        <ButtonsContainer>
          {hasProfile && (
            <>
              <ButtonWrapper>
                <Button
                  size="small"
                  level="secondary"
                  onClick={handleCreateCvPdf}
                  data-analytics-id="profile-export-pdf-button"
                >
                  {createCvPdfLoading ? "Loading..." : "Save to PDF"}
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Dropdown
                  toggler={
                    <Button
                      level="secondary"
                      size="small"
                      data-analytics-id="search-stage-dropdown"
                    >
                      Search stage:{" "}
                      {searchStage
                        ? searchStageMap[searchStage].shortText
                        : "NA"}
                    </Button>
                  }
                >
                  {Object.entries(searchStageMap).map(
                    ([stage, { longText }]) => (
                      <DropdownItem
                        key={stage}
                        onClick={() => handleUpdateUser({ searchStage: stage })}
                      >
                        {longText}
                      </DropdownItem>
                    )
                  )}
                </Dropdown>
              </ButtonWrapper>
            </>
          )}
        </ButtonsContainer>
      </Spacing>
    </Card>
  );
}
