export function Dribbble(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 100 100"
      {...props}
    >
      <g fill="#2F3942">
        <path d="m50 0c-27.603 0-50 22.397-50 50s22.397 50 50 50c27.549 0 50-22.397 50-50s-22.451-50-50-50zm33.026 23.048c5.9653 7.2668 9.5445 16.54 9.653 26.573-1.41-0.2712-15.51-3.1453-29.718-1.3558-0.3254-0.7049-0.5966-1.4642-0.9219-2.2234-0.8677-2.0607-1.8439-4.1757-2.82-6.1822 15.727-6.3991 22.885-15.618 23.807-16.811zm-33.026-15.672c10.846 0 20.77 4.0672 28.308 10.738-0.7592 1.0846-7.2126 9.7072-22.397 15.401-6.9957-12.852-14.751-23.373-15.944-25 3.1995-0.75922 6.5618-1.1388 10.032-1.1388zm-18.167 4.013c1.1388 1.5184 8.731 12.093 15.835 24.675-19.957 5.3145-37.581 5.2061-39.479 5.2061 2.7657-13.232 11.714-24.241 23.644-29.881zm-24.566 38.666v-1.3015c1.8438 0.0542 22.56 0.3254 43.872-6.0737 1.2473 2.3861 2.3861 4.8264 3.4707 7.2668-0.5423 0.1627-1.1388 0.3254-1.6811 0.488-22.017 7.1042-33.731 26.518-34.707 28.145-6.7788-7.538-10.954-17.57-10.954-28.525zm42.733 42.679c-9.8698 0-18.98-3.3623-26.193-9.0022 0.7593-1.5726 9.4361-18.276 33.514-26.681 0.1085-0.0542 0.1627-0.0542 0.2712-0.1085 6.0195 15.564 8.4599 28.633 9.1106 32.375-5.1518 2.2234-10.792 3.4165-16.703 3.4165zm23.807-7.321c-0.4339-2.6031-2.7115-15.076-8.2972-30.423 13.395-2.115 25.108 1.3557 26.573 1.8438-1.8438 11.876-8.6768 22.126-18.275 28.579z" />
      </g>
    </svg>
  );
}
