import { TextareaHTMLAttributes } from "react";

import { FieldContainer } from "./FieldContainer";
import { Label } from "./Label";
import { Textarea } from "./Textarea";
import { ErrorText } from "./ErrorText";

export interface ITextareaFieldProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  name: string;
  placeholder?: string;
  error?: string;
}

/**
 *
 * ```tsx
 *
 * import { TextareaField } from '@otta/design';
 *
 * ```
 */

export function TextareaField({
  label,
  name,
  error,
  placeholder,
  ...props
}: ITextareaFieldProps): React.ReactElement {
  return (
    <FieldContainer>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Textarea
        id={name}
        name={name}
        placeholder={placeholder}
        error={!!error}
        {...props}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </FieldContainer>
  );
}
