import { useEffect } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import styled from "@xstyled/styled-components";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { InputField, List, Spacing, Text, theme } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { Loading } from "@otta/search/components/Loading";
import { validLinkedinProfileUrl } from "@otta/search/utils/validators";
import { pushAnalyticsEvent } from "@otta/analytics";
import { ExternalLink } from "@otta/search/components/ExternalLink";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

const ExternalLinkWrapper = styled.div`
  margin-top: xl;
  margin-bottom: xl;

  @media (max-width: ${pxToRem(
      theme.screens.tablet
    )}) and (pointer: coarse) and (hover: none) {
    display: none;
  }
`;

export function LinkedinUrl({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const isPresent = useIsPresent();

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(true);
    }
  }, [setNextEnabled, isPresent]);

  const { preferencesData, updatePreferences } = useUserPreferences();

  if (!preferencesData) {
    return <Loading />;
  }

  return (
    <Spacing size={2} data-testid="linkedin-url-question">
      <Text align="center">Paste the link to your LinkedIn profile below</Text>
      <FinalForm<{ linkedinUrl: string | null }>
        initialValues={{ linkedinUrl: preferencesData.linkedinUrl }}
        onSubmit={input => {
          pushAnalyticsEvent({ eventName: "Entered LinkedIn URL" });
          updatePreferences({ linkedinUrl: input.linkedinUrl });
        }}
        render={({ handleSubmit }) => (
          <form style={{ textAlign: "left" }}>
            <ExternalLinkWrapper>
              <ExternalLink
                newTab
                to="https://www.linkedin.com/in/"
                align="center"
                onClick={() =>
                  pushAnalyticsEvent({
                    eventName: "Clicked Get your LinkedIn profile URL",
                  })
                }
              >
                Get your LinkedIn profile URL
              </ExternalLink>
            </ExternalLinkWrapper>
            <Field<string>
              data-cs-mask
              name="linkedinUrl"
              validate={validLinkedinProfileUrl}
            >
              {({ input, meta }) => (
                <InputField
                  {...input}
                  type="text"
                  name="linkedinUrl"
                  label="Linkedin URL"
                  data-testid="input-linkedin-url"
                  onBlur={handleSubmit}
                  error={meta.touched && meta.error}
                  placeholder="https://linkedin.com/in/your-name"
                />
              )}
            </Field>
          </form>
        )}
      />
      <List type="positive">
        <li>Be headhunted for exciting roles at our 1000+ companies</li>
        <li>
          Only hiring managers on our platform can contact you. No third party
          recruiters
        </li>
        <li>Your current employer cannot see your profile</li>
      </List>
    </Spacing>
  );
}
