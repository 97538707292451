import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { Recruiter } from "@otta/search/pages/Recruiter";
import { CurrentUserDocument, RoleType } from "@otta/search/schema";
import { Redirect } from "@otta/search/router";

interface CandidateRouteProps {
  children: React.ReactNode;
}

export function CandidateRoute({
  children,
}: CandidateRouteProps): React.ReactElement | null {
  const location = useLocation();
  const { data, loading } = useQuery(CurrentUserDocument, { ssr: true });

  if (loading) {
    return <Loading />;
  }

  if (!data?.currentUser?.role) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          search:
            location.pathname === "/"
              ? ""
              : queryString.stringify({
                  redirect: location.pathname,
                }),
        }}
      />
    );
  }

  if (data.currentUser.role === RoleType.CompanyRecruiter) {
    return <Recruiter />;
  }

  return <>{children}</>;
}
