import { QuestionComponentProps } from "..";

import { PositiveTechnologies } from "./PositiveTechnologies";

import { Spacing, Text } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { PreferenceQuestionWrapper } from "@otta/search/containers/Quiz/components/PreferenceQuestionWrapper";
import { TechnologyPreference } from "@otta/search/schema";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

const PositiveTechnologiesPreferenceQuestion = (
  props: QuestionComponentProps
): React.ReactElement => (
  <Spacing size={2}>
    <Spacing size={-4}>
      <Text align="center">
        Search for your favourite technologies, or choose from the list of
        popular tech below.
      </Text>
    </Spacing>
    <PositiveTechnologies {...props} />
  </Spacing>
);

export function PositiveTechnologiesPreference(
  props: QuestionComponentProps
): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();

  if (!preferencesData) {
    return <Loading />;
  }

  const preferences = preferencesData.technologiesUsedPreferences ?? [];

  const hasPreference =
    preferences.filter(
      ({ technologyPreference: pref }) =>
        pref === TechnologyPreference.Positive ||
        pref === TechnologyPreference.VeryPositive
    ).length > 0;

  const handleNoClick = () => {
    const negativePreferences = preferences
      .filter(
        ({ technologyPreference: pref }) =>
          pref === TechnologyPreference.Negative
      )
      .map(({ technologyUsedId, technologyPreference }) => ({
        technologyUsedId,
        technologyPreference,
      }));

    updatePreferences({
      technologiesUsedPreferences: negativePreferences,
    });
  };

  return (
    <PreferenceQuestionWrapper
      {...props}
      hasPreference={hasPreference}
      analyticsNoOptionName="no-positive-technologies-preference"
      analyticsYesOptionName="has-positive-technologies-preference"
      Component={PositiveTechnologiesPreferenceQuestion}
      onNoClick={handleNoClick}
      optionalQuestion
    />
  );
}
