import styled, { css, up } from "@xstyled/styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { Icon } from "@otta/icons";
import { Button, Spacing, Text } from "@otta/design";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${up(
    "tablet",
    css`
      align-items: flex-start;
    `
  )}
`;

const ButtonText = styled(Text)`
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: sm;
`;

interface IActionPanelProps {
  onSave: () => void;
  onApply: () => void;
  isSaved: boolean;
  isApplied: boolean;
  showApplyButton: boolean;
  testId?: string;
}

export function ActionPanel({
  onSave,
  onApply,
  isSaved,
  isApplied,
  showApplyButton,
  testId,
}: IActionPanelProps): React.ReactElement {
  return (
    <Container data-testid={testId}>
      <Spacing>
        {showApplyButton && (
          <Button
            onClick={onApply}
            key="apply-button"
            data-testid="apply-button"
            level="secondary"
          >
            <IconWrapper>
              <Icon icon="apply" />
              <AnimatePresence initial={false}>
                <AnimateContainer
                  key={isApplied ? "applied-button-text" : "apply-button-text"}
                >
                  <ButtonText bold>
                    {isApplied ? "Applied" : "Apply"}
                  </ButtonText>
                </AnimateContainer>
              </AnimatePresence>
            </IconWrapper>
          </Button>
        )}
        <Button
          onClick={onSave}
          key="save-button"
          data-testid="save-button"
          level="primary"
        >
          <IconWrapper>
            {isSaved ? <Icon icon="heart-filled" /> : <Icon icon="heart" />}
            <AnimatePresence initial={false} mode="wait">
              <AnimateContainer
                key={isSaved ? "saved-button-text" : "save-button-text"}
              >
                <ButtonText bold>{isSaved ? "Saved" : "Save"}</ButtonText>
              </AnimateContainer>
            </AnimatePresence>
          </IconWrapper>
        </Button>
      </Spacing>
    </Container>
  );
}

const AnimateContainer = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}): JSX.Element => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0, transition: { duration: 0.1, delay: 0 } }}
    transition={{ duration: 0.2, delay: 0.2 }}
  >
    {children}
  </motion.div>
);
