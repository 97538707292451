import styled from "@xstyled/styled-components";

import { Accordion } from "@otta/search/components/Accordion";
import { TipsList } from "@otta/search/components/components/Tips";

const TipWrapper = styled.div`
  margin: 28 auto;
  max-width: 490px;
  > div {
    margin-bottom: md;
  }
`;

export function SalaryTips(): React.ReactElement {
  return (
    <TipWrapper>
      <Accordion
        light
        noPadding
        sections={[
          {
            title: "How to handle salary conversations",
            isOpenAtStart: true,
            body: (
              <TipsList
                tips={[
                  {
                    value:
                      "If asked about salary expectations in an interview, give a range. They want to make sure they won’t waste your time. It’s not a negotiation",
                  },
                  {
                    value:
                      "If you have an offer, seek to understand how the company arrived at this to ensure you have all information (e.g. about how they perceive your experience level). Is there anything in their reasoning that’s worth challenging?",
                  },
                  {
                    value:
                      "Point to as much information as you can, e.g. showing roles at other companies. We hope we are a useful source for you",
                  },
                  {
                    value:
                      "If you don’t negotiate successfully, try to set expectations of when you’ll be able to discuss salary (e.g. in 6 months) so you can reopen the conversation easily",
                  },
                ]}
              />
            ),
          },
        ]}
      />
      <Accordion
        light
        noPadding
        sections={[
          {
            title: "Salaries by company size",
            body: (
              <TipsList
                tips={[
                  {
                    value:
                      "In our data we see companies with >500 employees pay 5-10% higher",
                  },
                  {
                    value:
                      "For companies with 2-500 employees there is no noticeable trend. There are small companies paying competitively",
                  },
                ]}
              />
            ),
          },
        ]}
      />
      <Accordion
        light
        noPadding
        sections={[
          {
            title: "Gender pay gap",
            body: (
              <TipsList
                tips={[
                  {
                    value:
                      "We don’t yet have enough data on salaries by gender. Help us out by submitting your salary",
                  },
                  {
                    value:
                      "We ask job seekers for minimum salary expectations, so we can filter out irrelevant jobs. From 25,000 data points we see on average women set their minimum at 10% lower than men",
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </TipWrapper>
  );
}
