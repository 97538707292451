export const Share = (
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        d="M13.6667 3.19052C13.6667 4.7679 12.3869 6.04766 10.8095 6.04766C9.94941 6.04766 9.17857 5.66671 8.65477 5.06552L5.98512 6.40183C6.02679 6.59528 6.02084 6.79468 6.02084 6.97326C6.02084 7.2054 6.02679 7.4048 5.98512 7.59826L8.65477 8.90778C9.17857 8.33337 9.94941 7.92564 10.8095 7.92564C12.3869 7.92564 13.6667 9.2054 13.6667 10.8096C13.6667 12.3869 12.3869 13.6667 10.8095 13.6667C9.20536 13.6667 7.95238 12.3869 7.95238 10.8096C7.95238 10.6042 7.97322 10.4048 8.01488 10.2114L5.34524 8.87504C4.82143 9.47623 4.0506 9.85718 3.19048 9.85718C1.6125 9.85718 0.333336 8.57742 0.333336 6.97326C0.333336 5.39587 1.6125 4.11611 3.19048 4.11611C4.0506 4.11611 4.82143 4.52385 5.34524 5.09826L8.01488 3.78873C7.97322 3.59528 7.95238 3.39587 7.95238 3.19052C7.95238 1.61254 9.20536 0.333374 10.8095 0.333374C12.3869 0.333374 13.6667 1.61254 13.6667 3.19052ZM3.16369 8.42861C3.97917 8.42861 4.59227 7.78873 4.59227 7.00004C4.59227 6.21135 3.97917 5.57147 3.16369 5.57147C2.40149 5.57147 1.73512 6.21135 1.73512 7.00004C1.73512 7.78873 2.40149 8.42861 3.16369 8.42861ZM10.8095 1.76195C10.0208 1.76195 9.38096 2.40183 9.38096 3.19052C9.38096 3.97921 10.0208 4.61909 10.8095 4.61909C11.5982 4.61909 12.2381 3.97921 12.2381 3.19052C12.2381 2.40183 11.5982 1.76195 10.8095 1.76195ZM10.8095 12.2381C11.5982 12.2381 12.2381 11.5983 12.2381 10.8096C12.2381 10.0209 11.5982 9.38099 10.8095 9.38099C10.0208 9.38099 9.38096 10.0209 9.38096 10.8096C9.38096 11.5983 10.0208 12.2381 10.8095 12.2381Z"
        fill="black"
      />
    </svg>
  );
};
