import { useMemo } from "react";

import { useQuery } from "@otta/search/apollo";
import { Country, locationCountries } from "@otta/search/constants/locations";
import { CurrentUserDocument, CurrentUserQuery } from "@otta/search/schema";
import { useStatus } from "@otta/search/providers/page";

type LocationData = CurrentUserQuery | undefined;

export interface IUserLocationReturnType {
  country: Country | undefined;
  loading: boolean;
}

/**
 * Canada and the US are much more complicated TZ wise than Europe
 * so we exhaustively list the possible Canadian timezones to disambiguate them
 * I got these from https://en.wikipedia.org/wiki/Time_in_Canada
 */
const timezones: Record<string, Country> = {
  "America/Atikokan": Country.CA,
  "America/Blanc-Sablon": Country.CA,
  "America/Cambridge_Bay": Country.CA,
  "America/Creston": Country.CA,
  "America/Dawson_Creek": Country.CA,
  "America/Dawson": Country.CA,
  "America/Edmonton": Country.CA,
  "America/Fort_Nelson": Country.CA,
  "America/Glace_Bay": Country.CA,
  "America/Goose_Bay": Country.CA,
  "America/Halifax": Country.CA,
  "America/Inuvik": Country.CA,
  "America/Iqaluit": Country.CA,
  "America/Moncton": Country.CA,
  "America/Montreal": Country.CA,
  "America/Nipigon": Country.CA,
  "America/Pangnirtung": Country.CA,
  "America/Rainy_River": Country.CA,
  "America/Rankin_Inlet": Country.CA,
  "America/Regina": Country.CA,
  "America/Resolute": Country.CA,
  "America/St_Johns": Country.CA,
  "America/Swift_Current": Country.CA,
  "America/Thunder_Bay": Country.CA,
  "America/Toronto": Country.CA,
  "America/Vancouver": Country.CA,
  "America/Whitehorse": Country.CA,
  "America/Winnipeg": Country.CA,
  "America/Yellowknife": Country.CA,
  "Europe/Amsterdam": Country.NL,
  "Europe/Ireland": Country.IE,
  "Europe/London": Country.UK,
  "Europe/Paris": Country.FR,
  "Europe/Spain": Country.ES,
};

function inferLocationFromTimezone(data: LocationData): Country | null {
  const timezone = data?.currentUser?.timezone?.location;

  if (!timezone) {
    return null;
  }

  if (timezones[timezone] !== undefined) {
    return timezones[timezone];
  }

  switch (timezone ? timezone.split("/", 1)[0] : "") {
    case "Europe":
      return Country.NL; // for alphabetical ordering
    case "America":
      return Country.US;
    default:
      return null;
  }
}

/**
 * Tries to figure out your location from the country that most
 * commonly occurs in your preferences
 */
function inferLocationFromPreferences(data: LocationData): Country | null {
  const prefs = data?.currentUser?.locationPreferences ?? [];
  const countries = prefs.flatMap(p => locationCountries[p.location] ?? []);
  let largest: { count: number; country: Country } | null = null;
  const counts: Partial<Record<Country, number>> = {};

  for (const country of [...countries].sort()) {
    const count = (counts[country] = (counts[country] ?? 0) + 1); // add to the count + return it
    largest = !largest || largest.count <= count ? { count, country } : largest;
  }

  return largest ? largest.country : null;
}

function inferLocationFromLocale(locale: string): Country | null {
  switch (locale) {
    case "en-US":
      return Country.US;
    case "en-GB":
      return Country.UK;
    case "en-CA":
      return Country.CA;
    default:
      return null;
  }
}

export function useUserLocation(): IUserLocationReturnType {
  const { data, loading } = useQuery(CurrentUserDocument, { ssr: true });
  const { locale } = useStatus();

  return useMemo(
    (): IUserLocationReturnType => ({
      loading,
      country:
        inferLocationFromPreferences(data) ??
        inferLocationFromTimezone(data) ??
        inferLocationFromLocale(locale) ??
        undefined,
    }),
    [data, loading, locale]
  );
}
