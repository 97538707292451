export function Person(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg viewBox="0 0 109.94 117" version="1.1" {...props}>
      <g id="g11" transform="translate(-32.53,-29)">
        <circle
          cx="87.489998"
          cy="66"
          r="37"
          id="circle7"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M 142.47,146 H 32.53 c 0.775,-17.338 15.055,-30.996 32.41,-31 h 45.12 c 17.355,0.004 31.635,13.662 32.41,31 z"
          id="path9"
        />
      </g>
    </svg>
  );
}
