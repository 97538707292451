import { QuestionComponentProps } from "..";

import { CompanySize } from "./CompanySize";

import { Spacing, Text } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { PreferenceQuestionWrapper } from "@otta/search/containers/Quiz/components/PreferenceQuestionWrapper";
import { CompanySizesDocument } from "@otta/search/schema";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

const CompanySizePreferenceQuestion = (
  props: QuestionComponentProps
): React.ReactElement => (
  <Spacing>
    <Text align="center">What size of company works best?</Text>
    <CompanySize {...props} />
  </Spacing>
);

export function CompanySizePreference(
  props: QuestionComponentProps
): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();

  const { data: companySizesData } = useQuery(CompanySizesDocument);

  if (!preferencesData || !companySizesData) {
    return <Loading />;
  }

  const preferences = preferencesData.companySizePreferences ?? [];
  const companySizes = companySizesData?.companySizes ?? [];
  const hasPreference =
    preferences.length > 0 && preferences.length < companySizes.length;
  const noPreference = preferences.length == companySizes.length;

  const handleNoClick = () => {
    updatePreferences({
      companySizePreferences: companySizes.map(({ id }) => ({
        companySizeId: id,
      })),
    });
  };

  const handleYesClick = () => {
    updatePreferences({
      companySizePreferences: [],
    });
  };

  return (
    <PreferenceQuestionWrapper
      {...props}
      hasPreference={hasPreference}
      noPreference={noPreference}
      analyticsNoOptionName="no-company-size-preference"
      analyticsYesOptionName="has-company-size-preference"
      Component={CompanySizePreferenceQuestion}
      onNoClick={handleNoClick}
      onYesClick={handleYesClick}
    />
  );
}
