import { SVGAttributes } from "react";

import { palette } from "@otta/design-tokens";

export function Gear(props: SVGAttributes<SVGElement>): React.ReactElement {
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="6" fill={palette.brand.yellow} />
      <g fill={palette.brand.black}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m5.896 12.049c0-3.3935 2.7553-6.1488 6.1488-6.1488 3.3935 0 6.1488 2.7553 6.1488 6.1488 0 3.3934-2.7553 6.1487-6.1488 6.1487-3.3935 0-6.1488-2.7553-6.1488-6.1487zm3.344 0c0-1.5481 1.2568-2.8048 2.8048-2.8048s2.8048 1.2567 2.8048 2.8048c0 1.548-1.2568 2.8047-2.8048 2.8047s-2.8048-1.2567-2.8048-2.8047z"
        />
        <path d="m10.286 5.276c0.0625-0.50043 0.4879-0.87597 0.9922-0.87597h1.7345c0.5043 0 0.9297 0.37554 0.9922 0.87597l0.1406 1.124h-4l0.1405-1.124z" />
        <path d="m17.179 7.2689c0.4742-0.17146 1.002 0.03709 1.2309 0.48644l0.7875 1.5454c0.2289 0.44935 0.0874 0.99884-0.3301 1.2818l-0.9377 0.6355-1.8159-3.5641 1.0653-0.38511z" />
        <path d="m18.75 13.63c0.4192 0.2804 0.5641 0.829 0.3379 1.2798l-0.7779 1.5502c-0.2262 0.4507-0.7526 0.6625-1.228 0.494l-1.0676-0.3786 1.794-3.5751 0.9416 0.6297z" />
        <path d="m6.8327 7.2689c-0.47428-0.17146-1.002 0.03709-1.231 0.48644l-0.78741 1.5454c-0.22896 0.44935-0.08748 0.99884 0.33 1.2818l0.93774 0.6355 1.816-3.5641-1.0653-0.38511z" />
        <path d="m5.2419 13.63c-0.41921 0.2804-0.56407 0.829-0.33788 1.2798l0.7779 1.5502c0.22619 0.4507 0.75263 0.6625 1.228 0.494l1.0677-0.3786-1.794-3.5751-0.94163 0.6297z" />
        <path d="m13.9 18.896c-0.0595 0.5008-0.4826 0.879-0.9869 0.8821l-1.7344 0.0106c-0.5043 0.0031-0.932-0.3698-0.9977-0.8698l-0.1474-1.1232 4-0.0246-0.1336 1.1249z" />
      </g>
    </svg>
  );
}
