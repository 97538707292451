import { useEffect, useMemo, useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";

import { SelectOptionFn } from "./SalaryReport";

import { Overline, Spacing, Button } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import {
  mapQueryResultToSelectStructure,
  Select,
} from "@otta/search/components/Input/Select";
import {
  AllJobSubFunctionsDocument,
  JobSubFunction,
  Location,
} from "@otta/search/schema";
import { AVAILABLE_CITIES, LocationOption } from "@otta/search/utils/locations";
import { useExperiment } from "@otta/search/hooks/unleash";
import { Experiment } from "@otta/search/constants/experiments";

const Container = styled.div`
  ${up(
    "tablet",
    css`
      display: flex;
      justify-content: space-between;
    `
  )}
`;

const Left = styled.div`
  flex: 0 1 ${pxToRem(560)};
  padding-top: lg;
`;

const Right = styled.div`
  flex: 0 1 ${pxToRem(280)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: lg;
`;

const ComparisonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    margin-bottom: lg;
  }

  ${up(
    "tablet",
    css`
      flex-direction: row;

      > div {
        max-width: ${pxToRem(280)};
        margin-right: lg;
        margin-bottom: 0;
      }
    `
  )}
`;

export interface Option {
  label: string;
  value: string;
}
interface SalaryFiltersProps {
  selectedSubFunction: JobSubFunction;
  comparisonSubFunction: JobSubFunction | null;
  selectedLocation: Location;
  onSelectSubFunction: SelectOptionFn;
  onSelectComparisonSubFunction: SelectOptionFn;
  onSelectLocation: SelectOptionFn<LocationOption>;
}

function useFilteredCities() {
  const { variant } = useExperiment(Experiment.AllSalaryBenchmarks);

  return useMemo(() => {
    return [
      ...AVAILABLE_CITIES.filter(
        city => variant === "variant" || city.value === Location.London
      ),
      ...(variant === "control"
        ? [
            {
              label: "More cities coming soon",
              value: Location.NewYorkUs,
              isDisabled: true,
            },
          ]
        : []),
    ];
  }, [variant]);
}

export function SalaryFilters({
  selectedSubFunction,
  comparisonSubFunction,
  selectedLocation,
  onSelectSubFunction,
  onSelectComparisonSubFunction,
  onSelectLocation,
}: SalaryFiltersProps): React.ReactElement {
  const { data: allJobSubFunctionsData } = useQuery(AllJobSubFunctionsDocument);

  const [compareSubFunctions, setCompareSubFunctions] = useState(false);
  const filteredCities = useFilteredCities();

  const subFunctions = useMemo(
    () =>
      allJobSubFunctionsData
        ? allJobSubFunctionsData.jobSubFunctions.map(
            mapQueryResultToSelectStructure
          )
        : [],
    [allJobSubFunctionsData]
  );

  const subFunctionsExSelected = subFunctions.filter(
    sf => sf.value !== selectedSubFunction.id
  );

  useEffect(() => {
    if (!comparisonSubFunction) {
      setCompareSubFunctions(false);
    }
  }, [comparisonSubFunction]);

  return (
    <Container>
      <Left>
        <Spacing size={-2}>
          <Overline size={-2}>Role type</Overline>
          <ComparisonWrapper>
            <Select
              options={subFunctions}
              value={subFunctions.find(
                sf => sf.value === selectedSubFunction.id
              )}
              onChange={onSelectSubFunction}
            />
            {compareSubFunctions ? (
              <Select
                options={subFunctionsExSelected}
                isClearable
                value={subFunctionsExSelected.find(
                  sf => sf.value === comparisonSubFunction?.id
                )}
                onChange={onSelectComparisonSubFunction}
              />
            ) : (
              <Button
                size="small"
                level="secondary"
                onClick={() => setCompareSubFunctions(true)}
                style={{ minWidth: "fit-content" }}
              >
                Compare role
              </Button>
            )}
          </ComparisonWrapper>
        </Spacing>
      </Left>
      <Right>
        <Spacing size={-2}>
          <Overline size={-2}>Location</Overline>
          <Select
            options={filteredCities}
            value={filteredCities.find(city => city.value === selectedLocation)}
            onChange={onSelectLocation}
            isOptionDisabled={city => !!city.isDisabled}
          />
        </Spacing>
      </Right>
    </Container>
  );
}
