import { MutationUpdaterFn, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css, down, up } from "@xstyled/styled-components";

import { NotFound } from "./NotFound";

import { boxShadow, modularScale } from "@otta/design-tokens";
import { Button as DefaultButton } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { Background, Overlay } from "@otta/search/components/Overlay";
import { useUserLocation } from "@otta/search/hooks/useUserLocation";
import { CloseButton } from "@otta/search/pages/Companies/components/CloseButton";
import { JobCardType } from "@otta/search/pages/Jobs/JobCard";
import { JobHeader } from "@otta/search/pages/Jobs/JobCard/JobHeader";
import {
  CreateJobReactionDocument,
  CreateJobReactionMutation,
  DashboardDocument,
  ShortlistJobDocument,
  UserJobPreferencesDocument,
  UserJobStatus,
} from "@otta/search/schema";
import { countryToCurrency } from "@otta/search/utils/currencies";

const Button = styled(DefaultButton)`
  margin: 0 xs;

  ${down(
    "tablet",
    css`
      font-size: ${modularScale(-1)};
    `
  )}
`;

const BottomBar = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: xl;
  background-color: white;
`;

const BottomBarInner = styled.div`
  box-shadow: ${boxShadow};
  padding: sm;
  margin: 0 auto;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${up(
    "tablet",
    css`
      max-width: 768px;
    `
  )}
  ${up(
    "desktop",
    css`
      max-width: 1024px;
    `
  )}
`;

const StyledOverlay = styled(Overlay)`
  background-color: yellow-500;
`;

const moveJobFromDiscarded =
  (id: string): MutationUpdaterFn<CreateJobReactionMutation> =>
  (cache, result) => {
    const cacheData = cache.readQuery({
      query: DashboardDocument,
    });

    if (!cacheData?.currentUser || !result.data?.createJobReaction) {
      return;
    }

    const { shortlistedJobs = [], discardedJobs = [] } = cacheData.currentUser;

    const job = discardedJobs.find(j => j.id === id);

    if (!job) {
      return;
    }

    cache.writeQuery({
      query: DashboardDocument,
      data: {
        ...cacheData,
        currentUser: {
          ...cacheData.currentUser,
          discardedJobs: discardedJobs.filter(j => j.id !== id),
          shortlistedJobs: [
            {
              ...job,
              userJobStatusInfo: {
                __typename: "UserJobStatusInfo",
                status: UserJobStatus.Saved,
                updatedAt: new Date().toISOString(),
              },
            },
            ...shortlistedJobs,
          ],
        },
      },
    });
  };

export default function ShortlistJob({
  batchEnd,
}: {
  batchEnd?: boolean;
}): React.ReactElement {
  const { jobId: jobExternalId } = useParams<{ jobId: string }>();

  const { country: userLocation } = useUserLocation();
  const currency = countryToCurrency(userLocation);

  const { data, loading } = useQuery(ShortlistJobDocument, {
    variables: { externalId: jobExternalId as string, currency },
  });

  const { data: userData, loading: userLoading } = useQuery(
    UserJobPreferencesDocument
  );

  const [createJobReactionMutation, { loading: saveButtonLoading }] =
    useMutation(CreateJobReactionDocument);

  const navigate = useNavigate();

  const handleApply = () => {
    navigate(
      batchEnd
        ? `/jobs/batch-end/${jobExternalId}/apply`
        : `/dashboard/jobs/${jobExternalId}/apply`,
      {
        state: batchEnd ? "from-batch-end" : undefined,
      }
    );
  };

  const handleClose = () => {
    navigate(batchEnd ? "/jobs/batch-end" : "/");
  };

  const job = data?.publicJob;

  const showBottomBar =
    job &&
    job.live &&
    [
      UserJobStatus.Saved,
      UserJobStatus.ApplicationStarted,
      UserJobStatus.NotSaved,
    ].includes(job.userJobStatusInfo.status);

  const shouldShowSaveButton =
    job?.userJobStatusInfo.status === UserJobStatus.NotSaved;

  const handleSave = async () => {
    if (!job) {
      return;
    }

    await createJobReactionMutation({
      variables: {
        jobId: job.id,
        direction: true,
        reason: null,
      },
      update: moveJobFromDiscarded(job.id),
    });

    navigate("/");
  };

  return (
    <>
      <Background
        onClick={handleClose}
        data-testid="shortlist-job-background"
      />
      <StyledOverlay>
        {loading || userLoading ? (
          <Loading />
        ) : !job ? (
          <NotFound />
        ) : (
          <>
            <CloseButton to={batchEnd ? "/jobs/batch-end" : "/"} />
            <ContentContainer data-testid="shortlist-job-content">
              <JobHeader
                job={job}
                user={userData?.currentUser ?? undefined}
                type={JobCardType.dashboard}
              />
            </ContentContainer>
          </>
        )}
      </StyledOverlay>
      {showBottomBar && (
        <BottomBar data-testid="apply-bottom-bar">
          <BottomBarInner>
            {shouldShowSaveButton && (
              <Button
                level="secondary"
                data-testid="save-button"
                onClick={handleSave}
              >
                {saveButtonLoading ? <>Saving...</> : <>Save for later</>}
              </Button>
            )}
            <Button
              level="primary"
              data-testid="apply-now-button"
              onClick={handleApply}
            >
              Apply now
            </Button>
          </BottomBarInner>
        </BottomBar>
      )}
    </>
  );
}
