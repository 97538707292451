import { ApolloCache } from "@apollo/client";

import { DashboardDocument } from "@otta/search/schema";

export const evictDashboardJobsCache = (cache: ApolloCache<unknown>): void => {
  const cacheData = cache.readQuery({
    query: DashboardDocument,
  });

  if (!cacheData?.currentUser) {
    return;
  }

  cache.evict({
    id: cache.identify({ ...cacheData.currentUser }),
    fieldName: "shortlistedJobs",
  });

  cache.evict({
    id: cache.identify({ ...cacheData.currentUser }),
    fieldName: "discardedJobs",
  });
};
