import { formatPhoneNumberIntl } from "react-phone-number-input";
import styled from "@xstyled/styled-components";

import { Spacing, Text } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { WebsiteIcon } from "@otta/search/components/WebsiteIcon";
import { Email as DefaultEmail } from "@otta/search/components/Icons/Email";
import { Phone as DefaultPhone } from "@otta/search/components/Icons/Phone";
import { LatestJobApplication } from "@otta/search/schema";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const WebsiteLinksWrapper = styled.div`
  display: inline-flex;
  margin-left: xs;
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EmailIcon = styled(DefaultEmail)`
  width: ${modularScale()};
  height: ${modularScale()};
  margin-right: xs;
`;

const PhoneIcon = styled(DefaultPhone)`
  width: ${modularScale(-2)};
  height: ${modularScale(-2)};
  margin-right: xs;
`;

const hasLinkedinUrl = (links: LatestJobApplication.WebsiteLinks[]) =>
  (links ?? []).filter(({ url }) => url?.includes("linkedin")).length > 0;

const WebsiteLinks = ({
  candidate,
}: {
  candidate: LatestJobApplication.Candidate;
}) => (
  <WebsiteLinksWrapper>
    {candidate.linkedinUrl && !hasLinkedinUrl(candidate.websiteLinks) && (
      <WebsiteIcon url={candidate.linkedinUrl} linkIcon />
    )}
    {candidate.websiteLinks?.length > 0 && (
      <>
        {candidate.websiteLinks.map(({ id, url }) => (
          <WebsiteIcon key={id} url={url} linkIcon />
        ))}
      </>
    )}
  </WebsiteLinksWrapper>
);

const Email = ({ email }: { email: string }) => (
  <ContactWrapper>
    <EmailIcon />
    <Text data-cs-mask size={-1} align="right">
      {email}
    </Text>
  </ContactWrapper>
);

const PhoneNumber = ({ phoneNumber }: { phoneNumber: string }) => (
  <ContactWrapper>
    <PhoneIcon />
    <Text data-cs-mask size={-1} align="right">
      {formatPhoneNumberIntl(phoneNumber)}
    </Text>
  </ContactWrapper>
);

export const CandidateInfo = ({
  candidate,
}: {
  candidate: LatestJobApplication.Candidate;
}): React.ReactElement => (
  <Wrapper data-testid="candidate-info-section">
    <Spacing size={-1}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Text data-cs-mask as="h3" bold size={1} align="left">
          {candidate.firstName} {candidate.lastName}
        </Text>
        <WebsiteLinks candidate={candidate} />
      </div>
      <Spacing size={-3}>
        <Email email={candidate.email} />
        {candidate.phoneNumber && (
          <PhoneNumber phoneNumber={candidate.phoneNumber} />
        )}
      </Spacing>
    </Spacing>
  </Wrapper>
);
