import { useEffect, useMemo, useState } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { CommonLocation, LocationGroup } from "./CommonLocation";

import { Location as LocationType } from "@otta/search/schema";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { Country, locationCountries } from "@otta/search/constants/locations";

const isSupportedCountry: (c: Country | undefined) => boolean = country =>
  country !== undefined;

const europeLocations = [
  LocationType.Amsterdam,
  LocationType.Barcelona,
  LocationType.Berlin,
  LocationType.Dublin,
  LocationType.London,
  LocationType.Paris,
];

const usLocations = [
  LocationType.AustinUs,
  LocationType.BostonUs,
  LocationType.ChicagoUs,
  LocationType.DenverUs,
  LocationType.LosAngelesUs,
  LocationType.MiamiUs,
  LocationType.NewYorkUs,
  LocationType.SanFranciscoBayAreaUs,
];

const caLocations = [LocationType.Toronto, LocationType.Vancouver];

const options: readonly LocationGroup[] = [
  {
    visible: isSupportedCountry,
    locations: europeLocations,
  },
  {
    visible: isSupportedCountry,
    locations: usLocations,
  },
  {
    visible: isSupportedCountry,
    locations: caLocations,
  },
  {
    visible: (c: Country | undefined) => !isSupportedCountry(c),
    locations: [...europeLocations, ...usLocations, ...caLocations].sort(),
  },
];

export function Location({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const {
    preferencesData,
    updatePreferences,
    setOnlyRemoteOpportunities,
    onlyRemoteOpportunities,
  } = useUserPreferences();

  const locationPreferences = useMemo(
    () => preferencesData?.locationPreferences ?? [],
    [preferencesData?.locationPreferences]
  );

  const baseLocationPreferences = useMemo(
    () => locationPreferences.filter(x => !x.location.includes("REMOTE")),
    [locationPreferences]
  );

  const [remoteSelected, setRemoteSelected] = useState(onlyRemoteOpportunities);

  const isPresent = useIsPresent();
  const isValid = remoteSelected || locationPreferences.length > 0;

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(isValid);
    }
  }, [isValid, isPresent, setNextEnabled]);

  useEffect(() => {
    const containsRemote = (preferencesData?.locationPreferences ?? []).some(
      val => val.location.includes("REMOTE")
    );
    if (containsRemote) {
      setRemoteSelected(true);
    }
  }, [preferencesData, setRemoteSelected]);

  const handleAddingRemoteOpportunities = (
    locations: ReadonlyArray<LocationType>
  ): ReadonlyArray<LocationType> => {
    return Array.from(
      new Set(
        locations.flatMap(l => {
          switch (locationCountries[l]) {
            case Country.US:
              return [l, LocationType.RemoteUs];
            case Country.UK:
              return [l, LocationType.RemoteUk];
            case Country.NL:
              return [l, LocationType.RemoteNetherlands];
            case Country.DE:
              return [l, LocationType.RemoteGermany];
            case Country.ES:
              return [l, LocationType.RemoteSpain];
            case Country.IE:
              return [l, LocationType.RemoteIreland];
            case Country.FR:
              return [l, LocationType.RemoteFrance];
            case Country.CA:
              return [l, LocationType.RemoteCanada];
            default:
              return [l];
          }
        })
      )
    );
  };

  const updateNewLocationPreferences = (
    locations: ReadonlyArray<LocationType>,
    remote: boolean
  ) => {
    const newLocationPreferences = remote
      ? handleAddingRemoteOpportunities(locations)
      : locations;

    updatePreferences({
      locationPreferences: newLocationPreferences.map(location => ({
        location,
      })),
    });

    setOnlyRemoteOpportunities(!newLocationPreferences.length && remote);
  };

  const handleChange = (locations: readonly LocationType[]) => {
    updateNewLocationPreferences(locations, remoteSelected);
  };

  const handleRemoteChange = (checked: boolean) => {
    setRemoteSelected(checked);
    const set = baseLocationPreferences.map(pref => pref.location);
    updateNewLocationPreferences(set, checked);
  };

  return (
    <CommonLocation
      options={options}
      valueChanged={handleChange}
      value={baseLocationPreferences.map(l => l.location)}
      remoteChanged={handleRemoteChange}
      remoteSelected={remoteSelected}
      loading={!preferencesData}
      showRemote={true}
    />
  );
}
