import { PublicInternalApplyModalContent } from "./PublicInternalModalContent";
import { Apply } from "./Apply";

import { useQuery } from "@otta/search/apollo";
import {
  CurrentUserDocument,
  ApplyModalJob,
  ThemeId,
} from "@otta/search/schema";

export function ApplyContent({
  token,
  job,
  onExternalApply,
  onClose,
  type,
  themeId,
}: {
  token?: string;
  job: ApplyModalJob.PublicJob;
  onExternalApply?(): void;
  onClose(): void;
  type: "DASHBOARD" | "JOB_DECK";
  themeId?: ThemeId;
}): React.ReactElement {
  const { data } = useQuery(CurrentUserDocument, {
    context: { emailToken: token },
  });

  const showSignUp = !data?.currentUser && !job.originalUrl;

  return (
    <div data-testid="apply-content">
      {showSignUp ? (
        <PublicInternalApplyModalContent job={job} />
      ) : (
        <Apply
          token={token}
          job={job}
          onExternalApply={onExternalApply}
          onClose={onClose}
          type={type}
          themeId={themeId}
        />
      )}
    </div>
  );
}
