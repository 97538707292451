import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";

import { Spacing, Paragraph } from "@otta/design";
import {
  composeValidators,
  maxLength,
  minLength,
} from "@otta/search/utils/validators";
import { Textarea } from "@otta/search/components/Input/Textarea";
import { UserInterestsQuery } from "@otta/search/schema";

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: xs;
`;

export function EditingInterests(): React.ReactElement {
  return (
    <ColumnWrapper>
      <Spacing>
        <Field
          name="profileInterests"
          validate={composeValidators(minLength(30), maxLength(200))}
          render={({ input, meta }) => (
            <Textarea
              data-cs-mask
              {...input}
              label=""
              placeholder="Sport (open-water swimming, basketball), hiking, cooking, hackathons (NHS Hack Day winner)"
              error={meta.touched && meta.error}
            />
          )}
        />
      </Spacing>
    </ColumnWrapper>
  );
}

export function DisplayInterests({
  data,
}: {
  data: UserInterestsQuery;
}): React.ReactElement {
  const profileInterests = data?.currentUser?.profileInterests;
  return (
    <div id="interests-form-id">
      <ColumnWrapper>
        <Paragraph data-cs-mask>{profileInterests}</Paragraph>
      </ColumnWrapper>
    </div>
  );
}
