import { Form } from "react-final-form";
import { useState, useMemo } from "react";
import deepEqual from "deep-equal";

import { FormState } from "../types";
import { EditQuestion } from "../EditQuestion";
import { DisplayQuestion } from "../DisplayQuestion";

import { SubmitWrapper } from "./shared";
import { matchQuestionsToAnswers } from "./utils";

import { Button, Spacing, ErrorText } from "@otta/design";
import {
  JobApplicationDataInput,
  OttaAnswerData,
  OttaAnswerInput,
  OttaAnswersFragment,
  OttaApplicationQuestion,
  OttaQuestionData,
} from "@otta/search/schema";
import { Pencil } from "@otta/search/components/Icons/Pencil";
import { Delete } from "@otta/search/components/Icons/Delete";
import { SectionHeader } from "@otta/search/pages/Profile/components/FormHeader";
import {
  Card,
  EditButton,
} from "@otta/search/pages/Profile/components/FormCard";
import { HeaderWrapper } from "@otta/search/pages/Profile/PublicProfile/components/Section";

type OttaFormState = FormState;

interface OttaFormProps {
  questionData: OttaQuestionData;
  answerData?: OttaAnswerData;
  onSubmit: (input: JobApplicationDataInput) => Promise<void>;
}

export function OttaForm({
  questionData,
  answerData = { __typename: "OttaAnswerData", answers: [] },
  onSubmit,
}: OttaFormProps): React.ReactElement {
  const [isEditing, setIsEditing] = useState(false);

  const questions = questionData.questions;

  const answers = useMemo(
    () =>
      matchQuestionsToAnswers<
        OttaApplicationQuestion[],
        OttaAnswersFragment["answers"]
      >(questions, answerData.answers),
    [questions, answerData.answers]
  );

  const initialValues = useMemo(() => ({ answers }), [answers]);

  const handleSubmit = async (input: OttaFormState) => {
    await onSubmit(formatMutationData(input));
    setIsEditing(false);
  };

  return (
    <Card
      clickable={!isEditing}
      onClick={isEditing ? undefined : () => setIsEditing(true)}
      data-testid="application-questions-card"
    >
      <Spacing>
        <HeaderWrapper>
          <SectionHeader bold size={1}>
            Application questions
          </SectionHeader>
          <EditButton
            data-testid="edit-button"
            onClick={() => setIsEditing(false)}
          >
            {isEditing ? <Delete /> : <Pencil id="edit-button" />}
          </EditButton>
        </HeaderWrapper>
        {isEditing ? (
          <Form<OttaFormState>
            onSubmit={handleSubmit}
            initialValues={initialValues}
            initialValuesEqual={(oldState, newState) =>
              deepEqual(oldState, newState)
            }
            render={({ handleSubmit, valid, submitFailed }) => (
              <form onSubmit={handleSubmit}>
                {questions.map((q, index) => (
                  <EditQuestion
                    key={index}
                    question={q}
                    fieldName={`answers[${index}].value`}
                  />
                ))}
                <SubmitWrapper>
                  <ErrorText>
                    {!valid &&
                      submitFailed &&
                      "Some fields need your attention before you can save"}
                  </ErrorText>
                  <Button level="primary" onClick={handleSubmit}>
                    Save
                  </Button>
                </SubmitWrapper>
              </form>
            )}
          />
        ) : (
          <Spacing>
            {questions.map((q, idx) => (
              <DisplayQuestion
                key={q.atsId}
                answer={answers[idx]}
                required={!!q.required}
              />
            ))}
          </Spacing>
        )}
      </Spacing>
    </Card>
  );
}

function formatMutationData({
  answers,
}: OttaFormState): JobApplicationDataInput {
  return {
    ottaData: {
      answers: answers.reduce<OttaAnswerInput[]>((acc, answer) => {
        if (!answer.value) {
          return acc;
        }
        if (answer.questionType === "TextAreaQuestion") {
          return [
            ...acc,
            {
              textAreaAnswer: {
                questionData: answer.questionData,
                value: answer.value,
              },
            },
          ];
        }
        return acc;
      }, []),
    },
  };
}
