import styled from "@xstyled/styled-components";

import { SalariesMultiOptionField } from "./SalariesMultiOptionField";

import { Spacing, Text, List } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { Exclamation } from "@otta/search/components/Icons/Exclamation";
import { QuestionComponentProps } from "@otta/search/containers/Quiz";

const ExclamationWrapper = styled.div`
  display: grid;
  grid-template-columns: ${modularScale()} 1fr;
  gap: lg;
`;

const gender = [
  { name: "FEMALE", text: "Female", field: "female" },
  { name: "MALE", text: "Male", field: "male" },
  { name: "NON_BINARY", text: "Non-binary", field: "nonBinary" },
  { name: "TRANSGENDER", text: "Transgender", field: "Transgender" },
  {
    name: "PREFER_NOT_TO_SAY",
    text: "Prefer not to say",
    field: "preferNotToSay",
  },
];

export function SalaryInputGender(
  props: QuestionComponentProps
): React.ReactElement {
  const formattedGenderList = gender.map(({ name, text }) => ({
    id: name,
    value: text,
  }));
  return (
    <Spacing>
      <SalariesMultiOptionField
        fieldName="genders"
        options={formattedGenderList}
        {...props}
      />
      <List type="positive" size={1}>
        <li>We want to help solve the gender pay gap</li>
      </List>
      <ExclamationWrapper>
        <Exclamation />
        <Text align="left" as="span">
          Our data currently confirms women are paid less in some roles, but we
          need more data points
        </Text>
      </ExclamationWrapper>
    </Spacing>
  );
}
