import ReactConfetti from "react-confetti";
import { useWindowSize } from "react-use";
import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

const ConfettiWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  pointer-events: none;
`;

const COLORS = [
  palette.brand.yellow,
  palette.brand.teal,
  palette.brand.orange,
  palette.brand.blue,
  palette.brand.pink,
  palette.brand.green,
  palette.brand.purple,
];

interface IConfettiProps {
  onComplete: () => void;
}

export function Confetti({
  onComplete,
  ...rest
}: IConfettiProps): React.ReactElement | null {
  const { width, height } = useWindowSize();

  return width && height ? (
    <>
      <ConfettiWrapper {...rest}>
        <ReactConfetti
          numberOfPieces={100}
          tweenDuration={1000}
          initialVelocityX={{ min: 0, max: 25 }}
          initialVelocityY={50}
          recycle={false}
          onConfettiComplete={onComplete}
          confettiSource={{ x: 0, y: height, w: 0, h: 0 }}
          colors={COLORS}
        />
      </ConfettiWrapper>
      <ConfettiWrapper>
        <ReactConfetti
          numberOfPieces={100}
          tweenDuration={1000}
          initialVelocityX={{ min: -25, max: 0 }}
          initialVelocityY={50}
          recycle={false}
          confettiSource={{ x: width, y: height, w: 0, h: 0 }}
          colors={COLORS}
        />
      </ConfettiWrapper>
    </>
  ) : null;
}
