export function Pencil(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 1.96436L17.8183 1.36481C17.0262 0.668119 15.8292 0.706439 15.0833 1.45237L1.16006 15.3756C1.11984 15.4158 1.09188 15.4666 1.07945 15.5221L0.0506564 20.1153C0.0105834 20.2942 0.17034 20.454 0.349253 20.4139L4.94246 19.3851C4.99797 19.3727 5.0488 19.3447 5.08902 19.3045L19.0122 5.38133C19.7581 4.6354 19.7964 3.43836 19.0997 2.64625L18.5 1.96436ZM1.65087 16.1736C2.90039 16.6407 3.92416 17.5682 4.51222 18.7641L0.888862 19.5757L1.65087 16.1736ZM13.472 4.05353L1.97676 15.5488C3.31248 16.0704 4.41339 17.0589 5.07579 18.3278L16.5632 6.84035C15.8519 5.6075 14.7693 4.63258 13.472 4.05353ZM13.9984 3.52713C15.2676 4.14899 16.3353 5.12059 17.074 6.3296L18.5172 4.88636C19.0021 4.4015 19.027 3.62342 18.5741 3.10855L18.004 2.46036L17.356 1.89043C16.8412 1.43758 16.0631 1.46249 15.5782 1.94734L13.9984 3.52713Z"
        fill="#2F3942"
      />
    </svg>
  );
}
