import styled from "@xstyled/styled-components";

// See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#icon_buttons
// CSS copied from https://github.com/alphagov/govuk-frontend/blob/e2c03b3851f6b00d82e53be85d5225d9b24c72f1/packages/govuk-frontend/src/govuk/helpers/_visually-hidden.scss#L5
export const ScreenReadableText = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0:
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
`;
