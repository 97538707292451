import styled from "@xstyled/styled-components";

import { Button, Middle } from "@otta/design";
import { palette } from "@otta/design-tokens";

const ButtonWrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  flex: 1;
  margin-right: lg;

  &:last-of-type {
    margin-right: 0;
  }
`;

const Bar = styled.div`
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: md;
  background-color: white;
  border-top: 1px solid ${palette.grayscale.shade200};
`;

interface IBottomButtonBarProps {
  handleNegative: React.MouseEventHandler<HTMLButtonElement>;
  handlePositive: React.MouseEventHandler<HTMLButtonElement>;
  negativeText: string;
  positiveText: string;
  showNegative: boolean;
  showPositive?: boolean;
  nextEnabled: boolean;
}

export function BottomButtonBar({
  negativeText,
  positiveText,
  showNegative,
  nextEnabled,
  showPositive = true,
  handlePositive,
  handleNegative,
}: IBottomButtonBarProps): React.ReactElement {
  return (
    <Bar>
      <Middle maxWidth={480}>
        <ButtonWrapper>
          {showNegative && (
            <StyledButton level="secondary" onClick={handleNegative}>
              {negativeText}
            </StyledButton>
          )}
          {showPositive && (
            <StyledButton
              level="primary"
              disabled={!nextEnabled}
              onClick={handlePositive}
            >
              {positiveText}
            </StyledButton>
          )}
        </ButtonWrapper>
      </Middle>
    </Bar>
  );
}
