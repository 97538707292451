import styled, { css, down, up } from "@xstyled/styled-components";

import { WorkExperienceItem } from "./FormCardList";

import { Spacing, Tipbox, Text, CircularProgress } from "@otta/design";
import { Icon } from "@otta/icons";
import { pxToRem } from "@otta/design-tokens";
import { EditButton } from "@otta/search/pages/Profile/components/FormCard";
import { Pencil } from "@otta/search/components/Icons/Pencil";
import { Dates } from "@otta/search/components/Dates";
import { BulletPointList } from "@otta/search/pages/Profile/components/FormData";

const StyledTipbox = styled(Tipbox)`
  margin-top: sm;
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.75rem;
  align-items: center;
`;

const ListWrapper = styled.div`
  padding-top: sm;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const LogoWrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

const InnerWrapper = styled.div`
  display: inline;
  white-space: wrap;
`;

const TitleTextWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
`;

const VerticalAlignedRowWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const EditButtonRowWrapper = styled(VerticalAlignedRowWrapper)`
  flex-flow: row nowrap;
`;

const TechnologiesRowWrapper = styled(RowWrapper)`
  flex-flow: row wrap;
`;

const WideRowWrapper = styled(RowWrapper)`
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const HeaderWrapper = styled(WideRowWrapper)`
  padding-bottom: sm;

  ${down(
    "desktop",
    css`
      flex-direction: column;
    `
  )}
`;

const ColumnWrapper = styled.div<{ leftAlign?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ leftAlign }) => leftAlign && "align-items: flex-end;"}
`;

const TechnologyContainer = styled.div`
  border-radius: 4;
  background-color: gray-200;
  padding: xxs sm;
  margin-right: xs;
  margin-top: sm;
  width: max-content;
`;

const GreyText = styled(Text)<{ leftPadding?: boolean }>`
  color: gray-600;
  margin: 0;
  padding: 0;
  padding-right: xxs;
`;

const Logo = styled.img`
  width: 100%;
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};
`;

const StyledSpinner = styled.div`
  width: 2rem;
  height: 2rem;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const MobileEditButton = styled(EditButton)`
  ${up(
    "desktop",
    css`
      display: none;
    `
  )}
`;

const DesktopEditButton = styled(EditButton)`
  ${down(
    "desktop",
    css`
      display: none;
    `
  )}
`;

const joinTags = (acc: JSX.Element | null, x: JSX.Element) =>
  acc === null ? (
    x
  ) : (
    <>
      {acc}
      <GreyText>·</GreyText>
      {x}
    </>
  );

function CompanySectorTags({
  sectorTags,
}: {
  sectorTags: string[];
}): React.ReactElement {
  return (
    <RowWrapper>
      {sectorTags
        .map((tag, index) => (
          <GreyText key={`${tag}-${index}`} size={-1}>
            {tag}
          </GreyText>
        ))
        .reduce(joinTags, null)}
    </RowWrapper>
  );
}

function JobTechnologies({
  technologies,
}: {
  technologies: string[];
}): React.ReactElement {
  return (
    <TechnologiesRowWrapper>
      {technologies.map((technology, index) => (
        <TechnologyContainer key={`${technology}-${index}`}>
          <Text>{technology}</Text>
        </TechnologyContainer>
      ))}
    </TechnologiesRowWrapper>
  );
}

interface DisplayWorkExperienceProps {
  experience: WorkExperienceItem;
  index?: number;
  handleEditClick?: () => void;
  isPreview?: boolean;
}

export function DisplayWorkExperience({
  experience,
  index,
  handleEditClick,
  isPreview = false,
}: DisplayWorkExperienceProps): React.ReactElement {
  const {
    title,
    companyName,
    startDate,
    endDate,
    companyDescription,
    companyLogoPath,
    descriptions,
    hasProcessingDescriptions,
    technologiesUsed,
    sectorTags,
  } = experience;

  return (
    <div>
      <Spacing>
        <Spacing size={-4}>
          <WideRowWrapper>
            <ColumnWrapper>
              <HeaderWrapper>
                <EditButtonRowWrapper>
                  <VerticalAlignedRowWrapper>
                    <TitleTextWrapper>
                      <InnerWrapper data-cs-mask>
                        {title}, {companyName}{" "}
                        <LogoWrapper>
                          {companyLogoPath ? (
                            <Logo src={companyLogoPath} />
                          ) : (
                            <Icon icon="company" />
                          )}
                        </LogoWrapper>
                      </InnerWrapper>
                    </TitleTextWrapper>
                  </VerticalAlignedRowWrapper>
                  {handleEditClick && (
                    <MobileEditButton
                      data-testid="mobile-edit-button"
                      id="edit-button"
                      type="button"
                      onClick={handleEditClick}
                    >
                      <Pencil />
                    </MobileEditButton>
                  )}
                </EditButtonRowWrapper>
                {startDate && (
                  <Dates
                    data-cs-mask
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="MMM yyyy"
                    showDuration
                    isMobile
                  />
                )}
              </HeaderWrapper>
              <WideRowWrapper>
                <ColumnWrapper>
                  {companyDescription && (
                    <GreyText data-cs-mask size={-1}>
                      {companyDescription}
                    </GreyText>
                  )}
                  {sectorTags.length > 0 && (
                    <CompanySectorTags
                      sectorTags={sectorTags.map(({ value }) => value)}
                    />
                  )}
                  {technologiesUsed.length > 0 && (
                    <JobTechnologies
                      technologies={technologiesUsed.map(({ value }) => value)}
                    />
                  )}
                  {descriptions.length > 0 && (
                    <ListWrapper>
                      <BulletPointList
                        values={descriptions.map(({ value }) => value)}
                      />
                    </ListWrapper>
                  )}
                </ColumnWrapper>
                {handleEditClick && (
                  <DesktopEditButton
                    data-testid="desktop-edit-button"
                    id="edit-button"
                    type="button"
                    onClick={handleEditClick}
                  >
                    <Pencil />
                  </DesktopEditButton>
                )}
              </WideRowWrapper>
            </ColumnWrapper>
          </WideRowWrapper>
        </Spacing>
      </Spacing>
      {!isPreview &&
        index === 0 &&
        descriptions.length < 3 &&
        !hasProcessingDescriptions && (
          <StyledTipbox level="error">
            We recommend you add 3 bullets and select up to 5 of your main
            technologies per experience, to put your best foot forward
          </StyledTipbox>
        )}
      {hasProcessingDescriptions && (
        <StyledTipbox level="information">
          <StyledSpinner>
            <CircularProgress quadrants={3} strokeWidth={10} />
          </StyledSpinner>
          <Text>
            We&apos;re processing the description for this work experience. This
            normally takes less than a minute, refresh the page to check
            progress.
          </Text>
        </StyledTipbox>
      )}
    </div>
  );
}
