import { motion } from "framer-motion";
import { useEffect, useMemo } from "react";
import styled from "@xstyled/styled-components";

import { DashboardEmptyState } from "../Empty/DashboardEmptyState";

import { BatchCardData, BatchCards, BatchCounts } from "./BatchCards";

import { pushAnalyticsEvent } from "@otta/analytics";
import { Heading, Middle, VerticalSpacing, responsive } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { toQueryKey } from "@otta/search/constants/themedBatches";
import {
  ThemeId,
  ThemedBatchesDocument,
  ThemedBatchesQuery,
  TopSectorDocument,
  TopSectorQuery,
} from "@otta/search/schema";

const BatchContainer = styled(motion.div)`
  ${responsive`
    padding: ${responsive.modularScale({
      mobile: 0.5,
      desktop: 1,
    })};`}
  background-color: yellow-500;
  margin-bottom: 36;
  position: relative;
  overflow: hidden;
`;

/**
 * Replaces the contents of BatchContainer when loading,
 * hence the position: absolute
 */
const LoadingWrapper = styled.div`
  align-items: center;
  position: absolute;
  inset: 0 0 0 0;
  display: flex;
`;

const Title = styled(Heading)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  ${responsive`
    font-size: ${responsive.modularScale({ mobile: 2, desktop: 5 })};
  `}
  grid-area: title;
`;

const HideWhenLoading = styled.div<{ $loading: boolean }>`
  opacity: ${p => (p.$loading ? "0" : "100")};
  transition: default;
`;

function counts(number: number): BatchCounts {
  return Object.values(ThemeId).reduce(
    (prev, curr) => ({ ...prev, [curr]: number }),
    { ALL_MATCHES: number } as BatchCounts
  );
}

function useExtractCounts(data?: ThemedBatchesQuery): BatchCounts | undefined {
  const user = data?.currentUser;
  return useMemo(() => {
    if (!user) return undefined;

    return Object.values(ThemeId).reduce<BatchCounts>(
      (acc, theme) => ({ ...acc, [theme]: user[toQueryKey(theme)].length }),
      {
        ALL_MATCHES: data?.currentUser?.allMatches.length ?? 0,
      } as BatchCounts
    );
  }, [user, data]);
}

function findTopSector(
  result: TopSectorQuery
): { id: string; value: string } | null {
  switch (result.me?.__typename) {
    case "CurrentCandidate":
      return result.me.topSector;
    case "CurrentAdmin":
      return result.me.topSector;
    default:
      return null;
  }
}

export function useBatchCardData(): BatchCardData | "loading" | undefined {
  const { data: topData, loading: topLoading } = useQuery(TopSectorDocument);
  const topSector = topData ? findTopSector(topData) : null;
  const preferredSectorId: string = topSector ? topSector.id : "";
  const { data, loading } = useQuery(ThemedBatchesDocument, {
    variables: { preferredSectorId },
    fetchPolicy: "network-only",
    skip: !topSector,
  });

  const counts = useExtractCounts(data);

  const batchCardData = useMemo(() => {
    if (!counts || !topSector) {
      return undefined;
    }
    return {
      counts,
      sector: topSector,
    };
  }, [counts, topSector]);

  if (topLoading || loading) {
    return "loading";
  }

  return batchCardData;
}

function analyticsTags(bc: BatchCounts): Record<string, number> {
  const es = Object.entries(bc) as [ThemeId | "ALL_MATCHES", number][];
  return Object.fromEntries(es.map(([k, v]) => [`${toQueryKey(k)}Count`, v]));
}

function useAnalyticsEvent(data?: BatchCardData | "loading") {
  useEffect(() => {
    if (data !== undefined && data !== "loading") {
      pushAnalyticsEvent({
        eventName: "Candidate Viewed Dashboard",
        topSectorName: data.sector.value,
        topSectorId: data.sector.id,
        ...analyticsTags(data.counts),
      });
    }
  }, [data]);
}

/**
 * Intepret the resulting batch card data from the backend
 * If it is loading we return non empty batches so the UI doesn't jump when jobs appear
 * otherwise if there's no data at all we hard code a preferred sector
 */
export function findData(input?: BatchCardData | "loading"): BatchCardData {
  const sector = { id: "38", value: "Sustainability" };
  if (input === "loading") {
    return { counts: counts(1), sector };
  } else if (input === undefined) {
    return { counts: counts(0), sector };
  } else {
    return input;
  }
}

function ThemedBatchesComponent({
  data,
}: {
  data: BatchCardData | "loading" | undefined;
}): React.ReactElement {
  const hasNewJobs = useMemo(() => {
    if (!data || data === "loading") {
      return false;
    }

    const { TAKE_ANOTHER_LOOK, ...rest } = data.counts;

    const newJobs = Object.values(rest).reduce((val, acc) => val + acc, 0);

    return newJobs > 0;
  }, [data]);

  if (!hasNewJobs && data !== "loading") {
    return <DashboardEmptyState />;
  }

  return <BatchCards {...findData(data)} />;
}

export function ThemedBatches({
  greeting,
}: {
  greeting: string | null;
}): React.ReactElement {
  const data = useBatchCardData();
  useAnalyticsEvent(data);

  return (
    <BatchContainer layout initial={false}>
      <Middle maxWidth={1170} textAlign="inherit">
        {data === "loading" && (
          <LoadingWrapper data-testid="loading">
            <Loading />
          </LoadingWrapper>
        )}
        <VerticalSpacing size={1}>
          <HideWhenLoading $loading={!greeting}>
            <Title data-cs-mask>{greeting || "Welcome"}</Title>
          </HideWhenLoading>
          <HideWhenLoading $loading={data === "loading"}>
            <ThemedBatchesComponent data={data} />
          </HideWhenLoading>
        </VerticalSpacing>
      </Middle>
    </BatchContainer>
  );
}
