import TextareaAutosize from "react-textarea-autosize";
import styled from "@xstyled/styled-components";

import { ErrorText, Label, Spacing, Text } from "@otta/design";
import { pxToRem, modularScale, palette } from "@otta/design-tokens";

interface IProps {
  minHeight?: string;
  maxHeight?: string;
  error?: string;
  label?: string;
  hint?: string;
  charCount?: number;
  charCountMax?: number;
  border?: boolean;
}

const CharCountWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column: 2;
`;

const TextareaBottom = styled.div`
  display: grid;
  grid-column-template: 1fr 1fr;
`;

const InputContainer = styled.div`
  position: relative;
  background: ${palette.brand.white};
  padding: md;
  width: 100%;
  border-radius: ${pxToRem(5)};
`;

const InputBorder = styled.div<{ $error?: boolean; $border?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: ${pxToRem(5)};
  border-color: ${({ $error }) =>
    $error ? palette.brand.red : palette.grayscale.shade400};
  border-width: 1px;
  border-style: ${({ $border }) => ($border ? "solid" : "none")};
  pointer-events: none;
`;

const Input = styled(TextareaAutosize)<{
  $minHeight?: string;
  $maxHeight?: string;
}>`
  resize: none;
  border: none;
  outline: none;
  width: 100%;
  min-height: ${({ $minHeight }) => $minHeight ?? "none"};
  max-height: ${({ $maxHeight }) => $maxHeight ?? "none"};
  font-family: inherit;
  font-size: ${modularScale()};

  ::placeholder {
    color: gray-400;
  }

  &:focus + ${InputBorder} {
    border-color: black;
  }
`;

const GreyText = styled(Text)`
  color: gray-600;
`;

export function Textarea({
  label,
  hint,
  error,
  minHeight,
  maxHeight,
  charCount,
  charCountMax,
  border,
  ...props
}: IProps &
  React.TextareaHTMLAttributes<HTMLTextAreaElement>): React.ReactElement {
  const showBorder = border ?? true;

  return (
    <Spacing size={-5}>
      {label && <Label htmlFor={props.id}>{label}</Label>}
      {hint && <GreyText>{hint}</GreyText>}
      <InputContainer>
        <Input
          as={TextareaAutosize}
          {...props}
          $minHeight={minHeight}
          $maxHeight={maxHeight}
          style={undefined}
        />
        <InputBorder $error={!!error} $border={showBorder} />
      </InputContainer>
      <TextareaBottom>
        {error && <ErrorText>{error}</ErrorText>}
        {charCountMax && (
          <CharCountWrapper>
            <Text
              size={-1}
              color={error ? palette.brand.red : palette.brand.black}
            >
              {charCount}/{charCountMax}
            </Text>
          </CharCountWrapper>
        )}
      </TextareaBottom>
    </Spacing>
  );
}
