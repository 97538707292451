import styled, { css, down } from "@xstyled/styled-components";

import { ScrollableContainer } from "../ScrollableContainer";

import { Section } from "./Section";

import { palette } from "@otta/design-tokens";
import { Spacing } from "@otta/design";
import { SidebarQuizLayoutProps } from "@otta/search/containers/Quiz/SidebarQuiz";

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${palette.grayscale.shade200};
  ${down(
    "desktop",
    css`
      display: none;
    `
  )}
`;

export function Sidebar({
  sections,
}: Pick<SidebarQuizLayoutProps, "sections">): React.ReactElement {
  return (
    <SidebarWrapper data-testid="quiz-sidebar">
      <ScrollableContainer style={{ backgroundColor: palette.beige.shade100 }}>
        <Spacing size={3}>
          {sections.map(section => (
            <Section key={section.title} section={section} />
          ))}
        </Spacing>
      </ScrollableContainer>
    </SidebarWrapper>
  );
}
