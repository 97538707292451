import styled, { css, up } from "@xstyled/styled-components";

import { CompanySize } from "../components/CompanySize";
import { SectorTags } from "../components/SectorTags";

import { pxToRem } from "@otta/design-tokens";
import { UserJobPreferences, Company } from "@otta/search/schema";

interface SizeAndSectorProps {
  company: Pick<Company.Fragment, "sectorTags" | "size">;
  user?: Pick<UserJobPreferences.CurrentUser, "sectorTagPreferences">;
}

const TopCardSectionResponsive = styled.div`
  display: flex;
  flex-direction: column;
  gap: md;
  ${up(
    "tablet",
    css`
    margin-top ${pxToRem(12)};
  `
  )}
`;

export const SizeAndSector = ({
  company,
  user,
}: SizeAndSectorProps): React.ReactElement => {
  return (
    <TopCardSectionResponsive>
      <CompanySize company={company} />
      <SectorTags company={company} user={user} />
    </TopCardSectionResponsive>
  );
};
