import { Field } from "react-final-form";

import { ConsentQuestion } from "../types";

import { OptionLabel } from "./SelectField";

import { Spacing } from "@otta/design";

type FieldProps = {
  question: ConsentQuestion;
  sectionId: string;
};

export function ConsentField(props: FieldProps): React.ReactElement {
  return <Checkbox {...props} />;
}

function Checkbox(props: FieldProps): React.ReactElement {
  return (
    <Spacing>
      <OptionLabel>
        <Field<boolean | undefined, HTMLElement, string | undefined>
          name={`s_${props.sectionId}.q_${props.question.id}.a_0.booleanValue`}
          component="input"
          type={"checkbox"}
          initialValue={props.question.consentAnswer ?? false}
        />
        {props.question.label}
      </OptionLabel>
    </Spacing>
  );
}
