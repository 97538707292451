import styled from "@xstyled/styled-components";

import { InfoIcon } from "./InfoIcon";

import { Tooltip as DesignTooltip, TooltipProps } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";

const IconContainer = styled.span<{
  iconColor?: string;
}>`
  display: inline-flex;
  svg {
    width: ${modularScale()};
    height: ${modularScale()};
    stroke: ${({ iconColor = palette.brand.black }) => iconColor};
    fill: ${({ iconColor = palette.brand.black }) => iconColor};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6;
`;

interface ITooltipWithIconProps extends Omit<TooltipProps, "children"> {
  iconColor?: string;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

export function TooltipWithIcon({
  iconColor,
  children,
  ...rest
}: ITooltipWithIconProps): React.ReactElement {
  const Tooltip = (
    <DesignTooltip {...rest}>
      <IconContainer iconColor={iconColor}>
        <InfoIcon data-testid="tooltip-info-icon" />
      </IconContainer>
    </DesignTooltip>
  );

  if (children) {
    return (
      <Wrapper>
        {children}
        {Tooltip}
      </Wrapper>
    );
  }

  return Tooltip;
}
