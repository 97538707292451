import { useEffect } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { Spacing } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { SelectButton } from "@otta/search/components/Button/SelectButton";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

enum SearchStageValue {
  lookingNow = "looking_now",
  inTheNextYear = "in_the_next_year",
  forTheRightRole = "for_the_right_role",
  justCurious = "just_curious",
}

const OPTIONS: { label: string; value: SearchStageValue }[] = [
  {
    label: "As soon as possible",
    value: SearchStageValue.lookingNow,
  },
  {
    label: "In the next year",
    value: SearchStageValue.inTheNextYear,
  },
  {
    label: "Not sure, I'd move for the right role",
    value: SearchStageValue.forTheRightRole,
  },
  {
    label: "Not looking, I'm just curious",
    value: SearchStageValue.justCurious,
  },
];

export function SearchStage({
  handleNext,
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();

  const searchStage = preferencesData?.searchStage;

  const isPresent = useIsPresent();

  useEffect(() => {
    if (searchStage && isPresent) {
      setNextEnabled(true);
    }
  }, [searchStage, setNextEnabled, isPresent]);

  if (!preferencesData) {
    return <Loading />;
  }

  const handleClick = (value: SearchStageValue) => {
    updatePreferences({ searchStage: value });
    handleNext();
  };

  return (
    <Spacing size={-4}>
      {OPTIONS.map(({ label, value }) => {
        return (
          <SelectButton
            key={value}
            level="secondary"
            selected={searchStage === value}
            onClick={() => handleClick(value)}
          >
            {label}
          </SelectButton>
        );
      })}
    </Spacing>
  );
}
