import styled from "@xstyled/styled-components";

import { Section } from ".";

import { Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

const Container = styled.div`
  box-sizing: border-box;
  position: sticky;
  top: 0;
  background-color: white;
  margin-bottom: 8px;
  z-index: 10;
  margin: 0 -10px lg;
`;

const ScrollContainer = styled.div`
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const BorderContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  border-bottom: 2px solid ${palette.grayscale.shade200};
`;

const Tab = styled.button<{ active: boolean }>`
  border: none;
  background: none;
  outline: none;
  margin: 0 10px;
  padding: md 0;
  z-index: 1;
  border-bottom: ${({ active }) =>
    active
      ? `2px solid ${palette.brand.yellow}`
      : `2px solid ${palette.grayscale.shade200}`};
`;

const TabsContainer = styled.div`
  display: flex;
  width: fit-content;
`;

const Title = styled(Text)<{ active: boolean }>`
  color: black;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  white-space: nowrap;
`;

interface IMobileTabsProps {
  tabs: Record<Section, string>;
  activeSection: Section;
  updateActive: (n: Section) => void;
  mobileTabsTopRef: React.RefObject<HTMLHeadingElement>;
  hiddenSections: Partial<Record<Section, boolean>>;
}

export function MobileTabs({
  tabs,
  activeSection,
  updateActive,
  mobileTabsTopRef,
  hiddenSections,
}: IMobileTabsProps): React.ReactElement {
  const tabKeys = Object.keys(tabs) as Section[];

  return (
    <Container data-testid="mobile-tabs">
      <ScrollContainer>
        <BorderContainer />
        <TabsContainer>
          {tabKeys.map(value => {
            if (hiddenSections[value]) {
              return null;
            }

            const active = activeSection === value;
            return (
              <Tab
                id={`${value.toLowerCase()}-section-id`}
                data-testid="tab"
                key={value}
                active={active}
                onClick={() => {
                  updateActive(value);
                  mobileTabsTopRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                <Title data-testid="tab-title" active={active}>
                  {tabs[value]}
                </Title>
              </Tab>
            );
          })}
        </TabsContainer>
      </ScrollContainer>
    </Container>
  );
}
