import { PublicJob } from "@otta/search/schema";

type JobValueClassification = "HHV" | "HV" | "LV";

export const jobValueClassification = (
  jobFunctionId: string | undefined
): JobValueClassification | null => {
  if (jobFunctionId === undefined) {
    return null;
  }

  if (jobFunctionId === "2") {
    // Engineering
    return "HHV";
  }
  if (["6", "13", "22", "46"].includes(jobFunctionId)) {
    // Data, Product, Design, Sales & Account Management
    return "HV";
  }
  return "LV";
};

export const jobProperties = (
  job: PublicJob.PublicJob
): {
  jobValueClassification: "HHV" | "HV" | "LV" | null;
  jobTitle: string;
  companyName: string;
  jobId: string;
  function: string | undefined;
  subFunction: string | undefined;
  minYearsExperienceRequired: number | null;
  maxYearsExperience: number | null;
  technologiesUsed: string;
  live: boolean;
} => {
  return {
    jobValueClassification: jobValueClassification(job.function?.id),
    jobTitle: job.title,
    companyName: job.company.name,
    jobId: job.id,
    function: job.function?.value,
    subFunction: job.subFunction?.value,
    minYearsExperienceRequired: job.minYearsExperienceRequired,
    maxYearsExperience: job.maxYearsExperienceRequired,
    technologiesUsed: (job.technologiesUsed ?? [])
      .map(({ value }) => value)
      .join(", "),
    live: job.live,
  };
};

export const getSalaryStatus = (
  job?: Pick<PublicJob.PublicJob, "hideSalary" | "salaryRange"> | null
) => {
  if (!job) return null;

  if (job.hideSalary && job.salaryRange) return "hidden";
  if (job.salaryRange) return "public";
  return "not-provided";
};
