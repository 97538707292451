import { useMutation } from "@apollo/client";
import {
  useParams,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useCallback, useEffect } from "react";

import { CategoryResponse } from "./components/CategoryResponse";
import { CategoryTags } from "./components/CategoryTags";
import { EmployeeSurveyThankYou } from "./components/EmployeeSurveyThankYou";

import { pushAnalyticsEvent } from "@otta/analytics";
import { useQuery } from "@otta/search/apollo";
import {
  CompanySurveyQuiz,
  CompanySurveyQuizDocument,
  UpdateCompanyEmployeeSurveySubmittedAtDocument,
} from "@otta/search/schema";
import { Question, Quiz } from "@otta/search/containers/Quiz";

const questions = (companyName: string): Question[] => [
  {
    title: `Pick 3 things that makes ${companyName} a great place to work`,
    subtitle:
      "We've picked 30 examples of what job seekers care about the most. You'll be able to tell us about your choices next",
    component: CategoryTags,
    path: "categories",
  },
  {
    title: `What makes ${companyName} special in this area?`,
    subtitle:
      "Take a few sentences to explain your experiences. Job seekers love to read examples!",
    component: CategoryResponse,
    path: "1",
  },
  {
    title: `What makes ${companyName} special in this area?`,
    subtitle:
      "Take a few sentences to explain your experiences. Job seekers love to read examples!",
    component: CategoryResponse,
    path: "2",
  },
  {
    title: `What makes ${companyName} special in this area?`,
    subtitle:
      "Take a few sentences to explain your experiences. Job seekers love to read examples!",
    component: CategoryResponse,
    path: "3",
  },
];

const eventProperties = (company: CompanySurveyQuiz.Company) => () => {
  return Promise.resolve({ companyId: company.id });
};

export const ErrorText = styled.div`
  padding: 50px 0;
  text-align: center;
`;

export const EmployeeSurvey: React.FC = () => {
  const navigate = useNavigate();

  const { surveyExternalId } = useParams<"surveyExternalId">();

  const { data: surveyData } = useQuery(CompanySurveyQuizDocument, {
    variables: { externalId: surveyExternalId as string },
  });

  const [mutate] = useMutation(UpdateCompanyEmployeeSurveySubmittedAtDocument);

  const location = useLocation();

  const survey = surveyData?.companyEmployeeSurvey;

  useEffect(() => {
    if (survey) {
      pushAnalyticsEvent({
        eventName: "Employee Viewed",
        pathname: location.pathname,
        companyId: survey.company.id,
      });
    }
  }, [location.pathname, survey]);

  const handleFinish = useCallback(() => {
    mutate({
      variables: { externalId: surveyExternalId as string },
    });
    navigate("thank-you", { replace: true });
  }, [mutate, navigate, surveyExternalId]);

  if (!survey) {
    return null;
  }

  return (
    <div>
      <Routes>
        <Route
          path="thank-you"
          element={<EmployeeSurveyThankYou company={survey.company} />}
        />
        <Route
          path="*"
          element={
            survey.submittedAt ? (
              <ErrorText>
                This endorsement has already been submitted. Thank you
              </ErrorText>
            ) : (
              <Quiz
                finishText="Finish"
                questions={questions(survey.company.name)}
                analyticsName="employee-survey"
                onFinish={handleFinish}
                eventProperties={eventProperties(survey.company)}
              />
            )
          }
        />
      </Routes>
    </div>
  );
};
