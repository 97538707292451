import { Link, useParams } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";

import { ApplicationQuestionResponse } from "../PublicProfile/sections/Application/ApplicationQuestionResponse";
import { SendApplicationButton } from "../components/SendApplicationButton";
import { ProfilePdfPreview } from "../components/ProfilePdfPreview";
import { NewATSApplication } from "../PublicProfile/sections/Application";

import { pxToRem } from "@otta/design-tokens";
import { Text, Spacing, Button } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import {
  JobApplicationDataDocument,
  PublicJobDocument,
} from "@otta/search/schema";
import {
  useCheckApplicationProgress,
  useIsJobLive,
  useNewAtsQuestions,
} from "@otta/search/utils/job";

const PageWrap = styled.article`
  max-width: ${pxToRem(1024)};
  width: 90%;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 150;
  ${up(
    "tablet",
    css`
      padding-bottom: 100;
    `
  )}
`;

const Header = styled.section`
  background-color: yellow-500;
  width: 100%;
  padding: 3rem 0;
`;

const HeaderContent = styled.div`
  width: 90%;
  max-width: ${pxToRem(1024)};
  margin: 0 auto;
`;

const ApplicationResponsesWrapper = styled.ul`
  display: grid;
  gap: 1rem;
  padding-top: 1.5rem;
  list-style: none;

  ${up(
    "tablet",
    css`
      grid-template-columns: 50% auto;
    `
  )}
`;

const ControlBarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: ${pxToRem(130)};
  padding: 1rem;

  width: 100%;
  box-shadow: 0 ${pxToRem(0)} ${pxToRem(7)} rgba(0, 0, 0, 0.2);
  background-color: white;
  ${up(
    "tablet",
    css`
      height: ${pxToRem(72)};
    `
  )}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 0.75rem;
  ${up(
    "tablet",
    css`
      flex-direction: row;
      width: ${pxToRem(500)};
    `
  )}
`;

const StyledControlLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  button {
    width: 100%;
    ${up(
      "tablet",
      css`
        width: ${pxToRem(250)};
      `
    )}}
  }
`;

function ControlBar({
  jobId,
  disabled,
}: {
  jobId: string;
  disabled: boolean;
}): React.ReactElement {
  return (
    <ControlBarWrapper>
      <ButtonsWrapper>
        <StyledControlLink to={`/jobs/${jobId}/apply`}>
          <Button level="secondary">Edit application</Button>
        </StyledControlLink>
        <SendApplicationButton
          jobId={jobId}
          disabled={disabled}
          level="primary"
        />
      </ButtonsWrapper>
    </ControlBarWrapper>
  );
}

function ApplicationResponses({
  jobId,
}: {
  jobId: string;
}): React.ReactElement {
  const { data, loading } = useQuery(JobApplicationDataDocument, {
    variables: { jobId },
  });

  const answers = data?.latestJobApplication?.data?.data.answers;

  if (!answers || loading) {
    return <Loading />;
  }

  return (
    <>
      <Text size={1} bold>
        Your answers
      </Text>
      <ApplicationResponsesWrapper>
        {answers.map(response => {
          const { questionData } = response;

          return (
            <li key={questionData.question}>
              <ApplicationQuestionResponse
                data-cs-mask
                response={response}
                expand={true}
              />
            </li>
          );
        })}
      </ApplicationResponsesWrapper>
    </>
  );
}

function NewAtsQuestionsResponses({
  jobId,
}: {
  jobId: string;
}): React.ReactElement {
  const { data, loading } = useQuery(JobApplicationDataDocument, {
    variables: { jobId },
  });

  const answers = data?.latestJobApplication?.atsQuestions;

  if (!answers || loading) {
    return <Loading />;
  }

  return (
    <Spacing>
      <Text size={1} bold>
        Your answers
      </Text>
      <NewATSApplication data-cs-mask answerData={answers} />
    </Spacing>
  );
}

function ApplicationPreview({ jobId }: { jobId: string }): React.ReactElement {
  const { data: jobData } = useQuery(PublicJobDocument, {
    variables: { externalId: jobId },
  });
  const job = jobData?.publicJob;

  const { isLive } = useIsJobLive(jobId);

  const showNewAtsQuestions = useNewAtsQuestions(jobId);

  const { numberCompleted, rows } = useCheckApplicationProgress(jobId);

  if (!job) {
    return <Loading />;
  }

  return (
    <>
      <Header>
        <HeaderContent>
          <h2>Check your application before sending</h2>
          <Text size={1}>
            {job.title} at {job.company.name}
          </Text>
        </HeaderContent>
      </Header>
      <PageWrap>
        <Spacing size={3}>
          {showNewAtsQuestions ? (
            <NewAtsQuestionsResponses jobId={jobId} />
          ) : (
            <ApplicationResponses jobId={jobId} />
          )}
          <ProfilePdfPreview jobId={jobId} />
        </Spacing>
        <ControlBar
          jobId={jobId}
          disabled={!isLive || numberCompleted < rows.length}
        />
      </PageWrap>
    </>
  );
}

export default function ApplicationPreviewRoute(): React.ReactElement | null {
  const { jobId } = useParams<"jobId">();

  if (!jobId) {
    return null;
  }

  return <ApplicationPreview jobId={jobId} />;
}
