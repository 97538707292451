import { QuestionComponentProps } from "..";

import { NegativeSectorTags } from "./NegativeSectorTags";

import { Spacing, Text } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { PreferenceQuestionWrapper } from "@otta/search/containers/Quiz/components/PreferenceQuestionWrapper";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

const NegativeSectorTagsPreferenceQuestion = (
  props: QuestionComponentProps
): React.ReactElement => (
  <Spacing>
    <Text align="center">Which industries do you want to filter out?</Text>
    <NegativeSectorTags {...props} />
  </Spacing>
);

export function NegativeSectorTagsPreference(
  props: QuestionComponentProps
): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();

  if (!preferencesData) {
    return <Loading />;
  }

  const preferences = preferencesData.sectorTagPreferences ?? [];

  const hasPreference =
    preferences.filter(({ preference }) => !preference).length > 0;

  const handleNoClick = () => {
    const positivePreferences = preferences.filter(
      ({ preference: pref }) => pref
    );

    updatePreferences({
      sectorTagPreferences: positivePreferences.map(
        ({ sectorTagId, preference }) => ({
          sectorTagId,
          preference: preference as boolean,
        })
      ),
    });
  };

  return (
    <PreferenceQuestionWrapper
      {...props}
      hasPreference={hasPreference}
      analyticsNoOptionName="no-negative-industry-preference"
      analyticsYesOptionName="has-negative-industry-preference"
      Component={NegativeSectorTagsPreferenceQuestion}
      onNoClick={handleNoClick}
      optionalQuestion
    />
  );
}
