import { useEffect, useRef, RefObject } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export function useScrollLock<T extends HTMLElement>(): RefObject<T> {
  const ref = useRef<T>(null);

  useEffect(() => {
    const currentRef = ref.current;

    if (!currentRef) {
      return;
    }

    disableBodyScroll(currentRef);

    return () => {
      enableBodyScroll(currentRef);
    };
  }, []);

  return ref;
}
