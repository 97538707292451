import { format, parseISO } from "date-fns";

import { TextQuestionResponse } from "./TextQuestionResponse";
import { LinkQuestionResponse } from "./LinkQuestionResponse";

import {
  WorkableAnswersFragment,
  GreenhouseAnswersFragment,
  LeverAnswersFragment,
  OttaAnswersFragment,
} from "@otta/search/schema";

interface ApplicationQuestionResponseProps {
  response:
    | WorkableAnswersFragment["answers"][number]
    | GreenhouseAnswersFragment["answers"][number]
    | LeverAnswersFragment["answers"][number]
    | OttaAnswersFragment["answers"][number];
  expand: boolean;
}

export function ApplicationQuestionResponse({
  response,
  expand,
}: ApplicationQuestionResponseProps): React.ReactElement | null {
  if (!response) {
    return null;
  }

  const responseType = response?.__typename;

  switch (responseType) {
    case "BooleanAnswer":
      return (
        <TextQuestionResponse
          question={response.questionData.question}
          answer={response.booleanValue ? "Yes" : "No"}
          expand={expand}
        />
      );
    case "DateAnswer":
      return (
        <TextQuestionResponse
          data-cs-mask
          question={response.questionData.question}
          answer={format(parseISO(response.dateValue), "dd-MMM-yyyy")}
          expand={expand}
        />
      );
    case "DecimalAnswer":
      return (
        <TextQuestionResponse
          data-cs-mask
          question={response.questionData.question}
          answer={`${response.decimalValue}`}
          expand={expand}
        />
      );
    case "FileAnswer":
      return (
        <LinkQuestionResponse
          data-cs-mask
          question={response.questionData.question}
          fileUrl={response.url}
          linkName={response.name}
        />
      );
    case "MultiSelectAnswer":
      return (
        <TextQuestionResponse
          data-cs-mask
          question={response.questionData.question}
          answer={
            response.choices.length > 0
              ? response.choices.map(({ label }) => label).join(", ")
              : "No options selected"
          }
          expand={expand}
        />
      );
    case "SingleSelectAnswer":
      return (
        <TextQuestionResponse
          data-cs-mask
          question={response.questionData.question}
          answer={response.choice.label}
          expand={expand}
        />
      );
    case "TextAnswer":
    case "TextAreaAnswer":
      return (
        <TextQuestionResponse
          data-cs-mask
          question={response.questionData.question}
          answer={response.value}
          expand={expand}
        />
      );
    case "UrlAnswer":
      return (
        <LinkQuestionResponse
          data-cs-mask
          question={response.questionData.question}
          fileUrl={response.value}
          linkName={response.value}
        />
      );
    default:
      return null;
  }
}
