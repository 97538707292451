import { useEffect, useMemo } from "react";
import styled, { css, up } from "@xstyled/styled-components";

import { CheckCircle } from "../components/icons/CheckCircle";
import { Ctas } from "../Application/Ctas";

import { VerticalSpacing, Text, Card as _Card } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import { PublicJobDocument } from "@otta/search/schema";
import { Link } from "@otta/search/components/Link";
import { useUserProfileStatus } from "@otta/search/utils/user";
import { useCheckApplicationProgress } from "@otta/search/utils/job";
import { palette, pxToRem } from "@otta/design-tokens";

const Card = styled(_Card)`
  display: flex;
  flex-direction: column;
  gap: lg;
`;

const Sections = styled.ul`
  display: flex;
  gap: lg;
  flex-wrap: wrap;
`;

const Section = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: xs;
  width: 100%;

  ${up(
    "tablet",
    css`
      width: auto;
    `
  )};
`;

const Icon = styled.span`
  flex-shrink: 0;
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
  border: 2px solid ${palette.grayscale.shade600};
  border-radius: 50%;

  svg {
    height: 100%;
  }

  [data-completed="true"] & {
    border-color: green-200;
    background-color: green-200;
    color: green-600;
  }

  ${up(
    "tablet",
    css`
      order: -1;
    `
  )};
`;

const MobileOnly = styled.div`
  display: contents;
  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

interface IApplicationStatusProps {
  jobId: string;
  isLive: boolean;
  showConfirmationPopup: () => void;
}

export function ApplicationStatus({
  jobId,
  isLive,
  showConfirmationPopup,
}: IApplicationStatusProps): React.ReactElement {
  const { data: jobData } = useQuery(PublicJobDocument, {
    variables: { externalId: jobId },
  });

  const { profileState, completedFields } = useUserProfileStatus();

  const job = jobData?.publicJob;

  const { rows, numberCompleted } = useCheckApplicationProgress(jobId);
  const sectionsLeft = useMemo(
    () => rows.filter(row => !row.completed).length,
    [rows]
  );

  useEffect(() => {
    if (profileState !== "NOT_CREATED") {
      pushAnalyticsEvent({
        eventName: "Candidate Completed Profile",
        completedFields,
        profileState,
      });
    }
    if (profileState === "DEEP" || profileState === "FULL") {
      pushAnalyticsEvent({
        eventName: "Candidate Completed Deep Profile",
        completedFields,
        profileState,
      });
    }
  }, [profileState, completedFields]);

  if (!job) {
    return <Loading />;
  }

  return (
    <VerticalSpacing>
      <Text bold size={2}>
        Applying for{" "}
        <Link to={`/jobs/${jobId}`} targetBlank>
          {job.title}
        </Link>{" "}
        at {job.company.name}
      </Text>
      <Card>
        <Text bold>
          {sectionsLeft === 0
            ? "All done!"
            : `${sectionsLeft} section${sectionsLeft === 1 ? "" : "s"} left`}
        </Text>
        <Sections>
          {rows.map(({ title, completed }) => (
            <Section key={title} data-completed={completed}>
              {title}
              <Icon>{completed && <CheckCircle />}</Icon>
            </Section>
          ))}
        </Sections>
        {!isLive && <Text bold>This job is no longer available</Text>}
        <MobileOnly>
          <Ctas
            jobId={jobId}
            showConfirmationPopup={showConfirmationPopup}
            disabled={!isLive || numberCompleted < rows.length}
          />
        </MobileOnly>
      </Card>
    </VerticalSpacing>
  );
}
