import { Form } from "react-final-form";
import { ReactElement } from "react";

import { SubmitWrapper } from "../ApplicationQuestions/forms/shared";

import { FormState } from "./submit";
import { ATSQuestionsV1, QuestionOrInformation } from "./types";
import { Information } from "./fields/Information";
import { SingleQuestion } from "./SingleQuestion";

import { Spacing, ErrorText, Button } from "@otta/design";
import {
  Card,
  EditButton,
} from "@otta/search/pages/Profile/components/FormCard";
import { SectionHeader } from "@otta/search/pages/Profile/components/FormHeader";
import { HeaderWrapper } from "@otta/search/pages/Profile/PublicProfile/components/Section";
import { Delete } from "@otta/search/components/Icons/Delete";

type FieldProps<A> = { section: string; question: A };

interface EditQuestionsProps {
  sections: ATSQuestionsV1;
  handleSubmit: (state: FormState) => void;
  handleCancel: () => void;
}

export const EditQuestions = ({
  sections,
  handleSubmit,
  handleCancel,
}: EditQuestionsProps): React.ReactElement => {
  return (
    <Form<FormState>
      onSubmit={state => handleSubmit(state)}
      render={({ handleSubmit, valid, submitFailed }) => (
        <form onSubmit={handleSubmit}>
          <Card clickable={false} data-testid="question-card">
            <Spacing>
              {sections.sections.map(
                ({ id, localId, label, questions }, index) => (
                  <Spacing key={id}>
                    {label && (
                      <HeaderWrapper>
                        <SectionHeader bold size={1}>
                          {label}
                        </SectionHeader>
                        {index === 0 && (
                          <EditButton
                            data-testid="edit-button"
                            onClick={handleCancel}
                            type="button"
                          >
                            <Delete />
                          </EditButton>
                        )}
                      </HeaderWrapper>
                    )}
                    {questions.map(question => (
                      <Field
                        section={localId}
                        question={question}
                        key={question.id}
                      />
                    ))}
                  </Spacing>
                )
              )}
              <SubmitWrapper>
                <ErrorText>
                  {!valid &&
                    submitFailed &&
                    "Some fields need your attention before you can save "}
                </ErrorText>
                <Button
                  level="primary"
                  type="submit"
                  data-testid="submit-button"
                >
                  Save
                </Button>
              </SubmitWrapper>
            </Spacing>
          </Card>
        </form>
      )}
    />
  );
};

function Field({
  section,
  question,
}: FieldProps<QuestionOrInformation>): ReactElement {
  if (question.__typename == "AtsInformation") {
    return <Information question={question} />;
  } else {
    return <SingleQuestion question={question} section={section} />;
  }
}
