import { keyframes } from "@xstyled/styled-components";

export const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

export const slideUp = (defaultX = "0%") => keyframes`
    from {
        transform: translate(${defaultX}, 100%);
    }
    to {
        transform: translate(${defaultX}, 0%);
    }
`;

export const pulse = keyframes`
    0% {
        transform: scale(1.0);
    }
    50% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1.0);
    }
`;
