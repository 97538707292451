import styled from "@xstyled/styled-components";

import { ATSQuestionsV1 } from "./types";
import { ViewQuestion } from "./ViewQuestion";

import { Spacing } from "@otta/design";
import {
  Card,
  EditButton,
} from "@otta/search/pages/Profile/components/FormCard";
import { SectionHeader } from "@otta/search/pages/Profile/components/FormHeader";
import { HeaderWrapper } from "@otta/search/pages/Profile/PublicProfile/components/Section";
import { Pencil } from "@otta/search/components/Icons/Pencil";

const SectionWrapper = styled.div``;

interface ViewQuestionsProps {
  sections: ATSQuestionsV1;
  handleClick: () => void;
}

export const ViewQuestions = ({
  sections,
  handleClick,
}: ViewQuestionsProps): React.ReactElement => {
  return (
    <Card clickable onClick={handleClick} data-test-id="new-ats-questions-view">
      <Spacing size={5}>
        {sections.sections.map(({ id, label, questions }, index) => (
          <SectionWrapper key={`${id}-${index}`}>
            {label && (
              <HeaderWrapper>
                <SectionHeader bold size={1}>
                  {label}
                </SectionHeader>
                {index === 0 && (
                  <EditButton
                    data-testid={`edit-button-${index}`}
                    onClick={handleClick}
                  >
                    <Pencil id="edit-button" />
                  </EditButton>
                )}
              </HeaderWrapper>
            )}
            {questions.map(
              (question, i) =>
                question.__typename !== "AtsInformation" && (
                  <ViewQuestion question={question} section={id} key={i} />
                )
            )}
          </SectionWrapper>
        ))}
      </Spacing>
    </Card>
  );
};
