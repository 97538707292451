import { HintContainer, Key, GreyText, Row } from "./styledJobCardComponents";

export const KeyboardHint = (): React.ReactElement => {
  const keys = [
    { key: "S", hint: "save" },
    { key: "A", hint: "apply" },
    { key: "<", hint: "previous" },
    { key: ">", hint: "next" },
  ];

  return (
    <HintContainer>
      {keys.map(({ key, hint }) => (
        <Row key={key}>
          <Key>
            <GreyText align="center" size={-1}>
              {key}
            </GreyText>
          </Key>
          <GreyText align="center" size={-1}>
            {hint}
          </GreyText>
        </Row>
      ))}
    </HintContainer>
  );
};
