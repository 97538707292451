import { InternalApplicationSummary } from "./InternalApplicationSummary";
import { FooterContent } from "./styles";

import { Text, Button, Middle, Spacing } from "@otta/design";
import { ApplyViaOttaButtonContent } from "@otta/search/components/ApplyViaOttaButtonContent";
import { Link } from "@otta/search/components/Link";
import { ApplyModalJob, ApplyContent } from "@otta/search/schema";

interface InternalApplyProps {
  job: ApplyModalJob.PublicJob;
  onApply(internal: boolean): void;
  submitting?: boolean;
  receivedMessageRequest:
    | ApplyContent.CandidateReceivedMessageRequest
    | undefined;
}

export function InternalApply({
  job,
  onApply,
  submitting,
  receivedMessageRequest,
}: InternalApplyProps): React.ReactElement {
  return (
    <FooterContent>
      <Spacing size={1}>
        <InternalApplicationSummary company={job.company} />
        <Middle>
          <Button
            type="button"
            level="primary"
            onClick={() => onApply(true)}
            disabled={submitting}
            style={{ margin: "0 auto" }}
            data-testid="apply-via-otta-button"
          >
            <ApplyViaOttaButtonContent />
          </Button>
        </Middle>
        {job.originalUrl && (
          <div style={{ textAlign: "center" }}>
            Or apply on{" "}
            <Link
              to={job.originalUrl}
              underline={false}
              onClick={() => onApply(false)}
            >
              {job.company.name}&apos;s website
            </Link>
          </div>
        )}
        {receivedMessageRequest?.conversation && (
          <Text align="center" size={-1}>
            You&apos;ve already been messaged by {job.company.name} about this
            role.{" "}
            <Link to={`/messaging/${receivedMessageRequest.conversation.id}`}>
              View message
            </Link>
          </Text>
        )}
      </Spacing>
    </FooterContent>
  );
}
