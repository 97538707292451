import { PropsWithChildren } from "react";
import styled, { css, down } from "@xstyled/styled-components";
import { useLocation } from "react-router-dom";

import { CardContent } from "./content";
import { Sector, Theme, themeUrl } from "./theme";

import { palette, pxToRem, borderRadius } from "@otta/design-tokens";
import { Heading, responsive, Text } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Link } from "@otta/search/components/Link";
import { ThemeIcon } from "@otta/search/components/ThemeIcon";
import { ThemeId } from "@otta/search/schema";

type Colour = {
  colour: string;
};

type BatchCardProps = {
  className?: string;
  theme: Theme<Sector>;
  index: number;
  count: number;
  hoverTheme?: (val: ThemeId | null) => void;
  onClick?: React.MouseEventHandler<Element>;
};

const CardTitle = styled(Heading)`
  ${responsive`font-size: ${responsive.modularScale({
    mobile: 0,
    desktop: 2,
  })};`}
  ${responsive`line-height: ${{ mobile: 21, desktop: 30 }};`}
`;

const Pill = styled.span.attrs<Colour, Colour>(props => props)`
  font-weight: 600;
  color: ${({ colour }) =>
    colour == palette.brand.black || colour === palette.grayscale.shade600
      ? palette.brand.white
      : palette.brand.black};
  background-color: ${props => props.colour};
  line-height: ${pxToRem(17)};

  padding: xs sm;
  ${down(
    "tablet",
    css`
      padding: xxs 6;
    `
  )}

  font-size: ${pxToRem(13)};
  border-radius: ${pxToRem(borderRadius)};
  float: right;
`;

const LinkButton = styled(Link)`
  :hover {
    text-decoration: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const emptyStack = css`
  background-color: rgba(255, 255, 255, 0.4);
`;

type StackProps = {
  count: number;
  mobileWidth?: number;
};

/**
 * Map a card index into a colour
 */
function colour(index: number): string {
  switch (index % 5) {
    case 1:
      return palette.brand.teal;
    case 2:
      return palette.brand.orange;
    case 3:
      return palette.brand.blue;
    case 4:
      return palette.brand.pink;
    case 5:
      return palette.brand.green;
    default:
      return palette.brand.yellow;
  }
}

/**
 * Construct the CSS for part of a card stack
 * They gradually get darker and smaller as index increases
 */
function shadowElement(index: number) {
  const bg = palette.grayscale.shade50;
  const offset: number = (index * 0.5) / 1.5;

  return css`
    background-color: ${bg};
    box-shadow: 0 ${pxToRem(2)} ${pxToRem(4)} 0 #0000000d;
    inset: 85% ${offset}rem -${offset}rem ${offset}rem;
    border-radius: 4;
    position: absolute;
    z-index: -${index};
    display: block;
    content: "";
  `;
}

const populatedStack = css`
  box-shadow: 0 ${pxToRem(2)} ${pxToRem(4)} 0 #0000000d;
  background-color: white;
  margin-bottom: 1rem;

  &:before {
    ${shadowElement(1)}
  }

  &:after {
    ${shadowElement(2)}
  }

  :hover {
    background-color: gray-100;
    transition: background-color 1s;
  }
`;

const Stack = styled.div<StackProps>`
  ${({ count }) => (count > 0 ? populatedStack : emptyStack)}

  ${responsive`padding: ${responsive.modularScale({
    mobile: -2,
    desktop: 0,
  })};`}
  border-radius: 4;
  position: relative;
  text-decoration: none;

  svg {
    height: ${pxToRem(20)};
    width: ${pxToRem(20)};
  }

  ${({ mobileWidth }) =>
    mobileWidth &&
    css`
      ${down(
        "tablet",
        css`
          width: ${pxToRem(mobileWidth)};
        `
      )}
    `}
`;

function analyticsId(theme: Theme<unknown>): string {
  return theme.id.replace(/_/g, "-").toLowerCase();
}

export function BatchCard({
  count,
  className,
  index,
  theme,
  hoverTheme,
  onClick,
}: PropsWithChildren<BatchCardProps>): React.ReactElement | null {
  const location = useLocation();

  const card = (
    <Stack
      count={count}
      className={className}
      {...(count == 0 ? { "data-testid": theme.id } : {})}
      onMouseEnter={() => {
        theme.id != "ALL_MATCHES" && hoverTheme?.(theme.id);
      }}
      onMouseLeave={() => {
        hoverTheme?.(null);
      }}
    >
      <Text>
        <ThemeIcon themeId={theme.id} />
        <Pill colour={count == 0 ? palette.grayscale.shade600 : colour(index)}>
          {count == 10 ? "10+" : `${count}`} {count == 1 ? "job" : "jobs"}
        </Pill>
      </Text>
      <CardTitle data-testid="card-title" as="h4">
        <CardContent theme={theme} />
      </CardTitle>
    </Stack>
  );
  if (count > 0) {
    return (
      <LinkButton
        to={themeUrl(theme)}
        data-testid={theme.id}
        onClick={event => {
          pushAnalyticsEvent({
            eventName:
              location.pathname === "/onboarding"
                ? "Candidate clicked show initial matches"
                : "Candidate Clicked",
            pathname: location.pathname,
            type: (event.target as HTMLElement).nodeName,
            name: `${analyticsId(theme)}-batch-card`,
            index: index,
            ...(location.pathname === "/onboarding" && {
              themeId: analyticsId(theme),
              sector:
                theme.id == ThemeId.PreferredSector
                  ? theme.sector.value
                  : "n/a",
            }),
          });
          onClick?.(event);
        }}
      >
        {card}
      </LinkButton>
    );
  }

  return card;
}
