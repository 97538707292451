import { AssetGroupSprinkler } from "@otta/search/components/AssetSprinkler";
import { BrandAssetGroup, CompanyFragment } from "@otta/search/schema";

export function shouldDisplayCompanyGallery(
  company: Pick<CompanyFragment, "brandAssetGroups">
): boolean {
  return (
    company?.brandAssetGroups?.some(
      b =>
        b.group.name === BrandAssetGroup.CompanyProfileGallery &&
        b.links.length > 0
    ) ?? false
  );
}

export function CompanyGallery({
  company,
}: {
  company: CompanyFragment;
}): React.ReactElement {
  return (
    <AssetGroupSprinkler
      assets={company.brandAssetGroups}
      group={BrandAssetGroup.CompanyProfileGallery}
      data-testid="gallery"
      mobileCarousel
      noTitles
    />
  );
}
