import { Field } from "react-final-form";
import { FieldValidator } from "final-form";
import { useCallback } from "react";

import { Textarea } from "@otta/search/components/Input/Textarea";

interface IDescriptionFormProps {
  placeholders: string[];
  fieldName: string;
  label?: string;
  hint?: string;
  onBlur?: () => void;
  validate?: FieldValidator<string[] | undefined>;
  border?: boolean;
  minHeight?: string;
  maxHeight?: string;
  maxBullets?: number;
}

const onChangeWithPreservedCursor =
  (
    onChange: React.ChangeEventHandler<HTMLTextAreaElement>
  ): React.ChangeEventHandler<HTMLTextAreaElement> =>
  async e => {
    e.persist();
    const oldLength = e.target.value.length;
    const caretStart = e.target.selectionStart;
    const caretEnd = e.target.selectionEnd;
    await onChange(e);
    const newLength = e.target.value.length;
    const diff = newLength - oldLength;
    e.target.setSelectionRange(caretStart + diff, caretEnd + diff);
  };

export function BulletTextarea({
  label,
  hint,
  fieldName,
  placeholders,
  validate,
  border,
  minHeight,
  maxHeight,
  maxBullets,
}: IDescriptionFormProps): React.ReactElement {
  const format = useCallback((v?: string[]): string => {
    if (!v) {
      return "";
    }
    const bullets = v ?? [""];
    const formattedBullets = bullets.map(content => `• ${content}`).join("\n");

    return formattedBullets;
  }, []);

  const parse = useCallback(
    (v?: string): undefined | string[] => {
      const bullets =
        v
          ?.replace(/\n•$/g, "")
          ?.replace(/•\s*/g, "")
          ?.split("\n")
          ?.map(value => value.charAt(0).toUpperCase() + value.slice(1)) ?? [];

      if (maxBullets) {
        return bullets.slice(0, maxBullets);
      }
      return bullets;
    },
    [maxBullets]
  );
  return (
    <Field name={fieldName} parse={parse} format={format} validate={validate}>
      {({ input, meta }) => (
        <div>
          <Textarea
            {...input}
            label={label}
            hint={hint}
            onChange={onChangeWithPreservedCursor(input.onChange)}
            placeholder={format(placeholders)}
            data-testid="bullet-textarea-field"
            error={meta.touched && meta.error}
            autoFocus={false}
            border={border}
            minHeight={minHeight}
            maxHeight={maxHeight ?? "200px"}
          />
        </div>
      )}
    </Field>
  );
}
