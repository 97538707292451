import { useMemo, useState } from "react";
import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { FrequencyButtons } from "./FrequencyButtons";

import { Button, Clickable, Heading, Text, Tipbox, Toggle } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import {
  CreateUserSuppressionDocument,
  DeleteUserSuppressionDocument,
  NotificationFrequency,
  UpdateUserNotificationPreferencesDocument,
  UserSubscription,
  UserSubscriptionsDocument,
} from "@otta/search/schema";
import { Email } from "@otta/search/components/Icons/Email";
import { Mobile } from "@otta/icons";

const SaveButton = styled(Button)`
  width: 100%;
  margin: xl 0;
`;

const jobMatchesSchema = Yup.object().shape({
  channels: Yup.array(),
  jobEmailNotificationsFrequency: Yup.string().when("channels", {
    is: (val: string[]) => val.length > 0,
    then: s =>
      s.test(
        "is-valid-frequency",
        "Select a frequency",
        val => val !== NotificationFrequency.Never
      ),
  }),
});

const schema = Yup.object().shape({
  channels: Yup.array(),
  jobEmailNotificationsFrequency: Yup.string(),
});

const Warning = styled(Tipbox)`
  margin-top: xl;
  border-color: red-800;
  background-color: orange-100;
`;

export const EditSetting = ({
  group,
  jobEmailNotificationsFrequency,
  onCancel,
}: {
  group: UserSubscription;
  jobEmailNotificationsFrequency: NotificationFrequency;
  onCancel: () => void;
}) => {
  const [
    isMessagesFromCompaniesUnsubscribed,
    setMessagesFromCompaniesUnsubscribed,
  ] = useState(false);

  const [updateUserNotificationPreferences] = useMutation(
    UpdateUserNotificationPreferencesDocument
  );

  const [createUserSuppression] = useMutation(CreateUserSuppressionDocument, {
    refetchQueries: [
      {
        query: UserSubscriptionsDocument,
      },
    ],
  });

  const [deleteUserSuppression] = useMutation(DeleteUserSuppressionDocument, {
    refetchQueries: [
      {
        query: UserSubscriptionsDocument,
      },
    ],
  });

  const form = useFormik({
    initialValues: {
      channels: (group.channels || []).reduce((acc: string[], val) => {
        if (val.value) {
          acc.push(val.id);
        }
        return acc;
      }, []),
      jobEmailNotificationsFrequency,
    },
    validationSchema: group.name === "Job matches" ? jobMatchesSchema : schema,
    onSubmit: values => {
      if (group.name === "Job matches") {
        if (values.channels.length < 1) {
          updateUserNotificationPreferences({
            variables: { frequency: NotificationFrequency.Never },
          });
        } else if (
          jobEmailNotificationsFrequency !==
          values.jobEmailNotificationsFrequency
        ) {
          updateUserNotificationPreferences({
            variables: { frequency: values.jobEmailNotificationsFrequency },
          });
        }
      }

      if (group.channels) {
        for (const c in group.channels) {
          const channel = group.channels[c];

          if (selectedChannels.has(channel.id)) {
            deleteUserSuppression({
              variables: {
                channelId: channel.id,
                unsubscribeGroupId: group.id,
              },
            });
          } else {
            createUserSuppression({
              variables: {
                channelId: channel.id,
                source: "search_app_settings",
                unsubscribeGroupId: group.id,
              },
            });
          }
        }
      }

      onCancel();
    },
  });

  const selectedChannels = useMemo(
    () => new Set(form.values.channels ?? []),
    [form.values.channels]
  );

  const handleValueChange = (channelId: string, value: boolean) => {
    const selectedChannelsInput = new Set(selectedChannels);
    if (value) {
      selectedChannelsInput.add(channelId);
    } else {
      selectedChannelsInput.delete(channelId);
    }

    form.setFieldValue("channels", Array.from(selectedChannelsInput));
  };

  return (
    <div key={`${group.name}-edit`}>
      <Heading
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: pxToRem(16),
        }}
      >
        <Text bold>{group.name}</Text>
        <Clickable>
          <Text
            onClick={onCancel}
            data-testid={`${group.name}-edit-button`}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            Cancel
          </Text>
        </Clickable>
      </Heading>
      <Text
        align="left"
        style={{ marginTop: pxToRem(16), marginBottom: pxToRem(8) }}
      >
        {group.description}
      </Text>

      {(group.channels || []).map(channel => (
        <ToggleSection
          key={`toggle-${channel.id}`}
          name={channel.name}
          checked={selectedChannels.has(channel.id)}
          onChange={(checked: boolean) => {
            setMessagesFromCompaniesUnsubscribed(!checked);
            handleValueChange(channel.id, checked);
          }}
        />
      ))}

      {group.name === "Messages from companies" &&
        isMessagesFromCompaniesUnsubscribed && (
          <Warning level="error">
            <Text align="left">
              By turning off messages from companies, you may miss important
              updates, such as a response to an application.
            </Text>
          </Warning>
        )}

      {group.name === "Job matches" && (
        <FrequencyButtons
          jobEmailNotificationsFrequency={
            form.values.jobEmailNotificationsFrequency
          }
          handleChangeFrequency={(value: string) =>
            form.setFieldValue("jobEmailNotificationsFrequency", value)
          }
          isDisabled={selectedChannels.size < 1}
        />
      )}
      {form.submitCount > 0 && form.errors.jobEmailNotificationsFrequency && (
        <Text
          align="left"
          color={palette.brand.red}
          style={{ marginTop: pxToRem(16) }}
        >
          {form.errors.jobEmailNotificationsFrequency}
        </Text>
      )}
      <SaveButton level="primary" type="submit" onClick={form.submitForm}>
        Save
      </SaveButton>
    </div>
  );
};

const ToggleSection = ({
  name,
  checked,
  onChange,
}: {
  name: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: pxToRem(16),
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {name === "push" ? (
          <Mobile style={{ width: pxToRem(24) }} color={palette.brand.black} />
        ) : (
          <Email style={{ width: pxToRem(24) }} color={palette.brand.black} />
        )}
        <Text style={{ marginLeft: pxToRem(8), lineHeight: pxToRem(24) }}>
          {name === "push" ? "Push" : "Email"}
        </Text>
      </div>
      <Toggle
        aria-label={`${name}-toggle`}
        onChange={(checked: boolean) => {
          onChange(checked);
        }}
        checked={checked}
      />
    </div>
  );
};
