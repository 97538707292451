import styled from "@xstyled/styled-components";
import { useEffect } from "react";

import { Spacing, Text, Button, Middle } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { PageWrapper } from "@otta/search/components/PageWrapper";
import { Link } from "@otta/search/components/Link";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: xl 0;
`;

interface IEmployeeSurveyThankYou {
  company: { name: string; urlSafeName: string };
}

export function EmployeeSurveyThankYou({
  company,
}: IEmployeeSurveyThankYou): React.ReactElement {
  useEffect(() => {
    pushAnalyticsEvent({ eventName: "Employee Completed Company Review" });
  }, []);

  return (
    <PageWrapper>
      <Wrapper>
        <Middle maxWidth={390}>
          <Spacing>
            <Text bold as="h2" size={2}>
              Thank you!
            </Text>
            <Text>
              Your submission is anonymous, and will help give candidates
              insight into what it&apos;s like to work at {company.name}.
            </Text>
            <Text>
              Endorsements are typically published within 2 business days.
            </Text>
            <Link
              to={`/companies/${encodeURIComponent(company.urlSafeName)}`}
              underline={false}
            >
              <Button style={{ width: "100%" }} type="button" level="primary">
                View {company.name}&apos;s profile
              </Button>
            </Link>
          </Spacing>
        </Middle>
      </Wrapper>
    </PageWrapper>
  );
}
