import styled from "@xstyled/styled-components";
import queryString from "query-string";

import { Button, Text, VerticalSpacing } from "@otta/design";
import { Icon } from "@otta/icons";
import { pxToRem } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";

const ModalContentContainer = styled.div`
  padding: xl lg;
`;
const BulletPoints = styled.div`
  display: flex;
  flex-direction: column;
  gap: lg;
  margin-top: xl;
`;
const BulletPoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const BulletIcon = styled(Icon)`
  margin-right: sm;
  padding-top: xxs;
`;

const SignUpButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const SignUpButton = styled(Button)`
  width: 100%;
  max-width: ${pxToRem(300)};
`;

export const NextButtonModalContents = ({
  utmSource,
  externalId,
}: {
  utmSource?: string;
  externalId: string;
}): React.ReactElement => {
  return (
    <ModalContentContainer>
      <VerticalSpacing size={2}>
        <Text bold>
          You&apos;ll find 70,000 of the best jobs from over 3,500 top
          companies.
        </Text>
        <BulletPoints>
          <BulletPoint>
            <BulletIcon icon="heart" />
            <Text>
              Tell us what you&apos;re looking for and we&apos;ll pick only the
              best matches for you, every day
            </Text>
          </BulletPoint>
          <BulletPoint>
            <BulletIcon icon="template" />
            <Text>
              Save and apply for the ones you&apos;re most interested in, and
              we&apos;ll use this to find you even better matches
            </Text>
          </BulletPoint>
        </BulletPoints>
        <SignUpButtonContainer>
          <SignUpButton
            data-analytics-id="public-job-next-save-modal-sign-up-button"
            as={Link}
            to={{
              pathname: "/sign-up",
              search: queryString.stringify({
                utm_source: utmSource,
                redirect: `/jobs/${externalId}`,
              }),
            }}
            underline={false}
            level="primary"
          >
            Sign up
          </SignUpButton>
        </SignUpButtonContainer>
        <Text align="center">
          Already have an account?{" "}
          <Link to="/login">
            <Text as="span" bold>
              Sign in
            </Text>
          </Link>
        </Text>
      </VerticalSpacing>
    </ModalContentContainer>
  );
};
