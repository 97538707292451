import { sub } from "date-fns";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { JobApplication } from "./types";

import { useQuery } from "@otta/search/apollo";
import { UnansweredJobApplicationsDocument } from "@otta/search/schema";

export default function useJobApplications(): [Array<JobApplication>, boolean] {
  const { externalId: excludedExternalId } = useParams();
  const [qs] = useSearchParams();
  const token = qs.get("token");

  const twoWeeksAgo = useMemo(() => sub(new Date(), { days: 13 }), []);
  const threeWeeksAgo = useMemo(() => sub(new Date(), { days: 21 }), []);

  const { loading, data } = useQuery(UnansweredJobApplicationsDocument, {
    variables: {
      appliedAtLt: twoWeeksAgo.toISOString(),
      appliedAtGt: threeWeeksAgo.toISOString(),
    },
    context: { emailToken: token },
  });

  const jobApplications =
    data?.currentUser?.jobApplications.filter(
      ({ externalId }) => externalId !== excludedExternalId
    ) ?? [];

  return [
    jobApplications.map(application => ({
      externalId: application.externalId,
      job: {
        title: application.job.title,
      },
      company: {
        name: application.job.company.name,
        primaryColour: application.job.company.primaryColour,
        faviconPath: application.job.company.faviconPath,
      },
    })),
    loading,
  ];
}
