export function Hourglass(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      viewBox="0 0 9 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19501 0.65L9 0.65V0H0V0.65L0.802935 0.65L1.06197 1.48614C1.34786 2.40894 1.89495 3.22915 2.6371 3.84761L3.82294 4.8358L3.17183 5.25488C2.22097 5.86688 1.50565 6.78312 1.14261 7.85404L0.821909 8.80007H0V9.45007H9V8.80007H8.21663L8.03175 8.1293C7.69041 6.8909 6.88553 5.83138 5.78403 5.17046L5.19767 4.81864L6.36116 3.84906C7.1044 3.22969 7.65198 2.408 7.93752 1.48361L8.19501 0.65ZM7.40512 8.30202L7.54239 8.80007H1.50824L1.7582 8.06272C2.07404 7.13102 2.69638 6.33389 3.52362 5.80145L4.32909 5.28302C4.44111 5.21092 4.58436 5.20867 4.69859 5.27721L5.4496 5.72783C6.40791 6.30283 7.10815 7.22461 7.40512 8.30202ZM7.31647 1.29178L7.51471 0.65H1.48341L1.68286 1.29379C1.93158 2.09662 2.40755 2.81021 3.05322 3.34827L4.27594 4.36719C4.40573 4.47536 4.59427 4.47536 4.72406 4.36719L5.94504 3.34972C6.59166 2.81087 7.06805 2.096 7.31647 1.29178Z"
      />
    </svg>
  );
}
