export function Pound(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      fill="none"
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="12" />
      <path
        d="m16.279 17.088v1.8725h-9.1334c0.52706-1.1928 0.92236-2.2609 1.1859-3.204 0.2774-0.95704 0.48545-2.0805 0.62416-3.3705h-1.602v-1.5396h1.7684l0.22886-2.2886c0.13876-1.4286 0.54094-2.5244 1.2067-3.2872 0.67969-0.77672 1.6507-1.1651 2.9127-1.1651 0.94328 0 1.8448 0.24273 2.7047 0.72818l-0.20805 2.1013c-0.4161-0.34676-0.8114-0.60335-1.1859-0.76979-0.36057-0.18032-0.74899-0.27047-1.1651-0.27047-0.67953 0-1.2136 0.22193-1.602 0.66577-0.37449 0.42998-0.60335 1.0403-0.68657 1.8309l-0.24966 2.455h3.8906v1.5396h-4.057c-0.12483 1.0819-0.27735 1.9765-0.45772 2.6839-0.16644 0.69346-0.40218 1.3663-0.70738 2.0181z"
        fill="#fff"
        strokeWidth="1.6004"
      />
    </svg>
  );
}
