// eslint-disable-next-line no-restricted-imports
import { Navigate, To, useHref } from "react-router-dom";

import { useStatus } from "@otta/search/providers/page";

function ServerNavigate({ to, status }: { to: To; status?: number }) {
  const location = useHref(to);

  const { redirect } = useStatus();

  return redirect(location, status);
}

export function Redirect({
  to,
  status,
  state,
}: {
  to: To;
  status?: number;
  state?: any;
}): React.ReactElement | null {
  if (import.meta.env.SSR && import.meta.env.MODE !== "test") {
    return <ServerNavigate to={to} status={status} />;
  }

  return <Navigate to={to} replace state={state} />;
}

export function NotFound({
  children,
}: {
  children?: React.ReactNode;
}): React.ReactElement {
  const { status } = useStatus();

  if (import.meta.env.SSR && import.meta.env.MODE !== "test") {
    status(404);
  }

  return <>{children}</>;
}
