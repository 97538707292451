"use client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import * as cookie from "cookie";

const localStorageKey = "_otta_experiment_debugger_state";
const cookieKey = "_otta_experiment_debugger";

export type ExperimentDefinition = Record<
  string,
  { variant: string; payload?: unknown }[]
>;

interface ExperimentDebuggerProps {
  definitions: ExperimentDefinition;
  lastSeen: Record<string, string>;
  active: Set<string>;
  onChange(name: string, value: string): void;
}

function ExperimentDebugger({
  definitions,
  lastSeen,
  active,
  onChange,
}: ExperimentDebuggerProps): React.ReactElement {
  const [open, setOpen] = useState(false);

  const experiments = useMemo(
    () => Object.entries(definitions).filter(([name]) => active.has(name)),
    [active, definitions]
  );

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: "50%",
        zIndex: 99999,
        background: "#fff",
        transform: open ? "translateX(-50%)" : "translate(-50%, -100%)",
        padding: "5px 10px 10px",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        transition: "transform 0.5s ease-in-out",
      }}
    >
      {experiments.length ? (
        experiments.map(([name, variants]) => (
          <div key={name}>
            <p style={{ fontWeight: "bold" }}>{name}</p>
            <select
              value={lastSeen[name]}
              onChange={e => {
                onChange(name, e.target.value);
              }}
            >
              {variants.map(({ variant }) => (
                <option key={variant}>{variant}</option>
              ))}
            </select>
          </div>
        ))
      ) : (
        <p style={{ fontWeight: "bold" }}>No experiments currently active</p>
      )}
      <button
        style={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 100%)",
          background: "#fff",
          border: "none",
          padding: "5px",
          outline: "none",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
        onClick={() => setOpen(!open)}
      >
        {open ? "Close" : "Open"}
      </button>
    </div>
  );
}

export function useDebugger(
  definitions: ExperimentDefinition,
  portal?: HTMLElement
): [
  Record<string, string>,
  (name: string, variant: string) => () => void,
  React.ReactNode
] {
  const [variantOverrides, setVariantOverrides] = useState<
    Record<string, string>
  >({});
  const [lastSeenVariants, setLastSeenVariants] = useState<
    Record<string, string>
  >({});
  const [activeExperiments, setActiveExperiments] = useState<
    Record<string, number>
  >({});

  const onSeen = useCallback((name: string, variant: string) => {
    setLastSeenVariants(v => ({ ...v, [name]: variant }));

    setActiveExperiments(v => ({ ...v, [name]: (v[name] ?? 0) + 1 }));

    return () => {
      setActiveExperiments(v => ({
        ...v,
        [name]: (v[name] ?? 1) - 1,
      }));
    };
  }, []);

  useEffect(() => {
    if (!definitions) {
      return;
    }

    const item = window.localStorage.getItem(localStorageKey);

    if (item) {
      const storedValue = Object.entries<string>(JSON.parse(item))
        .filter(([key]) => !!definitions[key])
        .reduce<Record<string, string>>((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      setVariantOverrides(storedValue);
    }
  }, [definitions]);

  const handleChange = useCallback(
    (name: string, variant: string) => {
      const overrides = { ...variantOverrides, [name]: variant };
      window.localStorage.setItem(localStorageKey, JSON.stringify(overrides));
      setVariantOverrides(overrides);
    },
    [variantOverrides]
  );

  const active = useMemo(
    () =>
      new Set(
        Object.keys(activeExperiments).filter(
          e => (activeExperiments[e] ?? 0) > 0
        )
      ),
    [activeExperiments]
  );

  return [
    variantOverrides,
    onSeen,
    portal &&
      createPortal(
        <ExperimentDebugger
          definitions={definitions}
          lastSeen={lastSeenVariants}
          active={active}
          onChange={handleChange}
        />,
        portal
      ),
  ];
}

export function useDebuggerPortal() {
  const [portal, setPortal] = useState<HTMLDivElement>();
  useEffect(() => {
    const cookies = cookie.parse(document.cookie);
    if (cookies[cookieKey]) {
      const el = document.createElement("div");
      el.id = "experiment-debugger";
      document.body.appendChild(el);
      setPortal(el);

      return () => {
        document.body.removeChild(el);
        setPortal(undefined);
      };
    }
  }, []);

  return portal;
}
