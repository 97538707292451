import { useEffect } from "react";
import styled from "@xstyled/styled-components";
import { Helmet } from "react-helmet-async";

import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  CompaniesSitemapDocument,
  CompanyWorkflowStatus,
} from "@otta/search/schema";

const PageWrapper = styled.section`
  padding: lg;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 690px;
`;

export function SitemapIndex(): React.ReactElement {
  useEffect(() => {
    pushAnalyticsEvent({ eventName: "Viewed Sitemap" });
  }, []);

  const { data } = useQuery(CompaniesSitemapDocument, {
    fetchPolicy: "network-only",
    ssr: true,
    variables: {
      workflowStatuses: [
        CompanyWorkflowStatus.Completed,
        CompanyWorkflowStatus.Changed,
      ],
    },
  });

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://app.welcometothejungle.com/companies-sitemap"
        />
      </Helmet>
      <PageWrapper>
        <Content>
          <ul>
            {data?.companies?.map(item => {
              return (
                <li>
                  <a
                    href={`https://app.welcometothejungle.com/companies/${item.urlSafeName}`}
                  >
                    {item.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </Content>
      </PageWrapper>
    </>
  );
}
