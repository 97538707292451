import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { Providers } from "@otta/search/providers";
import { apolloClient } from "@otta/search/providers/apollo";

export function Client({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  useIdleTimer({
    timeout: 1000 * 60 * 60 * 12,
    onIdle: () => window.location.reload(),
  });

  return (
    <ApolloProvider client={apolloClient}>
      <Providers>
        <BrowserRouter>{children}</BrowserRouter>
      </Providers>
    </ApolloProvider>
  );
}
