import styled, { css, up } from "@xstyled/styled-components";

import { Spacing, Text } from "@otta/design";
import { pxToRem, borderRadius } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import {
  Currency,
  ExampleJobs as ExampleJob,
  ExampleJobsDocument,
  JobSubFunction,
  Location,
} from "@otta/search/schema";
import { Link } from "@otta/search/components/Link";
import { currencyPrefix } from "@otta/search/utils/currencies";

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CardWrapper = styled.div`
  flex: 0 0 100%;
  display: flex;
  padding: sm 0;

  ${up(
    "tablet",
    css`
      padding: sm;
      flex: 0 0 50%;
    `
  )}
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

const Card = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  height: 80px;
  align-items: center;
  padding: 0 19;
  border-radius: ${pxToRem(borderRadius)};
  transition: default;
  cursor: pointer;

  &:hover {
    background-color: gray-50;
  }
`;

const CompanyLogo = styled.img`
  width: 50px;
  height: 50px;
`;

const JobTitle = styled(Text)`
  flex-grow: 1;
  padding: 0 lg;
`;

const SalaryRange = styled(Text)`
  flex-shrink: 0;
`;

const formatSalary = (value: number, currency: Currency): string => {
  return value > 0
    ? `${currencyPrefix(currency)}${Math.trunc(value / 1000)}K`
    : "";
};

interface ExampleJobsProps {
  subFunction: JobSubFunction;
  location: Location;
}

export function ExampleJobs({
  subFunction,
  location,
}: ExampleJobsProps): React.ReactElement | null {
  const { data } = useQuery(ExampleJobsDocument, {
    variables: {
      subFunctionId: subFunction.id,
      location: location,
      limit: 8,
    },
  });

  if (!data) {
    return <Loading />;
  }

  const jobs = data.salaryStatistics.exampleJobs;

  return jobs.length > 0 ? (
    <Spacing>
      <Text align="center" as="h3" size={2} bold>
        Example jobs with salaries
      </Text>
      <Text align="center">
        We have jobs and salary data from{" "}
        <Link to="/companies">
          1000s of the world&apos;s fastest growing tech companies
        </Link>
        .
      </Text>
      <CardsContainer>
        {jobs.map(job => (
          <ExampleJobCard job={job} key={job.id} />
        ))}
      </CardsContainer>
    </Spacing>
  ) : null;
}

function ExampleJobCard({
  job,
}: {
  job: ExampleJob.ExampleJobs;
}): React.ReactElement {
  const { title, externalId, salaryRange, company } = job;

  return (
    <CardWrapper>
      <StyledLink
        underline={false}
        to={`https://app.welcometothejungle.com/jobs/${externalId}`}
      >
        <Card>
          {company.faviconPath && <CompanyLogo src={company.faviconPath} />}
          <JobTitle align="left" size={-1} bold>
            {title}
          </JobTitle>
          {salaryRange && (
            <SalaryRange size={-1}>
              {formatSalary(
                Number(salaryRange.minAmount),
                salaryRange.currency
              )}{" "}
              -{" "}
              {formatSalary(
                Number(salaryRange.maxAmount),
                salaryRange.currency
              )}
            </SalaryRange>
          )}
        </Card>
      </StyledLink>
    </CardWrapper>
  );
}
