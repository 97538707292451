import { useMutation } from "@apollo/client";

import { FormCardList } from "../../components/FormCardList";
import { CardTitle } from "../../components/FormHeader";

import {
  DisplayContextQuestion,
  EditingContextQuestion,
} from "./ContextQuestion";

import {
  ContextQuestionResponsesDocument,
  ContextQuestionsDocument,
  CreateContextQuestionResponseDocument,
  DeleteContextQuestionResponseDocument,
  UpdateContextQuestionResponseDocument,
  ContextQuestionResponsesQuery,
  ContextQuestionResponses,
  ProfileCompletionStatusDocument,
} from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";

export function ContextQuestions(): React.ReactElement {
  const [updateMutation] = useMutation(UpdateContextQuestionResponseDocument, {
    refetchQueries: [
      { query: ContextQuestionResponsesDocument },
      { query: ContextQuestionsDocument },
      { query: ProfileCompletionStatusDocument },
    ],
    awaitRefetchQueries: true,
    onError: handleMutationError,
  });
  const [createMutation] = useMutation(CreateContextQuestionResponseDocument, {
    refetchQueries: [
      { query: ContextQuestionResponsesDocument },
      { query: ContextQuestionsDocument },
      { query: ProfileCompletionStatusDocument },
    ],
    awaitRefetchQueries: true,
    onError: handleMutationError,
  });
  const [deleteMutation] = useMutation(DeleteContextQuestionResponseDocument, {
    refetchQueries: [
      { query: ContextQuestionResponsesDocument },
      { query: ContextQuestionsDocument },
      { query: ProfileCompletionStatusDocument },
    ],
    awaitRefetchQueries: true,
    onError: handleMutationError,
  });
  return (
    <div id="context-questions-form-id">
      <FormCardList
        title="Questions about you"
        tipText="We recommend you add another answer to give your profile the best chance of standing out"
        tipLimit={3}
        introText="Answer questions about you to stand out to hiring managers. Select your best answer to feature at the top of your profile."
        query={ContextQuestionResponsesDocument}
        formatTitle={({ question }) => (
          <CardTitle>{question.question}</CardTitle>
        )}
        formatQueryData={({ currentUser }: ContextQuestionResponsesQuery) =>
          currentUser?.contextQuestionResponses ?? []
        }
        formatIncomingData={({
          value,
          question,
          featuredAnswer,
        }: ContextQuestionResponses.ContextQuestionResponses) => ({
          answer: value,
          question: question.id,
          featuredAnswer: featuredAnswer ?? false,
        })}
        formatOutgoingData={({ question, answer, featuredAnswer }) => ({
          questionId: question,
          value: answer,
          featuredAnswer: featuredAnswer ?? false,
        })}
        onCreate={async ({
          questionId,
          value,
          featuredAnswer,
        }: {
          questionId: string;
          value: string;
          featuredAnswer: boolean;
        }) =>
          createMutation({ variables: { questionId, value, featuredAnswer } })
        }
        onUpdate={id =>
          async ({ value, questionId, featuredAnswer }) =>
            updateMutation({
              variables: { id, value, questionId, featuredAnswer },
            })}
        onDelete={id => async () => deleteMutation({ variables: { id } })}
        editingComponent={EditingContextQuestion}
        displayComponent={DisplayContextQuestion}
        limit={5}
        recommended={false}
      />
    </div>
  );
}
