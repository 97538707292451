import { ApolloCache } from "@apollo/client";

import { JobRecommendationsDocument } from "@otta/search/schema";

export function evictJobRecommendationsCache(cache: ApolloCache<object>): void {
  const cacheData = cache.readQuery({
    query: JobRecommendationsDocument,
  });

  if (!cacheData?.currentUser) {
    return;
  }

  cache.evict({
    id: cache.identify({ ...cacheData.currentUser }),
    fieldName: "jobRecommendations",
  });
}
