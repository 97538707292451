import { useSearchParams } from "react-router-dom";

/**
 * Find a param called "redirect" and check it is a valid path
 * i.e. does not begin with two forward slashes as that is a protocol relative URL
 * so //google.com == https://google.com
 */
export function useRedirectParam(): string | null {
  const [qs] = useSearchParams();
  const param = qs.get("redirect");
  return param?.match(/^\/?([^/]+\/?)*$/) ? param : null;
}
