import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

const IconButtonComponent = styled.div<{
  colour: string;
  hoverColour: string;
}>`
  min-width: 34px;
  min-height: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ colour }) => colour};

  &:hover {
    font-weight: 500;
    svg {
      color: ${({ hoverColour }) => hoverColour};
      fill: ${({ hoverColour }) => hoverColour};
    }
  }
  &:active {
    svg {
      width: 14px;
      margin-bottom: 0px;
    }
  }
  svg {
    width: 16px;
    fill: ${({ colour }) => colour};
  }
`;

export function IconButton({
  children,
  colour = palette.brand.grey,
  hoverColour = palette.brand.black,
  ...props
}: {
  children: React.ReactNode;
  colour?: string;
  hoverColour?: string;
  [x: string]: any;
}): React.ReactElement {
  return (
    <IconButtonComponent {...props} colour={colour} hoverColour={hoverColour}>
      {children}
    </IconButtonComponent>
  );
}
