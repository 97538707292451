import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { GreenhouseForm } from "./forms/GreenhouseForm";
import { LeverForm } from "./forms/LeverForm";
import { WorkableForm } from "./forms/WorkableForm";
import { OttaForm } from "./forms/OttaForm";

import { useQuery } from "@otta/search/apollo";
import {
  JobQuestionDataDocument,
  JobApplicationDataDocument,
  UpsertJobApplicationDataDocument,
  JobApplicationDataInput,
} from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";

export function ApplicationQuestions({
  jobId,
}: {
  jobId: string;
}): React.ReactElement | null {
  const { data: questionsData } = useQuery(JobQuestionDataDocument, {
    variables: { jobId },
  });

  const { data: answersData } = useQuery(JobApplicationDataDocument, {
    variables: { jobId },
  });

  const [upsertMutation] = useMutation(UpsertJobApplicationDataDocument, {
    update: (cache, mutationResult) => {
      const cacheData = cache.readQuery({
        query: JobApplicationDataDocument,
        variables: { jobId },
      });

      if (!cacheData?.latestJobApplication || !mutationResult.data) {
        return;
      }

      cache.writeQuery({
        query: JobApplicationDataDocument,
        data: {
          ...cacheData,
          latestJobApplication: {
            ...cacheData.latestJobApplication,
            data: mutationResult.data.upsertJobApplicationData,
          },
        },
      });
    },
    onError: handleMutationError,
  });

  const job = questionsData?.publicJob;
  const questionData = job?.questionData.data;
  const jobApplication = answersData?.latestJobApplication;

  const handleSubmit = useCallback(
    async (input: JobApplicationDataInput) => {
      jobApplication &&
        (await upsertMutation({
          variables: {
            jobApplicationId: jobApplication.id,
            jobApplicationDataInput: input,
          },
        }));
    },
    [upsertMutation, jobApplication]
  );

  if (!job || !jobApplication || !questionData) {
    return null;
  }

  switch (questionData.__typename) {
    case "GreenhouseQuestionData":
      return (
        <GreenhouseForm
          questionData={questionData}
          answerData={
            jobApplication.data?.data.__typename === "GreenhouseAnswerData"
              ? jobApplication.data.data
              : undefined
          }
          onSubmit={handleSubmit}
        />
      );
    case "WorkableQuestionData":
      return (
        <WorkableForm
          questionData={questionData}
          answerData={
            jobApplication.data?.data.__typename === "WorkableAnswerData"
              ? jobApplication.data.data
              : undefined
          }
          onSubmit={handleSubmit}
        />
      );
    case "LeverQuestionData":
      return (
        <LeverForm
          questionData={questionData}
          answerData={
            jobApplication.data?.data.__typename === "LeverAnswerData"
              ? jobApplication.data.data
              : undefined
          }
          onSubmit={handleSubmit}
        />
      );
    case "OttaQuestionData":
      return (
        <OttaForm
          questionData={questionData}
          answerData={
            jobApplication.data?.data.__typename === "OttaAnswerData"
              ? jobApplication.data.data
              : undefined
          }
          onSubmit={handleSubmit}
        />
      );
  }
}
