import { useMutation } from "@apollo/client";

import { DisplayLinks } from "./components/DisplayLinks";
import { EditingLinks } from "./components/EditingLinks";

import { useQuery } from "@otta/search/apollo";
import { handleMutationError } from "@otta/search/utils/error";
import {
  UserLinksDocument,
  UpdateCurrentUserDocument,
  UserLinksQuery,
  ProfileCompletionStatusDocument,
} from "@otta/search/schema";
import { SingleInputFormCard } from "@otta/search/pages/Profile/components/SingleInputFormCard";

function formatIncomingData({ currentUser }: UserLinksQuery) {
  return {
    linkedinUrl: currentUser?.linkedinUrl,
    websiteLinks: currentUser?.websiteLinks ?? [],
  };
}

function formatOutgoingData({ linkedinUrl, websiteLinks }: ProfileLinks) {
  return {
    linkedinUrl: linkedinUrl?.toLowerCase() ?? null,
    websiteLinks: (websiteLinks ?? []).map(({ url, linkType }) => {
      return { url, linkType };
    }),
  };
}

export interface LinkType {
  url: string;
  linkType: string;
}

interface ProfileLinks {
  linkedinUrl: string | null;
  websiteLinks: LinkType[];
}

export function Links(): React.ReactElement {
  const { data } = useQuery(UserLinksDocument);

  const [mutation] = useMutation(UpdateCurrentUserDocument, {
    onError: handleMutationError,
    refetchQueries: [
      { query: ProfileCompletionStatusDocument },
      { query: UserLinksDocument },
    ],
  });

  const onSubmit = async (values: ProfileLinks) => {
    const input = await formatOutgoingData(values);
    await mutation({ variables: { input } });
  };

  const hasNoOtherLinks = data?.currentUser?.websiteLinks.length === 0;
  const hasNoLinks = data?.currentUser?.linkedinUrl === null && hasNoOtherLinks;

  return (
    <div id="linkedin-url-form-id">
      <SingleInputFormCard
        title="Links"
        addMoreText="link"
        introText="Add links to useful websites, such as your LinkedIn, portfolio or GitHub. Hiring managers use this information to build a picture of you."
        data={data ?? null}
        hasNoData={hasNoLinks}
        formattedData={data ? formatIncomingData(data) : null}
        displayComponent={DisplayLinks}
        editingComponent={EditingLinks}
        onSubmit={onSubmit}
      />
    </div>
  );
}
