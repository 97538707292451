interface IShareUrlProps {
  data: { url: string; title: string; text: string };
  onComplete: (didCopy: boolean) => void;
}

export const shareUrl = ({ data, onComplete }: IShareUrlProps): void => {
  if (navigator && navigator.share) {
    navigator
      .share(data)
      .then(() => onComplete(false))
      .catch(() => undefined);
  } else if (
    navigator &&
    navigator.clipboard &&
    typeof navigator.clipboard.writeText === "function"
  ) {
    navigator.clipboard.writeText(data.url).then(() => {
      /* clipboard successfully set */
      onComplete(true);
    });
  } else {
    const el = document.createElement("input");
    el.setAttribute("width", "0");
    el.setAttribute("opacity", "0");
    el.readOnly = true;
    el.contentEditable = "true";
    document.body.appendChild(el);
    // Set value (string to be copied)
    el.value = data.url;
    if (
      navigator &&
      navigator.userAgent &&
      navigator.userAgent.match(/ipad|ipod|iphone/i)
    ) {
      // this is a hack to get it to work on iOS
      // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
      const range = document.createRange();
      range.selectNodeContents(el);
      const s = window.getSelection();
      if (s) {
        s.removeAllRanges();
        s.addRange(range);
        el.setSelectionRange(0, 999999);
      }
    } else {
      el.select();
    }
    document.execCommand("copy");
    onComplete(true);
    // Remove temporary element
    document.body.removeChild(el);
  }
};
