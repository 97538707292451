import { useEffect } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { CommonLocation, LocationGroup } from "./CommonLocation";

import { Location as LocationType } from "@otta/search/schema";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { Country } from "@otta/search/constants/locations";

const isUk: (c: Country | undefined) => boolean = c => c === Country.UK;
const notUk: (c: Country | undefined) => boolean = c => !isUk(c);

/**
 * The complexity here is that if you're in the UK we show you EU countries
 * but if you're in the EU we split out the UK into a separate group.
 */
const groups: readonly LocationGroup[] = [
  {
    visible: notUk,
    locations: [
      LocationType.RemoteFrance,
      LocationType.RemoteGermany,
      LocationType.RemoteIreland,
      LocationType.RemoteNetherlands,
      LocationType.RemoteSpain,
      LocationType.RemoteEurope,
    ],
  },
  {
    visible: isUk,
    locations: [
      LocationType.RemoteFrance,
      LocationType.RemoteGermany,
      LocationType.RemoteIreland,
      LocationType.RemoteNetherlands,
      LocationType.RemoteSpain,
      LocationType.RemoteEurope,
      LocationType.RemoteUk,
    ],
  },
  {
    locations: [LocationType.RemoteCanada],
  },
  {
    visible: notUk,
    locations: [LocationType.RemoteUk],
  },
  {
    locations: [LocationType.RemoteUs],
  },
];

export function RemoteLocation({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();
  const preferences = preferencesData?.locationPreferences ?? [];

  const isPresent = useIsPresent();
  const isValid = preferences.length > 0;

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(isValid);
    }
  }, [isValid, isPresent, setNextEnabled]);

  const handleChange = (preferences: readonly LocationType[]) => {
    const updated = preferences.map(p => ({ location: p }));
    updatePreferences({ locationPreferences: updated });
  };

  return (
    <CommonLocation
      options={groups}
      value={preferences.map(p => p.location)}
      valueChanged={handleChange}
      loading={!preferencesData}
      showRemote={false}
    />
  );
}
