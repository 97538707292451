import { HTMLMotionProps, motion } from "framer-motion";
import styled from "@xstyled/styled-components";

export const Container = styled(motion.div)`
  max-width: 450px;
  margin: 0 auto;
`;

interface IAnimatedCardProps extends HTMLMotionProps<"div"> {
  children: React.ReactNode;
}

export function SlideUpContainer(
  props: IAnimatedCardProps
): React.ReactElement {
  return (
    <Container
      initial={{ y: 300, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      {...props}
    />
  );
}
