import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { CurrentUserDocument, RoleType } from "@otta/search/schema";
import { Recruiter } from "@otta/search/pages/Recruiter";
import { Redirect } from "@otta/search/router";

interface PreferencesSetRouteProps {
  element: React.ReactElement;
  fallback?: React.ReactElement;
}

export function PreferencesSetRoute({
  element,
  fallback,
}: PreferencesSetRouteProps): React.ReactElement | null {
  const location = useLocation();

  const { data, loading } = useQuery(CurrentUserDocument, { ssr: true });

  if (loading) {
    return <Loading />;
  }

  const user = data?.currentUser;

  if (!user) {
    if (fallback) {
      return fallback;
    }

    return (
      <Redirect
        to={{
          pathname: "/login",
          search: queryString.stringify({
            redirect: location.pathname,
          }),
        }}
      />
    );
  }

  if (user.role === RoleType.CompanyRecruiter) {
    return fallback ?? <Recruiter />;
  }

  if (!user.hasSetPreferences) {
    return <Redirect to="/initial-preferences" />;
  }

  return element;
}
