export function HiddenEye(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg width="21" height="16" viewBox="0 0 21 16" {...props}>
      <path
        d="M20.3126 14.7188L1.62506 0.109688C1.5738 0.0686496 1.51497 0.038109 1.45191 0.0198098C1.38885 0.00151062 1.3228 -0.00418892 1.25754 0.00303664C1.19228 0.0102622 1.12908 0.0302714 1.07155 0.0619217C1.01402 0.093572 0.963287 0.136244 0.922249 0.1875L0.609749 0.577813C0.568614 0.629087 0.537994 0.687969 0.519641 0.75109C0.501287 0.81421 0.49556 0.880331 0.502787 0.945667C0.510015 1.011 0.530054 1.07427 0.56176 1.13186C0.593466 1.18944 0.636215 1.24021 0.687561 1.28125L19.3751 15.8903C19.4263 15.9314 19.4852 15.9619 19.5482 15.9802C19.6113 15.9985 19.6773 16.0042 19.7426 15.997C19.8078 15.9897 19.871 15.9697 19.9286 15.9381C19.9861 15.9064 20.0368 15.8638 20.0779 15.8125L20.3904 15.4222C20.4315 15.3709 20.4621 15.312 20.4805 15.2489C20.4988 15.1858 20.5046 15.1197 20.4973 15.0543C20.4901 14.989 20.4701 14.9257 20.4384 14.8681C20.4067 14.8106 20.3639 14.7598 20.3126 14.7188ZM9.77475 4.57719L13.9869 7.87031C13.9176 5.99719 12.3901 4.5 10.5001 4.5C10.2563 4.50046 10.0132 4.52633 9.77475 4.57719ZM11.2254 11.4231L7.01319 8.13C7.08287 10.0028 8.61037 11.5 10.5001 11.5C10.7438 11.4995 10.9869 11.4737 11.2254 11.4231ZM10.5001 3.5C13.5829 3.5 16.4091 5.21875 17.9354 8C17.5612 8.68449 17.099 9.31697 16.5604 9.88125L17.7397 10.8031C18.3964 10.1007 18.9527 9.31081 19.3929 8.45594C19.4644 8.31453 19.5016 8.1583 19.5016 7.99984C19.5016 7.84139 19.4644 7.68516 19.3929 7.54375C17.6966 4.23719 14.3416 2 10.5001 2C9.35319 2 8.25912 2.21875 7.23037 2.58781L8.68069 3.72188C9.27256 3.5875 9.87881 3.5 10.5001 3.5ZM10.5001 12.5C7.41725 12.5 4.59131 10.7812 3.06475 8C3.43937 7.31552 3.90217 6.68314 4.44131 6.11906L3.26194 5.19719C2.60542 5.89952 2.04921 6.6893 1.60912 7.54406C1.53764 7.68547 1.50039 7.8417 1.50039 8.00016C1.50039 8.15861 1.53764 8.31484 1.60912 8.45625C3.30381 11.7628 6.65881 14 10.5001 14C11.6469 14 12.741 13.7797 13.7697 13.4122L12.3194 12.2784C11.7276 12.4125 11.1216 12.5 10.5001 12.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
