import {
  BulletPointSection,
  Bullet,
} from "../../pages/Jobs/JobCard/components/BulletPointSection";

import { Company } from "@otta/search/schema";

export const shouldDisplayCompanyAdditionalInfo = (
  company: CompanyAdditionalInfoProps["company"]
) => {
  return company.additionalInfoBullets.length > 0;
};

interface CompanyAdditionalInfoProps {
  company: Pick<Company.Fragment, "additionalInfoBullets">;
}

export function CompanyAdditionalInfo({
  company: { additionalInfoBullets },
}: CompanyAdditionalInfoProps): React.ReactElement {
  const bullets = additionalInfoBullets
    .filter(b => b.value)
    .map(b => ({ id: b.id, text: b.value } as Bullet));

  return (
    <BulletPointSection
      title="Additional info"
      bullets={bullets}
      bulletTestId="company-additional-info-bullet"
      headingHtmlTag="h2"
    />
  );
}
