import { useMemo } from "react";

import {
  JobCompanyTeamFragment,
  JobCompanyTeamMemberFragment,
  TeamMemberRole,
} from "@otta/search/schema";
import { Card, Spacing } from "@otta/design";
import { OrgChart } from "@otta/search/components/MeetTheTeam/OrgChart";
import { TeamSize } from "@otta/search/components/MeetTheTeam/TeamSize";
import { AssetSprinkler } from "@otta/search/components/AssetSprinkler";
import { MissionCard } from "@otta/search/components/MeetTheTeam/MissionCard";
import { MeetCards } from "@otta/search/components/MeetCards";

export function MeetTheTeamTab({
  jobTitle,
  members,
  team,
}: {
  jobTitle: string;
  team: JobCompanyTeamFragment;
  members: JobCompanyTeamMemberFragment[];
}) {
  const manager: JobCompanyTeamMemberFragment | undefined = useMemo(
    () => members.find(m => m.role === TeamMemberRole.Manager),
    [members]
  );

  return (
    <>
      <Card>
        <Spacing size={-3}>
          <TeamSize size={team.size} />
          {team.mission && <MissionCard>{team.mission}</MissionCard>}
        </Spacing>
      </Card>
      <Card>
        <AssetSprinkler assets={team.brandAssetLinks} />
      </Card>
      <Card>{manager && <MeetCards member={manager} />}</Card>
      <Card>
        <OrgChart
          teamSize={team.size}
          jobTitle={jobTitle}
          members={members}
        ></OrgChart>
      </Card>
    </>
  );
}
