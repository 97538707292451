import { useEffect, useMemo, useState } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useFirstMountState, useMountedState } from "react-use";
import styled, { useUp } from "@xstyled/styled-components";

import { BottomButtonBar } from "./BottomButtonBarSidebar";
import { Overview } from "./components/Overview";
import { SidebarQuizLayout } from "./components/SidebarQuizLayout";
import { Sidebar } from "./components/SidebarQuizLayout/Sidebar";

import { EventProperties, QuestionComponentProps } from ".";

import { pushAnalyticsEvent } from "@otta/analytics";
import { OttaIconsId } from "@otta/icons";
import { Loading } from "@otta/search/components/Loading";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { Redirect } from "@otta/search/router";

const Page = styled.div`
  display: flex;
  flex: 1;
  background-color: beige-200;
`;

const MainContent = styled.div`
  position: relative;
  max-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export interface Question {
  menuTitle: string;
  skip?: boolean;
  title: string;
  path: string;
  subtitle?: string;
  component: React.ComponentType<QuestionComponentProps>;
}

export interface QuizSection {
  title: string;
  icon: OttaIconsId;
  questions: Question[];
}

interface QuizProps {
  sections: QuizSection[];
  onFinish: () => void;
  finishText: string;
  analyticsName?: string;
  eventProperties?: () => Promise<EventProperties>;
}

export interface SidebarQuizLayoutProps extends QuestionComponentProps {
  question: Question;
  sections: QuizSection[];
  buttons: React.ReactElement;
}

function QuizComponent({
  onFinish,
  sections,
  finishText,
  eventProperties,
  analyticsName,
}: QuizProps): React.ReactElement {
  const location = useLocation();
  const { question } = useParams<"question">();
  const navigate = useNavigate();
  const { dispatchEvent } = useUserPreferences();
  const isTablet = useUp("tablet");

  const questions = useMemo(
    () => sections.flatMap(s => s.questions),
    [sections]
  );

  const startQuestion = useMemo(
    () => questions.findIndex(q => !q.skip),
    [questions]
  );

  const currentQuestion = useMemo(
    () => questions.findIndex(q => q.path === question),
    [questions, question]
  );

  const isLastQuestion = currentQuestion + 1 === questions.length;
  const eventProps = eventProperties ?? (() => Promise.resolve({}));
  const isFirstMount = useFirstMountState();
  const isMounted = useMountedState();

  useEffect(() => {
    if (analyticsName && isFirstMount) {
      const evt = {
        eventName: "Candidate Began Quiz",
        name: analyticsName,
        sidebar: true,
      };
      eventProps().then(props => pushAnalyticsEvent({ ...evt, ...props }));
    }
    return () => {
      if (analyticsName && !isMounted()) {
        const did = isLastQuestion ? "Completed" : "Left";
        const evt = {
          eventName: `Candidate ${did} Quiz`,
          name: analyticsName,
          sidebar: true,
        };
        eventProps().then(props => pushAnalyticsEvent({ ...evt, ...props }));
      }
    };
  });

  const [nextEnabled, setNextEnabled] = useState(false);

  const handleBackToOverview = () => {
    navigate("/preferences", { replace: true });
  };

  const handleButton = (forward: boolean, skip: boolean) => {
    const step = forward ? 1 : -1;

    let newQuestionNumber = currentQuestion + step;

    while (questions[newQuestionNumber]?.skip) {
      newQuestionNumber += step;
    }

    setNextEnabled(false);

    if (newQuestionNumber < startQuestion) {
      return;
    }

    if (newQuestionNumber >= questions.length) {
      return onFinish();
    }

    const to = {
      pathname: `../${questions[newQuestionNumber].path}`,
      search: location.search,
    };

    if (skip) {
      return navigate(to, { replace: true, state: location.state });
    }

    navigate(to, { state: location.state });
  };

  const numQuestions = questions.length;
  const actualQuestionNumber = currentQuestion + 1;

  if (currentQuestion === -1) {
    return <Redirect to=".." />;
  }

  if (actualQuestionNumber > numQuestions) {
    return <Loading />;
  }

  const isReviewAll = location.state === "review-all";
  const linkedQuestions = ["location", "remote-location", "visa-sponsorship"];
  const isEndOfLinkedQuestion =
    question === linkedQuestions[linkedQuestions.length - 1];

  const mobileButtons = (
    <BottomButtonBar
      showNegative={
        (question && !linkedQuestions.includes(question)) ||
        (isReviewAll && currentQuestion > startQuestion)
      }
      showPositive={
        isReviewAll || (question ? linkedQuestions.includes(question) : false)
      }
      positiveText={
        isLastQuestion || (!isReviewAll && isEndOfLinkedQuestion)
          ? finishText
          : "Next"
      }
      nextEnabled={nextEnabled}
      handlePositive={() => {
        // This is false if there is any event listener that calls preventDefault
        if (dispatchEvent("NEXT")) {
          if (!isReviewAll && isEndOfLinkedQuestion) {
            handleBackToOverview();
          } else {
            handleButton(true, false);
          }
        }
      }}
      handleNegative={() => {
        if (isReviewAll) {
          handleButton(false, false);
        } else {
          handleBackToOverview();
        }
      }}
    />
  );

  const desktopButtons = (
    <BottomButtonBar
      showNegative={currentQuestion > startQuestion}
      positiveText={isLastQuestion ? finishText : "Next"}
      nextEnabled={nextEnabled}
      handlePositive={() => {
        if (dispatchEvent("NEXT")) {
          handleButton(true, false);
        }
      }}
      handleNegative={() => handleButton(false, false)}
    />
  );

  const props = {
    buttons: isTablet ? desktopButtons : mobileButtons,
    sections,
    nextEnabled,
    setNextEnabled,
    question: questions[currentQuestion],
    handleNext: () => handleButton(true, false),
    handleSkip: () => handleButton(true, true),
  };

  return <SidebarQuizLayout {...props} />;
}

function RedirectOrOverview({ sections }: Pick<QuizProps, "sections">) {
  const location = useLocation();
  const navigate = useNavigate();

  const questions = sections.flatMap(s => s.questions);

  // this handles the case where the first question in a quiz is optional / skippable
  const startIndex = questions.findIndex(({ skip }) => !skip);

  const isTablet = useUp("tablet");

  useEffect(() => {
    if (isTablet) {
      navigate(
        { pathname: questions[startIndex].path, search: location.search },
        { replace: true }
      );
    }
  }, [isTablet, location.search, navigate, questions, startIndex]);

  return <Overview sections={sections} />;
}

const SidebarLayout = ({ sections }: { sections: QuizSection[] }) => {
  return (
    <Page>
      <Sidebar sections={sections} />
      <MainContent>
        <Outlet />
      </MainContent>
    </Page>
  );
};

export function SidebarQuiz(props: QuizProps): React.ReactElement {
  return (
    <Routes>
      <Route path="/" element={<SidebarLayout sections={props.sections} />}>
        <Route
          path="/"
          element={<RedirectOrOverview sections={props.sections} />}
        />
        <Route path=":question" element={<QuizComponent {...props} />} />
      </Route>
    </Routes>
  );
}
