import { IDashboardBannerNotification } from "..";

import { Text } from "@otta/design";
import { Notification } from "@otta/search/schema";
import { HiddenEye } from "@otta/search/components/Icons/HiddenEye";
import { Link } from "@otta/search/components/Link";

const UnhideProfileContent: React.FC = () => (
  <>
    <HiddenEye />
    <Text>
      Unhide your profile to get headhunted by top companies.{" "}
      <Link
        to="/profile"
        underline={true}
        data-testid="link-unhide-profile-notification"
        data-analytics-id="link-unhide-profile-notification"
      >
        Go to profile
      </Link>
    </Text>
  </>
);

export const UnhideProfileNotification: IDashboardBannerNotification = {
  key: "unhide-profile-notification",
  name: Notification.UnhideProfile,
  Content: UnhideProfileContent,
  level: "information",
};
