import { useEffect } from "react";
import styled from "@xstyled/styled-components";

import { Spacing, Button as DefaultButton, Text } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";

const ModalContent = styled.div`
  padding: xl;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: inherit;
`;

const Button = styled(DefaultButton)`
  width: 100%;
`;

export function JobNoLongerAvailableModalContent({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement {
  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Candidate Shown",
      name: "Job No Longer Available Modal",
    });
  }, []);

  return (
    <ModalContent>
      <Spacing size={2}>
        <Text size={1} bold>
          Job no longer available
        </Text>
        <Text>
          We&apos;re sorry - this job doesn&apos;t seem to be accepting
          applications any more.
        </Text>
        <Text>
          We check jobs every 8 hours - but sometimes companies remove them
          while candidates are still applying.
        </Text>
        <Text>
          Any information you&apos;ve added here will be saved to your profile
          to use on other applications once you leave the page.
        </Text>
        <Button level="primary" onClick={onClose}>
          Dismiss
        </Button>
      </Spacing>
    </ModalContent>
  );
}
