import styled from "@xstyled/styled-components";

import { Textarea } from "@otta/search/components/Input/Textarea";

const StyledTextArea = styled(Textarea)`
  grid-column: row-start / action-start;
  height: initial;
  ::placeholder {
    padding-bottom: 6px;
  }
`;

interface BulletInputProps {
  id?: string;
  value: string;
  rows: number;
  index: number;
  selected: boolean;
  onSetSelection: (i: number) => void;
  onChange: React.ChangeEventHandler;
  placeholder?: string;
  maxLength?: number;
}

export const BulletInput = ({
  id,
  value,
  rows,
  index,
  selected,
  onSetSelection,
  onChange,
  placeholder,
  maxLength,
}: BulletInputProps): React.ReactElement => {
  const moveCaretToEndOfInput: React.FocusEventHandler<
    HTMLTextAreaElement
  > = e => {
    const valueTemp = e.target.value;
    e.target.value = "";
    e.target.value = valueTemp;
  };

  return (
    <StyledTextArea
      id={id}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      rows={rows}
      placeholder={placeholder}
      onFocus={e => {
        if (!selected) {
          onSetSelection(index);
        } else {
          moveCaretToEndOfInput(e);
        }
      }}
      autoFocus={selected}
    />
  );
};
