import { useMutation } from "@apollo/client";

import { DisplayInterests, EditingInterests } from "./Interests";

import { useQuery } from "@otta/search/apollo";
import {
  UserInterestsDocument,
  UpdateCurrentUserDocument,
  UserInterestsQuery,
  ProfileCompletionStatusDocument,
} from "@otta/search/schema";
import { SingleInputFormCard } from "@otta/search/pages/Profile/components/SingleInputFormCard";

function formatIncomingData({ currentUser }: UserInterestsQuery) {
  return {
    profileInterests: currentUser?.profileInterests,
  };
}

function formatOutgoingData({ profileInterests }: ProfileInterests) {
  return {
    profileInterests: profileInterests ?? null,
  };
}
interface ProfileInterests {
  profileInterests: string | null | undefined;
}

export function Interests(): React.ReactElement {
  const { data } = useQuery(UserInterestsDocument);

  const [mutation] = useMutation(UpdateCurrentUserDocument, {
    refetchQueries: [{ query: ProfileCompletionStatusDocument }],
  });

  const onSubmit = async (values: ProfileInterests) => {
    const input = await formatOutgoingData(values);
    await mutation({ variables: { input } });
  };

  return (
    <SingleInputFormCard
      title="Interests"
      addMoreText="interests"
      introText="Add any interests you would like to share and be comfortable talking about."
      data={data ?? null}
      hasNoData={data?.currentUser?.profileInterests === null}
      formattedData={data ? formatIncomingData(data) : null}
      displayComponent={DisplayInterests}
      editingComponent={EditingInterests}
      onSubmit={onSubmit}
    />
  );
}
