import styled from "@xstyled/styled-components";

import { DismissButton } from "../components/DismissButton";

import { Button, Spacing, Text } from "@otta/design";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  padding-right: lg;
`;

const StyledButton = styled(Button)`
  padding: 0.5rem 1.5rem;
  flex: 1;
`;

interface ISimplePollCueProps {
  onClick: (eventProperties: Record<string, string>) => void;
  onDismiss: () => void;
  title: string;
  description?: string;
}

export const SimplePollCue = ({
  onClick,
  onDismiss,
  title,
  description,
}: ISimplePollCueProps): React.ReactElement => (
  <Spacing>
    <Header>
      <Text bold>{title}</Text>
      <DismissButton onClick={onDismiss} />
    </Header>
    {description && <Text>{description}</Text>}
    <Buttons>
      <StyledButton
        data-testid="poll-yes-button"
        level="secondary"
        size="small"
        onClick={() => onClick({ response: "yes" })}
      >
        Yes
      </StyledButton>
      <StyledButton
        data-testid="poll-no-button"
        level="secondary"
        size="small"
        onClick={() => onClick({ response: "no" })}
      >
        No
      </StyledButton>
    </Buttons>
  </Spacing>
);
