import { useRedirectParam } from "./useRedirectParam";

import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { CurrentUserDocument, RoleType } from "@otta/search/schema";
import { Recruiter } from "@otta/search/pages/Recruiter";
import { Redirect } from "@otta/search/router";

interface SignedOutRouteProps {
  children: React.ReactNode;
}

export function SignedOutRoute({
  children,
}: SignedOutRouteProps): React.ReactElement {
  const redirect = useRedirectParam();
  const { data, loading } = useQuery(CurrentUserDocument, { ssr: true });

  if (loading) {
    return <Loading />;
  }

  const user = data?.currentUser;

  if (!user) {
    return <>{children}</>;
  }

  if (user.role === RoleType.CompanyRecruiter) {
    return <Recruiter />;
  }

  return <Redirect to={redirect ?? "/"} />;
}
