import { Location } from "@otta/search/schema";

export const REMOTE_PREFERENCES = [
  Location.RemoteUk,
  Location.RemoteUs,
  Location.RemoteEurope,
  Location.RemoteCanada,
  Location.RemoteFrance,
  Location.RemoteGermany,
  Location.RemoteIreland,
  Location.RemoteNetherlands,
  Location.RemoteSpain,
];

export const NAV_HEIGHT = 58;

export const INBOX_WIDTH_DESKTOP = 400;

export const INBOX_WIDTH = 300;
