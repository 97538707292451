import { AnonymousBullets } from "./AnonymousBullets";
import { SalariesTextField } from "./SalariesTextField";

import { Spacing } from "@otta/design";
import { required } from "@otta/search/utils/validators";
import { QuestionComponentProps } from "@otta/search/containers/Quiz";

export function SalaryInputCompany(
  props: QuestionComponentProps
): React.ReactElement {
  return (
    <Spacing>
      <SalariesTextField
        fieldName="currentCompany"
        label="Company name"
        validate={required}
        {...props}
      />
      <AnonymousBullets />
    </Spacing>
  );
}
