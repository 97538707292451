import purify from "dompurify";
import styled from "@xstyled/styled-components";

import { Tipbox } from "@otta/design";
import { AtsInformation } from "@otta/search/schema";

/**
 * Restore some basic styles for paragraphs / lists in the HTML
 * otherwise it looks weird, particularly for Greenhouse EEOC info
 */
const InformationContent = styled.div`
  p:not(:last-child) {
    margin-bottom: 19;
  }
  li {
    margin-left: 19;
  }
`;

type FieldProps = {
  question: AtsInformation;
};

export function Information({ question }: FieldProps): React.ReactElement {
  return (
    <Tipbox level="information">
      <InformationContent
        dangerouslySetInnerHTML={{ __html: purify.sanitize(question.label) }}
      />
    </Tipbox>
  );
}
