import styled from "@xstyled/styled-components";

const Label = styled.label`
  position: absolute;
  top: -4px;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  text-align: left;
  color: black;
`;

export const Error = styled.span`
  width: 100%;
  font-size: 14px;
  color: red-600;
  text-align: right;
  position: absolute;
  top: -4px;
  left: 0;
`;

export const FieldWrapper = styled.div`
  position: relative;
`;

export const WhiteLabel = styled(Label)`
  color: gray-400;
`;
