import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { ATSQuestionsV1 } from "./types";

import {
  AtsQuestionAnswerInput,
  AtsQuestionAnswerValueInput,
  JobQuestionDataDocument,
  UpsertJobApplicationAtsQuestionAnswersDocument,
} from "@otta/search/schema";

/*
 * form state is a section -> question -> answer map
 * we don't use arrays for answers due to some dodgy final form behaviour
 * also the IDs are prefixed as final-form breaks if they are numeric strings 🙄
 */
type Answers = Record<`a_${string}`, AtsQuestionAnswerValueInput>;
type Questions = Record<`q_${string}`, Answers>;
type Sections = Record<`s_${string}`, Questions>;
export type FormState = Sections;

/**
 * Hook to take care of formatting records from react-final-form
 * and submit them to the backend in a reasonable format
 */
export const useSubmit = (
  questions: ATSQuestionsV1,
  jobId: string
): [(data: FormState) => void, boolean] => {
  const [submit, { loading }] = useMutation(
    UpsertJobApplicationAtsQuestionAnswersDocument,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: JobQuestionDataDocument, variables: { jobId } },
      ],
    }
  );
  return [
    useCallback(
      (data: FormState) => {
        submit({
          variables: {
            jobExternalId: jobId,
            answers: assembleAnswers(data, questions),
            atsQuestionDataExternalId: questions.id,
          },
        });
      },
      [questions, jobId, submit]
    ),
    loading,
  ];
};

/**
 * We use global question IDs in final-form as they're safer than ATS ones
 * which can include special characters like [] that break the form.
 * The backend expects ats (local) IDs however so we have to map them.
 */
function buildIdMap(questions: ATSQuestionsV1): Record<string, string> {
  return Object.fromEntries(
    questions.sections.flatMap<[string, string][]>(s =>
      s.questions.flatMap(question => {
        switch (question.__typename) {
          case "AtsInformation":
            return [];
          default:
            return [[question.id, question.localId]];
        }
      })
    )
  );
}

function assembleAnswers(
  formData: FormState,
  questions: ATSQuestionsV1
): AtsQuestionAnswerInput[] {
  const map = buildIdMap(questions);
  return Object.entries(formData).flatMap(([section, questions]) =>
    Object.entries(questions).flatMap(([question, answers]) => ({
      sectionId: section.replace("s_", ""),
      questionId: map[question.replace("q_", "")],
      value: Object.values(answers),
    }))
  );
}
