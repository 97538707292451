import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";
import { NotificationFrequency } from "@otta/search/schema";
import { Text } from "@otta/design";

const FrequencyButton = styled.button<{
  isFirst?: boolean;
  isLast?: boolean;
}>`
  border: 1px solid ${palette.grayscale.shade200};
  border-bottom-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0px")};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? "10px" : "0px")};
  border-top-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0px")};
  border-top-right-radius: ${({ isLast }) => (isLast ? "10px" : "0px")};
  padding: xspx lgpx;
  height: 44px;
  display: flex;
  justify-content: center;
  width: 50%;
`;

interface FrequencyButtonsProps {
  jobEmailNotificationsFrequency: NotificationFrequency;
  handleChangeFrequency: (value: string) => void;
  isDisabled: boolean;
}

const frequencies = [
  {
    name: "daily",
    label: "Daily",
    value: NotificationFrequency.Daily,
  },
  { name: "weekly", label: "Weekly", value: NotificationFrequency.Weekly },
];

export const FrequencyButtons = ({
  jobEmailNotificationsFrequency,
  handleChangeFrequency,
  isDisabled = false,
}: FrequencyButtonsProps) => {
  const currentFrequency = frequencies.find(
    ({ value }) => value === (jobEmailNotificationsFrequency ?? null)
  );

  return (
    <div
      style={{
        display: "flex",
        marginTop: pxToRem(24),
      }}
    >
      {frequencies.map((f, index) => (
        <FrequencyButton
          disabled={isDisabled}
          onClick={() => handleChangeFrequency(f.value)}
          key={f.name}
          isFirst={index === 0}
          isLast={index === frequencies.length - 1}
          style={{
            backgroundColor:
              f.value === currentFrequency?.value
                ? palette.grayscale.shade50
                : palette.brand.white,
            cursor: f.value === currentFrequency?.value ? "default" : "pointer",
          }}
        >
          <Text
            style={{
              alignSelf: "center",
            }}
            bold={f.value === currentFrequency?.value}
            color={
              isDisabled ? palette.grayscale.shade400 : palette.brand.black
            }
          >
            {f.label}
          </Text>
        </FrequencyButton>
      ))}
    </div>
  );
};
