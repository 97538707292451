import { CandidateInfo } from "./CandidateInfo";
import { Badges } from "./Badges";
import { OttaInsights } from "./OttaInsights";

import { Card, Spacing } from "@otta/design";
import { LatestJobApplication } from "@otta/search/schema";

export const ProfileTop = ({
  candidate,
  job,
}: {
  candidate: LatestJobApplication.Candidate;
  job: LatestJobApplication.Job;
}): React.ReactElement => (
  <Card data-testid="profile-top-card">
    <Spacing size={-1}>
      <CandidateInfo candidate={candidate} />
      <Badges candidate={candidate} job={job} />
      <OttaInsights candidate={candidate} />
    </Spacing>
  </Card>
);
