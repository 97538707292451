import { Location } from "@otta/search/schema";

export const UK_LOCATIONS = new Set([Location.RemoteUk, Location.London]);

export const EU_LOCATIONS = new Set([
  Location.Amsterdam,
  Location.Barcelona,
  Location.Berlin,
  Location.Dublin,
  Location.Paris,
  Location.RemoteFrance,
  Location.RemoteGermany,
  Location.RemoteIreland,
  Location.RemoteNetherlands,
  Location.RemoteSpain,
  Location.RemoteEurope,
]);

export const US_LOCATIONS = new Set([
  Location.AustinUs,
  Location.BostonUs,
  Location.ChicagoUs,
  Location.DenverUs,
  Location.LosAngelesUs,
  Location.MiamiUs,
  Location.NewYorkUs,
  Location.RemoteUs,
  Location.SanFranciscoBayAreaUs,
]);

export const CANADA_LOCATIONS = new Set([
  Location.RemoteCanada,
  Location.Toronto,
  Location.Vancouver,
]);

export enum Country {
  US = "US",
  UK = "UK",
  NL = "NL",
  FR = "FR",
  DE = "DE",
  ES = "ES",
  IE = "IE",
  CA = "CA",
}

export const locationCountries: Partial<Record<Location, Country>> = {
  [Location.Amsterdam]: Country.NL,
  [Location.AustinUs]: Country.US,
  [Location.Barcelona]: Country.ES,
  [Location.Berlin]: Country.DE,
  [Location.BostonUs]: Country.US,
  [Location.ChicagoUs]: Country.US,
  [Location.DenverUs]: Country.US,
  [Location.Dublin]: Country.IE,
  [Location.London]: Country.UK,
  [Location.LosAngelesUs]: Country.US,
  [Location.MiamiUs]: Country.US,
  [Location.NewYorkUs]: Country.US,
  [Location.Paris]: Country.FR,
  [Location.RemoteCanada]: Country.CA,
  [Location.RemoteFrance]: Country.FR,
  [Location.RemoteGermany]: Country.DE,
  [Location.RemoteIreland]: Country.IE,
  [Location.RemoteNetherlands]: Country.NL,
  [Location.RemoteSpain]: Country.ES,
  [Location.RemoteUk]: Country.UK,
  [Location.RemoteUs]: Country.US,
  [Location.SanFranciscoBayAreaUs]: Country.US,
  [Location.Toronto]: Country.CA,
  [Location.Vancouver]: Country.CA,
};

export type VisaLocation = "US" | "UK" | "EU" | "CA";

export const VISA_LOCATIONS: { value: VisaLocation; label: string }[] = [
  { value: "UK", label: "The UK" },
  { value: "US", label: "The US" },
  { value: "EU", label: "The EU" },
  { value: "CA", label: "Canada" },
];
