import { Routes, Route, Outlet, useOutletContext } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";
import { useEffect, useMemo, useState } from "react";

import { Conversation } from "./Conversation/Conversation";
import { NoMessages } from "./NoMessages";
import { MessagePlaceholder } from "./MessagePlaceholder";
import { InboxSidebar, Conversation as ConversationType } from "./InboxSidebar";

import { pxToRem } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import { INBOX_WIDTH_DESKTOP } from "@otta/search/globalConstants";
import { InboxDocument } from "@otta/search/schema";
import { PageWrapper } from "@otta/search/components/PageWrapper";
import {
  ConversationRedirect,
  MessageRequestRedirect,
} from "@otta/search/pages/Messaging/LegacyRouteRedirects";
import { useTabVisible } from "@otta/search/hooks/useTabVisible";

const InboxWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: ${pxToRem(1200)};
  margin: 0 auto;
  position: relative;
  ${up(
    "desktop",
    css`
      padding-top: lg;
    `
  )}
`;

const ContentWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex: 1;
  margin-left: 0;
  ${up(
    "desktop",
    css`
      margin-left: ${INBOX_WIDTH_DESKTOP}px;
    `
  )}
`;

const POLL_INTERVAL = 60000;

function MessagingFallback() {
  const setShowSideMenu = useOutletContext<(s: boolean) => void>();

  useEffect(() => {
    setShowSideMenu(true);
  }, [setShowSideMenu]);

  return <MessagePlaceholder />;
}

function MessagingPage() {
  const [showSideMenu, setShowSideMenu] = useState(true);

  const visible = useTabVisible();

  const { data, loading, stopPolling, startPolling } = useQuery(InboxDocument, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (
        data?.me?.__typename === "CurrentAdmin" ||
        data?.me?.__typename === "CurrentCandidate"
      ) {
        pushAnalyticsEvent({
          eventName: "Candidate Viewed",
          page: "inbox",
          receivedMessages: data?.me?.conversations?.length > 0 ?? false,
        });
      }
    },
  });

  useEffect(() => {
    if (visible) {
      startPolling(POLL_INTERVAL);
    } else {
      stopPolling();
    }
  }, [startPolling, stopPolling, visible]);

  const candidateData =
    data?.me?.__typename === "CurrentAdmin" ||
    data?.me?.__typename === "CurrentCandidate"
      ? data?.me
      : null;

  const conversations: ConversationType[] = useMemo(() => {
    return candidateData?.conversations ?? [];
  }, [candidateData]);

  if (loading) {
    return (
      <PageWrapper>
        <Loading />
      </PageWrapper>
    );
  }

  if (!conversations.length) {
    return (
      <NoMessages
        profileVisible={candidateData?.showProfileToCompanies ?? false}
      />
    );
  }

  return (
    <InboxWrapper>
      <InboxSidebar
        showSideMenu={showSideMenu}
        conversations={conversations}
        candidateId={candidateData?.id ?? ""}
      />
      <ContentWrapper>
        <Outlet context={setShowSideMenu} />
      </ContentWrapper>
    </InboxWrapper>
  );
}

export default function Messaging(): React.ReactElement {
  return (
    <Routes>
      <Route path="/" element={<MessagingPage />}>
        <Route index element={<MessagingFallback />} />
        <Route
          path="conversations/:conversationId"
          element={<ConversationRedirect />}
        />
        <Route
          path="message-requests/:messageRequestId"
          element={<MessageRequestRedirect />}
        />
        <Route path=":conversationId" element={<Conversation />} />
      </Route>
    </Routes>
  );
}
