import styled from "@xstyled/styled-components";

import {
  pxToRem,
  borderRadius,
  modularScale,
  palette,
} from "@otta/design-tokens";

const Label = styled.label<ICheckedState>`
  display: block;
  width: 100%;
  position: relative;
  padding: md 36px;
  border-radius: ${pxToRem(borderRadius / 2)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-size: ${modularScale()};
  line-height: 1.3;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  color: ${({ disabled }) =>
    disabled ? palette.grayscale.shade400 : palette.brand.black};

  background-color: ${({ checked }) =>
    checked ? palette.brand.yellow : palette.brand.white};
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const Checkmark = styled.span<ICheckedState>`
  position: absolute;
  border-style: solid;
  left: ${modularScale()};
  top: 50%;
  width: ${({ checked }) => (checked ? "7px" : modularScale(-1))};
  height: ${modularScale(-1)};
  border-color: ${({ disabled }) =>
    disabled ? palette.grayscale.shade400 : palette.brand.black};
  border-width: ${({ checked }) => (checked ? "0 2px 2px 0" : "1px")};
  transition: default;
  transform: ${({ checked }) =>
    checked ? "rotate(45deg) translate(-75%, -50%)" : "translateY(-50%)"};
  will-change: transform, border-width, width;
`;

interface ICheckboxProps {
  label: string;
  checked: boolean;
  onChange: (e: any) => any;
  disabled?: boolean;
  value?: string;
}

interface ICheckedState {
  checked: boolean;
  disabled?: boolean;
}

export function Checkbox({
  label,
  checked,
  disabled = false,
  ...props
}: ICheckboxProps): React.ReactElement {
  return (
    <Label checked={checked} disabled={disabled} data-testid="checkbox">
      {label}
      <Input type="checkbox" checked={checked} disabled={disabled} {...props} />
      <Checkmark checked={checked} disabled={disabled} />
    </Label>
  );
}
