import {
  DefaultTheme,
  FlattenInterpolation,
  ThemedStyledProps,
} from "styled-components";
import { css, up } from "@xstyled/styled-components";

import { modularScale, pxToRem } from "@otta/design-tokens";

type ResponsiveInput = {
  mobile: number | string;
  desktop: number | string;
};

type ResponsiveSizes = {
  mobile: string;
  desktop: string;
};

type ResponsiveInterpolationFunction<P> = (props: P) => ResponsiveInput;

type ResponsiveInterpolation<P> =
  | ResponsiveInput
  | ResponsiveInterpolationFunction<P>;

type ResponsiveInterpolations<P> = ResponsiveInterpolation<P>[];

const computeSizes = <P>(
  interpolation: ResponsiveInterpolation<P>,
  props: P
): ResponsiveSizes => {
  const input =
    typeof interpolation === "function" ? interpolation(props) : interpolation;

  return {
    mobile:
      typeof input.mobile === "string" ? input.mobile : pxToRem(input.mobile),
    desktop:
      typeof input.desktop === "string"
        ? input.desktop
        : pxToRem(input.desktop),
  };
};

const mapInterpolation =
  <P>(breakpoint: "mobile" | "desktop", props: P) =>
  (i: ResponsiveInterpolation<P>): string => {
    const sizes = computeSizes(i, props);

    return sizes[breakpoint];
  };

const createResponsiveStyles =
  <P extends object>(
    breakpoint: "mobile" | "desktop",
    strings: TemplateStringsArray,
    interpolations: ResponsiveInterpolations<P>
  ) =>
  (props: P) =>
    css(strings, ...interpolations.map(mapInterpolation(breakpoint, props)));

interface ResponsiveFunction {
  <P extends object>(
    strings: TemplateStringsArray,
    ...interpolations: ResponsiveInterpolations<P>
  ): FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>;
  modularScale(responsiveSizes: {
    mobile: number;
    desktop: number;
  }): ResponsiveInput;
}

export const responsive: ResponsiveFunction = (
  strings,
  ...interpolations
) => css`
  ${createResponsiveStyles("mobile", strings, interpolations)}

  ${up(
    "tablet",
    css`
      ${createResponsiveStyles("desktop", strings, interpolations)}
    `
  )}
`;

responsive.modularScale = step => ({
  mobile: modularScale(step.mobile),
  desktop: modularScale(step.desktop),
});
