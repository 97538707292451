import styled from "@xstyled/styled-components";

import { Text, Spacing } from "@otta/design";
import { pxToRem, borderRadius, palette } from "@otta/design-tokens";

const SelectionBox = styled.div<{ selected: boolean }>`
  background-color: ${({ selected }) =>
    selected ? palette.brand.yellow : palette.brand.white};
  cursor: pointer;
  border-radius: ${pxToRem(borderRadius)};
  padding: lg;
  border: 2px solid ${palette.grayscale.shade400};
`;

interface IBulletPointBoxButtonProps {
  title: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  selected?: boolean;
  children: React.ReactNode;
}

export function BulletPointBoxButton({
  title,
  onClick,
  selected = false,
  children,
}: IBulletPointBoxButtonProps): React.ReactElement {
  return (
    <SelectionBox
      onClick={onClick}
      selected={selected}
      data-testid="bullet-point-box-button"
    >
      <Spacing>
        <Text align="left" bold>
          {title}
        </Text>
        {children}
      </Spacing>
    </SelectionBox>
  );
}
