import { JobRequirements } from "../../../Jobs/JobCard/components/JobRequirements";
import { JobInvolves } from "../../../Jobs/JobCard/components/JobInvolves";

import { Card } from "@otta/design";
import { JobFragment } from "@otta/search/schema";

export function RoleTab({
  job,
}: {
  job: Pick<JobFragment, "requirements" | "involvesBullets">;
}) {
  return (
    <>
      <Card>
        <JobRequirements job={job} />
      </Card>
      <Card>
        <JobInvolves job={job} />
      </Card>
    </>
  );
}
