import styled from "@xstyled/styled-components";

import { SpotlightCard } from "./components";

import { palette } from "@otta/design-tokens";
import { OttaIcons } from "@otta/icons";
import { CompanyProfileLinkFragment } from "@otta/search/schema";
import { Asset } from "@otta/search/components/AssetSprinkler/Asset";

const StyledAsset = styled(Asset)`
  border-radius: 4;
`;

export function MeetTheTeam({ asset }: { asset: CompanyProfileLinkFragment }) {
  const spotlightTag = {
    icon: OttaIcons["HandWave"],
    text: "Meet the team",
    color: palette.brand.pink,
  };

  return (
    <SpotlightCard
      name="meet-the-team-video"
      tag={spotlightTag}
      fullWidth
      transparent
      isCentered
    >
      <StyledAsset asset={asset} />
    </SpotlightCard>
  );
}
