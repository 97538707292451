import { Trans } from "react-i18next";

interface SubFunctionInfo {
  values: string[];
  content: React.ReactNode;
}
export const subFunctionInfo: SubFunctionInfo[] = [
  {
    values: ["Brand & Creative Marketing"],
    content: (
      <span>
        Roles that are focused on developing and executing brand strategies,
        leveraging consumer insights to drive growth and increase customer
        awareness.
        <br />
        <br />
        Typical role titles for jobs in this category: Brand Manager, Brand
        Executive, Brand Marketing Manager, Director of Brand Marketing, Head of
        Brand
      </span>
    ),
  },
  {
    values: ["Support Engineer"],
    content: (
      <span>
        Support Engineers are responsible for troubleshooting and dealing with
        technical questions from end users
        <br />
        <br />
        Typically these roles are common at companies selling technologically
        complex products to other companies
      </span>
    ),
  },
  {
    values: ["Executive Assistant"],
    content: (
      <span>
        Roles focused on supporting the executive leadership team/founders of
        businesses
        <br />
        <br />
        Typical role titles for jobs in this category: Executive Assistant,
        Personal Assistant to CEO, Assistant to the Founders
      </span>
    ),
  },
  {
    values: ["Enterprise Sales"],
    content: (
      <span>
        Roles focused on selling products and services to large enterprise
        companies (1,000+ employees)
        <br />
        <br />
        Typical role titles for jobs in this category: Enterprise Sales Manager,
        Enterprise Account Executive
      </span>
    ),
  },
  {
    values: ["Project & Programme Management"],
    content: (
      <span>
        Roles focused on the planning and execution of discrete projects or
        programmes (for example, implementing a launch of a new product or
        market on time and on budget, or running a change management programme)
      </span>
    ),
  },
  {
    values: ["Backend Engineer"],
    content: (
      <span>
        Engineering roles focused on building the inner workings of products.
        Covers all software with no user interface
      </span>
    ),
  },
  {
    values: ["Full-stack Engineer"],
    content: (
      <span>
        Generalist engineering roles where you’re expected to work across the
        code-base. Suits people that like being involved with all the
        engineering challenges that come with building products
      </span>
    ),
  },
  {
    values: ["DevOps & Infrastructure"],
    content: (
      <span>
        Engineering roles focused on ensuring the reliability and scalability of
        systems that support products
        <br />
        <br />
        Typical role titles for jobs in this category: DevOps Engineer, Platform
        Engineer, Site Reliability Engineer
      </span>
    ),
  },
  {
    values: ["Frontend Engineer"],
    content: (
      <span>
        Engineering roles focused on building the part of the product that
        customers interact with (e.g. marketing websites, user flows)
      </span>
    ),
  },
  {
    values: ["Mobile Engineer"],
    content: (
      <span>
        Engineering roles that specialise in developing products to be used on
        mobiles (e.g. apps)
      </span>
    ),
  },
  {
    values: ["Sales & Solutions Engineer"],
    content: (
      <span>
        Sales/solutions engineers help integrate their company’s software into
        their customers. Typically these roles are more common at companies
        selling products to other enterprises
        <br />
        <br />
        Typical role titles for jobs in this category: Solutions Engineer,
        Technical Solutions Engineer, Pre-sales Engineer
      </span>
    ),
  },
  {
    values: ["QA & Testing Engineer"],
    content: (
      <span>
        QA/testing engineers specialise in making sure the code the engineering
        team writes is robust
        <br />
        <br />
        Typical role titles for jobs in this category: QA Engineer, Test
        Engineer, Software Developer in Test
      </span>
    ),
  },
  {
    values: ["Engineering Management"],
    content: (
      <span>
        Team management roles within engineering team. Some roles are 100%
        management, some are 50% management/50% coding. These roles usually
        require at least a few years of experience as an engineer
      </span>
    ),
  },
  {
    values: ["Game Engineer"],
    content: <span>Engineering roles that specialise in producing games</span>,
  },
  {
    values: ["Security Engineer"],
    content: (
      <span>
        Engineering roles that specialise in ensuring the security of the
        platform and the users. These roles are more common at larger scaleups
        or fintechs
      </span>
    ),
  },
  {
    values: ["Product Management"],
    content: (
      <span>
        Product Managers are responsible for the strategy, roadmap, and features
        of a product or product line. These roles involve working with
        engineers, designers and business leads
      </span>
    ),
  },
  {
    values: ["User Research"],
    content: (
      <span>
        User Research roles specialise in understanding customer needs. They
        typically work across the business to ensure insights and customer
        feedback are translated into solutions and products
      </span>
    ),
  },
  {
    values: ["Product Analyst"],
    content: (
      <span>
        Product Analyst roles provide support to Product Managers or engineering
        teams (e.g. product research, data analysis user testing). This role is
        typically a good entry point before becoming a Product Manager
      </span>
    ),
  },
  {
    values: ["Operations Generalist & Strategy"],
    content: (
      <span>
        This category includes generalist business roles. They typically
        contribute to strategy, launch new processes and help build the business
        <br />
        <br />
        Typical role titles for jobs in this category: Operations Manager,
        Strategy Associate, Commercial Analyst, Founder Associate, Chief of
        Staff
      </span>
    ),
  },
  {
    values: ["Sales & Business Development"],
    content: (
      <span>
        Roles focused on winning new business or growing the size of accounts
        with customers
        <br />
        <br />
        Typical role titles for jobs in this category: Business Development
        Manager, Account Executive, Sales Development Representative
      </span>
    ),
  },
  {
    values: [
      "Customer Success & Accounts",
      "Customer Success & Account Management",
    ],
    content: (
      <span>
        Roles focused on making sure customers have a successful experience with
        the company/product once they have signed up
        <br />
        <br />
        Typical role titles for jobs in this category: Account Manager, Customer
        Success Manager, Customer Success Associate
      </span>
    ),
  },
  {
    values: ["Customer Service & Support"],
    content: (
      <span>
        Roles focused on providing customers with day-to-day support and dealing
        with any problems they have with the product/service
      </span>
    ),
  },
  {
    values: ["Partnerships"],
    content: (
      <span>
        Roles focused on helping companies secure and build partnerships with
        other businesses
      </span>
    ),
  },
  {
    values: ["Generalist Marketing"],
    content: (
      <span>
        Incorporates all broad marketing roles that involve different parts of
        specialist marketing. These roles are especially common at early to
        mid-stage businesses that do not have large, <Trans>specialised</Trans>{" "}
        marketing teams.
        <br />
        <br />
        Typical role titles for jobs in this category: Marketing Manager,
        Digital Marketing Manager, Marketing Executive, Campaign Manager, Head
        of Marketing
      </span>
    ),
  },
  {
    values: ["PR & Communications"],
    content: (
      <span>
        Roles focused on managing the spread of information (including news,
        product updates etc.) from companies to external stakeholders (e.g.
        customers and the media)
        <br />
        <br />
        Typical role titles for jobs in this category: PR Manager,
        Communications Executive, Head of PR &amp; Comms
      </span>
    ),
  },
  {
    values: ["Content Marketing", "Content Marketing & Design"],
    content: (
      <span>
        Roles focused on content production, design or strategy. Content
        marketers and designers typically make things easier to understand,
        interpret and use.
        <br />
        <br />
        Typical role titles for jobs in this category: Copywriter, Content
        Writer, Content Designer, Head of Content
      </span>
    ),
  },
  {
    values: ["Performance Marketing"],
    content: (
      <span>
        Performance marketing means companies only pay advertisers for
        successful actions (e.g. clicks). These roles are typically more
        analytical and require skills like SQL, Python, Excel and Google
        Analytics
        <br />
        <br />
        Typical role titles for jobs in this category: Performance Marketing
        Executive, Paid Social Manager, Organic Performance Manager
      </span>
    ),
  },
  {
    values: ["Product Marketing"],
    content: (
      <span>
        Product marketers research, frame the message, and promote a new product
        to ensure it resonates with the target customer. They typically work
        closely with product teams and other marketing functions
      </span>
    ),
  },
  {
    values: ["Social Media & Community"],
    content: (
      <span>
        These are marketing roles that are focused on engaging with the
        community or users, whether it’s through events, social media or
        community forums
        <br />
        <br />
        Typical role titles for jobs in this category: Social Media Manager,
        Community Manager, Events Manager
      </span>
    ),
  },
  {
    values: ["CRM", "CRM & Marketing Operations"],
    content: (
      <span>
        Customer relationship marketing (CRM) focuses on building customer
        loyalty and brand value using marketing strategies and activities. CRM
        allows businesses to develop long-term relationships with customers
      </span>
    ),
  },
  {
    values: ["Product Design (UI/UX)"],
    content: (
      <span>
        Product design covers the process of creating usable web products and
        interfaces, starting by defining people’s problems and thinking about
        possible solutions
        <br />
        <br />
        Typical role titles for jobs in this category: Product Designer, User
        Experience (UX) Designer, Interaction Designer
      </span>
    ),
  },
  {
    values: ["Graphic Design", "Graphic & Motion Design"],
    content: (
      <span>
        Includes design roles that are focused on visual communication through
        typography, photography, and illustration
        <br />
        <br />
        Typical role titles for jobs in this category: Graphic Designer, Brand
        Designer, Junior Creative Artworker, Motion Designer
      </span>
    ),
  },
  {
    values: ["Data Analysis & BI"],
    content: (
      <span>
        These roles typically enable access to information across the business,
        and also analyse data to drive change. These roles will commonly require
        experience with technologies like SQL and Python
      </span>
    ),
  },
  {
    values: ["Data Scientist"],
    content: (
      <span>
        Data scientists collect, analyse, and interpret large volumes of data to
        help improve a company&apos;s product or operations. Typically these
        roles require a grounding in statistics and familiarity with
        technologies like SQL and Python
      </span>
    ),
  },
  {
    values: ["Data Engineer"],
    content: (
      <span>
        Data Engineers build software to prepare data for analytical or
        operational uses (e.g. by building data pipelines)
        <br />
        <br />
        Typical role titles for jobs in this category: Data Engineer, BI Data
        Engineer
      </span>
    ),
  },
  {
    values: ["Research Engineer"],
    content: (
      <span>
        Research engineers focus on enabling, contributing to, and accelerating
        ML/AI research by bringing engineering expertise to projects. These
        roles typically require advanced Masters degrees or PhDs
        <br />
        <br />
        Typical role titles for jobs in this category: AI Scientist, Research
        Engineer, Machine Learning Research Engineer
      </span>
    ),
  },
  {
    values: ["People Operations"],
    content: (
      <span>
        People Operations roles are generalist people-focused roles that help
        look after the employees in a business and provide support across HR and
        recruitment. They can be a good entry-point to more specialised people
        roles
        <br />
        <br />
        Typical role titles for jobs in this category: People Operations
        Representative, Community Assistant, People Coordinator, Office &amp;
        People Manager
      </span>
    ),
  },
  {
    values: ["Recruitment", "Generalist Recruitment"],
    content: (
      <span>
        Includes all generalist recruitment roles that focus on sourcing and
        securing talented employees to join the business
        <br />
        <br />
        Typical role titles for jobs in this category: Recruitment Coordinator,
        Talent Coordinator, Talent Acquisition Officer, Talent Acquisition
        Partner
      </span>
    ),
  },
  {
    values: ["Tech Recruitment", "Technical Recruitment"],
    content: (
      <span>
        Includes recruitment roles that specialise in hiring technical talent
        (including engineering and data roles)
        <br />
        <br />
        Typical role titles for jobs in this category: Technical Recruiter, Tech
        Talent Partner, Technical Sourcer, Data Science Recruiter
      </span>
    ),
  },
  {
    values: ["Human Resources"],
    content: (
      <span>
        Human resources roles deal with the well-being, training and development
        of staff
        <br />
        <br />
        Typical role titles for jobs in this category: People Partner, HR
        Business Partner, People Business Partner, People Advisor, Head of
        People
      </span>
    ),
  },
  {
    values: ["Accounting & Financial Planning"],
    content: (
      <span>
        Includes all internal finance roles that typically require you to have
        (or be working towards) an accounting qualification
        <br />
        <br />
        Typical role titles for jobs in this category: Finance Assistant, Senior
        Accountant, Financial Controller, FP&amp;A Manager, Finance Director
      </span>
    ),
  },
  {
    values: ["Compliance", "Risk & Compliance"],
    content: (
      <span>
        Roles that help ensure a company (usually a financial institution)
        complies with applicable laws, regulations and rules. Plays an essential
        role in helping to preserve the integrity and reputation of the company
        <br />
        <br />
        Typical role titles for jobs in this category: Regulatory Compliance
        Manager, Compliance Analyst, AML Manager
      </span>
    ),
  },
  {
    values: ["Legal"],
    content: (
      <span>
        Includes all legal roles, from legal operations assistant and paralegal
        roles up to General Counsel
        <br />
        <br />
        Typical role titles for jobs in this category: Legal Operations
        Associate, Head of Legal, Legal Assistant, General Counsel
      </span>
    ),
  },
  {
    values: ["Fintech Specialist", "Finance Operations"],
    content: (
      <span>
        Includes more niche roles that are common at fintech{" "}
        <Trans>specialised</Trans>
        companies, e.g. credit risk and payments
        <br />
        <br />
        Typical role titles for jobs in this category: Credit Risk Analyst,
        Payments Operations Analyst, FinCrime Analyst
      </span>
    ),
  },
  {
    values: ["Corporate Finance"],
    content: (
      <span>
        Commercial finance roles that deal with financing, capital structuring,
        and investment decisions. Typically good for people with experience in
        investment banking
        <br />
        <br />
        Typical role titles for jobs in this category: Consultant, Corporate
        Finance Associate, Finance &amp; Strategy Associate, Financial Modelling
        Associate, Corporate Development Lead
      </span>
    ),
  },
  {
    values: ["IT Support"],
    content: (
      <span>
        Roles focused on supporting organisations with their IT needs, for
        example managing the systems/devices in the business and helping ensure
        data protection
        <br />
        <br />
        Typical role titles for jobs in this category: IT Support Engineer, IT
        Manager, IT Operations Engineer, IT Technician
      </span>
    ),
  },
  {
    values: ["Accounts & Payroll"],
    content: (
      <span>
        Entry level or administration-focused finance and payroll roles. Can be
        a good entry point into accounting
        <br />
        <br />
        Typical role titles for jobs in this category: Accounts Payable
        Assistant, Finance Assistant, Expenses Clerk, Accounts Payable
      </span>
    ),
  },
  {
    values: ["Administration"],
    content: (
      <span>
        Includes all roles where most tasks are admin-focused
        <br />
        <br />
        Typical role titles for jobs in this category: Coordinator, Admin,
        Partnerships Administrator, Data Entry
      </span>
    ),
  },
  {
    values: ["Machine Learning Engineer"],
    content: (
      <span>
        Typically specialises in researching, building, and designing
        self-running software to automate predictive models. An ML Engineer
        builds artificial intelligence (AI) systems that leverage huge data sets
        to generate and develop algorithms capable of learning and eventually
        making predictions.
      </span>
    ),
  },
  {
    values: ["Software Architect"],
    content: (
      <span>
        Typically software development experts who make high-level design
        choices and try to enforce technical standards, including software
        coding standards, tools, and platforms.
      </span>
    ),
  },
  {
    values: ["Embedded Engineer"],
    content: (
      <span>
        Typically work on the process of controlling various devices and
        machines that are different from traditional computers, using software
        engineering. These types of roles are typically at companies that
        produce some form of hardware or physical product
      </span>
    ),
  },
  {
    values: ["Hardware Engineer"],
    content: (
      <span>
        Typically design, develop and test computer systems and various physical
        components related to computer systems, for example processors, circuit
        boards, memory devices, networks, and routers. These are the types of
        engineers required by companies building physical electronic products.
      </span>
    ),
  },
  {
    values: ["Mechanical Engineer"],
    content: (
      <span>
        Typically, mechanical engineers play an important role in the
        automotive, aerospace, biotechnology, computer and electronics,
        automation, and manufacturing industries. They design, develop, build,
        and test all sorts of mechanical devices, tools, engines and machines,
        from small parts to large, complex machinery.
      </span>
    ),
  },
  {
    values: ["Technical Writer"],
    content: (
      <span>
        Communication experts who help to transfer complex information between
        two or more parties. Technical writers research and create information
        through a variety of delivery media (electronic, printed, audio-visual
        and even touch). Examples of what they produce include white papers,
        design specifications, online training materials for complex products.
      </span>
    ),
  },
  {
    values: ["Brand Design"],
    content: (
      <span>
        Typically work closely with marketing teams to produce designs across a
        variety of formats, platforms and environments including logos, visual
        identities, apps and websites, out-of-home media, social media,
        magazines, games, physical environments and more.
      </span>
    ),
  },
  {
    values: ["UX Writer"],
    content: (
      <span>
        Typically focus on writing copy for products and web experiences and
        collaborate closely with design teams. They conduct research, understand
        best UX practices, and help to create entire user experiences from
        end-to-end.
      </span>
    ),
  },
  {
    values: ["Industrial Design"],
    content: (
      <span>
        A broad role category, Industrial designers work on the design of
        manufactured, physical products. They typically work closely with
        physical engineers (mechanical, hardware) to develop products.
      </span>
    ),
  },
  {
    values: ["Technical Product Management"],
    content: (
      <span>
        Typically a product manager with a strong technical background (software
        engineering experience) that is usually more focused on technical
        aspects of the product. A technical product manager works more closely
        with the engineering team than the operational and marketing teams of
        the organisation.
      </span>
    ),
  },
  {
    values: ["Delivery Manager & Agile Coach"],
    content: (
      <span>
        Typically a product manager with a strong technical background (software
        engineering experience) that is usually more focused on technical
        aspects of the product. A technical product manager works more closely
        with the engineering team than the operational and marketing teams of
        the organisation.
        <br />
        <br />
        Typical role titles for jobs in this category: Delivery Manager, Agile
        Coach, Scrum Master
      </span>
    ),
  },
  {
    values: ["Growth Marketing"],
    content: (
      <span>
        This category includes roles that focus on expanding a company’s
        customer base and overall revenue by designing and conducting rapid
        experiments at every stage of the marketing funnel. Activities may
        include running paid advertising campaigns, designing and iterating
        landing pages, building out referral schemes and running conversion
        tests.
        <br />
        <br />
        Typical role titles for jobs in this category: Head of Growth, Growth
        Hacker, Growth Marketer, Growth Manager, Growth Executive, Growth
        Marketing Manager
      </span>
    ),
  },
  {
    values: ["Copywriter"],
    content: (
      <span>
        Roles in this category involve researching, planning and producing
        written content to support multiple functions in a business, including
        marketing, product and operations. The day to day work of a copywriter
        varies significantly depending on the industry and product, but may
        include producing blog posts, advertising copy, marketing emails,
        slogans and sales decks.
      </span>
    ),
  },
  {
    values: ["SEO Marketing"],
    content: (
      <span>
        Roles in this category involve using research and analysis to improve a
        business’ ranking on search engines. Activities may include producing
        and optimising copy on landing pages, performing ongoing keyword
        discovery, and running paid search campaigns.
        <br />
        <br />
        Typical role titles for jobs in this category: SEO Manager, Head of SEO,
        SEO Analyst, Paid Search Specialist, Paid Search Manager
      </span>
    ),
  },
  {
    values: ["Business Operations & Strategy"],
    content: (
      <span>
        This category includes roles that contribute to the overall strategic
        direction of the business, as well as those that help build out early
        processes and help with tactical projects to progress the business.
        <br />
        <br />
        Typical role titles for jobs in this category: Business Operations
        Associate, Strategy Manager, Chief of Staff, Founder Associate
      </span>
    ),
  },
  {
    values: ["Operations Generalist"],
    content: (
      <span>
        This category includes cross-functional roles that help support in many
        different areas that touch operations. For example, roles that help the
        day-to-day functioning of the product, e.g. Rider Operations at food
        delivery companies.
        <br />
        <br />
        Typical role titles for jobs in this category: Operations Analyst,
        Operations Manager, Head of Operations
      </span>
    ),
  },
  {
    values: ["Sales Operations"],
    content: (
      <span>
        This category includes roles that support and enable frontline sales
        teams to sell more efficiently and effectively. They provide strategic
        direction and identify ways to reduce friction in the sales process.
        This may involve evaluating sales data to determine the effectiveness of
        a product, sales process, or campaign, maintaining a CRM, helping to set
        individual targets and quotas, or designing onboarding and training
        programs.
        <br />
        <br />
        Typical role titles for jobs in this category: Head of Sales Operations,
        Sales Operations Manager, Sales Operations Specialist, Commercial
        Operations Manager, CRM Operations Leader
      </span>
    ),
  },
  {
    values: ["Sales & Business Development"],
    content: (
      <span>
        Roles focused on winning new business, or growing the size of existing
        accounts. Some roles may focus on a specific stage of the sales process,
        like lead acquisition, whereas others may be end-to-end.
        <br />
        <br />
        Typical role titles for jobs in this category: Sales Development
        Representative, Sales Executive, Business Development Representative,
        Business Development Manager
      </span>
    ),
  },
  {
    values: ["Account Executive"],
    content: (
      <span>
        A quota-led sales role focused on closing new deals. Typically, Account
        Executives receive leads from a Sales Development Representative, before
        completing the sales process. This may include carrying out discovery
        calls, providing demos and closing deals. Sometimes, this role will also
        include maintenance of existing accounts.
      </span>
    ),
  },
  {
    values: ["Sales Leadership & Management"],
    content: (
      <span>
        This category includes roles that involve managing the sales function
        within a business. These types of roles are typically responsible for
        hiring, training and managing sales staff, as well as overall sales
        strategy. In some businesses, roles in this category may focus on a
        specific stage in the sales process, for example Sales Development.
        <br />
        <br />
        Typical role titles for jobs in this category: Head of Sales, Director
        of Sales, Sales Manager, Sales Development Lead, SDR Manager
      </span>
    ),
  },
  {
    values: ["Technical Account Management"],
    content: (
      <span>
        This category includes roles focused on assisting or training clients
        with the technical aspects of a product or service. Roles in this
        category are also typically involved in developing long term client
        relationships, increasing contract sizes and minimising churn.
      </span>
    ),
  },
];
