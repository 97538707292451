import { BaseJobCardNavProps, JobCardNavProps, JobDeckNavProps } from "./types";

export const isJobDeckProps = (
  props: JobCardNavProps
): props is JobDeckNavProps => {
  return "onPrevious" in props;
};

export const isPublicJobNavProps = (
  props: JobCardNavProps
): props is BaseJobCardNavProps => {
  return !("onPrevious" in props);
};
