import styled from "@xstyled/styled-components";
import { useState } from "react";

import { ExperienceItem } from "../../components/ExperienceItem";
import { SmallExperienceItem } from "../../components/SmallExperienceItem";
import {
  ExpansionArrow,
  CollapsedText,
  ExperienceWrapper,
  HeaderWrapper,
  ShowMore,
} from "../../components/Section";

import { Card, Spacing, Text } from "@otta/design";
import { LatestJobApplication } from "@otta/search/schema";

const SmallExperienceWrapper = styled.div`
  margin: 19 0 0;
`;

const ExpandedView = ({
  projects,
}: {
  projects: LatestJobApplication.Projects[];
}) => (
  <ShowMore show={3}>
    {projects.map(project => (
      <ExperienceWrapper key={project.id}>
        <ExperienceItem
          key={project.id}
          orgName={project.title}
          companyUrl={project.projectUrl ?? undefined}
          title={null}
          description={project.description}
          image={project.imagePath ?? undefined}
          showDuration
          showMonth
        />
      </ExperienceWrapper>
    ))}
  </ShowMore>
);

const CollapsedView = ({
  projects,
}: {
  projects: LatestJobApplication.Projects[];
}) => (
  <ShowMore show={3}>
    {projects.map(project => (
      <SmallExperienceWrapper key={project.id}>
        <Spacing size={-1}>
          <SmallExperienceItem
            orgName={project.title}
            title={null}
            companyUrl={project.projectUrl}
          />
          <CollapsedText size={-1} align="left">
            {project.description}
          </CollapsedText>
        </Spacing>
      </SmallExperienceWrapper>
    ))}
  </ShowMore>
);

export function Snippets({
  candidate,
  expand: defaultExpand,
}: {
  candidate: LatestJobApplication.Candidate;
  expand: boolean;
}): React.ReactElement | null {
  const [expand, setExpand] = useState(defaultExpand);

  if (!candidate.projects || candidate.projects.length <= 0) {
    return null;
  }

  return (
    <Card data-testid="snippets-card">
      <Spacing>
        <HeaderWrapper>
          <Text size={1} align="left" bold>
            Snippets
          </Text>
          <ExpansionArrow expand={expand} setExpand={setExpand} />
        </HeaderWrapper>
        {expand ? (
          <ExpandedView projects={candidate.projects} />
        ) : (
          <CollapsedView projects={candidate.projects} />
        )}
      </Spacing>
    </Card>
  );
}
