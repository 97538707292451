import styled from "@xstyled/styled-components";
import { useEffect, useCallback } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";
import { TickMark } from "../icons/TickMark";
import { Heart } from "../icons/Heart";

import { palette, modularScale } from "@otta/design-tokens";
import { Text, Spacing, SelectField } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { TagsContainer, Tag } from "@otta/search/components/Tag";
import {
  PopularTechnologiesUsedDocument,
  TechnologiesUsedDocument,
  TechnologyPreference,
} from "@otta/search/schema";
import { mapRowToOptions, IOption } from "@otta/search/utils/select";
import { removeDuplicates } from "@otta/search/utils/collections";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

export const StyledText = styled(Text)<{ selected: boolean }>`
  font-size: ${modularScale()};
  transition: default;
  transform: ${({ selected }) =>
    selected ? `translateX(0%)` : `translateX(-${modularScale(-2)})`};
`;

export const IconsWrapper = styled.div`
  position: relative;
  display: flex;
  top: 1px;
  align-items: center;
  justify-content: center;
  width: ${modularScale(2)};
  height: ${modularScale(1)};
`;

export const IconWrapper = styled.div<{
  enter: boolean;
  exit: boolean;
  isHeart?: boolean;
}>`
  position: absolute;
  opacity: 0;
  transform: rotate(${({ isHeart }) => isHeart && "-"}45deg);
  transition: default;
  ${({ enter, exit }) =>
    enter
      ? `
        opacity: 1;
        transform: rotate(0deg);
      `
      : exit &&
        `
        opacity: 0;
        transform: translateY(-50%);
      `}
`;

const StyledSubText = styled(Text)`
  display: block;
  font-size: ${modularScale(-1)};
`;

const preferenceToColor = (preference: TechnologyPreference | undefined) => {
  switch (preference) {
    case TechnologyPreference.VeryPositive:
      return palette.brand.yellow;
    case TechnologyPreference.Positive:
      return palette.brand.yellow;
    default:
      return palette.brand.white;
  }
};

export function PositiveTechnologies({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const isPresent = useIsPresent();

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(true);
    }
  }, [setNextEnabled, isPresent]);

  const { preferencesData, updatePreferences } = useUserPreferences();

  const subFunctions = (preferencesData?.jobSubFunctionPreferences ?? []).map(
    pref => pref.subFunctionId
  );

  const { data: popularTechnologiesData } = useQuery(
    PopularTechnologiesUsedDocument,
    {
      variables: { subFunctions },
      skip: subFunctions.length === 0,
    }
  );

  const { data: allTechnologiesData } = useQuery(TechnologiesUsedDocument);

  const handleMutate = useCallback(
    (map: Map<string, TechnologyPreference>) => {
      const input = Array.from(map.entries()).map(
        ([technologyUsedId, technologyPreference]) => ({
          technologyUsedId,
          technologyPreference,
        })
      );

      updatePreferences({
        technologiesUsedPreferences: input,
      });
    },
    [updatePreferences]
  );

  if (!popularTechnologiesData || !preferencesData || !allTechnologiesData) {
    return <Loading />;
  }

  const preferences = preferencesData.technologiesUsedPreferences ?? [];

  const preferencesMap = preferences.reduce<Map<string, TechnologyPreference>>(
    (map, pref) =>
      map.set(
        pref.technologyUsedId,
        pref.technologyPreference as TechnologyPreference
      ),
    new Map()
  );

  const nicheTechnologiesSelected = allTechnologiesData.technologiesUsed.filter(
    tech =>
      [
        TechnologyPreference.Positive,
        TechnologyPreference.VeryPositive,
      ].includes(preferencesMap.get(tech.id) as TechnologyPreference)
  );

  const technologies = removeDuplicates<{ id: string; value: string }>(
    [
      ...popularTechnologiesData.popularTechnologiesUsed,
      ...nicheTechnologiesSelected,
    ],
    ({ id }) => id
  );

  const technologiesSet = new Set(technologies.map(t => t.id));

  const allTechnologiesExceptPersonalised =
    allTechnologiesData.technologiesUsed.filter(
      t => !technologiesSet.has(t.id)
    );

  const handleClick = (technologyUsedId: string) => {
    switch (preferencesMap.get(technologyUsedId)) {
      case TechnologyPreference.VeryPositive:
        preferencesMap.delete(technologyUsedId);
        break;
      case TechnologyPreference.Positive:
        preferencesMap.set(technologyUsedId, TechnologyPreference.VeryPositive);
        break;
      default:
        preferencesMap.set(technologyUsedId, TechnologyPreference.Positive);
        break;
    }
    handleMutate(preferencesMap);
  };

  const handleSelectChange = (option: IOption | null) => {
    if (option) {
      preferencesMap.set(option.value, TechnologyPreference.Positive);
    }
    handleMutate(preferencesMap);
  };

  return (
    <Spacing>
      <SelectField<string>
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        openMenuOnClick={false}
        options={mapRowToOptions(allTechnologiesExceptPersonalised)}
        onChange={handleSelectChange}
        placeholder="Type technology here..."
      />
      <StyledSubText>
        Tap once if you like them. Tap twice if they&apos;re a must-have
      </StyledSubText>
      <TagsContainer>
        {technologies.map(({ id, value }) => {
          return (
            <Tag
              key={id}
              backgroundColor={preferenceToColor(preferencesMap.get(id))}
              data-testid="positive-technology-tag"
              onClick={() => handleClick(id)}
            >
              <IconsWrapper>
                <IconWrapper
                  enter={
                    preferencesMap.get(id) === TechnologyPreference.Positive
                  }
                  exit={
                    preferencesMap.get(id) === TechnologyPreference.Positive
                  }
                >
                  <TickMark />
                </IconWrapper>
                <IconWrapper
                  enter={
                    preferencesMap.get(id) === TechnologyPreference.VeryPositive
                  }
                  exit={!preferencesMap.get(id)}
                  isHeart
                >
                  <Heart />
                </IconWrapper>
              </IconsWrapper>
              <StyledText
                selected={[
                  TechnologyPreference.Positive,
                  TechnologyPreference.VeryPositive,
                ].includes(preferencesMap.get(id) as TechnologyPreference)}
              >
                {value}
              </StyledText>
            </Tag>
          );
        })}
      </TagsContainer>
    </Spacing>
  );
}
