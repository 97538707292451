import { useState } from "react";

import { Spacing, Text, Modal } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { ApplyModalJobDocument, ThemeId } from "@otta/search/schema";
import { ApplyContent } from "@otta/search/components/ApplyContent";
import { DidYouApplyContent } from "@otta/search/components/DidYouApplyContent";

interface IApplyModalProps {
  token?: string;
  jobExternalId: string;
  clickedApply?: boolean;
  onExternalApply?: () => void;
  onClose: () => void;
  type: "DASHBOARD" | "JOB_DECK";
  resetApplied?: () => void;
  themeId?: ThemeId;
}

export function ApplyModal({
  token,
  jobExternalId,
  onClose,
  type,
  themeId,
}: IApplyModalProps): React.ReactElement | null {
  const [clickedExternalApply, setClickedExternalApply] = useState(false);

  const { data: jobData } = useQuery(ApplyModalJobDocument, {
    variables: { externalId: jobExternalId },
    context: { emailToken: token },
  });

  const job = jobData?.publicJob;

  if (!job) {
    return null;
  }

  const headerContent = (
    <Spacing size={-4}>
      <Text align="left" as="h3" bold>
        {job.title}
      </Text>
      <Text align="left" as="h4">
        {job.company.name}
      </Text>
    </Spacing>
  );

  return (
    <Modal
      open
      onOpenChange={open => {
        if (!open) {
          onClose();
        }
      }}
      headerContent={headerContent}
    >
      {clickedExternalApply ? (
        <DidYouApplyContent
          token={token}
          job={job}
          type={type}
          onClose={onClose}
        />
      ) : (
        <ApplyContent
          token={token}
          job={job}
          type={type}
          onExternalApply={() => setClickedExternalApply(true)}
          onClose={onClose}
          themeId={themeId}
        />
      )}
    </Modal>
  );
}
