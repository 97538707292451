import { Link as RouterLink, To } from "react-router-dom";
import styled, { css } from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

interface StyledLinkProps {
  $inverted?: boolean;
  $underline?: boolean;
}

const styles = css<StyledLinkProps>`
  color: ${({ $inverted }) =>
    $inverted ? palette.brand.white : palette.brand.black};
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: ${({ $underline }) => ($underline ? "underline" : "none")};
  }
`;

const StyledLink = styled.a<StyledLinkProps>`
  ${styles}
`;

const StyledRouterLink = styled(RouterLink)<StyledLinkProps>`
  ${styles}
`;

interface ILinkProps {
  to: To;
  state?: unknown;
  children: React.ReactNode;
  className?: string;
  style?: Record<string, string>;
  inverted?: boolean;
  underline?: boolean;
  targetBlank?: boolean;
  onClick?: React.MouseEventHandler;
  target?: string;
  replace?: boolean;
}

export function Link({
  to,
  children,
  inverted,
  underline = true,
  targetBlank = true,
  ...props
}: ILinkProps): React.ReactElement {
  if (
    typeof to === "string" &&
    (to.startsWith("http") ||
      to.startsWith("mailto:") ||
      to.startsWith("javascript:") ||
      to.startsWith("tel:"))
  ) {
    return (
      <StyledLink
        href={to}
        target={targetBlank ? "_blank" : undefined}
        $inverted={inverted}
        $underline={underline}
        {...props}
      >
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledRouterLink
      to={to}
      $inverted={inverted}
      $underline={underline}
      {...props}
    >
      {children}
    </StyledRouterLink>
  );
}
