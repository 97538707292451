import { Field } from "react-final-form";

import { InputField } from "@otta/design";

const isNumber = (value: number): string | undefined => {
  return isNaN(value) ? "Input a number" : undefined;
};

export function EditDecimalField({
  name,
}: {
  name: string;
}): React.ReactElement {
  return (
    <Field
      name={name}
      validate={isNumber}
      render={({ input, meta }) => {
        return (
          <InputField
            {...input}
            type="number"
            placeholder="Enter a number"
            error={meta.touched && meta.error}
            onWheel={e => e.currentTarget.blur()}
          />
        );
      }}
    />
  );
}
