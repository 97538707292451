import { useEffect } from "react";
import styled from "styled-components";

import { Transcript } from "./Transcript";
import { Audio } from "./Audio";
import { CompanyPodcastData } from "./types";
import { Quote } from "./Quote";

import { pxToRem } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";

const CompanyPodcastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
`;

const getEventProperties = (audio: HTMLAudioElement, jobId: string) => ({
  jobId: jobId,
  currentTime: audio.currentTime,
  duration: audio.duration,
  currentPercent:
    audio.duration && Math.round((audio.currentTime * 100) / audio.duration),
});

export function CompanyPodcast({
  companyPodcastData,
}: {
  companyPodcastData: CompanyPodcastData;
}) {
  useEffect(() => {
    const audioComponent = document.getElementById(
      "podcast-audio-container"
    ) as HTMLAudioElement | null;

    if (audioComponent) {
      audioComponent.addEventListener("play", e => {
        pushAnalyticsEvent({
          eventName: "Candidate Played Audio",
          ...getEventProperties(
            e.target as HTMLAudioElement,
            companyPodcastData.jobId
          ),
        });
      });

      audioComponent.addEventListener("pause", e => {
        pushAnalyticsEvent({
          eventName: "Candidate Paused Audio",
          ...getEventProperties(
            e.target as HTMLAudioElement,
            companyPodcastData.jobId
          ),
        });
      });

      audioComponent.addEventListener("ended", e => {
        pushAnalyticsEvent({
          eventName: "Candidate Ended Audio",
          ...getEventProperties(
            e.target as HTMLAudioElement,
            companyPodcastData.jobId
          ),
        });
      });
    }
  }, [companyPodcastData.jobId]);

  return (
    <CompanyPodcastContainer>
      <Quote quote={companyPodcastData.quote} user={companyPodcastData.user} />
      <Audio audio={companyPodcastData.audio} />
      <Transcript transcript={companyPodcastData.transcript} />
    </CompanyPodcastContainer>
  );
}
