import styled, { css, down, up } from "@xstyled/styled-components";

import { Card } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";

const SectionContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;

  ${down(
    "tablet",
    css`
      border-radius: 0;
    `
  )}

  ${up(
    "tablet",
    css`
      border-radius: 4;
    `
  )}
`;

const SectionTitle = styled.h2<{ backgroundColor?: string }>`
  align-items: center;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : palette.beige.shade400};

  ${down(
    "tablet",
    css`
      border-radius: 0;
    `
  )}

  ${up(
    "tablet",
    css`
      border-radius: ${pxToRem(4)} ${pxToRem(4)} 0 0;
    `
  )}

  display: flex;
  font-size: ${pxToRem(23)};
  font-weight: 600;
  justify-content: space-between;
  padding: sm lg;
`;

const SectionBody = styled.div`
  padding: lg;
`;

interface JobCardSectionProps {
  title?: string;
  children: React.ReactNode;
  titleColor?: string;
  tooltipDescription?: string;
  TopRightComponent?: React.ReactNode;
  id?: string;
}

export function JobCardSection({
  title,
  children,
  titleColor,
  tooltipDescription,
  TopRightComponent,
  id,
}: JobCardSectionProps): React.ReactElement {
  return (
    <SectionContainer id={id}>
      {title && (
        <SectionTitle backgroundColor={titleColor ? titleColor : undefined}>
          {tooltipDescription ? (
            <TooltipWithIcon content={tooltipDescription}>
              {title}
            </TooltipWithIcon>
          ) : (
            title
          )}
          {TopRightComponent && TopRightComponent}
        </SectionTitle>
      )}
      <SectionBody>{children}</SectionBody>
    </SectionContainer>
  );
}
