import styled from "@xstyled/styled-components";

import { Clickable, Heading, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { NotificationFrequency, UserSubscription } from "@otta/search/schema";

const SettingDisplayContainer = styled.div<{ displayUnderline?: boolean }>`
  border-bottom: ${({ displayUnderline = true }) =>
    displayUnderline ? `1px solid ${palette.grayscale.shade200}` : "none"};
  text-align: left;
`;

const ContentContainer = styled.div`
  margin-top: sm;
  margin-bottom: lg;
`;

export const getNotificationText = (
  channels: { name: string; value: boolean }[]
) => {
  return channels.reduce((acc, channel) => {
    if (!channel.value) {
      return acc;
    } else {
      if (acc === "Off") {
        return channel.name.charAt(0).toUpperCase() + channel.name.slice(1);
      } else {
        return `${acc} and ${
          channel.name.charAt(0).toUpperCase() + channel.name.slice(1)
        }`;
      }
    }
  }, "Off");
};

export const DisplaySetting = ({
  group,
  onEdit,
  displayUnderline,
  jobEmailNotificationsFrequency,
  isDisabled,
}: {
  group: UserSubscription;
  onEdit: () => void;
  displayUnderline: boolean;
  jobEmailNotificationsFrequency: NotificationFrequency;
  isDisabled: boolean;
}) => {
  return (
    <SettingDisplayContainer displayUnderline={displayUnderline}>
      <Heading
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: pxToRem(16),
          color: isDisabled ? palette.grayscale.shade400 : palette.brand.black,
        }}
      >
        <Text bold>{group.name}</Text>
        <Clickable>
          <Text
            onClick={isDisabled ? undefined : onEdit}
            data-testid={`${group.name}-edit-button`}
            style={{
              textDecoration: "underline",
              cursor: isDisabled ? "not-allowed" : "pointer",
              userSelect: "none",
              color: isDisabled
                ? palette.grayscale.shade400
                : palette.brand.black,
            }}
          >
            Edit
          </Text>
        </Clickable>
      </Heading>

      {group.channels && group.channels?.length > 0 && (
        <ContentContainer>
          <Text
            style={{
              color: isDisabled
                ? palette.grayscale.shade400
                : palette.brand.black,
            }}
          >
            {getNotificationText(group.channels)}
            {group.name === "Job matches" &&
              jobEmailNotificationsFrequency !==
                NotificationFrequency.Never && (
                <>
                  {" "}
                  {` (${jobEmailNotificationsFrequency.toLocaleLowerCase()})`}
                </>
              )}
          </Text>
        </ContentContainer>
      )}
    </SettingDisplayContainer>
  );
};
