import styled from "@xstyled/styled-components";

import { AccordionSection } from "./AccordionSection";

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ISection {
  body: React.ReactNode;
  title: string;
  isOpenAtStart?: boolean;
}
interface IAccordionProps {
  sections: ISection[];
  light?: boolean;
  nested?: boolean;
  noPadding?: boolean;
}

export function Accordion({
  sections,
  light = false,
  nested = false,
  noPadding = false,
}: IAccordionProps): React.ReactElement {
  return (
    <AccordionContainer>
      {sections.map((section, index) => (
        <AccordionSection
          {...section}
          key={index}
          index={index}
          totalSections={sections.length}
          light={light}
          nested={nested}
          noPadding={noPadding}
        >
          {section.body}
        </AccordionSection>
      ))}
    </AccordionContainer>
  );
}
