import { useTranslation } from "react-i18next";
import styled from "@xstyled/styled-components";
import { SVGProps } from "react";

import { Card, Clickable, Spacing, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";

const Buttons = styled.div`
  display: flex;
  background-color: gray-400;
  border: 1px solid ${palette.grayscale.shade400};
  border-radius: 0.75rem;
  gap: 1px;
  overflow: hidden;
`;

const Button = styled(Clickable)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${palette.brand.white};
  font-weight: 600;
  padding: 0.5rem;

  &:hover {
    background: ${palette.brand.grey};
  }
`;

const ThumbsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.75 9.464h-2.5c-.69 0-1.25.56-1.25 1.215v8.715c0 .69.56 1.215 1.25 1.215h2.5c.69 0 1.25-.56 1.25-1.215v-8.68c0-.656-.559-1.25-1.25-1.25ZM22 10.866a2.618 2.618 0 0 0-2.616-2.614h-3.9c.371-.975.579-1.82.579-2.315 0-1.32-1.024-2.687-2.737-2.687-1.82 0-2.319 1.267-2.72 2.285C9.37 8.68 8.25 8.13 8.25 9.186a.938.938 0 0 0 1.523.732c2.998-2.397 2.264-4.793 3.553-4.793a.82.82 0 0 1 .861.812c0 .29-.296 1.545-.997 2.797-.08.142-.12.3-.12.457 0 .544.446.903.937.903h5.375c.411.034.743.366.743.772a.739.739 0 0 1-.69.731.937.937 0 0 0-.874.935c0 .605.445.623.445 1.13 0 .99-1.368.482-1.368 1.646 0 .438.25.51.25.87 0 .884-1.163.537-1.163 1.587 0 .176.043.233.043.37 0 .407-.333.74-.741.74h-2.052c-1 0-1.993-.331-2.793-.93L9.75 16.87a.94.94 0 0 0-1.5.751c0 .285.13.563.375.747l1.432 1.075a6.576 6.576 0 0 0 3.92 1.306h2.052a2.619 2.619 0 0 0 2.609-2.43 2.596 2.596 0 0 0 1.101-2.498 2.613 2.613 0 0 0 1.07-2.77C21.527 12.55 22 11.773 22 10.866Z"
    />
  </svg>
);

export function ImportedRating({ onClick }: { onClick(): void }) {
  const { t } = useTranslation();

  return (
    <Card>
      <Spacing>
        <Text bold>{t("How does your uploaded CV look?")}</Text>
        <Text>
          {t("Our CV uploader")} is an experimental feature and not always
          perfect. Let us know how it looks.
        </Text>
        <Buttons>
          <Button
            aria-label="Thumbs up"
            onClick={() => {
              pushAnalyticsEvent({
                eventName: "Candidate rated imported CV",
                direction: true,
              });
              onClick();
            }}
          >
            <ThumbsUp />
          </Button>
          <Button
            aria-label="Thumbs down"
            onClick={() => {
              pushAnalyticsEvent({
                eventName: "Candidate rated imported CV",
                direction: false,
              });
              onClick();
            }}
          >
            <ThumbsUp style={{ transform: "rotate(180deg) scaleX(-1)" }} />
          </Button>
        </Buttons>
      </Spacing>
    </Card>
  );
}
