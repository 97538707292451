import { useEffect } from "react";
import { useMutation } from "@apollo/client";

import { QuestionWrapper } from "..";
import { QuestionComponentProps } from "../..";

import { useQuery } from "@otta/search/apollo";
import { MultiOptionField } from "@otta/search/containers/Quiz/components/MultiOptionField";
import {
  SalaryPreferenceDocument,
  UpdateSalaryPreferenceDocument,
  SalariesFragment,
  UpdateSalaryPreferenceMutationVariables,
} from "@otta/search/schema";

interface ISalariesMultiOptionFieldProps<F> extends QuestionComponentProps {
  fieldName: F;
  options: Array<{ id: string; value: string }>;
  skip?: boolean;
  mutationFieldName?: string;
  otherOption?: boolean;
  selectedOtherOption?: boolean;
}

interface ISalariesMultiOptionFieldComponentProps<
  F extends
    | "genders"
    | "currentCompany"
    | "currentJobTitle"
    | "currentExperienceLevel"
    | "currentFunction"
    | "currentSubFunction"
    | "location"
> extends ISalariesMultiOptionFieldProps<F> {
  selectedOption: NonNullable<SalariesFragment>[F] | null;
  selectedOtherOption: boolean;
  enabled: boolean;
  salaryPreferenceData: SalariesFragment | null;
  refetchQueries: boolean;
  skip: boolean;
}

export function SalariesMultiOptionFieldComponent<
  F extends
    | "genders"
    | "currentExperienceLevel"
    | "currentFunction"
    | "currentSubFunction"
    | "location"
>({
  skip,
  handleSkip,
  enabled,
  selectedOtherOption,
  setNextEnabled,
  selectedOption,
  handleNext,
  salaryPreferenceData,
  fieldName,
  otherOption,
  options,
  mutationFieldName,
  refetchQueries,
}: ISalariesMultiOptionFieldComponentProps<F>): React.ReactElement | null {
  useEffect(() => {
    if (skip && handleSkip) {
      handleSkip();
    }
  }, [handleSkip, skip]);

  useEffect(() => {
    setNextEnabled(enabled || selectedOtherOption);
  }, [enabled, selectedOtherOption, setNextEnabled]);

  const handleOptimisticResponse =
    (fieldName: string, result: string | { id: string; value: string }) =>
    (): {
      updateSalaryPreference: UpdateSalaryPreferenceMutationVariables["input"];
    } => {
      return {
        updateSalaryPreference: {
          ...salaryPreferenceData,
          [fieldName]: result,
        },
      };
    };

  const refetch = () =>
    refetchQueries ? [{ query: SalaryPreferenceDocument }] : [];

  const [otherOptionMutation] = useMutation(UpdateSalaryPreferenceDocument, {
    refetchQueries: refetch,
    awaitRefetchQueries: refetchQueries,
  });

  if (skip) {
    return null;
  }

  return (
    <QuestionWrapper>
      <MultiOptionField
        options={options}
        mutation={UpdateSalaryPreferenceDocument}
        generateMutationVariables={result => ({
          input: {
            [mutationFieldName ?? fieldName]: result,
          },
        })}
        selectedOption={selectedOption}
        handleNext={handleNext}
        fieldName={mutationFieldName ?? fieldName}
        optimisticResponse={handleOptimisticResponse}
        otherOption={otherOption}
        otherOptionMutation={otherOptionMutation}
        otherOptionMutationVariables={{
          input: {
            [mutationFieldName ?? fieldName]: null,
          },
        }}
        selectedOtherOption={selectedOtherOption}
        customOptions={mutationFieldName === undefined}
        refetchQueries={refetch}
        awaitRefetchQueries={refetchQueries}
      />
    </QuestionWrapper>
  );
}

export function SalariesMultiOptionField<
  F extends
    | "genders"
    | "currentExperienceLevel"
    | "currentFunction"
    | "currentSubFunction"
    | "location"
>({
  fieldName,
  options,
  mutationFieldName,
  otherOption = false,
  selectedOtherOption = false,
  skip = false,
  ...props
}: ISalariesMultiOptionFieldProps<F>): React.ReactElement {
  const { data } = useQuery(SalaryPreferenceDocument);

  const salaryPreferenceData = data?.salaryPreference ?? null;
  const selectedOption = salaryPreferenceData?.[fieldName] ?? null;
  const hasSelected = selectedOption !== null;

  return (
    <SalariesMultiOptionFieldComponent
      options={options}
      selectedOption={selectedOption}
      enabled={hasSelected}
      salaryPreferenceData={salaryPreferenceData}
      selectedOtherOption={selectedOtherOption}
      skip={skip}
      fieldName={fieldName}
      otherOption={otherOption}
      mutationFieldName={mutationFieldName}
      refetchQueries={!data?.salaryPreference?.id}
      {...props}
    />
  );
}
