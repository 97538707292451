import { MotionConfig } from "framer-motion";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { ThemeProvider } from "@xstyled/styled-components";

import { MaintenanceProvider } from "./maintenance";
import { IAPRefresher } from "./iap";

import { theme } from "@otta/design";

export function Providers({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <RadixTooltip.Provider delayDuration={0}>
        <MaintenanceProvider>
          <IAPRefresher>
            <MotionConfig reducedMotion="user">{children}</MotionConfig>
          </IAPRefresher>
        </MaintenanceProvider>
      </RadixTooltip.Provider>
    </ThemeProvider>
  );
}
