export function TickMark(): React.ReactElement {
  return (
    <svg
      className="tick"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin"
      height="12px"
      viewBox="0 0 10 11"
      fill="none"
    >
      <path
        d="M1.83205 6.4453C1.5257 5.98577 0.904828 5.8616 0.4453 6.16795C-0.0142289 6.4743 -0.138403 7.09517 0.16795 7.5547L1.83205 6.4453ZM3 10L2.16795 10.5547C2.35342 10.8329 2.66565 11 3 11C3.33435 11 3.64658 10.8329 3.83205 10.5547L3 10ZM9.83205 1.5547C10.1384 1.09517 10.0142 0.474302 9.5547 0.16795C9.09517 -0.138403 8.4743 -0.0142289 8.16795 0.4453L9.83205 1.5547ZM0.16795 7.5547L2.16795 10.5547L3.83205 9.4453L1.83205 6.4453L0.16795 7.5547ZM3.83205 10.5547L9.83205 1.5547L8.16795 0.4453L2.16795 9.4453L3.83205 10.5547Z"
        fill="black"
      />
    </svg>
  );
}
