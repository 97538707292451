import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { useCookie } from "react-use";

import { Spinner } from "@otta/search/components/Loading";

export function Logout(): React.ReactElement {
  const [csrf] = useCookie(import.meta.env.VITE_CSRF_COOKIE);
  const client = useApolloClient();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${import.meta.env.VITE_API_HOST}/auth/logout`, {
      method: "POST",
      credentials: "include",
      headers: { ...(csrf ? { "X-CSRF-Token": csrf } : {}) },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Something went wrong");
        }

        return client.resetStore();
      })
      .then(() => {
        navigate("/login", { replace: true });
      })
      .catch(error => {
        Sentry.captureException(error);

        navigate("/account-settings", { replace: true });
      });
  }, [client, navigate, csrf]);

  return <Spinner />;
}
