import { SalariesMultiOptionField } from "./SalariesMultiOptionField";

import { useQuery } from "@otta/search/apollo";
import { QuestionComponentProps } from "@otta/search/containers/Quiz";
import {
  JobFunctionsDocument,
  SalaryPreferenceDocument,
} from "@otta/search/schema";

export function SalaryInputJobFunction(
  props: QuestionComponentProps
): React.ReactElement {
  const { data: salaryPreferenceData } = useQuery(SalaryPreferenceDocument);
  const { data } = useQuery(JobFunctionsDocument);

  const options = data?.jobFunctions ?? [];
  const selectedOtherOption =
    salaryPreferenceData?.salaryPreference?.currentJobTitle != null &&
    salaryPreferenceData?.salaryPreference?.currentSubFunction == null &&
    salaryPreferenceData?.salaryPreference?.currentFunction == null;

  return (
    <SalariesMultiOptionField
      fieldName="currentFunction"
      mutationFieldName="currentFunctionId"
      options={options}
      otherOption
      selectedOtherOption={selectedOtherOption}
      {...props}
    />
  );
}
