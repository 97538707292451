import styled, { css, up } from "@xstyled/styled-components";

import { JobSubtitle } from "../components/JobSubtitle";
import { JobTitle } from "../components/JobTitle";

import { Job } from "@otta/search/schema";

const JobTitleWrapper = styled.div`
  gap: 0.5rem;
  ${up(
    "tablet",
    css`
      display: flex;
      flex-direction: column;
    `
  )}
`;

interface JobTitleSectionProps {
  job: Pick<Job.Fragment, "title" | "subtitle" | "company">;
}

export const JobTitleSection = ({
  job,
}: JobTitleSectionProps): React.ReactElement => {
  return (
    <JobTitleWrapper>
      <JobTitle job={job} company={job.company} />
      <JobSubtitle job={job} />
    </JobTitleWrapper>
  );
};
