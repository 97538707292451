import { useMemo } from "react";

import { MeetCards } from "../MeetCards";

import { CompanyTeamFragment } from "@otta/search/schema";

export function ManagerPrompts({
  team,
}: {
  team: CompanyTeamFragment;
}): React.ReactElement | null {
  const membersWithPrompts = useMemo(
    () =>
      team.members
        .filter(member => !!member.prompts?.length)
        .map((m, idx) => <MeetCards key={idx} member={m} />),
    [team.members]
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return membersWithPrompts.length ? <>{membersWithPrompts}</> : null;
}
