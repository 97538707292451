import { useMemo } from "react";
import styled from "@xstyled/styled-components";
import { format, parseJSON } from "date-fns";

import { ConversationMessageGroup } from "./ConversationMessageGroup";

import { Button } from "@otta/design";
import { JobPreviewCard } from "@otta/search/components/JobPreviewCard";
import { MessageJobFragment } from "@otta/search/schema";

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
interface Sender {
  id: string;
  firstName: string;
  lastName: string;
  profileImagePath: Maybe<string>;
  currentTitle: Maybe<string>;
  currentCompany: Maybe<{ name: string }>;
}
interface ConversationMessagesProps {
  userId: string;
  sourced: boolean;
  messages: {
    id: string;
    insertedAt: string;
    read: boolean;
    content: string;
    sender: Sender;
  }[];
  job: MessageJobFragment;
}

interface Message {
  id: string;
  read: Maybe<boolean>;
  content: string;
  timestamp: string;
  sender?: Sender;
  meta?: React.ReactNode;
}

interface Group {
  sender?: Sender;
  date: string;
  messages: Message[];
}

export function ConversationMessages({
  userId,
  messages = [],
  sourced,
  job,
}: ConversationMessagesProps): React.ReactElement | null {
  const groups = useMemo<Group[]>(() => {
    const allMessages: (Omit<Message, "timestamp"> & {
      insertedAt: string;
    })[] = [];

    if (sourced) {
      const [firstMessage, ...rest] = messages;

      allMessages.push({
        id: firstMessage.id,
        read: firstMessage.read,
        content: firstMessage.content,
        insertedAt: firstMessage.insertedAt,
        meta: (
          <JobPreviewCard {...job}>
            <Button
              as="a"
              href={`/jobs/${job.externalId}`}
              target="_blank"
              level="secondary"
              style={{ width: "100%" }}
            >
              View job
            </Button>
          </JobPreviewCard>
        ),
        sender: {
          ...firstMessage.sender,
          currentCompany: job.company,
        },
      });

      allMessages.push(...rest);
    } else {
      allMessages.push(...messages);
    }

    const groupedMessages = allMessages.reduce<Group[]>(
      ([last, ...rest], { sender, insertedAt, ...message }) => {
        const actualSender = sender?.id === userId ? undefined : sender;

        const messageTime = parseJSON(insertedAt);

        const date = format(messageTime, "do MMMM yyyy");

        const messageWithTimestamp = {
          ...message,
          timestamp: format(messageTime, "HH:mm"),
        };

        if (
          last &&
          last.sender?.id === actualSender?.id &&
          last.date === date
        ) {
          return [
            { ...last, messages: [...last.messages, messageWithTimestamp] },
            ...rest,
          ];
        }

        if (last) {
          return [
            { sender: actualSender, date, messages: [messageWithTimestamp] },
            last,
            ...rest,
          ];
        }

        return [
          { sender: actualSender, date, messages: [messageWithTimestamp] },
          ...rest,
        ];
      },
      []
    );

    return groupedMessages.reverse();
  }, [job, messages, sourced, userId]);

  return (
    <MessagesWrapper>
      {groups.map(({ sender, messages, date }, i) => (
        <ConversationMessageGroup
          key={`message_group_${i}`}
          first={i === 0}
          sender={sender}
          messages={messages}
          date={groups[i - 1] && date === groups[i - 1].date ? undefined : date}
        />
      ))}
    </MessagesWrapper>
  );
}
