import { EmailNotificationPreferences } from "./EmailNotificationPreferences";

import { Card, Middle, Spacing, Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import {
  AccountSettingsDocument,
  NotificationFrequency,
} from "@otta/search/schema";
import { PageWrapper } from "@otta/search/components/PageWrapper";

type EmailAlignType = "center" | "left";

function EmailNotifications({
  align = "left",
}: {
  align?: EmailAlignType;
}): React.ReactElement {
  const { data, loading } = useQuery(AccountSettingsDocument);
  const { jobEmailNotificationsFrequency: frequency } = data?.currentUser ?? {};

  return (
    <Card style={{ padding: pxToRem(16) }}>
      <Spacing size={1}>
        <Text bold size={1} align={align}>
          New match notifications
        </Text>
        {loading ? (
          <Loading />
        ) : (
          <EmailNotificationPreferences user={data?.currentUser} />
        )}
        <Text align={align}>
          We
          {frequency === NotificationFrequency.Never
            ? " won't notify you"
            : `'ll notify you every ${
                frequency === NotificationFrequency.Weekly ? "week" : "day"
              }`}{" "}
          if new jobs match your search preferences.
        </Text>
      </Spacing>
    </Card>
  );
}

export const EmailNotificationsPage = (): React.ReactElement => (
  <PageWrapper style={{ marginTop: pxToRem(20) }}>
    <Middle maxWidth={390}>
      <EmailNotifications align={"center"} />
    </Middle>
  </PageWrapper>
);
