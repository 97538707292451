import styled, { css, up } from "@xstyled/styled-components";

export const QuestionWrapper = styled.div<{ center?: boolean }>`
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
  ${up(
    "tablet",
    css`
      margin: 0;
    `
  )}
`;

export const Item = styled.div`
  width: 100%;
`;
