import { useState } from "react";

import {
  CarouselArrows,
  DesktopView,
  HeaderWrapper,
  MobileView,
} from "../../components/Section";

import { Card, Spacing, Text } from "@otta/design";
import { LatestJobApplication } from "@otta/search/schema";

const SplitContextQuestions = ({
  candidate,
}: {
  candidate: LatestJobApplication.Candidate;
}) => (
  <Spacing size={2}>
    {candidate.contextQuestionResponses
      .slice(0, 3)
      .map(({ question, value }, i) => (
        <Card key={i}>
          <Spacing>
            <Text bold align="left">
              {question.question}
            </Text>
            <Text data-cs-mask size={-1} align="left">
              {value}
            </Text>
          </Spacing>
        </Card>
      ))}
  </Spacing>
);

function AllContextQuestions({
  candidate,
}: {
  candidate: LatestJobApplication.Candidate;
}): React.ReactElement {
  const [index, setIndex] = useState(0);

  const question = candidate.contextQuestionResponses[index].question.question;
  const answer = candidate.contextQuestionResponses[index].value;

  const numQuestions = candidate.contextQuestionResponses.length;

  const handleForward = () => {
    const nextIndex = index === numQuestions - 1 ? 0 : index + 1;
    setIndex(nextIndex);
  };

  const handleBackward = () => {
    const nextIndex = index === 0 ? numQuestions - 1 : index - 1;
    setIndex(nextIndex);
  };

  return (
    <Spacing size={2}>
      <Card>
        <Spacing size={2}>
          <HeaderWrapper>
            <Text size={1} align="left" bold>
              More about me
            </Text>
            <CarouselArrows
              handleForward={handleForward}
              handleBackward={handleBackward}
            />
          </HeaderWrapper>
          <Spacing>
            <Text bold align="left">
              {question}
            </Text>
            <Text size={-1} align="left">
              {answer}
            </Text>
          </Spacing>
        </Spacing>
      </Card>
    </Spacing>
  );
}

export const ContextQuestions = ({
  candidate,
}: {
  candidate: LatestJobApplication.Candidate;
}): React.ReactElement | null =>
  candidate.contextQuestionResponses.length > 0 ? (
    <>
      <MobileView>
        <AllContextQuestions
          candidate={candidate}
          data-testid="context-questions-card"
        />
      </MobileView>
      <DesktopView>
        <SplitContextQuestions
          candidate={candidate}
          data-testid="context-questions-split-card"
        />
      </DesktopView>
    </>
  ) : null;
