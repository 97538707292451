import { useUp } from "@xstyled/styled-components";

import { ActionButton } from "./ActionButton";

import { pushAnalyticsEvent } from "@otta/analytics";
import { Job } from "@otta/search/schema";
import { Icon } from "@otta/icons";
import { shareUrl } from "@otta/search/utils/clipboard";
import { useVisibleTimer } from "@otta/search/hooks/useVisibleTimer";

interface ShareLinkProps {
  job: Pick<Job.Fragment, "externalId" | "title">;
}

export function ShareLink({
  job: { externalId, title },
}: ShareLinkProps): React.ReactElement {
  const shareData = {
    title: title,
    text: "Check out this job on otta.com!",
    url: `https://app.welcometothejungle.com/jobs/${externalId}?utm_source=share_job`,
  };

  return <ShareLinkComponent title="Share this job" data={shareData} />;
}
interface IShareLinkProps {
  title: string;
  data: {
    title: string;
    text: string;
    url: string;
  };
}

function ShareLinkComponent({
  title,
  data,
}: IShareLinkProps): React.ReactElement {
  const isTablet = useUp("tablet");
  const [isVisible, setIsVisible] = useVisibleTimer(2300);

  const onComplete = (didCopy: boolean) => {
    if (didCopy) {
      setIsVisible(true);
    }
  };

  const onClick = () => {
    shareUrl({
      data,
      onComplete,
    });
    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      name: "job-card-share-link",
    });
  };

  return (
    <ActionButton
      data-testid="share-job-link"
      toastMessage="Link copied to clipboard"
      toastVisible={isVisible}
      toastPosition={isTablet ? "center" : "left"}
      toastInvertMotion={!isTablet}
      onClick={onClick}
      icon={<Icon icon="share" />}
      title={title}
    />
  );
}
