import { motion, AnimatePresence, useReducedMotionConfig } from "framer-motion";
import { useEffect, useState } from "react";

import { Suggestion } from "../types";

import { PreferenceSuggestion } from "./PreferenceSuggestion";

import { Card, Spacing, Paragraph, Text } from "@otta/design";

interface IPreferencesProps {
  suggestions: Suggestion[];
  suggestionIdx: number;
  firstName?: string;
  onNext: () => void;
}

export default function Preferences({
  suggestions,
  suggestionIdx,
  firstName,
  onNext,
}: IPreferencesProps): React.ReactElement {
  const [hideCard, setHideCard] = useState(false);

  useEffect(() => {
    if (suggestionIdx > 0 && suggestionIdx === suggestions.length) {
      const timer = setTimeout(() => {
        setHideCard(true);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [suggestionIdx, suggestions.length]);

  const suggestion = suggestions[suggestionIdx];
  const reducedMotion = useReducedMotionConfig();

  const content = (
    <Card>
      {suggestions[suggestionIdx] ? (
        <PreferenceSuggestion
          firstName={firstName}
          suggestion={suggestion}
          idx={suggestionIdx + 1}
          total={suggestions.length}
          onFinish={onNext}
        />
      ) : (
        <Spacing>
          <Text size={1} bold>
            Thanks for helping!
          </Text>
          <Paragraph>
            We&apos;ll use what you&apos;ve told us to show you better matches
            in future.
          </Paragraph>
        </Spacing>
      )}
    </Card>
  );

  return (
    <AnimatePresence initial={false}>
      {!hideCard ? (
        <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <AnimatePresence mode="popLayout">
            {!reducedMotion ? (
              <motion.div
                key={suggestionIdx}
                animate={{
                  opacity: 1,
                  x: 0,
                  zIndex: 0,
                }}
                exit={{
                  opacity: 0,
                  x: -200,
                  zIndex: 1,
                }}
                transition={{
                  default: {
                    delay: 0,
                    type: "tween",
                    duration: 0.6,
                    ease: "easeInOut",
                  },
                  zIndex: { duration: 0 },
                }}
              >
                {content}
              </motion.div>
            ) : (
              <div>{content}</div>
            )}
          </AnimatePresence>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
