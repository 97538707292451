import { useNavigate, useParams } from "react-router-dom";

import { ApplyModal } from "@otta/search/components/ApplyModal";

export function ApplyModalWrapper({
  batchEnd,
}: {
  batchEnd?: boolean;
}): React.ReactElement {
  const { jobId: jobExternalId } = useParams();

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(batchEnd ? "/jobs/batch-end" : "/");
  };

  return (
    <ApplyModal
      jobExternalId={jobExternalId as string}
      onClose={handleClose}
      type="DASHBOARD"
    />
  );
}
