import { useMemo } from "react";
import styled from "@xstyled/styled-components";

import { AssetSprinkler } from "../AssetSprinkler";

import { Tabs } from "@otta/search/components/Tabs";
import { Company } from "@otta/search/schema";
import { List, Spacing, Text } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: lg;
`;

const getLocationLabel = (location: string) => {
  if (location === "ALL") return "Company-wide";
  if (location === "CA") return "Canadian";
  return location;
};

export const shouldDisplayCompanyBenefitsList = (
  company: Pick<Company.Fragment, "regionalBenefits">
) => {
  return company.regionalBenefits?.length > 0;
};

export const CompanyBenefitsList = ({
  company,
}: {
  company: Pick<Company.Fragment, "regionalBenefits" | "brandAssetGroups">;
}) => {
  const benefitsByLocation = company.regionalBenefits;

  const tabs = useMemo(() => {
    return benefitsByLocation.map(
      ({ id, region, benefits, brandAssetLinks }) => {
        return {
          id,
          title: getLocationLabel(region?.name ?? "ALL"),
          content: (
            <AssetSprinkler
              assets={brandAssetLinks}
              data-testid="benefits-photos"
              mobileCarousel
            >
              <Spacing>
                <List type="bullet" size={0}>
                  {benefits.map(b => (
                    <li key={b.id} data-testid="company-benefit">
                      {b.value}
                    </li>
                  ))}
                </List>
              </Spacing>
            </AssetSprinkler>
          ),
        };
      }
    );
  }, [benefitsByLocation]);

  if (!shouldDisplayCompanyBenefitsList(company)) {
    return null;
  }

  return (
    <Wrapper>
      <Text bold as="h2" size={1}>
        Benefits
      </Text>
      {benefitsByLocation.length > 1 ? (
        <Tabs
          tabs={tabs}
          onChange={(tab: string) => {
            pushAnalyticsEvent({
              eventName: "User Clicked",
              name: tab,
              page: window.location.href,
              section: "benefits",
            });
          }}
        />
      ) : (
        tabs[0].content
      )}
    </Wrapper>
  );
};
