import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { Company } from "@otta/search/schema";
import { Icon } from "@otta/icons";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: sm;
`;
interface CompanySizeProps {
  company: Pick<Company.Fragment, "size">;
}

export function CompanySize({
  company: { size },
}: CompanySizeProps): React.ReactElement | null {
  if (!size?.value) {
    return null;
  }

  return (
    <Wrapper>
      <Icon icon="users" size={2} />
      <Text>{size.value} employees</Text>
    </Wrapper>
  );
}
