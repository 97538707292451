import styled, { css, down } from "@xstyled/styled-components";

import { InfoTextSection, SpotlightCard, DesktopAsset } from "./components";

import { palette } from "@otta/design-tokens";
import { Heading, Text } from "@otta/design";
import { OttaIcons } from "@otta/icons";
import { TeamMemberFragment, TeamMemberRole } from "@otta/search/schema";

const Content = styled.div`
  display: grid;
  gap: md;
  grid-template-columns: 1.5fr 1fr;
  width: 100%;

  ${down(
    "desktop",
    css`
      grid-template-columns: 1fr;
    `
  )}
`;

export function HiringManager({
  manager,
}: {
  manager: {
    id: string;
    role: TeamMemberRole;
    member: TeamMemberFragment;
  };
}) {
  const spotlightTag = {
    icon: OttaIcons["HandWave"],
    text: "Meet the team",
    color: palette.brand.pink,
  };

  const question = manager.member.prompts && manager.member.prompts[0].question;
  const answer = manager.member.prompts && manager.member.prompts[0].answer;
  const promptLink = manager.member.promptLinks[0];

  return (
    <SpotlightCard name="hiring-manager-snippet" tag={spotlightTag}>
      <Content>
        <InfoTextSection>
          {manager.member.name && (
            <Heading size={3}>Hi, I'm {manager.member.name}</Heading>
          )}
          <Text size={-1} bold>
            {question}
          </Text>
          <Text size={-1}>{answer}</Text>
        </InfoTextSection>
        {promptLink && <DesktopAsset asset={promptLink} />}
      </Content>
    </SpotlightCard>
  );
}
