import { useEffect, useMemo } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { VerticalSpacing } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { Checkbox } from "@otta/search/components/Input/Checkbox";
import { CompanySizesDocument } from "@otta/search/schema";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

export function CompanySize({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();

  const { data: companySizesData } = useQuery(CompanySizesDocument);

  const isPresent = useIsPresent();

  const preferences = useMemo(
    () => preferencesData?.companySizePreferences ?? [],
    [preferencesData]
  );

  const companySizes = companySizesData?.companySizes ?? [];

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(preferences.length > 0);
    }
  }, [preferences, setNextEnabled, isPresent]);

  if (!companySizesData || !preferencesData) {
    return <Loading />;
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const set = new Set(preferences.map(pref => pref.companySizeId));

    if (e.target.checked) {
      set.add(e.target.value);
    } else {
      set.delete(e.target.value);
    }

    updatePreferences({
      companySizePreferences: Array.from(set).map(id => ({
        companySizeId: id,
      })),
    });
  };

  return (
    <VerticalSpacing size={-4}>
      {companySizes.map(({ id, value }) => (
        <Checkbox
          key={id}
          label={`${value} employees`}
          onChange={handleChange}
          checked={preferences.some(pref => pref.companySizeId === id)}
          value={id}
          disabled={
            preferences.length == companySizes.length - 1 &&
            !preferences.some(pref => pref.companySizeId === id)
          }
        />
      ))}
    </VerticalSpacing>
  );
}
