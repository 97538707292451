import { forwardRef, useMemo, useState } from "react";
import styled, { css, down } from "@xstyled/styled-components";
import { format } from "date-fns";

import { FilterOptions, FilterTitles } from "./Graphs/graph-helpers";
import { FilterToggle } from "./Graphs/FilterToggle";

import { pushAnalyticsEvent } from "@otta/analytics";
import { Company, EbSubsetStatistic } from "@otta/search/schema";
import { HorizontalChart, Spacing, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { useEBTracking } from "@otta/search/contexts/EBTracking";

const Container = styled.div<{ showBorder: boolean }>`
  ${({ showBorder }) =>
    showBorder &&
    css`
      padding: lg 0;
      border-top: ${pxToRem(1)} solid #d5d5d5;
      margin-top: lg;
    `}
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${down(
    "desktop",
    css`
      flex-direction: column;
      align-items: flex-start;
      gap: lg;
    `
  )};
`;

export const PeopleBreakdown = forwardRef<
  HTMLDivElement,
  {
    company: Pick<Company.Company, "ebStatistics">;
    showBorder?: boolean;
  }
>(({ company, showBorder = false }, ref) => {
  const trackingData = useEBTracking();

  const [currentFilter, setCurrentFilter] =
    useState<FilterOptions>("workingLocations");

  const peopleBreakdown = company.ebStatistics?.peopleBreakdown;

  const updatedAt =
    company.ebStatistics?.updatedAt &&
    format(new Date(company.ebStatistics?.updatedAt), "dd MMMM yyyy");

  const filters = useMemo(() => {
    const filtersArray: FilterOptions[] = [];

    if (peopleBreakdown === undefined) {
      return [];
    }

    const is100Percent = (category: Omit<EbSubsetStatistic, "id">[]) => {
      return (
        category.reduce(
          (acc, details) => acc + parseInt(details.percent),
          0
        ) === 100
      );
    };

    if (
      !!peopleBreakdown.ethnicities &&
      peopleBreakdown.ethnicities.length > 0 &&
      is100Percent(peopleBreakdown.ethnicities)
    ) {
      filtersArray.push("ethnicities");
    }

    if (
      !!peopleBreakdown.genders &&
      peopleBreakdown.genders.length > 0 &&
      is100Percent(peopleBreakdown.genders)
    ) {
      filtersArray.push("genders");
    }

    if (
      !!peopleBreakdown.workingLocations &&
      peopleBreakdown.workingLocations.length > 0 &&
      is100Percent(peopleBreakdown.workingLocations)
    ) {
      filtersArray.push("workingLocations");
    }

    if (filtersArray.length > 0) {
      setCurrentFilter(filtersArray[0] as FilterOptions);
    }

    return filtersArray;
  }, [peopleBreakdown]);

  if (
    peopleBreakdown === undefined ||
    !peopleBreakdown[currentFilter] ||
    filters.length === 0
  ) {
    return <div hidden ref={ref} />;
  }

  const handleChangeFilter = (filter: FilterOptions) => {
    pushAnalyticsEvent({
      eventName: "Candidate Clicked People Breakdown Filter",
      filter,
      companyId: trackingData.companyId,
      jobId: trackingData.jobId,
      jobFunction: trackingData.jobFunction,
      jobSubFunction: trackingData.jobSubFunction,
      jobValueClassification: trackingData.jobValueClassification,
      minYearsExperienceRequired: trackingData.minYearsExperienceRequired,
      maxYearsExperienceRequired: trackingData.maxYearsExperienceRequired,
      loginStatus: trackingData.loginStatus,
    });

    setCurrentFilter(filter);
  };

  return (
    <Container showBorder={showBorder} ref={ref}>
      <Spacing>
        <TitleSection data-testid="people-breakdown">
          <Text as="h2" bold size={1}>
            People breakdown
          </Text>
          {filters.length > 1 ? (
            <FilterToggle
              currentFilter={currentFilter}
              onChangeFilter={handleChangeFilter}
              filters={filters}
            />
          ) : (
            <Text bold>{FilterTitles[currentFilter]}</Text>
          )}
        </TitleSection>
        <HorizontalChart data={peopleBreakdown[currentFilter] ?? []} />

        {updatedAt && (
          <Text size={-1} color={palette.grayscale.shade400}>
            Last updated on {updatedAt}
          </Text>
        )}
      </Spacing>
    </Container>
  );
});
