import * as Sentry from "@sentry/browser";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Location,
  matchPath,
  matchRoutes,
  useLocation,
} from "react-router-dom";
import styled from "@xstyled/styled-components";

import { ExternalLinkCue } from "././types/ExternalLink";
import { SimplePollCue } from "./types/SimplePoll";
import { AnyOttacue, parseAndValidateCue } from "./utils";

import { palette, pxToRem } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useQuery } from "@otta/search/apollo";
import { Experiment } from "@otta/search/constants/experiments";
import { useVariant } from "@otta/search/hooks/unleash";
import { CurrentUserDocument } from "@otta/search/schema";

const StyledCue = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - ${pxToRem(32)});
  margin: 0 1rem;
  padding: 1rem;
  background-color: yellow-100;
  border: ${pxToRem(1)} solid ${palette.grayscale.shade200};
  border-top-left-radius: ${pxToRem(10)};
  border-top-right-radius: ${pxToRem(10)};
  box-shadow: 0 0 ${pxToRem(10)} ${pxToRem(2)} rgba(0, 0, 0, 0.05);
  z-index: 15;
  max-width: ${pxToRem(343)};
`;

const setOttacue = (name: string, state: string) => {
  const ottacues = getOttacues();
  ottacues[name] = state;
  localStorage.setItem("ottacues", JSON.stringify(ottacues));
};

const getOttacues = (): Record<string, string> => {
  const ottacues = localStorage.getItem("ottacues");
  return ottacues ? JSON.parse(ottacues) : {};
};

const getOttacue = (name: string): string | null => {
  const ottacues = getOttacues();
  return name in ottacues ? ottacues[name] : null;
};

interface IChildProps {
  cue: AnyOttacue;
  onDismiss: () => void;
  onClick: () => void;
}

function Child({
  cue,
  onClick,
  onDismiss,
}: IChildProps): React.ReactElement | null {
  switch (cue.type) {
    case "external-link":
      return (
        <ExternalLinkCue
          onClick={() => onClick()}
          onDismiss={onDismiss}
          title={cue.title}
          description={cue.description}
          url={cue.url}
          primaryButtonCopy={cue.primaryButtonCopy}
        />
      );
    case "simple-poll":
      return (
        <SimplePollCue
          onClick={onClick}
          onDismiss={onDismiss}
          title={cue.title}
          description={cue.description}
        />
      );
    default:
      return null;
  }
}

function OttaCueComponent({
  name,
  payload,
  location,
}: {
  name: string;
  payload: AnyOttacue;
  location: Location;
}) {
  const [cueHidden, setCueHidden] = useState(true);

  useEffect(() => {
    const existingState = getOttacue(name);

    setCueHidden(!!existingState && existingState !== "active");
  }, [name]);

  const dismissCue = useCallback(() => {
    setOttacue(name, "dismissed");
    setCueHidden(true);
    pushAnalyticsEvent({
      eventName: "Candidate Dismissed Ottacue",
      key: name,
    });
  }, [name]);

  const clickCue = useCallback(
    (eventProperties?: Record<string, string>) => {
      setOttacue(name, "dismissed");
      setCueHidden(true);
      pushAnalyticsEvent({
        eventName: "Candidate Clicked Ottacue",
        key: name,
        ...eventProperties,
      });
    },
    [name]
  );

  const active =
    !cueHidden &&
    (payload.paths ?? ["/"]).some(p => matchPath(p, location.pathname));

  useEffect(() => {
    if (active) {
      pushAnalyticsEvent({
        eventName: "Candidate Viewed Ottacue",
        key: name,
      });
    }
  }, [active, name]);

  if (active) {
    return (
      <StyledCue data-testid="ottacue">
        <Child onClick={clickCue} onDismiss={dismissCue} cue={payload} />
      </StyledCue>
    );
  }

  return null;
}

export const Ottacue = (): React.ReactElement | null => {
  const { variant, payload } = useVariant(Experiment.OttaCues);
  const location = useLocation();
  const { data: userData, loading } = useQuery(CurrentUserDocument);

  const isJobRoute = matchRoutes(
    [
      {
        path: "jobs/:jobId",
      },
    ],
    location
  );

  const isPublicJob = isJobRoute && !userData?.currentUser;

  const cueData = useMemo<AnyOttacue | undefined>(() => {
    if (!payload) {
      return undefined;
    }
    try {
      return parseAndValidateCue(payload);
    } catch (error) {
      Sentry.captureException(error);
      return undefined;
    }
  }, [payload]);

  if (variant === "control" || !cueData || loading || isPublicJob) {
    return null;
  }

  return (
    <OttaCueComponent
      key={variant}
      name={variant}
      payload={cueData}
      location={location}
    />
  );
};
