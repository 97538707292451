import { useState } from "react";

import { ExperienceItem } from "../../components/ExperienceItem";
import { SmallExperienceItem } from "../../components/SmallExperienceItem";
import {
  ExpansionArrow,
  ExperienceWrapper,
  HeaderWrapper,
  ShowMore,
  SmallExperienceWrapper,
} from "../../components/Section";

import { Card, List, Spacing, Text } from "@otta/design";
import { LatestJobApplication } from "@otta/search/schema";

const ExpandedView = ({
  experiences,
  jobTechnologiesUsed,
}: {
  experiences: LatestJobApplication.WorkExperiences[];
  jobTechnologiesUsed: LatestJobApplication.TechnologiesUsed[];
}) => (
  <ShowMore show={3}>
    {experiences.map(experience => (
      <ExperienceWrapper key={experience.id}>
        <Spacing size={-4}>
          <ExperienceItem
            orgName={experience.companyName}
            ottaCompany={experience.companyOnOtta}
            companyUrl={experience.companyUrl}
            title={experience.title}
            startDate={experience.startDate}
            endDate={experience.endDate}
            technologiesUsed={experience.technologiesUsed}
            experienceTechnologiesUsed={jobTechnologiesUsed}
            showDuration
            showMonth
          />
          {experience.descriptions?.length > 0 && (
            <List type="bullet" size={-1}>
              {experience.descriptions.map(({ id, value }) => (
                <li key={id}>{value}</li>
              ))}
            </List>
          )}
        </Spacing>
      </ExperienceWrapper>
    ))}
  </ShowMore>
);

const CollapsedView = ({
  experiences,
  jobTechnologiesUsed,
}: {
  experiences: LatestJobApplication.WorkExperiences[];
  jobTechnologiesUsed: LatestJobApplication.TechnologiesUsed[];
}) => (
  <ShowMore show={3}>
    {experiences.map(experience => (
      <SmallExperienceWrapper key={experience.id}>
        <SmallExperienceItem
          orgName={experience.companyName}
          ottaCompany={experience.companyOnOtta}
          companyUrl={experience.companyUrl}
          startDate={experience.startDate}
          endDate={experience.endDate}
          title={experience.title}
          technologiesUsed={experience.technologiesUsed}
          experienceTechnologiesUsed={jobTechnologiesUsed}
        />
      </SmallExperienceWrapper>
    ))}
  </ShowMore>
);

export function PublicWorkExperiences({
  candidate,
  job,
  expand: defaultExpand,
}: {
  candidate: LatestJobApplication.Candidate;
  job: LatestJobApplication.Job;
  expand: boolean;
}): React.ReactElement | null {
  const [expand, setExpand] = useState(defaultExpand);

  if (!candidate.workExperiences || candidate.workExperiences.length <= 0) {
    return null;
  }

  return (
    <Card data-testid="work-experiences-card">
      <Spacing size={1}>
        <HeaderWrapper>
          <Text size={1} align="left" bold>
            Employment history
          </Text>
          <ExpansionArrow expand={expand} setExpand={setExpand} />
        </HeaderWrapper>
        {expand ? (
          <ExpandedView
            experiences={candidate.workExperiences}
            jobTechnologiesUsed={job.technologiesUsed ?? []}
          />
        ) : (
          <CollapsedView
            experiences={candidate.workExperiences}
            jobTechnologiesUsed={job.technologiesUsed ?? []}
          />
        )}
      </Spacing>
    </Card>
  );
}
