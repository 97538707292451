import styled, { css, down } from "@xstyled/styled-components";

import { Text, Toast } from "@otta/design";
import { pxToRem, modularScale } from "@otta/design-tokens";

const StyledToast = styled(Toast)`
  ${down(
    "desktop",
    css`
      transform: translateY(-120%) translateZ(0px) !important;
    `
  )}
  width: min(65vw, 350px);
  z-index: 5;
`;

const LinkWrapper = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  cursor: pointer;
  font-size: ${modularScale(0)};
  gap: sm;

  &:hover {
    text-decoration: none;
  }
`;

const IconWrapper = styled.span`
  height: ${pxToRem(16)};
  width: ${pxToRem(16)};
  > svg {
    height: ${pxToRem(16)};
    width: ${pxToRem(16)};
  }
`;

interface ActionButtonProps {
  icon?: React.ReactNode;
  title: React.ReactNode | string;
  onClick: () => void;
  "data-testid"?: string;
  "data-analytics-id"?: string;
  toastMessage?: string;
  toastVisible?: boolean;
  toastInvertMotion?: boolean;
  toastPosition?: "left" | "center";
}

export function ActionButton({
  title,
  onClick,
  icon,
  "data-testid": dataTestId,
  "data-analytics-id": dataAnalyticsId,
  toastMessage,
  toastVisible,
  toastInvertMotion = false,
  toastPosition = "center",
}: ActionButtonProps): React.ReactElement {
  return (
    <div>
      {toastMessage && toastVisible !== undefined ? (
        <StyledToast
          invertMotion={toastInvertMotion}
          position={toastPosition}
          isVisible={toastVisible}
          toastMessage={toastMessage}
        >
          <ActionButtonLink
            icon={icon}
            title={title}
            onClick={onClick}
            data-testid={dataTestId}
            data-analytics-id={dataAnalyticsId}
          />
        </StyledToast>
      ) : (
        <ActionButtonLink
          icon={icon}
          title={title}
          onClick={onClick}
          data-testid={dataTestId}
          data-analytics-id={dataAnalyticsId}
        />
      )}
    </div>
  );
}

function ActionButtonLink({
  icon,
  title,
  onClick,
  "data-testid": dataTestId,
  "data-analytics-id": dataAnalyticsId,
}: ActionButtonProps) {
  return (
    <LinkWrapper
      onClick={onClick}
      data-testid={dataTestId}
      data-analytics-id={dataAnalyticsId}
      role="button"
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {title}
    </LinkWrapper>
  );
}
