import { useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";
import { Link } from "react-router-dom";

import { LocationOptions, PreferencesContent } from "./PreferencesContent";

import { Text, Button, Spacing } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import {
  CurrentUserStatisticsDocument,
  CurrentUserDocument,
} from "@otta/search/schema";
import { Delete } from "@otta/search/components/Icons/Delete";
import { Gear } from "@otta/search/components/Icons/Gear";
import { Overlay } from "@otta/search/components/Overlay";

const Wrapper = styled.div`
  display: none;
  ${up(
    "tablet",
    css`
      display: block;
      width: 100%;
      max-width: 240px;
      margin-right: lg;
      margin-top: md;
    `
  )}
`;

const DeleteWrapper = styled.div`
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 16px;
  svg {
    fill: black;
  }
`;

const Header = styled.div`
  background-color: yellow-500;
  padding: lg;
`;

const FloatingMobileButton = styled(Gear)`
  position: fixed;
  bottom: ${modularScale(1)};
  right: ${modularScale(1)};
  width: ${modularScale(7)};
  height: ${modularScale(7)};
  z-index: 1;
  background-color: yellow-500;
  box-shadow: rgba(47, 57, 66, 0.4) 6px 6px 24px;
  padding: 4px;
  border-radius: 12px;
  -webkit-appearance: none;
  ${up(
    "tablet",
    css`
      display: none;
    `
  )}

  svg {
    fill: black;
  }
`;

const MobileOnlyOverlay = styled(Overlay)`
  max-width: unset;
  background: ${palette.brand.grey};
  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
  z-index: 20;
`;

const MobileOnlyOverlayContent = styled.div`
  padding: lg;
`;

export interface IPreferencesContentProps {
  companyName: string;
  companySizes: string[];
  sectorTagPreferences: Array<{ id: string; preference: boolean }>;
  investorPreferences: Array<{ id: string; preference: boolean | null }>;
  handleChangeSearchBar: React.ChangeEventHandler;
  handleClickCompanySize: (id: string) => React.ChangeEventHandler;
  handleClickSectorTag: (id: string) => React.MouseEventHandler<HTMLElement>;
  handleClickInvestor: (id: string) => React.MouseEventHandler<HTMLElement>;
  handleSelectLocation: (options: LocationOptions) => void;
}

export function Preferences(
  props: IPreferencesContentProps
): React.ReactElement {
  const [showMobileOverlay, setShowMobileOverlay] = useState<boolean>(false);

  const preferenceProps = {
    companyName: props.companyName,
    companySizes: props.companySizes,
    sectorTagPreferences: props.sectorTagPreferences,
    investorPreferences: props.investorPreferences,
    handleChangeSearchBar: props.handleChangeSearchBar,
    handleSelectLocation: props.handleSelectLocation,
    handleClickCompanySize: props.handleClickCompanySize,
    handleClickSectorTag: props.handleClickSectorTag,
    handleClickInvestor: props.handleClickInvestor,
  };

  const { data } = useQuery(CurrentUserDocument);

  const { data: userData } = useQuery(CurrentUserStatisticsDocument, {
    errorPolicy: "all",
  });

  return (
    <>
      <Wrapper>
        <Spacing size={2}>
          <PreferencesContent {...preferenceProps} />
          {!data?.currentUser && (
            <Spacing>
              <Text as="h4" bold size={1}>
                {userData?.publicStatistics?.numberLiveCompanies.toLocaleString(
                  "en-GB"
                ) ?? "10,000"}{" "}
                companies hiring on Welcome to the Jungle
              </Text>
              <Button
                as={Link}
                to="/sign-up"
                level="primary"
                style={{
                  textDecoration: "none",
                  display: "inline-block",
                  width: "auto",
                }}
              >
                Sign up
              </Button>
            </Spacing>
          )}
        </Spacing>
      </Wrapper>
      {showMobileOverlay && (
        <MobileOnlyOverlay>
          <Header>
            <Text as="h6" bold>
              Your preferences
            </Text>
          </Header>
          <DeleteWrapper onClick={() => setShowMobileOverlay(false)}>
            <Delete />
          </DeleteWrapper>
          <MobileOnlyOverlayContent>
            <PreferencesContent {...preferenceProps} />
          </MobileOnlyOverlayContent>
        </MobileOnlyOverlay>
      )}
      {!showMobileOverlay && (
        <FloatingMobileButton onClick={() => setShowMobileOverlay(true)} />
      )}
    </>
  );
}
