import { useMutation } from "@apollo/client";
import { useState, useEffect } from "react";

import { FormCardList } from "./components/FormCardList";

import {
  WorkExperiencesDocument,
  CreateWorkExperienceDocument,
  DeleteWorkExperienceDocument,
  UpdateWorkExperienceDocument,
  WorkExperiencesQuery,
  UserWorkExperienceInput,
  ProfileTopDocument,
  ProfileCompletionStatusDocument,
} from "@otta/search/schema";
import { CompletionStatus } from "@otta/search/pages/Profile/components/CompletionStatusTags";
import { handleMutationError } from "@otta/search/utils/error";

export enum FormStages {
  Overview = "OVERVIEW",
  RoleDescription = "ROLE_DESCRIPTION",
  CompanyDescription = "COMPANY_DESCRIPTION",
}

export function WorkExperiences({
  completedWorkExperience = false,
}: {
  completedWorkExperience?: boolean;
}): React.ReactElement {
  const [creatingId, setCreatingId] = useState<null | string>(null);

  const [updateMutation] = useMutation(UpdateWorkExperienceDocument, {
    refetchQueries: [
      { query: WorkExperiencesDocument },
      { query: ProfileTopDocument },
      { query: ProfileCompletionStatusDocument },
    ],
    onError: handleMutationError,
  });

  const [createMutation, { data }] = useMutation(CreateWorkExperienceDocument, {
    refetchQueries: [
      { query: WorkExperiencesDocument },
      { query: ProfileTopDocument },
      { query: ProfileCompletionStatusDocument },
    ],
    awaitRefetchQueries: true,
    onError: handleMutationError,
  });

  const [deleteMutation] = useMutation(DeleteWorkExperienceDocument, {
    refetchQueries: [
      { query: WorkExperiencesDocument },
      { query: ProfileTopDocument },
      { query: ProfileCompletionStatusDocument },
    ],
    awaitRefetchQueries: true,
    onError: handleMutationError,
  });

  const onFinish = () => {
    setCreatingId(null);
  };

  useEffect(() => {
    if (data?.createUserWorkExperience) {
      setCreatingId(data.createUserWorkExperience.id);
    }
  }, [data?.createUserWorkExperience]);

  return (
    <div id="work-experience-form-id">
      <FormCardList
        title="Work experience"
        guidance="Describe at least 3 of your work experiences, each with 3 or more bullet points. If you have less than 3 work experiences, add at least 3 bullet points for each of them."
        creatingId={creatingId}
        onCreate={async (input: UserWorkExperienceInput) =>
          createMutation({ variables: { input } })
        }
        onUpdate={id => (input: UserWorkExperienceInput) =>
          updateMutation({ variables: { id, input } })}
        onFinish={onFinish}
        onDelete={id => async () => deleteMutation({ variables: { id } })}
        formatCompletionStatus={({ currentUser }: WorkExperiencesQuery) =>
          currentUser?.workExperiences && currentUser.workExperiences.length > 0
            ? completedWorkExperience
              ? CompletionStatus.complete
              : CompletionStatus.inProgress
            : CompletionStatus.notStarted
        }
      />
    </div>
  );
}
