import { Card, List, Spacing, Text } from "@otta/design";
import { ApplicationCompanyFragment, JobFragment } from "@otta/search/schema";
import {
  Insights,
  shouldDisplayInsights,
} from "@otta/search/components/Insights";
import {
  CompanyFunding,
  shouldDisplayCompanyFunding,
} from "@otta/search/components/CompanyFunding";
import { shouldDisplayCompanyValues } from "@otta/search/components/CompanyValues";
import { shouldDisplayCompanyMission } from "@otta/search/components/CompanyMission";
import {
  Founders,
  PeopleProgressing,
  companyHasFounders,
  companyHasPeopleProgressing,
} from "@otta/search/components/PeopleAtCompany";
import { PeopleBreakdown } from "@otta/search/components/PeopleAtCompany/PeopleBreakdown";
import {
  OttasTake,
  shouldDisplayOttasTake,
} from "@otta/search/components/OttasTake";
import {
  DiversityInitiatives,
  shouldDisplayDiversityInitiatives,
} from "@otta/search/components/DiversityInitiatives";
import {
  Endorsements,
  shouldDisplayEndorsements,
} from "@otta/search/components/Endorsements";
import {
  CompanyArticles,
  shouldDisplayCompanyArticles,
} from "@otta/search/components/CompanyArticles";
import { useHasEBContent } from "@otta/search/hooks/useHasEBContent";

export function CompanyTab({
  job,
  company,
}: {
  job: Pick<JobFragment, "requirements" | "involvesBullets" | "activelyHiring">;
  company: ApplicationCompanyFragment;
}) {
  const hasEBContent = useHasEBContent();

  return (
    <>
      {shouldDisplayInsights(company) && (
        <Card>
          <Spacing>
            <Text bold as="h3" size={1}>
              Insights
            </Text>
            <Insights job={job} company={company} />
          </Spacing>
        </Card>
      )}
      {shouldDisplayCompanyMission(company) && (
        <Card>
          <Spacing>
            <Text bold as="h3" size={1}>
              Company mission
            </Text>
            <Text>{company.mission}</Text>
          </Spacing>
        </Card>
      )}
      {shouldDisplayCompanyValues(company) && (
        <Card>
          <Spacing>
            <Text bold as="h3" size={1}>
              Values
            </Text>
            <List type="bullet">
              {company.values.map(({ id, value }) => (
                <li key={id}>
                  <Text>{value}</Text>
                </li>
              ))}
            </List>
          </Spacing>
        </Card>
      )}
      {shouldDisplayCompanyFunding(company) && (
        <Card>
          <CompanyFunding company={company} />
        </Card>
      )}
      {hasEBContent && company.ebStatistics && (
        <Card>
          <PeopleBreakdown company={company} />
        </Card>
      )}
      {companyHasFounders(company) && (
        <Card>
          <Founders company={company} />
        </Card>
      )}
      {companyHasPeopleProgressing(company) && (
        <Card>
          <PeopleProgressing company={company} />
        </Card>
      )}
      {shouldDisplayDiversityInitiatives(company) && (
        <Card>
          <DiversityInitiatives company={company} />
        </Card>
      )}
      {shouldDisplayEndorsements(company) && (
        <Card>
          <Endorsements company={company} />
        </Card>
      )}
      {shouldDisplayOttasTake(company) && (
        <Card>
          <OttasTake company={company} hasTitle />
        </Card>
      )}
      {shouldDisplayCompanyArticles(company) && (
        <Card>
          <CompanyArticles company={company} />
        </Card>
      )}
    </>
  );
}
