export function Exclamation(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 16, width: 16 }}
      {...props}
    >
      <circle cx="11" cy="11" r="10.625" stroke="#2F3942" strokeWidth="0.75" />
      <path
        d="M10.2002 14.238L9.82022 4.7H12.1762L11.7962 14.238V14.732H10.2002V14.238ZM9.97222 15.986H12.0242V18H9.97222V15.986Z"
        fill="#2F3942"
      />
    </svg>
  );
}
