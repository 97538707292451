import { FieldInputProps } from "react-final-form";
import styled from "@xstyled/styled-components";
import RcSlider from "rc-slider";

import { Text, VerticalSpacing } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { NotificationFrequency } from "@otta/search/schema";

// these have to be toLowerCase as otherwise the atom produced in the backend is not recognised
const frequencies = [
  {
    position: 0,
    label: "Never",
    value: NotificationFrequency.Never.toLowerCase(),
  },
  {
    position: 50,
    label: "Weekly",
    value: NotificationFrequency.Weekly.toLowerCase(),
  },
  {
    position: 100,
    label: "Daily",
    value: NotificationFrequency.Daily.toLowerCase(),
  },
];

const marks = frequencies.reduce<Record<number, string>>((obj, item) => {
  obj[item.position] = item.label;
  return obj;
}, {});

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 16px 16px;
`;

export const EmailFrequencySlider = ({
  value,
  onChange,
}: FieldInputProps<NotificationFrequency, HTMLElement>): React.ReactElement => {
  const currentPosition = frequencies.find(
    ({ value: v }) => v === value
  )?.position;

  return (
    <VerticalSpacing>
      <VerticalSpacing size={-10}>
        <Text bold>Get new job match notifications</Text>
        <Text size={-1}>
          We&apos;ll let you know when we have new jobs that match your
          preferences
        </Text>
      </VerticalSpacing>
      <Wrapper data-testid="email-notification-frequency-slider">
        <RcSlider
          handleStyle={{
            borderColor: palette.brand.yellow,
            borderWidth: "4px",
            color: palette.brand.yellow,
            opacity: 1,
            width: "18px",
            height: "18px",
            marginTop: "-7px",
          }}
          trackStyle={{
            backgroundColor: palette.grayscale.shade200,
          }}
          railStyle={{
            backgroundColor: palette.grayscale.shade200,
          }}
          dotStyle={{ borderColor: palette.grayscale.shade200 }}
          marks={marks}
          included={false}
          step={null}
          value={currentPosition ?? 50}
          onChange={newPosition => {
            const frequency = frequencies.find(
              ({ position }) => position === newPosition
            );
            if (frequency) {
              onChange(frequency.value);
            }
          }}
        />
      </Wrapper>
    </VerticalSpacing>
  );
};
