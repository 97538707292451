interface RightArrowProps {
  className?: string;
  style?: React.CSSProperties;
}

export function RightArrow({
  className,
  style,
}: RightArrowProps): React.ReactElement {
  return (
    <svg
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M1 1L8 7.95552L1 15"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );
}
