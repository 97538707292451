import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";

import { Error } from "../../../components/FormData";

import { Select } from "@otta/search/components/Input/Select";
import { ChoiceInput } from "@otta/search/schema";

const StyledError = styled(Error)`
  margin-top: 10px;
`;

const formatOptions = (choices: { label: string; value: string }[]) =>
  choices.map(({ value, label }) => ({ value, label }));

interface EditSingleSelectFieldProps {
  choices: ChoiceInput[];
  name: string;
}

export function EditSingleSelectField({
  name,
  choices,
}: EditSingleSelectFieldProps): React.ReactElement | null {
  if (choices.length === 0) {
    return null;
  }

  return (
    <Field<{ label: string; value: string }>
      name={name}
      render={({ input, meta }) => {
        return (
          <>
            <Select
              {...input}
              options={choices ? formatOptions(choices) : []}
            />
            {meta.error && meta.touched && (
              <StyledError>{meta.error}</StyledError>
            )}
          </>
        );
      }}
    />
  );
}
