import { CSSProperties } from "react";

import { Icon, OttaIconsId } from "@otta/icons";
import { ThemeId } from "@otta/search/schema";

interface ThemeIconProps {
  themeId: ThemeId | "ALL_MATCHES";
  className?: string;
  color?: string;
  height?: string | number;
  id?: string;
  lang?: string;
  style?: CSSProperties;
  tabIndex?: number;
  title?: string;
  width?: string | number;
}

export function ThemeIcon({
  themeId,
  ...props
}: ThemeIconProps): React.ReactElement {
  const iconName = getIconName(themeId);
  return <Icon size={2} icon={iconName} {...props} />;
}

const getIconName = (themeId: ThemeId | "ALL_MATCHES"): OttaIconsId => {
  switch (themeId) {
    case ThemeId.ApplyViaOtta:
      return "apply";
    case ThemeId.FemaleFounders:
      return "female";
    case ThemeId.HasSalaries:
      return "salary";
    case ThemeId.PreferredSector:
      return "industry";
    case ThemeId.NewlyAdded:
      return "calendar-clock";
    case ThemeId.RecentlyFunded:
      return "money-bill";
    case ThemeId.TechForGood:
      return "tech-for-good";
    case ThemeId.FullyRemote:
      return "remote";
    case ThemeId.FavouriteTechnologies:
      return "technology";
    case ThemeId.TakeAnotherLook:
      return "visible-on";
    case "ALL_MATCHES":
      return "all-matches";
    default:
      return "apply";
  }
};
