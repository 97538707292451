import styled from "@xstyled/styled-components";

import { Footer } from "./Footer";

import {
  Spacing,
  Heading,
  Middle,
  Paragraph,
  WttjLogo,
  Illustration,
} from "@otta/design";
import { pxToRem, palette, boxShadow } from "@otta/design-tokens";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Nav = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: md;
  background: ${palette.brand.white};
  box-shadow: ${boxShadow};
  height: ${pxToRem(50)};
  z-index: 1;
`;

const Hero = styled.div`
  background: ${palette.beige.shade200};
  position: relative;
  overflow: hidden;
  padding: lg;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: xxl;
`;

const Section = styled(Middle)`
  z-index: 1;
  position: relative;
`;

const RedirectIllustration = styled(Illustration)`
  max-width: 1000;
  width: 80vw;
`;

const StyledWttjLogo = styled(WttjLogo)`
  height: 100%;
`;

export function Maintenance(): React.ReactElement {
  return (
    <Page>
      <Nav>
        <a href={`https://app.welcometothejungle.com/`}>
          <StyledWttjLogo />
        </a>
      </Nav>
      <Hero>
        <RedirectIllustration name="redirect" size={800} />
        <Section maxWidth={1000}>
          <Heading size={7} as="h1">
            One sec, we&apos;ll be right back
          </Heading>
        </Section>
        <Section maxWidth={1000}>
          <Spacing size={5}>
            <Paragraph size={2}>
              Sorry, Welcome to the Jungle is offline. We&apos;re either
              upgrading the site or something&apos;s gone wrong. We&apos;re
              working hard to get back online quickly.
            </Paragraph>
          </Spacing>
        </Section>
      </Hero>
      <Footer />
    </Page>
  );
}
