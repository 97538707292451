export const Swipe = (): React.ReactElement => (
  <svg
    enableBackground="new 0 0 512.002 512.002"
    height="512"
    viewBox="0 0 512.002 512.002"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m456.002 328.125v-84.375c0-24.115-12.736-43.646-34.069-52.243-11.487-4.63-23.375-5.068-32.689-1.665-4.246-5.4-9.847-9.923-16.578-13.217-12.826-6.275-27.315-7.135-38.889-2.802-6.028-7.121-14.467-12.424-24.63-15.052-8.507-2.2-16.862-2.188-24.043-.278l-1.668-76.708c-.484-22.308-17.509-39.782-38.758-39.782-21.373 0-38.76 17.944-38.76 40v191.486c-4.031-4.004-7.021-8.23-10.101-12.583-7.645-10.807-16.311-23.057-40.015-28.169-20.078-4.33-37.368 5.622-44.936 16.7-5.986 8.761-6.482 18.645-1.328 26.437 1.521 2.299 3.3 4.587 5.185 7.009 6.171 7.934 14.624 18.801 22.902 41.523 11.53 31.65 31.444 45.931 47.446 57.407 15.434 11.068 27.093 19.44 29.821 40.223-5.213.342-9.339 4.667-9.339 9.967v70c0 5.523 4.477 10 10 10h221.227c5.523 0 10-4.477 10-10v-70c0-5.321-4.16-9.659-9.403-9.97 1.33-12.744 4.917-25.72 8.676-39.299 4.891-17.668 9.949-35.937 9.949-54.609zm-29.223 163.877h-201.227v-50h55.61c5.523 0 10-4.477 10-10s-4.477-10-10-10h-46.15c-3.123-31.205-20.97-44.024-38.287-56.443-15.189-10.893-30.895-22.155-40.31-48-9.354-25.676-19.315-38.483-25.907-46.958-1.713-2.203-3.193-4.105-4.291-5.764-.791-1.195.959-3.824 1.161-4.12 3.94-5.768 13.763-10.683 24.206-8.431 16.091 3.47 20.79 10.113 27.903 20.169 5.676 8.022 12.587 17.793 26.428 26.169v4.126c0 5.523 4.477 10 10 10s10-4.477 10-10v-220.747c0-11.028 8.416-20 18.761-20 10.104 0 18.521 9.069 18.763 20.217l2.876 132.249c.12 5.521 4.715 9.859 10.215 9.78 5.522-.12 9.9-4.693 9.78-10.215l-.741-34.078c3.242-2.623 10.555-3.893 18.569-1.821 4.663 1.206 19.875 6.512 19.875 24.178 0 5.523 4.477 10 10 10s10-4.477 10-10c0-3.588-.4-7.062-1.17-10.384 6.195-1.56 14.203-.68 21.029 2.661 5.455 2.669 14.606 9.098 14.606 22.16 0 5.523 4.477 10 10 10s10-4.477 10-10c0-2.937-.281-5.808-.827-8.592 4.259-1.01 10.455-.659 16.803 1.899 6.478 2.61 21.545 11.12 21.545 33.692v84.375c0 15.954-4.481 32.137-9.225 49.271-4.015 14.498-8.133 29.406-9.483 44.606h-46.132c-5.523 0-10 4.477-10 10s4.477 10 10 10h55.617v50.001z" />
      <path d="m109.002 74.79-18.859-18.859h86.366c5.523 0 10-4.477 10-10s-4.477-10-10-10h-86.367l18.86-18.859c3.905-3.905 3.905-10.237 0-14.143s-10.237-3.905-14.143 0l-35.93 35.931c-1.875 1.875-2.929 4.419-2.929 7.071s1.054 5.196 2.929 7.071l35.931 35.93c1.953 1.953 4.512 2.929 7.071 2.929s5.119-.976 7.071-2.929c3.905-3.905 3.905-10.237 0-14.142z" />
      <path d="m326.166 422.002h-.007c-5.523 0-9.996 4.477-9.996 10s4.48 10 10.003 10 10-4.477 10-10-4.477-10-10-10z" />
    </g>
  </svg>
);
