import { ReactElement } from "react";
import styled from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";
import { Tick as TickIcon } from "@otta/search/pages/Jobs/icons/Tick";

const Circle = styled.div`
  background-color: green-400;
  border-radius: 100%;
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export function Tick(): ReactElement {
  return (
    <Circle>
      <TickIcon width={24} />
    </Circle>
  );
}
