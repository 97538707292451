import { Component } from "react";
import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Bars = styled.div<{ numOptions: number }>`
  display: inline-block;
  margin-right: 4px;
  div {
    border-radius: 2px;
    margin-right: 2px;
    display: inline-block;
    width: 4px;
    height: 100%;
    background-color: gray-100;
  }

  ${({ numOptions }) =>
    [...Array(numOptions)]
      .map(
        (_, n) =>
          `
            div:nth-child(${n + 1}) {
              height: ${20 + (n + 1) * (80 / numOptions)}%;
            }
          `
      )
      .join("\n")}

  height: 20px;
`;

const StrengthLabel = styled.span`
  display: inline-block;
  min-width: 70px;
  color: gray-600;
  font-size: 16px;
  text-align: left;
`;

interface IStrengthBarProps {
  currentStrength: number;
  strengthOptions?: Array<{ label: string; value: number; colour: string }>;
}

interface IStrengthBarState {
  defaultStrengthOptions: Array<{
    label: string;
    value: number;
    colour: string;
  }>;
}

export class StrengthBar extends Component<
  IStrengthBarProps,
  IStrengthBarState
> {
  constructor(props: IStrengthBarProps) {
    super(props);
    this.state = {
      defaultStrengthOptions: [
        {
          label: "We recommend writing some more",
          value: 33,
          colour: palette.brand.red,
        },
        {
          label: "You may need to include more detail",
          value: 67,
          colour: palette.extended.orange.shade400,
        },
        {
          label: "Great length of answer",
          value: 100,
          colour: palette.extended.green.shade400,
        },
      ],
    };
  }

  public strengthIndex = (options: { value: number }[]): number => {
    return options.findIndex(
      ({ value }, index) =>
        this.props.currentStrength <= value ||
        (this.props.currentStrength > value && index === options?.length - 1)
    );
  };

  public render(): React.ReactElement {
    const options =
      this.props.strengthOptions ?? this.state.defaultStrengthOptions;
    const strengthIndex = this.strengthIndex(options);
    const strength = options[strengthIndex];
    return (
      <Wrapper>
        <Bars numOptions={options.length}>
          {options.map((_, index) => (
            <div
              key={index}
              style={
                index <= strengthIndex
                  ? { backgroundColor: strength.colour }
                  : {}
              }
            />
          ))}
        </Bars>
        <StrengthLabel>{strength.label}</StrengthLabel>
      </Wrapper>
    );
  }
}
