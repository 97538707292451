import styled, { css } from "@xstyled/styled-components";

import { SpotlightContentTag } from "./SpotlightContentTag";
import { CarouselCard } from "./CarouselCard";

import { OttaIconsId } from "@otta/icons";

const Content = styled.div<{ isCentered?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: sm;
  height: 100%;

  ${({ isCentered = false }) =>
    isCentered &&
    css`
      align-items: center;
    `};
`;

export function SpotlightCard({
  tag,
  children,
  name,
  fullWidth = false,
  transparent = false,
  isCentered = false,
}: {
  tag: {
    icon: OttaIconsId;
    text: string;
    color: string;
  };
  name: string;
  fullWidth?: boolean;
  transparent?: boolean;
  isCentered?: boolean;
} & React.PropsWithChildren) {
  return (
    <CarouselCard
      data-testid={`${name}-card`}
      fullWidth={fullWidth}
      transparent={transparent}
    >
      <SpotlightContentTag icon={tag.icon} text={tag.text} color={tag.color} />
      <Content isCentered={isCentered}>{children}</Content>
    </CarouselCard>
  );
}
