export function Magnify(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      version="1.1"
      viewBox="0 0 9.8657 10.069"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path
        d="m6.8657 6c1.6569 0 3-1.3432 3-3s-1.3431-3-3-3c-1.6568 0-3 1.3432-3 3s1.3432 3 3 3zm0.00149-0.5c1.3807 0 2.5-1.1193 2.5-2.5s-1.1193-2.5-2.5-2.5-2.5 1.1193-2.5 2.5 1.1193 2.5 2.5 2.5z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path d="m0.039612 9.1712c-0.043402 0.04019-0.052231 0.10551-0.021059 0.15579 0.16754 0.27021 0.38565 0.50561 0.64236 0.69321l0.031046 0.0227c0.054642 0.0399 0.13052 0.0326 0.17656-0.017l3.7625-4.054-0.55623-0.53797z" />
    </svg>
  );
}
