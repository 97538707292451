import { useEffect, useState } from "react";

import { QuestionComponentProps } from "..";

import { Spacing } from "@otta/design";
import { SelectButton } from "@otta/search/components/Button/SelectButton";

interface IPreferenceQuestionWrapperProps extends QuestionComponentProps {
  hasPreference: boolean;
  Component?: any;
  analyticsNoOptionName: string;
  analyticsYesOptionName: string;
  onNoClick: () => void;
  onYesClick?: () => void;
  noPreference?: boolean;
  optionalQuestion?: boolean;
}

export function PreferenceQuestionWrapper({
  hasPreference,
  Component,
  analyticsNoOptionName,
  analyticsYesOptionName,
  onNoClick,
  onYesClick,
  noPreference = !hasPreference,
  optionalQuestion = false,
  ...props
}: IPreferenceQuestionWrapperProps): React.ReactElement {
  const [selectPreference, setSelectPreference] = useState<boolean | null>(
    hasPreference ? true : null
  );

  const isEnabled =
    optionalQuestion ||
    (selectPreference && hasPreference) ||
    selectPreference == false ||
    noPreference;

  const { setNextEnabled, handleNext } = props;

  useEffect(() => {
    setNextEnabled(isEnabled);
  }, [isEnabled, setNextEnabled]);

  return (
    <Spacing size={3}>
      <Spacing size={-3}>
        <SelectButton
          data-analytics-id={analyticsNoOptionName}
          level="secondary"
          selected={noPreference && selectPreference == false}
          onClick={() => {
            if (!noPreference) {
              onNoClick();
            }
            setSelectPreference(false);
            handleNext();
          }}
        >
          No
        </SelectButton>
        <SelectButton
          data-analytics-id={analyticsYesOptionName}
          level="secondary"
          selected={selectPreference || hasPreference}
          onClick={() => {
            if (onYesClick && noPreference) {
              onYesClick();
            }
            setSelectPreference(true);
          }}
        >
          Yes
        </SelectButton>
      </Spacing>
      {selectPreference && !!Component && <Component {...props} />}
    </Spacing>
  );
}
