import { useState } from "react";
import styled from "@xstyled/styled-components";
import { captureException } from "@sentry/browser";
import { Form as FinalForm } from "react-final-form";
import { useApolloClient } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import { useCookie } from "react-use";

import { Button as DefaultButton, Text } from "@otta/design";
import { typography, modularScale } from "@otta/design-tokens";
import { SignUpFormFields } from "@otta/search/pages/Signup/SignupForm";
import { CurrentUserDocument } from "@otta/search/schema";

const FormContainer = styled.div`
  padding: 36 lg;
  flex: 1;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.div`
  font-size: ${modularScale(1)};
  line-height: ${modularScale(3)};
  font-weight: 600;
  font-family: ${typography.family.heading.name};
  padding-bottom: xl;
`;

const Button = styled(DefaultButton)`
  flex-grow: 1;
  width: 100%;
`;

const Footer = styled.footer`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: md lg;
`;

const ErrorText = styled(Text)`
  color: red-600;
  text-align: center;
  margin-bottom: 6px;
`;

export function MobileAppSignup(): React.ReactElement {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>();
  const [csrf] = useCookie(import.meta.env.VITE_CSRF_COOKIE);
  const client = useApolloClient();

  const handleSignup = async (values: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    marketingConsent: boolean | undefined;
  }) => {
    setError(undefined);

    try {
      const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await fetch(
        `${import.meta.env.VITE_API_HOST}/auth/signup`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            ...(csrf ? { "X-CSRF-Token": csrf } : {}),
          },
          body: JSON.stringify({
            ...values,
            timezone,
          }),
        }
      );

      if (response.status === 409) {
        const redirectUri = searchParams.get("redirect_uri");
        if (redirectUri) {
          const url = new URL(redirectUri);
          url.searchParams.set("error", "conflict");
          window.location.assign(url.toString());
          return;
        }
      }

      if (!response.ok || response.status !== 201) {
        throw new Error("Invalid response from signup API");
      }

      const { data } = await client.query({
        query: CurrentUserDocument,
        errorPolicy: "all",
        fetchPolicy: "network-only",
      });
      if (!data?.currentUser) {
        throw new Error("User not returned after signup");
      }

      const nextSearchParams = new URLSearchParams(searchParams);

      nextSearchParams.set("uid", data.currentUser.externalId);

      const url = `${
        import.meta.env.VITE_API_HOST
      }/auth/oauth2/authorize?${nextSearchParams.toString()}`;

      window.location.assign(url);
    } catch (error) {
      captureException(error);
      setError("Something went wrong, try again.");
    }
  };

  return (
    <FinalForm onSubmit={handleSignup}>
      {({ handleSubmit, submitting }) => (
        <Form onSubmit={handleSubmit} style={{ flex: 1 }} autoComplete="on">
          <FormContainer>
            <Title>Create an account</Title>
            <SignUpFormFields showTerms={false} />
          </FormContainer>
          <Footer>
            {error && <ErrorText size={-1}> {error} </ErrorText>}
            <Button disabled={submitting} level="primary" type="submit">
              Next
            </Button>
          </Footer>
        </Form>
      )}
    </FinalForm>
  );
}
