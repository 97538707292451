import styled from "@xstyled/styled-components";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { Fragment } from "react";

import { Person } from "../icons/Person";
import { Pound } from "../icons/Pound";

import { Card as StyledCard, Text, Spacing, responsive } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { fadeIn } from "@otta/search/components/Animations";
import { PersonalisedCompanies } from "@otta/search/schema";
import { currencyPrefix } from "@otta/search/utils/currencies";

const Card = styled(StyledCard)`
  animation: ${fadeIn} 0.8s;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow: hidden;
  color: black;
`;

const CompanyLogo = styled.img`
  position: relative;
  width: auto;
  height: auto;
  max-height: 32px;
  max-width: 96px;
  object-fit: contain;
`;

const ColouredBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: sm;
  color: black;

  &:last-of-type {
    margin-right: 0;
  }

  svg {
    fill: currentColor;
    height: ${modularScale(-1)};
    margin-right: xs;
  }
`;

const Middle = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ResponsiveText = styled(Text)`
  ${responsive`
    font-size: ${({ size = 0 }) =>
      responsive.modularScale({ mobile: size - 1, desktop: size })}
  `}
`;

export function MiniCompanyCard({
  name,
  logoPath,
  primaryColour,
  shortDescription,
  size,
  urlSafeName,
  sectorTags,
  totalFunding,
}: PersonalisedCompanies.PersonalisedCompanies): React.ReactElement {
  return (
    <Link
      to={encodeURIComponent(urlSafeName)}
      target="_blank"
      style={{ textDecoration: "none" }}
    >
      <Card data-testid="mini-company-card">
        <Spacing>
          {logoPath && (
            <Middle>
              <CompanyLogo src={logoPath} alt={`${name} logo`} />
            </Middle>
          )}
          <ResponsiveText align="center">{shortDescription}</ResponsiveText>
          <Middle>
            {size && (
              <Icon>
                <Person />
                <ResponsiveText as="span" size={-1}>
                  {size.value}
                </ResponsiveText>
              </Icon>
            )}
            {totalFunding && Number(totalFunding.amount) > 0 && (
              <Icon>
                <Pound />

                <ResponsiveText as="span" size={-1}>
                  <NumericFormat
                    value={Number(totalFunding.amount) / 1000000}
                    prefix={
                      currencyPrefix(totalFunding.currency) ??
                      totalFunding.currency
                    }
                    decimalScale={1}
                    displayType="text"
                  />
                  m
                </ResponsiveText>
              </Icon>
            )}
          </Middle>

          <Middle>
            {sectorTags.map((tag, index) => (
              <Fragment key={tag.id}>
                <ResponsiveText as="span" size={-1}>
                  {tag.value}
                </ResponsiveText>
                {index < sectorTags.length - 1 && (
                  <ResponsiveText
                    as="span"
                    style={{ margin: `0 ${modularScale(-8)}` }}
                  >
                    •
                  </ResponsiveText>
                )}
              </Fragment>
            ))}
          </Middle>
        </Spacing>
        {primaryColour && (
          <ColouredBar style={{ backgroundColor: primaryColour }} />
        )}
      </Card>
    </Link>
  );
}
