import styled, { css, down, up } from "@xstyled/styled-components";
import { motion } from "framer-motion";

import { Clickable, Text, Heading, Spacing, Button } from "@otta/design";
import {
  pxToRem,
  borderRadius,
  modularScale,
  palette,
} from "@otta/design-tokens";

const AnimationContainer = styled(motion.div)`
  background: ${palette.brand.yellow};
  padding: xl;
  border-radius: ${pxToRem(borderRadius)};
  min-width: ${pxToRem(350)};
  width: 90vw;
  max-width: ${pxToRem(575)};

  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 4;
  overflow: hidden;

  ${down(
    "tablet",
    css`
      transform: translate(-50%, 0);
    `
  )}
`;

const QuestionContainer = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
  position: relative;
`;

const Delete = styled(Clickable)`
  z-index: 2;
  position: relative;
  width: ${modularScale()};
  height: ${modularScale()};

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 18px;
    width: 1px;
    top: 0;
    left: 50%;
    background-color: black;
    border-radius: 1px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const StyledImg = styled.img`
  width: ${pxToRem(350)};

  ${up(
    "tablet",
    css`
      width: ${pxToRem(450)};
    `
  )}
`;

const ImgContainer = styled.div`
  position: absolute;
  right: -200px;
  top: -250px;

  ${up(
    "tablet",
    css`
      position: absolute;
      right: -210px;
      top: -340px;
    `
  )}
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: -20px;
  right: -10px;
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const StyledButton = styled(Button)`
  font-size: ${modularScale(-1)};
  padding: sm xl;

  ${down(
    "tablet",
    css`
      width: 100%;
    `
  )}
`;

export function BottomPopup({
  heading,
  headingSize = 1,
  subHeading,
  onClose: handleClose,
  handleClickCTA,
  children,
  analyticsId,
}: {
  heading: string;
  headingSize?: number;
  subHeading: string;
  onClose: () => void;
  handleClickCTA: () => void;
  children: React.ReactNode;
  analyticsId?: string;
}): JSX.Element {
  const findFulfilmentPngUrl =
    "https://storage.googleapis.com/otta-static/hero-images/colourway-1/find_fulfilment_640.png";

  return (
    <AnimationContainer
      key="share-otta-popup"
      initial={{ opacity: 0, y: 100, x: "-50%" }}
      animate={{ opacity: 1, y: -20, x: "-50%" }}
      exit={{ opacity: 0, y: 100, x: "-50%" }}
      transition={{
        type: "spring",
        duration: 0.25,
        damping: 11,
        delay: 1,
      }}
    >
      <RelativeWrapper>
        <QuestionContainer>
          <Spacing>
            <Heading bold size={headingSize}>
              {heading}
            </Heading>
            <Text>{subHeading}</Text>
            <StyledButton
              level="secondary"
              onClick={handleClickCTA}
              data-analytics-id={analyticsId}
            >
              {children}
            </StyledButton>
          </Spacing>
        </QuestionContainer>
        <ImgContainer>
          <StyledImg src={findFulfilmentPngUrl} />
        </ImgContainer>
        <CloseButtonWrapper>
          <Delete onClick={handleClose} />
        </CloseButtonWrapper>
      </RelativeWrapper>
    </AnimationContainer>
  );
}
