import { Routes, Route } from "react-router-dom";

import { EmailNotificationsPage } from "./EmailNotifications";
import { PublicEmailNotifications } from "./PublicEmailNotifications";
import { Settings } from "./Settings";

import { PublicRoute } from "@otta/search/utils/routing/PublicRoute";
import { CandidateRoute } from "@otta/search/utils/routing/CandidateRoute";

export function AccountSettings(): React.ReactElement {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <CandidateRoute>
            <Settings />
          </CandidateRoute>
        }
      />
      <Route
        path="email-notifications"
        element={
          <PublicRoute
            publicElement={<PublicEmailNotifications />}
            privateElement={<EmailNotificationsPage />}
          />
        }
      />
    </Routes>
  );
}
