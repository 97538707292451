import { useMemo } from "react";

import { useQuery } from "@otta/search/apollo";
import {
  Company,
  ProfileCompletionStatusDocument,
  ProfileState,
  UserJobPreferences,
} from "@otta/search/schema";

export const useUserProfileStatus = (): {
  profileState: ProfileState | null;
  percentCompleted: number | null;
  completedFields: (string | null)[];
} => {
  const { data } = useQuery(ProfileCompletionStatusDocument, {
    fetchPolicy: "cache-and-network",
  });

  const profileStatusUser = data?.me;
  const profileStatus =
    profileStatusUser?.__typename === "CurrentCandidate" ||
    profileStatusUser?.__typename === "CurrentAdmin"
      ? profileStatusUser?.profileStatus
      : null;

  const profileState = profileStatus?.state ?? null;
  const percentCompleted = profileStatus?.percent ?? null;
  const completedFields = profileStatus?.completedFields ?? [];

  return {
    profileState,
    percentCompleted,
    completedFields,
  };
};

export const useUserCompanySectorTags = ({
  company: { sectorTags },
  user,
}: {
  company: Pick<Company.Fragment, "sectorTags">;
  user?: Pick<UserJobPreferences.CurrentUser, "sectorTagPreferences"> | null;
}) => {
  return useMemo(() => {
    if (sectorTags.length === 0) {
      return null;
    }

    const userPreferences = new Set(
      (user?.sectorTagPreferences ?? []).map(pref => pref.sectorTagId)
    );

    const orderedSectorTags = sectorTags
      .slice()
      .sort((a, b) =>
        userPreferences.has(a.id) && !userPreferences.has(b.id)
          ? -1
          : !userPreferences.has(a.id) && userPreferences.has(b.id)
          ? 1
          : 0
      );

    return { orderedSectorTags, userPreferences };
  }, [sectorTags, user?.sectorTagPreferences]);
};
