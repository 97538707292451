import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";

type TextProps = React.ComponentProps<typeof Text>;

export const SectionHeader = (props: TextProps): React.ReactElement => (
  <Text as="h3" align="left" bold {...props} />
);

export const CardTitle = styled(Text)`
  display: flex;
  align-items: center;
  gap: 6;
`.withComponent("h4");

export const CardSubtitle = (props: TextProps): React.ReactElement => (
  <Text as="h5" align="left" {...props} />
);
