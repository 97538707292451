import { DisplaySetting } from "./DisplaySetting";
import { EditSetting } from "./EditSetting";

import { NotificationFrequency, UserSubscription } from "@otta/search/schema";

interface GroupProps {
  group: UserSubscription;
  jobEmailNotificationsFrequency: NotificationFrequency;
  displayUnderline: boolean;
  editingField: string | null;
  setEditingField: (field: string | null) => void;
}

export const Group = ({
  group,
  jobEmailNotificationsFrequency,
  displayUnderline,
  editingField,
  setEditingField,
}: GroupProps) => {
  if (group.name === editingField) {
    return (
      <EditSetting
        group={group}
        jobEmailNotificationsFrequency={jobEmailNotificationsFrequency}
        onCancel={() => {
          setEditingField(null);
        }}
      />
    );
  }

  return (
    <DisplaySetting
      group={group}
      jobEmailNotificationsFrequency={jobEmailNotificationsFrequency}
      displayUnderline={displayUnderline}
      onEdit={() => {
        setEditingField(group.name);
      }}
      isDisabled={editingField !== null && group.name !== editingField}
    />
  );
};
