import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";

const i18n = i18next.use(XHR).use(LanguageDetector).use(initReactI18next);

const detection = import.meta.env.PROD ? {} : { caches: [] };

i18n.init({
  supportedLngs: ["en-GB", "en-US"],
  load: "currentOnly",
  fallbackLng: "en-GB",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  detection,
});

export default i18n;
