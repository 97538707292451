import { Helmet } from "react-helmet-async";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { SalaryReport } from "./SalaryReport";

import { SalaryInputThankYou } from "@otta/search/pages/Preferences/SalaryInputThankYou";
import { SalaryInput } from "@otta/search/pages/Preferences/SalaryInput";
import { PageWrapper } from "@otta/search/components/PageWrapper";
import { CandidateRoute } from "@otta/search/utils/routing/CandidateRoute";

const StyledPageWrapper = styled(PageWrapper)`
  margin: 0;
  padding-bottom: 0;
`;

export default function Salaries(): React.ReactElement {
  const location = useLocation();

  return (
    <StyledPageWrapper>
      <Helmet>
        <title>Salaries | Explore crowdsourced startup salary data</title>
        <link
          rel="canonical"
          href={`https://app.welcometothejungle.com${location.pathname}`}
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<SalaryReport />} />
        <Route
          path="submit-data/thank-you"
          element={
            <CandidateRoute>
              <SalaryInputThankYou />
            </CandidateRoute>
          }
        />
        <Route
          path="submit-data/*"
          element={
            <CandidateRoute>
              <SalaryInput />
            </CandidateRoute>
          }
        />
      </Routes>
    </StyledPageWrapper>
  );
}
